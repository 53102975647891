<template>
	<Button class="p-button-text p-button-primary" type="button" @click="goBack()">
		<i class="pi pi-arrow-left"></i>
		<span class="ml-1 text-bold">{{ label }}</span>
	</Button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { UrlHelper } from "@/types/helpers/urlHelper";

@Component
export default class BackButton extends Vue {
	@Prop({ type: String, required: true, default: {} }) label!: string;

	goBack(): void {
		UrlHelper.goBack();
	}
}
</script>

<style lang="scss" scoped>
.p-button.p-button-text {
	padding: 0;
	margin-bottom: 0.5rem;
	outline: none;
	box-shadow: none;

	span {
		font-size: 1.1rem;
	}

	&:enabled:focus,
	&:enabled:hover {
		background: transparent;

		span {
			color: black;
		}

		i {
			background: #f5f5f5;
			transition: all 0.3s;
		}
	}
}

.pi {
	&.pi-arrow-left {
		border: 1px solid #dddddd;
		border-radius: 50%;
		padding: 0.5rem;
		font-weight: 600;
		font-size: 0.75rem;
	}
}
</style>
