<template>
	<div class="container my-4">
		<div class="row">
			<div class="col-12">
				<div class="mb-2">
					<h1 v-if="vm.title">{{ vm.title }}</h1>
					<p class="lead text-preline mt-1">{{ vm.text }}</p>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<slot />
			
			<div v-if="!layoutStore.isAuthenticated" class="login-wrapper">
				<div class="bg-primary p-4 w-100">
					<h2>{{$t("expert.accountPage.loginTitle")}}</h2>
					<p>{{$t("expert.accountPage.loginText")}}</p>
					<a class="p-button p-component p-button-success p-button-arrow p-button-raised mt-2" :href="loginPageUrl">{{ $t("common.login") }}</a>
					<div class="mt-2"><a :href="registerPageUrl">{{ $t("common.register") }}</a></div>
				</div>
			</div>

			<div v-else class="col-12 col-lg-7 px-2 px-lg-4">
				<h2>{{ $t("expert.accountPage.profileTitle") }}</h2>
				<div>
					<div class="d-flex align-items-center">
						<ProfileImage can-change-image
									  :profile-image-url="profileImageUrl"
									  @onProfilePictureChange="onProfilePictureChange" />
						<div class="pb-2 pl-2">
							<label class="required">{{ $t("expert.accountPage.profilePicture") }}</label>
							<p>{{ $t("expert.accountPage.uploadExplanation") }}</p>
						</div>
					</div>
					<small v-if="submitted && !profileImageUrl" class="p-error">
						{{ $t("validations.validImage") }}
					</small>
				</div>
				<template v-if="!vm.isInternalExpert">
					<PersonalForm v-model="vm.personalForm" ref="personalForm" />
					<CompanyForm v-model="vm.companyForm"
								 ref="companyForm"
								 :countries="vm.countries"
								 use-iban
								 use-vat />
				</template>
				<Button class="p-button-success p-button-arrow p-button-raised mt-2"
						:disabled="submitted && !canSubmit"
						:label="$t('common.nextStep')"
						@click="submit()" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import AddressForm from "@/components/common/forms/addressForm.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import CompanyForm from "@/components/common/forms/companyForm.vue";
import PersonalForm from "@/components/common/forms/personalForm.vue";
import ProfileImage from "@/components/common/profileImage.vue";
import { CompanyForm as CompanyFormModel } from "@/types/models/form/companyForm";
import { Component, Prop, Ref } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { IExpertAccountPageViewModel } from "@/types/viewModels/commerce/expertAccountPageViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PersonalForm as PersonalFormModel } from "@/types/models/form/personalForm";
import { PropType } from "vue";

@Component({
	components: { ProfileImage, CompanyForm, PersonalForm, AddressForm },
})
export default class ExpertAccountPage extends BaseComponent {
	@Prop({ type: Object as PropType<IExpertAccountPageViewModel>, required: true, default: {} })
	vm!: IExpertAccountPageViewModel;

	@Ref readonly companyForm!: FormComponent<CompanyFormModel>;
	@Ref readonly personalForm!: FormComponent<PersonalFormModel>;

	profileImage!: File;
	profileImageUrl = "";
	submitted = false;

	created(): void {
		Log.info("ExpertAccount", this.vm);
	}

	addToastError(): void {
		this.$toast.add({
			severity: "error",
			detail: this.$t("expert.accountPage.toast.sendCreateExpertPageError"),
			life: 3000,
		});
	}

	get canSubmit(): boolean {
		return this.vm.isInternalExpert
			? !!this.profileImageUrl
			: this.formsDirty && this.formsValid && !!this.profileImageUrl;
	}

	get formsDirty(): boolean {
		return this.personalForm?.isDirty && this.companyForm.isDirty;
	}

	get formsValid(): boolean {
		return this.personalForm?.isValid && this.companyForm?.isValid;
	}

	submit(): void {
		if (!this.canSubmit) {
			this.addToastError();
			return;
		}
		this.companyForm?.form.submitForm();
		this.personalForm?.form.submitForm();
		this.loadingStore.increaseLoadingCount();

		const formData = new FormData();
		formData.append("file", this.profileImage);
		formData.append("personalForm", JSON.stringify(this.vm.personalForm));
		formData.append("companyForm", JSON.stringify(this.vm.companyForm));

		this.axios
			.post(`/api/${this.layoutStore.vm.localizationPrefix}/expert/createAccount`, formData, {
				requestId: "create-expert",
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then(() => {
				this.openUrl(this.vm.expertSettingsUrl);
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
				this.addToastError();
			});
	}

	onProfilePictureChange(img: File): void {
		if (!img) return;
		this.profileImageUrl = URL.createObjectURL(img);
		this.profileImage = img;
	}
}
</script>

<style scoped lang="scss">

    .login-wrapper {
        flex: 1;

		margin: 0 15px;

        a {
            color: #fff;
            text-decoration: underline;
        }

        @media screen and (min-width: 576px) {
			margin: 0;
        }
    }
</style>
