<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{
			'2000px': '40vw',
			'1600px': '50vw',
			'1200px': '60vw',
			'992px': '70vw',
			'768px': '80vw',
			'576px': '90vw',
		}"
		class="p-dialog-secondary"
		:draggable="false"
		:modal="true"
		:style="{ width: '35vw' }"
		@hide="hideModal()"
	>
		<template #header>
			<div class="d-flex flex-column">
				<h3 class="mb-1">{{ $t("cart.dialog.titleProductAdded") }}</h3>
				<div class="d-flex">
					<div class="align-self-center mr-2">
						<Avatar
							v-if="vm.product.iconUrl"
							:alt="vm.product.iconAlt"
							class="overview-product-icon"
							:image="vm.product.iconUrl"
							size="lg"
							:title="vm.product.name"
						/>
					</div>
					<div class="d-flex flex-column">
						<p class="mb-0 text-muted lead">
							{{ vm.product.brand }}
						</p>
						<h4 class="mb-0 pr-5">
							{{ formatProductName(vm.product.name, vm.product.brand) }}
						</h4>
						<p v-if="expertProduct?.expertProfile" class="mb-0 text-muted">
							{{ expertProduct.expertProfile.function }}
						</p>
					</div>
				</div>
			</div>
		</template>
		<div v-if="vm.crossSellProducts?.length" class="cross-sell">
			<div class="cross-sell-wrapper">
				<h3 class="">{{ $t("product.titleCrossSell") }}</h3>
				<div class="row gap-y-3">
					<div v-for="product in vm.crossSellProducts" :key="product" class="col-12 col-lg-6">
						<BaseProductOverviewItemCard is-cross-sell :vm="product" />
					</div>
				</div>
			</div>
		</div>

		<template #footer>
			<div class="d-flex align-items-center justify-content-end">
				<Button
					class="p-button-text p-button-plain"
					:label="$t('cart.dialog.button.continueShopping')"
					@click="hideModal()"
				/>
				<Button
					class="p-button-primary p-button-arrow p-button-sm"
					:label="$t('cart.dialog.button.goToCart')"
					@click="goToCart()"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { ExpertItemViewModel } from "@/types/models/expert/expertItemViewModel";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { useCartStore } from "@/store/commerce/cartStore";

@Component({ components: { BaseProductOverviewItemCard } })
export default class ProductAddedModal extends BaseComponent {
	@Prop({ type: Object as PropType<IProductAddedModal>, required: true, default: {} })
	vm!: IProductAddedModal;

	isVisible = true;

	created(): void {
		Log.info("ProductAddedModal", this.vm);
	}

	@Emit("close")
	hideModal(): boolean {
		useCartStore().hideModal("ProductAdded");
		return false;
	}

	@Emit()
	goToCart(): void {
		this.openUrl(this.layoutStore.vm.cartPageUrl);
	}

	get productType(): ItemProductType {
		return (this.vm.product as BaseProductItemViewModel)?.productType;
	}

	get expertProduct(): ExpertItemViewModel | undefined {
		return this.productType === ItemProductType.Expert ? (this.vm.product as ExpertItemViewModel) : undefined;
	}
}
</script>

<style scoped lang="scss">
.cross-sell {
	.cross-sell-wrapper {
		padding: 1rem;
		background: var(--surface-c);
		overflow: hidden;
	}
}
</style>
