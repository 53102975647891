<template>
	<ProductLayout v-model="showSideBar" :help-block="selectedHelpBlock" :vm="vm">
		<template #tabs>
			<ProductTabs :items="productTabs">
				<template #tabConfiguration>
					<ProductPageContentSection>
						<ProductConfiguration v-if="vm.viewModelIsValid" class="mb-3">
							<ProductConfigurationStep id="step-serviceType">
								<template #header>
									<span class="mr-1"> {{ $t("service.lblServiceType") }}</span>
									<Button
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockServiceType?.linkText"
										@click="showHelpSideBar(vm.helpBlockServiceType)"
									/>
								</template>
								<div class="d-flex flex-wrap">
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableServiceTypes"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedServiceType"
										:option="option"
										@option-selected="onServiceTypeSelected(option.value)"
									>
									</ProductConfigurationOption>
								</div>
							</ProductConfigurationStep>

							<ProductConfigurationStep id="step-unit">
								<template #header>
									<span class="mr-1"> {{ $t("service.lblUnit") }}</span>
									<Button
										class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
										:label="vm.helpBlockUnit?.linkText"
										@click="showHelpSideBar(vm.helpBlockUnit)"
									/>
								</template>
								<div class="d-flex flex-wrap">
									<ProductConfigurationOption
										v-for="(option, index) in productStore.availableUnits"
										:key="index"
										:disabled="isBusy"
										:is-selected="option.value === productStore.selectedUnit"
										:option="option"
										@option-selected="onUnitSelected(option.value)"
									>
									</ProductConfigurationOption>
								</div>
							</ProductConfigurationStep>
						</ProductConfiguration>
						<Message v-else :closable="false" severity="error">
							{{ $t("product.message.currentlyUnavailable") }}
						</Message>
					</ProductPageContentSection>
					<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
						<h3 class="px-2">{{ $t("product.titleUpSell") }}</h3>
						<div class="d-flex">
							<div v-for="product in vm.relatedUpSellProducts" :key="product">
								<ProductOverviewItemCard :item="product" layout="grid" />
							</div>
						</div>
					</ProductPageContentSection>
				</template>
				<template v-if="vm.gameRules" #tabGameRules>
					<ProductPageContentSection :type="'content'">
						<div class="rte" v-html="vm.gameRules" />
					</ProductPageContentSection>
				</template>
			</ProductTabs>
		</template>
		<template #summary>
			<ProductPageSummary
				:can-submit="true"
				:is-product-available="vm.viewModelIsValid"
				:max-amount="productStore.selectedVariation?.maxQuantity"
				:min-amount="productStore.selectedVariation?.minQuantity"
				show-amount-input
				:total-price="priceSummary?.totalPrice"
				:value="productStore?.selectedAmount"
				@addToCart="addProductToCart"
				@amountChanged="onSelectedAmountChanged"
			>
				<template #priceSummary>
					<PriceSummary :vm="priceSummary" />
					<p class="text-muted lead text-right">{{ $t("common.productSummary.allPricesExclVat") }}</p>
				</template>
				<template #configurationSummary>
					<ProductConfigurationSummary :header="$t('service.titleSummary')">
						<ProductConfigurationSummaryItem
							:content="$t(`enum.serviceType.${productStore.selectedServiceType}`)"
							:label="$t('service.lblServiceType')"
						/>
						<ProductConfigurationSummaryItem
							:content="$t(`enum.serviceUnit.${productStore.selectedUnit}`)"
							:label="$t('service.lblUnit')"
						/>
						<ProductConfigurationSummaryItem
							v-if="layoutStore.canImpersonate"
							:content="productStore.selectedVariation?.sku"
							label="SKU"
						/>
					</ProductConfigurationSummary>
				</template>

				<template #walletMessage>
					<WalletMessage
						v-show="
							!isSmallScreen && vm?.canUseWallet && productStore?.selectedVariation?.walletAmount
						"
						:wallet-amount="productStore.selectedVariation?.walletAmount"
					/>
				</template>
			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationOption from "@/components/product/productConfigurationOption.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
import ProductLayout from "@/views/layout/productLayout.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
import ProductPageHeader from "@/components/product/productPageHeader.vue";
import ProductPageSummary from "@/components/product/productPageSummary.vue";
import ProductTabs, { ProductTab } from "@/components/product/productTabs.vue";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";
import WalletMessage from "@/components/common/WalletMessage.vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { HelpBlockViewModel } from "@/types/generated/Common/helpBlockViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { IServicePassProductViewModel } from "@/types/viewModels/commerce/servicePassProductViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { PropType } from "vue";
import { ServiceType } from "@/types/enum/serviceType";
import { Unit } from "@/types/enum/unit";
import { useCartStore } from "@/store/commerce/cartStore";
import { useServicePassProductStore } from "@/store/service/servicePassProductStore";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({
	components: {
		PriceSummary,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationOption,
		ProductConfigurationStep,
		ProductConfigurationSummary,
		ProductConfigurationSummaryItem,
		ProductLayout,
		ProductOverviewItemCard,
		ProductPageContentSection,
		ProductPageHeader,
		ProductPageSummary,
		ProductTabs,
		ProgressSpinner,
		ScrollPanel,
		WalletMessage,
	},
})
export default class ServicePassProduct extends BaseComponent {
	@Prop({ type: Object as PropType<IServicePassProductViewModel>, required: true, default: {} })
	vm!: IServicePassProductViewModel;

	submitted = false;
	showSideBar = false;
	selectedHelpBlock: HelpBlockViewModel | null = null;

	cartStore = useCartStore();
	productStore = useServicePassProductStore();

	created(): void {
		this.productStore.vm = this.vm;
		this.productStore.init();

		this.setDataLayer.viewItem(
			toFixed(this.priceSummary?.totalPrice, 2),
			this.productStore.selectedVariation ? [this.productStore.selectedVariation] : [],
			this.productStore.selectedAmount.toString(),
			this.vm.brand,
			this.vm.name,
			ProductCategoryType[this.vm.productCategory]
		);
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Other,
			} as unknown as BaseProductItemViewModel,
			crossSellProducts: [],
		};
	}

	get productTabs(): ProductTab[] {
		return [
			{
				label: this.$t("service.configureYourService"),
				id: "tabConfiguration",
			},
			{
				label: this.$t("product.tabGameRulesTitle"),
				id: "tabGameRules",
			},
		];
	}

	get priceSummary(): IPriceSummary {
		const standardLines = new Map<string, number>();
		const totalPrice =
			(this.productStore.selectedVariation?.price || this.productStore.vm?.basePrice || 0) *
			this.productStore.selectedAmount;
		standardLines.set(this.$t(`common.priceSummary.yourPrice`), totalPrice);

		return {
			totalPrice: totalPrice,
			standardLines,
		} as IPriceSummary;
	}

	showHelpSideBar(helpBlock: HelpBlockViewModel) {
		this.selectedHelpBlock = helpBlock;
		this.showSideBar = true;
	}

	addProductToCart(): void {
		this.submitted = true;
		this.loadingStore.increaseLoadingCount();
		this.productStore
			.addToCart()
			.then(() => {
				this.cartStore.showModal("ProductAdded");

				this.setDataLayer.addToCart(
					toFixed(this.priceSummary?.totalPrice, 2),
					this.productStore.selectedVariation ? [this.productStore.selectedVariation] : [],
					this.productStore.selectedAmount.toString(),
					this.vm.brand,
					this.vm.name
				);
			})
			.catch(() =>
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("common.messages.addToCartError"),
					life: 3000,
				})
			)
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	onServiceTypeSelected(value: ServiceType) {
		if (value === this.productStore.selectedServiceType) return;
		this.productStore.selectedServiceType = value;
		const filterOptions = {
			serviceType: value,
		};
		this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
		this.productStore.updateConfigurationOptions();
	}

	onUnitSelected(value: Unit) {
		if (value === this.productStore.selectedUnit) return;
		this.productStore.selectedUnit = value;
		const filterOptions = {
			unit: value,
			serviceType: this.productStore.selectedServiceType,
		};
		this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
		this.productStore.updateConfigurationOptions();
	}

	onSelectedAmountChanged(value: number): void {
		this.productStore.selectedAmount = value;
	}
}
</script>

<style scoped lang="scss">
.product-config-step-subtitle {
	font-size: 1rem;
	font-weight: 400;
	color: var(--text-color-secondary);
}

.header-explanation-text {
	font-size: 0.9rem !important;
}
</style>
