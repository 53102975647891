import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1c02c1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "px-lg-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_ctx.editable)
    ? (_openBlock(), _createBlock(_component_InputNumber, {
        key: 0,
        modelValue: _ctx.item.amount,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.amount) = $event)),
        class: _normalizeClass(_ctx.isSmallScreen ? 'w-50 ml-auto' : 'w-75'),
        "decrement-button-class": "p-button-outlined",
        "increment-button-class": "p-button-outlined",
        max: _ctx.maxAmount,
        min: _ctx.item.minAmount,
        "show-buttons": "",
        style: {"max-width":"200px","min-width":"95px"},
        "use-grouping": false
      }, null, 8, ["modelValue", "class", "max", "min"]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.item.amount), 1))
}