import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cart-contract-table" }
const _hoisted_2 = { class: "p-datatable" }
const _hoisted_3 = { class: "p-datatable-header d-flex justify-content-between flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ContractSummary = _resolveComponent("ContractSummary")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Message = _resolveComponent("Message")!
  const _component_CartClientTable = _resolveComponent("CartClientTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t(`cart.table.title${_ctx.getUpperCaseCartLineType(_ctx.cartClient.cartLineType)}`)), 1),
        (_ctx.isEditable)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "p-button-text p-button-danger hidden-print p-0",
              icon: "pi pi-times",
              label: _ctx.$t('cart.button.removeItem'),
              title: _ctx.$t('cart.button.removeItem'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRemoveContractFromCartClick()))
            }, null, 8, ["label", "title"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ContractSummary, { vm: _ctx.contractSummary }, null, 8, ["vm"]),
    (_ctx.cartClient.contractCoterming && _ctx.contractSummary?.contractProlongationDate)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 0,
          class: "mt-0",
          closable: false,
          severity: "warn"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: "myCadac.contractDetail.message.newExpirationDateDurationChange",
              scope: "global"
            }, {
              date: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(_ctx.contractSummary.contractProlongationDate)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_ctx.contractSummary?.contractProlongationDate)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 1,
            class: "mt-0",
            closable: false,
            severity: "info"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_i18n_t, {
                keypath: "myCadac.contractDetail.message.newExpirationDate",
                scope: "global"
              }, {
                date: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(_ctx.contractSummary.contractProlongationDate)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
    (_ctx.editingCartLines?.length)
      ? (_openBlock(), _createBlock(_component_CartClientTable, {
          key: 2,
          "cart-contract-client": _ctx.cartClient,
          "cart-line-type": _ctx.cartClient.cartLineType,
          "is-editable": _ctx.isEditable,
          items: _ctx.editingCartLines
        }, null, 8, ["cart-contract-client", "cart-line-type", "is-editable", "items"]))
      : _createCommentVNode("", true)
  ]))
}