<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<slot name="breadcrumbs" />
			</div>
		</div>
		<div class="row">
			<div v-if="vm.availableInCurrentMarket" class="col-lg-10 mx-lg-auto">
				<div class="d-flex align-items-end">
					<div class="mb-2">
						<h1 class="mb-1">
							{{ vm.title }}
						</h1>
						<p v-if="vm.intro" class="lead text-preline">{{ vm.intro }}</p>
					</div>
					<img
						v-if="!isSmallScreen"
						v-lazy="'/Static/images/support.png'"
						alt=""
						class="ml-auto"
						height="144"
						width="150"
					/>
				</div>
				<Card>
					<template #content>
						<label>{{ $t("support.lblAmountOfTickets") }}</label>
						<div class="row">
							<div class="col-lg-4">
								<InputNumber
									v-model="ticketAmount"
									decrement-button-class="p-button-outlined"
									increment-button-class="p-button-outlined"
									:max="100"
									:min="0"
									show-buttons
									:use-grouping="false"
								/>
							</div>
							<div class="col-lg-4 offset-lg-4">
								<PriceSummary :vm="priceSummary" />
							</div>
						</div>
					</template>
					<template #footer>
						<div class="d-flex justify-content-end">
							<Button
								class="p-button-success p-button-raised p-button-lg"
								:label="$t('common.addToCart')"
								@click="addSupportTicketToCart()"
							/>
						</div>
					</template>
				</Card>
				<Message
					v-if="vm.supportSubscriptionText"
					class="message-with-button my-3"
					:closable="false"
					severity="custom"
				>
					<div class="d-flex align-items-center">
						<i class="pi pi-exclamation-circle text-primary message-icon mr-2" />
						<span>{{ vm.supportSubscriptionText }}</span>
					</div>
					<LinkButton
						v-if="vm.supportSubscriptionLinkItem"
						class="p-button-success m-1"
						:label="vm.supportSubscriptionLinkItem.text"
						:target="vm.supportSubscriptionLinkItem.target"
						:title="vm.supportSubscriptionLinkItem.title"
						:url="vm.supportSubscriptionLinkItem.url"
					/>
				</Message>
			</div>
			<div v-else class="col-lg-10 mx-lg-auto">
				<Message :closable="false" severity="error">{{ $t("common.productUnavailableInMarket") }}</Message>
			</div>
		</div>
	</div>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Card from "primevue/card";
import Image from "primevue/image";
import InputNumber from "primevue/inputnumber";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ISupportTicketProduct } from "@/types/viewModels/commerce/supportTicketProductViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { ProductUpdateInCart } from "@/types/generated/Api/productUpdateInCart";
import { PropType } from "vue";
import { useCartStore } from "@/store/commerce/cartStore";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { Image, Card, InputNumber, PriceSummary, ProductAddedModal } })
export default class SupportTicketProduct extends BaseComponent {
	@Prop({
		type: Object as PropType<ISupportTicketProduct>,
		required: true,
		default: {},
	})
	vm!: ISupportTicketProduct;

	ticketAmount = 0;

	cartStore = useCartStore();

	created(): void {
		this.ticketAmount = this.vm.amount;

		this.setDataLayer.viewItem(
			toFixed(this.priceSummary?.totalPrice, 2),
			[this.vm.supportTicketVariation],
			this.ticketAmount.toString(),
			this.vm.brand,
			this.vm.name,
			ProductCategoryType[this.vm.productCategory]
		);
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm,
				productType: ItemProductType.Other,
			} as unknown as BaseProductItemViewModel,
			crossSellProducts: [],
		};
	}

	get priceSummary(): IPriceSummary {
		const standardLines = new Map<string, number>();
		const price = this.ticketAmount * (this.vm.supportTicketVariation.basePrice || 0);
		standardLines.set(this.$t(`common.total`, [this.ticketAmount]), price);

		return { standardLines, totalPrice: price } as IPriceSummary;
	}

	addSupportTicketToCart(): void {
		this.loadingStore.increaseLoadingCount();
		const model: ProductUpdateInCart = {
			amount: this.ticketAmount,
			id: this.vm.lineItemId,
		};
		this.axios
			.post("/api/cart/add-support-ticket", model)
			.then(() => {
				this.cartStore.showModal("ProductAdded");

				this.setDataLayer.addToCart(
					toFixed(this.priceSummary?.totalPrice, 2),
					[this.vm.supportTicketVariation],
					this.ticketAmount.toString(),
					this.vm.brand,
					this.vm.name
				);
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
			});
	}
}
</script>

<style lang="scss" scoped></style>
