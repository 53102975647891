<template>
	<div class="product-tabs">
		<div class="product-tabs-header">
			<ul
				ref="nav"
				v-scroll-spy-active="{ selector: '.product-tabs-header-item', class: 'active' }"
				v-scroll-spy-link="{ selector: '.product-tabs-header-item-link' }"
				class="product-tabs-header-items"
				role="tablist"
			>
				<template v-for="(item, i) of items" :key="`tab_${item.label}_${i.toString()}`">
					<li v-show="!item.isHidden" class="product-tabs-header-item">
						<a class="product-tabs-header-item-link" :href="getLinkForItem(item)">
							<span>{{ item.label }}</span>
						</a>
					</li>
				</template>
			</ul>
		</div>
		<div v-scroll-spy="{ allowNoActive: items?.length > 1, offset: 120 }">
			<div v-for="(item, i) of items" :id="item.id" :key="`content_${item.label}_${i.toString()}`">
				<slot v-if="$slots[item.id]" :name="item.id" />
				<slot v-else />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { PropType } from "vue";

export interface ProductTab {
	id: string;
	label: string;
	href?: string;
	isDisabled?: boolean;
	isHidden?: boolean;
}

@Component
export default class ProductTabs extends BaseComponent {
	@Prop({
		type: Object as PropType<ProductTab[]>,
		required: true,
		default: {},
	})
	items!: ProductTab[];

	getLinkForItem(item: ProductTab): string {
		return item.href || `#${item.id}`;
	}
}
</script>

<style lang="scss">
.product-tabs {
	position: relative;
}

.product-tabs-header {
	position: sticky;
	top: 0;
	z-index: 101;
	background: var(--surface-a);

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 100%;
		width: 200%;
		height: 100%;
		background: inherit;
	}
}

.product-tabs-header-items {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	border-bottom: 0.8px solid rgba(0, 0, 0, 0.15);
}

.product-tabs-header-item {
	position: relative;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	padding: 0.75rem 0.75rem;
	cursor: pointer;
	outline: none;

	&:hover {
		.product-tabs-header-item-link {
			&::after {
				background-color: var(--primary-color);
			}
		}
	}

	&.active,
	&[aria-selected="true"] {
		.product-tabs-header-item-link {
			color: var(--primary-color);

			&::after {
				background-color: var(--primary-color);
			}
		}
	}

	.product-tabs-header-item-link {
		text-decoration: none;
		color: var(--text-color);
		font-size: 1.2rem;
		font-weight: 600;

		&::after {
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3px;
		}
	}
}
</style>
