import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "lead"
}
const _hoisted_2 = {
  key: 1,
  class: "lead"
}
const _hoisted_3 = { class: "d-flex flex-row justify-center ml-0 ml-lg-2" }
const _hoisted_4 = { class: "text-fade" }
const _hoisted_5 = {
  key: 2,
  class: "p-field-checkbox mt-3"
}
const _hoisted_6 = {
  class: "ml-1 mb-0",
  for: 'setAsDefaultCompany'
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '50vw', '1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '90vw' },
    closable: !_ctx.mustChooseDefault,
    "close-on-escape": _ctx.mustChooseDefault,
    draggable: false,
    header: _ctx.$t('menu.dialog.chooseCompanyHeader'),
    modal: true,
    style: { width: '40vw' },
    onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCancelClick()))
  }, {
    footer: _withCtx(() => [
      (!_ctx.mustChooseDefault)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "p-button-text p-button-plain",
            label: _ctx.$t('common.cancel'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCancelClick()))
          }, null, 8, ["label"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.$t('common.confirm'),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onConfirmClick()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      (_ctx.mustChooseDefault)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t("menu.dialog.chooseDefaultCompanyIntro")), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t("menu.dialog.chooseCompanyIntro")), 1)),
      _createVNode(_component_DataTable, {
        selection: _ctx.selectedCompany,
        "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCompany) = $event)),
        "auto-layout": "",
        paginator: _ctx.showPagination,
        "responsive-layout": "stack",
        rows: _ctx.maxRowsToShow,
        "selection-mode": "single",
        value: _ctx.vm
      }, {
        default: _withCtx(() => [
          (!_ctx.isSmallScreen)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                class: "text-lg-center",
                "selection-mode": "single",
                style: {"padding":"0.75rem","overflow":"hidden"}
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Column, null, {
            body: _withCtx((item) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("b", null, [
                  _createTextVNode(_toDisplayString(item.data.name) + " ", 1),
                  _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(item.data.accountNumber) + ")", 1)
                ]),
                (item.data.isPreferredOrganization)
                  ? (_openBlock(), _createBlock(_component_Chip, {
                      key: 0,
                      class: "p-chip-primary ml-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("common.sortDefault")), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { field: "address" })
        ]),
        _: 1
      }, 8, ["selection", "paginator", "rows", "value"]),
      (!_ctx.mustChooseDefault && _ctx.canSetPreferredCompany)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Checkbox, {
              modelValue: _ctx.setAsDefault,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.setAsDefault) = $event)),
              id: "setAsDefaultCompany",
              binary: true,
              name: "setAsDefaultCompany"
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("menu.dialog.lblSetAsDefaultCompany")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "closable", "close-on-escape", "header"]))
}