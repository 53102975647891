<template>
	<div class="mycadac-banner">
		<div class="d-flex">
			<img v-if="vm.imageUrl" v-lazy="vm.imageUrl" :alt="vm.imageAlt" class="banner-image" />

			<div class="d-flex align-items-center flex-1 justify-content-center p-2">
				<p class="lead">{{ vm.text }}</p>
				<LinkButton
					v-if="vm.cta"
					class="p-button-link p-button-primary text-primary ml-auto flex-shrink-0"
					:label="vm.cta.text"
					:target="vm.cta.target"
					:title="vm.cta.title"
					:url="vm.cta.url"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { MyCadacBannerBlockViewModel } from "@/types/viewModels/blocks/myCadacBannerBlockViewModel";
import { PropType } from "vue";

@Component
export default class MyCadacBannerBlock extends BaseComponent {
	@Prop({ type: Object as PropType<MyCadacBannerBlockViewModel>, required: true, default: {} })
	vm!: MyCadacBannerBlockViewModel;
}
</script>

<style scoped lang="scss">
.mycadac-banner {
	margin-left: auto;
	min-height: 75x;
	max-width: clamp(600px, 60%, 1000px);
	border: 1px solid var(--surface-d);
	border-radius: var(--border-radius);

	.banner-image {
		max-width: clamp(75px, 30%, 125px);
	}
}
</style>
