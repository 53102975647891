import {
	enGB as gb,
	enUS as us,
	nl,
	nlBE as be,
	de,
	deAT as at,
	de as ch,
	fr,
	pt,
	es,
	it,
	nb,
	sv,
	da,
} from 'date-fns/locale';
import { format, isDate, parseISO, set } from 'date-fns';

export class FormatHelper {
	static dateLocales = { gb, us, nl, be, de, fr, pt, es, at, ch, it, nb, sv, da };

	/// Returns the second part of the language
	/// eg. en-GB return 'gb'
	static formatRegion(region: string): string {
		if (!region) return region;
		const splitLang = region.split('-');
		const formattedRegionPart = splitLang.length > 1 ? splitLang[1] : region;
		return formattedRegionPart.toLowerCase();
	}

	static formatDateTimeString(date: string, locale: string): string {
		const parsedDate = Date.parse(date);
		if (!parsedDate) return '';
		return format(parsedDate, 'Pp', { locale: FormatHelper.dateLocales[FormatHelper.formatRegion(locale)] });
	}

	static formatDateString(date: string, locale: string): string {
		const parsedDate = Date.parse(date);
		if (!parsedDate) return '';
		return format(parsedDate, 'P', { locale: FormatHelper.dateLocales[FormatHelper.formatRegion(locale)] });
	}

	static formatLongDateString(date: string, locale: string): string {
		const parsedDate = Date.parse(date);
		if (!parsedDate) return '';
		return format(parsedDate, 'PPP', { locale: FormatHelper.dateLocales[FormatHelper.formatRegion(locale)] });
	}

	static formatDateAsMonthYearString(date: string, locale: string): string {
		const parsedDate = Date.parse(date);
		if (!parsedDate) return '';
		return format(parsedDate, 'MMM yyy', { locale: FormatHelper.dateLocales[locale] });
	}

	static formatTimeString(date: string, locale: string): string {
		const parsedDate = Date.parse(date);
		if (!parsedDate) return '';
		return format(parsedDate, 'p', { locale: FormatHelper.dateLocales[FormatHelper.formatRegion(locale)] });
	}

	static productNameWithoutBrand(productName: string, brandName: string): string {
		return brandName && productName.startsWith(brandName + ' ')
			? productName.substring(brandName.length + 1)
			: productName;
	}

	static formatJsonAsCamelCase(_key: string, value: unknown): unknown {
		if (value && typeof value === 'object') {
			for (const k in value) {
				if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
					value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
					delete value[k];
				}
			}
		}
		return value;
	}

	static getUTCDate(dateNumber: number = Date.now()): Date {
		const date = new Date(dateNumber);

		return new Date(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getUTCHours(),
			date.getUTCMinutes(),
			date.getUTCSeconds()
		);
	}

	static convertDateObjectToDate(date: { day: number; month: number; year: number }): Date {
		return new Date(date.year, date.month, date.day);
	}

	static getDateWithoutTime(date: Date): Date {
		return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
	}

	static toDate(date: string | Date): Date {
		return isDate(date) ? (date as Date) : parseISO(date as string);
	}
}
