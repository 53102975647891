<template>
	<div class="container hidden-print">
		<div class="row my-2 my-lg-3">
			<div class="col-md-6 col-lg-5">
				<h1>
					{{ pageTitle }}<span v-if="vm.cartTitle">: {{ vm.cartTitle }}</span>
				</h1>
				<div v-if="userProfile?.isImpersonated || userProfile?.isCadacSales">
					<div v-if="vm.cartOpportunityName">
						<p class="text-muted">
							{{ $t("cart.lblSalesOpportunity") }}:
							<span class="text-bold">
								<a :href="vm.cartOpportunityUrl" :title="$t('common.goToCrm')">
									{{ vm.cartOpportunityName }}
								</a>
							</span>
						</p>
					</div>
					<Button v-if="cartClient?.itemCount"
							class="pl-lg-0 p-button-secondary p-button-text flex-shrink-0 hidden-print"
							icon="pi pi-envelope"
							:label="$t('cart.button.shareCart')"
							:title="$t('cart.button.shareCart')"
							@click="onShareCartClick()" />
					<Button v-if="cartClient?.itemCount"
							class="pl-lg-0 p-button-secondary p-button-text flex-shrink-0 hidden-print"
							icon="pi pi-file-pdf"
							:label="$t('cart.button.printInvoice')"
							:title="$t('cart.button.printInvoice')"
							@click="onPrintInvoiceClick()" />
				</div>
			</div>
			<div class="col-md-6 col-lg-7 d-flex h-100 flex-column align-items-start flex-lg-row justify-content-lg-end flex-wrap-reverse">
				<Button v-if="showOtherCartsButton"
						:badge="vm.totalNumberOfShoppingCarts.toString()"
						badge-class="p-badge-primary"
						class="p-button-primary p-button-text flex-shrink-0"
						:disabled="isBusy"
						icon="pi pi-shopping-cart"
						:label="$t('cart.button.otherCarts')"
						:title="$t('cart.button.otherCarts')"
						@click="
						openUrl(`${vm.myCartsPageUrl}`);
						$event.target.disabled = true;
					" />
				<Button v-if="showSaveCartButton"
						class="p-button-primary p-button-text flex-shrink-0"
						:disabled="isBusy"
						:icon="vm.cartTitle ? 'pi pi-star-fill' : 'pi pi-star'"
						:label="$t('cart.button.saveCart')"
						:title="$t('cart.button.saveCart')"
						@click="onSaveCartClick()" />
				<Button v-if="!cartStore.isCartEmpty"
						class="p-button-primary p-button-text flex-shrink-0"
						:disabled="isBusy"
						icon="pi pi-trash"
						:label="$t('cart.button.emptyCart')"
						:title="$t('cart.button.emptyCart')"
						@click="onDeleteCartClick()" />
				<Button v-if="!cartStore.isCartEmpty"
						class="p-button-primary p-button-text flex-shrink-0"
						:disabled="isBusy"
						icon="pi pi-print"
						:label="$t('cart.button.printCart')"
						:title="$t('cart.button.printCart')"
						@click="onPrintCartClick()" />
			</div>
		</div>
		<Message v-if="showCartItemsMergedMsg"
				 :closable="true"
				 severity="info"
				 @close="
				showCartItemsMergedMsg = false;
				cookies.remove('shoppingCartsMerged', { path: '/' });
			">
			{{ $t("cart.message.itemsMerged") }}
		</Message>

		<div class="row">
			<div v-if="vm.announcements.isVisible" class="col-12">
				<Message v-for="(announcement, index) in vm.announcements.items"
						 :key="index"
						 :closable="true"
						 severity="info">
					<p v-html="announcement.body"></p>
				</Message>
			</div>
		</div>

		<div class="row mb-2">
			<div v-if="!cartStore.isCartEmpty" class="col-12">
				<CartOverview :cart-client="cartClient" />
			</div>
			<div v-else class="col-12">
				<Message :closable="false" severity="warn">
					{{ $t("cart.message.noItemsInCart") }}
				</Message>
				<div class="d-flex justify-content-between flex-wrap">
					<Button v-if="vm.totalNumberOfShoppingCarts && vm.myCartsPageUrl"
							:badge="vm.totalNumberOfShoppingCarts.toString()"
							badge-class="p-badge-primary"
							class="p-button-primary p-button-outlined p-button-lg flex-shrink-0 mb-2"
							icon="pi pi-shopping-cart"
							:label="$t('cart.button.otherCarts')"
							@click="
							openUrl(`${vm.myCartsPageUrl}`);
							$event.target.disabled = true;
						" />
					<Button v-if="vm.softwareOverviewUrl"
							class="p-button-primary p-button-lg flex-shrink-0 mb-2"
							icon="pi pi-arrow-right"
							icon-pos="right"
							:label="$t('cart.button.continueShopping')"
							@click="
							openUrl(`${vm.softwareOverviewUrl}`);
							$event.target.disabled = true;
						" />
				</div>
			</div>
		</div>

		<div v-if="canExchangeWallet" class="wallet-message p-message p-component">
			<div class="p-message-wrapper justify-content-between flex-wrap">
				<div class="d-flex align-items-center mb-1 mb-lg-0">
					<svg class="mr-1" height="46" width="46">
						<use xlink:href="#icon-wallet"></use>
					</svg>
					<span v-if="!cartStore.hasActiveWalletCoupon" class="text-primary">
						<i18n-t keypath="cart.lblYouCanExchangeWalletAmount">
							<b>{{ formatCurrency(cartStore.maxWalletAmount) }} </b>
						</i18n-t>
					</span>
					<span v-else class="text-primary">
						<i18n-t keypath="cart.lblYouHaveExchangedWalletAmount">
							<b>{{ formatCurrency(cartStore.walletExchangeAmount) }} </b>
						</i18n-t>
					</span>
				</div>
				<div>
					<Button v-if="!cartStore.hasActiveWalletCoupon"
							class="p-button-primary"
							:label="$t('cart.button.addWalletCoupon')"
							@click="onExchangeWalletClick($event)" />
					<div v-else class="d-flex align-items-center">
						<Button v-for="(coupon, index) in cartClient.appliedCouponCodes"
								:key="index"
								class="p-button-danger p-button-text"
								:disabled="isSpinnerVisible('btnRemoveCoupon')"
								:icon="isSpinnerVisible('btnRemoveCoupon') ? 'pi pi-spinner' : 'pi pi-times'"
								icon-pos="right"
								:label="$t('cart.button.removeWalletCoupon')"
								:loading="isSpinnerVisible('btnRemoveCoupon')"
								:title="$t('cart.button.removeWalletCoupon')"
								@click="removeCouponCode(coupon)" />
					</div>
				</div>
			</div>
		</div>

		<div v-if="walletUnavailable" class="wallet-message p-message p-component">
			<div class="p-message-wrapper justify-content-between flex-wrap">
				<div class="d-flex align-items-center mb-1 mb-lg-0">
					<svg class="mr-1" height="46" width="46">
						<use xlink:href="#icon-wallet"></use>
					</svg>
					<span class="text-primary">
						{{ $t("cart.lblWalletNotAvailable") }}
					</span>
				</div>
			</div>
		</div>
	</div>
	<CartPageSummary
		v-if="!cartStore.isCartEmpty"
		:button-text="$t('cart.button.goToCheckout')"
		:can-submit="canCheckout || !isSpinnerVisible('priceSummary')"
		:total-price="priceSummary?.totalPrice"
		@clickAction="onCheckoutClick()"
	>
		<template #priceSummary>
			<PriceSummary :vm="priceSummary" />
		</template>

		<template #card>
			<div v-if="cartClient?.itemCount" class="container hidden-print">
				<div class="">
					<Card class="mb-3 shadow-none">
						<template #content>
							<div class="d-flex"
								 :style="{
									'min-height': isSmallScreen ? '' : '300px',
								}">
								<div class="d-flex w-100">
									<div class="d-flex flex-column w-100">
										<div v-if="cartClient?.totalWalletAmountEarned" class="wallet-earning">
											<i18n-t keypath="cart.lblWalletAmountEarned">
												<b>
													{{ formatCurrency(cartClient?.totalWalletAmountEarned) }}
												</b>
											</i18n-t>
										</div>
										<div class="left-container" :style="{'background-image': `url('/Static/images/yaron1.png')`}">
											<div class="help-text">
												<h1>{{ $t("common.support.helpTitle") }}</h1>
												<p>{{ $t("common.support.helpText") }}</p>
												<div class="contact-info">
													<span>
														<a :href="'tel:' + $t('common.support.phoneNumber')">
															<i class="pi pi-phone pr-1"></i>{{ $t("common.support.phoneNumber") }}
														</a>
													</span>
												</div>
											</div>
											<div class="d-flex flex-column mx-2">
												<Accordion v-model:active-index="couponFormActiveIndex"
														   ref="couponForm"
														   class="">
													<!--Coupon tab-->
													<AccordionTab :disabled="!layoutStore.isAuthenticated">
														<template #header>
															<div class="d-flex justify-content-between">
																<span class="text-primary">
																	{{$t("cart.lblCouponForm")}}
																</span>
																<span v-if="cartStore.hasActiveCoupon"
																	  class="p-badge p-badge-primary">
																	{{ cartClient.appliedCouponCodes.length }}
																</span>
															</div>
														</template>
														<div v-if="canExchangeCoupon && !cartStore.hasActiveCoupon"
															 class="p-inputgroup">
															<InputText v-model="couponCode"
																	   :placeholder="$t('cart.couponForm_placeholder')" />
															<Button class="p-button-primary"
																	:disabled="isSpinnerVisible('btnSubmitCoupon')"
																	icon="pi pi-check"
																	:loading="isSpinnerVisible('btnSubmitCoupon')"
																	@click="addCouponCode()" />
														</div>
														<div v-if="canExchangeCoupon && cartStore.hasActiveCoupon">
															<p>{{ $t("cart.lblActiveCoupon") }}</p>
															<div v-for="(coupon, index) in cartClient.appliedCouponCodes"
																 :key="index"
																 class="d-flex align-items-center">
																<span class="text-bold mr-2">{{ coupon.name }}</span>
																<Button class="p-button-danger p-button-text"
																		:disabled="isSpinnerVisible('btnRemoveCoupon')"
																		:icon="isSpinnerVisible('btnRemoveCoupon') ? 'pi pi-spinner': 'pi pi-times'"
																		icon-pos="right"
																		:label="$t('cart.button.removeCoupon')"
																		:loading="isSpinnerVisible('btnRemoveCoupon')"
																		:title="$t('cart.button.removeCoupon')"
																		@click="removeCouponCode(coupon)" />
															</div>
														</div>
														<p v-show="canExchangeCoupon && cartClient.hasError" class="p-error">
															{{ firstErrorMessage }}
														</p>
														<div v-if="showCouponMessage">
															<p>{{ $t("cart.lblCouponNotAvailable") }}</p>
														</div>
													</AccordionTab>
												</Accordion>
												<a v-if="!layoutStore.isAuthenticated"
												   class="coupon-login"
												   href="javascript:void(0)"
												   @click="goToLoginPage()">
													{{ $t("cart.lblCouponFormLoggedOut") }}
												</a>
											</div>
										</div>
									</div>	
								</div>

								<div v-if="!isSmallScreen" class="col-lg-6 col-xl-6 col-xxl-5 ml-auto p-3 right-container">
									<ProgressSpinner v-if="isSpinnerVisible('priceSummary')"
													 animation-duration=".75s"
													 class="d-block ml-auto mr-0"
													 stroke-width="3" />
									<PriceSummary v-else :vm="priceSummary" />
									<div
										 v-if="layoutStore.isAuthenticated && vm.autoDeskWalletAmount && parseInt(vm.autoDeskWalletAmount) > 0 && priceSummary.totalPersonalPrice && parseInt(priceSummary.totalPersonalPrice) > 0" 
										 class="wallet-card-container" 
										 :style="{'background-image': `url('/Static/images/stars-background.jpg')`}"
										 >
										<div class="wallet-card-extended w-100">
											<img v-lazy="'/Static/images/autodesk-coin.png'"
												 :alt="vm.autodeskLoyaltyCashbackBlock?.icon?.alt"
												 class="wallet-icon" />
											<div class="wallet-title">
												{{$t("cart.autodesk.gift")}}
											</div>
										</div>
										<div class="wallet-text">
											{{$t("cart.autodesk.text", { amount: formatCurrency(Math.min(vm.autoDeskWalletAmount, priceSummary.totalPersonalPrice)) })}}
										</div>
									</div>

									<Message v-if="!hasValidProfile" :closable="false" severity="warn">
										{{ $t("cart.message.unverifiedEmail") }}
									</Message>
									<Message v-if="vm.userIsPartnerCompany" :closable="false" severity="warn">
										{{ $t("cart.message.userIsPartner") }}
									</Message>
									<div class="d-flex justify-content-end">
										<Button class="p-button-success p-button-raised p-button-lg p-button-arrow mt-2 w-100"
												:disabled="!canCheckout || isSpinnerVisible('priceSummary')"
												:label="$t('cart.button.goToCheckout')"
												:loading="isBusy"
												@click="onCheckoutClick()" />
									</div>
								</div>
							</div>
						</template>
					</Card>
				</div>
			</div>
		</template>
	</CartPageSummary>
	<SaveCartModal
		v-if="cartStore.showSaveCartModal"
		:current-title="vm.cartTitle"
		@cart-saved="saveCart($event)"
	/>
	<ShareCartModal v-if="cartStore.showShareCartModal" :vm="vm" @cart-shared="shareCart($event)" />
	<!-- Wallet exchange overlay panel -->
	<OverlayPanel ref="useWalletPanel" :dismissable="true" :show-close-icon="true">
		<div v-if="!cartStore.hasActiveWalletCoupon" class="d-flex flex-column">
			<p class="text-bold">{{ $t("cart.lblExchangeAmount") }}</p>
			<div class="d-flex align-items-center justify-content-between">
				<div class="flex-wrap">
					<InputNumber
						v-model="cartStore.walletExchangeAmount"
						:currency="currentCurrency"
						:locale="currentRegion"
						:max="cartStore.maxWalletAmount"
						:max-fraction-digits="2"
						:min="0.01"
						:min-fraction-digits="2"
						mode="currency"
					/>
					<small>Max: {{ formatCurrency(cartStore.maxWalletAmount) }}</small>
				</div>
			</div>
			<div class="mt-1 align-self-end justify-content-between">
				<Button
					class="p-button-text"
					:disabled="isSpinnerVisible('btnExchangeWallet')"
					:icon="isSpinnerVisible('btnExchangeWallet') ? 'pi pi-spinner spin' : 'pi pi-check'"
					:label="$t('cart.button.exchange')"
					:loading="isSpinnerVisible('btnExchangeWallet')"
					@click="exchangeWalletAmount()"
				/>
			</div>
		</div>
	</OverlayPanel>
	<div v-if="cartClient" class="visible-print">
		<CartPrintPreview
			:cart-client="cartClient"
			:checkout-form="vm.checkoutForm"
			:is-invoice="printInvoice"
			:title="vm.cartTitle ? vm.cartTitle : vm.pageTitle"
		/>
	</div>
</template>

<script lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import BaseComponent from "@/components/base/baseComponent.vue";
import CartOverview from "@/components/commerce/cart/cartOverview.vue";
import CartPageSummary from "@/components/commerce/cart/cartPageSummary.vue";
import CartPrintPreview from "@/components/commerce/cart/cartPrintPreview.vue";
import OverlayPanel from "primevue/overlaypanel";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProgressSpinner from "primevue/progressspinner";
import SaveCartModal from "@/components/commerce/cart/saveCartModal.vue";
import ShareCartModal from "@/components/commerce/cart/shareCartModal.vue";
import { AxiosResponse } from "axios";
import { CartClient } from "@/types/models/cart/cartClient";
import { CartSaveForm } from "@/types/models/form/cartSaveForm";
import { CartShareForm } from "@/types/models/form/cartShareForm";
import { Component, Prop, Ref } from "vue-facing-decorator";
import { CouponCode } from "@/types/generated/Api/couponCode";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { IShoppingCart } from "@/types/viewModels/commerce/shoppingCartViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { concat, sum } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";
import { useCookies } from "@vueuse/integrations/useCookies";
import { GaEvent } from "@/types/models/googleAnalytics/enum/gaEvent";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({
	components: {
		Accordion,
		AccordionTab,
		CartOverview,
		CartPageSummary,
		CartPrintPreview,
		SaveCartModal,
		ShareCartModal,
		PriceSummary,
		ProgressSpinner,
		OverlayPanel,
	},
})
export default class ShoppingCart extends BaseComponent {
	@Prop({ type: Object as PropType<IShoppingCart>, required: true, default: {} }) vm!: IShoppingCart;
	@Ref useWalletPanel!: OverlayPanel;

	cartStore = useCartStore();

	isLoading = false;
	couponFormActiveIndex = -1;
	couponCode = "";
	showCartItemsMergedMsg = false;
	printInvoice = false;
	cookies = useCookies(["shoppingCartsMerged"]);

	get cartClient(): CartClient {
		return this.cartStore.cartClient;
	}

	get canCheckout(): boolean {
		return (
			!this.isBusy &&
			!!this.vm.checkoutPageUrl &&
			!this.vm.userIsPartnerCompany &&
			this.hasValidProfile &&
			!this.cartStore.isCartEmpty
		);
	}

	get hasValidProfile(): boolean {
		return (
			!this.layoutStore.isAuthenticated ||
			!!(this.userProfile?.isEmailVerified || this.userProfile?.isImpersonated)
		);
	}

	get canExchangeWallet(): boolean {
		return !this.cartStore.isCartEmpty && !this.cartStore.hasActiveCoupon && this.vm.canUseWallet;
	}

	get walletUnavailable(): boolean {
		return this.cartStore.hasActiveCoupon && this.canExchangeWallet;
	}

	get showCouponMessage(): boolean {
		return this.cartStore.hasActiveWalletCoupon;
	}

	get canExchangeCoupon(): boolean {
		return !this.cartStore.hasActiveWalletCoupon;
	}

	get pageTitle(): string {
		return this.vm.cartTitle && this.vm.titleEnabled
			? `${this.vm.pageTitle}: ${this.vm.cartTitle}`
			: this.vm.pageTitle;
	}

	get firstErrorMessage(): string {		
		return this.cartClient?.messages?.length ? this.cartClient.messages[0] : "";
	}

	get priceSummary(): IPriceSummary {
		const {
			appliedCouponCodes,
			totalBasePrice,
			subtotalWithoutVat,
			personalDiscount,
			totalPrice,
			totalInstallationPrice,
			vatPercentage,
			vatAmount,
			vatSituation,
		} = this.cartClient;
		const couponDiscount = sum(appliedCouponCodes?.map((x) => x.discount));
		const discountLines = new Map<string, number>();
		appliedCouponCodes?.forEach((coupon: CouponCode) => {
			const name = coupon.isCadacWallet ? this.$t("cart.lblCadacWallet") : coupon.name;
			discountLines.set(name, coupon.discount);
		});
		return {
			totalBasePrice,
			discountLines: discountLines,
			totalCouponDiscount: couponDiscount,
			totalDiscount: personalDiscount + couponDiscount,
			totalPersonalPrice: subtotalWithoutVat,
			totalInstallPrice: totalInstallationPrice,
			totalPrice,
			vatPercentage,
			vatAmount,
			vatSituation,
		} as IPriceSummary;
	}

	get showOtherCartsButton(): boolean {
		return !this.cartStore.isCartEmpty && this.vm.totalNumberOfShoppingCarts > 0 && !!this.vm.myCartsPageUrl;
	}

	get showSaveCartButton(): boolean {
		return (
			!this.cartStore.isCartEmpty &&
			!!(this.userProfile?.isEmailVerified || this.userProfile?.isImpersonated)
		);
	}

	created(): void {
		this.cartStore.cartClient = this.vm.cartClient;
		this.couponFormActiveIndex = this.cartStore.hasActiveWalletCoupon
			? 0
			: this.cartStore.hasActiveCoupon
				? 1
				: -1;
		this.cartStore.updateWalletAmount();
		// Check if Carts were merged.
		if (this.cookies.get("shoppingCartsMerged")) {
			this.showCartItemsMergedMsg = true;
			this.cookies.remove("shoppingCartsMerged", { path: "/" });
		}

		const eCommerceDataLayer = this.constructECommerceDataLayer(
			GaEvent.ViewCart,
			toFixed(this.priceSummary.totalPersonalPrice, 2),
			this.cartStore.allCartLineClients.map((item) => ({
				...item,
				name: item.title,
				priceSubTotal: item.priceSubTotal / (item.amount || 1),
				priceBase: item.priceSubTotal / (item.amount || 1),
				discount: item.discount / (item.amount || 1),
			})),
			null
		);

		const checkoutDataLayer = this.constructCheckoutData(0, "shopping_cart", 4);
		this.setDataLayer.viewCart(eCommerceDataLayer, checkoutDataLayer);
	}

	onCheckoutClick(): void {
		this.loadingStore.increaseLoadingCount();
		const eCommerceDataLayer = this.constructECommerceDataLayer(
			GaEvent.BeginCheckout,
			toFixed(this.priceSummary.totalPersonalPrice, 2),
			this.cartStore.allCartLineClients.map((item) => ({
				...item,
				name: item.title,
				priceSubTotal: item.priceSubTotal / (item.amount || 1),
				priceBase: item.priceSubTotal / (item.amount || 1),
				discount: item.discount / (item.amount || 1),
			}))
		);

		const checkoutDataLayer = this.constructCheckoutData(0, "begin_checkout", 4);
		const orderDataDataLayer = {
			coupon: this.toSnakeCase(this.couponCode) || null,
		};
		this.setDataLayer.beginCheckout(eCommerceDataLayer, checkoutDataLayer, orderDataDataLayer);
		if (this.userProfile && (this.userProfile.isEmailVerified || this.userProfile.isImpersonated)) {
			this.openUrl(this.vm.checkoutPageUrl);
		} else {
			this.goToLoginPage();
		}
	}

	onDeleteCartClick(): void {
		this.$confirm.require({
			message: this.$t("cart.dialog.confirmDeleteCartMessage"),
			header: this.$t("cart.dialog.confirmDeleteCartTitle"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.deleteCart();
			},
		});
	}

	onPrintCartClick(): void {
		this.printInvoice = false;
		this.$nextTick(() => window.print());
	}

	onPrintInvoiceClick(): void {
		this.printInvoice = true;
		this.$nextTick(() => window.print());
	}

	onSaveCartClick(): void {
		this.cartStore.showModal("SaveCart");
	}

	onShareCartClick(): void {
		this.cartStore.showModal("ShareCart");
	}

	goToLoginPage(): void {
		this.openUrl(this.loginPageUrl);
	}

	onExchangeWalletClick(event: Event): void {
		this.useWalletPanel.toggle(event);
	}

	protected addCouponCode(): void {
		if (this.isSpinnerVisible("btnSubmitCoupon")) return;
		this.loadingStore.showSpinner("btnSubmitCoupon");
		this.cartStore
			.addCouponCode(this.couponCode)
			.then(() => {
				if (this.cartClient.appliedCouponCodes?.length && !this.cartClient.hasError) {
					// update cart
					this.$toast.add({
						severity: "success",
						detail: this.$t("cart.toast.addCouponSuccess"),
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: "error",
						detail: this.$t("cart.toast.addCouponError"),
						life: 3000,
					});
				}
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => this.loadingStore.hideSpinner("btnSubmitCoupon"));
	}

	protected removeCouponCode(coupon: CouponCode): void {
		if (this.isSpinnerVisible("btnRemoveCoupon")) return;
		this.loadingStore.showSpinner("btnRemoveCoupon");
		this.cartStore
			.removeCouponCode(coupon.value)
			.then(() => {
				if (!this.cartClient.hasError) {
					this.couponFormActiveIndex = -1;
					this.$toast.add({
						severity: "success",
						detail: coupon?.isCadacWallet
							? this.$t("cart.toast.removeWalletSuccess")
							: this.$t("cart.toast.removeCouponSuccess"),
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: "error",
						detail: coupon?.isCadacWallet
							? this.$t("cart.toast.removeWalletError")
							: this.$t("cart.toast.removeCouponError"),
						life: 3000,
					});
				}
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => this.loadingStore.hideSpinner("btnRemoveCoupon"));
	}

	protected exchangeWalletAmount(): void {
		if (this.isSpinnerVisible("btnExchangeWallet")) return;
		this.loadingStore.showSpinner("btnExchangeWallet");

		this.cartStore
			.exchangeWalletAmount(this.cartStore.walletExchangeAmount)
			.then(() => {
				if (!this.cartClient.hasError) {
					this.useWalletPanel.hide();
					this.$toast.add({
						severity: "success",
						detail: this.$t("cart.toast.exchangeWalletSuccess"),
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: "error",
						detail: this.$t("cart.toast.exchangeWalletError"),
						life: 3000,
					});
				}
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => this.loadingStore.hideSpinner("btnExchangeWallet"));
	}

	protected deleteCart(): void {
		this.loadingStore.increaseLoadingCount();
		this.cartStore
			.deleteCart()
			.then(() => {
				if (!this.cartClient.hasError) {
					this.$toast.add({
						severity: "success",
						detail: this.$t("cart.toast.deleteCartSuccess"),
						life: 3000,
					});

					const allCartItems = concat(
						this.cartStore.allCartLineClients,
						this.cartStore.contractCartClients as any
					).filter(Boolean);

					this.setDataLayer.removeFromCart(
						this.priceSummary.totalPrice?.toString(),
						allCartItems,
						"null"
					);
				} else {
					this.$toast.add({
						severity: "error",
						detail: this.$t("cart.toast.deleteCartError"),
					});
				}
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					detail: this.$t("cart.toast.deleteCartError"),
				});
			})
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	protected saveCart(model: CartSaveForm): void {
		model.salesOpportunity = this.vm.cartOpportunityName;
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post<boolean>("/api/cart/saveCart", model)
			.then((res: AxiosResponse<boolean>) => {
				if (res.data) {
					this.cartStore.hideModal("SaveCart");
					if (model.deleteAfterSave) {
						this.loadingStore.increaseLoadingCount();
						if (this.vm.myCartsPageUrl) this.openUrl(this.vm.myCartsPageUrl);
						else window.location.reload();
					}
					this.$toast.add({
						severity: "success",
						detail: this.$t("cart.toast.saveCartSuccess", [model.title]),
						life: 3000,
					});
				} else {
					this.$toast.add({
						severity: "error",
						detail: this.$t("cart.toast.saveCartError"),
					});
				}
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					detail: this.$t("cart.toast.saveCartError"),
				});
			})
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	protected shareCart(model: CartShareForm): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post<boolean>("/api/cart/shareCart", model)
			.then((res: AxiosResponse<boolean>) => {
				if (res.data) {
					this.cartStore.hideModal("ShareCart");
					this.$toast.add({
						severity: "success",
						detail: this.$t("cart.toast.shareCartSuccess", [model.title]),
						life: 3000,
					});
					window.location.reload();
				} else {
					this.$toast.add({
						severity: "error",
						detail: this.$t("cart.toast.shareCartError"),
					});
				}
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					detail: this.$t("cart.toast.shareCartError"),
				});
			})
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}
}
</script>

<style lang="scss" scoped>
    ::v-deep(.p-accordion) {
        &:not(.p-disabled) .p-accordion-header-link:focus {
            box-shadow: none;
        }

        &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
            background: var(--surface-c);
            border: 0;
        }

        &.p-highlight:hover .p-accordion-header-link {
            background: var(--surface-b);
        }

        .p-accordion-header-link {
            background: var(--surface-b);
            border: 0;
            justify-content: center;
            padding: 1.2rem;
        }
    }

    ::v-deep(.p-card) {
        .p-card-content, .p-card-body {
            padding: 0 !important;
        }
    }

	.wallet-message {
		margin: 1.5rem 0;
		background: var(--surface-b);
		border: solid var(--primary-color);
		border-width: 1px;
		color: var(--primary-color);
	}

	.questions-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;

		h4 {
			flex: 1;
		}

		div {
			flex: 2;
			justify-content: flex-end;
		}
	}

	.wallet-card-container {
		padding: 1.5rem;
		background-color: #b72a5a;
		background-position: center;
		background-size: cover;
		border-radius: 0.3125rem;
		display: flex;
		flex-direction: column;
		color: white;
	}

    .wallet-icon {
        width: 1.5rem;
        height: 1.5rem;
        float: left;
        margin-right: 1rem;
    }

    .wallet-title {
		font-size: 1rem;
		font-weight: 400;
    }

    .wallet-text {
        font-size: 1.5rem;
        font-weight: 450;
    }

    .wallet-card-extended {
		margin-bottom: 1.5rem;
    }

    .left-container {
        background-repeat: no-repeat;
        background-size: auto 90%;
        background-position: right -6rem bottom;
        
        background-color: #8a0044;
        color: white;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    @media (max-width: 535px) {
        .left-container {
			background-image: none !important;
        }
    }

    @media (min-width: 576px) {
        .left-container {
            background-position: right -8rem bottom;
        }
    }
	
	@media (min-width: 768px) {
        .left-container {
            background-position: right -3rem bottom;
        }
    }

    @media (min-width: 992px) {
        .left-container {
            background-position: right -14rem bottom;
        }
    }

    @media (min-width: 1200px) {
        .left-container {
            background-position: right -11rem bottom;
        }
    }

	@media (min-width: 2140px) {
        .left-container {
            background-position: right -1rem bottom;
        }
	}

    .right-container {
        background-color: #FEF4F7;
    }

    .wallet-earning {
        text-align: center;
        background-color: #f4fbf6;
        color: #488b5c;
        padding: 1rem;
        font-weight: 400;
		border-bottom: 1px solid;
    }

    .help-text {
        padding: 2rem;

        h1 {
            font-size: 2.5rem;
            margin-bottom: 10px;
            font-weight: 450;
        }

        p {
            font-size: 1.5rem;
            margin-bottom: 10px;
            font-weight: 450;
        }
    }

    .contact-info {
        font-size: 1rem;
        font-weight: 400;
    }
	
    .coupon-login {
        color: white;
    }
</style>
