import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap" }
const _hoisted_2 = { class: "modal-image" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "flex-1 p-2" }
const _hoisted_5 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '45vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    class: "p-dialog-secondary cta-modal",
    closable: true,
    header: _ctx.header,
    modal: true,
    style: { width: '40vw' },
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCancel()))
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        class: "p-button-success p-button-arrow",
        label: _ctx.vm.ctaText,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("img", {
            alt: _ctx.vm.imageAlt
          }, null, 8, _hoisted_3), [
            [_directive_lazy, _ctx.vm.imageUrl]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.vm.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.vm.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: "text-preline mb-0",
            innerHTML: _ctx.vm?.text
          }, null, 8, _hoisted_6)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}