<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:closable="true"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<template #header>
			<h3 class="mb-0">
				{{ isCancelType ? $t("expert.cancelModal.header") : $t("expert.declineModal.header") }}
			</h3>
		</template>
		<p class="lead mb-2">
			<i18n-t v-if="isCancelType" keypath="expert.cancelModal.intro">
				<a :href="'tel:' + $t('common.support.phoneNumber')">
					{{ $t("common.support.phoneNumber") }}
				</a>
			</i18n-t>
			<i18n-t v-else keypath="expert.declineModal.intro">
				<a :href="'tel:' + $t('common.support.phoneNumber')">
					{{ $t("common.support.phoneNumber") }}
				</a>
			</i18n-t>
		</p>
		<form>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['expertDeclineBookingForm'].message?.required }">
						{{
							isCancelType
								? $t("expert.cancelModal.lblDescription")
								: $t("expert.declineModal.lblDescription")
						}}
					</label>
					<Textarea
						v-model="v$['expertDeclineBookingForm'].message.$model"
						:class="{ 'p-invalid': shouldShowError(v$['expertDeclineBookingForm'].message) }"
						rows="8"
						type="text"
					/>
					<span v-if="shouldShowError(v$['expertDeclineBookingForm'].message)">
						<small v-if="!v$['expertDeclineBookingForm'].message.$model" class="p-error">
							{{ v$["expertDeclineBookingForm"].message?.required?.$message }}
						</small>
						<small v-else-if="v$['expertDeclineBookingForm'].message.$model" class="p-error">
							{{ v$["expertDeclineBookingForm"].message.minLength?.$message }}
						</small>
					</span>
				</div>
			</div>
		</form>

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :disabled="!canSubmit" :label="$t('common.confirm')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Textarea from "primevue/textarea";
import useVuelidate, { Validation } from "@vuelidate/core";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { ExpertBookingSummaryViewModel } from "@/types/models/expert/expertBookingSummaryViewModel";
import { ExpertDeclineBookingForm } from "@/types/models/form/expertDeclineBookingForm";
import { PropType } from "vue";
import { minLength, required } from "@/types/utils/i18n-validators";
import { useExpertStore } from "@/store/expert/expertStore";

export enum ExpertCancelOrDeclineBookingModalType {
	Decline,
	Cancel,
}

@Component({
	components: { Textarea },
	options: {
		validations: {
			expertDeclineBookingForm: {
				message: { required, minLength: minLength(120) },
			},
		},
	},
})
export default class ExpertDeclineBookingModal extends BaseComponent {
	@Prop({
		type: Object as PropType<ExpertBookingSummaryViewModel>,
		required: false,
		default: null,
	})
	bookingSummary!: ExpertBookingSummaryViewModel;

	@Prop({
		type: Number as PropType<ExpertCancelOrDeclineBookingModalType>,
		required: false,
		default: ExpertCancelOrDeclineBookingModalType.Decline,
	})
	type!: ExpertCancelOrDeclineBookingModalType;

	@Emit("submit")
	submitForm(): ExpertDeclineBookingForm {
		return { message: this.expertDeclineBookingForm?.message || "" };
	}

	v$ = useVuelidate();
	expertDeclineBookingForm: null | ExpertDeclineBookingForm = null;
	submitted = false;
	isVisible = true;
	expertStore = useExpertStore();

	created(): void {
		this.expertDeclineBookingForm = {
			message: "",
		};
	}

	get isCancelType(): boolean {
		return this.type === ExpertCancelOrDeclineBookingModalType.Cancel;
	}

	get canSubmit(): boolean {
		return !this.v$["expertDeclineBookingForm"].$invalid;
	}

	onCancel(): void {
		this.expertStore.hideModal("CancelOrDeclineBooking");
	}

	onSubmit(): void {
		if (!this.canSubmit) return;
		this.submitForm();
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}
}
</script>

<style scoped lang="scss"></style>
