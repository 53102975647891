export enum ProductCategoryType {
	Unknown,
	ServicePass,
	Expert,
	Product,
	ServiceProduct,
	SupportProduct,
	SupportTicketProduct,
	TrainingProductGroup,
	ProductCollection,
	WalletProduct,
}
