import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-568aa878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lead mb-2" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-group col-12" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = {
  key: 1,
  class: "p-error"
}
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "form-group col-12" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = {
  key: 1,
  class: "p-error"
}
const _hoisted_12 = { class: "form-row" }
const _hoisted_13 = { class: "form-group col-12" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}
const _hoisted_16 = {
  key: 1,
  class: "p-error"
}
const _hoisted_17 = { class: "form-row" }
const _hoisted_18 = { class: "form-group col-12" }
const _hoisted_19 = { class: "p-inputgroup" }
const _hoisted_20 = { class: "d-flex" }
const _hoisted_21 = { class: "mb-0 mr-1 text-bold" }
const _hoisted_22 = { class: "mb-0" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 0,
  class: "p-error"
}
const _hoisted_25 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Rating = _resolveComponent("Rating")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    closable: true,
    header: _ctx.$t('expert.reviewModal.header'),
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onCancel()))
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.confirm'),
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("expert.reviewModal.intro")), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['expertReviewBookingForm'].score?.required })
            }, _toDisplayString(_ctx.$t("expert.reviewModal.lblScore")), 3),
            _createVNode(_component_Rating, {
              modelValue: _ctx.v$['expertReviewBookingForm'].score.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['expertReviewBookingForm'].score.$model) = $event)),
              cancel: false
            }, null, 8, ["modelValue"]),
            (_ctx.shouldShowError(_ctx.v$['expertReviewBookingForm'].score))
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  (!_ctx.v$['expertReviewBookingForm'].score.$model)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$["expertReviewBookingForm"].score?.required?.$message), 1))
                    : (_ctx.v$['expertReviewBookingForm'].score.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$["expertReviewBookingForm"].score.minValue?.$message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['expertReviewBookingForm'].title?.required })
            }, _toDisplayString(_ctx.$t("expert.reviewModal.lblProjectTitle")), 3),
            _createVNode(_component_InputText, {
              modelValue: _ctx.v$['expertReviewBookingForm'].title.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['expertReviewBookingForm'].title.$model) = $event))
            }, null, 8, ["modelValue"]),
            (_ctx.shouldShowError(_ctx.v$['expertReviewBookingForm'].title))
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                  (!_ctx.v$['expertReviewBookingForm'].title.$model)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.v$["expertReviewBookingForm"].title?.required?.$message), 1))
                    : (_ctx.v$['expertReviewBookingForm'].title.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.v$["expertReviewBookingForm"].title.minLength?.$message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['expertReviewBookingForm'].description?.required })
            }, _toDisplayString(_ctx.$t("expert.reviewModal.lblDescription")), 3),
            _createVNode(_component_Textarea, {
              modelValue: _ctx.v$['expertReviewBookingForm'].description.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['expertReviewBookingForm'].description.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertReviewBookingForm'].description) }),
              rows: "5",
              type: "text"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.shouldShowError(_ctx.v$['expertReviewBookingForm'].description))
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                  (!_ctx.v$['expertReviewBookingForm'].description.$model)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.v$["expertReviewBookingForm"].description?.required?.$message), 1))
                    : (_ctx.v$['expertReviewBookingForm'].description.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.v$["expertReviewBookingForm"].description.minLength?.$message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['expertReviewBookingForm'].expertises?.required })
            }, _toDisplayString(_ctx.$t("expert.reviewModal.lblExpertises")), 3),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_AutoComplete, {
                modelValue: _ctx.v$['expertReviewBookingForm'].expertises.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$['expertReviewBookingForm'].expertises.$model) = $event)),
                "force-selection": "",
                "min-length": 2,
                multiple: true,
                "option-label": "name",
                placeholder: _ctx.$t('expert.reviewModal.autocomplete_placeholder'),
                suggestions: _ctx.searchExpertiseResults,
                onComplete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchExpertises($event)))
              }, {
                option: _withCtx(({ option }) => [
                  _createTextVNode("> "),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("p", _hoisted_21, _toDisplayString(option?.name), 1),
                    _createElementVNode("p", _hoisted_22, "(" + _toDisplayString(option?.category) + ")", 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder", "suggestions"]),
              _createVNode(_component_Button, {
                class: "p-button-primary",
                icon: "pi pi-search"
              })
            ]),
            (_ctx.shouldShowError(_ctx.v$['expertReviewBookingForm'].expertises))
              ? (_openBlock(), _createElementBlock("span", _hoisted_23, [
                  (!_ctx.v$['expertReviewBookingForm'].expertises.$model)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_24, _toDisplayString(_ctx.v$["expertReviewBookingForm"].expertises?.required?.$message), 1))
                    : (_ctx.v$['expertReviewBookingForm'].expertises.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_25, _toDisplayString(_ctx.v$["expertReviewBookingForm"].expertises.minLength?.$message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}