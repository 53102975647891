export enum AdvisorLogStatus {
	/// Status used for when the advisor replies with an unknown status.
	Unsupported,
	/// Status used for showing the polled request is queued to be processed.
	Queued,
	/// Status used for showing the polled request is being processed.
	Processing,
	/// Status used for showing the polled request ended up in an error.
	Error,
	/// Status used for showing the polled request is finished.
	Finished,
}
