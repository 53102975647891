import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "required" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TrainingModuleTable = _resolveComponent("TrainingModuleTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCancel())),
    onSelect: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSelectedCourseChanged()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("training.dialog.titleAddModule")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.$t('common.add'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t("training.dialog.lblChooseCourse")), 1),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedCourse,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCourse) = $event)),
          "append-to": "self",
          "option-label": "label",
          "option-value": "value",
          options: _ctx.availableCourses
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("training.dialog.lblChooseModules")), 1),
      _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.$t("training.dialog.lblMinimumModuleAmount")), 1),
      _createVNode(_component_TrainingModuleTable, {
        "is-editable": true,
        "is-modal": true,
        "training-course": _ctx.selectedCourse,
        onSelectedModulesChanged: _ctx.onSelectedModulesChanged
      }, null, 8, ["training-course", "onSelectedModulesChanged"])
    ]),
    _: 1
  }, 8, ["visible"]))
}