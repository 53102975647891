<template>
	<div v-if="!isGridLayout" class="d-flex p-2 m-2 flex-shrink-0 surface-a">
		<Skeleton height="222px" width="400px" />

		<div class="d-flex flex-column ml-1 w-75">
			<div class="">
				<Skeleton class="mb-1" height="1.4rem" width="20%" />
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton class="mb-1" height=".75rem" width="90%" />
				<Skeleton class="mb-1" height=".75rem" width="95%" />
				<Skeleton height=".75rem" width="80%" />
			</div>
		</div>
	</div>

	<div v-else class="w-100">
		<!--		Grid layout-->
		<div class="p-2 m-2 surface-a">
			<Skeleton height="225px" width="100%" />
			<div class="d-flex flex-column mt-2">
				<Skeleton class="mb-1" height="1.25rem" width="40%" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Skeleton from "primevue/skeleton";
import { Component, Prop } from "vue-facing-decorator";
import { OverviewLayoutType } from "@/views/layout/productOverviewLayout.vue";
import { PropType } from "vue";

@Component({ components: { Skeleton } })
export default class ContentCategoryOverviewItemSkeletonCard extends BaseComponent {
	@Prop({ type: String as PropType<OverviewLayoutType>, required: true, default: "grid" })
	type!: OverviewLayoutType;

	get isGridLayout(): boolean {
		return this.type === "grid";
	}
}
</script>

<style scoped lang="scss"></style>
