<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '45vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		class="p-dialog-secondary partner-info-modal"
		closable
		close-on-escape
		:draggable="false"
		modal
		:style="{ width: '40vw' }"
		@hide="onCancel()"
	>
		<template #header>
			<div class="d-flex">
				<div class="align-self-center mr-1">
					<Avatar shape="circle" size="xlarge">
						<img v-if="vm.iconUrl" v-lazy="vm.iconUrl" :alt="vm.iconAlt" :title="vm.name" />
					</Avatar>
				</div>
				<div class="d-flex flex-column">
					<h2 class="mb-0">
						{{ vm.name }}
					</h2>
					<p class="mb-0 text-muted lead">
						{{ vm.addressString }}
					</p>
				</div>
			</div>
		</template>
		<div class="d-flex flex-wrap">
			<div class="flex-1 pb-2">
				<p class="mb-0 preserve-html" v-html="vm?.introText" />
			</div>
			<div class="modal-image">
				<img v-lazy="vm.mainImageUrl" :alt="vm.imageAlt" />
			</div>
		</div>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { PartnerViewModel } from "@/types/models/partner/partnerViewModel";

@Component
export default class PartnerInfoModal extends BaseComponent {
	@Prop({ type: Object as PropType<PartnerViewModel>, required: true, default: {} })
	vm!: PartnerViewModel;

	isVisible = true;

	@Emit() submit() {
		return true;
	}

	@Emit() cancel() {
		return false;
	}

	onCancel(): void {
		this.cancel();
	}

	onSubmit(): void {
		this.submit();
	}
}
</script>

<style lang="scss">
.partner-info-modal {
	.modal-image {
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.preserve-html {
		ul {
			list-style: disc inside;
		}
	}
}
</style>
