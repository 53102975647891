<template>
	<div class="cart-training-table">
		<div class="d-flex justify-content-between flex-wrap">
			<h5 class="text-primary">
				{{
					trainingGroup?.isModular
						? `${trainingGroup?.name} (${$t("myCadac.trainingOverview.table.lblModular")})`
						: trainingGroup?.name
				}}
			</h5>
			<div>
				<Button
					v-if="canChangeConfiguration && isEditable"
					class="p-button-secondary p-button-text flex-shrink-0 hidden-print"
					icon="pi pi-cog"
					:label="$t('cart.button.editConfiguration')"
					:title="$t('cart.button.editConfiguration')"
					@click="
						onChangeConfigurationClick();
						$event.target.disabled = true;
					"
				/>
				<Button
					v-if="isEditable"
					class="p-button-text p-button-danger hidden-print flex-shrink-0"
					icon="pi pi-times"
					:label="$t('cart.button.removeItem')"
					:title="$t('cart.button.removeItem')"
					@click="onRemoveTrainingFromCartClick()"
				/>
			</div>
		</div>
		<DataTable breakpoint="1150px" data-key="id" responsive-layout="stack" :value="editingCartLines">
			<Column field="productName" :header="$t('cart.table.colName')" :style="{ width: '25%' }">
				<template #body="item">
					<ColumnProductName :item="productNameColumnModel(item.data)" />
				</template>
			</Column>
			<Column key="trainingInfo">
				<template #body="item">
					<ColumnTrainingInfo :item="item.data" />
				</template>
			</Column>

			<Column :header="$t('cart.table.colPrice')" style="width: 35%">
				<template #body="item">
					<div v-if="!isSpinnerVisible(item.data.name)">
						<PriceSummary
							:vm="
								trainingGroup?.isModular
									? modularPriceSummary(item.data)
									: standardPriceSummary(item.data)
							"
						/>
					</div>
					<div v-else-if="isSpinnerVisible(item.data.name)">
						<i class="pi pi-spin pi-spinner" />
					</div>
				</template>
			</Column>
		</DataTable>
		<div v-if="trainingGroup?.isModular" class="ml-auto">
			<p v-if="totalPriceSummary?.totalPrice" class="lead d-flex justify-content-end pr-1">
				<span class="mr-3 text-bold">
					{{ $t("common.priceSummary.totalPrice") }}
				</span>
				<span class="text-bold">
					{{ formatCurrency(totalPriceSummary?.totalPrice) }}
				</span>
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartClientTable from "@/components/commerce/cart/cartClientTable.vue";
import ColumnProductName from "@/components/commerce/cart/columnProductName.vue";
import ColumnTrainingInfo from "@/components/commerce/cart/columnTrainingInfo.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import { CartClient } from "@/types/models/cart/cartClient";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop } from "vue-facing-decorator";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { LocalTrainingType } from "@/types/enum/localTrainingType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { RemoveTrainingProductRequest } from "@/types/generated/Api/removeTrainingProductRequest";
import { TrainingCartLineClient } from "@/types/generated/Api/trainingCartLineClient";
import { TrainingGroupCartLineClient } from "@/types/generated/Api/trainingGroupCartLineClient";
import { sortBy } from "lodash";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { ColumnTrainingInfo, CartClientTable, PriceSummary, ColumnProductName } })
export default class CartTrainingTable extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient>, required: true, default: {} })
	cartLineClient!: CartLineClient;
	@Prop({ type: Boolean, required: true, default: false }) isEditable!: boolean;

	editingCartLines: TrainingCartLineClient[] = [];

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get trainingGroup(): TrainingGroupCartLineClient {
		return this.cartLineClient.trainingGroup;
	}

	get productNameColumnModel() {
		return (trainingCartLine: TrainingCartLineClient) => {
			const { name, brand, icon, iconAltTag } = trainingCartLine;
			return {
				title: name,
				brand,
				icon,
				iconAltTag,
				url: "",
			} as CartLineClient;
		};
	}

	get standardPriceSummary() {
		return (trainingProductCartLine: TrainingCartLineClient) => {
			const { basePrice, subTotal, type, numberOfParticipants } = trainingProductCartLine;
			const additionalLines = new Map<string, number>();
			const standardLines = new Map<string, number>();
			const basePriceLine =
				type === LocalTrainingType.openRoster
					? `${this.$t(`enum.trainingType.${type}`)} (${this.$tc(
							`cart.table.lblParticipantCount`,
							numberOfParticipants
						)})`
					: this.$t(`enum.trainingType.${type}`);
			standardLines.set(basePriceLine, basePrice);
			sortBy(trainingProductCartLine.additionalPrices, "order")
				.filter((x) => x.price)
				.forEach((x) =>
					additionalLines.set(this.$t(`enum.trainingCalculationLineType.${x.type}`), x.price)
				);
			return { subtotal: subTotal, additionalLines, standardLines } as IPriceSummary;
		};
	}

	get modularPriceSummary() {
		return (trainingProductCartLine: TrainingCartLineClient) => {
			const { basePrice, subTotal, numberOfModules, type } = trainingProductCartLine;
			const additionalLines = new Map<string, number>();
			const standardLines = new Map<string, number>();
			standardLines.set(
				`${this.$t(`enum.trainingType.${type}`)} (${this.$tc(`training.lblModuleCount`, numberOfModules)})`,
				basePrice
			);
			sortBy(trainingProductCartLine.additionalPrices, "order")
				.filter((x) => x.price)
				.forEach((x) =>
					additionalLines.set(this.$t(`enum.trainingCalculationLineType.${x.type}`), x.price)
				);
			return { subtotal: subTotal, additionalLines, standardLines } as IPriceSummary;
		};
	}

	get totalPriceSummary() {
		const { totalPrice } = this.trainingGroup;
		return { totalPrice };
	}

	get canChangeConfiguration(): boolean {
		return this.cartLineClient.trainingLineItemIds?.length > 0 && !!this.trainingGroup?.url;
	}

	created(): void {
		this.editingCartLines = sortBy(this.trainingGroup?.trainings, "order");
	}

	onChangeConfigurationClick(): void {
		this.loadingStore.increaseLoadingCount();
		this.openUrl(
			`${this.cartLineClient.url}?lineItemIds=${this.cartLineClient.trainingLineItemIds?.join(",")}`
		);
	}

	onRemoveTrainingFromCartClick(): void {
		this.$confirm.require({
			message: this.$t("cart.dialog.confirmRemoveProduct", [this.trainingGroup?.name]),
			header: this.$t("cart.dialog.confirmRemoveTrainingTitle"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.removeTrainingFromCart({ lineItemIds: this.cartLineClient.trainingLineItemIds });
			},
		});
	}

	protected removeTrainingFromCart(model: RemoveTrainingProductRequest): void {
		this.loadingStore.increaseLoadingCount();

		this.axios
			.post<CartClient>("/api/cart/remove-training", model)
			.then(() => {
				debugger;
				this.setDataLayer.removeFromCart(toFixed(this.cartLineClient.priceSubTotal, 2), [
					{
						id: this.cartLineClient.id,
						name: this.cartLineClient.title,
						productCategory: this.cartLineClient.productCategory,
						priceSubTotal: this.cartLineClient.priceSubTotal,
						priceBase: this.cartLineClient.priceSubTotal,
						brand: this.cartLineClient.brand,
						amount: 1,
						discount: this.cartLineClient.discount,
					},
				]);

				window.location.reload();
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
			});
	}
}
</script>

<style scoped lang="scss">
.cart-training-table {
	padding: 0.75rem 0.75rem 0 0.75rem;
}
</style>
