import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37667e83"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-config-step" }
const _hoisted_2 = {
  key: 0,
  class: "product-config-step-header"
}
const _hoisted_3 = {
  key: 1,
  class: "product-config-step-header"
}
const _hoisted_4 = { class: "product-config-step-title" }
const _hoisted_5 = { class: "product-config-step-content" }
const _hoisted_6 = {
  key: 0,
  class: "product-config-step-options"
}
const _hoisted_7 = {
  key: 1,
  class: "product-config-step-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
        ])),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.$slots.options)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "options", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _renderSlot(_ctx.$slots, "error", {}, undefined, true),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}