import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73bd7cb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "searchBar",
  class: "main-search-bar pl-2 pl-lg-0"
}
const _hoisted_2 = { class: "pt-2 pl-2" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  alt: "Icon",
  height: "28",
  width: "28"
}
const _hoisted_7 = { class: "ml-2" }
const _hoisted_8 = {
  key: 0,
  class: "px-2 pb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.searchCategory,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCategory) = $event)),
      "append-to": "self",
      class: _normalizeClass(["search-category-dropdown", { 'ml-lg-0': _ctx.layoutStore.showSearchBar }]),
      "option-label": "label",
      "option-value": "category",
      options: _ctx.vm.searchCategoryOptions,
      "scroll-height": "300px"
    }, null, 8, ["modelValue", "class", "options"]),
    _createVNode(_component_AutoComplete, {
      ref: "searchInput",
      "append-to": "self",
      class: "mx-2 search-input",
      "complete-on-focus": "",
      "input-id": "mainSearchBarInput",
      "model-value": _ctx.searchString,
      "option-label": "name",
      "panel-class": "search-panel",
      placeholder: _ctx.$t('menu.search_placeholder'),
      "scroll-height": "420px",
      suggestions: _ctx.searchResults,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trackSearch(_ctx.searchString))),
      onComplete: _ctx.onSearch,
      onItemSelect: _ctx.onSearchResultSelected,
      "onUpdate:modelValue": _ctx.updateSearchstring
    }, {
      header: _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createVNode(_component_i18n_t, { keypath: "menu.searchResultHeader" }, {
              query: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(value.name ?? value), 1)
              ]),
              count: _withCtx(() => [
                (!_ctx.hasNoResults)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.searchResult?.totalResults), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ])
        ])
      ]),
      option: _withCtx(({ option }) => [
        (!_ctx.hasNoResults)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "d-flex justify-content-start flex-row px-2 py-1",
              title: option.intro || option.name
            }, [
              (option.hasIconUrl)
                ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_6, null, 512)), [
                    [_directive_lazy, option.iconUrl]
                  ])
                : (_openBlock(), _createBlock(_component_Badge, {
                    key: 1,
                    value: option.type.charAt(0)
                  }, null, 8, ["value"])),
              _createElementVNode("div", _hoisted_7, _toDisplayString(option.name), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_Chip, { class: "p-chip-secondary ml-2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.type), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ], 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        (_ctx.hasNoResults)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("menu.noSearchResults")), 1))
          : _createCommentVNode("", true),
        (_ctx.showDisplayAllResultsButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "p-button-text p-button-secondary ml-2 mb-1",
              label: _ctx.$t('menu.button.showAllSearchResults'),
              onClick: _ctx.onShowAllResultsClick
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model-value", "placeholder", "suggestions", "onComplete", "onItemSelect", "onUpdate:modelValue"])
  ], 512))
}