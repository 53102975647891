<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<h2 v-if="vm.title" class="display-4 mb-1" :class="textAlignmentClass(vm.titleAlignment)">
				{{ vm.title }}
			</h2>
			<p v-if="vm.subtitle" class="lead mb-3" :class="textAlignmentClass(vm.titleAlignment)">
				{{ vm.subtitle }}
			</p>
			<div class="row">
				<div v-for="(item, index) in vm.items" :key="`recentContentItem_${index}`" class="col-12">
					<Card>
						<template #content>
							<img v-lazy="item.image?.url" :alt="item.image?.alt" class="" />
							<div class="d-flex flex-column flex-1 p-2">
								<h3 v-if="item.title" class="mb-1">{{ item.title }}</h3>
								<p class="intro-text text-muted" v-html="item.intro" />
								<div class="mt-auto">
									<LinkButton
										v-if="item.pageUrl"
										class="p-button-link p-button-arrow mt-2"
										:label="vm.buttonText"
										:title="vm.buttonText"
										:url="item.pageUrl"
									/>
								</div>
							</div>
						</template>
					</Card>
				</div>
			</div>
			<LinkButton
				v-if="vm.cta"
				:class="ctaButtonClass(vm.ctaStyle) || 'p-button-arrow p-button-raised p-button-text'"
				:label="vm.cta.text"
				:target="vm.cta.target"
				:title="vm.cta.title"
				:url="vm.cta.url"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { RecentContentBlockViewModel } from "@/types/viewModels/blocks/recentContentBlockViewModel";

@Component
export default class RecentContentBlock extends BaseBlock {
	@Prop({ type: Object as PropType<RecentContentBlockViewModel>, required: true })
	vm!: RecentContentBlockViewModel;
}
</script>

<style scoped lang="scss">
.intro-text {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

:deep(.p-card) {
	padding: 0;
	margin-bottom: 2rem;

	.p-card-body {
		padding: 0;
	}

	.p-card-content {
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		img {
			object-fit: cover;
			height: auto;
			max-width: 100%;
			gap: 1rem;
			@media (min-width: 992px) {
				width: clamp(150px, 25%, 400px);
			}
		}
	}
}
</style>
