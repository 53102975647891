<template>
	<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>

	<div class="row">
		<div class="col-12 mb-1">
			<Message v-if="!licenceProducts?.length" :closable="false" severity="warn">
				{{ $t("myCadac.licenceManagementOverview.message.noLicences") }}
			</Message>
			<Message
				v-if="!licencePanelUnlocked && licenceProducts?.length"
				class="message-with-button"
				:closable="false"
				severity="warn"
			>
				<i18n-t keypath="myCadac.licenceManagementOverview.message.confirmUnlock">
					<Button
						class="p-button-sm p-button-secondary ml-auto"
						:label="$t('common.confirm')"
						@click="unlockPanel()"
					/>
				</i18n-t>
			</Message>
			<BlockUI
				v-if="licenceProducts?.length"
				:base-z-index="1"
				:blocked="!licencePanelUnlocked"
				:class="!licencePanelUnlocked ? 'p-2' : ''"
			>
				<Panel id="panel-softwareLicences" :toggleable="false">
					<template #header>
						<div class="d-flex flex-column">
							<h3 class="mb-1">{{ $t("myCadac.licenceManagementOverview.panel.titleSoftware") }}</h3>
							<p class="mb-0">
								{{ $t("myCadac.licenceManagementOverview.panel.subtitleSoftware") }}
							</p>
						</div>
					</template>
					<DataTable
						v-if="licenceProducts?.length"
						removable-sort
						responsive-layout="stack"
						:value="licenceProducts"
					>
						<Column
							field="productName"
							:header="$t('myCadac.licenceManagementOverview.table.colProduct')"
							:sortable="false"
						>
							<template #body="item">
								<div class="d-flex align-items-center ml-1 ml-lg-0">
									<Avatar
										v-if="item.data.productIcon"
										:alt="item.data.productName"
										class="mr-1 bg-transparent flex-shrink-0"
										:image="item.data.productIconUrl"
										shape="square"
										size="large"
										:title="item.data.productName"
									>
									</Avatar>
									<div class="d-flex flex-column">
										<small class="mb-0 text-muted d-block">
											{{ item.data.productBrand }}
										</small>
										<p class="mb-0 text-bold">
											{{ item.data.productName }}
										</p>
									</div>
								</div>
							</template>
						</Column>
						<Column
							key="licenceAmount"
							:header="$t('myCadac.licenceManagementOverview.table.colLicences')"
						>
							<template #body="item">
								<span :class="isOutOfLicences(item.data) ? 'text-danger' : ''">
									{{ item.data.totalActiveLicences }} / {{ item.data.totalSeats }}
								</span>
							</template>
						</Column>
						<Column key="actions">
							<template #body="item">
								<div class="d-flex justify-content-end">
									<Button
										v-if="showBuyLicencesButton(item.data)"
										class="p-button-success p-button-sm mr-auto mr-lg-1 flex-shrink-0"
										:label="$t('myCadac.licenceManagementOverview.button.buyLicences')"
										@click="
											openUrl(item.data.productPageUrl);
											$event.target.disabled = true;
										"
									/>
									<Button
										class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
										icon="pi pi-pencil"
										:label="
											isSmallScreen
												? $t('myCadac.licenceManagementOverview.button.viewDetails')
												: ''
										"
										:title="$t('myCadac.licenceManagementOverview.button.viewDetails')"
										@click="
											openUrl(
												`${vm.softwareLicenceDetailUrl}?productGroupId=${item.data.id}`
											);
											$event.target.disabled = true;
										"
									/>
								</div>
							</template>
						</Column>
					</DataTable>
				</Panel>
			</BlockUI>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { AxiosResponse } from "axios";
import { Component, Prop } from "vue-facing-decorator";
import { ILicenceManagementOverview } from "@/types/viewModels/myCadac/licenceManagementOverviewViewModel";
import { LicenceProduct } from "@/types/generated/licenceProduct";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { useSessionStorage } from "@vueuse/core";

@Component
export default class LicenceManagementOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ILicenceManagementOverview>,
		required: true,
		default: {},
	})
	vm!: ILicenceManagementOverview;

	licenceProducts: LicenceProduct[] = [];

	isLoading = false;
	licencePanelUnlocked = false;

	canSeeLicences = useSessionStorage("licenceViewUnlocked", false);

	created(): void {
		if (this.canSeeLicences) {
			this.licencePanelUnlocked = true;
			(this.canSeeLicences as unknown as boolean) = false;
		}
		this.licenceProducts = this.vm.licenceProducts;
		//reload from server to make sure there are no cached changes.
		this.getLicenceProducts();
	}

	isOutOfLicences(licenceProduct: LicenceProduct): boolean {
		return licenceProduct.totalSeats <= licenceProduct.totalActiveLicences;
	}

	showBuyLicencesButton(licenceProduct: LicenceProduct): boolean {
		return this.isOutOfLicences(licenceProduct);
	}

	unlockPanel(): void {
		this.licencePanelUnlocked = true;
		(this.canSeeLicences as unknown as boolean) = true;
	}

	confirmUnlockPanel(): void {
		if (this.canSeeLicences) return;
		// Confirm dialog with reverse buttons
		this.$confirm.require({
			header: this.$t("myCadac.licenceManagementOverview.dialog.confirmUnlockLicencesTitle"),
			message: this.$t("myCadac.licenceManagementOverview.dialog.confirmUnlockLicencesMessage"),
			rejectLabel: this.$t("common.yes"),
			acceptLabel: this.$t("common.no"),
			reject: () => {
				this.unlockPanel();
			},
		});
	}

	getLicenceProducts(): void {
		this.isLoading = true;
		this.axios
			.get<LicenceProduct[]>(`/api/licenceProduct/all`)
			.then((result: AxiosResponse<LicenceProduct[]>) => {
				this.licenceProducts = result.data;
				this.$forceUpdate();
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => (this.isLoading = false));
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.message-with-button) {
	.p-message-text {
		display: flex;
		align-items: center;
		flex: 1;
	}
}
</style>
