<template>
	<TrainingOverviewItemCard
		v-if="vm.productType === productTypeEnum.Training"
		:compact="!isCrossSell"
		:is-cross-sell="isCrossSell"
		:item="trainingProduct"
		layout="grid"
	/>
	<ExpertOverviewItemCard
		v-else-if="vm.productType === productTypeEnum.Expert"
		:is-cross-sell="isCrossSell"
		:item="expertProduct"
		layout="grid"
	/>
	<ProductOverviewItemCard v-else :is-cross-sell="isCrossSell" :item="vm" layout="grid" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ExpertOverviewItemCard from "@/components/experts/expertOverviewItemCard.vue";
import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
import TrainingOverviewItemCard from "@/components/training/trainingOverviewItemCard.vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { CollectionItemViewModel } from "@/types/models/software/collectionItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertItemViewModel } from "@/types/models/expert/expertItemViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { PropType } from "vue";
import { SoftwareItemViewModel } from "@/types/models/software/softwareItemViewModel";
import { TrainingProductGroupItemViewModel } from "@/types/models/training/trainingProductGroupItemViewModel";

@Component({
	components: {
		ExpertOverviewItemCard,
		TrainingOverviewItemCard,
		ProductOverviewItemCard,
	},
})
export default class BaseProductOverviewItemCard extends BaseComponent {
	@Prop({
		type: Object as PropType<BaseProductItemViewModel>,
		required: true,
	})
	vm!: BaseProductItemViewModel;

	@Prop({ type: Boolean, default: false, required: false })
	isCrossSell!: boolean;

	get productTypeEnum(): typeof ItemProductType {
		return ItemProductType;
	}

	get expertProduct(): ExpertItemViewModel | undefined {
		return this.vm.productType === ItemProductType.Expert ? (this.vm as ExpertItemViewModel) : undefined;
	}

	get softwareProduct(): SoftwareItemViewModel | undefined {
		return this.vm.productType === ItemProductType.Software ? (this.vm as SoftwareItemViewModel) : undefined;
	}

	get collectionProduct(): CollectionItemViewModel | undefined {
		return this.vm.productType === ItemProductType.Collection
			? (this.vm as CollectionItemViewModel)
			: undefined;
	}

	get trainingProduct(): TrainingProductGroupItemViewModel | undefined {
		return this.vm.productType === ItemProductType.Training
			? (this.vm as TrainingProductGroupItemViewModel)
			: undefined;
	}
}
</script>

<style scoped lang="scss"></style>
