import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21527d93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-page mt-4 mb-2" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "search-results d-flex flex-wrap justify-content-start w-100" }
const _hoisted_4 = { class: "d-flex flex-lg-row flex-column header-left justify-content-start justify-content-lg-start flex-grow-1 flex-lg-grow-0" }
const _hoisted_5 = { class: "p-input-icon-left p-input-icon-right search-bar" }
const _hoisted_6 = {
  key: 0,
  class: "pi pi-spinner spin"
}
const _hoisted_7 = {
  key: 1,
  class: "pi pi-search text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Message = _resolveComponent("Message")!
  const _component_SearchPageResultItemSkeleton = _resolveComponent("SearchPageResultItemSkeleton")!
  const _component_SearchPageResultItem = _resolveComponent("SearchPageResultItem")!
  const _component_DataView = _resolveComponent("DataView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t("searchPage.searchPageResults")), 1),
        _createVNode(_component_DataView, {
          first: (_ctx.filter.page - 1) * _ctx.filter.pageSize,
          layout: "list",
          lazy: true,
          paginator: true,
          rows: parseInt(_ctx.filter.pageSize),
          "total-records": _ctx.searchResult?.totalResults,
          value: _ctx.searchResult?.items,
          onPage: _ctx.onPageChange
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.filter.category,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.category) = $event)),
                  "append-to": "self",
                  class: "search-categories",
                  "option-label": "label",
                  "option-value": "value",
                  options: _ctx.searchCategoryOptions,
                  "scroll-height": "400px",
                  "show-toggle-all": false,
                  onChange: _ctx.onCategoryChange
                }, null, 8, ["modelValue", "options", "onChange"]),
                _createElementVNode("span", _hoisted_5, [
                  (_ctx.isSpinnerVisible('searchPageOnSearch'))
                    ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                    : (_openBlock(), _createElementBlock("i", _hoisted_7)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.filter.query,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.query) = $event)),
                    class: "mr-1",
                    placeholder: _ctx.$t('searchPage.input.searchPlaceholder'),
                    type: "search",
                    onBlur: _ctx.onSearch,
                    onKeyup: _withKeys(_ctx.onSearch, ["enter"]),
                    onSubmit: _ctx.onSearch
                  }, null, 8, ["modelValue", "placeholder", "onBlur", "onKeyup", "onSubmit"]),
                  (_ctx.filter.query)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 2,
                        class: "pi pi-times icon-clear",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)))
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          empty: _withCtx(() => [
            _createVNode(_component_Message, {
              closable: false,
              severity: "warn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("searchPage.noResults")), 1)
              ]),
              _: 1
            })
          ]),
          list: _withCtx((item) => [
            (_ctx.isSpinnerVisible('searchPageOnSearch'))
              ? (_openBlock(), _createBlock(_component_SearchPageResultItemSkeleton, { key: 0 }))
              : (_openBlock(), _createBlock(_component_SearchPageResultItem, {
                  key: 1,
                  item: item.data
                }, null, 8, ["item"]))
          ]),
          _: 1
        }, 8, ["first", "rows", "total-records", "value", "onPage"])
      ])
    ])
  ]))
}