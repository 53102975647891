<template>
	<ProductLayoutSimple>
		<template #header>
			<slot name="breadcrumbs" />
			<div class="py-3">
				<h1 v-if="vm.introTitle">{{ vm.introTitle }}</h1>
				<p class="lead">{{ vm.intro }}</p>
			</div>
		</template>
		<template #configuration>
			<ProductConfiguration class="mb-3">
				<ProductConfigurationStep id="step-walletType">
					<template #error>
						<Message
							v-if="
								!walletProductValidation.get(WalletProductValidationEnum.AtleastOneTypeSelected) &&
								submitted
							"
							:id="`validation-msg-${WalletProductValidationEnum.AtleastOneTypeSelected}`"
							:closable="false"
							severity="error"
						>
							{{ $t("product.message.atleastOneWalletType") }}
						</Message>
					</template>
					<template #header>
						<span class=""> {{ $t("product.lblChooseWalletType") }}</span>
					</template>
					<div class="row">
						<Card
							v-for="(option, index) in depositTypeOptions"
							:key="`walletDepositType_${index}`"
							class="col product-configuration-option-card"
							:class="{ 'p-highlight': option.isSelected, 'p-disabled': isOptionDisabled(option) }"
							@click="option.isSelected = !option.isSelected"
						>
							<template #content>
								<div class="d-flex flex-column align-items-center">
									<font-awesome-icon class="mb-2" :icon="option.icon" size="2x" />
									<b class="mb-2 text-center"> {{ option.label }} </b>
									<Checkbox
										v-model="option.isSelected"
										:binary="true"
										class="mt-auto"
										@click.stop
									/>
								</div>
							</template>
						</Card>
					</div>
				</ProductConfigurationStep>
				<ProductConfigurationStep id="step-walletAmount">
					<template #header>
						<span class=""> {{ $t("product.lblChooseWalletAmount") }}</span>
					</template>
					<Card>
						<template #content>
							<div class="d-flex flex-wrap align-items-center">
								<div class="slider-wrapper flex-1">
									<Slider
										v-model="price"
										:disabled="isBusy"
										:max="maxPrice"
										:min="minPrice"
										:step="minPrice"
										@change="onPriceChange"
									/>
									<div class="slider-annotation">
										<small>{{ formatCurrency(minPrice) }}</small>
										<b>{{ formatCurrency(currentPrice) }}</b>
										<small>{{ formatCurrency(maxPrice) }}</small>
									</div>
								</div>
							</div>
						</template>
					</Card>
				</ProductConfigurationStep>
			</ProductConfiguration>
		</template>
		<hr />
		<div class="w-75 mx-auto">
			<Button
				class="p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1"
				:disabled="isBusy || (submitted && !validateProductConfiguration())"
				:label="$t(`common.addToCart`)"
				@click="addToCart()"
			/>
			<p>
				{{ $t("product.walletDepositDisclaimer") }}
			</p>
		</div>
	</ProductLayoutSimple>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProductConfiguration from "@/components/product/productConfiguration.vue";
import ProductConfigurationOption from "@/components/product/productConfigurationOption.vue";
import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
import ProductLayoutSimple from "@/views/layout/productLayoutSimple.vue";
import Slider from "primevue/slider";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ISelectItem } from "@/types/models/common/selectItem.interface";
import { IWalletProduct } from "@/types/viewModels/commerce/walletProductViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { PropType } from "vue";
import { WalletDepositType } from "@/types/enum/walletDepositType";
import { WalletProductValidation } from "@/types/enum/walletProductValidation";
import { useCartStore } from "@/store/commerce/cartStore";
import { values } from "lodash";
import { WalletProductAddToCartRequest } from "@/types/models/wallet/walletProductAddToCartRequest";
import { WalletProductAddToCartResponse } from "@/types/models/wallet/walletProductAddToCartResponse";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({
	components: {
		Checkbox,
		ProductAddedModal,
		ProductConfiguration,
		ProductConfigurationOption,
		ProductConfigurationStep,
		ProductLayoutSimple,
		Slider,
	},
})
export default class WalletProduct extends BaseComponent {
	@Prop({ type: Object as PropType<IWalletProduct>, required: true, default: {} }) vm!: IWalletProduct;

	quantity = 0;
	price = 0;
	submitted = false;
	depositTypeOptions: ISelectItem[] = [];
	walletProductValidation: Map<WalletProductValidation, boolean> = new Map<WalletProductValidation, boolean>();

	cartStore = useCartStore();

	created(): void {
		this.quantity = this.vm.amount || this.vm.walletVariation?.minQuantity;
		this.price = this.currentPrice;
		this.depositTypeOptions = values(WalletDepositType)
			.filter(Number.isFinite)
			.map((x) => ({
				value: x,
				label: this.$t(`enum.walletDepositType.${x}`),
				isSelected: this.vm.walletDepositTypes?.includes(x as WalletDepositType),
				icon: this.getIconForDepositType(x as WalletDepositType),
			}));
		//Move the unknown option to the end of the array
		const unknownOption = this.depositTypeOptions.shift();
		this.depositTypeOptions.push(unknownOption as ISelectItem);

		this.setDataLayer.viewItem(
			toFixed(this.currentPrice, 2),
			[this.vm.walletVariation],
			this.quantity.toString(),
			this.vm.walletProduct.brand,
			this.vm.walletProduct.name
		);
	}

	get minPrice(): number {
		return (this.vm.walletVariation?.price || 0) * this.vm.walletVariation?.minQuantity;
	}

	get currentPrice(): number {
		return (this.vm.walletVariation?.price || 0) * this.quantity;
	}

	get maxPrice(): number {
		return (this.vm.walletVariation?.price || 0) * this.vm.walletVariation?.maxQuantity;
	}

	get selectedDepositTypes(): WalletDepositType[] {
		return this.depositTypeOptions.filter((x) => x.isSelected).map((x) => x.value as WalletDepositType);
	}

	get WalletProductValidationEnum(): typeof WalletProductValidation {
		return WalletProductValidation;
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm.walletProduct,
				productType: ItemProductType.Other,
			} as unknown as BaseProductItemViewModel,
			crossSellProducts: [],
		};
	}

	get validationErrors(): boolean[] {
		return [...this.walletProductValidation.values()].filter((x) => !x);
	}

	getIconForDepositType(value: WalletDepositType): string[] {
		switch (value) {
			case WalletDepositType.Unknown:
				return ["far", "question"];
			case WalletDepositType.Expert:
				return ["far", "suitcase"];
			case WalletDepositType.Support:
				return ["far", "life-ring"];
			case WalletDepositType.Training:
				return ["far", "computer-mouse"];
			case WalletDepositType.Software:
				return ["far", "hard-drive"];
		}
	}

	isOptionDisabled(option: ISelectItem): boolean {
		return option.value === WalletDepositType.Unknown
			? this.selectedDepositTypes.length > 0 &&
					!this.selectedDepositTypes.includes(WalletDepositType.Unknown)
			: this.selectedDepositTypes.includes(WalletDepositType.Unknown);
	}

	onPriceChange(value: number) {
		const validValue = value > this.maxPrice ? this.maxPrice : value < this.minPrice ? this.minPrice : value;
		this.quantity = validValue / this.minPrice;
	}

	addToCart(): void {
		this.submitted = true;
		if (!this.validateProductConfiguration()) {
			setTimeout(() => this.scrollToFirstValidationError(), 50);
			return;
		}
		this.loadingStore.increaseLoadingCount();

		const model: WalletProductAddToCartRequest = {
			chosenTypes: this.selectedDepositTypes,
			lineItemId: this.vm.lineItemId,
			quantity: this.quantity,
		};

		this.axios
			.post<WalletProductAddToCartResponse>(
				`/api/${this.layoutStore.vm.localizationPrefix}/cart/add-or-update-wallet-deposit`,
				model
			)
			.then((res) => {
				const response: WalletProductAddToCartResponse = res.data;
				if (response.addedToCart) {
					this.cartStore.showModal("ProductAdded");
				} else if (response.cartUpdated) {
					this.loadingStore.increaseLoadingCount();
					this.openUrl(this.layoutStore.vm.cartPageUrl);
				} else {
					this.$toast.add({
						severity: "error",
						summary: this.$t("common.messages.titleError"),
						detail: this.$t("common.messages.addToCartError"),
						life: 3000,
					});
				}

				this.setDataLayer.addToCart(
					toFixed(this.currentPrice, 2),
					[this.vm.walletVariation],
					this.quantity.toString(),
					this.vm.walletProduct.brand,
					this.vm.walletProduct.name
				);
			})
			.catch(() =>
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("common.messages.addToCartError"),
					life: 3000,
				})
			)
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private validateProductConfiguration(): boolean {
		this.walletProductValidation.set(
			WalletProductValidation.AtleastOneTypeSelected,
			this.selectedDepositTypes?.length > 0
		);
		return this.validationErrors.length === 0;
	}
}
</script>

<style scoped lang="scss">
.input-wallet {
	width: clamp(100px, 15%, 175px);
}

.slider-annotation {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.slider-annotation {
		flex-wrap: wrap;

		:nth-child(1) {
			order: 1;
		}

		:nth-child(2) {
			order: 3;
			width: 100%;
			text-align: center;
		}

		:nth-child(3) {
			order: 2;
		}
	}
}

::v-deep(.product-configuration-option-card) {
	margin: 0.5rem;
	border: 1px solid var(--surface-b);
	cursor: pointer;

	&.p-highlight {
		border: 1px solid var(--primary-color);
	}

	&:hover:not(.p-highlight) {
		border: 1px solid var(--surface-d);
	}

	.p-card-body,
	.p-card-content {
		height: 100%;
	}

	.p-card-content {
		display: flex;
		flex-direction: column;
		align-items: center;

		> div {
			height: 100%;
		}
	}
}
</style>
