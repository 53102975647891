import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-492d4696"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader d-flex flex-column justify-content-center align-items-center text-center mx-auto form-group col-md-12 col-lg-10 col-xxl-8"
}
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-group col-md-12 col-lg-10 col-xxl-8" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "mb-0 mr-1 text-bold" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "form-group col-md-12 col-lg-10 col-xxl-8" }
const _hoisted_9 = { class: "form-row" }
const _hoisted_10 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_11 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_12 = { class: "form-row" }
const _hoisted_13 = { class: "form-group col-md-12 col-lg-10 col-xxl-8" }
const _hoisted_14 = { class: "form-row" }
const _hoisted_15 = {
  key: 0,
  class: "form-group col-md-12 col-lg-10 col-xxl-8"
}
const _hoisted_16 = { class: "p-input-icon-right w-100" }
const _hoisted_17 = {
  key: 0,
  class: "pi pi-spin pi-spinner"
}
const _hoisted_18 = { class: "form-row" }
const _hoisted_19 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_20 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_21 = { class: "p-input-icon-right w-100" }
const _hoisted_22 = {
  key: 0,
  class: "pi pi-spin pi-spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_InputWithValidation = _resolveComponent("InputWithValidation")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("form", {
    class: "position-relative",
    onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.formStateChanged && _ctx.formStateChanged(...args)))
  }, [
    (_ctx.isSpinnerVisible('autofill'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressSpinner, {
            "animation-duration": "1.75s",
            "stroke-width": "4"
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.autoComplete)
          ? (_openBlock(), _createBlock(_component_InputWithValidation, {
              key: 0,
              "is-required": _ctx.validations['companyName'].required,
              label: _ctx.$t('common.forms.lblCompanyName'),
              name: "companyName"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_AutoComplete, {
                  modelValue: field.value.value,
                  "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
                  class: _normalizeClass(["w-100", { 'p-invalid': field.errors?.length }]),
                  "min-length": 2,
                  "option-label": "name",
                  suggestions: _ctx.companySuggestions,
                  onBlur: ($event: any) => (field.setTouched(true)),
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.autoFillOptOut())),
                  onComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchCompanies($event.query))),
                  onItemSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSuggestionSelection($event.value.dunsNumber)))
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(option?.name), 1),
                      _createElementVNode("p", _hoisted_6, "in " + _toDisplayString(option?.mainAddress.city), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "class", "suggestions", "onBlur"])
              ]),
              _: 1
            }, 8, ["is-required", "label"]))
          : (_openBlock(), _createBlock(_component_InputWithValidation, {
              key: 1,
              "is-required": _ctx.validations['companyName'].required,
              label: _ctx.$t('common.forms.lblCompanyName'),
              name: "companyName"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_InputText, {
                  modelValue: field.value.value,
                  "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
                  class: _normalizeClass({ 'p-invalid': field.errors?.length }),
                  name: field.name,
                  onBlur: ($event: any) => (field.setTouched(true))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
              ]),
              _: 1
            }, 8, ["is-required", "label"]))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['street'].required,
          label: _ctx.$t('common.forms.lblAddress'),
          name: "street"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              disabled: !_ctx.canEditCompany,
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['postalCode'].required,
          label: _ctx.$t('common.forms.lblPostalCode'),
          name: "postalCode"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              disabled: !_ctx.canEditCompany,
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['city'].required,
          label: _ctx.$t('common.forms.lblCity'),
          name: "city"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              disabled: !_ctx.canEditCompany,
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['countryCode'].required,
          label: _ctx.$t('common.forms.lblCountry'),
          name: "countryCode"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_Dropdown, {
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              disabled: (_ctx.countries?.length === 1 && _ctx.canEditCompany) || !_ctx.canEditCompany,
              "model-value": field.value.value,
              "option-label": "value",
              "option-value": "key",
              options: _ctx.countries,
              onChange: ($event: any) => (field.setTouched(true)),
              "onUpdate:modelValue": 
							(e) => {
								field.value.value = e;
								field.setTouched(true);
								_ctx.formStateChanged();
							}
						
            }, null, 8, ["class", "disabled", "model-value", "options", "onChange", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      (_ctx.useIban)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_InputWithValidation, {
              info: _ctx.$t('iban.infoIcon'),
              "is-required": _ctx.validations['iban'].required,
              label: _ctx.$t('common.forms.lblIban'),
              name: "iban"
            }, {
              default: _withCtx(({ field }) => [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_InputText, {
                    modelValue: field.value.value,
                    "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
                    modelModifiers: { lazy: true, trim: true },
                    class: _normalizeClass({ 'p-invalid': field.errors?.length }),
                    disabled: !_ctx.canEditCompany,
                    name: field.name,
                    onBlur: ($event: any) => {
								_ctx.formatIban(field.value.value);
								field.setTouched(true);
							}
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "name", "onBlur"]),
                  (field.meta.pending)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["info", "is-required", "label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['cocNumber'].required,
          label: _ctx.$t('common.forms.lblCocNumber'),
          name: "cocNumber"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              disabled: !_ctx.canEditCompany,
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_InputWithValidation, {
          info: _ctx.$t('vat.infoIcon'),
          "is-required": _ctx.validations['vatNumber'].required,
          label: _ctx.$t('common.forms.lblVatNumber'),
          name: "vatNumber"
        }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_InputText, {
                modelValue: field.value.value,
                "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
                modelModifiers: { trim: true, lazy: true },
                class: _normalizeClass({ 'p-invalid': field.errors?.length }),
                disabled: !_ctx.canEditCompany,
                name: field.name,
                onBlur: ($event: any) => (field.setTouched(true))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "name", "onBlur"]),
              (field.meta.pending)
                ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["info", "is-required", "label"])
      ])
    ])
  ], 32))
}