import { Directive } from 'vue';

function setLazyImages(el, binding) {
	const imageObserver = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const lazyImage = entry.target as HTMLImageElement;
					if (binding.value) {
						lazyImage.src = binding.value;
						if (!lazyImage?.alt) lazyImage.alt = getImageAlt(lazyImage.src);
						imageObserver.unobserve(el);
					} else if (lazyImage.dataset.src) {
						lazyImage.src = lazyImage.dataset.src;
						if (!lazyImage?.alt) lazyImage.alt = getImageAlt(lazyImage.src);
						imageObserver.unobserve(el);
					}
				}
			});
		},
		{
			root: null,
			rootMargin: '100%',
		}
	);
	imageObserver.observe(el);
}

function getImageAlt(src: string): string {
	const fileNameRegex = /\/?([^/]+\.[a-z0-9]+)$/i;
	const match = src?.match(fileNameRegex);
	if (match) {
		const fileName = match[1];
		return fileName.split('.')[0];
	}
	return 'Image';
}

export const LazyLoadImageDirective: Directive = {
	mounted(el, binding: any) {
		setLazyImages(el, binding);
	},
	updated(el, binding) {
		setLazyImages(el, binding);
	},
};
