<template>
	<div v-if="collapsed && relatedProducts.length > 1" class="advice-section mx-2 py-2 text-center w-100">
		<i18n-t class="text-center" keypath="myCadac.advisorAdvice.adviceAmount" scope="global" tag="p">
			<template #amount>
				<b>{{ relatedProducts.length }}</b>
			</template>
		</i18n-t>
	</div>

	<div
		v-for="(relatedProduct, index) in relatedProducts"
		v-show="!collapsed || relatedProducts.length < 2"
		:key="`nextStepAdvice_${index}`"
		class="col-6 mb-3"
	>
		<div class="advice-section py-2 px-2">
			<div
				v-if="relatedProducts.length > 1"
				class="d-flex flex-row align-items-center justify-content-center mb-2"
			>
				<Avatar :image="relatedProduct.iconUrl" size="large" />
			</div>

			<i18n-t
				class="text-center text-primary font-weight-normal"
				keypath="myCadac.advisorAdvice.tokenAdviceTitle"
				scope="global"
				tag="b"
			>
				<template #product>
					{{ relatedProduct.name }}
				</template>
			</i18n-t>

			<i18n-t class="text-center" keypath="myCadac.advisorAdvice.tokenAdviceMessage" scope="global" tag="p">
				<template #tokenAmount>
					<b>{{ relatedProduct.tokenAmount }}</b>
				</template>
			</i18n-t>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProgressSpinner from "primevue/progressspinner";
import { Component, Prop } from "vue-facing-decorator";
import AdvisorTokenAdvice from "@/components/advisor/advisorTokenAdvice.vue";
import { PropType } from "vue";
import Divider from "primevue/divider";
import { RelatedProductsViewModel } from "@/types/models/advisor/relatedProductsViewModel";

@Component({ components: { AdvisorTokenAdvice, ProgressSpinner, Divider } })
export default class AdvisorTokenAdviceContent extends BaseComponent {
	@Prop({
		type: Object as PropType<RelatedProductsViewModel[]>,
		required: true,
	})
	relatedProducts!: RelatedProductsViewModel[];

	@Prop({
		type: Boolean,
		required: false,
	})
	collapsed!: boolean;
}
</script>

<style scoped lang="scss">
.advice-section {
	display: flex;
	flex-direction: column;
	background-color: rgba(32, 176, 101, 0.06);
	border-radius: 4px;
	height: 100%;
}
</style>
