import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvisorUploadUsageModal = _resolveComponent("AdvisorUploadUsageModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.advisorStore.uploadUsageModalVisible)
      ? (_openBlock(), _createBlock(_component_AdvisorUploadUsageModal, {
          key: 0,
          "api-version": _ctx.vm.apiVersion,
          "results-page-url": _ctx.vm.advisorResultsPageUrl,
          vm: _ctx.vm.uploadModalViewModel,
          onHideModal: _ctx.removeAdvisorModalUrlTag
        }, null, 8, ["api-version", "results-page-url", "vm", "onHideModal"]))
      : _createCommentVNode("", true)
  ], 64))
}