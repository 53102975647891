<template>
	<div class="d-flex align-items-center">
		<i :class="value ? 'pi pi-check text-success mr-1' : 'pi pi-times text-danger mr-1'" />
		<span v-text="value ? $t('common.yes') : $t('common.no')" />
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component
export default class ColumnYesNo extends BaseComponent {
	@Prop({ type: Boolean, required: true, default: false }) value!: boolean;
}
</script>

<style lang="scss" scoped></style>
