<template>
	<div class="cart-service-table">
		<div class="d-flex justify-content-between flex-wrap">
			<h5 class="text-primary">
				{{ microServiceProduct?.name }}
			</h5>
			<div>
				<Button
					v-if="canChangeConfiguration && isEditable"
					class="p-button-secondary p-button-text flex-shrink-0 hidden-print"
					icon="pi pi-cog"
					:label="$t('cart.button.editConfiguration')"
					:title="$t('cart.button.editConfiguration')"
					@click="
						onChangeConfigurationClick();
						$event.target.disabled = true;
					"
				/>
				<Button
					v-if="isEditable"
					class="p-button-text p-button-danger hidden-print flex-shrink-0"
					icon="pi pi-times"
					:label="$t('cart.button.removeItem')"
					:title="$t('cart.button.removeItem')"
					@click="onRemoveFromCartClick()"
				/>
			</div>
		</div>
		<DataTable breakpoint="1150px" data-key="id" responsive-layout="stack" :value="editingCartLines">
			<Column field="productName" :header="$t('cart.table.colName')" :style="{ width: '25%' }">
				<template #body="item">
					<ColumnProductName :item="productNameColumnModel(item.data)" />
				</template>
			</Column>
			<Column />

			<Column :header="$t('cart.table.colPrice')" style="width: 35%">
				<template #body="item">
					<div v-if="!isSpinnerVisible(item.data.name)">
						<PriceSummary :vm="modularPriceSummary(item.data)" />
					</div>
					<div v-else-if="isSpinnerVisible(item.data.name)">
						<i class="pi pi-spin pi-spinner" />
					</div>
				</template>
			</Column>
		</DataTable>
		<div class="ml-auto">
			<p v-if="totalPriceSummary?.totalPrice" class="lead d-flex justify-content-end pr-1">
				<span class="mr-3 text-bold">
					{{ $t("common.priceSummary.totalPrice") }}
				</span>
				<span class="text-bold">
					{{ formatCurrency(totalPriceSummary?.totalPrice) }}
				</span>
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartClientTable from "@/components/commerce/cart/cartClientTable.vue";
import ColumnProductName from "@/components/commerce/cart/columnProductName.vue";

import PriceSummary from "@/components/common/priceSummary.vue";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop } from "vue-facing-decorator";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { RemoveMicroServiceProductRequest } from "@/types/generated/Api/removeMicroServiceProductRequest";
import { MicroServiceProductCartClient } from "@/types/generated/Api/microServiceProductCartLineItem";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { CartClientTable, PriceSummary, ColumnProductName } })
export default class CartMircoServiceTable extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient>, required: true, default: {} })
	cartLineClient!: CartLineClient;
	@Prop({ type: Boolean, required: true, default: false })
	isEditable!: boolean;

	editingCartLines: MicroServiceProductCartClient[] = [];

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get microServiceProduct(): MicroServiceProductCartClient {
		return this.cartLineClient.microServiceProduct;
	}

	get productNameColumnModel() {
		return (CartLine: MicroServiceProductCartClient) => {
			const { name, brand, icon, iconAltTag } = CartLine;
			return {
				title: name,
				brand,
				icon,
				iconAltTag,
				url: "",
			} as CartLineClient;
		};
	}

	get modularPriceSummary() {
		return (ProductCartLine: MicroServiceProductCartClient) => {
			const { totalPrice, modules } = ProductCartLine;
			const numOfModules = modules.length;
			const standardLines = new Map<string, number>();
			const text =
				numOfModules > 1
					? `${this.$t(`service.type.modular`)} (${this.$tc(`cart.lblModuleCount`, numOfModules)})`
					: `${this.$t(`service.type.modular`)} (${this.$t(`cart.lblModuleSingle`)})`;
			standardLines.set(text, totalPrice);
			const subtotal = totalPrice;
			return { subtotal, standardLines } as IPriceSummary;
		};
	}

	get totalPriceSummary() {
		const { totalPrice } = this.microServiceProduct;
		return { totalPrice };
	}

	get canChangeConfiguration(): boolean {
		return this.cartLineClient.microServiceLineItemIds?.length > 0 && !!this.microServiceProduct?.url;
	}

	created(): void {
		this.editingCartLines = [this.microServiceProduct];
	}

	onChangeConfigurationClick(): void {
		this.loadingStore.increaseLoadingCount();
		this.openUrl(
			`${this.cartLineClient.url}?lineItemIds=${this.cartLineClient.microServiceLineItemIds?.join(",")}`
		);
	}

	onRemoveFromCartClick(): void {
		this.$confirm.require({
			message: this.$t("cart.dialog.confirmRemoveProduct", [this.microServiceProduct?.name]),
			header: this.$t("cart.dialog.confirmRemoveServiceTitle"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.removeFromCart({ lineItemIds: this.cartLineClient.microServiceLineItemIds });
			},
		});
	}

	protected removeFromCart(model: RemoveMicroServiceProductRequest): void {
		this.loadingStore.increaseLoadingCount();

		this.axios
			.post("/api/cart/remove-micro-service", model)
			.then(() => {
				this.setDataLayer.removeFromCart(toFixed(this.cartLineClient.price, 2), [
					{
						id: this.cartLineClient.productId,
						name: this.cartLineClient.title,
						productCategory: this.cartLineClient.productCategory,
						priceSubTotal: this.cartLineClient.price,
						priceBase: this.cartLineClient.price,
						brand: this.cartLineClient.brand,
						amount: 1,
						discount: this.cartLineClient.discount,
					},
				]);

				window.location.reload();
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
			});
	}
}
</script>

<style scoped lang="scss">
.cart-service-table {
	padding: 0.75rem 0.75rem 0 0.75rem;
}
</style>
