<template>
	<h1 class="">
		{{ vm.pageTitle }}
	</h1>

	<div class="row">
		<div class="col-12">
			<ExpertBookingOverviewTable :is-expert="isExpert" :vm="vm" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ExpertBookingOverviewTable from "@/components/experts/expertBookingOverviewTable.vue";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IExpertBookingOverview } from "@/types/viewModels/myCadac/expertBookingOverviewViewModel";
import { PropType } from "vue";

@Component({
	components: { ExpertBookingOverviewTable, MyCadacMenu },
})
export default class ExpertBookingOverview extends BaseComponent {
	@Prop({ type: Object as PropType<IExpertBookingOverview>, required: true, default: {} })
	vm!: IExpertBookingOverview;
	@Prop({ type: Boolean, required: false, default: false }) isExpert!: boolean;
}
</script>

<style scoped lang="scss"></style>
