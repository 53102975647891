<template>
	<MainMenuBarMobile v-if="isSmallScreen" />
	<MainMenuBar v-else />
</template>

<script lang="ts">
import MainMenuBar from "@/components/common/menu/mainMenuBar.vue";
import MainMenuBarMobile from "@/components/common/menu/mainMenuBarMobile.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";

@Component({ components: { MainMenuBar, MainMenuBarMobile } })
export default class MainNavBar extends BaseComponent {
	created(): void {
		if (
			this.layoutStore.hasMultipleCompanies &&
			!this.layoutStore.preferredCompany &&
			!this.userProfile?.isImpersonated &&
			this.layoutStore.vm.isDebug
		) {
			this.layoutStore.showModal("ChangeCompany");
		}

		if (this.layoutStore.vm.showRedirect) {
			this.layoutStore.showModal("ChangeRegion");
		}
	}
}
</script>
