import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a666174"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-filters-subheader" }
const _hoisted_2 = { class: "product-filters" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "text-muted ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.filterStore.hasActiveFilters)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "p-button-secondary p-button-text p-0 mb-1",
            icon: "pi pi-filter-slash",
            label: _ctx.$t('common.resetFilters'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetFilters()))
          }, null, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterStore.optionFilters, (filter) => {
        return (_openBlock(), _createBlock(_component_Panel, {
          collapsed: _ctx.filterCollapsed[_ctx.filterId(filter)],
          "onUpdate:collapsed": ($event: any) => ((_ctx.filterCollapsed[_ctx.filterId(filter)]) = $event),
          key: filter,
          class: "product-filter-item"
        }, {
          header: _withCtx(() => [
            _createElementVNode("p", {
              class: "p-link lead mb-0 w-100",
              onClick: ($event: any) => (_ctx.toggleFilterCollapse(filter))
            }, _toDisplayString(filter.title || _ctx.$t(`enum.overviewFilterCategory.${filter.category}`)), 9, _hoisted_3)
          ]),
          icons: _withCtx(() => [
            _createElementVNode("button", {
              class: "p-panel-header-icon p-link mr-2",
              onClick: ($event: any) => (_ctx.toggleFilterCollapse(filter))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.isFilterCollapsed(filter) ? 'pi pi-chevron-down' : 'pi pi-chevron-up')
              }, null, 2)
            ], 8, _hoisted_4)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option,
                class: "product-filter-option p-field-checkbox form-group"
              }, [
                _createVNode(_component_Checkbox, {
                  modelValue: option.isActive,
                  "onUpdate:modelValue": ($event: any) => ((option.isActive) = $event),
                  id: `filter-${_ctx.filterId(filter)}-${option.label}`,
                  binary: "",
                  disabled: !option.isAvailable,
                  onChange: ($event: any) => (_ctx.filtersChanged(filter))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "id", "disabled", "onChange"]),
                (option.iconUrl)
                  ? (_openBlock(), _createBlock(_component_Avatar, {
                      key: 0,
                      class: "ml-2 option-icon",
                      image: option.iconUrl,
                      shape: "square",
                      onClick: ($event: any) => {
						option.isActive = !option.isActive;
						_ctx.filtersChanged(filter);
					}
                    }, null, 8, ["image", "onClick"]))
                  : _createCommentVNode("", true),
                _createElementVNode("label", {
                  class: _normalizeClass(["ml-2 mb-0 p-link", option.isActive ? 'font-weight-bold' : 'font-weight-normal']),
                  for: `filter-${_ctx.filterId(filter)}-${option.label}`
                }, _toDisplayString(option.label), 11, _hoisted_5),
                _createElementVNode("span", _hoisted_6, "(" + _toDisplayString(option.count) + ")", 1)
              ]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["collapsed", "onUpdate:collapsed"]))
      }), 128))
    ])
  ], 64))
}