<template>
	<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>

	<div class="row">
		<div class="col-12">
			<DataTable
				v-if="tableData.length"
				auto-layout
				class="mt-2"
				:paginator="showPagination"
				removable-sort
				responsive-layout="stack"
				:row-class="getRowClass"
				:rows="maxRowsToShow"
				:value="tableData"
			>
				<Column
					key="orderNumber"
					field="orderNumber"
					:header="$t('myCadac.orderOverview.table.colOrderNumber')"
					:sortable="false"
				>
					<template #body="item">
						<a
							v-if="canGenerateInvoice(item.data)"
							class="text-bold"
							:href="item.data.urlToGenerateInvoice"
						>
							{{ item.data.orderNumber }}
						</a>
						<span v-else>
							{{ item.data.orderNumber }}
						</span>
					</template>
				</Column>
				<Column
					key="customerName"
					field="customerName"
					:header="$t('myCadac.orderOverview.table.colOrganisation')"
					:sortable="false"
				>
				</Column>
				<Column
					key="invoiceDateTime"
					field="invoiceDateTime"
					:header="$t('myCadac.orderOverview.table.colInvoiceDate')"
					:sortable="true"
				>
					<template #body="item">
						<span>{{ formatDate(item.data.invoiceDateTime) }}</span>
					</template>
				</Column>
				<Column
					key="isPaid"
					field="isPaid"
					:header="$t('myCadac.orderOverview.table.colPaid')"
					:sortable="true"
				>
					<template #body="item">
						<div
							v-if="layoutStore.vm.isImpersonatedByCadacSales && item.data.paymentMethod"
							class="d-flex align-items-center"
							:title="'Betaalmethode: ' + item.data.paymentMethod"
						>
							<i
								:class="
									item.data.isPaid
										? 'pi pi-check text-success mr-1'
										: 'pi pi-times text-danger mr-1'
								"
							/>
							<span>
								{{ item.data.isPaid ? $t("common.yes") : $t("common.no") }}
							</span>
						</div>
						<div v-else class="d-flex align-items-center">
							<i
								:class="
									item.data.isPaid
										? 'pi pi-check text-success mr-1'
										: 'pi pi-times text-danger mr-1'
								"
							/>

							<span>
								{{ item.data.isPaid ? $t("common.yes") : $t("common.no") }}
							</span>
						</div>
					</template>
				</Column>
				<Column
					v-if="showTotalColumn"
					key="total"
					field="total"
					:header="$t('myCadac.orderOverview.table.colTotalAmount')"
					:sortable="true"
				>
					<template #body="item">
						<span
							v-if="layoutStore.vm.isImpersonatedByCadacSales && item.data.orderStatus"
							:title="'Orderstatus: ' + item.data.orderStatus"
						>
							{{ formatCurrency(item.data.total) }}
						</span>
						<span v-else-if="!item.data.isInvoicedByPartner">
							{{ formatCurrency(item.data.total) }}
						</span>
						<span v-else>-</span>
					</template>
				</Column>

				<Column key="invoice" :header="$t('myCadac.orderOverview.table.lblInvoice')">
					<template #body="item">
						<Button
							v-if="canSeeInvoiceForOrder(item.data)"
							class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text"
							icon="pi pi-file-pdf"
							@click="viewPdf(item.data.orderNumber)"
						/>
						<span v-if="item.data.isInvoicedByPartner">
							{{ $t("myCadac.orderOverview.table.lblInvoicedByPartner") }}
						</span>
						<span v-if="item.data.isCancelled">
							{{ $t("myCadac.orderOverview.table.lblCancelled") }}
						</span>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IOrderOverview } from "@/types/viewModels/myCadac/orderOverviewViewModel";
import { OrderInfo } from "@/types/models/order/orderInfo";
import { PropType } from "vue";

@Component
export default class OrderOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<IOrderOverview>,
		required: true,
		default: {},
	})
	vm!: IOrderOverview;

	maxRowsToShow = 15;
	cancelReason!: string;

	get tableData(): OrderInfo[] {
		return this.vm.orders;
	}

	get showPagination(): boolean {
		return this.tableData?.length > this.maxRowsToShow;
	}

	get showTotalColumn(): boolean {
		return this.vm.orders?.some((x) => !x.isInvoicedByPartner);
	}

	get canSeeInvoiceForOrder(): (order: OrderInfo) => boolean {
		return (order: OrderInfo) => {
			return !order.isCancelled && !!order.invoiceNumber && !order.isInvoicedByPartner;
		};
	}

	get canGenerateInvoice(): (order: OrderInfo) => boolean {
		return (order: OrderInfo) => {
			if (!order.urlToGenerateInvoice || order.isInvoicedByPartner) return false;
			return this.layoutStore.vm.isImpersonatedByCadacSales || !!this.userProfile?.isCadacSales;
		};
	}

	getRowClass(order: OrderInfo): string {
		return order.isCancelled ? "disabled" : "";
	}

	viewPdf(orderNumber: string): void {
		this.openUrl(`${window.location.href}GetPDF/?ordernumber=${orderNumber}`, "_blank");
	}
}
</script>
