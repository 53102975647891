import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fa42f4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "text-bold price-label" }
const _hoisted_3 = { class: "text-bold" }
const _hoisted_4 = { class: "text-bold price-label" }
const _hoisted_5 = { class: "text-bold flex-shrink-0" }
const _hoisted_6 = { class: "price-label" }
const _hoisted_7 = { class: "prefix-plus flex-shrink-0" }
const _hoisted_8 = {
  key: 1,
  class: "text-success"
}
const _hoisted_9 = {
  key: 0,
  class: "price-label"
}
const _hoisted_10 = {
  key: 1,
  class: "price-label"
}
const _hoisted_11 = { class: "prefix-min flex-shrink-0" }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "price-label" }
const _hoisted_14 = { class: "prefix-min flex-shrink-0" }
const _hoisted_15 = {
  key: 0,
  class: "price-label"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 4 }
const _hoisted_18 = {
  key: 0,
  class: "text-bold"
}
const _hoisted_19 = { class: "price-label" }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "price-label" }
const _hoisted_22 = { class: "prefix-plus flex-shrink-0" }
const _hoisted_23 = { key: 2 }
const _hoisted_24 = { class: "text-success" }
const _hoisted_25 = {
  key: 0,
  class: "price-label"
}
const _hoisted_26 = {
  key: 1,
  class: "price-label"
}
const _hoisted_27 = { class: "prefix-min flex-shrink-0" }
const _hoisted_28 = { class: "price-label" }
const _hoisted_29 = { class: "prefix-min flex-shrink-0" }
const _hoisted_30 = {
  key: 3,
  class: "text-success text-bold"
}
const _hoisted_31 = {
  key: 0,
  class: "price-label"
}
const _hoisted_32 = {
  key: 1,
  class: "price-label"
}
const _hoisted_33 = { class: "prefix-min flex-shrink-0" }
const _hoisted_34 = {
  key: 4,
  class: "text-success text-bold"
}
const _hoisted_35 = {
  key: 0,
  class: "price-label"
}
const _hoisted_36 = {
  key: 1,
  class: "price-label"
}
const _hoisted_37 = { class: "prefix-min flex-shrink-0" }
const _hoisted_38 = {
  key: 0,
  class: "price-label"
}
const _hoisted_39 = {
  key: 1,
  class: "price-label"
}
const _hoisted_40 = { key: 6 }
const _hoisted_41 = { class: "text-muted" }
const _hoisted_42 = { class: "price-label" }
const _hoisted_43 = { class: "prefix-plus flex-shrink-0" }
const _hoisted_44 = { key: 7 }
const _hoisted_45 = { class: "text-muted" }
const _hoisted_46 = { class: "price-label" }
const _hoisted_47 = { class: "prefix-plus flex-shrink-0" }
const _hoisted_48 = {
  key: 8,
  class: "pt-3"
}
const _hoisted_49 = { class: "text-primary" }
const _hoisted_50 = { class: "price-label" }
const _hoisted_51 = { class: "text-bold flex-shrink-0" }
const _hoisted_52 = { class: "loyaltyCashback" }
const _hoisted_53 = { class: "text-bold price-label" }
const _hoisted_54 = { class: "text-bold prefix-min flex-shrink-0" }
const _hoisted_55 = {
  key: 9,
  class: "text-primary"
}
const _hoisted_56 = { class: "price-label" }
const _hoisted_57 = {
  key: 10,
  class: "text-muted"
}
const _hoisted_58 = { key: 0 }
const _hoisted_59 = { key: 1 }
const _hoisted_60 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.basePrice)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("common.priceSummary.basePrice")), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatCurrency(_ctx.vm.basePrice)), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.standardLines?.entries(), ([key, value], index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: index,
        class: ""
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(key), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatCurrency(value)), 1)
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.additionalLines?.entries(), ([key, value], index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: index,
        class: "text-muted"
      }, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(key), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatCurrency(value)), 1)
      ]))
    }), 128)),
    (_ctx.vm.discount)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
          (_ctx.layoutStore.isAuthenticated)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("common.priceSummary.personalDiscount")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("common.priceSummary.storeDiscount")), 1)),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatCurrency(_ctx.vm.discount)), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.vm.totalDiscount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.discountLines?.entries(), ([key, value], index) => {
            return (_openBlock(), _createElementBlock("p", {
              key: index,
              class: "text-success"
            }, [
              _createElementVNode("span", _hoisted_13, _toDisplayString(key), 1),
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatCurrency(value)), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (
	   _ctx.vm.subtotal &&
	   ((_ctx.vm.basePrice>
		0 && _ctx.vm.basePrice !== _ctx.vm.subtotal) ||
		_ctx.vm.additionalLines?.size ||
		_ctx.vm.discountLines?.size)
		)
      ? (_openBlock(), _createElementBlock("p", {
          key: 3,
          class: _normalizeClass(["text-bold", { 'line-discount': _ctx.vm.discount || _ctx.vm.additionalLines?.size }])
        }, [
          (_ctx.layoutStore.isAuthenticated && _ctx.vm.discount)
            ? (_openBlock(), _createElementBlock("b", _hoisted_15, _toDisplayString(_ctx.$t("common.priceSummary.yourPrice")), 1))
            : (_openBlock(), _createElementBlock("b", _hoisted_16, _toDisplayString(_ctx.$t("common.priceSummary.subtotal")), 1)),
          _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.vm.subtotal)), 1)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.vm.totalDiscount || _ctx.vm.totalInstallPrice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          (_ctx.vm.totalBasePrice)
            ? (_openBlock(), _createElementBlock("p", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("common.priceSummary.totalBasePrice")), 1),
                _createElementVNode("span", null, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalBasePrice)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.vm.totalInstallPrice)
            ? (_openBlock(), _createElementBlock("p", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("common.priceSummary.installHelp")), 1),
                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalInstallPrice)), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.vm.totalCouponDiscount && _ctx.vm.totalDiscount - _ctx.vm.totalCouponDiscount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("p", _hoisted_24, [
                  (_ctx.layoutStore.isAuthenticated)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.$t("common.priceSummary.personalDiscount")), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.$t("common.priceSummary.storeDiscount")), 1)),
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalDiscount - _ctx.vm.totalCouponDiscount)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.discountLines?.entries(), ([key, value], index) => {
            return (_openBlock(), _createElementBlock("p", {
              key: index,
              class: "text-success"
            }, [
              _createElementVNode("span", _hoisted_28, _toDisplayString(key), 1),
              _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.formatCurrency(value)), 1)
            ]))
          }), 128)),
          (_ctx.vm.totalCouponDiscount)
            ? (_openBlock(), _createElementBlock("p", _hoisted_30, [
                (_ctx.layoutStore.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t("common.priceSummary.totalPersonalDiscount")), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t("common.priceSummary.totalStandardDiscount")), 1)),
                _createElementVNode("span", _hoisted_33, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalDiscount)), 1)
                ])
              ]))
            : (_ctx.vm.totalDiscount)
              ? (_openBlock(), _createElementBlock("p", _hoisted_34, [
                  (_ctx.layoutStore.isAuthenticated)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_ctx.$t("common.priceSummary.personalDiscount")), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.$t("common.priceSummary.storeDiscount")), 1)),
                  _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalDiscount)), 1)
                ]))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.vm.totalPersonalPrice)
      ? (_openBlock(), _createElementBlock("p", {
          key: 5,
          class: _normalizeClass(["text-bold", { 'line-discount': _ctx.vm.totalDiscount, 'mt-2': !_ctx.vm.totalDiscount }])
        }, [
          _createElementVNode("b", null, [
            (_ctx.layoutStore.isAuthenticated && _ctx.vm.totalDiscount)
              ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.$t("common.priceSummary.yourPrice")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_ctx.$t("common.priceSummary.subtotal")), 1))
          ]),
          _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalPersonalPrice)), 1)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.vm.vatPercentage && _ctx.vm.vatAmount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
          _createElementVNode("p", _hoisted_41, [
            _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("common.priceSummary.vatPercentage", [_ctx.vm.vatPercentage])) + " " + _toDisplayString(_ctx.$t("common.vat")), 1),
            _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.formatCurrency(_ctx.vm.vatAmount)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.vm.creditCardSurchargePercentage && _ctx.vm.creditCardSurchargeAmount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
          _createElementVNode("p", _hoisted_45, [
            _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t("common.priceSummary.creditCardSurcharge", [_ctx.vm.creditCardSurchargePercentage])), 1),
            _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.formatCurrency(_ctx.vm.creditCardSurchargeAmount)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.vm.applyAutodeskWallet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
          _createElementVNode("p", _hoisted_49, [
            _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.$t("common.priceSummary.total")), 1),
            _createElementVNode("span", _hoisted_51, [
              _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalPrice)), 1)
            ])
          ]),
          _createElementVNode("p", _hoisted_52, [
            _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t("common.priceSummary.cashback")), 1),
            _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.formatCurrency(_ctx.vm.maxAutodeskWalletToSpend)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.vm.totalPrice !== undefined)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_55, [
          _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t("common.priceSummary.totalPrice")) + " " + _toDisplayString(_ctx.vm.vatPercentage > 0 ? _ctx.$t("common.inclVat") : ""), 1),
          _createElementVNode("span", null, [
            _createElementVNode("b", null, _toDisplayString(_ctx.vm.applyAutodeskWallet ? _ctx.formatCurrency(_ctx.vm.totalPrice - _ctx.vm.maxAutodeskWalletToSpend)  : _ctx.formatCurrency(_ctx.vm.totalPrice)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.vm.totalPrice > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_57, [
          (_ctx.vm.vatSituation === _ctx.vatSituation.VatZeroPercent)
            ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(_ctx.$t("common.priceSummary.vatPercentageZero")), 1))
            : (_ctx.vm.vatSituation === _ctx.vatSituation.ReverseVat)
              ? (_openBlock(), _createElementBlock("span", _hoisted_59, _toDisplayString(_ctx.$t("common.priceSummary.vatReverseCharged")), 1))
              : (_ctx.vm.vatSituation === _ctx.vatSituation.NoVat)
                ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(_ctx.$t("common.priceSummary.noVat")), 1))
                : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}