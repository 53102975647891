import httpClient from '@/types/helpers/apiHelper';
import { AxiosResponse } from 'axios';
import { GetCalculatedAdviceResponse } from '@/types/models/advisor/getCalculatedAdviceResponse';
import { InsightViewModel } from '@/types/models/advisor/insightViewModel';
import { PollAdviceCalculationResponse } from '@/types/models/advisor/pollAdviceCalculationResponse';
import { defineStore } from 'pinia';
import { AdvisorProductViewModel } from '@/types/models/advisor/productViewModel';
import { IDropdownItem } from '@/types/models/common/dropdownItem.interface';
import { PollProcessingResponse } from '@/types/models/advisor/pollProcessingResponse';
import { OverviewViewModel } from '@/types/models/advisor/OverviewViewModel';
import { ProductAdviceViewModel } from '@/types/models/advisor/productAdviceViewModel';
import { TokenAdviceViewModel } from '@/types/models/advisor/tokenAdviceViewModel';
import { Log } from '@/types/helpers/logHelper';

interface AdvisorStoreState {
	uploadUsageModalVisible: boolean;
	insight: InsightViewModel | undefined;
	overview: OverviewViewModel | undefined;
	productAdvices: ProductAdviceViewModel[] | undefined;
	tokenAdvice: TokenAdviceViewModel | undefined;
	insightProductFilter: string[];
	isAccessRestricted: boolean;
}

export type AdvisorModalType = 'UploadUsage';

export const useAdvisorStore = defineStore('advisor', {
	state: () =>
		({
			uploadUsageModalVisible: false,
			insightProductFilter: [] as string[],
			insight: undefined,
			overview: undefined,
			productAdvices: undefined,
			tokenAdvice: undefined,
			isAccessRestricted: true,
		}) as AdvisorStoreState,
	getters: {
		insightProductFilterOptions(): IDropdownItem[] {
			return (
				this.insight?.products?.map((x) => ({
					value: x.name,
					label: x.name,
				})) || []
			);
		},
		hasInsightProductsToShow(): boolean {
			return this.insightProductsToShow?.length > 0;
		},
		hasAdviceToShow(): boolean {
			return !!this.productAdvices?.length;
		},
		hasTokenAdviceToShow(): boolean {
			return !!this.tokenAdvice;
		},
		insightProductsToShow(state: AdvisorStoreState): AdvisorProductViewModel[] {
			if (state.insightProductFilter.length <= 0) return state.insight?.products || [];
			else return state.insight?.products?.filter((x) => state.insightProductFilter.includes(x.name)) || [];
		},
	},
	actions: {
		showModal(type: AdvisorModalType) {
			switch (type) {
				case 'UploadUsage':
					this.uploadUsageModalVisible = true;
					break;
			}
		},
		hideModal(type: AdvisorModalType) {
			switch (type) {
				case 'UploadUsage':
					this.uploadUsageModalVisible = false;
					break;
			}
		},

		async getAdvice(adviceId: string, adviceLogId: string, apiVersion: string): Promise<void> {
			const queryParams = new URLSearchParams();
			queryParams.set('adviceId', adviceId);
			queryParams.set('adviceLogId', adviceLogId);
			queryParams.set('apiVersion', apiVersion);

			const response: AxiosResponse<GetCalculatedAdviceResponse> =
				await httpClient.get<GetCalculatedAdviceResponse>('/api/advisor/get-advice?' + queryParams, {
					requestId: 'getAdvice',
				});
			Log.info('getAdvice', response);

			this.overview = response.data?.advisorResult.overview;
			this.insight = response.data?.advisorResult.insight;
			this.productAdvices = response.data?.advisorResult.productAdvices;
			this.tokenAdvice = response.data?.advisorResult.tokenAdvice;
			this.isAccessRestricted = response.data?.isAccessRestricted;

			Promise.resolve(true);
		},

		async pollAdviceCalculation(adviceLogId: string): Promise<PollAdviceCalculationResponse> {
			const queryParams = new URLSearchParams();
			queryParams.set('adviceLogId', adviceLogId);
			const response: AxiosResponse<PollAdviceCalculationResponse> =
				await httpClient.get<PollAdviceCalculationResponse>(
					'/api/advisor/poll-advice-calculation?' + queryParams,
					{
						requestId: 'pollAdviceCalculation',
					}
				);
			Log.info('pollAdviceCalculation', response);

			return response.data;
		},
		async pollInsightProcessing(processingLogId: string): Promise<PollProcessingResponse> {
			const queryParams = new URLSearchParams();
			queryParams.set('processingLogId', processingLogId);
			const response: AxiosResponse<PollProcessingResponse> = await httpClient.get<PollProcessingResponse>(
				'/api/advisor/poll-processing?' + queryParams,
				{
					requestId: 'pollInsightProcessing',
				}
			);

			return response.data;
		},
	},
});
