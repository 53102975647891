import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "lead mb-2" }
const _hoisted_3 = {
  class: "pb-3",
  onsubmit: "return false;"
}
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = { class: "form-group col-12" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "form-group col-12" }
const _hoisted_9 = {
  key: 2,
  class: "p-error"
}
const _hoisted_10 = {
  key: 0,
  class: "form-row"
}
const _hoisted_11 = { class: "form-group col-12" }
const _hoisted_12 = {
  key: 0,
  class: "p-error"
}
const _hoisted_13 = {
  key: 1,
  class: "p-field-checkbox form-group mt-1"
}
const _hoisted_14 = {
  class: "ml-2 mb-0",
  for: "sendEmail"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "form-row" }
const _hoisted_17 = { class: "form-group col-12" }
const _hoisted_18 = {
  key: 0,
  class: "p-error"
}
const _hoisted_19 = { class: "form-row" }
const _hoisted_20 = { class: "form-group col-12" }
const _hoisted_21 = {
  key: 0,
  class: "p-chips-token-label"
}
const _hoisted_22 = {
  key: 1,
  class: "p-chips-token-label text-danger"
}
const _hoisted_23 = {
  key: 0,
  class: "p-error"
}
const _hoisted_24 = { class: "form-row" }
const _hoisted_25 = { class: "form-group col-12" }
const _hoisted_26 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onCancel()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.canMakeSalesOpportunity
						? _ctx.$t("cart.dialog.shareCartTitleImpersonated")
						: _ctx.$t("cart.dialog.shareCartTitle")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.canMakeSalesOpportunity ? _ctx.$t('common.save') : _ctx.$t('common.send'),
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.canMakeSalesOpportunity
					? _ctx.$t("cart.dialog.shareCartHelpTextImpersonated")
					: _ctx.$t("cart.dialog.shareCartHelpText")), 1),
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['cartShareForm'].title?.required })
            }, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblTitle")), 3),
            _createVNode(_component_InputText, {
              modelValue: _ctx.v$['cartShareForm'].title.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['cartShareForm'].title.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['cartShareForm'].title) }),
              readonly: !_ctx.vm.allowChooseSalesOpportunity,
              type: "text"
            }, null, 8, ["modelValue", "class", "readonly"]),
            (_ctx.shouldShowError(_ctx.v$['cartShareForm'].title))
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$["cartShareForm"].title?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['cartShareForm'].salesOpportunityName?.required })
            }, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblOpportunityName")), 3),
            (_ctx.canMakeSalesOpportunity)
              ? (_openBlock(), _createBlock(_component_AutoComplete, {
                  key: 0,
                  modelValue: _ctx.v$['cartShareForm'].salesOpportunityName.$model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['cartShareForm'].salesOpportunityName.$model) = $event)),
                  "append-to": "self",
                  class: "d-inline",
                  "complete-on-focus": true,
                  "option-label": "name",
                  placeholder: _ctx.$t('cart.dialog.salesOpportunityName_placeholder'),
                  suggestions: _ctx.salesOpportunitySuggestions,
                  onComplete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSearchTrigger($event))),
                  onItemSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSalesOpportunitySelected($event)))
                }, null, 8, ["modelValue", "placeholder", "suggestions"]))
              : (_openBlock(), _createBlock(_component_InputText, {
                  key: 1,
                  modelValue: _ctx.v$['cartShareForm'].salesOpportunityName.$model,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$['cartShareForm'].salesOpportunityName.$model) = $event)),
                  class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['cartShareForm'].salesOpportunityName) }),
                  readonly: "",
                  type: "text"
                }, null, 8, ["modelValue", "class"])),
            (_ctx.shouldShowError(_ctx.v$['cartShareForm'].salesOpportunityName))
              ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.v$["cartShareForm"].salesOpportunityName?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.canMakeSalesOpportunity)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", {
                  class: _normalizeClass({ required: _ctx.v$['cartShareForm'].responsibleSales?.required })
                }, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblResponsibleSales")), 3),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.v$['cartShareForm'].responsibleSales.$model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$['cartShareForm'].responsibleSales.$model) = $event)),
                  "option-label": "label",
                  "option-value": "value",
                  options: _ctx.responsibleSalesOptions,
                  placeholder: _ctx.$t('cart.dialog.responsibleSales_placeholder')
                }, null, 8, ["modelValue", "options", "placeholder"]),
                (_ctx.shouldShowError(_ctx.v$['cartShareForm'].responsibleSales))
                  ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.v$["cartShareForm"].responsibleSales?.required?.$message), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.canMakeSalesOpportunity)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_Checkbox, {
                modelValue: _ctx.v$['cartShareForm'].sendEmail.$model,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$['cartShareForm'].sendEmail.$model) = $event)),
                id: "sendEmail",
                binary: true
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblSendEmail")), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.v$['cartShareForm'].sendEmail?.$model)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("label", {
                    class: _normalizeClass({ required: _ctx.v$['cartShareForm'].fromEmail?.required })
                  }, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblSenderEmail")), 3),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$['cartShareForm'].fromEmail.$model,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.v$['cartShareForm'].fromEmail.$model) = $event)),
                    class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['cartShareForm'].fromEmail) }),
                    readonly: _ctx.userProfile?.isImpersonated || _ctx.userProfile?.isImpersonationAllowed,
                    type: "text"
                  }, null, 8, ["modelValue", "class", "readonly"]),
                  (_ctx.shouldShowError(_ctx.v$['cartShareForm'].fromEmail))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_18, _toDisplayString(_ctx.v$["cartShareForm"].fromEmail?.email?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", {
                    class: _normalizeClass({ required: _ctx.v$['cartShareForm'].toEmail?.required })
                  }, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblReceiverEmail")), 3),
                  _createVNode(_component_Chips, {
                    modelValue: _ctx.v$['cartShareForm'].toEmail.$model,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.v$['cartShareForm'].toEmail.$model) = $event)),
                    "add-on-blur": true,
                    "allow-duplicate": false,
                    class: _normalizeClass(["d-inline", { 'p-invalid': _ctx.shouldShowError(_ctx.v$['cartShareForm'].toEmail) }]),
                    onAdd: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onToEmailAdded($event)))
                  }, {
                    chip: _withCtx((item) => [
                      (_ctx.validToEmailAddresses.includes(item.value))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(item.value), 1))
                        : (_openBlock(), _createElementBlock("s", _hoisted_22, _toDisplayString(item.value), 1))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "class"]),
                  (_ctx.shouldShowError(_ctx.v$['cartShareForm'].toEmail))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_23, _toDisplayString(_ctx.v$["cartShareForm"].toEmail?.oneValidEmailRequired?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("label", {
                    class: _normalizeClass({ required: _ctx.v$['cartShareForm'].description?.required })
                  }, _toDisplayString(_ctx.$t("cart.dialog.shareCartLblDescription")), 3),
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.v$['cartShareForm'].description.$model,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.v$['cartShareForm'].description.$model) = $event)),
                    class: _normalizeClass(["d-inline", { 'p-invalid': _ctx.shouldShowError(_ctx.v$['cartShareForm'].description) }]),
                    rows: "5"
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.shouldShowError(_ctx.v$['cartShareForm'].description))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_26, _toDisplayString(_ctx.v$["cartShareForm"].description?.required?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}