<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { BlockBackgroundColor } from "@/types/enum/blockBackgroundColor";
import { ComponentBase } from "vue-facing-decorator";
import { CtaButtonStyle } from "@/types/enum/ctaButtonStyle";
import { HorizontalAlignment } from "@/types/enum/horizontalAlignment";

@ComponentBase({ name: "BaseBlock" })
export default class BaseBlock extends BaseComponent {
	get blockBackgroundColor(): typeof BlockBackgroundColor {
		return BlockBackgroundColor;
	}

	get ctaButtonStyle(): typeof CtaButtonStyle {
		return CtaButtonStyle;
	}

	get horizontalAlignment(): typeof HorizontalAlignment {
		return HorizontalAlignment;
	}

	get textAlignmentClass(): (alignment: HorizontalAlignment) => string {
		return (alignment: HorizontalAlignment) => {
			return `text-${HorizontalAlignment[alignment].toLowerCase()}`;
		};
	}

	get blockBackgroundClass(): (bgColor: BlockBackgroundColor) => string {
		return (bgColor: BlockBackgroundColor) => {
			switch (bgColor) {
				case BlockBackgroundColor.Green:
					return "bg-success";
				case BlockBackgroundColor.Grey:
					return "surface-b";
				case BlockBackgroundColor.Pink:
					return "surface-e";
				case BlockBackgroundColor.Purple:
					return "bg-primary";
				case BlockBackgroundColor.White:
				default:
					return "";
			}
		};
	}

	get ctaButtonClass(): (btnStyle: CtaButtonStyle) => string {
		return (btnStyle: CtaButtonStyle) => {
			switch (btnStyle) {
				case CtaButtonStyle.WhiteArrow:
					return "p-button-text p-button-raised p-button-arrow";
				case CtaButtonStyle.White:
					return "p-button-text p-button-raised";
				case CtaButtonStyle.GreenArrow:
					return "p-button-success p-button-arrow";
				case CtaButtonStyle.Green:
					return "p-button-success";
				case CtaButtonStyle.PurpleArrow:
					return "p-button-primary p-button-arrow";
				case CtaButtonStyle.Purple:
					return "p-button-primary";
				case CtaButtonStyle.LinkArrow:
					return "p-button-link p-button-arrow";
				case CtaButtonStyle.Link:
					return "p-button-link p-button-primary";
				case CtaButtonStyle.Default:
				default:
					return "";
			}
		};
	}
}
</script>

<style lang="scss" scoped></style>
