import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.value ? 'pi pi-check text-success mr-1' : 'pi pi-times text-danger mr-1')
    }, null, 2),
    _createElementVNode("span", {
      textContent: _toDisplayString(_ctx.value ? _ctx.$t('common.yes') : _ctx.$t('common.no'))
    }, null, 8, _hoisted_2)
  ]))
}