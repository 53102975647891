import httpClient from '@/types/helpers/apiHelper';
import i18n from '@/types/utils/i18n';
import { ContentCategoryOverviewViewModel } from '@/types/viewModels/general/contentCategoryOverviewViewModel';
import { OverviewSortType } from '@/types/enum/overviewSortType';
import { defineStore } from 'pinia';
import { useLayoutStore } from '@/store/common/layoutStore';
import { IDropdownItem } from '@/types/models/common/dropdownItem.interface';
import { useLoadingStore } from '@/store/common/loadingStore';
import { PagedList } from '@/types/models/common/pagedList.interface';
import { ContentCategoryOverviewItemViewModel } from '@/types/viewModels/general/contentCategoryOverviewItemViewModel';
import { ContentCategory } from '@/types/enum/contentCategory';

interface contentCategoryOverviewFilterState {
	filter: any;
	sortOptions: IDropdownItem[] | null;
	authorTags: IDropdownItem[] | null;
	branchTags: IDropdownItem[] | null;
	themeTags: IDropdownItem[] | null;
	contentCategories: number[];
	currentContentCategory: ContentCategory | null;
	overviewItems: unknown[];
	sortType: OverviewSortType;
}

export const useContentCategoryOverviewFilterStore = defineStore('contentCategoryOverviewFilters', {
	state: () =>
		({
			filter: {},
			sortOptions: null,
			authorTags: null,
			branchTags: null,
			themeTags: null,
			sortType: 0,
			overviewItems: [],
			contentCategories: [],
			currentContentCategory: null,
		}) as contentCategoryOverviewFilterState,
	getters: {
		hasAuthorTags(): boolean {
			return !!this.authorTags?.length;
		},
		hasBranchTags(): boolean {
			return !!this.branchTags?.length;
		},
		hasThemeTags(): boolean {
			return !!this.themeTags?.length;
		},
		hasSelectedAuthorTags(): boolean {
			return !!this.filter.authorTags?.length;
		},
		hasSelectedBranchTags(): boolean {
			return !!this.filter.branchTags?.length;
		},
		hasSelectedThemeTags(): boolean {
			return !!this.filter.themeTags?.length;
		},
		hasPage(): boolean {
			return this.filter.page;
		},
	},
	actions: {
		async init(vm: ContentCategoryOverviewViewModel): Promise<void> {
			useLoadingStore().showSpinner('contentCategory');
			const remap = (x) =>
				x?.map((x) => {
					x.label = x.text;
					delete x.text;
					return x;
				});

			this.filter = vm.filter;

			this.filter.totalItems = vm.pagedItems.totalItems;
			this.filter.pageSize = 12;

			this.currentContentCategory = this.filter.contentCategories;

			this.authorTags = remap(vm.authorTags);
			this.branchTags = remap(vm.branchTags);
			this.themeTags = remap(vm.themeTags);
			this.sortOptions = [
				{ label: i18n.global.t('common.sortDefault'), value: OverviewSortType.Default },
				{ label: i18n.global.t('common.sortNameAsc'), value: OverviewSortType.NameAsc },
				{ label: i18n.global.t('common.sortNameDesc'), value: OverviewSortType.NameDesc },
			];

			await this.updateFilters();
		},
		async updateFilters(): Promise<PagedList<ContentCategoryOverviewItemViewModel>> {
			useLoadingStore().showSpinner('contentCategory');
			const payload = { ...this.filter };

			const result = await httpClient.get<PagedList<ContentCategoryOverviewItemViewModel>>(
				`/api/${useLayoutStore().vm.localizationPrefix}/contentcategory/filter`,
				{
					params: { ...payload },
					data: {},
				}
			);
			this.overviewItems = result.data.items;
			this.filter.totalItems = result.data.totalItems;
			this.filter.pages = Math.ceil(this.filter.totalItems / this.filter.pageSize);

			this.updateQueryParams();
			useLoadingStore().hideSpinner('contentCategory');
			return result.data;
		},

		onSortChange(): void {
			switch (this.sortType) {
				case OverviewSortType.Default:
					if (this.currentContentCategory === ContentCategory.Event)
						this.overviewItems.sort((a: any, b: any) => b.dateCreated.localeCompare(a.relatedDate));
					else this.overviewItems.sort((a: any, b: any) => b.dateCreated.localeCompare(a.dateCreated));
					break;
				case OverviewSortType.NameAsc:
					this.overviewItems.sort((a: any, b: any) => {
						if (a.title < b.title) {
							return -1;
						}
						if (a.title > b.title) {
							return 1;
						}
						return 0;
					});
					break;
				case OverviewSortType.NameDesc:
					this.overviewItems.sort((a: any, b: any) => {
						if (a.title < b.title) {
							return 1;
						}
						if (a.title > b.title) {
							return -1;
						}
						return 0;
					});
					break;
				default:
					this.overviewItems.sort((a: any, b: any) => b.dateCreated.localeCompare(a.dateCreated));
			}
		},

		updateQueryParams(): void {
			const queryParams = new URLSearchParams('');

			if (this.hasPage) {
				queryParams.append('page', this.filter.page);
			}

			if (this.hasSelectedBranchTags) {
				queryParams.append('branchTag', this.filter.branchTags.map((x) => x).join(','));
			}

			if (this.hasSelectedAuthorTags) {
				queryParams.append('authorTag', this.filter.authorTags.map((x) => x).join(','));
			}

			if (this.hasSelectedThemeTags) {
				queryParams.append('themeTag', this.filter.themeTags.map((x) => x).join(','));
			}

			const url = new URL(window.location.href);
			url.search = queryParams.toString();

			if (window.history.replaceState) {
				window.history.replaceState({}, document.title, url.toString());
			}
		},

		async onPageChange(page: number) {
			this.filter.page = page + 1;
			await this.updateFilters();
		},
		// resetFilters(): void {
		// 	this.filters.forEach((x) => {
		// 		x.props = {} as FilterPropsViewModel;
		// 		x.isActive = false;
		// 		x.options.forEach((y) => (y.isActive = false));
		// 	});
		// },
	},
});
