<template>
	<div class="hero" :class="{ 'has-image': vm.backgroundImageUrl, [backgroundColor]: backgroundColor }">
		<video
			v-if="vm.backgroundVideoUrl"
			autoplay
			class="video-header__video"
			height="100%"
			loop
			muted
			width="100%"
		>
			<source :src="vm.backgroundVideoUrl" />
		</video>

		<div v-else-if="vm.backgroundImageUrl" class="hero__background">
			<img :alt="vm.backgroundImageAlt" class="hero__background__image" :src="vm.backgroundImageUrl" />
		</div>

		<div v-else-if="vm.heroBackgroundColor === HeroBackgroundColorEnum.magenta">
			<svg class="hero__background-circles" viewBox="0 0 1154 1154">
				<g fill="#FFF" fill-rule="evenodd">
					<circle cx="577" cy="577" opacity=".033" r="577" />
					<circle cx="577.5" cy="576.5" opacity=".037" r="405.5" />
				</g>
			</svg>
		</div>

		<div class="hero__body">
			<div class="container">
				<div class="w-75 text-white">
					<h1 v-if="vm.title" class="display-3 text-shadow">{{ vm.title }}</h1>
					<h3 v-if="vm.subtitle" class="display-5">{{ vm.subtitle }}</h3>
				</div>

				<HeroSearchBar
					v-if="!vm.hideSearch && vm.searchBarVerticalAlignment === VerticalAlignmentEnum.top"
					:vm="vm"
				/>

				<div class="hero__cta">
					<LinkButton
						v-if="vm.ctaButton"
						class="p-button-success p-button-raised p-button-arrow"
						:class="{ 'p-button-lg': !isSmallScreen }"
						:label="vm.ctaButton.text"
						:target="vm.ctaButton.target"
						:title="vm.ctaButton.title"
						:url="vm.ctaButton.url"
					/>
					<LinkButton
						v-if="vm.ctaLink"
						class="p-button-link"
						:class="{
							'p-button-lg': !isSmallScreen,
							'p-button-primary':
								vm.heroBackgroundColor === HeroBackgroundColorEnum.grey || vm.backgroundImageUrl,
						}"
						:label="vm.ctaLink.text"
						:target="vm.ctaLink.target"
						:title="vm.ctaLink.title"
						:url="vm.ctaLink.url"
					/>
				</div>
			</div>
		</div>

		<div v-if="!vm.hideCompanyLogos && vm.companyLogoUrls?.length" class="hero__footer">
			<div class="container">
				<div class="hero__logos hidden-xs">
					<div v-for="(companyLogo, i) in vm.companyLogoUrls" :key="i">
						<img v-lazy="companyLogo" :alt="'companyLogo' + i" />
					</div>
				</div>

				<HeroSearchBar
					v-if="!vm.hideSearch && vm.searchBarVerticalAlignment === VerticalAlignmentEnum.bottom"
					:vm="vm"
				/>

				<div class="visible-xs">
					<div class="hero__logos">
						<div v-for="(companyLogo, i) in vm.companyLogoUrls" :key="i">
							<img v-lazy="companyLogo" :alt="'companyLogo' + i" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="(vm.hideCompanyLogos || !vm.companyLogoUrls?.length) && !vm.hideSearch" class="hero__footer">
			<div class="container">
				<HeroSearchBar
					v-if="!vm.hideSearch && vm.searchBarVerticalAlignment === VerticalAlignmentEnum.bottom"
					:vm="vm"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import HeroSearchBar from "@/components/common/heroSearchBar.vue";
import { Component, Prop } from "vue-facing-decorator";
import { HeroBackgroundColor } from "@/types/enum/heroBackgroundColor";
import { HeroDefaultBlockViewModel } from "@/types/viewModels/blocks/heroDefaultBlockViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { VerticalAlignment } from "@/types/enum/verticalAlignment";

@Component({ components: { HeroSearchBar } })
export default class HeroDefaultBlock extends BaseComponent {
	@Prop({ type: Object as PropType<HeroDefaultBlockViewModel>, required: true, default: {} })
	vm!: HeroDefaultBlockViewModel;

	created(): void {
		Log.info("HeroDefaultBlockVm", this.vm);
	}

	get HeroBackgroundColorEnum(): typeof HeroBackgroundColor {
		return HeroBackgroundColor;
	}

	get VerticalAlignmentEnum(): typeof VerticalAlignment {
		return VerticalAlignment;
	}

	get backgroundColor(): string {
		switch (this.vm.heroBackgroundColor) {
			case HeroBackgroundColor.grey:
				return "background-grey";
			case HeroBackgroundColor.magenta:
				return "background-purple";
			default:
				return "";
		}
	}
}
</script>

<style scoped lang="scss"></style>
