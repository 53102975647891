<template>
	<div class="training-course-table" :style="{ height: maxTableHeight }">
		<DataTable
			v-if="items.length"
			v-model:expanded-rows="expandedRows"
			v-model:selection="selectedRow"
			data-key="id"
			:row-class="highlightRow"
			:row-hover="true"
			scroll-height="flex"
			scrollable
			selection-mode="single"
			:value="items"
			@row-click.self="onRowClick"
		>
			<Column v-if="!isSmallScreen" expander style="min-width: 1.75rem; max-width: 2.5rem" />
			<Column key="name" field="name" :sortable="false">
				<template #body="item">
					<div
						class="d-flex align-items-center font-weight-bold text-primary justify-content-between flex-1"
					>
						<span>
							{{ item.data.name }}
							<i v-if="isRowSelected(item.data)" class="pi pi-check ml-1" />
						</span>

						<PopoverIcon v-if="item.data.modules.length" class="d-inline-flex m-0 border-0">
							<font-awesome-icon
								:icon="['fas', 'list-check']"
								style="color: var(--secondary-color); line-height: 100%"
							/>
							<template #content>
								<div v-html="$t('training.modularTraining')" />
							</template>
						</PopoverIcon>
					</div>
				</template>
			</Column>
			<template #expansion="item">
				<div class="row-expansion-content">
					<p class="text-preline" v-text="item.data.intro" />
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { TrainingViewModel } from "@/types/generated/Training/trainingViewModel";
import { clamp } from "lodash";
import { useTrainingProductStore } from "@/store/training/trainingProductStore";

@Component
export default class TrainingCourseTable extends BaseComponent {
	@Prop({ type: Object as PropType<TrainingViewModel[]>, required: true, default: {} })
	items!: TrainingViewModel[];

	@Emit trainingCourseChanged(training: TrainingViewModel): TrainingViewModel {
		return training;
	}

	expandedRows: TrainingViewModel[] = [];
	selectedRow!: TrainingViewModel;

	trainingProductStore = useTrainingProductStore();

	get maxTableHeight(): string {
		let height = 85 * clamp(this.items.length, 1, 5);
		if (this.expandedRows.length > 0) height += 200;
		return `${height}px`;
	}

	created(): void {
		this.selectedRow = this.trainingProductStore.selectedTrainingCourse
			? this.trainingProductStore.selectedTrainingCourse
			: this.items[0];
	}

	mounted(): void {
		this.scrollTopToSelectedRow();
	}

	onRowClick(event: { data: TrainingViewModel; index: number }): void {
		const isRowExpanded = this.expandedRows.findIndex((x) => x.id === event.data.id) > -1;
		this.expandedRows = isRowExpanded ? [] : [event.data];
		this.scrollTopToSelectedRow();
		this.trainingCourseChanged(event.data);
	}

	isRowSelected(course: TrainingViewModel): boolean {
		return this.selectedRow?.id === course.id;
	}

	highlightRow(course: TrainingViewModel): string {
		if (this.isRowSelected(course)) return "selected";
		const isRowExpanded = this.expandedRows.findIndex((x) => x.id === course.id) > -1;
		return isRowExpanded ? "highlight" : "";
	}

	private scrollTopToSelectedRow(): void {
		setTimeout(() => {
			const element = document.querySelector(".training-course-table .p-highlight");
			const container = document.querySelector(".training-course-table .p-datatable-wrapper");
			if (!element || !container) return;
			container.scrollTop = (element as any).offsetTop;
		}, 10);
	}
}
</script>

<style lang="scss" scoped>
.training-course-table {
	::v-deep(.p-datatable) {
		.p-datatable-tbody tr {
			&.selected {
				border-radius: 0.25em;
				background: var(--surface-e);
				border: 1px solid var(--primary-color);
				color: var(--primary-color);
			}

			> td {
				padding: 1rem 0.5rem;
			}
		}

		.p-datatable-tbody tr.p-datatable-row-expansion {
			border: none;
			background: var(--surface-a) !important;

			.row-expansion-content {
				background: var(--surface-a);
			}
		}
	}

	.p-datatable-wrapper {
		flex: 1;
	}
}
</style>
