import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrainingOverviewItemCard = _resolveComponent("TrainingOverviewItemCard")!
  const _component_ExpertOverviewItemCard = _resolveComponent("ExpertOverviewItemCard")!
  const _component_ProductOverviewItemCard = _resolveComponent("ProductOverviewItemCard")!

  return (_ctx.vm.productType === _ctx.productTypeEnum.Training)
    ? (_openBlock(), _createBlock(_component_TrainingOverviewItemCard, {
        key: 0,
        compact: !_ctx.isCrossSell,
        "is-cross-sell": _ctx.isCrossSell,
        item: _ctx.trainingProduct,
        layout: "grid"
      }, null, 8, ["compact", "is-cross-sell", "item"]))
    : (_ctx.vm.productType === _ctx.productTypeEnum.Expert)
      ? (_openBlock(), _createBlock(_component_ExpertOverviewItemCard, {
          key: 1,
          "is-cross-sell": _ctx.isCrossSell,
          item: _ctx.expertProduct,
          layout: "grid"
        }, null, 8, ["is-cross-sell", "item"]))
      : (_openBlock(), _createBlock(_component_ProductOverviewItemCard, {
          key: 2,
          "is-cross-sell": _ctx.isCrossSell,
          item: _ctx.vm,
          layout: "grid"
        }, null, 8, ["is-cross-sell", "item"]))
}