import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-steps-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!

  return (_openBlock(), _createBlock(_component_Steps, {
    class: "my-3 quotation-steps d-none d-md-block",
    model: _ctx.steps,
    readonly: true
  }, {
    item: _withCtx(({ item }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["p-menuitem-link", {'active': item.step === _ctx.activeStep }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["p-steps-number", {'showIcon': item.autoDeskIcon }])
        }, _toDisplayString(item.step), 3),
        _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
      ], 2)
    ]),
    _: 1
  }, 8, ["model"]))
}