import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = {
  key: 0,
  class: "display-4 mb-1"
}
const _hoisted_5 = {
  key: 1,
  class: "lead mb-1"
}
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = {
  key: 0,
  class: "lead mb-2"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "display-4 mb-1"
}
const _hoisted_11 = {
  key: 1,
  class: "lead mb-2"
}
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor) || 'surface-a'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isColumnLayout)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.vm.title)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.vm.title), 1))
                : _createCommentVNode("", true),
              (_ctx.vm.intro && _ctx.vm.introPosition === _ctx.introPosition.belowTitle)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.vm.intro), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.vm.intro && _ctx.vm.introPosition !== _ctx.introPosition.belowTitle)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.vm.intro), 1))
                : _createCommentVNode("", true),
              (_ctx.vm.text)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    class: "rte",
                    innerHTML: _ctx.vm.text
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true),
              (_ctx.vm.ctaButton)
                ? (_openBlock(), _createBlock(_component_LinkButton, {
                    key: 2,
                    class: _normalizeClass(["mt-3", _ctx.ctaButtonClass(_ctx.vm.ctaStyle) || 'p-button-raised p-button-text p-button-arrow']),
                    label: _ctx.vm.ctaButton.text,
                    target: _ctx.vm.ctaButton.target,
                    title: _ctx.vm.ctaButton.title,
                    url: _ctx.vm.ctaButton.url
                  }, null, 8, ["class", "label", "target", "title", "url"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_ctx.vm.title)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_10, _toDisplayString(_ctx.vm.title), 1))
              : _createCommentVNode("", true),
            (_ctx.vm.intro)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.vm.intro), 1))
              : _createCommentVNode("", true),
            (_ctx.vm.text)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 2,
                  class: "rte",
                  innerHTML: _ctx.vm.text
                }, null, 8, _hoisted_12))
              : _createCommentVNode("", true),
            (_ctx.vm.ctaButton)
              ? (_openBlock(), _createBlock(_component_LinkButton, {
                  key: 3,
                  class: _normalizeClass(["mt-3", _ctx.ctaButtonClass(_ctx.vm.ctaStyle) || 'p-button-raised p-button-text p-button-arrow']),
                  label: _ctx.vm.ctaButton.text,
                  target: _ctx.vm.ctaButton.target,
                  title: _ctx.vm.ctaButton.title,
                  url: _ctx.vm.ctaButton.url
                }, null, 8, ["class", "label", "target", "title", "url"]))
              : _createCommentVNode("", true)
          ]))
    ])
  ], 2))
}