<template>
	<Steps class="my-3 checkout-steps" :model="steps" :readonly="false" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CheckoutDetails from "@/components/commerce/checkout/checkoutDetails.vue";
import CheckoutOrderConfirmation from "@/components/commerce/checkout/checkoutOrderConfirmation.vue";
import OrderSummary from "@/components/commerce/checkout/orderSummary.vue";
import PaymentMethodSelection from "@/components/commerce/checkout/paymentMethodSelection.vue";
import Steps from "primevue/steps";
import { Component, Watch } from "vue-facing-decorator";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";

export enum CheckoutStep {
	Details,
	PaymentMethod,
	Overview,
	Confirmation,
	Unknown = -1,
}

export interface CheckoutStepOption {
	label: string;
	step: CheckoutStep;
	to: string;
	command: any;
	disabled: any;
}

@Component({ components: { Steps } })
export default class CheckoutSteps extends BaseComponent {
	checkoutStore = useCheckoutStore();
	steps: CheckoutStepOption[] = [];

	@Watch("checkoutStore.activeStep") onStepChanged(step: CheckoutStep): void {
		const nextStep = this.steps.find((x) => x.step === step);
		if (nextStep && this.checkoutStore.canGoToStep(step)) this.displayStep(nextStep);
	}

	created(): void {
		this.setupStepsRoutes();
		this.steps = [
			{
				label: this.$t("checkout.step1"),
				to: "/checkout/details",
				step: CheckoutStep.Details,
				command: () => this.goToStep(CheckoutStep.Details),
				disabled: () => !this.checkoutStore.canGoToStep(CheckoutStep.Details),
			},
			{
				label: this.$t("checkout.step2"),
				to: "/checkout/payment",
				step: CheckoutStep.PaymentMethod,
				command: () => this.goToStep(CheckoutStep.PaymentMethod),
				disabled: () => !this.checkoutStore.canGoToStep(CheckoutStep.PaymentMethod),
			},
			{
				label: this.$t("checkout.step3"),
				to: "/checkout/overview",
				step: CheckoutStep.Overview,
				command: () => this.goToStep(CheckoutStep.Overview),
				disabled: () => !this.checkoutStore.canGoToStep(CheckoutStep.Overview),
			},
			{
				label: this.$t("checkout.step4"),
				to: "/checkout/confirmation",
				step: CheckoutStep.Confirmation,
				command: () => this.goToStep(CheckoutStep.Confirmation),
				disabled: () => true,
			},
		];

		this.checkoutStore.activeStep = CheckoutStep.Details;
	}

	goToStep(step: CheckoutStep): void {
		if (this.checkoutStore.canGoToStep(step)) this.checkoutStore.activeStep = step;
	}

	private displayStep(step: CheckoutStepOption): void {
		this.$router.push({ path: step.to });
	}

	private setupStepsRoutes(): void {
		this.$router.addRoute({ path: "/checkout/details", component: CheckoutDetails });
		this.$router.addRoute({ path: "/checkout/payment/", component: PaymentMethodSelection });
		this.$router.addRoute({ path: "/checkout/overview/", component: OrderSummary });
		this.$router.addRoute({ path: "/checkout/confirmation/", component: CheckoutOrderConfirmation });
	}
}
</script>

<style scoped lang="scss"></style>
