export enum CartLineType {
	product,
	productBundle,
	trainingProduct,
	servicePassProduct,
	rentalSystemProduct,
	contractProlong,
	contractChange,
	contractAddition,
	contractSwitch,
	contractCoterm,
	contractCotermProlong,
	expertProduct,
	walletDeposit,
	support,
	invoiceTotals,
	paymentInstructions,
	serviceProduct
}
