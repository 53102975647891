import axios from 'axios';
import localazyMetadata from '@/types/generated/localazyMetaData';
import { FormatHelper } from '@/types/helpers/formatHelper';
import LocalazyApi from '@localazy/ts-api';
import { createI18n } from 'vue-i18n';
import { values } from 'lodash';

const localazyApi = LocalazyApi({
	projectToken: process.env.VUE_APP_LOCALAZY_TOKEN,
	baseUrl: 'https://api.localazy.com',
});

/// Returns the first part of the language
/// eg. en-GB return 'en'
export function getActiveLocale() {
	const splitLang = getActiveLanguage().split('-');
	const lang = splitLang.length > 1 ? splitLang[0] : getActiveLanguage();
	//Map exclusion for certain languages.
	switch (lang) {
		case 'no':
			return 'nb';
		default:
			return lang;
	}
}

/// Returns the full language
export function getActiveLanguage() {
	return document.documentElement.lang;
}

export function loadLocalMessagesForActiveLocale() {
	const messages = {};
	try {
		messages[getActiveLocale()] = require(`../../assets/i18n/${getActiveLocale()}.json`);
	} catch {
		console.error(`Local translation file for ${getActiveLocale()} not found.`);
	}
	return messages;
}

/**
 * Load translations from CDN or API
 */
export function loadMessagesFromCdnForActiveLocale() {
	try {
		const cdnFile = localazyMetadata.files[0]?.cdnFiles[getActiveLocale()];
		if (cdnFile) {
			return axios.get(cdnFile).then((x) => x.data);
		}
	} catch {
		//use local
		console.error(
			`Unable to load CDN translations from Localazy, falling back to local files for locale: ${getActiveLocale()}.`
		);
		return Promise.resolve(loadLocalMessagesForActiveLocale());
	}

	return Promise.resolve(loadLocalMessagesForActiveLocale());
}

export async function loadMessagesFromApiForActiveLocale() {
	try {
		const files = await localazyApi.listFiles({ projectId: process.env.VUE_APP_LOCALAZY_PROJECT });
		const file = await localazyApi.getFileContents({
			projectId: process.env.VUE_APP_LOCALAZY_PROJECT,
			lang: getActiveLocale(),
			fileId: files[0]?.id,
		});
		if (file) return Promise.resolve(JSON.parse(await file.text()));
	} catch {
		//use local
		console.error(
			`Unable to load API translations from Localazy, falling back to local files for locale: ${getActiveLocale()}.`
		);
		return Promise.resolve(loadLocalMessagesForActiveLocale());
	}

	return Promise.resolve(loadLocalMessagesForActiveLocale());
}

export async function loadPrimeVueLocale() {
	const messages = await import(`../../assets/i18n/${getActiveLocale()}.json`);
	const primeVueMessages = messages['primeVue'] || {};
	const commonMessages = messages['common'];
	const activeLocale =
		values(FormatHelper.dateLocales).find((x) => x.code === getActiveLanguage()) ||
		FormatHelper.dateLocales[getActiveLocale()] ||
		FormatHelper.dateLocales['gb'];
	if (!activeLocale) return primeVueMessages;
	// Set some dynamic translations used in PrimeVue
	primeVueMessages['dateFormat'] = activeLocale.formatLong
		.date({ width: 'short' })
		?.toLowerCase()
		?.replace('yyyy', 'yy')
		?.replace('y', 'yy');
	primeVueMessages['firstDayOfWeek'] = activeLocale.options.weekStartsOn;
	primeVueMessages['dayNames'] = [...Array(7).keys()].map((i) =>
		activeLocale.localize.day(i, { width: 'wide' })
	);
	primeVueMessages['dayNamesMin'] = [...Array(7).keys()].map((i) =>
		activeLocale.localize.day(i, { width: 'short' })
	);
	primeVueMessages['dayNamesShort'] = [...Array(7).keys()].map((i) =>
		activeLocale.localize.day(i, { width: 'abbreviated' })
	);
	primeVueMessages['monthNamesShort'] = [...Array(12).keys()].map((i) =>
		activeLocale.localize.month(i, { width: 'abbreviated' })
	);
	primeVueMessages['monthNames'] = [...Array(12).keys()].map((i) =>
		activeLocale.localize.month(i, { width: 'wide' })
	);
	primeVueMessages['reject'] = commonMessages['no'];
	primeVueMessages['accept'] = commonMessages['yes'];
	primeVueMessages['cancel'] = commonMessages['cancel'];

	return primeVueMessages;
}

const i18nConfig = {
	locale: getActiveLocale() || 'nl',
	fallbackLocale: localazyMetadata?.baseLocale || 'en',
	fallbackWarn: false,
	messages: loadLocalMessagesForActiveLocale(),
};

export default createI18n(i18nConfig);
