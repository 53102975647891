<template>
	<div class="profile-image text-center">
		<label class="label">
			<input
				v-if="canChangeImage"
				accept="image/png,image/jpeg"
				class="d-none"
				type="file"
				@change="onProfilePictureChange"
			/>
			<figure class="profile-figure" :style="{ cursor: canChangeImage ? 'pointer' : 'auto' }">
				<template v-if="!validProfileImage">
					<div
						v-if="firstLetterAvatar"
						aria-hidden="true"
						class="avatar"
						:class="{ 'avatar--large': !isSmallScreen }"
						:data-firstletter="firstLetterAvatar"
					/>
					<div v-else class="avatar" :class="{ 'avatar--large': !isSmallScreen }">
						<font-awesome-icon :icon="('far', 'user')" size="sm" />
					</div>
				</template>

				<img
					v-else-if="!isSpinnerVisible('avatar')"
					alt="avatar"
					class="profile-avatar"
					:src="profileImageUrl || loadedFile"
					@error="onProfilePictureError"
				/>
				<i v-else class="pi pi-spinner spin profile-avatar-loading" />
				<figcaption v-if="canChangeImage" class="profile-figcaption">
					<span>{{ $t("common.change") }}</span>
				</figcaption>
			</figure>
		</label>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop, Emit, Watch } from "vue-facing-decorator";
import { useExpertStore } from "@/store/expert/expertStore";

@Component({
	components: {},
})
export default class ProfileImage extends BaseComponent {
	@Prop({ type: String, required: false, default: "" })
	profileImageUrl!: string;
	@Prop({ type: String, required: false, default: "" }) firstLetterAvatar = "";
	expertStore = useExpertStore();
	@Prop({ type: Boolean, required: false, default: false }) canChangeImage;

	validProfileImage = false;
	loadedFile = null;

	@Emit()
	onProfilePictureChange(evt: any): File | void {
		if (evt.target.files[0]) {
			this.loadedFile = evt.target.files[0];
			this.validProfileImage = !!this.loadedFile;
			return evt.target.files[0];
		}
	}

	@Watch("profileImageUrl")
	onImageUrlChange(val: string): void {
		this.validProfileImage = !!val;
	}

	created(): void {
		this.validProfileImage = !!this.profileImageUrl;
	}

	onProfilePictureError(): void {
		this.validProfileImage = false;
	}
}
</script>

<style scoped lang="scss">
.avatar {
	width: 4rem;
	height: 4rem;
	font-weight: 700;
	font-size: 1.75rem;
	background: var(--surface-c);

	&:hover {
		background: var(--surface-c);
	}

	&.avatar--large {
		width: 6rem;
		height: 6rem;
		font-size: 3rem;
	}
}

.profile-status {
	margin-left: auto;
	width: clamp(300px, 15%, 600px);
}

.profile-progress {
	position: relative;
	margin-left: auto;
	width: clamp(600px, 25%, 900px);
	text-align: center;
	margin-bottom: 1rem;

	::v-deep(.p-progressbar) {
		background: var(--surface-b);

		.p-progressbar-value {
			background: var(--secondary-color);
		}
	}

	&::before {
		content: "0%";
		font-size: 85%;
		left: 0;
		top: 2px;
		position: absolute;
	}

	&::after {
		font-size: 85%;
		content: "100%";
		right: 0;
		top: 2px;
		position: absolute;
	}
}

.profile-figure {
	position: relative;
	width: 6rem;
	height: 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profile-avatar {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-radius: 100%;
	border: 2px solid transparent;
	transition: all ease-in-out 0.3s;
	object-fit: cover;

	.profile-avatar-loading {
		font-size: 1.5rem;
	}
}

.profile-figcaption {
	position: absolute;
	font-size: 1.2rem;
	font-weight: 500;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primary-color-text);
	border-radius: 100%;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: all ease-in-out 0.3s;

	&:hover {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.4);
	}
}
</style>
