import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row p-2 product-grid-columned-block" }
const _hoisted_5 = { class: "col-lg-6" }
const _hoisted_6 = { class: "html-content mb-2" }
const _hoisted_7 = {
  key: 0,
  class: "display-4 mb-0"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseProductOverviewItemCard = _resolveComponent("BaseProductOverviewItemCard")!
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.products, (product) => {
              return (_openBlock(), _createElementBlock("div", {
                key: product,
                class: "col col-12 col-md-6 col-lg-12 col-xl-6 p-1"
              }, [
                _createVNode(_component_BaseProductOverviewItemCard, { vm: product }, null, 8, ["vm"])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.vm.title)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_7, _toDisplayString(_ctx.vm.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              innerHTML: _ctx.vm.text
            }, null, 8, _hoisted_8)
          ]),
          (_ctx.vm.cta)
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 0,
                class: _normalizeClass({
							[_ctx.ctaButtonClass(_ctx.vm.ctaStyle)]: _ctx.ctaButtonClass(_ctx.vm.ctaStyle),
							'p-button-success p-button-raised p-button-arrow': !_ctx.ctaButtonClass(_ctx.vm.ctaStyle),
						}),
                label: _ctx.vm.cta.text,
                target: _ctx.vm.cta.target,
                title: _ctx.vm.cta.title,
                url: _ctx.vm.cta.url
              }, null, 8, ["class", "label", "target", "title", "url"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}