import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8074099e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "d-flex flex-row search-result-item" }
const _hoisted_3 = { class: "image-container my-auto" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "item-content my-auto" }
const _hoisted_6 = { class: "d-flex flex-row" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "contentcategory-tag" }
const _hoisted_9 = {
  key: 0,
  class: "lead"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Tag = _resolveComponent("Tag")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("a", {
    class: "text-decoration-none",
    href: _ctx.item?.pageUrl
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.item.hasIconUrl && _ctx.item.iconUrl)
          ? _withDirectives((_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: _ctx.item.name,
              class: "image w-100"
            }, null, 8, _hoisted_4)), [
              [_directive_lazy, _ctx.item.iconUrl]
            ])
          : (_openBlock(), _createBlock(_component_Badge, {
              key: 1,
              value: _ctx.item.type.charAt(0)
            }, null, 8, ["value"]))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.item.name), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Tag, {
              value: _ctx.item.type
            }, null, 8, ["value"])
          ])
        ]),
        (_ctx.item.intro)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.item.intro), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 8, _hoisted_1))
}