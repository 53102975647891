import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-762b9ab3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "lead mb-2"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "d-flex flex-column align-items-center p-3" }
const _hoisted_6 = { class: "d-flex flex-column align-items-center" }
const _hoisted_7 = { class: "font-weight-bold lead text-primary" }
const _hoisted_8 = { class: "mb-0 lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.vm.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass(["display-4", { 'mb-0': _ctx.vm.subtitle }]),
            innerHTML: _ctx.vm.title
          }, null, 10, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.vm.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.vm.subtitle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_SelectButton, {
          class: "row m-0",
          "data-key": "id",
          options: _ctx.filteredMainCategories
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("a", {
              href: _ctx.getUrlForCategory(slotProps.option)
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Avatar, {
                    class: "mb-2",
                    image: slotProps.option.icon,
                    shape: "square",
                    size: "large"
                  }, null, 8, ["image"]),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(slotProps.option.title), 1),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(slotProps.option.subtitle), 1)
                ])
              ])
            ], 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["options"]),
        (_ctx.otherMainCategory)
          ? (_openBlock(), _createBlock(_component_LinkButton, {
              key: 0,
              class: "my-2 p-0 p-button-text p-button-secondary p-button-link",
              label: _ctx.otherMainCategory?.title,
              url: _ctx.getUrlForCategory(_ctx.otherMainCategory)
            }, null, 8, ["label", "url"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}