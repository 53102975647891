import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f63a954"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "lead mb-2"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = ["alt"]
const _hoisted_6 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.vm.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass(["display-4", { 'mb-0': _ctx.vm.subTitle }]),
            innerHTML: _ctx.vm.title
          }, null, 10, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.vm.subTitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.vm.subTitle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `linkToItem_${index}`,
            class: _normalizeClass(["col-md-6", { 'col-lg-3': _ctx.vm.itemsPerRow === 4, 'col-lg-4': _ctx.vm.itemsPerRow === 3 }])
          }, [
            _createVNode(_component_Card, null, {
              header: _withCtx(() => [
                _withDirectives(_createElementVNode("img", {
                  alt: item.imageAlt,
                  height: "250"
                }, null, 8, _hoisted_5), [
                  [_directive_lazy, item.imageUrl]
                ])
              ]),
              content: _withCtx(() => [
                (item.title)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(item.title), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", {
                  innerHTML: item.text
                }, null, 8, _hoisted_7),
                (item.linkItem)
                  ? (_openBlock(), _createBlock(_component_LinkButton, {
                      key: 1,
                      class: "p-button-link p-button-arrow mt-2",
                      label: item.linkItem.text,
                      target: item.linkItem.target,
                      title: item.linkItem.title,
                      url: item.linkItem.url
                    }, null, 8, ["label", "target", "title", "url"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ], 2))
        }), 128))
      ])
    ])
  ], 2))
}