<template>
	<div class="training-module-table">
		<DataTable
			v-if="modules?.length"
			v-model:expanded-rows="expandedRows"
			auto-layout
			data-key="sku"
			responsive-layout="stack"
			:row-class="highlightRow"
			:row-hover="true"
			:value="modules"
			@row-click.self="onRowClick"
		>
			<Column v-if="!isSmallScreen" expander style="width: 2.5rem" />
			<Column key="name" field="name">
				<template #body="item">
					<span>{{ item.data.name }}</span>
				</template>
			</Column>

			<Column v-if="!isDefaultTraining" key="durationInHours" field="durationInMinutes" style="width: 20%">
				<template #body="item">
					<span>{{ $t(`training.duration.${getDuration(item.data.durationInHours)}`) }}</span>
				</template>
			</Column>

			<Column v-if="!isDefaultTraining" key="price" field="price" style="width: 20%">
				<template #body="item">
					<span>{{ formatCurrency(item.data.price) }}</span>
				</template>
			</Column>
			<Column v-if="isEditable" key="selection" style="width: 10%">
				<template #body="item">
					<InputSwitch
						:model-value="isRowSelected(item.data)"
						@click.stop="onToggleSelectionClicked(item.data)"
					/>
				</template>
			</Column>
			<template v-if="!isDefaultTraining" #footer>
				<div class="d-flex w-100 font-weight-bold">
					<div class="flex-grow-1">{{ $t("common.total") }}</div>
					<div class="px-1" style="width: 20%">
						<span>{{ $tc("common.days", totalDurationInDays) }}</span>
					</div>
					<div class="px-2" style="width: 20%">
						<span>{{ formatCurrency(totalPrice || 0) }}</span>
					</div>
					<div v-if="isEditable" style="width: 10%"></div>
				</div>
			</template>
			<template #expansion="item">
				<div class="row-expansion-content p-1">
					<p class="text-preline" v-html="item.data.shortDescription" />
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { LocalTrainingType } from "@/types/enum/localTrainingType";
import { PropType } from "vue";
import { TrainingModuleViewModel } from "@/types/generated/Training/trainingModuleViewModel";
import { TrainingViewModel } from "@/types/generated/Training/trainingViewModel";
import { cloneDeep, sum } from "lodash";
import { useTrainingProductStore } from "@/store/training/trainingProductStore";

export interface TrainingModulesChangedEvent {
	course: TrainingViewModel;
	modules: TrainingModuleViewModel[];
}

@Component
export default class TrainingModuleTable extends BaseComponent {
	@Prop({ type: Object as PropType<TrainingViewModel>, required: true, default: {} })
	trainingCourse!: TrainingViewModel;
	@Prop({ type: Boolean, required: false, default: false }) isEditable!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) isModal!: boolean;

	@Emit() selectedModulesChanged(): TrainingModulesChangedEvent {
		return { course: this.trainingCourse, modules: this.selectedModules };
	}

	expandedRows: TrainingModuleViewModel[] = [];
	selectedModules: TrainingModuleViewModel[] = [];

	trainingProductStore = useTrainingProductStore();

	created(): void {
		this.selectedModules = cloneDeep(
			this.modules?.filter((x) =>
				this.trainingProductStore.isModuleSelected(this.trainingCourse.cadacIdentifier, x.sku)
			) || []
		);
	}

	get totalDurationInHours(): number {
		return sum(this.selectedModules.map((x) => x.durationInHours));
	}

	get totalDurationInDays(): number {
		return this.totalDurationInHours / 6;
	}

	get totalPrice(): number {
		return sum(this.selectedModules.map((x) => x.price));
	}

	get modules(): TrainingModuleViewModel[] {
		return this.trainingCourse.modules;
	}

	get isDefaultTraining(): boolean {
		return this.trainingProductStore.trainingProductForm?.type !== LocalTrainingType.modular;
	}

	onToggleSelectionClicked(module: TrainingModuleViewModel): void {
		if (
			this.isModal ||
			!this.isRowSelected(module) ||
			this.totalDurationInHours - module.durationInHours >= 3
		) {
			this.toggleSelection(module);
		} else if (
			this.trainingCourse.cadacIdentifier === this.trainingProductStore.trainingProductForm?.course?.id
		) {
			this.$confirm.require({
				message: this.$t("training.dialog.confirmRemoveModuleMessage"),
				header: this.$t("training.dialog.confirmRemoveModuleTitle"),
				acceptLabel: "OK",
				rejectClass: "d-none",
			});
		} else {
			this.$confirm.require({
				message: this.$t("training.dialog.confirmRemoveCourseMessage"),
				header: this.$t("training.dialog.confirmRemoveCourseTitle"),
				acceptLabel: this.$t("common.yes"),
				rejectLabel: this.$t("common.no"),
				accept: () => {
					this.removeCourse();
				},
			});
		}
	}

	getDuration(duration: number): string {
		switch (duration) {
			case 1.5:
				return "quarterDay";
			case 3:
				return "halfDay";
			case 4.5:
				return "threeQuartersDay";
			case 6:
				return "fullDay";
			default:
				return "";
		}
	}

	toggleSelection(module: TrainingModuleViewModel): void {
		if (this.isRowSelected(module)) {
			this.selectedModules.splice(
				this.selectedModules.findIndex((x) => x.sku === module.sku),
				1
			);
		} else {
			this.selectedModules.push(module);
		}
		if (!this.isModal) {
			const courseCartModel = this.trainingProductStore.trainingCourseById(
				this.trainingCourse.cadacIdentifier
			);
			courseCartModel.selectedModuleSkus = this.selectedModules.map((x) => x.sku);
			this.trainingProductStore.updateCourse(courseCartModel);
		}
		this.selectedModulesChanged();
	}

	removeCourse(): void {
		this.trainingProductStore.removeCourse(this.trainingCourse.cadacIdentifier);
		this.selectedModulesChanged();
	}

	onRowClick(event: { data: TrainingModuleViewModel; index: number }): void {
		const existingRowIndex = this.expandedRows.findIndex((x) => x.sku === event.data.sku);

		if (existingRowIndex > -1) {
			this.expandedRows.splice(existingRowIndex, 1);
		} else {
			this.expandedRows.push(event.data);
		}
		this.expandedRows = [...this.expandedRows];
	}

	isRowSelected(module: TrainingModuleViewModel): boolean {
		return this.selectedModules.findIndex((x) => x.sku === module.sku) > -1;
	}

	highlightRow(module: TrainingModuleViewModel): string {
		let className = this.isEditable ? (this.isRowSelected(module) ? "" : "text-light") : "";
		className += this.expandedRows.findIndex((x) => x.sku === module.sku) > -1 ? "highlight" : "";
		return className;
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable) {
	.p-datatable-thead {
		display: none;
	}

	.p-datatable-footer {
		background: inherit;
		border: none;
	}
}
</style>
