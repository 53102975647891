import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lead text-bold mb-0" }
const _hoisted_2 = { class: "lead mb-2" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "text-bold mr-1" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    closable: true,
    header: _ctx.$t('expert.acceptBookingModal.header'),
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCancel()))
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.confirm'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("expert.acceptBookingModal.introTitle")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("expert.acceptBookingModal.intro")), 1),
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("common.messages.titleImportant")), 1),
        _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.$t("expert.acceptBookingModal.importantMessage")), 1)
      ]),
      _createVNode(_component_Message, {
        class: "mb-0 mt-2",
        closable: false,
        severity: "info"
      }, {
        default: _withCtx(() => [
          _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.$t("expert.acceptBookingModal.tip")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}