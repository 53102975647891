import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f97c0f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "advice-card pb-3" }
const _hoisted_2 = { class: "advice-card-content" }
const _hoisted_3 = { class: "advice-card-header" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-primary font-weight-normal text-center" }
const _hoisted_6 = { class: "advice-card-header-icons" }
const _hoisted_7 = { class: "mt-2 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_AvatarGroup = _resolveComponent("AvatarGroup")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_AdvisorProductAdviceContent = _resolveComponent("AdvisorProductAdviceContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.product.currentProduct.name), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Avatar, {
              image: _ctx.product.currentProduct.iconUrl,
              size: "xlarge"
            }, null, 8, ["image"]),
            (_ctx.product.nextStepAdvices.length > 1)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: ['far', 'arrow-right-long'],
                  size: "lg"
                }))
              : _createCommentVNode("", true),
            (_ctx.product.nextStepAdvices.length > 1)
              ? (_openBlock(), _createBlock(_component_AvatarGroup, { key: 1 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.nextStepAdvices.slice(
									0,
									_ctx.maxNextStepAdviceIcons
								), (nextStepAdvice, index) => {
                      return (_openBlock(), _createBlock(_component_Avatar, {
                        key: `nextStepAdvice_${index}`,
                        image: nextStepAdvice.iconUrl,
                        size: "large"
                      }, null, 8, ["image"]))
                    }), 128)),
                    (_ctx.product.nextStepAdvices.length > _ctx.maxNextStepAdviceIcons)
                      ? (_openBlock(), _createBlock(_component_Avatar, {
                          key: 0,
                          class: "plus-badge",
                          label: `+${_ctx.product.nextStepAdvices.length - _ctx.maxNextStepAdviceIcons}`,
                          shape: "circle",
                          size: "large"
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_i18n_t, {
              class: "text-center",
              keypath: "myCadac.advisorAdvice.contractNumber",
              scope: "global",
              tag: "p"
            }, {
              contractNumber: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_ctx.product.currentContractNumber), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_i18n_t, {
              class: "text-center",
              keypath: "myCadac.advisorAdvice.licenceNumber",
              scope: "global",
              tag: "p"
            }, {
              licenceNumber: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_ctx.product.currentSerialNumber), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_i18n_t, {
              class: "text-center",
              keypath: "myCadac.advisorAdvice.amountSeats",
              scope: "global",
              tag: "p"
            }, {
              seats: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_ctx.product.currentProduct.seats), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createVNode(_component_AdvisorProductAdviceContent, {
        collapsed: _ctx.collapsed,
        "current-product": _ctx.product.currentProduct,
        "next-step-advices": _ctx.product.nextStepAdvices
      }, null, 8, ["collapsed", "current-product", "next-step-advices"])
    ]),
    (_ctx.product.nextStepAdvices.length > 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "expand-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', _ctx.collapsed ? 'chevrons-down' : 'chevrons-up']
          }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true)
  ]))
}