<template>
	<Card class="my-3">
		<template #title>
			<div class="d-flex justify-content-between">
				<h4 class="mb-0">
					{{ $t("myCadac.contractDetail.card.titleExtend") }}
				</h4>
				<Button class="p-button-text" :label="$t('common.cancel')" @click="onExtendCancelled()" />
			</div>
		</template>
		<template #content>
			<div class="d-flex flex-wrap">
				<ContractSummary
					class="mb-1"
					:title="$t('myCadac.contractDetail.card.lblCurrentContract')"
					:vm="currentContractSummary"
				/>
				<ContractSummary
					:title="$t('myCadac.contractDetail.card.lblCoTermContract')"
					:vm="selectedContractSummary"
				/>
			</div>
			<Message class="mt-0" :closable="false" severity="warn">
				<i18n-t keypath="myCadac.contractDetail.message.newExpirationDateDurationChange">
					<template #date>
						<b>{{ formatDate(newEndDate) }}</b>
					</template>
				</i18n-t>
			</Message>
			<DataTable
				auto-layout
				data-key="serialNumber"
				responsive-layout="stack"
				:value="prolongableContractLines"
			>
				<Column
					field="productName"
					:header="$t('myCadac.contractDetail.table.colProductName')"
					:sortable="false"
				>
					<template #body="item">
						<div class="d-flex align-items-center ml-1 ml-lg-0">
							<Avatar
								v-if="item.data.icon"
								:alt="item.data.iconAlt"
								class="mr-1 bg-transparent flex-shrink-0"
								:image="item.data.icon"
								shape="square"
								size="large"
								:title="item.productName"
							>
							</Avatar>
							<div class="d-flex flex-column">
								<small class="mb-0 text-muted d-block">
									{{ item.data.productBrand }}
								</small>
								<p
									v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
									class="mb-0 text-bold"
								>
									<a :href="item.data.crmUrl" target="_blank" :title="$t('common.goToCrm')">
										{{ formatProductName(item.data.productName, item.data.productBrand) }}
									</a>
								</p>
								<p v-else class="mb-0 text-bold">
									{{ formatProductName(item.data.productName, item.data.productBrand) }}
								</p>
							</div>
						</div>
					</template>
				</Column>
				<Column
					field="deployment"
					:header="$t('myCadac.contractDetail.table.colProductLicenceType')"
					:sortable="false"
				>
					<template #body="item">
						{{ $t(`enum.deployment.${item.data.deployment}`) }}
					</template>
				</Column>
				<Column
					field="seats"
					:header="$t('myCadac.contractDetail.table.colProductSeats')"
					:sortable="false"
				/>
				<Column
					field="endDate"
					:header="$t('myCadac.contractDetail.table.colRemainingDuration')"
					:sortable="false"
				>
					<template #body>
						{{
							$tc(
								"myCadac.contractDetail.table.duration",
								selectedExtendContract?.remainingMonthsForOrder
							)
						}}
					</template>
				</Column>
				<Column
					class="text-lg-right"
					:header="$t('myCadac.contractDetail.table.colProductPrice')"
					:sortable="false"
				>
					<template #body="item">
						<div class="text-right">
							<small v-if="item.data.priceBase !== item.data.price" class="d-block text-muted">
								<s>{{
									formatCurrency(calcProRatePrice(item.data.priceBase, vm.contract.duration))
								}}</s>
							</small>
							<b>{{ formatCurrency(calcProRatePrice(item.data.price, vm.contract.duration)) }}</b>
						</div>
					</template>
				</Column>
			</DataTable>
		</template>
		<template #footer>
			<div class="col-md-10 col-lg-8 col-xl-6 ml-auto pr-2">
				<PriceSummary :vm="priceSummary" />
				<Button
					class="p-button-success p-button-raised p-button-lg d-block ml-auto"
					:disabled="!canCheckout"
					:label="$t('myCadac.contractDetail.button.goToCheckout')"
					:loading="isLoading"
					@click="onCheckoutClick()"
				/>
			</div>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ContractSummary, { IContractSummary } from "@/components/common/contractSummary.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ContractAddToCart } from "@/types/generated/Api/contractAddToCart";
import { ContractAddToCartLine } from "@/types/generated/Api/contractAddToCartLine";
import { ContractClient } from "@/types/generated/Api/contractClient";
import { ContractLineClient } from "@/types/generated/Api/contractLineClient";
import { ContractProlongationOption } from "@/types/generated/Api/contractProlongationOption";
import { ContractProlongationOptionType } from "@/types/enum/contractProlongationOptionType";
import { IContractExtend } from "@/types/viewModels/myCadac/contractExtendViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { add, isBefore, set } from "date-fns";
import { reduce } from "lodash";
import { useContractStore } from "@/store/software/contractStore";

@Component({ components: { ContractSummary, PriceSummary } })
export default class ContractDetailExtendCard extends BaseComponent {
	@Prop({
		type: Object as PropType<IContractExtend>,
		required: true,
		default: {},
	})
	vm!: IContractExtend;

	@Prop()
	prolongationOptions!: ContractProlongationOption[];

	editingContractLines: ContractLineClient[] = [];
	contractStore = useContractStore();

	get selectedExtendContract(): ContractProlongationOption | null {
		return this.contractStore.selectedProlongationOption;
	}

	get canCheckout(): boolean {
		return (
			(!!this.userProfile?.isEmailVerified || !!this.userProfile?.isImpersonated) &&
			!!this.selectedExtendContract
		);
	}

	get priceSummary(): IPriceSummary {
		const { vatPercentage, duration } = this.vm.contract;
		return reduce(
			this.prolongableContractLines,
			(result: IPriceSummary, contractLine: ContractLineClient) => {
				const proRatePrice = this.calcProRatePrice(contractLine.price, duration);
				const proRatePriceBase = this.calcProRatePrice(contractLine.priceBase, duration);
				const proRateDiscount = this.calcProRatePrice(contractLine.discount, duration);
				const vatAmount = proRatePrice * (vatPercentage / 100);
				const totalPrice = proRatePrice + vatAmount;
				result.totalPersonalPrice = result.totalPersonalPrice
					? result.totalPersonalPrice + proRatePrice
					: proRatePrice;
				result.totalBasePrice = result.totalBasePrice
					? result.totalBasePrice + proRatePriceBase
					: proRatePriceBase;
				result.totalDiscount = result.totalDiscount
					? result.totalDiscount + proRateDiscount
					: proRateDiscount;
				result.totalPrice = result.totalPrice ? result.totalPrice + totalPrice : totalPrice;
				result.vatAmount = result.vatAmount ? result.vatAmount + vatAmount : vatAmount;
				return result;
			},
			{ vatPercentage, useZeroPercentVAT: this.vm.useZeroPercentVAT } as IPriceSummary
		);
	}

	get currentContractSummary(): IContractSummary {
		return {
			contractEndDate: this.vm.contract.endDate,
			contractNumber: this.vm.contract.contractId,
			contractType: this.vm.contract.contractType,
		};
	}

	get selectedContractSummary(): IContractSummary {
		if (!this.selectedExtendContract) return {} as IContractSummary;
		return {
			contractEndDate: this.selectedExtendContract.endDate,
			contractNumber: this.selectedExtendContract.contractNumber,
			contractType: this.selectedExtendContract.contractClient.contractType,
		};
	}

	get newEndDate(): Date {
		const currentEndDate = this.toDate(this.vm.contract.endDate);
		// When extending or co-terming use the extend contract's endDate, else the current endDate.
		let newEndDate = this.selectedExtendContract
			? this.toDate(this.selectedExtendContract.endDate)
			: currentEndDate;
		// If extending only the month/day of the chosen contract should be synced with the current endDate, but it cannot be in the past.
		if (this.selectedExtendContract?.type === ContractProlongationOptionType.extend) {
			newEndDate = set(newEndDate, { year: currentEndDate.getFullYear() });
			if (isBefore(newEndDate, currentEndDate)) {
				newEndDate = add(newEndDate, { years: 1 });
			}
		}
		return newEndDate;
	}

	get prolongableContractLines(): ContractLineClient[] {
		return this.vm.contract.contractLines.filter((x) => x.canProlong);
	}

	get contractUpdateData(): ContractAddToCart {
		return {
			contractId: this.vm.contract.contractId,
			endDateToUseForCotermExtend: this.newEndDate,
			contractLines: reduce(
				this.editingContractLines,
				(result, value) => {
					result.push({
						checked: value.checked,
						addSeatVariationCode: value.addSeatVariationCode,
						renewVariationCode: value.renewVariationCode,
						serialNumber: value.serialNumber,
						seats: value.seats,
						switchDuration: 0,
						switchRenewVariationCode: "",
						switchSeats: 0,
					});
					return result;
				},
				[] as ContractAddToCartLine[]
			),
		};
	}

	created(): void {
		this.editingContractLines = this.prolongableContractLines;
	}

	onExtendCancelled(): void {
		this.contractStore.cancelExtend();
	}

	calcProRatePrice(price: number, contractDuration: number): number {
		const pricePerMonth = price / contractDuration;
		return pricePerMonth * (this.selectedExtendContract?.remainingMonthsForOrder || 0);
	}

	onCheckoutClick(): void {
		this.addContractProlongationToCart();
	}

	addContractProlongationToCart(): void {
		this.loadingStore.increaseLoadingCount();
		const updateData = this.contractUpdateData;
		this.axios
			.post("/api/cart/add-prolongation", updateData)
			.then(() => {
				this.openUrl(this.vm.checkoutPageUrl);
			})
			.catch((err) => Log.error(err))
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	recalculatePrices(): void {
		if (this.loadingStore.isBusy) return;
		this.loadingStore.increaseLoadingCount();
		const updateData: ContractAddToCart = this.contractUpdateData;
		this.axios
			.post("/api/contract/recalculate", updateData)
			.then(({ data }: { data: ContractClient }) => {
				this.editingContractLines = [...data.contractLines];
			})
			.catch((err) => Log.error(err))
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}
}
</script>

<style scoped lang="scss"></style>
