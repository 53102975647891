<template>
	<div class="row">
		<div class="col-12 mb-2">
			<h3>{{ $t("checkout.titleYourCompany") }}</h3>
			<CompanyForm
				v-model="vm.checkoutForm.companyForm"
				ref="companyForm"
				auto-complete
				:countries="vm.countries"
				:has-previously-ordered="vm.hasPreviouslyOrdered"
				:use-vat="useVat"
				@form-state-changed="checkIfDetailStepIsValid"
			/>
			<div class="p-field-checkbox mr-2 my-2">
				<Checkbox
					v-model="vm.checkoutForm.useDifferentInvoiceAddress"
					:id="'useDifferentInvoiceAddress'"
					:binary="true"
					name="roles"
				/>
				<label class="ml-1 mb-0" :for="'useDifferentInvoiceAddress'">
					{{ $t("checkout.lblUseDifferentInvoiceAddress") }}
				</label>
			</div>
		</div>
	</div>
	<div v-if="vm.checkoutForm.useDifferentInvoiceAddress" class="row">
		<div class="col-12 mb-2">
			<h3>{{ $t("checkout.titleBillingAddress") }}</h3>
			<AddressForm
				v-model="vm.checkoutForm.billingAddressForm"
				ref="billingAddressForm"
				:countries="vm.countries"
				@form-state-changed="checkIfDetailStepIsValid"
			/>
		</div>
	</div>
	<div class="row">
		<div class="col-12 mb-2">
			<h3>{{ $t("checkout.titlePersonalInfo") }}</h3>
			<PersonalForm
				v-model="vm.checkoutForm.personalForm"
				ref="personalForm"
				@form-state-changed="checkIfDetailStepIsValid"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import AddressForm from "@/components/common/forms/addressForm.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import CompanyForm from "@/components/common/forms/companyForm.vue";
import PersonalForm from "@/components/common/forms/personalForm.vue";
import { AddressForm as AddressFormModel } from "@/types/models/form/addressForm";
import { CompanyForm as CompanyFormModel } from "@/types/models/form/companyForm";
import { Component, Ref } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { ICheckout } from "@/types/viewModels/commerce/checkoutViewModel";
import { PersonalForm as PersonalFormModel } from "@/types/models/form/personalForm";
import { VatSituation } from "@/types/enum/vatSituation";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";

@Component({
	components: { PersonalForm, AddressForm, CompanyForm, Checkbox },
})
export default class CheckoutDetails extends BaseComponent {
	@Ref readonly companyForm!: FormComponent<CompanyFormModel>;
	@Ref readonly billingAddressForm!: FormComponent<AddressFormModel>;
	@Ref readonly personalForm!: FormComponent<PersonalFormModel>;

	checkoutStore = useCheckoutStore();

	mounted(): void {
		this.checkIfDetailStepIsValid();
	}

	get vm(): ICheckout {
		return this.checkoutStore.vm as ICheckout;
	}

	get useVat(): boolean {
		return this.vm.vatSituation !== VatSituation.NoVat;
	}

	checkIfDetailStepIsValid(): void {
		if (!this.companyForm?.form) return;
		const requiredForms: any[] = [this.companyForm.form.validate(), this.personalForm.form.validate()];

		if (this.vm.checkoutForm.useDifferentInvoiceAddress) {
			requiredForms.push(this.billingAddressForm.form.validate());
		}
		Promise.all(requiredForms).then((results) => {
			this.checkoutStore.isDetailStepValid = results.map((x) => x.valid).every(Boolean);
		});
	}
}
</script>

<style scoped lang="scss"></style>
