/* Auto Generated */

export enum TrainingCalculationLineType {
	module = 0,
	vdiEnvironment = 1,
	trainingMaterial = 2,
	trainingRoom = 3,
	standardTraining = 4,
	intake = 5,
}
