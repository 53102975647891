import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-2" }
const _hoisted_3 = { class: "p-field-checkbox mr-2 my-2" }
const _hoisted_4 = {
  class: "ml-1 mb-0",
  for: 'useDifferentInvoiceAddress'
}
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col-12 mb-2" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyForm = _resolveComponent("CompanyForm")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_AddressForm = _resolveComponent("AddressForm")!
  const _component_PersonalForm = _resolveComponent("PersonalForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("checkout.titleYourCompany")), 1),
        _createVNode(_component_CompanyForm, {
          modelValue: _ctx.vm.checkoutForm.companyForm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.checkoutForm.companyForm) = $event)),
          ref: "companyForm",
          "auto-complete": "",
          countries: _ctx.vm.countries,
          "has-previously-ordered": _ctx.vm.hasPreviouslyOrdered,
          "use-vat": _ctx.useVat,
          onFormStateChanged: _ctx.checkIfDetailStepIsValid
        }, null, 8, ["modelValue", "countries", "has-previously-ordered", "use-vat", "onFormStateChanged"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.vm.checkoutForm.useDifferentInvoiceAddress,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vm.checkoutForm.useDifferentInvoiceAddress) = $event)),
            id: 'useDifferentInvoiceAddress',
            binary: true,
            name: "roles"
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("checkout.lblUseDifferentInvoiceAddress")), 1)
        ])
      ])
    ]),
    (_ctx.vm.checkoutForm.useDifferentInvoiceAddress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("checkout.titleBillingAddress")), 1),
            _createVNode(_component_AddressForm, {
              modelValue: _ctx.vm.checkoutForm.billingAddressForm,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vm.checkoutForm.billingAddressForm) = $event)),
              ref: "billingAddressForm",
              countries: _ctx.vm.countries,
              onFormStateChanged: _ctx.checkIfDetailStepIsValid
            }, null, 8, ["modelValue", "countries", "onFormStateChanged"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("checkout.titlePersonalInfo")), 1),
        _createVNode(_component_PersonalForm, {
          modelValue: _ctx.vm.checkoutForm.personalForm,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.vm.checkoutForm.personalForm) = $event)),
          ref: "personalForm",
          onFormStateChanged: _ctx.checkIfDetailStepIsValid
        }, null, 8, ["modelValue", "onFormStateChanged"])
      ])
    ])
  ], 64))
}