import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-lg-6" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "form-group col-lg-6" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "form-row" }
const _hoisted_7 = { class: "form-group col-lg-6" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "p-error"
}
const _hoisted_10 = {
  key: 1,
  class: "p-error"
}
const _hoisted_11 = { class: "form-group col-lg-6" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = {
  key: 1,
  class: "p-error"
}
const _hoisted_15 = { class: "form-row" }
const _hoisted_16 = { class: "form-group col-lg-6" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['askAQuestionPersonalForm'].firstName?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblFirstName")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['askAQuestionPersonalForm'].firstName.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['askAQuestionPersonalForm'].firstName.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].firstName) }),
          type: "text"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].firstName))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].firstName?.required?.$message), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['askAQuestionPersonalForm'].lastName?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblLastName")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['askAQuestionPersonalForm'].lastName.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['askAQuestionPersonalForm'].lastName.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].lastName) }),
          type: "text"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].lastName))
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].lastName?.required?.$message), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['askAQuestionPersonalForm'].email?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblEmail")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['askAQuestionPersonalForm'].email.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['askAQuestionPersonalForm'].email.$model) = $event)),
          class: "mr-1 username-input",
          name: "email",
          type: "email"
        }, null, 8, ["modelValue"]),
        (_ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].email))
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              (!_ctx.v$['askAQuestionPersonalForm'].email.$model)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].email?.required?.$message), 1))
                : (_ctx.v$['askAQuestionPersonalForm'].email.$model)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].email.email?.$message), 1))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['askAQuestionPersonalForm'].phoneNumber?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblPhoneNumber")), 3),
        _createVNode(_component_vue_tel_input, {
          modelValue: _ctx.v$['askAQuestionPersonalForm'].phoneNumber.$model,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$['askAQuestionPersonalForm'].phoneNumber.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].phoneNumber) }),
          "input-options": { placeholder: '' },
          onOnInput: _ctx.onPhoneInputBlur,
          onValidate: _ctx.onPhoneInputValidate
        }, null, 8, ["modelValue", "class", "onOnInput", "onValidate"]),
        (_ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].phoneNumber))
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
              (!_ctx.v$['askAQuestionPersonalForm'].phoneNumber.$model)
                ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].phoneNumber?.required?.$message), 1))
                : (_ctx.v$['askAQuestionPersonalForm'].phoneNumber.$model)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].phoneNumber.validPhone?.$message), 1))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['askAQuestionPersonalForm'].company?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblCompanyName")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['askAQuestionPersonalForm'].company.$model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$['askAQuestionPersonalForm'].company.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].company) }),
          type: "text"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.shouldShowError(_ctx.v$['askAQuestionPersonalForm'].company))
          ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.v$["askAQuestionPersonalForm"].company?.required?.$message), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}