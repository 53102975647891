<template>
	<BackButton :label="$t('myCadac.trainingDetail.button.back')" />

	<div v-if="vm.training" class="d-flex align-items-center mt-2 mb-2">
		<img
			v-lazy="vm.training.productIconUrl"
			:alt="vm.training.productIconAlt"
			class="icon-training-item mr-2"
		/>
		<div class="d-flex flex-column justify-content-between">
			<p class="lead text-muted mb-0">{{ vm.training.brand }}</p>
			<h4 class="m-0">{{ vm.training.name }}</h4>
		</div>
	</div>

	<TrainingDetailSummary class="mb-2" :is-instructor="vm.isInstructor" :vm="vm.training">
		<Button
			v-if="vm.isParticipant && vm.isVdiTraining"
			class="p-button-success"
			icon="pi pi-play"
			:label="$t('myCadac.trainingDetail.button.startTeams')"
			:title="$t('myCadac.trainingDetail.button.startTeams_title')"
			@click="startTraining()"
		/>
	</TrainingDetailSummary>

	<TrainingParticipantsTable :vm="vm" />
</template>

<script lang="ts">
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import TrainingAddParticipantsModal from "@/components/training/trainingAddParticipantsModal.vue";
import TrainingDetailSummary from "@/components/training/trainingDetailSummary.vue";
import TrainingParticipantsTable from "@/components/training/trainingParticipantsTable.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IPlannedTrainingDetail } from "@/types/viewModels/myCadac/plannedTrainingDetailViewModel";
import { PropType } from "vue";

@Component({
	components: {
		TrainingDetailSummary,
		TrainingParticipantsTable,
		TrainingAddParticipantsModal,
		BackButton,
	},
})
export default class PlannedTrainingDetail extends BaseComponent {
	@Prop({
		type: Object as PropType<IPlannedTrainingDetail>,
		required: true,
		default: {},
	})
	vm!: IPlannedTrainingDetail;

	startTraining(): void {
		this.openUrl(this.vm.training.teamUrl, "blank");
	}
}
</script>

<style lang="scss" scoped>
.icon-training-item {
	width: 64px;
	height: 64px;
}
</style>
