<template>
	<div class="advice-card pb-3">
		<div class="advice-card-content">
			<div class="advice-card-header">
				<div class="">
					<h3 class="text-primary font-weight-normal text-center">
						{{ product.currentProduct.name }}
					</h3>
					<div class="advice-card-header-icons">
						<Avatar :image="product.currentProduct.iconUrl" size="xlarge" />
						<font-awesome-icon
							v-if="product.nextStepAdvices.length > 1"
							:icon="['far', 'arrow-right-long']"
							size="lg"
						/>
						<AvatarGroup v-if="product.nextStepAdvices.length > 1">
							<Avatar
								v-for="(nextStepAdvice, index) in product.nextStepAdvices.slice(
									0,
									maxNextStepAdviceIcons
								)"
								:key="`nextStepAdvice_${index}`"
								:image="nextStepAdvice.iconUrl"
								size="large"
							/>
							<Avatar
								v-if="product.nextStepAdvices.length > maxNextStepAdviceIcons"
								class="plus-badge"
								:label="`+${product.nextStepAdvices.length - maxNextStepAdviceIcons}`"
								shape="circle"
								size="large"
							/>
						</AvatarGroup>
					</div>
					<div class="mt-2 text-center">
						<i18n-t
							class="text-center"
							keypath="myCadac.advisorAdvice.contractNumber"
							scope="global"
							tag="p"
						>
							<template #contractNumber
								><b>{{ product.currentContractNumber }} </b></template
							>
						</i18n-t>

						<i18n-t
							class="text-center"
							keypath="myCadac.advisorAdvice.licenceNumber"
							scope="global"
							tag="p"
						>
							<template #licenceNumber
								><b>{{ product.currentSerialNumber }} </b></template
							>
						</i18n-t>

						<i18n-t
							class="text-center"
							keypath="myCadac.advisorAdvice.amountSeats"
							scope="global"
							tag="p"
						>
							<template #seats>
								<b>{{ product.currentProduct.seats }}</b>
							</template>
						</i18n-t>
					</div>
				</div>
			</div>
			<AdvisorProductAdviceContent
				:collapsed="collapsed"
				:current-product="product.currentProduct"
				:next-step-advices="product.nextStepAdvices"
			/>
		</div>
		<div v-if="product.nextStepAdvices.length > 1" class="expand-button" @click="collapsed = !collapsed">
			<font-awesome-icon :icon="['fas', collapsed ? 'chevrons-down' : 'chevrons-up']" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProgressSpinner from "primevue/progressspinner";
import { Component, Prop } from "vue-facing-decorator";
import AdvisorTokenAdvice from "@/components/advisor/advisorTokenAdvice.vue";
import { PropType } from "vue";
import { ProductAdviceViewModel } from "@/types/models/advisor/productAdviceViewModel";
import AdvisorProductAdviceContent from "@/components/advisor/advisorProductAdviceContent.vue";

@Component({ components: { AdvisorProductAdviceContent, AdvisorTokenAdvice, ProgressSpinner } })
export default class AdvisorAdviceOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ProductAdviceViewModel>,
		required: true,
	})
	product!: ProductAdviceViewModel;

	collapsed = true;
	maxNextStepAdviceIcons = 3;
}
</script>

<style scoped lang="scss">
.advice-card {
	position: relative;
	background: #ffffff;
	color: #495057;
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 3px;

	&.p-card,
	.p-card-body,
	.p-card-content {
		height: 100%;
	}
	.p-card-body {
		padding: 0;
	}

	.advice-card-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;

		.p-button {
			margin-top: auto;
		}

		.advice-card-header {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 1rem;
			width: 100%;
		}

		.advice-card-header-icons {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			width: 100%;
		}
	}
}

::v-deep(.p-avatar) {
	img {
		background-color: var(--surface-a);
		width: 100%;
		height: 100%;
	}
}

.plus-badge {
	background-color: #b9b9b9;
	color: #ffffff;
}

.expand-button {
	position: absolute;
	bottom: 0;
	left: 50%;
	padding: 1rem;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	align-items: center;
	display: flex;
	justify-content: center;
	transform: translate(-50%, 50%);
	background-color: #ffffff;

	&:hover {
		background-color: var(--success-color);
		color: #ffffff;
		cursor: pointer;
	}
}
</style>
