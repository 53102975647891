<template>
	<div>
		<Card>
			<template #content>
				<p class="text-preline" v-html="vm.intro" />
				<LinkButton
					v-if="vm.link"
					class="my-2"
					:class="{
						[ctaButtonClass(vm.linkStyle)]: ctaButtonClass(vm.linkStyle),
						'p-button-success p-button-raised p-button-arrow': !ctaButtonClass(vm.linkStyle),
					}"
					:href="vm.link.url"
					:label="vm.link.text"
					:target="vm.link.target"
					:title="vm.link.title"
					:url="vm.link.url"
				/>
			</template>
		</Card>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { CallToActionSimpleBlockViewModel } from "@/types/viewModels/blocks/callToActionSimpleBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component
export default class CallToActionSimpleBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<CallToActionSimpleBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: CallToActionSimpleBlockViewModel;

	created(): void {
		Log.info("CallToActionSimpleBlock", this.vm);
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-card) {
	.p-card-content {
		padding: 0;
	}
}
</style>
