import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "p-dialog-mask p-component-overlay loading-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavBar = _resolveComponent("MainNavBar")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_DynamicDialog = _resolveComponent("DynamicDialog")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.vm?.hideMenuAndFooter)
      ? (_openBlock(), _createBlock(_component_MainNavBar, { key: 0 }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (!_ctx.vm?.hideMenuAndFooter)
      ? (_openBlock(), _createBlock(_component_MainFooter, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_ConfirmDialog, {
      breakpoints: { '768px': '70vw', '576px': '90vw' },
      style: { width: '30vw' }
    }),
    _createVNode(_component_DynamicDialog, {
      breakpoints: {
			'2000px': '35vw',
			'1600px': '40vw',
			'1200px': '50vw',
			'992px': '60vw',
			'768px': '75vw',
			'576px': '90vw',
		},
      modal: true,
      style: { width: '30vw' }
    }),
    _createVNode(_component_Toast, {
      "auto-z-index": false,
      "base-z-index": 1001,
      position: "top-right"
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressSpinner, {
            "animation-duration": "1.75s",
            "stroke-width": "3.5"
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent('script'), { type: "application/ld+json" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.breadCrumbsLinkedData), 1)
        ]),
        _: 1
      })),
      (_openBlock(), _createBlock(_resolveDynamicComponent('script'), { type: "application/ld+json" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.searchLinkedData), 1)
        ]),
        _: 1
      }))
    ]))
  ], 64))
}