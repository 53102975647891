<template>
	<Inplace v-if="!layoutStore.isImpersonated" class="">
		<template #display>
			<Button class="p-button-outlined p-button-sm" :label="$t('menu.button.impersonateUser')" />
		</template>
		<template #content>
			<div class="d-flex">
				<InputText
					v-model="impersonationUserName"
					class="mr-1 username-input"
					name="userName"
					:placeholder="$t('menu.impersonateUser_placeholder')"
					type="email"
					@keyup.enter="impersonateUser()"
				/>
				<Button
					class="p-button-outlined p-button-sm"
					:disabled="!impersonationUserName"
					:label="$t('common.login')"
					:icon="isValidInput() ? 'pi pi-check' : ''"
					@click="impersonateUser()"
				/>
			</div>
		</template>
	</Inplace>

	<Button
		class="p-button-text p-button-rounded"
		:class="{ 'p-button-info': layoutStore.isImpersonated }"
		:title="$t('menu.button.refreshUserData_title')"
		@click="refreshUserInfo()"
	>
		<font-awesome-icon :icon="['far', 'rotate-right']" size="lg" />
	</Button>
	<Button
		v-if="layoutStore.isImpersonated"
		class="p-button-text p-button-rounded p-button-info"
		:title="$t('menu.button.cancelImpersonation_title')"
		@click="cancelImpersonation()"
	>
		<font-awesome-icon :icon="['far', 'arrow-right-from-bracket']" size="lg" />
	</Button>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";

@Component
export default class ImpersonationForm extends BaseComponent {
	impersonationUserName = "";

	/** Input can be an email address or an order number [ORD123...] If correct only shows icon checkmark in button */
    isValidInput() {
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const orderNumberPattern = /^ORD\d+$/;
		return (emailPattern.test(this.impersonationUserName) || orderNumberPattern.test(this.impersonationUserName));
	}

	impersonateUser(): void {
		this.loadingStore.increaseLoadingCount();
		localStorage.setItem("showedCompanyModal", "false");
		window.location.href = `/MyCadacStore/ImpersonateUser?userName=${this.impersonationUserName}`;
	}

	cancelImpersonation(): void {
		this.loadingStore.increaseLoadingCount();
		window.location.href = `/MyCadacStore/RevertImpersonation`;
	}

	refreshUserInfo(): void {
		this.loadingStore.increaseLoadingCount();
		window.location.href = `/MyCadacStore/RefreshUser`;
	}
}
</script>

<style scoped lang="scss">
.username-input {
	width: clamp(250px, 300px, 350px);
}
</style>
