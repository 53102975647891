<template>
	<ProductOverviewLayout
		:vm="overviewVm"
		@on-filter-change="onFilterChange"
		@on-search="onSearch"
		@on-sort-change="onSortChange"
	>
		<template #header>
			<div class="pt-3 pb-2">
				<slot name="breadcrumbs" />
				<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
			</div>
		</template>
		<template #grid-item="{ data }">
			<div
				v-if="!isSpinnerVisible('product-overview')"
				class="mb-2 col"
				:class="data?.products?.length ? 'col-md-6' : 'col-md-6 col-xxl-4'"
			>
				<ExpertOverviewItemCard :item="data" layout="grid" />
			</div>
			<div v-else class="col-md-6 col-xxl-4">
				<ExpertOverviewItemCardSkeleton type="grid" />
			</div>
		</template>
		<template #list-item="{ data }">
			<ExpertOverviewItemCard v-if="!isSpinnerVisible('product-overview')" :item="data" layout="list" />
			<ExpertOverviewItemCardSkeleton v-else type="list" />
		</template>
	</ProductOverviewLayout>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import ExpertFilters from "@/components/experts/expertFilters.vue";
import ExpertOverviewItemCard from "@/components/experts/expertOverviewItemCard.vue";
import ExpertOverviewItemCardSkeleton from "@/components/experts/expertOverviewItemCardSkeleton.vue";
import ProductOverviewLayout, { IProductOverviewLayout } from "@/views/layout/productOverviewLayout.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertItemViewModel } from "@/types/models/expert/expertItemViewModel";
import { IExpertOverview } from "@/types/viewModels/commerce/expertOverviewViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { OverviewFilterViewModel } from "@/types/models/common/filters/overviewFilterViewModel";
import { OverviewSortType } from "@/types/enum/overviewSortType";
import { PropType } from "vue";
import { useProductOverviewFilterStore } from "@/store/common/productOverviewFiltersStore";

@Component({
	components: {
		Chip,
		Divider,
		ExpertFilters,
		ExpertOverviewItemCard,
		ExpertOverviewItemCardSkeleton,
		ProductOverviewLayout,
	},
})
export default class ExpertOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<IExpertOverview>,
		required: true,
		default: {},
	})
	vm!: IExpertOverview;

	overviewItems: ExpertItemViewModel[] = [];

	filterStore = useProductOverviewFilterStore();

	created(): void {
		Log.info("ExpertOverview", this.vm);
		this.overviewItems = this.vm.overviewItems;
		this.filterStore.init(this.vm.filters);
		this.filterStore.addExpertSortOptions();
	}

	get overviewVm(): IProductOverviewLayout {
		return { productType: ItemProductType.Expert, items: this.overviewItems, defaultViewLayout: "list" };
	}

	onSortChange(sortType: OverviewSortType): void {
		this.filterStore.sortType = sortType;
		this.updateFilters();
	}

	onFilterChange(_filter: OverviewFilterViewModel): void {
		this.updateFilters();
	}

	onSearch(query: string): void {
		if (!this.filterStore.hasQueryFilter) return;
		this.filterStore.updateSearchQuery(query);
		this.updateFilters();
	}

	private updateFilters(): void {
		this.loadingStore.showSpinner("product-overview");
		this.loadingStore.increaseLoadingCount();
		this.filterStore
			.updateExpertFilters()
			.then((data) => {
				this.overviewItems = data?.overviewItems ? [...data.overviewItems] : [];
			})
			.catch((err) => Log.error(err))
			.finally(() => {
				this.loadingStore.hideSpinner("product-overview");
				this.loadingStore.decreaseLoadingCount();
			});
	}
}
</script>

<style lang="scss" scoped>
:deep(.p-dataview .p-dataview-header) {
	top: var(--navbarHeightInPx);
}
</style>
