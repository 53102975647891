import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-716b2252"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container my-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "lead text-preline mt-1" }
const _hoisted_7 = { class: "row mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "login-wrapper"
}
const _hoisted_9 = { class: "bg-primary p-4 w-100" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "mt-2" }
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 1,
  class: "col-12 col-lg-7 px-2 px-lg-4"
}
const _hoisted_14 = { class: "d-flex align-items-center" }
const _hoisted_15 = { class: "pb-2 pl-2" }
const _hoisted_16 = { class: "required" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileImage = _resolveComponent("ProfileImage")!
  const _component_PersonalForm = _resolveComponent("PersonalForm")!
  const _component_CompanyForm = _resolveComponent("CompanyForm")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.vm.title)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.vm.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.vm.text), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (!_ctx.layoutStore.isAuthenticated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("expert.accountPage.loginTitle")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("expert.accountPage.loginText")), 1),
              _createElementVNode("a", {
                class: "p-button p-component p-button-success p-button-arrow p-button-raised mt-2",
                href: _ctx.loginPageUrl
              }, _toDisplayString(_ctx.$t("common.login")), 9, _hoisted_10),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("a", { href: _ctx.registerPageUrl }, _toDisplayString(_ctx.$t("common.register")), 9, _hoisted_12)
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("expert.accountPage.profileTitle")), 1),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_ProfileImage, {
                  "can-change-image": "",
                  "profile-image-url": _ctx.profileImageUrl,
                  onOnProfilePictureChange: _ctx.onProfilePictureChange
                }, null, 8, ["profile-image-url", "onOnProfilePictureChange"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("expert.accountPage.profilePicture")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("expert.accountPage.uploadExplanation")), 1)
                ])
              ]),
              (_ctx.submitted && !_ctx.profileImageUrl)
                ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.$t("validations.validImage")), 1))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.vm.isInternalExpert)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_PersonalForm, {
                    modelValue: _ctx.vm.personalForm,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.personalForm) = $event)),
                    ref: "personalForm"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_CompanyForm, {
                    modelValue: _ctx.vm.companyForm,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vm.companyForm) = $event)),
                    ref: "companyForm",
                    countries: _ctx.vm.countries,
                    "use-iban": "",
                    "use-vat": ""
                  }, null, 8, ["modelValue", "countries"])
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, {
              class: "p-button-success p-button-arrow p-button-raised mt-2",
              disabled: _ctx.submitted && !_ctx.canSubmit,
              label: _ctx.$t('common.nextStep'),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit()))
            }, null, 8, ["disabled", "label"])
          ]))
    ])
  ]))
}