<template>
	<div class="hero" :class="backgroundColor">
		<video
			v-if="vm.backgroundVideoUrl"
			autoplay
			class="video-header__video"
			height="100%"
			loop
			muted
			width="100%"
		>
			<source :src="vm.backgroundVideoUrl" />
		</video>

		<div v-else-if="vm.backgroundImage?.url" class="hero__background">
			<img :alt="vm.backgroundImage?.alt" class="hero__background__image" :src="vm.backgroundImage?.url" />
		</div>

		<div v-else-if="vm.heroBackgroundColor === HeroBackgroundColorEnum.magenta">
			<svg class="hero__background-circles" viewBox="0 0 1154 1154">
				<g fill="#FFF" fill-rule="evenodd">
					<circle cx="577" cy="577" opacity=".033" r="577" />
					<circle cx="577.5" cy="576.5" opacity=".037" r="405.5" />
				</g>
			</svg>
		</div>

		<div class="hero__body">
			<div class="container">
				<div class="row row-gap-2">
					<div class="col-md-8">
						<h1
							class="display-3 text-shadow"
							:class="{
								'text-white':
									hasBackground || vm.heroBackgroundColor === HeroBackgroundColorEnum.magenta,
							}"
						>
							{{ vm.title }}
						</h1>
						<h3
							class="display-5"
							:class="{
								'text-white':
									hasBackground || vm.heroBackgroundColor === HeroBackgroundColorEnum.magenta,
							}"
						>
							{{ vm.subtitle }}
						</h3>
						<HeroSearchBar v-if="!vm.hideSearch" :vm="searchBarVm" />

						<div v-if="vm.firstCtaLink" class="d-flex hero__footer mt-3 gap-2">
							<LinkButton
								v-if="vm.firstCtaLink"
								class="p-button-link p-button-primary"
								:class="{
									'text-white': vm.heroBackgroundColor === HeroBackgroundColorEnum.magenta,
								}"
								:label="vm.firstCtaLink.text"
								:target="vm.firstCtaLink.target"
								:title="vm.firstCtaLink.title"
								:url="vm.firstCtaLink.url"
							/>
							<LinkButton
								v-if="vm.secondCtaLink"
								class="p-button-link p-button-primary"
								:class="{
									'text-white': vm.heroBackgroundColor === HeroBackgroundColorEnum.magenta,
								}"
								:label="vm.secondCtaLink.text"
								:target="vm.secondCtaLink.target"
								:title="vm.secondCtaLink.title"
								:url="vm.secondCtaLink.url"
							/>
						</div>
					</div>
					<div v-if="vm.serviceIntro && vm.serviceTitle" class="col-md-4 mt-2 m-md-0">
						<Card>
							<template #header>
								<div class="d-flex p-2">
									<Avatar
										v-if="vm.serviceImage"
										class="mr-2 service-img flex-shrink-0"
										shape="circle"
									>
										<img
											v-lazy="vm.serviceImage?.url"
											:alt="vm.serviceImage?.alt"
											height="80px"
											width="80px"
										/>
									</Avatar>
									<div class="d-flex flex-column">
										<h3 v-if="vm.serviceTitle" class="lead font-weight-bold mb-0">
											{{ vm.serviceTitle }}
										</h3>
										<p class="text-muted">{{ vm.serviceIntro }}</p>
									</div>
								</div>
							</template>
							<template #content>
								<div class="d-flex flex-column align-items-start">
									<LinkButton
										v-if="vm.askAQuestionPageUrl"
										class="ml-0 mb-1 p-button-link flex-1"
										icon="pi pi-envelope"
										:label="$t('support.button.askAQuestion')"
										:url="vm.askAQuestionPageUrl"
									/>

									<Button
										v-if="layoutStore.chatWidgetAvailable"
										class="ml-0 mb-1 p-button-link flex-1"
										icon="pi pi-comment"
										:label="$t('support.button.chatWithUs')"
										@click="initChat()"
									/>
								</div>
							</template>
						</Card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import HeroSearchBar, { IHeroSearchBar } from "@/components/common/heroSearchBar.vue";
import LinkButton from "@/components/common/linkButton.vue";
import { Component, Prop } from "vue-facing-decorator";
import { HeroBackgroundColor } from "@/types/enum/heroBackgroundColor";
import { HeroFaqBlockViewModel } from "@/types/viewModels/blocks/heroFaqBlockViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SearchCategory } from "@/types/enum/searchCategory";

@Component({ components: { HeroSearchBar, LinkButton } })
export default class HeroFaqBlock extends BaseComponent {
	@Prop({ type: Object as PropType<HeroFaqBlockViewModel>, required: true, default: {} })
	vm!: HeroFaqBlockViewModel;

	created(): void {
		Log.info("HeroFaqBlock", this.vm);
	}

	get hasBackground(): boolean {
		return !!this.vm.backgroundImage?.url || !!this.vm.backgroundVideoUrl;
	}

	get HeroBackgroundColorEnum(): typeof HeroBackgroundColor {
		return HeroBackgroundColor;
	}

	get searchBarVm(): IHeroSearchBar {
		return {
			hideAdvancedSearchButton: true,
			defaultSearchCategory: SearchCategory.faq,
			searchPlaceholder: this.vm.searchPlaceholder,
			searchCategoryOptions: [],
		};
	}

	get backgroundColor(): string {
		switch (this.vm.heroBackgroundColor) {
			case HeroBackgroundColor.grey:
				return "surface-c";
			case HeroBackgroundColor.magenta:
				return "background-purple";
			default:
				return "";
		}
	}

	initChat(): void {
		this.chatWidget?.open({ name: "Support", replyText: "I have a question" });
	}
}
</script>

<style scoped lang="scss">
.hero__footer {
	padding-top: 1.5rem;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.service-img {
	width: 80px;
	height: 80px;

	img {
		object-fit: cover;
	}
}

::v-deep(.p-card) {
	padding: 1rem 0.5rem;

	.p-card-content {
		padding: 0 0.5rem;
	}

	.p-card-body {
		padding: 1rem 0.5rem 0.5rem 0.5rem;
	}
}
</style>
