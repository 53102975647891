import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "align-self-center mr-1" }
const _hoisted_3 = ["alt", "title"]
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = { class: "mb-0 text-muted lead" }
const _hoisted_7 = { class: "d-flex flex-wrap" }
const _hoisted_8 = { class: "flex-1 pb-2" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "modal-image" }
const _hoisted_11 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '45vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    class: "p-dialog-secondary partner-info-modal",
    closable: "",
    "close-on-escape": "",
    draggable: false,
    modal: "",
    style: { width: '40vw' },
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            shape: "circle",
            size: "xlarge"
          }, {
            default: _withCtx(() => [
              (_ctx.vm.iconUrl)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: _ctx.vm.iconAlt,
                    title: _ctx.vm.name
                  }, null, 8, _hoisted_3)), [
                    [_directive_lazy, _ctx.vm.iconUrl]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.vm.name), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.vm.addressString), 1)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", {
            class: "mb-0 preserve-html",
            innerHTML: _ctx.vm?.introText
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _withDirectives(_createElementVNode("img", {
            alt: _ctx.vm.imageAlt
          }, null, 8, _hoisted_11), [
            [_directive_lazy, _ctx.vm.mainImageUrl]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}