<template>
	<div class="col-md-8">
		<ExpertProfile :expert-product="vm?.expertProduct" :expert-profile="vm?.expertProfile" />
	</div>
	<div id="expert-booking-container" class="col-md-4">
		<Card class="expert-booking-card" :class="{ 'position-static': isLargeScreen }">
			<template #title>
				{{ `${$t("expert.titleBookingCard", [bookingSummary?.expertDetails?.firstName])}` }}
			</template>
			<template #subtitle>
				<b>{{ formatCurrency(bookingSummary?.expertDetails?.hourlyRate || 0) }} </b>
				{{ $t("expert.hourExclTax") }}
			</template>
			<template #content>
				<div v-if="canBookExpert">
					<div>
						<h5>{{ $t(`expert.workRemoteOrOnLocation`) }}</h5>
						<SelectButton
							v-model="selectedLocationOption"
							class="d-flex"
							option-label="label"
							:options="locationOptions"
							:unselectable="false"
						/>
					</div>

					<div v-if="!isRemote" class="mt-2">
						<div class="d-flex">
							<h5>{{ $t(`expert.workLocation`) }}</h5>
						</div>
						<div v-if="editWorkLocation" class="d-flex align-items-center mb-1">
							<GMapAutocomplete
								class="w-100"
								:placeholder="$t(`expert.searchYourLocation`)"
								@change="onLocationSelected"
								@place_changed="onPlaceChanged"
							>
							</GMapAutocomplete>
						</div>
						<div v-else class="d-flex align-items-center mb-1">
							<i
								class="pi pi-map-marker text-primary"
								:title="$t('cart.table.iconLocation_title')"
							/>
							<span class="text-bold ml-1 text-preline">
								{{ bookingSummary.configuration.workLocation.name }}
							</span>
							<Button
								class="p-button-text ml-auto"
								icon="pi pi-pencil"
								:label="isSmallScreen ? $t('common.edit') : ''"
								:title="$t('common.edit')"
								@click="editWorkLocation = true"
							/>
						</div>
					</div>

					<div class="mt-2">
						<h5 class="mb-1">
							{{
								` ${$t("expert.whenDoYouWantToBook", [bookingSummary?.expertDetails?.firstName])}`
							}}
						</h5>
						<div
							v-for="(selected, index) in selectedDates"
							:key="JSON.stringify(selected)"
							class="d-flex flex-row align-items-center mb-1"
						>
							<Chip
								class="custom-chip"
								:class="{
									'surface-e': index === editDateIndex,
									'p-disabled': editDateIndex != null && index !== editDateIndex,
								}"
								:label="`${formatDate(selected.date)} ${formatTime(selected.start)} - ${formatTime(selected.end)}`"
								removable
								@click.stop="openDate(selected, index)"
								@remove.stop="deleteDate(index, selected)"
							/>
						</div>
						<div v-if="visibleStep === 1">
							<Button
								v-if="selectedDates?.length"
								class="p-button-secondary p-button-text mb-2 mt-1 px-0"
								icon="pi pi-calendar-plus p-button-icon-lg"
								:label="$t(`expert.button.addAnotherDate`)"
								@click="() => (visibleStep = 2)"
							/>
							<Button
								v-else
								class="w-100 mb-2"
								:label="$t(`expert.button.chooseDate`)"
								@click="() => (visibleStep = 2)"
							/>
						</div>

						<div v-else-if="visibleStep === 2">
							<ExpertBookingCalendar
								v-model="selectedDate"
								:availability-dates="bookingSummary?.availabilityDates"
								:calendar-max-date="bookingSummary?.calendarMaxDate"
								:calendar-min-date="bookingSummary?.calendarMinDate"
								:selected-date-starting-hour="selectedDateStartingHour"
								:selected-dates="selectedDates"
								:selected-location-option="selectedLocationOption"
								:total-work-hours="totalWorkHours"
								@date-select="onDateSelected"
							/>
						</div>

						<div v-else-if="visibleStep === 3">
							<Button
								class="p-button-outlined w-100 mb-2"
								:label="formatDate(selectedDate)"
								@click="visibleStep = 2"
							/>

							<SelectButton
								v-model="selectedDayParts"
								class="d-flex day-part-select"
								option-disabled="isDisabled"
								option-label="label"
								:options="dayParts"
								:unselectable="false"
								@change="resetSelectedHours"
							>
								<template #option="slotProps">
									<span class="p-button-label"
										>{{ slotProps.option.label
										}}{{ slotProps.option.isDisabled ? "*" : "" }}</span
									>
								</template>
							</SelectButton>
							<SelectButton
								v-if="selectedDayParts?.value === ExpertBookingDateType.morning"
								v-model="selectedDayPartsTimes"
								class="mt-2 d-flex"
								option-disabled="isDisabled"
								option-label="label"
								:options="dayPartsTimes"
								:unselectable="false"
							/>

							<div v-if="concatenatedUnavailableHours.length" class="my-2">
								<small class="text-warning"
									>{{ $t("expert.lblExpertNotAvailableBetween") }}
								</small>
								<ul class="ml-2">
									<li
										v-for="(unavailableHour, index) in concatenatedUnavailableHours"
										:key="`unavailable-hour-${index}`"
									>
										<small class="text-warning">
											- {{ formatTime(unavailableHour.start.time) }} -
											{{ formatTime(unavailableHour.end.time) }}
										</small>
									</li>
								</ul>
							</div>
							<div v-if="selectedDayParts?.value === ExpertBookingDateType.hour">
								<div class="mx-1">
									<ExpertBookingSlider
										v-model="selectedHours"
										:selected-date="selectedDate"
										:unavailable-hours="concatenatedUnavailableHours"
										:work-day-end-hour="workDayEndHour"
										:work-day-start-hour="workDayStartHour"
										@onValidityChange="onValidityChange"
									/>
								</div>
							</div>
						</div>

						<div v-if="visibleStep === 1 && selectedDates.length">
							<div class="d-flex flex-row align-items-center justify-content-start my-2">
								<InputSwitch v-model="isFlexBooking" />
								<p class="leading mb-0 ml-1">
									{{
										$t(`enum.expertCalculationLineType.5`, [
											vm.bookingSummary.expertDetails.flexBookingPercentage,
										])
									}}
								</p>
								<PopoverIcon class="d-inline-flex ml-0">
									<i class="popover-icon pi pi-question" />
									<template #content>
										<div
											v-html="
												$t(`expert.withFlexBookingYouCanCancelYourBookingFor`, [
													$tc(
														'common.days',
														bookingSummary?.expertDetails?.flexBookingCancellationTerm
													),
												])
											"
										/>
									</template>
								</PopoverIcon>
							</div>

							<ProgressSpinner
								v-if="isSpinnerVisible('priceSummary')"
								animation-duration=".75s"
								class="d-flex justify-content-center mt-3 mb-3"
								stroke-width="3"
							/>
							<PriceSummary v-else :vm="priceSummary" />
							<WalletMessage
								v-show="vm?.bookingSummary.canUseWallet"
								:wallet-amount="expertPriceCalculation?.walletAmount || 0"
							/>
						</div>
					</div>
				</div>
				<Message v-else :closable="false" severity="warn">
					<span class="text-preline">
						<i18n-t keypath="expert.message.expertNotAvailable">
							<a :href="`tel:${$t('common.support.phoneNumber')}`">{{
								$t("common.support.phoneNumber")
							}}</a>
						</i18n-t>
					</span>
				</Message>
			</template>
			<template #footer>
				<div v-if="visibleStep !== 1" class="d-flex justify-content-end">
					<Button
						class="p-button-secondary p-button-text mr-2"
						:label="$t('common.cancel')"
						@click="cancelBooking()"
					/>
					<Button
						class="p-button-primary"
						:disabled="!canAddDate"
						:label="isEditingDate ? $t(`expert.button.editDate`) : $t(`expert.button.addDate`)"
						@click="onDateAdded()"
					/>
				</div>
				<div v-else>
					<Button
						class="p-button-success p-button-raised p-button-arrow w-100 px-1 mb-1"
						:disabled="!canSubmit"
						:label="
							isRequestBooking ? $t(`expert.button.sendRequest`) : $t(`expert.button.confirmBooking`)
						"
						@click="openBookingModal()"
					/>
				</div>
			</template>
		</Card>
	</div>
	<ExpertBookingModal
		v-if="expertStore.isBookingRequestModalVisible"
		:booking-summary="bookingSummary"
		:modal-type="modalType"
		@submit="onModalSubmit"
	/>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedModel" />
	<StickyScrollToButton
		v-if="isMediumScreen"
		:button-label="$t(`expert.button.bookNow`)"
		:offset="80"
		parent-container-id="expert-booking-container"
		target=".expert-booking-card"
	/>
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import Button from "primevue/button";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import ExpertBookingCalendar, {
	ExpertBookingDateSelectedEvent,
} from "@/components/experts/expertBookingCalendar.vue";
import ExpertBookingModal, {
	ExpertBookingForm,
	ExpertBookingModalType,
} from "@/components/experts/expertBookingModal.vue";
import ExpertBookingSlider from "@/components/experts/expertBookingSlider.vue";
import ExpertProfile from "@/components/experts/expertProfile.vue";
import InputSwitch from "primevue/inputswitch";
import PriceSummary from "@/components/common/priceSummary.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import ProgressSpinner from "primevue/progressspinner";
import SelectButton from "primevue/selectbutton";
import SplitButton from "primevue/splitbutton";
import StickyScrollToButton from "@/components/common/stickyScrollToButton.vue";
import WalletMessage from "@/components/common/WalletMessage.vue";
import { BookingRequestRequester } from "@/types/models/expert/bookingRequestRequester";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { ExpertBookedHour } from "@/types/models/expert/expertBookedHour";
import { ExpertBookingDate } from "@/types/models/expert/expertBookingDate";
import { ExpertBookingDateType } from "@/types/enum/expertBookingDateType";
import { ExpertBookingLocationType } from "@/types/enum/expertBookingLocation";
import { ExpertBookingSummaryViewModel } from "@/types/models/expert/expertBookingSummaryViewModel";
import { ExpertBookingWorkLocation } from "@/types/models/expert/expertBookingWorkLocation";
import { ExpertCalculationLineResponse } from "@/types/models/expert/expertCalculationLineResponse";
import { ExpertCalculationLineType } from "@/types/enum/expertCalculationLineType";
import { ExpertCalculationRequest } from "@/types/models/expert/expertCalculationRequest";
import { ExpertCalculationResponse } from "@/types/models/expert/expertCalculationResponse";
import { ExpertDateAvailability } from "@/types/enum/expertDateAvailability";
import { ExpertItemViewModel } from "@/types/models/expert/expertItemViewModel";
import { ExpertProductAddToCartRequest } from "@/types/models/expert/expertProductAddToCartRequest";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { IExpertDetail } from "@/types/viewModels/commerce/expertDetailViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SendBookingRequestRequest } from "@/types/models/expert/sendBookingRequestRequest";
import { UnavailableHours } from "@/types/models/expert/expertBookingSlider";
import { addHours, compareAsc, differenceInHours, getHours, set } from "date-fns";
import { cloneDeep, first, last, orderBy } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";
import { useExpertStore } from "@/store/expert/expertStore";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import toFixed from "accounting-js/lib/toFixed.js";
import { ExpertPageState } from "../../types/enum/expertPageState";

const { getDateWithoutTime } = FormatHelper;

@Component({
	components: {
		AutoComplete,
		Button,
		Chip,
		Divider,
		ExpertBookingCalendar,
		ExpertBookingModal,
		ExpertBookingSlider,
		ExpertProfile,
		InputSwitch,
		PriceSummary,
		ProductAddedModal,
		ProgressSpinner,
		SelectButton,
		SplitButton,
		StickyScrollToButton,
		WalletMessage,
	},
})
export default class ExpertDetail extends BaseComponent {
	@Prop({ type: Object as PropType<IExpertDetail>, required: true, default: {} }) vm!: IExpertDetail;

	@Watch("selectedDates", { deep: true })
	onSelectedDatesChanged(): void {
		this.calculatePrice();
		this.isRequestBooking = this.selectedDates.some((element) => {
			return this.getAvailabilityType(this.toDate(element.start)) === ExpertDateAvailability.onRequest;
		});
	}

	@Watch("isRemote")
	@Watch("selectedDate", { deep: true })
	@Watch("selectedDates", { deep: true })
	@Watch("isEditingDate", { deep: true })
	onSelectedDateChanged(): void {
		let bookedHours =
			this.bookingSummary?.availabilityDates
				?.find((x) => {
					return (
						compareAsc(
							getDateWithoutTime(this.selectedDate),
							getDateWithoutTime(this.toDate(x.date))
						) === 0
					);
				})
				?.bookedHours?.map((bookedHour) => {
					return {
						start: new Date(bookedHour.start),
						end: new Date(bookedHour.end),
					};
				}) || [];

		this.selectedDates.map((date) => {
			if (
				compareAsc(getDateWithoutTime(this.selectedDate), getDateWithoutTime(this.toDate(date.date))) === 0
			)
				bookedHours.push({ start: this.toDate(date.start), end: this.toDate(date.end) });
		});

		if (this.isEditingDate)
			bookedHours = bookedHours.filter((x) => {
				{
					return !(
						getHours(this.toDate(x.start)) === this.selectedHours[0] &&
						getHours(this.toDate(x.end)) === this.selectedHours[1]
					);
				}
			});

		const expertMidDay = this.workDayStartHour + this.totalWorkHours / 2;

		const hasAfternoonBookings =
			bookedHours?.some((hour) => {
				return (
					this.getSliderPositionFromDate(this.toDate(hour.start)) >= expertMidDay ||
					this.getSliderPositionFromDate(this.toDate(hour.end)) > expertMidDay
				);
			}) || false;

		const hasMorningBookings =
			bookedHours?.some((hour) => {
				return (
					this.getSliderPositionFromDate(this.toDate(hour.start)) < expertMidDay ||
					this.getSliderPositionFromDate(this.toDate(hour.end)) <= expertMidDay
				);
			}) || false;

		this.dayParts = [
			{
				label: this.$t(`enum.expertBookingDateType.${ExpertBookingDateType.fullDay}`),
				value: ExpertBookingDateType.fullDay,
				isDisabled: hasAfternoonBookings || hasMorningBookings,
			},
			{
				label: this.$t("expert.partOfDay"),
				value: ExpertBookingDateType.morning || ExpertBookingDateType.afternoon,
				isDisabled: hasAfternoonBookings && hasMorningBookings,
			},
			{
				label: this.$t(`enum.expertBookingDateType.${ExpertBookingDateType.hour}`),
				value: ExpertBookingDateType.hour,
				isDisabled: false,
			},
		];

		if (!this.isRemote) {
			this.dayParts = this.dayParts.filter((x) => x.value !== ExpertBookingDateType.hour);
		}

		this.dayPartsTimes = [
			{
				label: `${this.formatTime(this.selectedDateStartingHour)} - ${this.formatTime(
					addHours(this.selectedDateStartingHour, this.totalWorkHours / 2)
				)}`,
				value: ExpertBookingDateType.morning,
				isDisabled: hasMorningBookings,
			},
			{
				label: `${this.formatTime(
					addHours(this.selectedDateStartingHour, this.totalWorkHours / 2)
				)} - ${this.formatTime(addHours(this.selectedDateStartingHour, this.totalWorkHours))}`,
				value: ExpertBookingDateType.afternoon,
				isDisabled: hasAfternoonBookings,
			},
		];
	}

	@Watch("selectedLocationOption", { deep: true })
	onSelectedLocationOptionChanged(val: IDropdownItem): void {
		if (val?.value === ExpertBookingLocationType.Remote) this.workLocation = null;
		else {
			const bookedHoursToRemove = this.selectedDates.filter((x) => x.type === ExpertBookingDateType.hour);

			bookedHoursToRemove.forEach((bookedHour) => {
				this.vm.bookingSummary.availabilityDates.map((x) => {
					if (
						compareAsc(
							getDateWithoutTime(getDateWithoutTime(this.toDate(bookedHour.date))),
							getDateWithoutTime(this.toDate(x.date))
						) === 0
					) {
						x.bookedHours = x.bookedHours?.filter((y) => {
							return y.start !== bookedHour.start && y.end !== bookedHour.end;
						});
						x.totalBookedHours =
							x.totalBookedHours -
							differenceInHours(this.toDate(bookedHour.end), this.toDate(bookedHour.start));
						x.availability = 0;
					}
					return x;
				});
			});

			this.selectedDates = this.selectedDates.filter((x) => x.type !== ExpertBookingDateType.hour);
			this.selectedDayParts = null;
			this.selectedDayPartsTimes = null;
			this.resetSelectedHours();
		}
		this.calculatePrice();
		this.setupConfigurationOptions();
	}

	@Watch("workLocation", { deep: true })
	onWorkLocationChanged(): void {
		this.calculatePrice();
	}

	@Watch("isFlexBooking")
	onIsFlexBookingChanged(): void {
		this.calculatePrice();
	}

	@Watch("isRemoteAllowed")
	onRemoteAllowedChanged(): void {
		this.calculatePrice();
	}

	dayParts: IDropdownItem[] = [];
	dayPartsTimes: IDropdownItem[] = [];
	hours: IDropdownItem[] = [];
	editDateIndex: number | null = null;
	expertPriceCalculation: ExpertCalculationResponse | null = null;

	isFlexBooking = false;
	isRequestBooking = false;
	editWorkLocation = true;
	visibleStep = 1;
	walletAmount = 0;

	modalType: ExpertBookingModalType = ExpertBookingModalType.Default;
	locationOptions: IDropdownItem[] = [];
	requester: BookingRequestRequester | null = null;
	workLocation: ExpertBookingWorkLocation | null = null;

	selectedDate!: Date;
	selectedDates: ExpertBookingDate[] = [];
	selectedEditingHours: Date[] = [];
	selectedDayParts: IDropdownItem | null = null;
	selectedDayPartsTimes: IDropdownItem | null = null;
	selectedHours: number[] = [];
	selectedLocationOption: IDropdownItem | null = null;
	selectedDateStartingHour = set(new Date(), {
		hours: 9,
		minutes: 0,
		seconds: 0,
		milliseconds: 0,
	});
	totalWorkHours = 8;
	startingHour = 9;
	unavailableHours: Array<UnavailableHours> = [];
	isValidRange = false;

	cartStore = useCartStore();
	expertStore = useExpertStore();

	created(): void {
		Log.info("Expert Detail VM", this.vm);

		this.setupConfigurationOptions();
		this.selectedLocationOption = this.locationOptions[0];
		this.setupPreviousConfiguration();

		this.sendViewItem();
	}

	sendViewItem() {
		// hourly rate times minimal hours. which is 4 atm.
		const minimalCosts = (this.bookingSummary?.expertDetails?.hourlyRate || 0) * 4;

		this.setDataLayer.viewItem(toFixed(minimalCosts, 2) || null, [
			{
				id: this.vm.expertProfile.expertVariationContentId,
				name: this.vm.expertProfile.firstName,
				basePrice: minimalCosts,
				personalPrice: minimalCosts,
				productCategory: ProductCategoryType.Expert,
				brand: "cadac",
				amount: 1,
			},
		]);
	}

	get workDayStartHour(): number {
		return getHours(this.selectedDateStartingHour);
	}

	get workDayEndHour(): number {
		return getHours(this.selectedDateStartingHour) + this.totalWorkHours;
	}

	get isEditingDate(): boolean {
		return this.editDateIndex !== null && this.editDateIndex >= 0;
	}

	get isRemoteAllowed(): boolean {
		return this.bookingSummary?.expertDetails?.remoteAllowed;
	}

	get isRemote(): boolean {
		return this.selectedLocationOption?.value === ExpertBookingLocationType.Remote;
	}

	get bookingSummary(): ExpertBookingSummaryViewModel {
		return this.vm.bookingSummary;
	}

	get canSubmit(): boolean {
		return (
			!!this.selectedDates.length && !!this.selectedLocationOption && (this.isRemote || !!this.workLocation)
		);
	}

	get canAddDate(): boolean {
		return (
			this.selectedDayParts?.value === ExpertBookingDateType.fullDay ||
			!!this.selectedDayPartsTimes?.value ||
			(this.selectedDayParts?.value !== ExpertBookingDateType.hour &&
				this.selectedHours &&
				this.selectedHours.length > 1) ||
			(this.selectedDayParts?.value === ExpertBookingDateType.hour && this.isValidRange)
		);
	}

	get canBookExpert(): boolean {
		if (this.vm.expertProfile.state == ExpertPageState.revoked) {
			return false;
		}

		return this.bookingSummary.availabilityDates?.some(
			(x) =>
				x.availability === ExpertDateAvailability.available ||
				x.availability === ExpertDateAvailability.onRequest
		);
	}

	get ExpertBookingDateType(): typeof ExpertBookingDateType {
		return ExpertBookingDateType;
	}

	get productAddedModel(): IProductAddedModal {
		return {
			product: {
				productType: ItemProductType.Expert,
				name: this.bookingSummary?.expertDetails?.firstName,
				iconUrl: this.vm.expertProfile?.imageUrl,
				expertProfile: this.vm.expertProfile,
			} as ExpertItemViewModel,
			crossSellProducts: this.vm.relatedCrossSellProducts,
		};
	}

	get calculationRequestModel(): ExpertCalculationRequest {
		return {
			expertPageId: this.bookingSummary?.expertDetails?.pageId || 0,
			isFlexBooking: this.isFlexBooking,
			workLocation: this.workLocation as ExpertBookingWorkLocation,
			dates: this.selectedDates as ExpertBookingDate[],
			isRemote: this.isRemote,
		};
	}

	get concatenatedUnavailableHours(): UnavailableHours[] {
		return cloneDeep(this.unavailableHours)
			.sort((a, b) => a.start.position - b.start.position)
			.reduce((accumulator: UnavailableHours[], currentValue: UnavailableHours) => {
				const previousValue = accumulator[accumulator.length ? accumulator.length - 1 : 0];
				if (
					!previousValue ||
					(previousValue.end.position !== currentValue.start.position &&
						!(previousValue.end.position >= currentValue.start.position))
				) {
					accumulator.push(currentValue);
				} else {
					accumulator[accumulator.length - 1].end.position = currentValue.end.position;
					accumulator[accumulator.length - 1].end.time = currentValue.end.time;
				}
				return accumulator;
			}, []);
	}

	get priceSummary(): IPriceSummary {
		if (!this.expertPriceCalculation) return {};
		return {
			standardLines: this.getPriceLines(this.expertPriceCalculation.lines),
			additionalLines: this.getAdditionalPriceLines(this.expertPriceCalculation.lines),
			totalPrice: this.expertPriceCalculation.totalPrice,
		};
	}

	onValidityChange(val: boolean): void {
		this.isValidRange = val;
	}

	getPriceLines(lines: ExpertCalculationLineResponse[]): Map<string, number> {
		const priceLines = new Map<string, number>();
		lines.forEach((element: ExpertCalculationLineResponse) => {
			if (element.type === ExpertCalculationLineType.fullDay)
				priceLines.set(
					this.$t(`enum.expertCalculationLineType.${element.type}`, [element.quantity]),
					element.totalPrice
				);
			if (element.type === ExpertCalculationLineType.dayPart)
				priceLines.set(
					this.$t(`enum.expertCalculationLineType.${element.type}`, [element.quantity]),
					element.totalPrice
				);
			if (element.type === ExpertCalculationLineType.hour)
				priceLines.set(
					this.$t(`enum.expertCalculationLineType.${element.type}`, [element.quantity]),
					element.totalPrice
				);
		});
		return priceLines;
	}

	getAdditionalPriceLines(lines: ExpertCalculationLineResponse[]): Map<string, number> {
		const priceLines = new Map<string, number>();
		lines.forEach((element: ExpertCalculationLineResponse) => {
			if (element.type === ExpertCalculationLineType.travel)
				priceLines.set(
					this.$t(`enum.expertCalculationLineType.${element.type}`, [
						element.quantity,
						element.price.toFixed(2),
					]),
					element.totalPrice
				);
			if (element.type === ExpertCalculationLineType.accommodation)
				priceLines.set(
					element.quantity > 1
						? this.$t(`enum.expertCalculationLineType.${element.type}`) +
								" " +
								this.$t(`expert.nights`, [element.quantity])
						: this.$t(`enum.expertCalculationLineType.${element.type}`) +
								" " +
								this.$t(`expert.night`, [element.quantity]),
					element.totalPrice
				);
			if (element.type === ExpertCalculationLineType.flexBooking)
				priceLines.set(
					this.$t(`enum.expertCalculationLineType.${element.type}`, [
						this.vm.bookingSummary.expertDetails.flexBookingPercentage,
					]),
					element.totalPrice
				);
		});

		return priceLines;
	}

	getAvailabilityType(date: Date): ExpertDateAvailability {
		const expertDate = this.bookingSummary.availabilityDates.find((x) => {
			return compareAsc(getDateWithoutTime(date), getDateWithoutTime(this.toDate(x.date))) === 0;
		});
		return expertDate?.availability as ExpertDateAvailability;
	}

	onDateSelected(evt: ExpertBookingDateSelectedEvent): void {
		this.selectedDate = evt.date;
		this.visibleStep = 3;
		this.selectedDateStartingHour = set(evt.startHour, {
			hours: this.startingHour,
			minutes: 0,
			seconds: 0,
			milliseconds: 0,
		});
		this.resetSelectedHours();
		this.setBookedHours(evt.bookedHours);
	}

	setBookedHours(bookedHours?: Array<ExpertBookedHour>): void {
		if (bookedHours) {
			this.unavailableHours = bookedHours.map((bookedHour) => {
				const start = this.toDate(bookedHour.start);
				const end = this.toDate(bookedHour.end);
				return {
					start: {
						time: start,
						position: this.getSliderPositionFromDate(start),
					},
					end: { time: end, position: this.getSliderPositionFromDate(end) },
				};
			});
		} else this.unavailableHours = [];
		if (this.selectedDates.length) {
			this.selectedDates.forEach((selected) => {
				if (this.getIsBetweenSelectedDate(this.toDate(selected.start), this.toDate(selected.end))) {
					this.unavailableHours.push({
						start: {
							time: this.toDate(selected.start),
							position: this.getSliderPositionFromDate(this.toDate(selected.start)),
						},
						end: {
							time: this.toDate(selected.end),
							position: this.getSliderPositionFromDate(this.toDate(selected.end)),
						},
					});
				}
			});
		}

		if (this.isEditingDate)
			this.unavailableHours = this.unavailableHours.filter((x) => {
				return !(
					getHours(this.toDate(x.start.time)) === this.selectedHours[0] &&
					getHours(this.toDate(x.end.time)) === this.selectedHours[1]
				);
			});
	}

	getIsBetweenSelectedDate(start: Date, end: Date): boolean {
		const workDayEnd = addHours(this.selectedDateStartingHour, this.totalWorkHours);

		return (
			(compareAsc(end, this.selectedDateStartingHour) === 1 ||
				compareAsc(end, this.selectedDateStartingHour) === 0) &&
			(compareAsc(start, workDayEnd) === -1 || compareAsc(start, workDayEnd) === 0)
		);
	}

	getSliderPositionFromDate(date): number {
		return this.workDayStartHour + differenceInHours(date, this.selectedDateStartingHour);
	}

	onLocationSelected(e): void {
		if (!e.target.value) {
			this.workLocation = null;
		}
	}

	onDateAdded(): void {
		const dateObject: ExpertBookingDate = {} as ExpertBookingDate;

		if (this.selectedDayParts?.value === ExpertBookingDateType.fullDay) {
			dateObject.start = this.selectedDateStartingHour;
			dateObject.end = addHours(this.selectedDateStartingHour, this.totalWorkHours);
			dateObject.date = dateObject.start;
			dateObject.type = ExpertBookingDateType.fullDay;
			dateObject.totalHours = 8;
		} else if (
			this.selectedDayParts?.value === ExpertBookingDateType.morning ||
			this.selectedDayParts?.value === ExpertBookingDateType.afternoon
		) {
			if (this.selectedDayPartsTimes?.value === ExpertBookingDateType.morning) {
				dateObject.start = this.selectedDateStartingHour;
				dateObject.end = addHours(this.selectedDateStartingHour, this.totalWorkHours / 2);
				dateObject.date = dateObject.start;
				dateObject.type = ExpertBookingDateType.morning;
				dateObject.totalHours = this.totalWorkHours / 2;
			} else {
				dateObject.start = addHours(this.selectedDateStartingHour, this.totalWorkHours / 2);
				dateObject.end = addHours(this.selectedDateStartingHour, this.totalWorkHours);
				dateObject.date = dateObject.start;
				dateObject.type = ExpertBookingDateType.afternoon;
				dateObject.totalHours = this.totalWorkHours / 2;
			}
		} else if (this.selectedDayParts?.value === ExpertBookingDateType.hour && this.selectedHours) {
			const startHour = first(this.selectedHours) ?? 0;
			const endHour = last(this.selectedHours) ?? 0;
			dateObject.start = set(this.selectedDate, {
				hours: startHour as number,
				minutes: 0,
				seconds: 0,
				milliseconds: 0,
			});
			dateObject.end =
				this.selectedHours.length > 1
					? set(this.selectedDate, { hours: endHour as number, minutes: 0, seconds: 0, milliseconds: 0 })
					: set(this.selectedDate, {
							hours: startHour as number,
							minutes: 0,
							seconds: 0,
							milliseconds: 0,
						});
			dateObject.date = dateObject.start;
			dateObject.type = ExpertBookingDateType.hour;
			dateObject.totalHours = endHour - startHour;
		}

		if (this.editDateIndex != null) {
			this.selectedDates[this.editDateIndex] = { ...dateObject };
		} else {
			const concurrentBooking = this.selectedDates.filter((selectedDate) => {
				return (
					compareAsc(this.toDate(selectedDate.start), this.toDate(dateObject.end)) === 0 ||
					compareAsc(this.toDate(selectedDate.end), this.toDate(dateObject.start)) === 0
				);
			});
			if (concurrentBooking.length) {
				if (concurrentBooking.length === 1) {
					this.selectedDates.forEach((selectedDate) => {
						if (compareAsc(this.toDate(selectedDate.start), this.toDate(dateObject.end)) === 0) {
							selectedDate.start = this.toDate(dateObject.start);
							selectedDate.date = selectedDate.start;
							dateObject.end = this.toDate(selectedDate.end);
							dateObject.totalHours = differenceInHours(
								this.toDate(dateObject.end),
								this.toDate(dateObject.start)
							);
							dateObject.type = this.getBookingType(dateObject);
							selectedDate.type = this.getBookingType(dateObject);
						} else if (
							compareAsc(this.toDate(selectedDate.end), this.toDate(dateObject.start)) === 0
						) {
							selectedDate.end = this.toDate(dateObject.end);
							dateObject.start = this.toDate(selectedDate.start);
							dateObject.date = dateObject.start;
							dateObject.totalHours = differenceInHours(
								this.toDate(dateObject.end),
								this.toDate(dateObject.start)
							);
							dateObject.type = this.getBookingType(dateObject);
							selectedDate.type = this.getBookingType(dateObject);
						}
					});
				}

				if (concurrentBooking.length === 2) {
					const indexOne = this.selectedDates.findIndex(
						(x) => compareAsc(this.toDate(x.start), this.toDate(concurrentBooking[0].start)) === 0
					);
					const indexTwo = this.selectedDates.findIndex(
						(x) => compareAsc(this.toDate(x.start), this.toDate(concurrentBooking[1].start)) === 0
					);

					dateObject.start = this.selectedDates[indexOne].start;
					dateObject.end = this.selectedDates[indexTwo].end;
					dateObject.totalHours = differenceInHours(
						this.toDate(dateObject.end),
						this.toDate(dateObject.start)
					);
					dateObject.type = this.getBookingType(dateObject);
					dateObject.date = dateObject.start;

					this.selectedDates.splice(indexTwo);
					this.selectedDates.splice(indexOne);
					this.selectedDates.push(dateObject);
				}
			} else this.selectedDates.push(dateObject);
		}
		this.selectedDates = orderBy(
			this.selectedDates,
			[
				(item) => {
					return this.toDate(item.date);
				},
			],
			["asc"]
		);

		this.updateAvailabilityDates(dateObject);

		this.resetDay();
		this.visibleStep = 1;
		this.editDateIndex = null;
	}

	getBookingType(dateObject: ExpertBookingDate): ExpertBookingDateType {
		const totalBookedHours: number = differenceInHours(
			this.toDate(dateObject.end),
			this.toDate(dateObject.start)
		);

		if (dateObject.type === ExpertBookingDateType.hour) {
			return ExpertBookingDateType.hour;
		}

		if (totalBookedHours === this.totalWorkHours) {
			return ExpertBookingDateType.fullDay;
		}
		if (totalBookedHours === this.totalWorkHours / 2) {
			if (
				compareAsc(this.selectedDateStartingHour, dateObject.start) === 0 &&
				compareAsc(addHours(dateObject.start, this.totalWorkHours / 2), dateObject.end) === 0
			) {
				return ExpertBookingDateType.morning;
			}
			if (
				compareAsc(addHours(dateObject.start, this.totalWorkHours / 2), dateObject.start) === 0 &&
				compareAsc(addHours(dateObject.start, this.totalWorkHours), dateObject.end) === 0
			) {
				return ExpertBookingDateType.afternoon;
			}
		}
		return ExpertBookingDateType.hour;
	}

	updateAvailabilityDates(dateObject: ExpertBookingDate): void {
		this.vm.bookingSummary.availabilityDates = this.vm.bookingSummary.availabilityDates.map((x) => {
			if (
				compareAsc(
					getDateWithoutTime(getDateWithoutTime(this.toDate(dateObject.date))),
					getDateWithoutTime(this.toDate(x.date))
				) === 0
			) {
				x.bookedHours =
					x.bookedHours?.filter((y) => {
						if (this.isEditingDate) {
							return !(
								compareAsc(this.toDate(y.start), this.toDate(this.selectedEditingHours[0])) ===
									0 &&
								compareAsc(this.toDate(y.end), this.toDate(this.selectedEditingHours[1])) === 0
							);
						} else
							return !(
								compareAsc(this.toDate(y.start), this.toDate(dateObject.start)) === 0 &&
								compareAsc(this.toDate(y.start), this.toDate(dateObject.start)) === 1 &&
								compareAsc(this.toDate(y.end), this.toDate(dateObject.end)) === 0 &&
								compareAsc(this.toDate(y.end), this.toDate(dateObject.end)) === -1
							);
					}) || [];

				x.totalBookedHours = 0;
				x.bookedHours.push(dateObject);
				x.bookedHours.forEach(
					(bookedHour) =>
						(x.totalBookedHours += bookedHour.totalHours
							? bookedHour.totalHours
							: differenceInHours(this.toDate(bookedHour.end), this.toDate(bookedHour.start)))
				);
				x.availability =
					x.availability === ExpertDateAvailability.onRequest
						? ExpertDateAvailability.onRequest
						: x.totalBookedHours === this.totalWorkHours
							? 2
							: 0;
			}
			return x;
		});
	}

	onPlaceChanged(place): void {
		if (place?.name) {
			const result = place.address_components.find((component) => {
				return component.types.includes("country");
			});

			this.workLocation = {
				name: place.formatted_address,
				countryCode: result.short_name,
				latitude: place.geometry.location.lat(),
				longitude: place.geometry.location.lng(),
			};
		} else {
			this.workLocation = null;
		}
	}

	onModalSubmit(val: { type: ExpertBookingModalType; data: ExpertBookingForm | null }): void {
		const { type, data } = val;
		if (data) {
			this.requester = {
				company: data.company || "",
				email: data.email || "",
				firstName: data.firstname || "",
				lastName: data.lastname || "",
			};
		}
		if (type === ExpertBookingModalType.OnRequest) {
			this.sendBookingRequest(data);
		} else {
			this.addToCartRequest(data);
		}

		this.setDataLayer.addToCart(toFixed(this.priceSummary.totalPrice, 2) || null, [
			{
				id: this.vm.expertProfile.expertVariationContentId || "0000-0000-0000-0000",
				name: this.vm.expertProfile.expertVariationContentId.toString(),
				productCategory: ProductCategoryType.Expert,
				basePrice: this.priceSummary.totalPrice,
				personalPrice: this.priceSummary.totalPrice,
				brand: "cadac",
				amount: 1,
			},
		]);
	}

	cancelBooking(): void {
		this.resetDay();
		this.editDateIndex = null;
		this.visibleStep = 1;
	}

	resetDay(): void {
		this.selectedDate = null as any;
		this.selectedDayParts = null;
		this.selectedDayPartsTimes = null;
		this.resetSelectedHours();
	}

	resetSelectedHours(): void {
		if (this.selectedDayParts?.value === ExpertBookingDateType.hour) {
			this.onValidityChange(false);
			this.selectedDayPartsTimes = null;
			if (!this.isEditingDate) this.selectedHours = [this.workDayStartHour, this.workDayEndHour];
		} else if (!this.isEditingDate) this.selectedHours = [];
	}

	deleteDate(index: number, selected: ExpertBookingDate): void {
		const indexOfUnavailableHours = this.unavailableHours.findIndex((object) => {
			return object.start.time === selected.start;
		});

		this.unavailableHours.splice(indexOfUnavailableHours, 1);
		this.selectedDates.splice(index, 1);

		this.vm.bookingSummary.availabilityDates = this.vm.bookingSummary.availabilityDates.map((x) => {
			if (
				compareAsc(
					getDateWithoutTime(getDateWithoutTime(this.toDate(selected.date))),
					getDateWithoutTime(this.toDate(x.date))
				) === 0
			) {
				x.bookedHours = x.bookedHours?.filter((y) => {
					return y.start !== selected.start && y.end !== selected.end;
				});
				x.totalBookedHours =
					x.totalBookedHours - differenceInHours(this.toDate(selected.end), this.toDate(selected.start));
				x.availability = 0;
			}
			return x;
		});
		const bookedHours = this.vm.bookingSummary.availabilityDates?.find((x) => {
			return (
				compareAsc(getDateWithoutTime(this.selectedDate), getDateWithoutTime(this.toDate(x.date))) === 0
			);
		})?.bookedHours;

		this.setBookedHours(bookedHours);
	}

	openDate(date: ExpertBookingDate, index: number | null): void {
		setTimeout(() => {
			this.editDateIndex = index;
			this.selectedDate = date.date;
			this.selectedEditingHours = [date.start, date.end];
			this.visibleStep = 2;
			this.selectedHours = [getHours(this.toDate(date.start)), getHours(this.toDate(date.end))];

			this.onDateSelected({
				date: this.toDate(date.date),
				availability: this.getAvailabilityType(this.toDate(date.date)),
				bookedHours:
					this.bookingSummary.availabilityDates.find((x) => {
						return (
							compareAsc(
								getDateWithoutTime(this.toDate(date.date)),
								getDateWithoutTime(this.toDate(x.date))
							) === 0
						);
					})?.bookedHours || [],
				startHour: new Date(
					this.bookingSummary.availabilityDates.find((x) => {
						return (
							compareAsc(
								getDateWithoutTime(this.toDate(date.date)),
								getDateWithoutTime(this.toDate(x.date))
							) === 0
						);
					})?.date || new Date()
				),
			});
		}, 10);
	}

	openBookingModal(): void {
		this.modalType = this.isRequestBooking ? ExpertBookingModalType.OnRequest : ExpertBookingModalType.Default;
		this.expertStore.showModal("BookingRequest");
	}

	calculatePrice(): void {
		if (this.isSpinnerVisible("priceSummary") || !this.calculationRequestModel.dates?.length) return;
		this.loadingStore.showSpinner("priceSummary");
		this.axios
			.post(`/api/expert/calculate-costs`, this.calculationRequestModel)
			.then((result) => {
				this.expertPriceCalculation = result.data;
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => this.loadingStore.hideSpinner("priceSummary"));
	}

	sendBookingRequest(data: ExpertBookingForm | null): void {
		const bookingRequest: SendBookingRequestRequest = {
			pageId: this.bookingSummary?.expertDetails?.pageId || 0,
			isFlexBooking: this.isFlexBooking,
			workLocation: this.workLocation as ExpertBookingWorkLocation,
			dates: this.selectedDates,
			projectDescription: data?.description || "",
			requester: this.requester as BookingRequestRequester,
			isRemote: this.isRemote,
		};
		this.axios
			.post(`	/api/expert/sendBookingRequest`, bookingRequest)
			.then(() => {
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("expert.toast.sendBookingRequestSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => {
				this.expertStore.hideModal("BookingRequest");
			});
	}

	addToCartRequest(data: ExpertBookingForm | null): void {
		const model: ExpertProductAddToCartRequest = {
			oldLineItemId: this.bookingSummary?.configuration?.oldLineItemId,
			pageId: this.bookingSummary?.expertDetails?.pageId || 0,
			isFlexBooking: this.isFlexBooking,
			workLocation: this.workLocation as ExpertBookingWorkLocation,
			dates: this.selectedDates,
			projectDescription: data?.description || "",
			isRemote: this.isRemote,
		};

		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/cart/add-expert`, model)
			.then(() => {
				this.cartStore.showModal("ProductAdded");
			})
			.catch((err) => Log.error(err))
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("BookingRequest");
			});
	}

	private setupConfigurationOptions(): void {
		if (this.isRemoteAllowed) {
			this.locationOptions = [
				{ label: this.$t("expert.onLocation"), value: ExpertBookingLocationType.OnLocation },
				{ label: this.$t("expert.remote"), value: ExpertBookingLocationType.Remote },
			];
		} else {
			this.locationOptions = [
				{ label: this.$t("expert.onLocation"), value: ExpertBookingLocationType.OnLocation },
			];
		}
	}

	private setupPreviousConfiguration(): void {
		if (!this.bookingSummary?.configuration?.oldLineItemId) return;

		const { workLocation, isFlexBooking, requester, selectedDates } = this.bookingSummary.configuration;

		if (workLocation) {
			this.editWorkLocation = false;
			this.selectedLocationOption = this.locationOptions.find(
				(x) => x.value === ExpertBookingLocationType.OnLocation
			) as IDropdownItem;
			this.workLocation = workLocation;
		} else {
			this.selectedLocationOption = this.locationOptions.find(
				(x) => x.value === ExpertBookingLocationType.Remote
			) as IDropdownItem;
		}
		this.isFlexBooking = isFlexBooking;
		this.requester = requester;

		this.selectedDates = orderBy(
			selectedDates,
			[
				(item) => {
					return item.date;
				},
			],
			["asc"]
		);
	}
}
</script>

<style scoped lang="scss">
::v-deep(.expert-booking-card) {
	position: sticky;
	top: calc(1rem + var(--navbarHeightInPx));

	.p-card-title {
		margin-bottom: 0;
	}

	.p-card-subtitle {
		color: var(--text-color-secondary);
		font-weight: 400;
		margin-bottom: 0;
	}

	.p-chip.custom-chip {
		background: transparent;
		color: var(--primary-color);
		border: 1px solid var(--primary-color);
		width: 100%;
		justify-content: space-between;
		font-weight: 500;
		cursor: pointer;
	}

	.pac-target-input:focus {
		outline: var(--primary-color);
	}
}

.p-timeline-event-opposite {
	display: none;
}

::v-deep(.day-part-select) {
	.p-button-label {
		font-size: 1rem;
		padding: 0.5rem 1rem !important;
	}

	.p-button {
		overflow: visible;
		padding: 0;

		div {
			padding: 0;
			margin: 0 !important;
			border: none !important;
			width: 100%;
			height: 100%;
		}
	}
}
</style>
