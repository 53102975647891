import { defineStore } from 'pinia';
import { IMainLayout } from '@/types/viewModels/common/mainLayoutViewModel';
import { OrganizationOptionViewModel } from '@/types/models/menu/organizationOptionViewModel';

interface LayoutStoreState {
	showSearchBar: boolean;
	showUserMenu: boolean;
	changeRegionModalVisible: boolean;
	changeCompanyModalVisible: boolean;
	chatWidgetAvailable: boolean;
	navBarHeight: number;
	vm: IMainLayout;
}

export type LayoutModalType = 'ChangeCompany' | 'ChangeRegion';

export const useLayoutStore = defineStore('layout', {
	state: () =>
		({
			showSearchBar: false,
			showUserMenu: false,
			changeRegionModalVisible: false,
			changeCompanyModalVisible: false,
			chatWidgetAvailable: false,
			navBarHeight: 0,
			vm: {},
		}) as LayoutStoreState,
	getters: {
		preferredCompany(state): OrganizationOptionViewModel | null {
			return state.vm?.organizationOptions?.find(
				(x) => x.isPreferredOrganization
			) as OrganizationOptionViewModel;
		},
		isAuthenticated(state): boolean {
			return !!state?.vm.currentUser?.isAuthenticated;
		},
		isLoggedIn(state): boolean {
			return !!state?.vm.currentUser?.email;
		},
		isImpersonated(state): boolean {
			return !!state?.vm.impersonatedUser?.isImpersonated;
		},
		canImpersonate(state): boolean {
			return !!state?.vm.currentUser?.isImpersonationAllowed || !!state?.vm.impersonatedUser;
		},
		hasMultipleCompanies(state): boolean {
			return (state?.vm.organizationOptions?.length || 0) > 1;
		},
	},
	actions: {
		showModal(type: LayoutModalType) {
			switch (type) {
				case 'ChangeCompany':
					this.changeCompanyModalVisible = true;
					break;
				case 'ChangeRegion':
					this.changeRegionModalVisible = true;
					break;
			}
		},
		hideModal(type: LayoutModalType) {
			switch (type) {
				case 'ChangeCompany':
					this.changeCompanyModalVisible = false;
					break;
				case 'ChangeRegion':
					this.changeRegionModalVisible = false;
					break;
			}
		},
		toggleUserMenu() {
			this.showUserMenu = !this.showUserMenu;
		},
		toggleSearchBar() {
			this.showSearchBar = !this.showSearchBar;
		},
		updateItemsInCart(itemCount: number) {
			this.vm.itemsInCart = itemCount;
		},
	},
});
