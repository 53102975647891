import httpClient from '@/types/helpers/apiHelper';
import i18n from '@/types/utils/i18n';
import { AddToCartResponse } from '@/types/generated/Api/addToCartResponse';
import { AxiosResponse } from 'axios';
import { ContractsForProduct } from '@/types/generated/Api/contractsForProduct';
import { IDropdownItem } from '@/types/models/common/dropdownItem.interface';
import { ISoftwareProduct } from '@/types/viewModels/commerce/softwareProductViewModel';
import { LicenseClass } from '@/types/enum/licenseClass';
import { LicenseModel } from '@/types/enum/licenseModel';
import { ProductAddToCart } from '@/types/generated/Api/productAddToCart';
import { ProductUsageAdvice } from '@/types/models/software/productUsageAdvice';
import { ProductVariation } from '@/types/models/software/productVariation';
import { defineStore } from 'pinia';
import { filter, find, first, sortBy, uniqBy } from 'lodash';
import { useLayoutStore } from '@/store/common/layoutStore';
import { useLoadingStore } from '@/store/common/loadingStore';

interface SoftwareProductState {
	isAddingToContract: boolean;

	selectedLicenseSize?: number;
	selectedLicenseClass?: LicenseClass;
	selectedLicenseModel?: LicenseModel;
	selectedContract?: ContractsForProduct;
	selectedVariation?: ProductVariation;
	selectedDuration?: number;
	selectedAmount: number;
	walletAmount: number;

	availableLicenseSizes: IDropdownItem[];
	availableLicenseClasses: IDropdownItem[];
	availableDurations: IDropdownItem[];
	contractsForProduct: ContractsForProduct[];
	availableLicenseModels: IDropdownItem[];
	productUsageAdvice: ProductUsageAdvice | null;
	vm: ISoftwareProduct | null;
}

export const useSoftwareProductStore = defineStore('softwareProduct', {
	state: () =>
		({
			isAddingToContract: false,
			selectedLicenseSize: undefined,
			selectedLicenseClass: undefined,
			selectedContract: undefined,
			selectedDuration: undefined,
			selectedVariation: undefined,
			selectedLicenseModel: LicenseModel.subscription,
			availableDurations: [],
			availableLicenseSizes: [],
			availableLicenseClasses: [],
			availableLicenseModels: [{ value: 0, label: i18n.global.t(`enum.licenseModel.0`) }],
			contractsForProduct: [],
			vm: null,
			selectedAmount: 1,
			walletAmount: 0,
			productUsageAdvice: null,
		}) as SoftwareProductState,
	getters: {
		selectedLicenseSizeLabel(state: SoftwareProductState): string {
			return state.availableLicenseSizes.find((x) => x.value === state.selectedLicenseSize)?.label || '';
		},
		canAddToContract(state: SoftwareProductState): boolean {
			return !!state.vm?.canAddToContract && state.contractsForProduct?.length > 0;
		},
		isSelectedDurationAvailable(state: SoftwareProductState): boolean {
			return state.availableDurations.findIndex((x) => x.value === state.selectedDuration) > -1;
		},
		currentVariation(
			state: SoftwareProductState
		): (filterOptions?: Partial<ProductVariation>) => ProductVariation {
			return (filterOptions?: Partial<ProductVariation>) => {
				if (!filterOptions)
					filterOptions = {
						licenseSize:
							state.selectedLicenseSize || (first(this.availableLicenseSizes)?.value as number),
						licenseClass:
							state.selectedLicenseClass || (first(this.availableLicenseClasses)?.value as number),
						duration: this.isSelectedDurationAvailable
							? state.selectedDuration
							: (first(this.availableDurations)?.value as number),
					};
				return find(state.vm?.productVariations, filterOptions) as ProductVariation;
			};
		},
	},
	actions: {
		async init(): Promise<void> {
			if (this.vm?.productUsageAdvice) {
				this.productUsageAdvice = this.vm?.productUsageAdvice;
				this.availableLicenseModels.push({ value: 1, label: i18n.global.t(`enum.licenseModel.1`) });
			}

			this.availableLicenseClasses =
				this.vm?.distinctLicenseClass?.map((x) => ({
					value: x,
					label: i18n.global.t(`enum.licenseClass.${x}`),
				})) || [];
			this.availableDurations =
				this.vm?.distinctDuration?.map((x) => ({
					value: x,
					label: i18n.global.tc('common.months', x),
				})) || [];
			this.availableLicenseSizes =
				this.vm?.licenseSizes?.map((x) => ({
					value: x.key,
					label: x.value,
				})) || [];

			this.selectedVariation =
				this.vm?.selectedProductVariation || (this.vm?.productVariations[0] as ProductVariation);
			this.selectedAmount = this.vm?.selectedSeats || 1;
			this.selectedContract = this.contractsForProduct?.find(
				(x) => x.contractNumber === this.vm?.selectedContractNumber
			);
			this.isAddingToContract = !!this.vm?.canAddToContract && useLayoutStore().isAuthenticated && !this.vm?.isAutodeskProduct;
			await this.updateConfiguration();
		},
		updateAvailableOptions(): void {
			this.availableDurations = filter(this.vm?.productVariations, {
				licenseClass: this.selectedLicenseClass,
				licenseSize: this.selectedLicenseSize,
			}).map((x) => ({
				value: x.duration,
				label: i18n.global.tc('common.months', x.duration || 0),
			}));
			this.availableDurations = sortBy(uniqBy(this.availableDurations, 'value'), 'value');
		},
		async updateSelectedContract(): Promise<ContractsForProduct | undefined> {
			if (this.isAddingToContract) {
				useLoadingStore().showSpinner('contractTable');
				return this.fetchContractsForSelectedVariation()
					.then((contractsForProduct) => {
						return (
							contractsForProduct.find(
								(x) => x.contractNumber === this.vm?.selectedContractNumber
							) || contractsForProduct[0]
						);
					})
					.finally(() => useLoadingStore().hideSpinner('contractTable'));
			} else {
				return undefined;
			}
		},
		async updateConfiguration(selectedContract?: ContractsForProduct): Promise<void> {
			useLoadingStore().showSpinner('priceSummary');
			if (!selectedContract) {
				selectedContract = await this.updateSelectedContract();
				this.selectedVariation = selectedContract
					? this.currentVariation({
							sku: selectedContract.skuCodeToUse,
						})
					: this.selectedVariation;
			}

			this.selectedContract = selectedContract;
			this.selectedLicenseSize = this.selectedVariation?.licenseSize;
			this.selectedLicenseClass = this.selectedVariation?.licenseClass;
			this.selectedDuration = selectedContract?.duration || this.selectedVariation?.duration;
			this.walletAmount = (this.selectedVariation?.walletAmount || 0) * this.selectedAmount;
			this.updateAvailableOptions();
			useLoadingStore().hideSpinner('priceSummary');
		},
		async addToCart() {
			const payLoad: ProductAddToCart = {
				seats: this.selectedAmount || 1,
				contractNumber: this.selectedContract?.contractNumber,
				oldLineItemId: this.vm?.oldLineItemId,
				walletAmount: this.walletAmount || 0,
				types: this.selectedContract
					? this.selectedContract?.skuIdToUse
					: ([this.selectedVariation?.id] as number[]),
			};
			const response: AxiosResponse<AddToCartResponse> = await httpClient.post<AddToCartResponse>(
				'/api/cart/add',
				payLoad
			);
			return response.data;
		},
		async fetchContractsForSelectedVariation() {
			if (!this.selectedVariation) return [];
			const response = await httpClient.get<ContractsForProduct[]>(
				`/api/product/contractsForProduct/${this.selectedVariation?.sku}`
			);
			this.contractsForProduct = response.data;
			this.isAddingToContract = this.canAddToContract && !this.vm?.isAutodeskProduct;
			return response.data;
		},
	},
});
