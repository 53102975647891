import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_7 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_8 = { class: "form-row" }
const _hoisted_9 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_InputWithValidation = _resolveComponent("InputWithValidation")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createElementBlock("form", {
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formStateChanged && _ctx.formStateChanged(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['gender'].required,
          label: _ctx.$t('common.forms.lblSalutation'),
          name: "gender"
        }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableGenderOptions, (gender) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `gender_${gender.value}`,
                  class: "form-check-inline"
                }, [
                  _createVNode(_component_RadioButton, {
                    modelValue: field.value.value,
                    "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
                    class: _normalizeClass({ 'p-invalid': field.errors?.length }),
                    "input-id": `gender_${gender.value}`,
                    name: field.name.value,
                    value: gender.value,
                    onChange: ($event: any) => (field.setTouched(true))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "input-id", "name", "value", "onChange"]),
                  _createElementVNode("span", {
                    class: "ml-1 mb-0",
                    for: `gender_${gender.value}`
                  }, _toDisplayString(gender.label), 9, _hoisted_4)
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['firstName'].required,
          label: _ctx.$t('common.forms.lblFirstName'),
          name: "firstName"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              feedback: false,
              name: field.name,
              "toggle-mask": "",
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['lastName'].required,
          label: _ctx.$t('common.forms.lblLastName'),
          name: "lastName"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              feedback: false,
              name: field.name,
              "toggle-mask": "",
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['phone'].required,
          label: _ctx.$t('common.forms.lblPhoneNumber'),
          name: "phone"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_vue_tel_input, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              "input-options": { placeholder: '' },
              onChange: ($event: any) => (field.setTouched(true)),
              onValidate: _ctx.onPhoneInputValidate
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "onChange", "onValidate"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ])
  ], 32))
}