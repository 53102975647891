<template>
	<form v-if="v$['askAQuestionSupportTicketForm']">
		<div class="form-row">
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionSupportTicketForm']?.impact?.required }">
					{{ $t("support.lblImpact") }}
				</label>
				<Dropdown
					v-model="v$['askAQuestionSupportTicketForm'].impact.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionSupportTicketForm']?.impact) }"
					option-label="label"
					option-value="value"
					:options="availableImpactOptions"
				/>
				<small v-if="shouldShowError(v$['askAQuestionSupportTicketForm']?.impact)" class="p-error">
					{{ v$["askAQuestionSupportTicketForm"].impact?.required?.$message }}
				</small>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionSupportTicketForm']?.urgency?.required }">
					{{ $t("support.lblUrgency") }}
				</label>
				<Dropdown
					v-model="v$['askAQuestionSupportTicketForm'].urgency.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionSupportTicketForm']?.urgency) }"
					option-label="label"
					option-value="value"
					:options="availableUrgencyOptions"
				/>
				<small v-if="shouldShowError(v$['askAQuestionSupportTicketForm']?.urgency)" class="p-error">
					{{ v$["askAQuestionSupportTicketForm"]?.urgency?.required?.$message }}
				</small>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Dropdown from "primevue/dropdown";
import { Validation } from "@vuelidate/core";
import { Component, Prop, VModel } from "vue-facing-decorator";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { SupportVariationViewModel } from "@/types/models/support/supportVariationViewModel";
import { TicketImpact } from "@/types/enum/ticketImpact";
import { TicketUrgency } from "@/types/enum/ticketUrgency";
import { values } from "lodash";

@Component({
	components: { Dropdown },
})
export default class AskAQuestionSupportTicketForm extends BaseComponent {
	@Prop({ type: Boolean, required: true, default: false }) submitted!: boolean;
	@VModel() v$!: Validation;

	selectedSupportProduct!: SupportVariationViewModel | null;

	availableUrgencyOptions: IDropdownItem[] = [];
	availableImpactOptions: IDropdownItem[] = [];

	created(): void {
		this.availableUrgencyOptions = values(TicketUrgency)
			.filter(Number.isFinite)
			.filter(Boolean)
			.map((x) => ({
				value: x,
				label: this.$t(`enum.ticketUrgency.${x}`),
			}));
		this.availableImpactOptions = values(TicketImpact)
			.filter(Number.isFinite)
			.filter(Boolean)
			.map((x) => ({
				value: x,
				label: this.$t(`enum.ticketImpact.${x}`),
			}));
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}
}
</script>

<style scoped lang="scss"></style>
