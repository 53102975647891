import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!

  return (_openBlock(), _createBlock(_component_Steps, {
    class: "my-3 checkout-steps",
    model: _ctx.steps,
    readonly: false
  }, null, 8, ["model"]))
}