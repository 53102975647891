import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fad6d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["href", "target"]
const _hoisted_6 = ["alt", "title"]
const _hoisted_7 = { class: "item-text" }
const _hoisted_8 = { class: "mb-0 text-primary" }
const _hoisted_9 = { class: "lead text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (!_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.vm.title)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 0,
                class: _normalizeClass(["display-4", _ctx.textAlignmentClass(_ctx.vm.titleAlignment)]),
                innerHTML: _ctx.vm.title
              }, null, 10, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Carousel, {
                "autoplay-interval": 7500,
                "num-scroll": _ctx.amountOfProductsToScroll,
                "num-visible": _ctx.amountOfVisibleItems,
                "show-navigators": false,
                value: _ctx.vm.items
              }, {
                item: _withCtx((slotProps) => [
                  _createElementVNode("a", {
                    class: "highlight-item",
                    href: slotProps.data.linkItem.url,
                    style: {"text-decoration":"none"},
                    target: slotProps.data.linkItem.target
                  }, [
                    (slotProps.data.imageUrl)
                      ? _withDirectives((_openBlock(), _createElementBlock("img", {
                          key: 0,
                          alt: slotProps.data.imageAlt || slotProps.data.title,
                          title: slotProps.data.title
                        }, null, 8, _hoisted_6)), [
                          [_directive_lazy, slotProps.data.imageUrl]
                        ])
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h3", _hoisted_8, _toDisplayString(slotProps.data.title), 1),
                      _createElementVNode("p", _hoisted_9, _toDisplayString(slotProps.data.text), 1)
                    ])
                  ], 8, _hoisted_5)
                ]),
                _: 1
              }, 8, ["num-scroll", "num-visible", "value"])
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}