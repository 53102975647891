<template>
	<a class="text-decoration-none" :href="item?.pageUrl">
		<div class="d-flex flex-row search-result-item">
			<div class="image-container my-auto">
				<img v-if="item.hasIconUrl && item.iconUrl" v-lazy="item.iconUrl" :alt="item.name" class="image w-100" />
				<Badge v-else :value="item.type.charAt(0)"></Badge>
			</div>

			<div class="item-content my-auto">
				<div class="d-flex flex-row">
					<h5 class="title">{{ item.name }}</h5>
					<div class="contentcategory-tag">
						<Tag :value="item.type" />
					</div>
				</div>

				<p v-if="item.intro" class="lead">{{ item.intro }}</p>
			</div>
		</div>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import Tag from "primevue/tag";
import { SearchResultItemViewModel } from "@/types/models/search/searchResultItem";

@Component({
	components: {
		Tag,
	},
})
export default class SearchPageResultItem extends BaseComponent {
	@Prop({ type: Object as PropType<SearchResultItemViewModel>, required: true, default: {} })
	item!: SearchResultItemViewModel;
}
</script>

<style scoped lang="scss">
.search-result-item {
	height: 6rem;
	color: #000;
	transition: all 0.4s;
	box-shadow:
		0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
	border-radius: 3px;
	margin: 1rem 0.25rem;
	overflow: hidden;
	position: relative;
	background-color: #ffffff;

	.image-container {
		position: relative;
		overflow: hidden;
		height: 4.5rem;
		width: 4.5rem;
		padding: 0.5rem;

		.image {
			opacity: 1;
			display: block;
			height: 100%;
			transition: 0.5s ease;
			backface-visibility: hidden;
			object-fit: cover;
		}
	}

	&:hover {
		box-shadow:
			4px 4px 2px 0 rgb(0 0 0 / 20%),
			0 1px 4px 0 rgb(0 0 0 / 14%),
			0 2px 8px 0 rgb(0 0 0 / 12%);
		cursor: pointer;
	}

	.item-content {
		width: 85%;
		padding: 0.5rem;

		.title {
			margin-bottom: 0;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			-webkit-box-pack: end;
		}

		.lead {
			font-size: 1.1rem;
			margin-bottom: 0;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			-webkit-box-pack: end;
		}

		.contentcategory-tag {
			margin-left: 1rem;
		}
	}

	.overlay {
		transition: 0.5s ease;
		opacity: 0;
		position: absolute;
		bottom: 0%;
		right: 0%;
		padding: 1.5rem;

		button {
			background-color: #91004b;
			color: white;
			font-size: 16px;
			padding: 16px 32px;
		}
	}

	&:hover {
		.image {
			transform: scale(1.01);
		}

		.overlay {
			opacity: 1;
			cursor: pointer;
		}
	}
}

::v-deep(.p-badge) {
	width: 100%;
	height: 100%;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

::v-deep(.p-badge-no-gutter) {
	border-radius: 10px;
}
</style>
