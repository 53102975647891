
import BaseBlock from "@/components/base/baseBlock.vue";
import { BlockBackgroundColor } from "@/types/enum/blockBackgroundColor";
import { CategoriesBlockViewModel } from "@/types/viewModels/blocks/categoriesBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component class CategoriesBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<CategoriesBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: CategoriesBlockViewModel;

	created(): void {
		Log.info("CategoriesBlock", this.vm);
	}

	get columnWidth(): string {
		let colWidth = "col-12 col-sm-6 ";
		colWidth += this.vm.items?.length === 4 ? "col-lg-3" : "col-lg-4";
		return colWidth;
	}

	// Don't remove (it's used in the style section)
	get itemHoverColor(): string {
		switch (this.vm.backgroundColor) {
			case BlockBackgroundColor.White:
			case BlockBackgroundColor.Pink:
				return "var(--surface-b)";
			case BlockBackgroundColor.Grey:
				return "var(--surface-c)";
			case BlockBackgroundColor.Purple:
			case BlockBackgroundColor.Green:
			default:
				return "rgba(255, 255, 255, 0.2)";
		}
	}

	get textColor(): string {
		switch (this.vm.backgroundColor) {
			case BlockBackgroundColor.Purple:
			case BlockBackgroundColor.Green:
				return "var(--primary-color-text)";
			case BlockBackgroundColor.White:
			case BlockBackgroundColor.Grey:
			case BlockBackgroundColor.Pink:
			default:
				return "var(--primary-color)";
		}
	}
}

const __default__ = CategoriesBlock
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1e5d0922": (_ctx.textColor),
  "513bcf8e": (_ctx.itemHoverColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__