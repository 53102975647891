<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '45vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		class="p-dialog-secondary cta-modal"
		:closable="true"
		:header="header"
		:modal="true"
		:style="{ width: '40vw' }"
		@hide="onCancel()"
	>
		<div class="d-flex flex-wrap">
			<div class="modal-image">
				<img v-lazy="vm.imageUrl" :alt="vm.imageAlt" />
			</div>
			<div class="flex-1 p-2">
				<h3 v-if="vm.title" class="mb-0">{{ vm.title }}</h3>
				<p class="text-preline mb-0" v-html="vm?.text" />
			</div>
		</div>

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button class="p-button-success p-button-arrow" :label="vm.ctaText" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { CallToActionModalBlockViewModel } from "@/types/models/common/callToActionModalBlockViewModel";

@Component
export default class CtaBlockModal extends BaseComponent {
	isVisible = true;

	@Prop({ type: String, default: "", required: false }) header!: string;

	@Prop({ type: Object as PropType<CallToActionModalBlockViewModel>, required: true, default: undefined })
	vm!: CallToActionModalBlockViewModel;

	@Emit() submit() {
		return true;
	}

	@Emit() cancel() {
		return false;
	}

	onCancel(): void {
		this.cancel();
	}

	onSubmit(): void {
		this.submit();
	}
}
</script>

<style lang="scss">
.cta-modal {
	.p-dialog-content {
		padding: 0;
	}

	.p-dialog-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.modal-image {
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
</style>
