<template>
	<a class="content-list-item content-list-card-item row text-decoration-none" :href="item?.url">
		<div class="image-container">
			<img v-lazy="item.image?.url" :alt="item.image?.alt" class="image" />
		</div>

		<div class="d-flex content-list-item-right col-8">
			<div class="d-flex flex-column justify-content-between h-100">
				<div class="item-content">
					<h3 v-if="item.title" class="mb-0 mr-5">{{ item.title }}</h3>
					<Tag
						v-if="showUpcomingEventTag"
						class="mr-1"
						severity="success"
						:value="$t('contentCategory.lblUpcomingTag')"
					/>
					<small class="text-muted">{{ contentDate }}</small>
					<p class="lead mt-2" v-html="item.intro"></p>
				</div>
			</div>
		</div>

		<div class="">
			<Button class="overlay p-button-link p-button-arrow">
				{{ $t("contentCategory.lblContinueReading") }}
			</Button>
		</div>
		<div class="contentcategory-tag">
			<Tag :value="$t('contentCategory.lblCategoryTags.' + item.contentCategory)" />
		</div>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { ContentCategoryOverviewItemViewModel } from "@/types/viewModels/general/contentCategoryOverviewItemViewModel";
import Tag from "primevue/tag";
import { ContentCategory } from "@/types/enum/contentCategory";
import { isFuture, isToday } from "date-fns";

@Component({
	components: {
		Tag,
	},
})
export default class ContentCategoryOverviewItemListCard extends BaseComponent {
	@Prop({ type: Object as PropType<ContentCategoryOverviewItemViewModel>, required: true, default: {} })
	item!: ContentCategoryOverviewItemViewModel;

	get contentDate(): string {
		return this.formatLongDate(
			this.item.contentCategory === ContentCategory.Event ? this.item.relatedDate : this.item.dateCreated
		);
	}

	get showUpcomingEventTag(): boolean {
		return (
			this.item.contentCategory === ContentCategory.Event &&
			!!this.item.relatedDate &&
			(isFuture(this.toDate(this.item.relatedDate)) || isToday(this.toDate(this.item.relatedDate)))
		);
	}
}
</script>

<style scoped lang="scss">
.content-list-card-item {
	transition: all 0.4s;
	box-shadow:
		0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
	border-radius: 3px;
	margin: 0 0 1.5rem 0;
	overflow: hidden;
	position: relative;
	color: var(--text-color);

	&:hover {
		color: var(--text-color);
	}

	.contentcategory-tag {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1.5rem;
	}

	.content-list-item-right {
		max-height: 270px;
		padding: 1rem 1.5rem;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		width: 33%;
		height: 270px;

		.image {
			opacity: 1;
			display: block;
			height: 100%;
			width: 100%;
			aspect-ratio: 16 / 9;
			transition: 0.5s ease;
			backface-visibility: hidden;
			object-fit: cover;
		}
	}

	&:hover {
		box-shadow:
			4px 4px 2px 0 rgb(0 0 0 / 20%),
			0 1px 4px 0 rgb(0 0 0 / 14%),
			0 2px 8px 0 rgb(0 0 0 / 12%);
		cursor: pointer;
	}

	.item-content {
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.overlay {
		transition: 0.5s ease;
		opacity: 0;
		position: absolute;
		bottom: 0%;
		right: 0%;
		padding: 1.5rem;

		button {
			background-color: var(--primary-color);
			color: var(--primary-color-text);
			font-size: 16px;
			padding: 16px 32px;
		}
	}

	&:hover {
		.image {
			transform: scale(1.03);
		}

		.overlay {
			opacity: 1;
			cursor: pointer;
		}
	}
}
</style>
