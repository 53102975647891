<template>
	<nav ref="mainMenu" class="main-menu">
		<div class="menu-bar" :class="{ 'border-bottom': isMenuCollapsed, 'px-1': isTablet }">
			<div class="button-container pl-1">
				<Button
					class="p-button-text p-button-primary p-button-rounded"
					@click="isMenuCollapsed = !isMenuCollapsed"
				>
					<font-awesome-icon :icon="['far', 'bars']" size="lg" />
				</Button>
				<div class="main-menu-logo-mobile">
					<img
						v-lazy="'/Static/images/cadacgroup-logo-mobile.svg'"
						style="cursor: pointer"
						@click="openUrl(layoutStore.vm.homePageUrl)"
					/>
				</div>
			</div>

			<div class="button-container justify-content-end">
				<div class="main-menu-icons d-flex justify-content-center">
					<font-awesome-icon
						:class="{ 'text-primary': layoutStore.showSearchBar }"
						:icon="['far', 'magnifying-glass']"
						:title="$t('menu.button.search_title')"
						@click="onSearchToggle($event)"
					/>
					<font-awesome-layers
						v-if="layoutStore.vm.itemsInCart"
						class="fa-fw fa-2x"
						@click="onCartClick()"
					>
						<font-awesome-icon
							:icon="['far', 'cart-shopping']"
							:title="$t('menu.button.cart_title')"
						/>
						<font-awesome-layers-text
							class="fa-layers-counter"
							transform="up-1 right-15"
							:value="layoutStore.vm.itemsInCart"
						/>
					</font-awesome-layers>
					<font-awesome-icon
						v-else
						:icon="['far', 'cart-shopping']"
						:title="$t('menu.button.cartEmpty_title')"
					/>
					<font-awesome-icon
						:icon="layoutStore.isAuthenticated ? ['fas', 'circle-user'] : ['fas', 'user']"
						:title="$t('menu.button.userMenu_title')"
						@click="onToggleUserMenuClick($event)"
					/>
				</div>
			</div>
		</div>
	</nav>
	<div
		 v-if="layoutStore.vm.uspBlock.items && 
		 layoutStore.vm.uspBlock.items.length && 
		 layoutStore.vm.uspBlock.isVisible"
		 class="w-100 hidden-print"
	 >
		<Carousel
			:autoplay-interval="5000"
			circular
			class="usp-bar"
			:num-scroll="2"
			:num-visible="2"
			:responsive-options="responsiveOptions"
			:show-indicators="false"
			:show-navigators="false"
			:value="layoutStore.vm.uspBlock.items"
		>
			<template #item="item">
				<div class="usp-bar-item">
					<template v-if="item.data.uspCollectionLink">
						<a :href="item.data.uspCollectionLink.url">
							<font-awesome-icon class="usp-icon" :icon="item.data.uspIcon" />
							{{ item.data.uspCollectionTitle }}
							<span class="usp-text-small">{{ item.data.uspSubText }}</span>
						</a>
					</template>
					<template v-if="!item.data.uspCollectionLink">
						<font-awesome-icon class="usp-icon" :icon="item.data.uspIcon" />
						{{ item.data.uspCollectionTitle }}
						<span class="usp-text-small">{{ item.data.uspSubText }}</span>
					</template>
				</div>
			</template>
		</Carousel>
	</div>

	<Sidebar
		v-model:visible="isMenuCollapsed"
		:base-z-index="1000"
		:dismissable="true"
		:position="isTablet ? 'left' : 'full'"
		:show-close-icon="true"
	>
		<template #header>
			<svg height="46.45" width="253.08">
				<use xlink:href="#main-logo" />
			</svg>
		</template>
		<div class="sidebar">
			<ul class="main-list p-1 mb-0">
				<li v-for="menuItem in layoutStore.vm.mainMenuItems" :key="menuItem" class="p-ripple h4">
					<a :href="menuItem.url" :title="menuItem.title"> {{ menuItem.label }}</a>
				</li>
			</ul>
			<Divider />
			<ul class="sub-list p-1 mb-0">
				<li v-for="menuItem in layoutStore.vm.subMenuItems" :key="menuItem" class="p-ripple h6">
					<a :href="menuItem.url" :title="menuItem.title"> {{ menuItem.label }}</a>
				</li>
			</ul>
			<LanguageDropdown :languages="layoutStore.vm.availableLanguageOptions" update-cookie use-full-width />
		</div>
	</Sidebar>

	<OverlayPanel
		ref="searchMenuPanel"
		append-to=".main-menu"
		:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
		class="main-menu-overlay"
		:style="{ width: '450px' }"
	>
		<MainSearchBar :vm="layoutStore.vm" />
	</OverlayPanel>

	<OverlayPanel
		ref="userMenuPanel"
		append-to=".main-menu"
		class="main-menu-overlay"
		:dismissable="true"
		:show-close-icon="false"
	>
		<MainUserMenu :vm="layoutStore.vm" />
	</OverlayPanel>

	<ChangeCompanyModal v-if="layoutStore.changeCompanyModalVisible" :vm="layoutStore.vm.organizationOptions" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ChangeCompanyModal from "@/components/common/menu/changeCompanyModal.vue";
import Divider from "primevue/divider";
import LanguageDropdown from "@/components/common/menu/languageDropdown.vue";
import MainSearchBar from "@/components/common/menu/mainSearchBar.vue";
import MainUserMenu from "@/components/common/menu/mainUserMenu.vue";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import Carousel from "primevue/carousel";
import { Component, Ref } from "vue-facing-decorator";

@Component({
	components: {
		ChangeCompanyModal,
		OverlayPanel,
		Sidebar,
		Divider,
		LanguageDropdown,
		MainUserMenu,
		MainSearchBar,
		Carousel,
	},
})
export default class MainMenuBarMobile extends BaseComponent {
	@Ref readonly userMenuPanel!: OverlayPanel;
	@Ref readonly searchMenuPanel!: OverlayPanel;
	@Ref readonly mainMenu!: HTMLElement;

	isMenuCollapsed = false;

	responsiveOptions = [
		{
			breakpoint: "1200px",
			numVisible: 2,
			numScroll: 2,
		},
		{
			breakpoint: "635px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	mounted(): void {
		this.layoutStore.navBarHeight = this.mainMenu?.clientHeight;
	}

	onCartClick(): void {
		this.openUrl(this.layoutStore.vm.cartPageUrl);
	}

	onToggleUserMenuClick(event: Event): void {
		this.userMenuPanel.toggle(event);
	}

	onChangeCompanyClick(): void {
		this.layoutStore.showModal("ChangeCompany");
	}

	onMenuToggle(): void {
		this.isMenuCollapsed = !this.isMenuCollapsed;
	}

	onSearchToggle(event: Event): void {
		this.layoutStore.toggleSearchBar();
		this.searchMenuPanel.toggle(event);
	}
}
</script>

<style scoped lang="scss">
.main-menu {
	position: sticky;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	top: 0;
	z-index: 120;
	background: var(--surface-a);
	border-bottom: 0.8px solid rgba(0, 0, 0, 0.15);

	.container {
		display: flex;
		align-items: center;
	}

	.menu-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 72px;

		.main-menu-logo-mobile {
			display: flex;
			flex: 1;
			height: 3rem;
		}

		.button-container {
			display: flex;
			flex: 1;
		}
	}
}

.main-menu-icons {
	flex-shrink: 0;
	z-index: 2;
	background: var(--surface-a);
	padding: 0.25rem 0.75rem;
	border-left: 1px solid var(--surface-d);
	display: flex;
	align-items: center;

	svg {
		font-size: 1.35rem;
		color: var(--secondary-color);
		padding: 0.25rem 0.5rem;
		cursor: pointer;
	}

	::v-deep(.p-badge) {
		transform: translate(30%, -45%);
		min-width: 1.2rem;
		height: 1.2rem;
		line-height: 1.2rem;
	}
}

.main-list {
	li {
		flex-shrink: 0;

		&:last-of-type {
			margin-bottom: 0;
		}

		a {
			font-size: 1.4rem;
			font-weight: 600;
			color: var(--text-color);
			position: relative;
			cursor: pointer;

			&::after {
				content: "";
				bottom: -6px;
				left: 0;
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: var(--primary-color);
				transition: transform 0.3s;
				transform: scaleX(0);
				transform-origin: right;
			}

			&:hover {
				text-decoration: none;
				box-shadow: none;

				&::after {
					opacity: 1;
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}
	}
}

.sub-list {
	li {
		flex-shrink: 0;
		margin-right: 1rem;

		a {
			font-size: 1.05rem;
			font-weight: 500;
			color: var(--text-color);
			position: relative;
			cursor: pointer;

			&::after {
				content: "";
				bottom: -6px;
				left: 0;
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: var(--primary-color);
				transition: transform 0.3s;
				transform: scaleX(0);
				transform-origin: right;
			}

			&:hover {
				text-decoration: none;
				box-shadow: none;

				&::after {
					opacity: 1;
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}
	}
}

.usp-bar {
	background: #373737;
	height: 2.5rem;
	font-size: 0.8125rem;
	padding: 0 1rem;

	@media (min-width: 576px) {
		padding: 0 1.5rem;
	}

	.usp-bar-item {
		color: #fff;
		display: flex;
		align-items: center;
		margin: 0;
		height: 2.5rem;
	}

	a {
		cursor: pointer;
		color: #fff;

		&:hover {
			text-decoration: underline;
		}
	}

	.usp-text-small {
		font-size: 0.6875rem;
		margin-left: 0.25rem;
	}

	.usp-icon {
		color: #fff;
		margin: 0.2rem 0.25rem;
		float: left;
	}
}

.language-menu {
	margin-top: 1rem;
	margin-left: -0.8rem;
}

.mobile-image {
	transform: scale(0.9);
}

:deep(.personal-overview) {
	width: 100%;
	min-width: 275px;
}

:deep(.main-search-bar) {
	flex-wrap: wrap;

	.search-category-dropdown {
		margin-left: 0;
		margin-right: 1rem;
		margin-bottom: 1rem;
	}

	.search-input {
		flex-basis: 100%;
		margin-left: 0 !important;
	}
}

::v-deep(.fa-layers-counter) {
	overflow: visible;
	width: fit-content;
	background-color: var(--primary-color);
	font-size: 0.9rem;
	font-weight: 500;
}

::v-deep(.main-menu-overlay) {
	top: 60px !important;
	right: 0;
	left: auto;
}
</style>
