<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '45vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		class="p-dialog-secondary"
		:closable="true"
		:header="$t('myCadac.expertSettings.selectExpertLevel')"
		:modal="true"
		:style="{ width: '35vw' }"
		@hide="onCancel()"
	>
		<p class="lead">
			{{ `${$t("myCadac.expertSettings.expertLevelIntro")}` }}
		</p>
		<SelectButton
			v-model="selectedLevel"
			class="p-select-button-grid m-0"
			data-key="displayName"
			:options="options"
		>
			<template #option="slotProps">
				<div class="d-flex flex-column align-items-center p-1">
					<div class="d-flex flex-column align-items-center">
						<Avatar
							:alt="slotProps.option?.displayName"
							class="mb-2"
							:image="slotProps.option.iconUrl"
							shape="square"
							size="large"
						/>
						<p class="font-weight-bold">
							{{ slotProps.option.displayName }}
						</p>
					</div>
				</div>
			</template>
		</SelectButton>
		<div v-if="selectedLevel">
			<p class="mt-2 font-weight-bold">
				{{
					$t("expert.expertLevelHourlyPriceRange", [
						formatCurrency(selectedLevel?.minPrice),
						formatCurrency(selectedLevel?.maxPrice),
					])
				}}
			</p>
			<div class="text-preline" v-html="selectedLevel?.description" />
			<hr />
			<div class="mb-2 text-preline" v-html="selectedLevel?.requirements" />
			<Message v-if="hasSelectedRequestedLevel" :closable="false" severity="info">
				{{ $t("myCadac.expertSettings.message.awaitingExpertLevelApproval") }}
			</Message>
		</div>

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :disabled="!canSubmit" :label="$t('common.confirm')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { useExpertStore } from "@/store/expert/expertStore";
import SelectButton from "primevue/selectbutton";
import { ExpertLevelProductViewModel } from "@/types/models/expert/expertLevelProductViewModel";

@Component({ components: { PopoverIcon, SelectButton } })
export default class ExpertLevelModal extends BaseComponent {
	selectedLevel!: ExpertLevelProductViewModel;
	isVisible = true;

	@Prop({ type: Object as PropType<ExpertLevelProductViewModel>, required: true, default: undefined })
	currentLevel!: ExpertLevelProductViewModel;

	@Prop({ type: Object as PropType<ExpertLevelProductViewModel>, required: true, default: undefined })
	requestedLevel!: ExpertLevelProductViewModel;

	@Prop({ type: Object as PropType<ExpertLevelProductViewModel[]>, required: true, default: [] })
	options!: ExpertLevelProductViewModel[];

	@Emit() levelSelected() {
		return this.selectedLevel;
	}

	created(): void {
		this.selectedLevel = this.currentLevel;
	}

	get canSubmit(): boolean {
		return !this.hasSelectedRequestedLevel && !!this.selectedLevel;
	}

	get hasSelectedRequestedLevel(): boolean {
		return this.selectedLevel?.expertProductContentId === this.requestedLevel?.expertProductContentId;
	}

	onCancel(): void {
		useExpertStore().hideModal("ExpertLevel");
	}

	onSubmit(): void {
		this.levelSelected();
	}
}
</script>

<style scoped lang="scss"></style>
