<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '50vw', '1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '40vw' }"
		@hide="cancelExtend()"
	>
		<template #header>
			<h3 class="mb-0">{{ $t("myCadac.contractDetail.extendModal.title") }}</h3>
		</template>
		<h5>{{ $t("myCadac.contractDetail.extendModal.currentContract") }}</h5>
		<dl class="contract-info">
			<div>
				<dt>{{ $t("myCadac.contractDetail.extendModal.colContractNumber") }}</dt>
				<dd>{{ vm.contract.contractId }}</dd>
			</div>
			<div>
				<dt>{{ $t("myCadac.contractDetail.extendModal.colDuration") }}</dt>
				<dd>{{ vm.contract.duration }}</dd>
			</div>
			<div>
				<dt>{{ $t("myCadac.contractDetail.extendModal.colRemainingDuration") }}</dt>
				<dd>
					{{ $tc("myCadac.contractDetail.table.duration", vm.contract.remainingMonths) }}
				</dd>
			</div>
			<div>
				<dt>{{ $t("myCadac.contractDetail.extendModal.colExpiresOn") }}</dt>
				<dd>{{ formatDate(vm.contract.endDate) }}</dd>
			</div>
		</dl>
		<h5>{{ $t("myCadac.contractDetail.extendModal.chooseContract") }}</h5>
		<DataTable
			v-if="extendOptions.length"
			v-model:selection="selectedProlongationOption"
			auto-layout
			data-key="contractNumber"
			:row-class="rowClass"
			selection-mode="single"
			:value="extendOptions"
		>
			<Column
				key="contractNumber"
				field="contractNumber"
				:header="$t('myCadac.contractDetail.extendModal.colContractNumber')"
			>
				<template #body="item">
					<p v-if="userProfile?.isCadacSales || userProfile?.isImpersonated" class="mb-0 text-bold">
						<a
							href="javascript:void(0);"
							:title="$t('common.goToCrm')"
							@click="openUrl(item.data.contractClient.crmUrl, '_blank')"
						>
							{{ item.data.contractNumber }}
						</a>
						<small v-if="item.data.contractClient.crmBusinessUnit" class="d-block text-muted">
							{{
								$t("myCadac.contractDetail.extendModal.lblCrmBusinessUnit", {
									name: item.data.contractClient.crmBusinessUnit.name,
								})
							}}
						</small>
					</p>
					<p v-else class="mb-0 text-bold">
						{{ item.data.contractNumber }}
					</p>
				</template>
			</Column>
			<Column key="products" :header="$t('myCadac.contractDetail.extendModal.colProducts')">
				<template #body="item">
					<AvatarGroup>
						<Avatar
							v-for="(asset, index) in item.data.contractClient?.contractLines?.slice(
								0,
								maxContractAssetsToShow
							)"
							:key="index"
							:alt="asset.iconAlt"
							class="ml-0 bg-transparent"
							:image="asset.icon"
							shape="square"
							size="small"
							:title="asset.productName"
						>
						</Avatar>
						<Avatar
							v-if="item.data.contractClient?.contractLines?.length > maxContractAssetsToShow"
							class="text-bold ml-0"
							:label="`+${item.data.contractClient?.contractLines?.length - maxContractAssetsToShow}`"
							shape="square"
							size="small"
						>
						</Avatar>
					</AvatarGroup>
				</template>
			</Column>
			<Column
				key="renewalTerm"
				field="duration"
				:header="$t('myCadac.contractDetail.extendModal.colDuration')"
			/>
			<Column key="endDate" field="endDate" :header="$t('myCadac.contractDetail.extendModal.colExpiresOn')">
				<template #body="item">
					<span>
						{{ formatDate(item.data.endDate) }}
					</span>
				</template>
			</Column>
			<Column key="remainingTerm" :header="$t('myCadac.contractDetail.table.colRemainingDuration')">
				<template #body="item">
					<span>
						{{ $tc("myCadac.contractDetail.table.duration", item.data.remainingMonthsForOrder) }}
					</span>
				</template>
			</Column>
		</DataTable>
		<Message v-if="isRowDisabled(selectedProlongationOption)" class="mb-0" :closable="false" severity="error">
			{{ $t("myCadac.contractDetail.message.selectedOptionNotAvailable") }}
		</Message>
		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="cancelExtend()" />
			<Button :disabled="!canSubmit" :label="$t('common.confirm')" @click="confirmExtend()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { ContractClient } from "@/types/generated/Api/contractClient";
import { ContractLineClient } from "@/types/generated/Api/contractLineClient";
import { ContractProlongationOption } from "@/types/generated/Api/contractProlongationOption";
import { ContractProlongationOptionType } from "@/types/enum/contractProlongationOptionType";
import { IContractDetail } from "@/types/viewModels/myCadac/contractDetailViewModel";
import { PropType } from "vue";
import { add, isBefore, set } from "date-fns";

@Component
export default class ContractExtendModal extends BaseComponent {
	@Prop({ type: Object as PropType<IContractDetail>, required: true, default: {} })
	vm!: IContractDetail;
	@Prop()
	prolongationOptions!: ContractProlongationOption[];
	isVisible = true;
	maxContractAssetsToShow = 3;
	selectedProlongationOption: ContractProlongationOption | null = null;

	get extendOptions(): ContractProlongationOption[] {
		return this.userProfile?.isCadacSales || this.userProfile?.isImpersonated
			? this.prolongationOptions
			: this.prolongationOptions.filter((x) => x.type === 1);
	}

	get canSubmit(): boolean {
		return !!this.selectedProlongationOption && !this.isRowDisabled(this.selectedProlongationOption);
	}

	@Emit()
	cancelExtend(): boolean {
		return false;
	}

	@Emit()
	confirmExtend(): ContractProlongationOption | null {
		return this.selectedProlongationOption;
	}

	isRowDisabled(_contract: ContractProlongationOption): boolean {
		return _contract?.type === ContractProlongationOptionType.extend;
	}

	rowClass(contract: ContractProlongationOption): string {
		return this.isRowDisabled(contract) ? "disabled" : "";
	}

	calculateNewEndDate(contract: ContractClient | ContractLineClient | ContractProlongationOption): Date {
		const currentEndDate = this.toDate(this.vm.contract.endDate);
		// When extending or co-terming use the extend contract's endDate, else the current endDate.
		let newEndDate = contract ? this.toDate(contract.endDate) : currentEndDate;
		// If extending only the month/day of the chosen contract should be synced with the current endDate, but it cannot be in the past.
		if ((contract as ContractProlongationOption)?.type === ContractProlongationOptionType.extend) {
			newEndDate = set(newEndDate, { year: currentEndDate.getFullYear() });
			if (isBefore(newEndDate, currentEndDate)) {
				newEndDate = add(newEndDate, { years: 1 });
			}
		}
		return newEndDate;
	}
}
</script>

<style lang="scss" scoped>
.contract-info {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
	margin-bottom: 2rem;
	background: var(--surface-b);

	dt {
		color: var(--text-color);
	}

	dd {
		color: var(--text-color-secondary);
	}
}
</style>
