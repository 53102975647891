<template>
	<div class="block mt-0">
		<div class="container-fluid">
			<div class="row">
				<div class="col-6 split-block" :style="getBackgroundStyle(vm.backgroundColor, 0)">
					<h2 v-if="vm.leftTitle" class="display-5 mb-1" v-text="vm.leftTitle"></h2>
					<p class="lead" v-text="vm.leftText"></p>
					<div class="mt-2">
						<LinkButton
							v-if="vm.leftCta"
							:class="
								ctaButtonClass(vm.leftCtaStyle) || 'p-button-raised p-button-text p-button-arrow'
							"
							:label="vm.leftCta.text"
							:target="vm.leftCta.target"
							:title="vm.leftCta.title"
							:url="vm.leftCta.url"
						/>
					</div>
				</div>

				<div class="col-6 split-block" :style="getBackgroundStyle(vm.backgroundColor, 1)">
					<h2 v-if="vm.rightTitle" class="display-5 mb-1" v-text="vm.rightTitle"></h2>
					<p class="lead" v-text="vm.rightText"></p>
					<div class="mt-2">
						<LinkButton
							v-if="vm.rightCta"
							:class="
								ctaButtonClass(vm.rightCtaStyle) || 'p-button-raised p-button-text p-button-arrow'
							"
							:label="vm.rightCta.text"
							:target="vm.rightCta.target"
							:title="vm.rightCta.title"
							:url="vm.rightCta.url"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { CallToActionSplitBlockViewModel } from "@/types/viewModels/blocks/callToActionSplitBlockViewModel";
import { BlockBackgroundColor } from "@/types/enum/blockBackgroundColor";
import BaseBlock from "@/components/base/baseBlock.vue";

@Component
export default class CallToActionSplitBlock extends BaseBlock {
	@Prop({ type: Object as PropType<CallToActionSplitBlockViewModel>, required: true, default: {} })
	vm!: CallToActionSplitBlockViewModel;

	getBackgroundStyle(backgroundColor: BlockBackgroundColor, index: number) {
		switch (backgroundColor) {
			case BlockBackgroundColor.Grey:
				return { backgroundColor: index === 0 ? "var(--gray-500)" : "var(--gray-600)" };
			case BlockBackgroundColor.Pink:
				return { backgroundColor: index === 0 ? "var(--purple-200)" : "var(--purple-300)" };
			case BlockBackgroundColor.Purple:
				return { backgroundColor: index === 0 ? "var(--purple-500)" : "var(--purple-600)" };
			case BlockBackgroundColor.Green:
				return { backgroundColor: index === 0 ? "var(--green-500)" : "var(--green-600)" };
			case BlockBackgroundColor.White:
			default:
				return {};
		}
	}
}
</script>

<style lang="scss" scoped>
.split-block {
	color: var(--primary-color-text);
	padding: 2rem;

	&:first-of-type {
		text-align: right;
	}
}
</style>
