import axios from '@/types/helpers/apiHelper';
import { Gender } from '@/types/enum/gender';
import { IPhoneObject } from '@/types/models/common/phoneObject.interface';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

export class ValidationHelper {
	/**
	 * Validate if the given value is a valid VAT number
	 * Note: This is an async request!
	 * @param value
	 * @param countryCode
	 * @param useVat
	 */
	static async validateVat(value: string, countryCode: string, useVat: boolean): Promise<boolean> {
		if ((!value && !this.checkIfVatIsRequired(countryCode)) || !useVat) return Promise.resolve(true);
		else if (!value) return Promise.resolve(false);
		const result = await axios.get(`/api/finance/validatevat/${value}`, { requestId: 'validateVat' });
		return Promise.resolve(!!JSON.parse(result.data));
	}

	/**
	 * Check if country is in EU, but not NL; then VAT number is required.
	 * @param countryCode
	 */
	static checkIfVatIsRequired(countryCode: string): boolean {
		if (!countryCode) return true;
		const requiredCountries = [
			'AT',
			'BE',
			'BG',
			'CY',
			'CH',
			'NO',
			'CZ',
			'DE',
			'DK',
			'EE',
			'ES',
			'FI',
			'FR',
			'GR',
			'HR',
			'HU',
			'IE',
			'IT',
			'LT',
			'LU',
			'LV',
			'MT',
			'PL',
			'PT',
			'RO',
			'SE',
			'SI',
			'SK',
			'SM',
			'XI',
		];

		return (
			countryCode !== 'NL' &&
			countryCode !== 'GB' &&
			countryCode !== 'CH' &&
			countryCode !== 'NO' &&
			requiredCountries.includes(countryCode)
		);
	}

	/**
	 * Validate if the given value is a valid IBAN number
	 * Note: This is an async request!
	 * @param value
	 * @param useIban
	 */
	static validateIban(value: string, useIban: boolean): boolean {
		if (!value || !useIban) return true;
		return isValidIBAN(electronicFormatIBAN(value) || value);
	}

	/**
	 * Checks if the given value matches the following:
	 *  - At least one lowercase
	 *  - At least one uppercase
	 *	- At least one numeric
	 *	- Minimum 8 characters
	 * @param value
	 */
	static validatePassword(value: string): any {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value);
	}

	/**
	 * Validate if the given value is a valid postal code for the given countryCode
	 * @param countryCode
	 * @param value
	 */
	static validatePostalCode(value: string, countryCode: string): boolean {
		switch (countryCode) {
			case 'NL':
				return /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(value);
			case 'BE':
				return /^\d{4}$/.test(value);
			case 'AT':
				return /^\d{4}$/.test(value);
			case 'CH':
				return /^\d{4}$/.test(value);
			case 'DE':
				return /^\d{5}$/.test(value);
			case 'PT':
				return /^\d{4}[- ]{0,1}\d{3}$/.test(value);
			case 'ES':
				return /^\d{5}$/.test(value);
			case 'UK':
			case 'GB':
				return /^((([A-PR-UWYZ][0-9])|([A-PR-UWYZ][0-9][0-9])|([A-PR-UWYZ][A-HK-Y][0-9])|([A-PR-UWYZ][A-HK-Y][0-9][0-9])|([A-PR-UWYZ][0-9][A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRVWXY]))\s?([0-9][ABD-HJLNP-UW-Z]{2})|(GIR)\s?(0AA))$/i.test(
					value
				);
		}
		return true;
	}

	/**
	 * Check if the CoC number is valid.
	 * @param value
	 */
	static validateCocNumber(value: string): boolean {
		if (!value) return true;
		return /^\d{8}$/.test(value);
	}

	/**
	 * Check if email is valid
	 * Regex taken from Vuelidate package.
	 * @param value
	 */
	static validateEmail(value: string): boolean {
		return /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i.test(
			value
		);
	}

	/**
	 * Checks if the PhoneObject that should be in the component is valid (Using Vue-tel-input).
	 * @param value
	 * @param phoneObject
	 */
	static validatePhoneObject(value: string, phoneObject: IPhoneObject): boolean {
		return phoneObject?.valid !== undefined ? phoneObject.valid : !!value;
	}

	/**
	 * Checks if the phoneObjectValid boolean that should be in the component is valid (Using Vue-tel-input).
	 * Alternative version of validatePhoneObject that checks for a boolean instead of an object.
	 * @param value
	 * @param siblings
	 * @param vm
	 */
	static validatePhoneObjectValid(value: string, siblings: any, vm: any): boolean {
		if (!vm && siblings) vm = siblings;
		return vm.phoneObjectValid !== undefined ? vm.phoneObjectValid : !!value;
	}

	/**
	 * Validates a gender value.
	 *
	 * @param {Gender} value - The gender value to validate.
	 * @returns {boolean} `true` if the value is a valid gender, `false` otherwise.
	 */
	static validateGender(value: Gender): boolean {
		return value === Gender.Male || value === Gender.Female;
	}
}
