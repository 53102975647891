import { defineStore } from 'pinia';
import { values } from 'lodash';

interface LoadingStoreState {
	loadingCount: number;
	loadingSpinner: { [key: string]: boolean };
}

export const useLoadingStore = defineStore('loading', {
	state: () =>
		({
			loadingSpinner: {},
			loadingCount: 0,
		}) as LoadingStoreState,
	getters: {
		isSpinnerVisible(state): (key: string) => boolean {
			return (key: string) => !!state.loadingSpinner[key];
		},
		isBusy(state): boolean {
			return this.isLoading || (state.loadingSpinner && values(state.loadingSpinner).some(Boolean));
		},
		isLoading(state): boolean {
			return state.loadingCount > 0;
		},
	},
	actions: {
		increaseLoadingCount(): void {
			this.loadingCount++;
		},
		decreaseLoadingCount(): void {
			this.loadingCount--;
		},
		showSpinner(name: string | number): void {
			this.loadingSpinner[String(name)] = true;
		},
		hideSpinner(name: string | number): void {
			this.loadingSpinner[String(name)] = false;
		},
	},
});
