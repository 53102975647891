<template>
	<section class="insights-section">
		<div
			v-if="!isSpinnerVisible('advice') && !showNoInsightMessage"
			class="insights-header insights-section-card mb-3 py-2 px-2"
		>
			<div class="score-chart mb-1">
				<Chart :data="scoreChartData" :options="scoreChartOptions" type="doughnut" />
			</div>
			<h1 v-if="score" class="text-primary font-weight-normal mb-0">
				{{ $t("myCadac.advisorInsights.scoreTitle") }}
			</h1>

			<p class="lead text-center mb-2">{{ $t("myCadac.advisorInsights.title", { months: 12 }) }}</p>

			<small class="text-center mb-2">{{ $t("myCadac.advisorInsights.contactMessage") }}</small>

			<Button
				v-if="layoutStore.chatWidgetAvailable"
				class="p-button-success p-button-sm"
				:label="$t('digitalAdvisor.button.contactUs')"
				@click="initChat(`${$t('myCadac.advisorAdvice.chatMessage.contactSupport')}`)"
			/>
		</div>

		<BlockUI
			v-if="!isSpinnerVisible('advice') && hasInsightProductsToShow"
			auto-z-index
			:blocked="advisorStore.isAccessRestricted"
			class="blurred-content"
		>
			<div class="insights-section-card insights-content py-2">
				<AdvisorInsightSkeleton v-if="isSpinnerVisible('advice')" />

				<div class="insights-overview">
					<div class="text-center">
						<h4 class="text-primary font-weight-normal mb-0">
							{{ $t("myCadac.advisorInsights.usageAnalysis") }}
						</h4>
					</div>

					<div class="d-flex align-items-center justify-content-center p-1">
						<div class="d-flex align-items-center">
							<div class="legend bg-success"></div>
							<span class="mr-1">{{ $t("myCadac.advisorInsights.optimal") }}</span>
							<div class="legend bg-danger"></div>
							<span>{{ $t("myCadac.advisorInsights.suboptimal") }}</span>
						</div>
					</div>
					<Divider />
					<ScrollPanel
						class="px-1 custom-scroll-bar"
						style="width: 100%; height: clamp(450px, 35vh, 1000px)"
					>
						<Accordion
							:active-index="productAccordionActiveIndexes"
							class="accordion-products"
							:multiple="true"
						>
							<AccordionTab v-for="product in insightProductsToShow" :key="`${product.name}`">
								<template #header>
									<div
										class="product-wrapper justify-content-center justify-content-lg-start text-center text-lg-left"
									>
										<div class="pr-2">
											<span class="lead text-primary font-weight-normal mb-0">
												{{ product.name }} -
												<small class="text-grey mb-0"
													>Seats: <b>{{ product.statistics.seats }}</b></small
												>
											</span>
										</div>

										<div class="d-flex flex-row w-100">
											<div class="product-icon-wrapper mr-1">
												<img
													v-lazy="product.iconUrl"
													:alt="product.iconAlt"
													class="product-icon"
													:title="product.name"
												/>
											</div>
											<div class="product-usage-wrapper">
												<div class="product-usage-details">
													<div
														v-if="product.statistics.optimalUsage !== 0"
														class="usage-details-positive"
														:style="{ width: `${product.statistics.optimalUsage}%` }"
													>
														<span
															>{{
																product.statistics.optimalUsage.toFixed(0)
															}}%</span
														>
													</div>
													<div
														v-if="product.statistics.subOptimalUsage !== 0"
														class="usage-details-negative"
														:style="{
															width: `${product.statistics.subOptimalUsage}%`,
														}"
													>
														<span
															>{{
																product.statistics.subOptimalUsage.toFixed(0)
															}}%</span
														>
													</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								<!--	Accordion content-->
								<div
									v-for="(item, index) in product.licenses"
									:key="`${product.name}_item${index}`"
									class="product-wrapper product-licences justify-content-center justify-content-lg-start text-center text-lg-left"
								>
									<div class="product-icon-wrapper mr-1">
										<font-awesome-icon
											class="product-item-icon"
											:icon="productItemTypeIcon(item.licenseType)"
											size="1x"
										/>
									</div>

									<div class="product-usage-wrapper">
										<div class="pr-2">
											<span class="text-primary font-weight-normal mb-0">
												{{ productItemTypeLabel(item.licenseType) }} -
												<small class="text-grey mb-0"
													>Seats: <b>{{ item.usage?.seats }}</b></small
												>
											</span>
										</div>

										<div class="product-usage-details">
											<div
												v-if="item.usage.optimalUsage !== 0"
												class="usage-details-positive"
												:style="{ width: `${item.usage.optimalUsage}%` }"
											>
												<span>{{ item.usage.optimalUsage.toFixed(0) }}%</span>
											</div>
											<div
												v-if="item.usage.subOptimalUsage !== 0"
												class="usage-details-negative"
												:style="{ width: `${item.usage.subOptimalUsage}%` }"
											>
												<span>{{ item.usage.subOptimalUsage.toFixed(0) }}%</span>
											</div>
										</div>
									</div>
								</div>
								<Divider />
							</AccordionTab>
							<Divider />
						</Accordion>
					</ScrollPanel>

					<Divider />
					<div class="text center px-2 pb-2">
						<p class="mb-0 mt-1">{{ $t("myCadac.advisorInsights.totalUsageDisclaimer") }}</p>
					</div>
				</div>
			</div>
		</BlockUI>
	</section>
</template>

<script lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AdvisorInsightSkeleton from "@/components/advisor/advisorInsightSkeleton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import Chart from "primevue/chart";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import ScrollPanel from "primevue/scrollpanel";
import Skeleton from "primevue/skeleton";
import { ChartData, ChartOptions } from "chart.js";
import { AdvisorLicenseType } from "@/types/enum/advisorLicenseType";
import { Component } from "vue-facing-decorator";
import { useAdvisorStore } from "@/store/advisor/advisorStore";
import Divider from "primevue/divider";
import { AdvisorProductViewModel } from "@/types/models/advisor/productViewModel";

@Component({
	components: {
		AdvisorInsightSkeleton,
		PopoverIcon,
		Accordion,
		AccordionTab,
		Skeleton,
		ScrollPanel,
		Chart,
		Divider,
	},
})
export default class AdvisorInsightsOverview extends BaseComponent {
	productAccordionActiveIndexes: number[] = [];
	advisorStore = useAdvisorStore();

	minScore = 1;
	maxScore = 10;
	redColor = [220, 53, 69]; // dark red color in RGB format
	yellowColor = [250, 188, 60]; // neutral yellow/orange color in RGB format
	greenColor = [0, 167, 0]; // dark green color in RGB format

	get score(): number {
		return this.advisorStore.insight?.score ? Math.round(this.advisorStore.insight?.score) : 1;
	}

	get scoreColor(): string {
		return this.getColorForScore(this.score);
	}

	get scoreChartData(): ChartData {
		const documentStyle = getComputedStyle(document.body);
		return {
			datasets: [
				{
					data: [this.score, this.maxScore - this.score],
					backgroundColor: [
						this.scoreColor,
						this.score >= 1
							? documentStyle.getPropertyValue("--surface-a")
							: documentStyle.getPropertyValue("--surface-b"),
					],
				},
			],
		};
	}

	get scoreChartOptions(): ChartOptions<"doughnut"> {
		return {
			cutout: "80%",
			animation: {
				duration: 2000,
				onProgress: (animation) => {
					if (this.score < 1) return;
					const chartInstance = animation.chart;
					const ctx = chartInstance.ctx;
					const centerX = (chartInstance.chartArea.left + chartInstance.chartArea.right) / 2;
					const centerY = (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;

					let score =
						Math.round(
							(((this.score - this.minScore) * animation.currentStep) / animation.numSteps) * 10
						) /
							10 +
						this.minScore;

					ctx.clearRect(centerX - 37.5, centerY - 40, 75, 80); // Clear only the area where the text is drawn
					ctx.font = "bold 2rem futura-pt";
					ctx.fillStyle = this.getColorForScore(score);
					ctx.textAlign = "center";
					ctx.textBaseline = "middle";
					ctx.fillText(`${this.$n(score)}`, centerX, centerY);
				},
				onComplete: (animation) => {
					if (this.score < 1) return;
					const chartInstance = animation.chart;
					const ctx = chartInstance.ctx;
					const centerX = (chartInstance.chartArea.left + chartInstance.chartArea.right) / 2;
					const centerY = (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;
					ctx.clearRect(centerX - 37.5, centerY - 40, 75, 80); // Clear only the area where the text is drawn
					ctx.font = "bold 2rem futura-pt";
					ctx.fillStyle = this.scoreColor;
					ctx.textAlign = "center";
					ctx.textBaseline = "middle";
					ctx.fillText(`${this.$n(this.score)}/10`, centerX, centerY);
				},
			},
			plugins: {
				tooltip: {
					enabled: false,
				},
			},
			events: [],
		};
	}

	get productItemTypeIcon(): (type: AdvisorLicenseType) => string[] {
		return (type) => {
			switch (type) {
				case 1:
					return ["far", "file-contract"];
				case 2:
					return ["far", "folders"];
				case 3:
					return ["far", "coins"];
				default:
					return [];
			}
		};
	}

	get productItemTypeLabel(): (type: AdvisorLicenseType) => string {
		return (type) => {
			return this.$t(`enum.licenceTypeInsight.${type}`);
		};
	}

	getColorForScore(score: number): string {
		if (score < 6) {
			// interpolate between red and yellow
			const redYellowRange = this.yellowColor.map((value, index) =>
				Math.round(
					this.redColor[index] +
						((value - this.redColor[index]) * (score - this.minScore)) / (6 - this.minScore)
				)
			);
			return `rgb(${redYellowRange.join(",")})`;
		} else {
			// interpolate between yellow and green
			const yellowGreenRange = this.greenColor.map((value, index) =>
				Math.round(
					this.yellowColor[index] +
						((value - this.yellowColor[index]) * (score - 6)) / (this.maxScore - 6)
				)
			);
			return `rgb(${yellowGreenRange.join(",")})`;
		}
	}

	get showNoInsightMessage(): boolean {
		return !this.advisorStore.hasInsightProductsToShow;
	}

	get hasInsightProductsToShow(): boolean {
		return this.advisorStore.hasInsightProductsToShow;
	}

	get insightProductsToShow(): AdvisorProductViewModel[] {
		return this.advisorStore.insightProductsToShow;
	}

	initChat(message: string): void {
		this.chatWidget.open({ name: message });
	}
}
</script>

<style scoped lang="scss">
::v-deep(.accordion-products) {
	.p-accordion-header {
		&.p-highlight .p-accordion-header-link {
			background: var(--surface-b) !important;
		}

		&:not(.p-highlight):hover .p-accordion-header-link {
			text-decoration: none;
			background: inherit;
			cursor: pointer;
		}

		.p-accordion-header-link {
			background: inherit;
			text-decoration: none;
			padding: 0.75rem 0;

			&:focus {
				box-shadow: none;
			}

			.p-accordion-toggle-icon {
				font-size: 1.1rem;
				margin: 0 1rem;
			}
		}
	}

	.p-accordion-content {
		border: none;
		background-color: inherit;
		padding: 0;

		.product-wrapper {
			gap: 0.5rem;

			.product-users {
				font-size: 1rem;
				background-color: inherit;
			}

			.product-usage-wrapper {
				.product-usage-details {
					height: 1.35rem;

					span {
						font-size: 0.8rem;
					}

					.usage-details-negative,
					.usage-details-positive {
						border-radius: 6px;
					}
				}
			}
		}
	}
}

.product-wrapper {
	display: flex;
	align-items: center;
	flex: 1;
	gap: 0.75rem;
	min-height: 85px;
	flex-wrap: wrap;

	.product-icon-wrapper {
		display: flex;
		flex: 0 0 10%;

		.product-icon {
			width: 40px;
			height: 40px;
		}

		.product-item-icon {
			margin: auto;
			background: white;
			border-radius: 50%;
			font-size: 1.5rem;
		}
	}

	.product-users {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 3rem;
		padding: 0.5rem;
		height: 100%;
		font-size: 1rem;
		background-color: var(--surface-b);
		color: var(--text-color-secondary);
	}

	.product-usage-wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: flex-start;
		justify-content: flex-start;
		height: 100%;
		gap: 0.25rem;
		padding-right: 0.5rem;

		.product-usage-statistics {
			flex: 1;
			padding: 0 0.5rem;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: wrap;
			flex-direction: column;
			width: 100%;
		}

		.product-usage-placeholder {
			height: 1.35rem;
			width: 100%;
			gap: 0.25rem;
			display: flex;

			.usage-details-default {
				border-radius: 8px;
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}

		.product-usage-details {
			height: 1.75rem;
			width: 100%;
			gap: 0.25rem;
			display: flex;
			align-items: center;

			span {
				font-weight: 500;
				font-size: 0.95rem;
			}

			.usage-details-negative {
				border-radius: 8px;
				background-color: var(--danger-color);
				color: var(--primary-color-text);
				display: flex;
				justify-content: center;
			}

			.usage-details-positive {
				border-radius: 8px;
				background-color: var(--success-color);
				color: var(--primary-color-text);
				display: flex;
				justify-content: center;
			}
		}
	}
}

.insights-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.legend {
	height: 0.65rem;
	width: 0.65rem;
	background-color: black;
	margin-right: 5px;
}

::v-deep(.score-chart) {
	width: 100%;
	padding: 0 1rem 1rem 1rem;

	.p-chart {
		width: 50%;
		margin: 0 auto;
	}
}

::v-deep(.p-component-overlay-enter) {
	animation: p-component-overlay-content-lock-animation-enter 150ms forwards;
}

::v-deep(.p-component-overlay-leave) {
	animation: p-component-overlay-content-lock-animation-leave 150ms forwards;
}

@keyframes p-component-overlay-content-lock-animation-enter {
	from {
		background-color: transparent;
	}
	to {
		transform-style: preserve-3d;
		backdrop-filter: blur(4px);
		background-color: rgba(0, 0, 0, 0.02);
		border-radius: 10px;
		box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
	}
}

@keyframes p-component-overlay-content-lock-animation-leave {
	from {
		transform-style: preserve-3d;
		backdrop-filter: blur(4px);
		background-color: rgba(0, 0, 0, 0.02);
		border-radius: 10px;
		box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
	}
	to {
		background-color: transparent;
	}
}

::v-deep(.blurred-content) {
	height: 100%;
	width: 100%;
	overflow: visible;

	.p-component-overlay:before {
		content: "\e95f";
		font-family: primeicons;
		font-style: normal;
		font-size: 3.25rem;
		font-weight: 400;
		font-variant: normal;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.insights-section-card {
	color: #495057;
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
}

.score-cta {
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
}

.product-licences {
	margin-left: 3.1rem;
}

::v-deep(.p-scrollpanel.custom-scroll-bar .p-scrollpanel-bar) {
	background-color: var(--surface-300);
	border-radius: 0;
	opacity: 1;
	transition: background-color 0.3s;
	transform: translateX(-10px);
}
</style>
