<template>
	<Message :closable="false" severity="custom">
		<div class="d-flex align-items-center">
			<svg class="mr-1 flex-shrink-0" height="60" width="60">
				<use xlink:href="#icon-wallet" />
			</svg>
			<div class="py-1">
				<i18n-t
					v-if="!layoutStore.isAuthenticated"
					keypath="common.productSummary.loginForWalletAdvantages"
				>
					<template #loginLink>
						<a :href="loginPageUrl">{{ $t("common.login") }}</a>
					</template>
					<template #registerLink>
						<a :href="registerPageUrl">{{ $t("common.register")?.toLowerCase() }}</a>
					</template>
				</i18n-t>
				<i18n-t v-else keypath="common.productSummary.earnWalletCredit">
					<template #amount>
						<b class="text-success" style="margin-right: 4px">
							{{ formatCurrency(walletAmount) }}
						</b>
					</template>
				</i18n-t>
			</div>
		</div>
	</Message>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";

@Component
export default class WalletMessage extends BaseComponent {
	@Prop({ type: Number, required: false, default: 0 }) walletAmount!: number;
}
</script>

<style></style>
