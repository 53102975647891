import { IComponent } from '@/types/models/common/component.interface';

// Vendor Imports
import Popper from 'vue3-popper';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import CountryFlag from 'vue-country-flag-next';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { VueRecaptcha } from 'vue3-recaptcha-v2';

// Component Imports
import BackButton from '@/components/common/backButton.vue';
import LinkButton from '@/components/common/linkButton.vue';
import PopoverIcon from '@/components/common/popoverIcon.vue';
import PriceSummary from '@/components/common/priceSummary.vue';
import ProductAddedModal from '@/components/commerce/productAddedModal.vue';
import StatusBadge from '@/components/common/statusBadge.vue';

// Prime Imports
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import RadioButton from 'primevue/radiobutton';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Textarea from 'primevue/textarea';
import Inplace from 'primevue/inplace';
import BlockUI from 'primevue/blockui';
import MultiSelect from 'primevue/multiselect';

export const vendorComponents: IComponent[] = [
	{ element: 'Popper', component: Popper },
	{ element: 'font-awesome-icon', component: FontAwesomeIcon },
	{ element: 'font-awesome-layers', component: FontAwesomeLayers },
	{ element: 'font-awesome-layers-text', component: FontAwesomeLayersText },
	{ element: 'country-flag', component: CountryFlag },
	{ element: 'Form', component: Form },
	{ element: 'Field', component: Field },
	{ element: 'ErrorMessage', component: ErrorMessage },
	{ element: 'vue-recaptcha', component: VueRecaptcha },
];

export const components: IComponent[] = [
	...vendorComponents,
	{ element: 'BackButton', component: BackButton },
	{ element: 'LinkButton', component: LinkButton },
	{ element: 'PopoverIcon', component: PopoverIcon },
	{ element: 'PriceSummary', component: PriceSummary },
	{ element: 'ProductAddedModal', component: ProductAddedModal },
	{ element: 'StatusBadge', component: StatusBadge },
];

export const primeComponents: IComponent[] = [
	{ element: 'Avatar', component: Avatar },
	{ element: 'AvatarGroup', component: AvatarGroup },
	{ element: 'Badge', component: Badge },
	{ element: 'BlockUI', component: BlockUI },
	{ element: 'Button', component: Button },
	{ element: 'Card', component: Card },
	{ element: 'Column', component: Column },
	{ element: 'DataTable', component: DataTable },
	{ element: 'Dialog', component: Dialog },
	{ element: 'Dropdown', component: Dropdown },
	{ element: 'InputNumber', component: InputNumber },
	{ element: 'InputSwitch', component: InputSwitch },
	{ element: 'InputText', component: InputText },
	{ element: 'Inplace', component: Inplace },
	{ element: 'Message', component: Message },
	{ element: 'MultiSelect', component: MultiSelect },
	{ element: 'Panel', component: Panel },
	{ element: 'RadioButton', component: RadioButton },
	{ element: 'TabPanel', component: TabPanel },
	{ element: 'TabView', component: TabView },
	{ element: 'Textarea', component: Textarea },
];
