<template>
	<div ref="searchBar" class="main-search-bar pl-2 pl-lg-0">
		<Dropdown
			v-model="searchCategory"
			append-to="self"
			class="search-category-dropdown"
			:class="{ 'ml-lg-0': layoutStore.showSearchBar }"
			option-label="label"
			option-value="category"
			:options="vm.searchCategoryOptions"
			scroll-height="300px"
		>
		</Dropdown>
		<AutoComplete
			ref="searchInput"
			append-to="self"
			class="mx-2 search-input"
			complete-on-focus
			input-id="mainSearchBarInput"
			:model-value="searchString"
			option-label="name"
			panel-class="search-panel"
			:placeholder="$t('menu.search_placeholder')"
			scroll-height="420px"
			:suggestions="searchResults"
			@blur="trackSearch(searchString)"
			@complete="onSearch"
			@item-select="onSearchResultSelected"
			@update:modelValue="updateSearchstring"
		>
			<template #header="{ value }">
				<div class="pt-2 pl-2">
					<p class="mb-0">
						<i18n-t keypath="menu.searchResultHeader">
							<template #query>
								<b>{{ value.name ?? value }}</b>
							</template>
							<template #count>
								<span v-if="!hasNoResults">
									{{ searchResult?.totalResults }}
								</span>
							</template>
						</i18n-t>
					</p>
				</div>
			</template>
			<template #option="{ option }">
				<div
					v-if="!hasNoResults"
					class="d-flex justify-content-start flex-row px-2 py-1"
					:title="option.intro || option.name"
				>
					<img v-if="option.hasIconUrl" v-lazy="option.iconUrl" alt="Icon" height="28" width="28" />

					<Badge v-else :value="option.type.charAt(0)"></Badge>

					<div class="ml-2">
						{{ option.name }}
					</div>

					<div>
						<Chip class="p-chip-secondary ml-2">{{ option.type }}</Chip>
					</div>
				</div>
			</template>
			<template #footer>
				<div v-if="hasNoResults" class="px-2 pb-2">
					{{ $t("menu.noSearchResults") }}
				</div>
				<Button
					v-if="showDisplayAllResultsButton"
					class="p-button-text p-button-secondary ml-2 mb-1"
					:label="$t('menu.button.showAllSearchResults')"
					@click="onShowAllResultsClick"
				/>
				<!--				<div class="pl-2 pb-2">-->
				<!--					<p class="text-muted">{{ $t("menu.searchResultFooter") }}</p>-->
				<!--					<div>-->
				<!--						<Button-->
				<!--							v-for="option in relatedSearches"-->
				<!--							:key="option"-->
				<!--							:label="option"-->
				<!--							class="p-button-sm p-button-outlined p-button-rounded mr-2"-->
				<!--							@click.stop="onRelatedSearchTermSelected($event, option)"-->
				<!--						/>-->
				<!--					</div>-->
				<!--				</div>-->
			</template>
		</AutoComplete>
	</div>
</template>

<script lang="ts">
import AutoComplete, { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import { Component, Prop, Ref, Watch } from "vue-facing-decorator";
import { DomHandler } from "primevue/utils";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SearchCategory } from "@/types/enum/searchCategory";
import { SearchOptionViewModel } from "@/types/models/search/searchOption";
import { SearchResultItemViewModel } from "@/types/models/search/searchResultItem";
import { SearchResultViewModel } from "@/types/models/search/searchResult";
import { onClickOutside } from "@vueuse/core";
import { isEmpty } from "lodash";

export interface IMainSearchBar {
	searchCategoryOptions: SearchOptionViewModel[];
	searchPageUrl: string;
	localizationPrefix: string;
}

@Component({ components: { OverlayPanel, Dropdown, AutoComplete, Chip } })
export default class MainSearchBar extends BaseComponent {
	@Prop({ type: Object as PropType<IMainSearchBar>, required: true, default: {} }) vm!: IMainSearchBar;
	@Ref readonly searchBar!: HTMLElement;

	@Watch("layoutStore.showSearchBar") onSearchBarVisibleChanged(val: boolean): void {
		if (val) DomHandler.focus(document.getElementById("mainSearchBarInput") as HTMLElement);
		else this.axios.cancel("menuSearch");
	}

	relatedSearches: string[] = [];
	searchResult!: SearchResultViewModel;
	searchString = "";
	searchCategory = SearchCategory.all;

	created(): void {
		this.relatedSearches = ["Revit 2021", "Revit Training", "Revit Expert"];
	}

	mounted(): void {
		onClickOutside(this.searchBar, () => {
			this.layoutStore.showSearchBar = false;
		});
	}

	get searchResults(): SearchResultItemViewModel[] {
		return this.searchResult?.items || [];
	}

	get showDisplayAllResultsButton(): boolean {
		return (
			!this.hasNoResults && this.searchResults.length < this.searchResult.totalResults && !!this.searchString
		);
	}

	get hasNoResults(): boolean {
		return this.searchResult.totalResults < 1;
	}

	onSearch(event: AutoCompleteCompleteEvent): void {
		const defaultResult = {} as SearchResultViewModel;
		if (!this.layoutStore.showSearchBar || !event.query) {
			this.searchResult = defaultResult;
			return;
		}
		const queryParams = new URLSearchParams("");
		queryParams.append("query", event.query);
		queryParams.append("category", this.searchCategory.toString());
		queryParams.append("allowRedirect", "true");

		const url = new URL(`${window.location.origin}/api/${this.vm.localizationPrefix}/search/`);
		url.search = queryParams.toString();
		this.axios
			.get<SearchResultViewModel>(url.toString(), { requestId: "menuSearch" })
			.then((x) => {
				this.searchResult = x.data;
				// If there are no results, add an empty item to the itemlist as a hack to show the autocomplete result dropdown anyway.
				if (this.hasNoResults) {
					this.searchResult.items.push({} as any);
				}
			})
			.catch((err) => {
				Log.error(err);
				this.searchResult = defaultResult;
			});
	}

	// getRelatedSearches(): void {
	// 	this.axios.get(`/find_v2/_didyoumean?query=${encodeURIComponent(this.searchString)}&size=5`).then((res) => {
	// 		console.log(res);
	// 	});
	// }

	onSearchResultSelected(event: AutoCompleteItemSelectEvent): void {
		this.trackSearch(this.searchString);

		const item = event.value as SearchResultItemViewModel;
		if (!isEmpty(item)) this.openUrl(item.pageUrl);
	}

	onRelatedSearchTermSelected(event: InputEvent, option: string): void {
		this.searchString = option;
		this.onSearch({ originalEvent: event, query: option });
	}

	onShowAllResultsClick(): void {
		const queryParams = new URLSearchParams("");
		queryParams.append("query", this.searchString);
		queryParams.append("category", this.searchCategory.toString());
		queryParams.append("allowRedirect", "true");
		const url = new URL(`${window.location.origin}/${this.vm.searchPageUrl}`);
		url.search = queryParams.toString();
		this.openUrl(url.toString());
	}

	updateSearchstring(str: any): void {
		if (typeof str === "string") this.searchString = str;
	}
}
</script>

<style scoped lang="scss">
.main-search-bar {
	display: flex;
	flex-direction: row;
	flex: 1;

	.search-category-dropdown {
		display: flex;
		flex: 1;
		margin-left: 1rem;
	}

	.search-input {
		display: flex;
		flex: 3;
	}

	::v-deep(.p-autocomplete-item) {
		white-space: initial;
	}
}
</style>
