import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4647122e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("dl", _mergeProps({ class: "contract-summary" }, _ctx.$attrs), [
      _createElementVNode("div", null, [
        _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.contractSummary.lblContractNumber")), 1),
        _createElementVNode("dd", null, _toDisplayString(_ctx.vm.contractNumber), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.contractSummary.lblContractType")), 1),
        _createElementVNode("dd", null, _toDisplayString(_ctx.vm.contractType), 1)
      ]),
      (_ctx.vm.contractManager)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.contractSummary.lblContractManager")), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.vm.contractManager), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.contractSummary.lblEndDate")), 1),
        _createElementVNode("dd", null, [
          (_ctx.vm.contractProlongationDate)
            ? (_openBlock(), _createElementBlock("s", _hoisted_3, _toDisplayString(_ctx.formatDate(_ctx.vm.contractEndDate)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatDate(_ctx.vm.contractEndDate)), 1))
        ])
      ]),
      (_ctx.vm.contractProlongationDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.contractSummary.lblProlongationDate")), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.formatDate(_ctx.vm.contractProlongationDate)), 1)
          ]))
        : _createCommentVNode("", true)
    ], 16)
  ], 64))
}