<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:closable="true"
		:header="$t('expert.bookingModal.header')"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<p class="lead mb-2">
			{{ isOnRequest ? $t("expert.bookingModal.introRequest") : $t("expert.bookingModal.introBook") }}
		</p>
		<form onsubmit="return false;">
			<div v-if="isOnRequest">
				<div class="form-row">
					<div class="form-group col-12">
						<label>
							{{ $t("common.forms.lblFirstName") }}
						</label>
						<InputText
							v-model="v$['expertBookingForm'].firstname.$model"
							:class="{ 'p-invalid': shouldShowError(v$['expertBookingForm'].firstname) }"
							type="text"
						/>
						<small v-if="shouldShowError(v$['expertBookingForm'].firstname)" class="p-error">
							{{ v$["expertBookingForm"].firstname?.required?.$message }}
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-12">
						<label>
							{{ $t("common.forms.lblLastName") }}
						</label>
						<InputText
							v-model="v$['expertBookingForm'].lastname.$model"
							:class="{ 'p-invalid': shouldShowError(v$['expertBookingForm'].lastname) }"
							type="text"
						/>
						<small v-if="shouldShowError(v$['expertBookingForm'].lastname)" class="p-error">
							{{ v$["expertBookingForm"].lastname?.required?.$message }}
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-12">
						<label>
							{{ $t("common.forms.lblEmail") }}
						</label>
						<InputText
							v-model="v$['expertBookingForm'].email.$model"
							:class="{ 'p-invalid': shouldShowError(v$['expertBookingForm'].email) }"
							type="text"
						/>
						<small v-if="shouldShowError(v$['expertBookingForm'].email)" class="p-error">
							{{ v$["expertBookingForm"].email?.required?.$message }}
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-12">
						<label>
							{{ $t("common.forms.lblCompanyName") }}
						</label>
						<InputText
							v-model="v$['expertBookingForm'].company.$model"
							:class="{ 'p-invalid': shouldShowError(v$['expertBookingForm'].company) }"
							type="text"
						/>
						<small v-if="shouldShowError(v$['expertBookingForm'].company)" class="p-error">
							{{ v$["expertBookingForm"].company?.required?.$message }}
						</small>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-12">
					<label>
						{{ $t("common.forms.lblDescription") }}
					</label>
					<Textarea
						v-model="v$['expertBookingForm'].description.$model"
						:class="{ 'p-invalid': shouldShowError(v$['expertBookingForm'].description) }"
						rows="8"
						type="text"
					/>
					<span v-if="shouldShowError(v$['expertBookingForm'].description)">
						<small v-if="!v$['expertBookingForm'].description.$model" class="p-error">
							{{ v$["expertBookingForm"].description?.required?.$message }}
						</small>
						<small v-else-if="v$['expertBookingForm'].description.$model" class="p-error">
							{{ v$["expertBookingForm"].description.minLength?.$message }}
						</small>
					</span>
				</div>
			</div>
		</form>

		<p class="mb-0">
			<span class="text-bold mr-1">{{ $t("common.messages.titleImportant") }}</span>
			<small class="">
				{{ $t("expert.bookingModal.footer") }}
			</small>
		</p>

		<Message v-if="!isOnRequest" class="my-1" :closable="false" severity="warn">
			{{ $t("expert.message.flexWarning") }}
		</Message>

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button
				:disabled="!canSubmit"
				:label="isOnRequest ? $t('expert.button.sendRequest') : $t('expert.button.confirmBooking')"
				@click="onSubmit()"
			/>
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import InlineMessage from "primevue/inlinemessage";
import Textarea from "primevue/textarea";
import useVuelidate, { Validation } from "@vuelidate/core";
import { BookingRequestRequester } from "@/types/models/expert/bookingRequestRequester";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { ExpertBookingSummaryViewModel } from "@/types/models/expert/expertBookingSummaryViewModel";
import { PropType } from "vue";
import { email, minLength, required } from "@/types/utils/i18n-validators";
import { requiredIf } from "@vuelidate/validators";
import { useExpertStore } from "@/store/expert/expertStore";

const requiredValue = (_value: string, siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return !!vm.requester;
};

export interface ExpertBookingForm {
	firstname?: string;
	lastname?: string;
	email?: string;
	company?: string;
	description?: string;
}

export enum ExpertBookingModalType {
	Default,
	OnRequest,
}

@Component({
	components: { Textarea, InlineMessage },
	options: {
		validations: {
			expertBookingForm: {
				firstname: { required: requiredIf(requiredValue as any) },
				lastname: { required: requiredIf(requiredValue as any) },
				email: { required: requiredIf(requiredValue as any), email },
				company: { required: requiredIf(requiredValue as any) },
				description: { required, minLength: minLength(120) },
			},
		},
	},
})
export default class ExpertBookingModal extends BaseComponent {
	@Prop({
		type: Object as PropType<ExpertBookingSummaryViewModel>,
		required: false,
		default: null,
	})
	bookingSummary!: ExpertBookingSummaryViewModel;

	@Prop({
		type: Number as PropType<ExpertBookingModalType>,
		required: true,
		default: ExpertBookingModalType.Default,
	})
	modalType!: ExpertBookingModalType;

	@Emit("submit")
	submitForm(): { type: ExpertBookingModalType; data: ExpertBookingForm | null } {
		return { data: this.expertBookingForm, type: this.modalType };
	}

	v$ = useVuelidate();
	expertBookingForm: null | ExpertBookingForm = null;
	submitted = false;
	isVisible = true;
	expertStore = useExpertStore();

	created(): void {
		if (this.requester) {
			this.expertBookingForm = {
				firstname: this.requester.firstName,
				lastname: this.requester.lastName,
				email: this.requester.email,
				company: this.requester.company,
				description: this.bookingSummary?.configuration?.projectDescription,
			};
		} else {
			this.expertBookingForm = {
				description: "",
			};
		}
	}

	get requester(): BookingRequestRequester {
		return this.bookingSummary?.configuration?.requester;
	}

	get canSubmit(): boolean {
		return !this.v$["expertBookingForm"].$invalid;
	}

	get isOnRequest(): boolean {
		return this.modalType === ExpertBookingModalType.OnRequest;
	}

	onCancel(): void {
		this.expertStore.hideModal("BookingRequest");
	}

	onSubmit(): void {
		if (!this.canSubmit) return;
		this.submitForm();
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}
}
</script>

<style scoped lang="scss"></style>
