<template>
	<div>
		<DataTable
			v-if="vm.bookings?.length"
			auto-layout
			breakpoint="991px"
			class="mt-2"
			:paginator="showPagination"
			responsive-layout="scroll"
			:rows="maxRowsToShow"
			:value="vm?.bookings"
		>
			<Column key="status" :header="$t('myCadac.supportTicketOverview.table.colStatus')" style="">
				<template #body="item">
					<StatusBadge
						:status="item.data.status"
						translation-prefix="enum.expertBookingStatus"
						:type="1"
					/>
				</template>
			</Column>
			<Column v-if="!isExpert" key="expert" :header="$t('expert.booking.expert')">
				<template #body="item">
					<div class="d-flex align-items-center">
						<Avatar
							v-if="item.data.expertProfile?.imageUrl"
							class="mr-2 profile-image"
							:image="item.data.expertProfile?.imageUrl"
							shape="circle"
							size="lg"
						/>
						<div>
							<span class="text-primary">
								{{ item.data.expertProfile?.firstName }} {{ item.data.expertProfile?.lastName }}
							</span>
						</div>
					</div>
				</template>
			</Column>

			<Column
				v-if="isExpert"
				key="projectDescription"
				field="projectDescription"
				:header="$t('expert.booking.projectDescription')"
				style="width: 30%"
			>
				<template #body="item">
					<span class="text-preline project-description">{{ item.data.projectDescription }}</span>
				</template>
			</Column>

			<Column key="location" :header="$t('expert.booking.workLocation')" style="width: 20%">
				<template #body="item">
					<p v-if="isExpert" class="text-bold">{{ item.data.customerDisplayName }}</p>
					<span class="text-preline">{{
						item.data.isRemote ? $t("expert.remote") : item.data.workLocation
					}}</span>
				</template>
			</Column>

			<Column key="date" :header="$t('expert.booking.dates')">
				<template #body="item">
					<div
						v-for="date in sortedDates(item.data.dates)"
						:key="JSON.stringify(date)"
						class="d-flex flex-column"
					>
						<p class="text-bold mb-0">
							{{ `${formatDate(date.date)} ` }}
						</p>
						<small>{{ `${formatTime(date.start)} - ${formatTime(date.end)}` }} </small>
					</div>
				</template>
			</Column>

			<Column key="actions">
				<template #body="item">
					<div class="d-flex align-items-center justify-content-end">
						<div v-if="canAcceptOrRejectBooking(item.data)" class="d-flex">
							<Button
								class="p-button-success p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
								icon="pi pi-check"
								:label="isSmallScreen ? $t('common.accept') : ''"
								:title="$t('common.accept')"
								@click="onAcceptClick(item.data)"
							/>
							<Button
								class="p-button-danger p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
								icon="pi pi-times"
								:label="isSmallScreen ? $t('common.reject') : ''"
								:title="$t('common.reject')"
								@click="onRejectClick(item.data)"
							/>
							<Divider layout="vertical"></Divider>
						</div>

						<div v-if="canCompleteBooking(item.data)" class="d-flex">
							<Button
								class="p-button-success p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
								icon="pi pi-check"
								:label="$t('common.complete')"
								:title="$t('common.complete')"
								@click="onCompleteClick(item.data)"
							/>
							<Divider layout="vertical"></Divider>
						</div>

						<Button
							v-if="canSeeInvoiceForBooking(item.data)"
							class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
							icon="pi pi-file-pdf"
							:label="isSmallScreen ? $t('expert.button.viewInvoice') : ''"
							:title="$t('expert.button.viewInvoice')"
							@click="onViewInvoiceClick(item.data)"
						/>

						<Button
							class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0"
							icon="pi pi-eye"
							:label="isSmallScreen ? $t('common.view') : ''"
							:title="$t('common.view')"
							@click="onViewDetailClick(item.data)"
						/>
					</div>
				</template>
			</Column>
		</DataTable>
	</div>
	<ExpertDeclineBookingModal v-if="expertStore.isCancelOrDeclineBookingModalVisible" @submit="declineBooking" />
	<ExpertAcceptBookingModal v-if="expertStore.isAcceptBookingModalVisible" @submit="acceptBooking" />
	<ExpertCompleteBookingModal v-if="expertStore.isCompleteBookingModalVisible" @submit="completeBooking" />
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Divider from "primevue/divider";
import ExpertAcceptBookingModal from "@/components/experts/expertAcceptBookingModal.vue";
import ExpertCompleteBookingModal from "@/components/experts/expertCompleteBookingModal.vue";
import ExpertDeclineBookingModal from "@/components/experts/expertDeclineBookingModal.vue";
import { AcceptBookingRequest } from "@/types/models/expert/acceptBookingRequest";
import { CompleteBookingRequest } from "@/types/models/expert/completeBookingRequest";
import { CadacExpertType } from "@/types/enum/cadacExpertType";
import { Component, Prop } from "vue-facing-decorator";
import { DeclineBookingRequest } from "@/types/models/expert/declineBookingRequest";
import { ExpertBookingStatus } from "@/types/enum/expertBookingStatus";
import { ExpertBookingViewModel } from "@/types/generated/Custom/expertBookingViewModel";
import { IExpertBookingOverview } from "@/types/viewModels/myCadac/expertBookingOverviewViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { orderBy } from "lodash";
import { useExpertStore } from "@/store/expert/expertStore";

@Component({
	components: { ExpertAcceptBookingModal, ExpertCompleteBookingModal, ExpertDeclineBookingModal, Divider },
})
export default class ExpertBookingOverviewTable extends BaseComponent {
	@Prop({ type: Object as PropType<IExpertBookingOverview>, required: true, default: {} })
	vm!: IExpertBookingOverview;

	@Prop({ type: Boolean, required: false, default: false }) isExpert!: boolean;

	maxRowsToShow = 8;
	selectedBookingId = "";
	expertStore = useExpertStore();

	get showPagination(): boolean {
		return this.vm.bookings?.length > this.maxRowsToShow;
	}

	get canAcceptOrRejectBooking() {
		return (booking: ExpertBookingViewModel) =>
			this.isExpert && booking.status === ExpertBookingStatus.Requested;
	}

	get canCompleteBooking() {
		return (booking: ExpertBookingViewModel) =>
			this.isExpert && booking.status === ExpertBookingStatus.InProgress;
	}

	get canSeeInvoiceForBooking() {
		return (booking: ExpertBookingViewModel) =>
			this.isExpert &&
			(booking.status === ExpertBookingStatus.Completed ||
				booking.status === ExpertBookingStatus.Rejected) &&
			booking.expertType === CadacExpertType.External;
	}

	sortedDates(dates) {
		return orderBy(
			dates,
			function (o) {
				return new Date(o.date);
			},
			["desc"]
		);
	}

	onRejectClick(booking: ExpertBookingViewModel): void {
		this.selectedBookingId = booking.id;
		this.expertStore.showModal("CancelOrDeclineBooking");
	}

	onAcceptClick(booking: ExpertBookingViewModel): void {
		this.selectedBookingId = booking.id;
		this.expertStore.showModal("AcceptBooking");
	}

	onCompleteClick(booking: ExpertBookingViewModel): void {
		this.selectedBookingId = booking.id;
		this.expertStore.showModal("CompleteBooking");
	}

	onViewDetailClick(booking: ExpertBookingViewModel): void {
		const queryParams = new URLSearchParams("");
		queryParams.append("id", booking.id);
		const url = new URL(this.vm.detailPageUrl);
		url.search = queryParams.toString();
		this.openUrl(url.toString());
	}

	onViewInvoiceClick(booking: ExpertBookingViewModel): void {
		const queryParams = new URLSearchParams("");
		queryParams.append("bookingId", booking.id);
		const url = new URL(`${window.location.href}/GetPdf`);
		url.search = queryParams.toString();
		this.openUrl(url.toString());
	}

	declineBooking({ message }): void {
		const payload: DeclineBookingRequest = {
			bookingId: this.selectedBookingId,
			message: message,
		};
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/request-decline`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.rejectBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("CancelOrDeclineBooking");
			});
	}

	acceptBooking(): void {
		const payload: AcceptBookingRequest = {
			bookingId: this.selectedBookingId,
		};

		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/accept`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.acceptBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("AcceptBooking");
			});
	}

	completeBooking(): void {
		const payload: CompleteBookingRequest = {
			bookingId: this.selectedBookingId,
		};

		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/expertbooking/complete`, payload)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("expert.toast.completeBookingError"),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.expertStore.hideModal("CompleteBooking");
			});
	}
}
</script>

<style scoped lang="scss">
:deep(.project-description) {
	display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
	overflow: hidden !important;
	word-break: break-word;
}

:deep(.p-datatable-tbody) {
	tr td {
		vertical-align: top;
		word-break: break-all !important;
	}
}
</style>
