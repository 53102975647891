<template>
	<BackButton :label="$t('myCadac.contractDetail.button.back')" />

	<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
	<p class="text-muted lead">
		{{
			$t("myCadac.contractDetail.contractNumber", {
				contractNumber: vm.contract.contractId,
			})
		}}
	</p>

	<hr class="mt-1" />

	<ContractDetailTable :contracts="[vm.contract]" :vm="vm" />

	<h3 v-if="vm.yourProlongOptionsText">{{ vm.yourProlongOptionsText }}</h3>
	<p v-if="canCoTerm" v-html="vm.switchCoTermText" />
	<p v-if="canTradeIn" v-html="vm.switchTradeInText" />

	<Message v-if="vm.contract.renewMultiYearMaintenanceContractForOneYear" :closable="false" severity="warn">
		{{ $t("myCadac.contractDetail.message.prolongOnly12months") }}
	</Message>

	<!-- Prolong/Switch Card -->
	<ContractDetailSwitchCard v-if="!contractStore.isExtendingContract" :vm="vm" />
	<!--	Card for Extend/Co-term-->
	<ContractDetailExtendCard v-else :prolongation-options="contractStore.prolongationOptions" :vm="vm" />

	<!--	Co-term / Extend dialog -->
	<ContractExtendModal
		v-if="contractStore.isExtendModalVisible"
		:prolongation-options="contractStore.prolongationOptions"
		:vm="vm"
		@cancel-extend="onExtendCancelled"
		@confirm-extend="onExtendConfirmed"
	/>
	<!--	Trade-in dialog-->
	<ContractSwitchModal
		v-if="contractStore.isTradeInModalVisible"
		:contract-line="contractStore.selectedTradeInContractLine"
		:vm="vm"
		@cancel-trade-in="onTradeInCancelled"
		@confirm-trade-in="onTradeInConfirmed"
	/>
</template>

<script lang="ts">
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import ContractDetailExtendCard from "@/components/contracts/contractDetailExtendCard.vue";
import ContractDetailSwitchCard from "@/components/contracts/contractDetailSwitchCard.vue";
import ContractDetailTable from "@/components/contracts/contractDetailTable.vue";
import ContractExtendModal from "@/components/contracts/contractExtendModal.vue";
import ContractSwitchModal from "@/components/contracts/contractSwitchModal.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ContractProlongationOption } from "@/types/generated/Api/contractProlongationOption";
import { ContractProlongationOptionType } from "@/types/enum/contractProlongationOptionType";
import { IContractExtend } from "@/types/viewModels/myCadac/contractExtendViewModel";
import { ProductVariationClient } from "@/types/generated/Api/productVariationClient";
import { PropType } from "vue";
import { SwitchProductClient } from "@/types/generated/Api/switchProductClient";
import { useContractStore } from "@/store/software/contractStore";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";

@Component({
	components: {
		BackButton,
		ContractDetailSwitchCard,
		ContractSwitchModal,
		ContractDetailExtendCard,
		ContractExtendModal,
		ContractDetailTable,
		MyCadacMenu,
	},
})
export default class ContractExtend extends BaseComponent {
	@Prop({ type: Object as PropType<IContractExtend>, required: true, default: {} })
	vm!: IContractExtend;

	contractStore = useContractStore();

	get canCoTerm(): boolean {
		return this.contractStore.prolongationOptions.some(
			(x) => x.type === ContractProlongationOptionType.coterm
		);
	}

	get canTradeIn(): boolean {
		return this.vm.contract.contractLines.some((x) => x.switchProducts.length > 0);
	}

	created(): void {
		this.contractStore.fetchProlongationOptions(this.vm.contract);
	}

	onExtendCancelled(): void {
		this.contractStore.cancelExtend();
	}

	onExtendConfirmed(selectedOption: ContractProlongationOption): void {
		if (!selectedOption) return;
		this.contractStore.confirmExtend(selectedOption);
	}

	onTradeInCancelled(): void {
		this.contractStore.cancelTradeIn();
	}

	onTradeInConfirmed(switchProduct: SwitchProductClient): void {
		if (!this.contractStore.selectedTradeInContractLine) return;
		const selectedVariation: ProductVariationClient = switchProduct.switchVariations[0];
		const totalBasePrice = switchProduct.renewSeats * selectedVariation.basePrice;
		const totalPrice = switchProduct.renewSeats * selectedVariation.customerPrice;
		const totalDiscount = totalBasePrice - totalPrice;
		const newContractLine = {
			...this.contractStore.selectedTradeInContractLine,
			selectedSwitchSeats: switchProduct.renewSeats,
			selectedSwitchSku: selectedVariation?.sku,
			selectedSwitchDuration: selectedVariation?.duration,
			switchProducts: [switchProduct],
			priceBase: totalBasePrice,
			price: totalPrice,
			discount: totalDiscount,
			deployment: selectedVariation.licenseAudience,
		};
		this.contractStore.confirmTradeIn(newContractLine);
	}
}
</script>

<style scoped lang="scss"></style>
