<template>
	<div class="d-flex py-2 flex-shrink-0 surface-a">
		<Skeleton height="6rem" width="6rem" />

		<div class="d-flex flex-column ml-1 w-75">
			<div class="">
				<Skeleton class="mb-1" height="1.4rem" width="20%" />
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton class="mb-1" height=".75rem" width="90%" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Skeleton from "primevue/skeleton";
import { Component } from "vue-facing-decorator";

@Component({ components: { Skeleton } })
export default class SearchPageResultItemSkeleton extends BaseComponent {}
</script>

<style scoped lang="scss"></style>
