<template>
	<form @change="formStateChanged">
		<div class="form-row">
			<div class="form-group col-md-12 col-lg-10 col-xxl-8">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['street'].required"
					:label="$t('common.forms.lblAddress')"
					name="street"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['postalCode'].required"
					:label="$t('common.forms.lblPostalCode')"
					name="postalCode"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['city'].required"
					:label="$t('common.forms.lblCity')"
					name="city"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12 col-lg-10 col-xxl-8">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['countryCode'].required"
					:label="$t('common.forms.lblCountry')"
					name="countryCode"
				>
					<Dropdown
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:disabled="countries?.length === 1"
						option-label="value"
						option-value="key"
						:options="countries"
						@change="
							formStateChanged;
							field.setTouched(true);
						"
					/>
				</InputWithValidation>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import InputWithValidation from "@/components/common/inputWithValidation.vue";
import { AddressForm as AddressFormModel } from "@/types/models/form/addressForm";
import { Component, Emit, Model, Prop } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { FormContext, FormMeta, useForm } from "vee-validate";
import { PropType, unref } from "vue";
import { cloneDeep } from "lodash";

@Component({
	components: { InputWithValidation },
	emits: ["update:modelValue"],
})
export default class AddressForm extends BaseComponent implements FormComponent<AddressFormModel> {
	@Model({
		type: Object as PropType<AddressFormModel>,
		required: true,
		default: {},
	})
	model!: AddressFormModel;

	@Prop({
		type: Object as PropType<{ [key: string]: string }>,
		required: true,
		default: {},
	})
	countries!: { [key: string]: string }[];

	@Emit() formStateChanged(): FormMeta<AddressFormModel> {
		const { meta, values } = this.form;
		this.model = cloneDeep(values);
		return unref(meta);
	}

	form!: FormContext<AddressFormModel>;

	created(): void {
		if (!this.model.countryCode && this.countries.length === 1) {
			this.model.countryCode = this.countries[0].key;
		}
		this.form = useForm({ initialValues: this.model, validationSchema: this.validations });
	}

	get validations(): {
		street: { required: boolean };
		postalCode: {
			required: boolean;
			validPostalCode: string;
		};
		city: { required: boolean };
		countryCode: { required: boolean };
	} {
		return {
			street: { required: true },
			postalCode: {
				required: true,
				validPostalCode: "@countryCode",
			},
			city: { required: true },
			countryCode: { required: this.countries?.length > 1 },
		};
	}

	get isValid(): boolean {
		return unref(this.form.meta)?.valid;
	}

	get isDirty(): boolean {
		return unref(this.form.meta)?.dirty;
	}
}
</script>

<style scoped lang="scss"></style>
