import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c16f1e52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center flex-wrap p-1" }
const _hoisted_2 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Calendar, _mergeProps(_ctx.bookingDateOptions, {
      class: "w-100",
      "disabled-dates": _ctx.disabledDates,
      locale: _ctx.currentRegion,
      "max-date": new Date(_ctx.calendarMaxDate),
      "model-value": _ctx.activeDate,
      onDateSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDateSelected($event)))
    }), {
      date: _withCtx((slotProps) => [
        _createElementVNode("strong", {
          class: _normalizeClass(["custom-date", {
						selected: _ctx.isSelectedDate(_ctx.convertDateObjectToDate(slotProps.date)),
						available:
							_ctx.getAvailabilityType(_ctx.convertDateObjectToDate(slotProps.date)) ===
							_ctx.expertDateAvailability.available,
						'on-request':
							_ctx.getAvailabilityType(_ctx.convertDateObjectToDate(slotProps.date)) ===
							_ctx.expertDateAvailability.onRequest,
					}])
        }, _toDisplayString(slotProps.date.day), 3)
      ]),
      _: 1
    }, 16, ["disabled-dates", "locale", "max-date", "model-value"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legendOptions, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.label,
          class: "d-flex align-items-center flex-shrink-0 mr-2"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["legend-box", option.color])
          }, null, 2),
          _createElementVNode("small", _hoisted_2, _toDisplayString(`${_ctx.$t(option.label)}`), 1)
        ]))
      }), 128))
    ])
  ]))
}