import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-12" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = { class: "form-group col-md-12" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = {
  key: 1,
  class: "p-error"
}
const _hoisted_8 = {
  key: 0,
  class: "form-row"
}
const _hoisted_9 = { class: "form-group col-md-12" }
const _hoisted_10 = { class: "d-flex align-items-start flex-wrap" }
const _hoisted_11 = ["for"]
const _hoisted_12 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['serverLicenceForm'].serverName?.required })
        }, _toDisplayString(_ctx.$t("myCadac.licenceManagementDetail.dialog.lblName")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['serverLicenceForm'].serverName.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['serverLicenceForm'].serverName.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['serverLicenceForm'].serverName) }),
          type: "text"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.shouldShowError(_ctx.v$['serverLicenceForm'].serverName))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$["serverLicenceForm"].serverName?.required?.$message), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['serverLicenceForm'].email?.required })
        }, _toDisplayString(_ctx.$t("myCadac.licenceManagementDetail.dialog.lblEmail")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['serverLicenceForm'].email.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['serverLicenceForm'].email.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['serverLicenceForm'].email) }),
          disabled: _ctx.isEditing,
          type: "email"
        }, null, 8, ["modelValue", "class", "disabled"]),
        (_ctx.shouldShowError(_ctx.v$['serverLicenceForm'].email) && !_ctx.v$['serverLicenceForm'].email.$model)
          ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$["serverLicenceForm"].email?.required?.$message), 1))
          : (_ctx.shouldShowError(_ctx.v$['serverLicenceForm'].email) && _ctx.v$['serverLicenceForm'].email.$model)
            ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$["serverLicenceForm"].email.email?.$message), 1))
            : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.selectedLicenceProduct.possibleRoles?.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['serverLicenceForm'].roles?.required })
            }, _toDisplayString(_ctx.$t("myCadac.licenceManagementDetail.dialog.lblRoles")), 3),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedLicenceProduct.possibleRoles, (role, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "p-field-checkbox mr-2 mb-2"
                }, [
                  _createVNode(_component_Checkbox, {
                    modelValue: _ctx.v$['serverLicenceForm'].roles.$model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['serverLicenceForm'].roles.$model) = $event)),
                    id: `role${index}`,
                    name: "roles",
                    value: role
                  }, null, 8, ["modelValue", "id", "value"]),
                  _createElementVNode("label", {
                    class: "ml-1 mb-0",
                    for: `role${index}`
                  }, _toDisplayString(_ctx.$i18n.locale === "nl" ? role.descriptionNL : role.descriptionEN), 9, _hoisted_11)
                ]))
              }), 128))
            ]),
            (_ctx.shouldShowError(_ctx.v$['serverLicenceForm'].roles))
              ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.v$["serverLicenceForm"].roles?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}