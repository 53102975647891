<template>	
	<div v-if="isSmallScreen && isProductAvailable && isAutodeskProduct">
		<ProgressSpinner v-if="isSpinnerVisible('priceSummary')"
						 animation-duration=".75s"
						 class="d-block ml-auto mr-0"
						 stroke-width="3" />
		<slot v-else-if="$slots.priceSummary" name="priceSummary" />
	</div>
	<Panel v-else-if="isSmallScreen && isProductAvailable" :collapsed="isMobilePriceSummaryCollapsed">
		<template #header>
			<div class="d-flex justify-content-between align-items-center w-100"
				 @click="isMobilePriceSummaryCollapsed = !isMobilePriceSummaryCollapsed">
				<ProgressSpinner v-if="isSpinnerVisible('priceSummary')"
								 animation-duration=".75s"
								 class="m-0"
								 stroke-width="3"
								 style="width: 2.25rem; height: 2.25rem" />
				<h4 v-show="!isSpinnerVisible('priceSummary')" class="text-primary mb-0 h5">
					{{ formatCurrency(totalPrice) }}
				</h4>
				<span v-if="!isAutodeskProduct" class="expander">----</span>
				<div class="pt-1">
					<div class="d-flex align-items-center justify-content-end" @click.stop>
						<InputNumber v-if="showAmountInput"
									 v-model="selectedAmount"
									 class="mx-1"
									 decrement-button-class="p-button-outlined"
									 increment-button-class="p-button-outlined"
									 :max="maxAmount"
									 :min="minAmount"
									 show-buttons
									 style="width: 90px"
									 :use-grouping="false"
									 @input="amountChanged" />						
						<Button v-if="!isAutodeskProduct"
								class="p-button-success p-button-raised p-button-lg ml-1"
								:disabled="!canSubmit"
								icon="pi pi-shopping-cart"
								icon-pos="right"
								@click="addToCart()" />
					</div>										
					<div v-if="offerRequestFormUrl && !isAutodeskProduct && canSubmit" class="text-right mb-1 mt-1">
						<a class="quote-btn-url" :href="requestOfferForThisProductVariation(true)" target="_blank">
							{{ $t("product.button.quote") }}
						</a>
					</div>
				</div>
			</div>			
		</template>
		<template #default>			
			<ScrollPanel v-if="!isAutodeskProduct" style="width: 100%; height: clamp(200px, 30vh, 300px)">
				<ProgressSpinner v-if="isSpinnerVisible('priceSummary')"
								 animation-duration=".75s"
								 class="d-block ml-auto mr-0"
								 stroke-width="3" />
				<slot v-else-if="$slots.priceSummary" name="priceSummary" />
			</ScrollPanel>
		</template>
	</Panel>
	<Card v-else-if="isProductAvailable" class="summary-sticky-top shadow-none">
		<template #title>
			<slot v-if="$slots.configurationSummary" name="configurationSummary" />
		</template>
		<template #content>
			<ProgressSpinner v-if="isSpinnerVisible('priceSummary')"
							 animation-duration=".75s"
							 class="d-block ml-auto mr-0"
							 stroke-width="3" />
			<div v-else>
				<slot v-if="$slots.priceSummary" name="priceSummary" />
			</div>
		</template>
		<template #footer>
			<slot v-if="$slots.validationMessage" name="validationMessage" />
			<div class="amount-input d-flex align-items-center">
				<!-- amount selector -->
				<InputNumber v-if="showAmountInput && !isAutodeskProduct"
							 v-model="selectedAmount"
							 class="mr-2"
							 decrement-button-class="p-button-outlined"
							 increment-button-class="p-button-outlined"
							 :max="maxAmount"
							 :min="minAmount"
							 show-buttons
							 style="width: clamp(125px, 25%, 175px); height: 59px; margin-top: -8px"
							 :use-grouping="false"
							 @input="amountChanged" />
				<!-- add to cart button -->
				<Button v-if="!isAutodeskProduct"
						class="p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1"
						:disabled="!canSubmit"
						:label="addToCartButtonLabel()"
						@click="addToCart()" />
				<!-- request autodesk quote button -->
				<Button v-if="isAutodeskProduct"
						class="p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 quote-btn"
						:disabled="!canSubmit"
						:label="$t(`product.button.quote`)"
						@click="requestAutodeskQuote()" />				
			</div>
			<div v-if="offerRequestFormUrl" class="text-right">
				<!-- request offer for non-autodesk products -->
				<Button v-if="!isAutodeskProduct"
						class="p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1 quote-btn"
						:disabled="!canSubmit"
						:label="$t(`product.button.quote`)"
						@click="requestOfferForThisProductVariation()" />
			</div>
			<slot v-if="$slots.walletMessage" name="walletMessage" />
		</template>
	</Card>
	<div v-else class="summary-sticky-top mt-2">
		<slot />
		<SupportCard v-if="showSupportCard" />
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop, Watch } from "vue-facing-decorator";
import SupportCard from "@/components/commerce/supportCard.vue";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";

	@Component({ components: { SupportCard, ScrollPanel, ProgressSpinner } })
	export default class ProductPageSummary extends BaseComponent {
		@Prop({ type: Number, required: false, default: 0 }) totalPrice!: number;
		@Prop({ type: Boolean, required: true, default: false }) isProductAvailable!: boolean;
		@Prop({ type: Boolean, required: false, default: false }) canSubmit!: boolean;
		@Prop({ type: Boolean, required: false, default: false }) showAmountInput!: boolean;
		@Prop({ type: Number, required: false, default: 1 }) minAmount!: number;
		@Prop({ type: Number, required: false, default: 999 }) maxAmount!: number;
		@Prop({ type: Number, required: false, default: 1 }) value!: number;
        @Prop({ type: Boolean, required: false, default: true }) showSupportCard!: number;
		@Prop({ type: Number, required: false }) variationId!: number;
		@Prop({ type: String, required: false }) variationGuid!: string;
		@Prop({ type: String, required: false }) offerRequestFormUrl!: string;
        @Prop({ type: Boolean, required: false, default: false }) isInEditMode!: boolean;
        @Prop({ type: Boolean, required: false, default: false }) isAutodeskProduct!: boolean;

	selectedAmount!: number;
	isMobilePriceSummaryCollapsed = true;

	@Emit() addToCart(): void {
		return;
		}

	@Emit() requestAutodeskQuote(): void {
		return;
	}

	@Emit() amountChanged({ value }: { value: number }): number {
		this.selectedAmount =
			value > this.maxAmount ? this.maxAmount : value < this.minAmount ? this.minAmount : value;
		return this.selectedAmount;
	}

	created(): void {
		this.selectedAmount = this.value ?? this.minAmount;
	}

	requestOfferForThisProductVariation(urlResponse = false) {
		var url = `${window.location.origin}${this.offerRequestFormUrl}`;
		if (urlResponse) {
			return url;
		} else {
			window.open(url, "_blank");
			return;
		}
	}

	addToCartButtonLabel(): string {
		if (this.isInEditMode) {
			return this.$t(`common.configureAndOrder`);
		}
		return this.$t(`common.addToCart`);
	}

	@Watch("variationId") onVariationChanged(): void {
		this.selectedAmount = this.minAmount;
	}
}
</script>

<style scoped lang="scss">
.quote-btn {
	&:hover::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='17'%3E%3Cpath d='M7.431 2.482L9.9 4.95 8.531 6.318 4.956 2.743 3.172 4.527c-.981.982-1.79 1.785-1.797 1.784-.007 0-.32-.306-.694-.68L0 4.95l2.469-2.468A330.393 330.393 0 0 1 4.95.014c.007 0 1.124 1.11 2.481 2.468z' fill='white' fill-rule='evenodd'/%3E%3C/svg%3E");
	}

	&:after {
		/*margin-left: 0px;*/
		background-color: var(--primary-color-dark);
		border-color: var(--primary-color-dark);
		color: #ffffff;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='17'%3E%3Cpath d='M7.431 2.482L9.9 4.95 8.531 6.318 4.956 2.743 3.172 4.527c-.981.982-1.79 1.785-1.797 1.784-.007 0-.32-.306-.694-.68L0 4.95l2.469-2.468A330.393 330.393 0 0 1 4.95.014c.007 0 1.124 1.11 2.481 2.468z' fill='white' fill-rule='evenodd'/%3E%3C/svg%3E");
	}
}

.quote-btn-url {
	font-weight: 500;
	color: #91004b !important;
	padding: 0 0 0.25rem 0;
	border-bottom: 2px solid var(--surface-d);
	border-radius: 0;
	text-decoration: none;
	&:hover {
		border-color: var(--primary-color);
	}
	font-size: 1rem;
}

.summary-sticky-top {
	position: sticky;
	top: calc(var(--navbarHeightInPx) + 1rem);
	margin-bottom: 1rem;

	::v-deep(.p-card-footer) {
		padding-top: 0;
	}
}

.expander {
	color: var(--text-color-secondary);
	font-size: 2rem;
	font-weight: 600;
	cursor: pointer;
	position: absolute;
	top: -1.2rem;
	left: 50%;
	letter-spacing: -5px;
}
</style>
