<template>
	<div class="container-fluid container-lg pb-5">
		<div class="row">
			<div class="col-10 col-xxl-8 mx-auto px-0">
				<slot v-if="$slots.header" name="header" />
				<div class="product-configuration-card p-1 p-lg-2 p-xl-3">
					<slot v-if="$slots.configuration" name="configuration" />
					<!-- Default content goes here-->
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";

@Component({})
export default class ProductLayoutSimple extends BaseComponent {}
</script>

<style scoped lang="scss">
.product-configuration-card {
	background: var(--surface-b);
	border-radius: var(--border-radius);
	box-shadow:
		0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
}
</style>
