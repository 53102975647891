<template>
	<div class="product-banner-bg">
		<div class="product-banner-text">
			<h3 class="mb-0">{{ $t("product.newVersionTitle") }}</h3>
			<p class="lead mb-0">{{ $t("product.newVersionSubtitle") }}</p>
		</div>
		<div>
			<BaseProductOverviewItemCard :vm="vm" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";

@Component({
	components: { BaseProductOverviewItemCard },
})
export default class ProductNewVersionBanner extends BaseComponent {
	@Prop({
		type: Object as PropType<BaseProductItemViewModel>,
		required: true,
		default: {},
	})
	vm!: BaseProductItemViewModel;
}
</script>

<style scoped lang="scss">
.product-banner-bg {
	background: var(--primary-color);
	position: relative;
	padding: 2rem 2rem 2rem 1rem;
	display: flex;
	flex-wrap: wrap;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 100%;
		width: 200%;
		height: 100%;
		background: inherit;
	}
}

.product-banner-text {
	color: var(--primary-color-text);
	display: flex;
	flex: 1;
	flex-direction: column;
}
</style>
