import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lead mb-2" }
const _hoisted_2 = { onsubmit: "return false;" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = { class: "form-group col-12" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "form-group col-12" }
const _hoisted_9 = {
  key: 0,
  class: "p-error"
}
const _hoisted_10 = { class: "form-row" }
const _hoisted_11 = { class: "form-group col-12" }
const _hoisted_12 = {
  key: 0,
  class: "p-error"
}
const _hoisted_13 = { class: "form-row" }
const _hoisted_14 = { class: "form-group col-12" }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}
const _hoisted_16 = { class: "form-row" }
const _hoisted_17 = { class: "form-group col-12" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 0,
  class: "p-error"
}
const _hoisted_20 = {
  key: 1,
  class: "p-error"
}
const _hoisted_21 = { class: "mb-0" }
const _hoisted_22 = { class: "text-bold mr-1" }
const _hoisted_23 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    closable: true,
    header: _ctx.$t('expert.bookingModal.header'),
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onCancel()))
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.isOnRequest ? _ctx.$t('expert.button.sendRequest') : _ctx.$t('expert.button.confirmBooking'),
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.isOnRequest ? _ctx.$t("expert.bookingModal.introRequest") : _ctx.$t("expert.bookingModal.introBook")), 1),
      _createElementVNode("form", _hoisted_2, [
        (_ctx.isOnRequest)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("common.forms.lblFirstName")), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$['expertBookingForm'].firstname.$model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['expertBookingForm'].firstname.$model) = $event)),
                    class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertBookingForm'].firstname) }),
                    type: "text"
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.shouldShowError(_ctx.v$['expertBookingForm'].firstname))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$["expertBookingForm"].firstname?.required?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("common.forms.lblLastName")), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$['expertBookingForm'].lastname.$model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['expertBookingForm'].lastname.$model) = $event)),
                    class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertBookingForm'].lastname) }),
                    type: "text"
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.shouldShowError(_ctx.v$['expertBookingForm'].lastname))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.v$["expertBookingForm"].lastname?.required?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("common.forms.lblEmail")), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$['expertBookingForm'].email.$model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['expertBookingForm'].email.$model) = $event)),
                    class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertBookingForm'].email) }),
                    type: "text"
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.shouldShowError(_ctx.v$['expertBookingForm'].email))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.v$["expertBookingForm"].email?.required?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("common.forms.lblCompanyName")), 1),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.v$['expertBookingForm'].company.$model,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$['expertBookingForm'].company.$model) = $event)),
                    class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertBookingForm'].company) }),
                    type: "text"
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.shouldShowError(_ctx.v$['expertBookingForm'].company))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.v$["expertBookingForm"].company?.required?.$message), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("common.forms.lblDescription")), 1),
            _createVNode(_component_Textarea, {
              modelValue: _ctx.v$['expertBookingForm'].description.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$['expertBookingForm'].description.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertBookingForm'].description) }),
              rows: "8",
              type: "text"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.shouldShowError(_ctx.v$['expertBookingForm'].description))
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                  (!_ctx.v$['expertBookingForm'].description.$model)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_19, _toDisplayString(_ctx.v$["expertBookingForm"].description?.required?.$message), 1))
                    : (_ctx.v$['expertBookingForm'].description.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.v$["expertBookingForm"].description.minLength?.$message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("p", _hoisted_21, [
        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("common.messages.titleImportant")), 1),
        _createElementVNode("small", _hoisted_23, _toDisplayString(_ctx.$t("expert.bookingModal.footer")), 1)
      ]),
      (!_ctx.isOnRequest)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            class: "my-1",
            closable: false,
            severity: "warn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("expert.message.flexWarning")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}