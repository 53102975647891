<template>
	<form>
		<div class="form-row">
			<div class="form-group col-md-6">
				<label :class="{ required: v$['trainingParticipantForm'].firstName?.required }">
					{{ $t("common.forms.lblFirstName") }}
				</label>
				<InputText
					v-model="v$['trainingParticipantForm'].firstName.$model"
					:class="{ 'p-invalid': shouldShowError(v$['trainingParticipantForm'].firstName) }"
					type="text"
				/>
				<small v-if="shouldShowError(v$['trainingParticipantForm'].firstName)" class="p-error">
					{{ v$["trainingParticipantForm"].firstName?.required?.$message }}
				</small>
			</div>
			<div class="form-group col-md-6">
				<label :class="{ required: v$['trainingParticipantForm'].lastName?.required }">
					{{ $t("common.forms.lblLastName") }}
				</label>
				<InputText
					v-model="v$['trainingParticipantForm'].lastName.$model"
					:class="{ 'p-invalid': shouldShowError(v$['trainingParticipantForm'].lastName) }"
					type="text"
				/>
				<small v-if="shouldShowError(v$['trainingParticipantForm'].lastName)" class="p-error">
					{{ v$["trainingParticipantForm"].lastName?.required?.$message }}
				</small>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-12">
				<label :class="{ required: v$['trainingParticipantForm'].email?.required }">
					{{ $t("myCadac.licenceManagementDetail.dialog.lblEmail") }}
				</label>
				<InputText
					v-model="v$['trainingParticipantForm'].email.$model"
					:class="{ 'p-invalid': shouldShowError(v$['trainingParticipantForm'].email) }"
					type="email"
				/>
				<small
					v-if="
						shouldShowError(v$['trainingParticipantForm'].email) &&
						!v$['trainingParticipantForm'].email.$model
					"
					class="p-error"
				>
					{{ v$["trainingParticipantForm"].email?.required?.$message }}
				</small>
				<small
					v-else-if="
						shouldShowError(v$['trainingParticipantForm'].email) &&
						v$['trainingParticipantForm'].email.$model
					"
					class="p-error"
				>
					{{ v$["trainingParticipantForm"].email.email?.$message }}
				</small>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import useVuelidate, { Validation } from "@vuelidate/core";
import { Component, Prop, VModel } from "vue-facing-decorator";
import { PropType } from "vue";
import { TrainingParticipantForm as TrainingParticipantFormModel } from "@/types/models/form/trainingParticipantForm";
import { email, minLength } from "@/types/utils/i18n-validators";
import { maxLength } from "@vuelidate/validators";
import { throttledWatch } from "@vueuse/core";

@Component({
	options: {
		validations: {
			trainingParticipantForm: {
				firstName: { minLength: minLength(2), maxLength: maxLength(55) },
				lastName: { minLength: minLength(2), maxLength: maxLength(75) },
				email: { email },
			},
		},
	},
})
export default class TrainingParticipantForm extends BaseComponent {
	@VModel({
		type: Object as PropType<TrainingParticipantFormModel>,
		required: false,
	})
	model!: TrainingParticipantFormModel;

	@Prop({
		type: Object as PropType<TrainingParticipantFormModel>,
		required: false,
		default: {},
	})
	item!: TrainingParticipantFormModel;

	@Prop({
		type: Number,
		required: false,
		default: 0,
	})
	index!: number;
	@Prop({ type: Boolean, required: true, default: false }) submitted!: boolean;

	v$ = useVuelidate();

	trainingParticipantForm: TrainingParticipantFormModel | null = null;

	get isAnyDirty(): boolean {
		return this.v$["trainingParticipantForm"]?.$anyDirty;
	}

	get isValid(): boolean {
		return !this.v$["trainingParticipantForm"]?.$invalid;
	}

	created(): void {
		this.trainingParticipantForm = this.model || this.item;

		// Watch for changes on the participant form and update isUnknown property, updates only every 750 ms
		throttledWatch(this.trainingParticipantForm, (val) => this.checkIfParticipantIsUnknown(val), {
			throttle: 750,
		});
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	private checkIfParticipantIsUnknown(trainingParticipantForm: TrainingParticipantFormModel): void {
		trainingParticipantForm.isUnknown =
			!(
				!!trainingParticipantForm.firstName &&
				!!trainingParticipantForm.lastName &&
				!!trainingParticipantForm.email
			) || !this.isValid;
	}
}
</script>

<style scoped lang="scss"></style>
