<template>
	<h3 class="mt-3 mt-lg-4 mb-0">{{ $t("myCadac.accountSettings.company.relatedContacts") }}</h3>
	<hr class="my-2" />

	<DataTable
		v-if="items.length"
		auto-layout
		:paginator="showPagination"
		removable-sort
		responsive-layout="stack"
		:rows="maxRowsToShow"
		:value="items"
	>
		<Column
			key="firstName"
			field="firstName"
			:header="$t('myCadac.accountSettings.company.table.lblFirstName')"
			:sortable="true"
		>
			<template #body="item">
				<span>{{ item.data.firstName }}</span>
			</template>
		</Column>
		<Column
			key="lastName"
			field="lastName"
			:header="$t('myCadac.accountSettings.company.table.lblLastName')"
			:sortable="true"
		>
			<template #body="item">
				<span>{{ item.data.lastName }}</span>
			</template>
		</Column>
		<Column key="email" field="email" :header="$t('myCadac.accountSettings.company.table.lblEmail')">
			<template #body="item">
				<span>{{ item.data.email }}</span>
			</template>
		</Column>
		<Column key="edit">
			<template #body="item">
				<Button
					v-if="canEdit"
					class="p-button-icon p-button-rounded p-button-text p-button-raised"
					icon="pi pi-pencil"
					:label="isSmallScreen ? $t('myCadac.accountSettings.company.table.lblEditButton') : ''"
					:title="$t('myCadac.accountSettings.company.table.lblEditButton')"
					@click="onEditClick(item.data)"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { RelatedContact } from "@/types/models/common/relatedContact.interface";

@Component({})
export default class RelatedContactTable extends BaseComponent {
	@Prop({
		type: Object as PropType<RelatedContact>,
		required: true,
		default: [],
	})
	items!: RelatedContact[];
	maxRowsToShow = 15;
	canEdit = false;

	get showPagination(): boolean {
		return this.items?.length > this.maxRowsToShow;
	}

	onEditClick(contact) {
		console.log("edit contact", contact);
	}
}
</script>

<style scoped lang="scss"></style>
