import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c73da9d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "training-module-table" }
const _hoisted_2 = { class: "d-flex w-100 font-weight-bold" }
const _hoisted_3 = { class: "flex-grow-1" }
const _hoisted_4 = {
  class: "px-1",
  style: {"width":"20%"}
}
const _hoisted_5 = {
  class: "px-2",
  style: {"width":"20%"}
}
const _hoisted_6 = {
  key: 0,
  style: {"width":"10%"}
}
const _hoisted_7 = { class: "row-expansion-content p-1" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modules?.length)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          "expanded-rows": _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedRows) = $event)),
          "auto-layout": "",
          "data-key": "sku",
          "responsive-layout": "stack",
          "row-class": _ctx.highlightRow,
          "row-hover": true,
          value: _ctx.modules,
          onRowClick: _withModifiers(_ctx.onRowClick, ["self"])
        }, _createSlots({
          expansion: _withCtx((item) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", {
                class: "text-preline",
                innerHTML: item.data.shortDescription
              }, null, 8, _hoisted_8)
            ])
          ]),
          default: _withCtx(() => [
            (!_ctx.isSmallScreen)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  expander: "",
                  style: {"width":"2.5rem"}
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              key: "name",
              field: "name"
            }, {
              body: _withCtx((item) => [
                _createElementVNode("span", null, _toDisplayString(item.data.name), 1)
              ]),
              _: 1
            }),
            (!_ctx.isDefaultTraining)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: "durationInHours",
                  field: "durationInMinutes",
                  style: {"width":"20%"}
                }, {
                  body: _withCtx((item) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`training.duration.${_ctx.getDuration(item.data.durationInHours)}`)), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.isDefaultTraining)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: "price",
                  field: "price",
                  style: {"width":"20%"}
                }, {
                  body: _withCtx((item) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.formatCurrency(item.data.price)), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isEditable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: "selection",
                  style: {"width":"10%"}
                }, {
                  body: _withCtx((item) => [
                    _createVNode(_component_InputSwitch, {
                      "model-value": _ctx.isRowSelected(item.data),
                      onClick: _withModifiers(($event: any) => (_ctx.onToggleSelectionClicked(item.data)), ["stop"])
                    }, null, 8, ["model-value", "onClick"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (!_ctx.isDefaultTraining)
            ? {
                name: "footer",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("common.total")), 1),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$tc("common.days", _ctx.totalDurationInDays)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.formatCurrency(_ctx.totalPrice || 0)), 1)
                    ]),
                    (_ctx.isEditable)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                      : _createCommentVNode("", true)
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["expanded-rows", "row-class", "value", "onRowClick"]))
      : _createCommentVNode("", true)
  ]))
}