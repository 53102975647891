<template>
	<div class="field">
		<p class="lead">{{ $t(`enum.overviewFilterCategory.${filterStore.availabilityFilter?.category}`) }}</p>
		<Divider class="mt-0" />
		<Calendar
			v-model="selectedDates"
			ref="periodCalendar"
			class="w-100"
			:manual-input="false"
			:placeholder="$t('expert.selectPeriod')"
			selection-mode="range"
		>
			<template #footer>
				<div class="py-1 d-flex justify-content-between">
					<Button
						v-if="selectedDates?.length > 0"
						class="p-button-text p-button-secondary"
						:label="$t('common.resetFilters')"
						@click="resetDateFilter"
					/>
					<Button class="p-button-text ml-auto" :label="$t('common.apply')" @click="applyDateFilter" />
				</div>
			</template>
		</Calendar>
	</div>

	<div class="field mt-2">
		<p class="lead">{{ $t(`enum.overviewFilterCategory.${filterStore.workOnLocationFilter?.category}`) }}</p>
		<Divider class="mt-0" />
		<div class="product-filter-option p-field-checkbox form-group">
			<Checkbox
				v-model="workLocationActiveRemote"
				:id="`filter-remote`"
				binary
				@change="onRemoteLocationChanged"
			/>
			<label
				class="ml-2 mb-0 p-link"
				:class="workLocationActiveRemote ? 'font-weight-bold' : 'font-weight-normal'"
				:for="`filter-remote`"
			>
				{{ $t("expert.remote") }}
			</label>
		</div>
		<div class="product-filter-option p-field-checkbox form-group">
			<Checkbox v-model="workLocationActive" :id="`filter-onLocation`" binary />
			<label
				class="ml-2 mb-0 p-link"
				:class="workLocationActive ? 'font-weight-bold' : 'font-weight-normal'"
				:for="`filter-onLocation`"
			>
				{{ $t("expert.onLocation") }}
			</label>
		</div>
		<GMapAutocomplete
			v-show="workLocationActive"
			ref="locationInput"
			class="p-inputtext p-component"
			:placeholder="$t(`expert.searchYourLocation`)"
			@place_changed="onLocationChanged"
		/>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import SelectButton from "primevue/selectbutton";
import { Component, Emit, Ref, Watch } from "vue-facing-decorator";
import { LocationFilterViewModel } from "@/types/models/common/filters/locationFilterViewModel";
import { OverviewFilterViewModel } from "@/types/models/common/filters/overviewFilterViewModel";
import { useProductOverviewFilterStore } from "@/store/common/productOverviewFiltersStore";
import { values } from "lodash";
import { zonedTimeToUtc } from "date-fns-tz";

@Component({
	components: { Calendar, SelectButton, Checkbox, Divider },
})
export default class ExpertFilters extends BaseComponent {
	@Ref("periodCalendar") readonly periodCalendar;
	@Ref("locationInput") readonly locationInput;

	selectedDates!: Date[];
	selectedLocation!: LocationFilterViewModel | null;
	selectedStartDate!: Date;
	selectedEndDate!: Date;

	workLocationActiveRemote = false;
	workLocationActive = false;

	filterStore = useProductOverviewFilterStore();

	@Emit()
	onExpertFilterChange(filter: OverviewFilterViewModel): OverviewFilterViewModel {
		return filter;
	}

	// Check if the availabilityFilter has been reset
	@Watch("filterStore.availabilityFilter", { deep: true }) onAvailabilityFilterChanged(
		val: OverviewFilterViewModel
	) {
		if (!val.props?.availability) {
			this.selectedDates = [];
		}
	}

	mounted(): void {
		if (this.filterStore.hasActiveWorkOnLocationFilter) {
			this.workLocationActive = true;
			this.selectedLocation = this.filterStore.workOnLocationFilter.props?.location;
			setTimeout(() => (this.locationInput.value = this.selectedLocation?.locationName));
		}
		if (this.filterStore.hasActiveWorkRemoteFilter) {
			this.workLocationActiveRemote = true;
		}
		if (this.filterStore.hasActiveAvailabilityFilter) {
			this.selectedDates = values(this.filterStore.availabilityFilter.props?.availability).map((x) =>
				this.toDate(x)
			);
		}
	}

	get reviewFilter(): OverviewFilterViewModel {
		return this.filterStore.filters.find((x) => x.label === "review") as OverviewFilterViewModel;
	}

	onLocationChanged(place): void {
		if (place?.name) {
			const result = place.address_components?.find((component) => {
				return component.types.includes("country");
			});
			if (!result) {
				this.selectedLocation = null;
			} else {
				this.selectedLocation = {
					locationName: place.formatted_address,
					countryCode: result.short_name,
					latitude: place.geometry.location.lat(),
					longitude: place.geometry.location.lng(),
					workOnLocation: this.workLocationActive,
				};
			}
		} else {
			this.selectedLocation = null;
		}
		if (this.filterStore.hasWorkOnLocationFilter) {
			this.filterStore.updateWorkOnLocationFilter(this.selectedLocation as LocationFilterViewModel);
			this.onExpertFilterChange(this.filterStore.workOnLocationFilter);
		}
	}

	applyDateFilter() {
		try {
			this.selectedStartDate = this.selectedDates[0];
			this.selectedEndDate = this.selectedDates[1];
			this.periodCalendar.overlayVisible = false;
		} catch {
			//
		}

		if (this.filterStore.hasAvailabilityFilter) {
			this.filterStore.updateAvailabilityFilter({
				startDate: zonedTimeToUtc(this.selectedStartDate, "UTC"),
				endDate: zonedTimeToUtc(this.selectedEndDate, "UTC"),
			});
			this.onExpertFilterChange(this.filterStore.availabilityFilter);
		}
	}

	resetDateFilter() {
		this.selectedDates = [];
		this.applyDateFilter();
	}

	onRemoteLocationChanged(): void {
		if (this.filterStore.hasWorkRemoteFilter) {
			this.filterStore.updateWorkRemoteLocationFilter({
				workRemote: this.workLocationActiveRemote,
			} as LocationFilterViewModel);
			this.onExpertFilterChange(this.filterStore.workRemoteFilter);
		}
	}
}
</script>

<style lang="scss" scoped></style>
