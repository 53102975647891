import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38dcd120"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container text-center" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "lead mb-3" }
const _hoisted_4 = { class: "row justify-content-center px-2" }
const _hoisted_5 = { class: "col-md-4 block-card" }
const _hoisted_6 = { class: "text-primary font-weight-bold" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "col-md-4 block-card" }
const _hoisted_9 = { class: "text-primary font-weight-bold" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "col-md-8 py-2" }
const _hoisted_12 = {
  key: 0,
  class: "text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.vm.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: "display-4",
            innerHTML: _ctx.vm.title
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.vm.text), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.vm.leftPreTitle), 1),
          (_ctx.vm.leftTitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(_ctx.vm.leftTitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.vm.leftText), 1),
          (_ctx.vm.leftCta)
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 1,
                class: _normalizeClass(["mt-2", _ctx.ctaButtonClass(_ctx.vm.leftCtaStyle) || 'p-button-link p-button-arrow']),
                label: _ctx.vm.leftCta.text,
                target: _ctx.vm.leftCta.target,
                title: _ctx.vm.leftCta.title,
                url: _ctx.vm.leftCta.url
              }, null, 8, ["class", "label", "target", "title", "url"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.vm.rightPreTitle), 1),
          (_ctx.vm.rightTitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_10, _toDisplayString(_ctx.vm.rightTitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.vm.rightText), 1),
          (_ctx.vm.rightCta)
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 1,
                class: _normalizeClass(["mt-2", _ctx.ctaButtonClass(_ctx.vm.rightCtaStyle) || 'p-button-link p-button-arrow']),
                label: _ctx.vm.rightCta.text,
                target: _ctx.vm.rightCta.target,
                title: _ctx.vm.rightCta.title,
                url: _ctx.vm.rightCta.url
              }, null, 8, ["class", "label", "target", "title", "url"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.vm.bottomTitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_12, _toDisplayString(_ctx.vm.bottomTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.vm.bottomCta)
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 1,
                class: _normalizeClass(["p-button-lg", 
							_ctx.ctaButtonClass(_ctx.vm.bottomCtaStyle) || 'p-button-success p-button-raised p-button-arrow'
						]),
                label: _ctx.vm.bottomCta.text,
                target: _ctx.vm.bottomCta.target,
                title: _ctx.vm.bottomCta.title,
                url: _ctx.vm.bottomCta.url
              }, null, 8, ["class", "label", "target", "title", "url"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}