<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<h2 v-if="vm.title" class="display-4" :class="{ 'mb-0': vm.subTitle }" v-html="vm.title"></h2>
			<p v-if="vm.subTitle" class="lead mb-2">{{ vm.subTitle }}</p>
			<div class="row gap-y-3">
				<div v-for="(item, index) in vm.items" :key="`categoryBlockItem_${index}`" :class="columnWidth">
					<LinkButton
						class="category-item-button"
						:target="item.linkItem.target"
						:url="item.linkItem.url"
					>
						<img v-lazy="item.imageUrl" :alt="item.imageAlt" class="mr-2" height="80" />
						<div>
							<p v-if="item.linkItem.title" class="lead">{{ item.linkItem.title }}</p>
							<h4 class="mb-0 font-weight-bold">{{ item.linkItem.text }}</h4>
						</div>
						<i class="pi pi-chevron-right ml-auto mr-2"></i>
					</LinkButton>
				</div>
				<div class="col-12">
					<LinkButton
						v-if="vm.ctaLinkItem"
						:class="ctaButtonClass(vm.ctaStyle) || 'p-button-arrow p-button-raised p-button-text'"
						:label="vm.ctaLinkItem.text"
						:target="vm.ctaLinkItem.target"
						:title="vm.ctaLinkItem.title"
						:url="vm.ctaLinkItem.url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { BlockBackgroundColor } from "@/types/enum/blockBackgroundColor";
import { CategoriesBlockViewModel } from "@/types/viewModels/blocks/categoriesBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component
export default class CategoriesBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<CategoriesBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: CategoriesBlockViewModel;

	created(): void {
		Log.info("CategoriesBlock", this.vm);
	}

	get columnWidth(): string {
		let colWidth = "col-12 col-sm-6 ";
		colWidth += this.vm.items?.length === 4 ? "col-lg-3" : "col-lg-4";
		return colWidth;
	}

	// Don't remove (it's used in the style section)
	get itemHoverColor(): string {
		switch (this.vm.backgroundColor) {
			case BlockBackgroundColor.White:
			case BlockBackgroundColor.Pink:
				return "var(--surface-b)";
			case BlockBackgroundColor.Grey:
				return "var(--surface-c)";
			case BlockBackgroundColor.Purple:
			case BlockBackgroundColor.Green:
			default:
				return "rgba(255, 255, 255, 0.2)";
		}
	}

	get textColor(): string {
		switch (this.vm.backgroundColor) {
			case BlockBackgroundColor.Purple:
			case BlockBackgroundColor.Green:
				return "var(--primary-color-text)";
			case BlockBackgroundColor.White:
			case BlockBackgroundColor.Grey:
			case BlockBackgroundColor.Pink:
			default:
				return "var(--primary-color)";
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.category-item-button) {
	&.p-button:enabled {
		width: 100%;
		padding: 20px;
		border: 1px solid var(--surface-d);
		color: v-bind(textColor);
		background: linear-gradient(v-bind(itemHoverColor) 0 0) calc(100% - var(--p, 0%)) / var(--p, 0%) no-repeat;
		transition:
			0.5s,
			background-position 0s;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

		i.pi {
			transition: transform 1s;
		}

		&:hover {
			--p: 100%;
			color: v-bind(textColor);

			i.pi {
				transform: scale(1.5);
			}
		}
	}
}
</style>
