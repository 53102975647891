<template>
	<div class="product-config-step">
		<div v-if="$slots.header" class="product-config-step-header">
			<slot name="header"></slot>
		</div>
		<div v-else class="product-config-step-header">
			<span class="product-config-step-title">{{ title }}</span>
		</div>
		<div class="product-config-step-content">
			<div v-if="$slots.options" class="product-config-step-options">
				<slot name="options" />
			</div>
			<slot />
			<slot name="error" />
			<div v-if="$slots.footer" class="product-config-step-footer">
				<slot name="footer" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component
export default class ProductConfigurationStep extends BaseComponent {
	@Prop({
		type: String,
		required: false,
		default: "",
	})
	title!: string;
}
</script>

<style lang="scss" scoped>
.product-config-step {
	position: relative;
	margin-top: 0;
	margin-bottom: 0;

	&:not(:last-of-type) {
		// padding-bottom: 4rem;

		&::after {
			content: "";
			top: 5px;
			left: -30px;
			position: absolute;
			z-index: 1;
			width: 1px;
			height: 100%;
			background-color: var(--surface-d);
		}
	}

	.product-config-step-header {
		padding: 0.25rem 0;
		font-size: 1.1rem;
		font-weight: 600;
		counter-increment: product-config-step-counter;

		&::before {
			content: counter(product-config-step-counter);
			position: absolute;
			top: 0;
			left: -50px;
			z-index: 2;
			padding: 0.25rem 0.75rem;
			border: 1px solid #e1e1e1;
			border-radius: 50%;
			background: var(--surface-a);
			color: var(--primary-color);
			font-size: 1.1rem;
			text-align: center;
			font-weight: 500;
		}

		:deep(.p-button-secondary) {
			padding: 0;
			text-align: left;
		}
	}

	.product-config-step-content {
		padding: 2rem 2rem 2rem 0;

		.product-config-step-options {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.product-config-step-footer {
		width: fit-content;
		margin-top: 1rem;
		padding-top: 0.5rem;
		padding-bottom: 1.5rem;
		border-top: 1px solid var(--surface-500);
		color: var(--text-color-secondary);
	}
}
</style>
