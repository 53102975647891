<template>
	<div class="d-flex align-items-center justify-content-between flex-wrap mb-2">
		<h1 class="mb-0">
			<svg class="mb-1" height="60" width="60">
				<use xlink:href="#icon-wallet"></use>
			</svg>
			{{ vm.title }}
		</h1>
		<Button
			v-if="vm.walletDepositPageUrl"
			class="p-button-raised p-button-success p-button-arrow"
			:label="$t('myCadac.walletOverview.button.buyPrepaidCredit')"
			@click="
				openUrl(vm.walletDepositPageUrl);
				$event.target.disabled = true;
			"
		/>
	</div>

	<p class="lead" v-html="vm.introText"></p>

	<div class="row my-3">
		<div :class="vm?.totalDeposited > 0 ? 'col-12 col-lg-12 col-xl-8 mb-1': 'col-12 col-lg-6 mb-1'">
			<Card @click="toggleWalletType()"
				  id="wallet-card-1"
				  :class="['wallet-total-card', { 'active-wallet-card': cadacWalletTransactionsVisible }]">
				<template #content>
					<div class="d-flex justify-content-between">
						<div v-if="vm?.totalDeposited > 0" class="subcard">
							<div class="mb-2">
								{{$t('myCadac.walletOverview.t2')}}
							</div>
							<div class="d-flex mb-2">
								<img v-lazy="'/Static/images/loyalty-gold.png'"
									 alt="cadac prepaid icon"
									 class="wallet-icon" />
								<h2 class="text-primary mb-0 leading-8">
									{{ formatCurrency(vm?.totalDeposited) }}
								</h2>
							</div>
						</div>
						<div class="subcard">
							<div class="mb-2">
								{{$t('myCadac.walletOverview.t1')}}
							</div>
							<div class="d-flex mb-2">
								<img v-lazy="'/Static/images/loyalty-silver.png'"
									 alt="loyalty discount icon"
									 class="wallet-icon" />
								<h2 class="text-primary mb-0 leading-8">
									{{ formatCurrency(vm?.totalSaved) }}
								</h2>
							</div>
						</div>
						<RadioButton @click.stop v-model="cadacWalletTransactionsVisible" :value="true" />
					</div>
				</template>
			</Card>
		</div>
		<div :class="vm?.totalDeposited > 0 ? 'col-12 col-lg-12 col-xl-4 mb-1': 'col-12 col-lg-6 col-xl-6 mb-1'">
			<Card @click="toggleWalletType()" id="wallet-card-2"
				  :class="['wallet-total-card', { 'active-wallet-card': !cadacWalletTransactionsVisible }]">
				<template #content>
					<div class="d-flex justify-content-between">
						<div class="subcard">
							<div class="mb-2">
								{{$t('myCadac.walletOverview.t3')}}
							</div>
							<div class="d-flex mb-2">
								<img v-lazy="'/Static/images/autodesk-coin.png'"
									 alt="loyalty cashback icon"
									 class="wallet-icon" />
								<h2 class="text-primary mb-0 leading-8">
									{{ formatCurrency(vm?.totalAutodeskSaved) }}
								</h2>
							</div>
						</div>
						<RadioButton @click.stop v-model="cadacWalletTransactionsVisible" :value="false" />
					</div>
				</template>
			</Card>
		</div>
	</div>

	<Message v-if="!tableData?.length" :closable="false" severity="warn">
		{{ cadacWalletTransactionsVisible ? $t("myCadac.walletOverview.message.noTransactions") : $t("myCadac.walletOverview.message.noAutodeskTransactions") }}
	</Message>

	<DataTable v-if="tableData?.length"
				id="walletOverviewTable"
				class="mb-3"
				:paginator="showPagination"
				removable-sort
				:responsive-layout="'scroll'"
				:row-class="getRowClass"
				:rows="maxRowsToShow"
				sort-field="transactionDate"
				:sort-order="-1"
				:value="tableData">
		<Column key="type" field="type" :header="$t('myCadac.walletOverview.table.colType')" :sortable="true">
			<template #body="item">
				<div class="d-flex align-items-center">
					<Avatar class="mr-1 bg-transparent" :icon="getTransactionIcon(item.data)" size="sm" />
					<p class="text-bold mb-0">
						{{ getTransactionText(item.data) }}
					</p>
				</div>
			</template>
		</Column>

		<Column key="orderNumber" field="orderNumber" :header="$t('myCadac.walletOverview.table.colOrderNumber')">
			<template #body="item">
				<span>{{ item.data.orderNumber }}</span>
			</template>
		</Column>

		<Column key="date"
				field="transactionDate"
				:header="$t('myCadac.walletOverview.table.colDate')"
				:sortable="true">
			<template #body="item">
				<span>{{ formatDate(item.data.transactionDate) }}</span>
			</template>
		</Column>

		<Column key="amount"
				field="amount"
				:header="$t('myCadac.walletOverview.table.colTotalAmount')"
				:sortable="true">
			<template #body="item">
				<div class="d-flex align-items-center">
					<Avatar class="mr-1 bg-transparent text-danger icon-border"
							:icon="getAmountIcon(item.data)"
							size="sm" />
					<span>{{ formatCurrency(item.data.amount) }}</span>
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IWalletOverview } from "@/types/viewModels/myCadac/walletOverviewViewModel";
import { PrimeIcons } from "primevue/api";
import { PropType } from "vue";
import { WalletTransaction } from "@/types/generated/custom/walletTransaction";
import { WalletTransactionOrigin } from "@/types/enum/walletTransactionOrigin";
import { WalletTransactionType } from "@/types/enum/walletTransactionType";

@Component({ components: { MyCadacMenu } })
export default class WalletOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<IWalletOverview>,
		required: true,
		default: {},
	})
	vm!: IWalletOverview;

	maxRowsToShow = 15;

	cadacWalletTransactionsVisible = true;

	toggleWalletType() {
		this.cadacWalletTransactionsVisible = !this.cadacWalletTransactionsVisible;
	}

	get tableData(): WalletTransaction[] {
		return this.cadacWalletTransactionsVisible ? this.vm.cadacWalletTransactions : this.vm.autodeskWalletTransactions;
	}

	get showPagination(): boolean {
		return this.tableData?.length > this.maxRowsToShow;
	}

	getTransactionIcon(transaction: WalletTransaction): string | undefined {
		switch (transaction.type) {
			case WalletTransactionType.prepaidCreditSpent:
				return `${PrimeIcons.MONEY_BILL} text-danger`;
			case WalletTransactionType.prepaidCreditEarned:
				return `${PrimeIcons.MONEY_BILL} text-success`;
			case WalletTransactionType.creditSpent:
				return `${PrimeIcons.WALLET} text-danger`;
			case WalletTransactionType.creditEarned:
				return `${PrimeIcons.WALLET} text-success`;
			case WalletTransactionType.expiresSoon:
				return `${PrimeIcons.EXCLAMATION_TRIANGLE} text-warning`;
			case WalletTransactionType.expired:
				return `${PrimeIcons.EXCLAMATION_TRIANGLE} text-danger`;
			case WalletTransactionType.manualEdit:
				return `${PrimeIcons.USER} text-primary`;
			case WalletTransactionType.cancelledOrder:
				return `${PrimeIcons.TIMES_CIRCLE} text-primary`;
			case WalletTransactionType.projectOrder:
				return `${PrimeIcons.BRIEFCASE} text-primary`;
			case WalletTransactionType.autodeskWalletAmountSpent:
				return `${PrimeIcons.MONEY_BILL} text-danger`;
			case WalletTransactionType.autodeskWalletAmountEarned:
				return `${PrimeIcons.MONEY_BILL} text-success`;
			default:
				return "";
		}
	}

	getAmountIcon(transaction: WalletTransaction): string | undefined {
		if (
			transaction.origin === WalletTransactionOrigin.storeOrder ||
			transaction.origin === WalletTransactionOrigin.purchased ||
			transaction.origin === WalletTransactionOrigin.autodeskQuote
		) {
			return transaction.amount > 0
				? `${PrimeIcons.ARROW_UP} text-success`
				: `${PrimeIcons.ARROW_DOWN} text-danger`;
		} else {
			switch (transaction.origin) {
				case WalletTransactionOrigin.cancelledOrder:
					return `${PrimeIcons.ARROW_DOWN_LEFT} text-danger`;
				case WalletTransactionOrigin.expired:
					return `${PrimeIcons.ARROW_DOWN_LEFT} text-danger`;
				case WalletTransactionOrigin.cRMEdit:
					return transaction.amount > 0
						? `${PrimeIcons.ARROW_UP} text-info`
						: `${PrimeIcons.ARROW_DOWN} text-warning`;
				default:
					return "";
			}
		}
	}

	getTransactionText(transaction: WalletTransaction): string | undefined {
		return this.$t(`enum.walletTransactionType.${transaction.type}`);
	}

	getRowClass(transaction: WalletTransaction): string {
		switch (transaction.type) {
			case WalletTransactionType.expiresSoon:
				return "disabled";
			default:
				return "";
		}
	}
}
</script>

<style lang="scss" scoped>
    ::v-deep(.wallet-total-card.p-card) {
        border-radius: 5px;

        .p-card-content {
            padding: 0.5rem 0;
        }

        .p-card-body {
            padding: 0.5rem 0.75rem 0 0.5rem;
        }

        .subcard {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
        }

        .p-radiobutton {
            margin-top: 2px;
            margin-right: 0.25rem;
        }

		.wallet-icon {
            width: 2rem;
            height: 2rem;
            margin-right: 1.5rem;
        }
    }

	.icon-border {
		border: 1px solid #dddddd;
	}

	.active-wallet-card {
		cursor: default;
		border: 2px solid #91004b;
	}

	.wallet-total-card:not(.active-wallet-card) {
		cursor: pointer;
	}

</style>
