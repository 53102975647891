import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58fff78a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-panel-title flex-1" }
const _hoisted_2 = { class: "row no-gutters py-2" }
const _hoisted_3 = { class: "col-1" }
const _hoisted_4 = { class: "col px-2" }
const _hoisted_5 = { class: "ml-1 font-weight-bold lead" }
const _hoisted_6 = { class: "ml-1" }
const _hoisted_7 = { class: "d-flex gap-3" }
const _hoisted_8 = { class: "lead" }
const _hoisted_9 = { class: "d-inline-block" }
const _hoisted_10 = { class: "text-muted" }
const _hoisted_11 = { class: "flex-1" }
const _hoisted_12 = { class: "d-flex align-items-center gap-1" }
const _hoisted_13 = { class: "text-primary mt-2" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Message = _resolveComponent("Message")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_ctx.advisorStore.overview)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "p-3 mb-3",
        style: _normalizeStyle({ 'background-color': _ctx.scoreStyleObject.ctaBgColor, 'border-radius': '5px' })
      }, [
        _createVNode(_component_Panel, { collapsed: _ctx.panelCollapsed }, {
          header: _withCtx(() => [
            _createElementVNode("div", {
              class: "d-flex align-items-center flex-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.panelCollapsed = !_ctx.panelCollapsed))
            }, [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.scoreStyleObject.ctaTitle), 1),
              _createElementVNode("i", {
                class: _normalizeClass(["p-panel-header-icon", _ctx.panelCollapsed ? 'pi pi-chevron-left' : 'pi pi-chevron-down'])
              }, null, 2)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Message, {
              closable: false,
              icon: "none",
              severity: _ctx.scoreStyleObject.ctaMessageStyle
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_font_awesome_icon, {
                      class: _normalizeClass(["cta-icon", _ctx.scoreStyleObject.ctaTextColor]),
                      icon: [_ctx.scoreStyleObject.ctaIcon.style, _ctx.scoreStyleObject.ctaIcon.icon]
                    }, null, 8, ["class", "icon"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.scoreStyleObject.ctaMessage), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("digitalAdvisor.cta.messageSubtitle")), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["severity"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("digitalAdvisor.cta.savingsTitle")), 1),
                _createVNode(_component_i18n_t, {
                  keypath: "product.advice.lblYearlyTokenUsage",
                  scope: "global"
                }, {
                  amount: _withCtx(() => [
                    _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.formatCurrency(_ctx.overview.amountSavedPerYear || 0)), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("digitalAdvisor.cta.savingsSubtitle")), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.vm.ctaTitle), 1),
                _createElementVNode("div", _hoisted_12, [
                  (_ctx.vm.primaryCta)
                    ? (_openBlock(), _createBlock(_component_LinkButton, {
                        key: 0,
                        class: _normalizeClass(["p-button-raised", 
								_ctx.ctaButtonClass(_ctx.vm.primaryCtaStyle) ||
								'p-button-raised p-button-success p-button-arrow'
							]),
                        label: _ctx.vm.primaryCta.text,
                        target: _ctx.vm.primaryCta.target,
                        title: _ctx.vm.primaryCta.title,
                        url: _ctx.vm.primaryCta.url
                      }, null, 8, ["class", "label", "target", "title", "url"]))
                    : _createCommentVNode("", true),
                  (_ctx.layoutStore.chatWidgetAvailable)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "p-button-secondary",
                        label: _ctx.$t('product.advice.button.chatWithEmployee'),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.initChat(`${_ctx.$t('myCadac.advisorAdvice.chatMessage.contactSupport')}`)))
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", _hoisted_13, [
                  _createVNode(_component_i18n_t, {
                    keypath: "common.support.contactUsAt",
                    scope: "global"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: 'tel:' + _ctx.$t('common.support.phoneNumber')
                      }, _toDisplayString(_ctx.$t("common.support.phoneNumber")), 9, _hoisted_14)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["collapsed"])
      ], 4))
    : _createCommentVNode("", true)
}