<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<div class="row">
				<div class="col">
					<div class="row p-2 product-grid-columned-block">
						<div
							v-for="product in vm.products"
							:key="product"
							class="col col-12 col-md-6 col-lg-12 col-xl-6 p-1"
						>
							<BaseProductOverviewItemCard :vm="product" />
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="html-content mb-2">
						<h2 v-if="vm.title" class="display-4 mb-0">{{ vm.title }}</h2>
						<p v-html="vm.text"></p>
					</div>
					<LinkButton
						v-if="vm.cta"
						:class="{
							[ctaButtonClass(vm.ctaStyle)]: ctaButtonClass(vm.ctaStyle),
							'p-button-success p-button-raised p-button-arrow': !ctaButtonClass(vm.ctaStyle),
						}"
						:label="vm.cta.text"
						:target="vm.cta.target"
						:title="vm.cta.title"
						:url="vm.cta.url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ProductGridColumnedBlockViewModel } from "@/types/viewModels/blocks/productGridColumnedBlockViewModel";
import { PropType } from "vue";

@Component({ components: { BaseProductOverviewItemCard } })
export default class ProductGridColumnedBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<ProductGridColumnedBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: ProductGridColumnedBlockViewModel;
}
</script>

<style lang="scss">
.product-grid-columned-block .product-grid-card .p-card-body {
	padding: 1rem;
}

.product-grid-columned-block .product-grid-card p-card-content {
	height: 100%;
}

.product-grid-columned-block h4 {
	font-size: 1.2rem;
}

.product-grid-columned-block .product-grid-card .p-avatar-group {
	margin: 1rem 0;
}
</style>
