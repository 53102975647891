<template>
	<div class="d-flex align-items-center justify-content-between">
		<h1 class="mb-1">
			{{ vm.pageTitle }}
		</h1>
		<Button
			v-if="vm.askAQuestionPageUrl"
			class="p-button-raised p-button-success p-button-arrow"
			:label="$t('myCadac.supportTicketOverview.button.askAQuestion')"
			@click="
				openUrl(vm.askAQuestionPageUrl);
				$event.target.disabled = true;
			"
		/>
	</div>

	<div class="row my-3">
		<div class="col-12 col-lg-4 mb-1">
			<Card id="card-supportTickets-1" class="support-ticket-card">
				<template #content>
					<div class="d-flex">
						<Avatar class="mr-1 bg-transparent" shape="square" size="large">
							<svg class="" fill="#000" height="40" width="40">
								<use xlink:href="#icon-training"></use>
							</svg>
						</Avatar>
						<div>
							<h3 class="text-primary mb-0 mb-lg-2">
								{{ vm?.openTicketCount }}
							</h3>
							<p class="mb-0">
								{{ $t("myCadac.supportTicketOverview.card.questions") }}
							</p>
						</div>
					</div>
				</template>
			</Card>
		</div>

		<div class="col-12 col-lg-4 mb-1">
			<Card id="card-supportTickets-2" class="support-ticket-card">
				<template #content>
					<div class="d-flex">
						<Avatar class="mr-1 bg-transparent" shape="square" size="large">
							<svg class="" fill="#000" height="40" width="40">
								<use xlink:href="#icon-ticket-warning"></use>
							</svg>
						</Avatar>
						<div>
							<h3 class="text-primary mb-0 mb-lg-2">
								{{ vm?.actionRequiredTicketCount }}
							</h3>
							<p class="mb-0">
								{{
									$tc(
										"myCadac.supportTicketOverview.card.actions",
										vm?.actionRequiredTicketCount
									)
								}}
							</p>
						</div>
					</div>
				</template>
			</Card>
		</div>

		<div class="col-12 col-lg-4 mb-1">
			<Card id="card-supportTickets-3" class="support-ticket-card">
				<template #content>
					<div class="d-flex">
						<Avatar class="mr-1 bg-transparent" shape="square" size="large">
							<svg class="" fill="#000" height="40" width="40">
								<use xlink:href="#icon-support-avatar"></use>
							</svg>
						</Avatar>
						<div>
							<h3 class="text-primary mb-0 mb-lg-2">
								{{ vm?.inProgressTicketCount }}
							</h3>
							<p class="mb-0">
								{{ $t("myCadac.supportTicketOverview.card.inProgress") }}
							</p>
						</div>
					</div>
				</template>
			</Card>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<DataTable
				v-if="tableData.length"
				breakpoint="991px"
				class="mt-2"
				removable-sort
				responsive-layout="stack"
				:value="tableData"
			>
				<Column
					key="id"
					field="id"
					:header="$t('myCadac.supportTicketOverview.table.colId')"
					:sortable="false"
				/>
				<Column
					key="subject"
					field="subject"
					:header="$t('myCadac.supportTicketOverview.table.colSubject')"
					:sortable="false"
				>
					<template #body="item">
						<a class="text-blue" :href="item.data.ticketUrl" rel="noreferrer noopener" target="_blank">
							<b>{{ item.data.subject }}</b>
						</a>
					</template>
				</Column>
				<Column
					key="mainCategory"
					field="mainCategory"
					:header="$t('myCadac.supportTicketOverview.table.colType')"
					:sortable="true"
				/>
				<Column
					key="modified"
					field="modified"
					:header="$t('myCadac.supportTicketOverview.table.colModified')"
					:sortable="true"
				>
					<template #body="item">
						{{ formatDate(item.data.modified) }}
					</template>
				</Column>
				<Column
					key="status"
					field="status"
					:header="$t('myCadac.supportTicketOverview.table.colStatus')"
					:sortable="true"
				>
					<template #body="item">
						<StatusBadge
							:status="item.data.simpleStatus"
							:translation-prefix="'myCadac.supportTicketOverview.badge_status'"
						/>
					</template>
				</Column>
			</DataTable>
			<Card v-if="!tableData.length" class="mx-auto mt-2">
				<template #content>
					<p class="lead">
						{{ $t("myCadac.supportTicketOverview.card.noTicketsWarning") }}
					</p>
					<Button class="p-button-primary" @click="openUrl(vm.askAQuestionPageUrl)">
						{{ $t("myCadac.supportTicketOverview.card.noTicketsCta") }}
					</Button>
				</template>
			</Card>
			<div class="d-flex mt-2">
				<Button
					v-if="tableData.length && vm.showAllTicketsCtaLinkItemHref"
					class="p-button-primary p-button-raised"
					:label="vm.showAllTicketsCtaLinkItemText"
					@click="
						openUrl(vm.showAllTicketsCtaLinkItemHref, vm.showAllTicketsCtaLinkItemTarget);
						$event.target.disabled = true;
					"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";
import { Component, Prop } from "vue-facing-decorator";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { ISupportTicketOverview } from "@/types/viewModels/myCadac/supportTicketOverviewViewModel";
import { PropType } from "vue";
import { TicketViewModel } from "@/types/generated/custom/ticketViewModel";

@Component({ components: { MyCadacMenu } })
export default class SupportTicketOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ISupportTicketOverview>,
		required: true,
		default: {},
	})
	vm!: ISupportTicketOverview;

	get tableData(): TicketViewModel[] {
		return this.vm.tickets ? JSON.parse(this.vm.tickets, FormatHelper.formatJsonAsCamelCase) : [];
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.support-ticket-card.p-card) {
	.p-card-body {
		padding: 0;
	}

	.p-card-content {
		padding: 1rem;
	}
}
</style>
