<template>
	<div class="container py-3">
		<div class="row">
			<div class="col-12 col-lg-8">
				<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>
				<p v-if="vm.introText" class="lead">{{ vm.introText }}</p>
				<!--				<div v-if="userProfile.isCadacSales" class="d-flex align-items-center mb-2">-->
				<!--					<Checkbox v-model="advisorStore.isAccessRestricted" binary input-id="lock" name="contentLock" />-->
				<!--					<label class="ml-2 my-auto" for="lock">Content locked</label>-->
				<!--				</div>-->

				<AdvisorResultCta :vm="{ ...vm.resultCtaBlockViewModel }" />
				<AdvisorAdviceOverview :advice-log-id="vm.adviceLogId" :api-version="vm.apiVersion" />
			</div>
			<div class="col-12 col-lg-4 order-first order-lg-last mb-3 mb-lg-0">
				<div class="sticky-section">
					<AdvisorInsightsOverview />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import AdvisorAdviceOverview from "@/components/advisor/advisorAdviceOverview.vue";
import AdvisorInsightsOverview from "@/components/advisor/advisorInsightsOverview.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import ScrollPanel from "primevue/scrollpanel";
import { Component, Prop } from "vue-facing-decorator";
import { IDigitalAdvisor } from "@/types/viewModels/myCadac/digitalAdvisorViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import AdvisorResultCta from "@/components/advisor/advisorResultCta.vue";
import { useAdvisorStore } from "@/store/advisor/advisorStore";
import Checkbox from "primevue/checkbox";
@Component({
	components: { AdvisorResultCta, AdvisorAdviceOverview, AdvisorInsightsOverview, ScrollPanel, Checkbox },
})
export default class DigitalAdvisor extends BaseComponent {
	@Prop({ type: Object as PropType<IDigitalAdvisor>, required: true, default: {} }) vm!: IDigitalAdvisor;
	advisorStore = useAdvisorStore();

	created(): void {
		Log.info("DigitalAdvisor", this.vm);
	}
}
</script>

<style scoped lang="scss">
.sticky-section {
	position: sticky;
	top: calc(var(--navbarHeightInPx) + 1rem);
}
</style>
