import { CheckoutStep } from '@/components/commerce/checkout/checkoutSteps.vue';
import { ICheckout } from '@/types/viewModels/commerce/checkoutViewModel';
import { PaymentMethod } from '@/types/enum/paymentMethod';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

interface CheckoutStoreState {
	vm: ICheckout;
	activeStep: CheckoutStep;
	isDetailStepValid: boolean;
	isOverviewStepValid: boolean;
}

export const useCheckoutStore = defineStore('checkout', {
	state: () =>
		({
			vm: {},
			activeStep: -1,
			isDetailStepValid: false,
			isOverviewStepValid: false,
		}) as CheckoutStoreState,
	getters: {
		canGoToStep(state): (step: CheckoutStep) => boolean {
			return (step: CheckoutStep) => {
				switch (step) {
					case CheckoutStep.Details:
						return true;
					case CheckoutStep.PaymentMethod:
						return state.isDetailStepValid;
					case CheckoutStep.Overview:
						return state.isDetailStepValid && this.isValidPaymentOptionSelected;
					case CheckoutStep.Confirmation:
						return !!state.vm?.allowPlaceOrder && state.isOverviewStepValid;
					default:
						return false;
				}
			};
		},
		isValidPaymentOptionSelected(state: CheckoutStoreState) {
			if (!state.vm?.checkoutForm) return false;
			const { selectedPaymentProvider, selectedPaymentMethod, paymentMethod } = state.vm.checkoutForm;
			if (!state.vm.availablePaymentMethods.map((x) => x.value).includes(selectedPaymentMethod))
				return false;
			if (selectedPaymentMethod === PaymentMethod.creditcard) {
				return state.vm.creditCardMethods.findIndex((x) => x.key === selectedPaymentProvider) > -1;
			} else if (selectedPaymentMethod === PaymentMethod.ideal) {
				return state.vm.idealIssuers.findIndex((x) => x.key === selectedPaymentProvider) > -1;
			}

			return !!paymentMethod;
		},
		selectedPaymentMethod(state: CheckoutStoreState): string {
			return state.vm.checkoutForm?.selectedPaymentMethod;
		},
	},
	actions: {
		setSelectedPaymentProvider(paymentProvider: string): void {
			if (this.vm?.checkoutForm) {
				this.vm.checkoutForm.selectedPaymentProvider = paymentProvider;
				this.vm = cloneDeep(this.vm);
			}
		},
		setPaymentMethod(methodName: string): void {
			if (this.vm?.checkoutForm) {
				this.vm.checkoutForm.paymentMethod = methodName;
				this.vm.checkoutForm.selectedPaymentMethod = this.vm.availablePaymentMethods.find(
					(x) => x.key === methodName
				)?.value as PaymentMethod;
				this.vm = cloneDeep(this.vm);
			}
		},
		toggleApplyAutodeskWallet(applyLoyalty: boolean, maxAutodeskWalletToSpend: number): void {
			if (this.vm?.checkoutForm) {
				this.vm.checkoutForm.applyAutodeskWallet = (applyLoyalty && maxAutodeskWalletToSpend > 0);
				this.vm.checkoutForm.maxAutodeskWalletToSpend = maxAutodeskWalletToSpend; 
				this.vm = cloneDeep(this.vm);
			}
		},
	},
});
