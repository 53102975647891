import { ValidationHelper } from '@/types/helpers/validationHelper';
import { configure, defineRule } from 'vee-validate';
import { email, max, max_value, min, min_value, required } from '@vee-validate/rules';
import { getActiveLocale } from '@/types/utils/i18n';
import { localize, setLocale } from '@vee-validate/i18n';
import { memoize } from 'lodash';

defineRule('required', required);
defineRule('email', email);
defineRule('minLength', min);
defineRule('maxLength', max);
defineRule('minValue', min_value);
defineRule('maxValue', max_value);
defineRule('gender', ValidationHelper.validateGender);

defineRule('validPassword', (value) => {
	if (!value || !value.length) {
		return true;
	}
	return ValidationHelper.validatePassword(value);
});

defineRule('samePassword', (value, [target]) => {
	return value === target;
});

defineRule('validPostalCode', (value, [countryCode]) => {
	if (!value || !value.length) {
		return true;
	}
	return ValidationHelper.validatePostalCode(value, countryCode);
});

defineRule(
	'validVatNumber',
	memoize((value, [countryCode, useVat]) => {
		if (!value || !useVat) return true;
		return ValidationHelper.validateVat(value, countryCode, useVat);
	})
);
defineRule('validIbanNumber', (value, useIban) => {
	if (!value || !useIban) return true;
	return ValidationHelper.validateIban(value, useIban);
});

defineRule('validPhone', (value, phoneObj) => {
	if (!value || !value.length) {
		return true;
	}
	return phoneObj?.valid;
});

export const configureValidationMessages = (messages: any): void => {
	const mappedMessages = {
		[getActiveLocale()]: {
			messages: messages['validations'],
		},
	};
	configure({
		generateMessage: localize(mappedMessages),
	});

	setLocale(getActiveLocale());
};
