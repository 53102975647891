import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpertBookingOverviewTable = _resolveComponent("ExpertBookingOverviewTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.vm.pageTitle), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ExpertBookingOverviewTable, {
          "is-expert": _ctx.isExpert,
          vm: _ctx.vm
        }, null, 8, ["is-expert", "vm"])
      ])
    ])
  ], 64))
}