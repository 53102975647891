<template>
	<div class="product-filters-subheader">
		<Button
			v-if="filterStore.hasActiveFilters"
			class="p-button-secondary p-button-text p-0 mb-1"
			icon="pi pi-filter-slash"
			:label="$t('common.resetFilters')"
			@click="resetFilters()"
		/>
	</div>

	<div class="product-filters">
		<slot />
		<Panel
			v-for="filter in filterStore.optionFilters"
			v-model:collapsed="filterCollapsed[filterId(filter)]"
			:key="filter"
			class="product-filter-item"
		>
			<template #header>
				<p class="p-link lead mb-0 w-100" @click="toggleFilterCollapse(filter)">
					{{ filter.title || $t(`enum.overviewFilterCategory.${filter.category}`) }}
				</p>
			</template>
			<template #icons>
				<button class="p-panel-header-icon p-link mr-2" @click="toggleFilterCollapse(filter)">
					<i :class="isFilterCollapsed(filter) ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" />
				</button>
			</template>
			<div
				v-for="option in filter.options"
				:key="option"
				class="product-filter-option p-field-checkbox form-group"
			>
				<Checkbox
					v-model="option.isActive"
					:id="`filter-${filterId(filter)}-${option.label}`"
					binary
					:disabled="!option.isAvailable"
					@change="filtersChanged(filter)"
				/>
				<Avatar
					v-if="option.iconUrl"
					class="ml-2 option-icon"
					:image="option.iconUrl"
					shape="square"
					@click="
						option.isActive = !option.isActive;
						filtersChanged(filter);
					"
				/>
				<label
					class="ml-2 mb-0 p-link"
					:class="option.isActive ? 'font-weight-bold' : 'font-weight-normal'"
					:for="`filter-${filterId(filter)}-${option.label}`"
				>
					{{ option.label }}
				</label>
				<span class="text-muted ml-1">({{ option.count }})</span>
			</div>
		</Panel>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import { Component, Emit } from "vue-facing-decorator";
import { OverviewFilterViewModel } from "@/types/models/common/filters/overviewFilterViewModel";
import { useProductOverviewFilterStore } from "@/store/common/productOverviewFiltersStore";

@Component({ components: { Checkbox } })
export default class ProductFilters extends BaseComponent {
	filterCollapsed: { [name: string]: boolean } = {};

	filterStore = useProductOverviewFilterStore();

	@Emit() filtersChanged(filter?: OverviewFilterViewModel): OverviewFilterViewModel {
		if (filter) filter.isActive = filter.options?.map((x) => x.isActive)?.filter(Boolean).length > 0;
		return filter as OverviewFilterViewModel;
	}

	created(): void {
		this.filterStore.optionFilters.forEach(
			(x) => (this.filterCollapsed[this.filterId(x)] = x.options?.length > 8)
		);
	}

	get filterId(): (filter: OverviewFilterViewModel) => string {
		return (filter) => filter.title || filter.label;
	}

	get isFilterCollapsed(): (filter) => boolean {
		return (filter) => this.filterCollapsed[this.filterId(filter)];
	}

	resetFilters(): void {
		this.filterStore.resetFilters();
		this.filtersChanged();
	}

	toggleFilterCollapse(filter: OverviewFilterViewModel): void {
		this.filterCollapsed[this.filterId(filter)] = !this.filterCollapsed[this.filterId(filter)];
	}
}
</script>

<style lang="scss" scoped>
.product-filters-subheader {
	background: var(--surface-b);
	position: sticky;
	top: 0;
	padding-bottom: 1rem;
	z-index: 2;
}

::v-deep(.product-filter-item) {
	.p-panel-header {
		background: inherit;
	}

	.p-panel-content {
		background: inherit;
	}
}

::v-deep(.product-filter-option) {
	display: flex;

	.p-checkbox-box {
		width: 1.25rem;
		height: 1.25rem;
	}

	.option-icon {
		width: 1.25rem;
		height: 1.25rem;
	}
}
</style>
