<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:closable="true"
		:header="$t('expert.acceptBookingModal.header')"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<p class="lead text-bold mb-0">{{ $t("expert.acceptBookingModal.introTitle") }}</p>
		<p class="lead mb-2">
			{{ $t("expert.acceptBookingModal.intro") }}
		</p>

		<p class="mb-0">
			<span class="text-bold mr-1">{{ $t("common.messages.titleImportant") }}</span>
			<small class="">
				{{ $t("expert.acceptBookingModal.importantMessage") }}
			</small>
		</p>

		<Message class="mb-0 mt-2" :closable="false" severity="info">
			<small class="">
				{{ $t("expert.acceptBookingModal.tip") }}
			</small>
		</Message>

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :label="$t('common.confirm')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import { Component, Emit } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { useExpertStore } from "@/store/expert/expertStore";

@Component
export default class ExpertAcceptBookingModal extends BaseComponent {
	@Emit("submit")
	submitForm(): boolean {
		return true;
	}

	submitted = false;
	isVisible = true;

	expertStore = useExpertStore();

	onCancel(): void {
		this.expertStore.hideModal("AcceptBooking");
	}

	onSubmit(): void {
		this.submitForm();
	}
}
</script>

<style scoped lang="scss"></style>
