import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36ef79ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "display-4"
}
const _hoisted_6 = { class: "faq-items" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.vm.hideInfoAndImage ? 'd-none' : 'col-md-6')
        }, [
          _createVNode(_component_Card, null, {
            header: _withCtx(() => [
              (_ctx.vm.image)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: _ctx.vm.image?.alt
                  }, null, 8, _hoisted_3)), [
                    [_directive_lazy, _ctx.vm.image?.url]
                  ])
                : _createCommentVNode("", true)
            ]),
            content: _withCtx(() => [
              _createElementVNode("p", {
                class: "",
                innerHTML: _ctx.vm.intro
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["pt-2 pt-md-0", _ctx.vm.hideInfoAndImage ? 'col-12' : 'col-md-6 '])
        }, [
          (_ctx.vm.title)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.vm.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.faqItems, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `item_${item.faqId}`,
                class: "faq-item"
              }, [
                _createElementVNode("a", {
                  class: "p-button p-button-arrow p-button-outlined",
                  href: item.url
                }, _toDisplayString(item.title), 9, _hoisted_7)
              ]))
            }), 128))
          ]),
          (_ctx.vm.faqLinkItem)
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 1,
                class: _normalizeClass(
							_ctx.ctaButtonClass(_ctx.vm.linkItemStyle)
								? _ctx.ctaButtonClass(_ctx.vm.linkItemStyle)
								: 'p-button-text p-button-raised p-button-arrow'
						),
                label: _ctx.vm.faqLinkItem.text,
                target: _ctx.vm.faqLinkItem.target,
                title: _ctx.vm.faqLinkItem.title,
                url: _ctx.vm.faqLinkItem.url
              }, null, 8, ["class", "label", "target", "title", "url"]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ], 2))
}