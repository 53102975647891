import axios from '@/types/helpers/apiHelper';
import { AxiosResponse } from 'axios';
import { ContractClient } from '@/types/generated/Api/contractClient';
import { ContractLineClient } from '@/types/generated/Api/contractLineClient';
import { ContractProlongationOption } from '@/types/generated/Api/contractProlongationOption';
import { defineStore } from 'pinia';
import { remove } from 'lodash';

interface ContractStoreState {
	isExtendingContract: boolean;
	isSwitchingContract: boolean;
	isExtendModalVisible: boolean;
	isTradeInModalVisible: boolean;
	prolongationOptions: ContractProlongationOption[];
	selectedProlongationOption: ContractProlongationOption | null;
	selectedTradeInContractLine: ContractLineClient | null;
	switchingContractLines: ContractLineClient[];
}

export type ContractModalType = 'Extend' | 'TradeIn';

export const useContractStore = defineStore('contract', {
	state: () =>
		({
			isExtendingContract: false,
			isSwitchingContract: false,
			isExtendModalVisible: false,
			isTradeInModalVisible: false,
			prolongationOptions: [],
			selectedProlongationOption: null,
			selectedTradeInContractLine: null,
			switchingContractLines: [],
		}) as ContractStoreState,
	getters: {},
	actions: {
		async fetchProlongationOptions(contract: ContractClient) {
			const response: AxiosResponse = await axios.get(
				`/api/contract/prolongationoptions/${contract.contractId}`
			);
			this.prolongationOptions = response.data as ContractProlongationOption[];
			return response;
		},
		addSwitchContract(contractLineClient: ContractLineClient): void {
			if (
				this.switchingContractLines.findIndex((x) => x.serialNumber === contractLineClient.serialNumber) >
				-1
			)
				return;
			this.switchingContractLines.push(contractLineClient);
		},
		removeSwitchContract(contractLineClient: ContractLineClient): void {
			remove(this.switchingContractLines, (x) => x.serialNumber === contractLineClient?.serialNumber);
		},
		startTradeIn(contractLineClient: ContractLineClient): void {
			this.selectedTradeInContractLine = contractLineClient;
			this.showModal('TradeIn');
		},
		cancelTradeIn(): void {
			this.hideModal('TradeIn');
			this.selectedTradeInContractLine = null;
		},
		removeTradeIn(contractLineClient: ContractLineClient): void {
			this.removeSwitchContract(contractLineClient);
			this.selectedTradeInContractLine = null;
			this.hideModal('TradeIn');
		},
		confirmTradeIn(contractLineClient: ContractLineClient): void {
			this.addSwitchContract(contractLineClient);
			this.selectedTradeInContractLine = null;
			this.hideModal('TradeIn');
		},
		cancelExtend(): void {
			this.selectedProlongationOption = null;
			this.isExtendingContract = false;
			this.hideModal('Extend');
		},
		confirmExtend(selectedOption: ContractProlongationOption): void {
			this.selectedProlongationOption = selectedOption;
			this.isExtendingContract = true;
			this.hideModal('Extend');
		},
		showModal(type: ContractModalType) {
			switch (type) {
				case 'Extend':
					this.isExtendModalVisible = true;
					break;
				case 'TradeIn':
					this.isTradeInModalVisible = true;
					break;
			}
		},
		hideModal(type: ContractModalType) {
			switch (type) {
				case 'Extend':
					this.isExtendModalVisible = false;
					break;
				case 'TradeIn':
					this.isTradeInModalVisible = false;
					break;
			}
		},
	},
});
