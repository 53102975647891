<template>
	<a class="expert-card-item" :href="item.productPageUrl" style="text-decoration: none">
		<Card v-if="!isGridLayout" class="expert-list-card w-100">
			<template #header>
				<div class="d-flex justify-content-between px-3 pt-3">
					<div class="d-flex">
						<Avatar class="mr-2 profile-image" :image="item?.expertProfile?.imageUrl" shape="circle" />
						<div class="d-flex flex-column">
							<div class="d-flex align-items-center">
								<h4 v-if="item?.expertProfile?.firstName" class="mb-0 mr-1">
									{{ item?.expertProfile?.firstName }}
								</h4>

								<i
									class="pi pi-map-marker text-primary"
									:title="$t('cart.table.iconLocation_title')"
								/>
								<PopoverIcon :open-delay="500" placement="right">
									<p class="m-0 ml-1 text-bold text-primary">
										{{ `${item.expertProfile?.region}, ${item?.expertProfile?.country}` }}
									</p>
									<template #content>
										<!--										<GMapMap-->
										<!--											:center="{-->
										<!--												lat: item.expertProfile?.latitude,-->
										<!--												lng: item.expertProfile?.longitude,-->
										<!--											}"-->
										<!--											:options="{-->
										<!--												zoomControl: true,-->
										<!--												mapTypeControl: false,-->
										<!--												scaleControl: false,-->
										<!--												streetViewControl: false,-->
										<!--												rotateControl: false,-->
										<!--												fullscreenControl: false,-->
										<!--											}"-->
										<!--											style="width: 300px; height: 300px"-->
										<!--											:zoom="7"-->
										<!--										>-->
										<!--											<GMapMarker-->
										<!--												:clickable="false"-->
										<!--												:draggable="false"-->
										<!--												:position="{-->
										<!--													lat: item.expertProfile?.latitude,-->
										<!--													lng: item.expertProfile?.longitude,-->
										<!--												}"-->
										<!--											/>-->
										<!--										</GMapMap>-->
									</template>
								</PopoverIcon>
							</div>

							<div class="d-flex align-items-center">
								<PopoverIcon :open-delay="500" placement="right">
									<Avatar
										:alt="item?.expertLevel?.displayName"
										class="expert-level"
										:image="item?.expertLevel?.iconUrl"
										shape="square"
									/>

									<template #content>
										<p class="text-bold" v-html="item?.expertLevel.displayName" />
										<div v-html="item?.expertLevel.description" />
									</template>
								</PopoverIcon>
								<p class="text-muted m-0 ml-1 text-preline">
									{{ item.expertProfile?.function }}
								</p>
							</div>

							<div v-if="item.numberOfDaysAvailable || item.numberOfDaysOnRequest" class="mt-1">
								<Chip
									v-if="item.numberOfDaysAvailable > 0"
									class="p-chip-secondary mr-1 mb-1"
									:label="$tc('expert.daysAvailable', item.numberOfDaysAvailable)"
								/>
								<Chip
									v-if="item.numberOfDaysOnRequest > 0"
									class="p-chip-primary"
									:label="$t('expert.availableOnRequest')"
								/>
							</div>
						</div>
					</div>
					<div class="text-lg-right">
						<p v-if="item.hourlyRate" class="lead text-muted">
							<i18n-t keypath="common.pricePerHour">
								<b class="mr-1 text-primary">{{ formatCurrency(item.hourlyRate) }}</b>
							</i18n-t>
						</p>
						<div v-if="item.expertProfile?.reviewScoreAverage" class="mt-1">
							<Rating
								v-model="item.expertProfile.reviewScoreAverage"
								:cancel="false"
								class="rating-lg"
								:readonly="true"
							/>
							<small class="mb-0">
								({{ $tc("expert.reviewCount", item.expertProfile?.reviews?.length) }})
							</small>
						</div>
					</div>
				</div>
			</template>
			<template #content>
				<div class="row h-100">
					<div class="col-12 mt-2 mt-lg-0 px-3 d-flex justify-content-start flex-row">
						<Inplace @open.stop.prevent>
							<template #display>
								<Chip
									v-for="(expertise, index) in getVisibleExpertises(
										item.expertProfile?.expertises
									)"
									:key="index"
									class="p-chip-secondary mr-1 mb-1"
									:label="expertise.name"
								/>
								<Button
									v-if="item.expertProfile?.expertises?.length > maxExpertisesToShow"
									class="p-button-text p-button-rounded p-button-secondary mb-1"
									:label="$t('expert.button.showAllExpertises')"
								/>
							</template>
							<template #content>
								<Chip
									v-for="(expertise, index) in item.expertProfile?.expertises"
									:key="index"
									class="p-chip-secondary mr-1 mb-1"
									:label="expertise.name"
								/>
							</template>
						</Inplace>
					</div>
				</div>
			</template>
		</Card>

		<Card v-else class="expert-grid-card" :class="{ 'cross-sell': isCrossSell }">
			<template #header>
				<div class="d-flex flex-column h-100">
					<div class="d-flex flex-wrap">
						<Avatar class="profile-image" :image="item?.expertProfile?.imageUrl" shape="circle" />
						<div class="d-flex flex-column flex-1 ml-2">
							<div class="d-flex align-items-center justify-content-between flex-wrap-reverse">
								<h3 v-if="item?.expertProfile?.firstName" class="mb-0">
									{{ item?.expertProfile?.firstName }}
								</h3>
								<Tag
									v-if="isCrossSell"
									class="mb-auto ml-auto"
									:value="$t('enum.itemProductType.' + item.productType)"
								/>
							</div>
							<div class="d-flex align-items-center">
								<PopoverIcon :open-delay="500" placement="right">
									<Avatar
										:alt="item?.expertLevel?.displayName"
										class="expert-level"
										:image="item?.expertLevel?.iconUrl"
										shape="square"
									/>
									<template #content>
										<p class="text-bold" v-html="item?.expertLevel?.displayName" />
										<div v-html="item?.expertLevel?.description" />
									</template>
								</PopoverIcon>
								<p class="text-muted m-0 ml-1 text-preline">
									{{ item?.expertProfile?.function }}
								</p>
							</div>
							<div class="d-flex align-items-center">
								<i
									class="pi pi-map-marker text-primary"
									:title="$t('cart.table.iconLocation_title')"
								/>
								<p v-if="isCrossSell" class="m-0 ml-1 text-bold text-primary">
									{{ `${item.expertProfile?.region}, ${item?.expertProfile?.country}` }}
								</p>
								<PopoverIcon v-else :open-delay="500" placement="right">
									<p class="m-0 ml-1 text-bold text-primary">
										{{ `${item.expertProfile?.region}, ${item?.expertProfile?.country}` }}
									</p>
									<template #content>
										<GMapMap
											:center="{
												lat: item.expertProfile?.latitude,
												lng: item.expertProfile?.longitude,
											}"
											:options="{
												zoomControl: true,
												mapTypeControl: false,
												scaleControl: false,
												streetViewControl: false,
												rotateControl: false,
												fullscreenControl: false,
											}"
											style="width: 300px; height: 300px"
											:zoom="7"
										>
											<GMapMarker
												:clickable="false"
												:draggable="false"
												:position="{
													lat: item.expertProfile?.latitude,
													lng: item.expertProfile?.longitude,
												}"
											/>
										</GMapMap>
									</template>
								</PopoverIcon>
							</div>
						</div>
					</div>

					<div :class="isCrossSell ? 'mt-auto' : 'mt-2'">
						<p
							v-if="item.hourlyRate"
							class="lead text-muted mb-0"
							:class="{ 'text-right': isCrossSell }"
						>
							<i18n-t keypath="common.pricePerHour">
								<b class="mr-1 text-primary">{{ formatCurrency(item.hourlyRate) }}</b>
							</i18n-t>
						</p>
						<div v-if="item.expertProfile?.reviewScoreAverage" class="mt-1">
							<Rating
								v-model="item.expertProfile.reviewScoreAverage"
								:cancel="false"
								:readonly="true"
							/>
							<small class="text-right mb-0">
								({{ $tc("expert.reviewCount", item.expertProfile?.reviews.length) }})
							</small>
						</div>
						<div
							v-if="(item.numberOfDaysAvailable || item.numberOfDaysOnRequest) && !isCrossSell"
							class="mt-1"
						>
							<Chip
								v-if="item.numberOfDaysAvailable > 0"
								class="p-chip-secondary mr-1 mb-1"
								:label="$tc('expert.daysAvailable', item.numberOfDaysAvailable)"
							/>
							<Chip
								v-if="item.numberOfDaysOnRequest > 0"
								class="p-chip-primary"
								:label="$t('expert.availableOnRequest')"
							/>
						</div>
					</div>
				</div>
			</template>
			<template #content>
				<div class="">
					<Divider class="m-0" />
					<p class="profile-description">{{ item?.expertProfile?.shortDescription }}</p>
					<Inplace @open.stop.prevent>
						<template #display>
							<Chip
								v-for="(expertise, index) in getVisibleExpertises(item.expertProfile?.expertises)"
								:key="index"
								class="p-chip-secondary mr-1 mb-1"
								:label="expertise.name"
							/>
							<Button
								v-if="item.expertProfile.expertises?.length > maxExpertisesToShow"
								class="p-button-text p-button-rounded p-button-secondary mb-1"
								:label="$t('expert.button.showAllExpertises')"
							/>
						</template>
						<template #content>
							<Chip
								v-for="(expertise, index) in item.expertProfile?.expertises"
								:key="index"
								class="p-chip-secondary mr-1 mb-1"
								:label="expertise.name"
							/>
						</template>
					</Inplace>
				</div>
			</template>
		</Card>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import Rating from "primevue/rating";
import Tag from "primevue/tag";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertItemViewModel } from "@/types/models/expert/expertItemViewModel";
import { PropType } from "vue";
import { take } from "lodash";

@Component({ components: { Chip, Divider, Rating, Tag } })
export default class ExpertOverviewItemCard extends BaseComponent {
	@Prop({ type: Object as PropType<ExpertItemViewModel>, required: true, default: {} })
	item!: ExpertItemViewModel;

	@Prop({
		type: String,
		required: true,
		default: "grid",
	})
	layout!: string;

	@Prop({ type: Boolean, default: false, required: false })
	isCrossSell!: boolean;

	get isGridLayout(): boolean {
		return this.layout === "grid";
	}

	get maxExpertisesToShow(): number {
		return this.isGridLayout ? 3 : 7;
	}

	getVisibleExpertises(expertises: string[]): string[] {
		return take(expertises, this.maxExpertisesToShow);
	}
}
</script>

<style scoped lang="scss">
.expert-card-item {
	position: relative;
	height: 100%;
	display: flex;

	.expert-level {
		width: 1.75rem;
		height: 1.75rem;
	}

	:deep(.profile-image) {
		width: 4rem;
		height: 4rem;
		flex-shrink: 0;

		img {
			object-fit: cover;
		}
	}

	:deep(.p-chip) {
		font-size: 0.9rem;
		font-weight: 400;
	}

	:deep(.expert-grid-card) {
		.profile-description {
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		&.cross-sell {
			.p-card-header {
				height: 100%;
				min-height: 1px;
			}
			.p-card-body {
				display: none !important;
			}
		}

		&.p-card {
			border: var(--surface-border);
			transition: all 0.4s;
			position: relative;
			height: 100%;
			flex: 1;

			&:hover {
				box-shadow:
					8px 4px 10px -1px rgb(0 0 0 / 12%),
					0px 4px 10px 0px rgb(0 0 0 / 8%),
					0px 1px 4px 4px rgb(0 0 0 / 8%);
				cursor: pointer;
			}

			.p-card-body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
			}

			.p-card-header {
				padding: 1rem;
				min-height: 200px;
			}
		}

		.p-card-content {
			padding: 0;
		}
	}

	:deep(.expert-list-card) {
		margin: 0.75rem;

		.profile-description {
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		&.p-card {
			position: relative;
			border: var(--surface-border);
			transition: all 0.4s;

			&:hover {
				box-shadow:
					4px 4px 2px 0 rgb(0 0 0 / 20%),
					0 1px 4px 0 rgb(0 0 0 / 14%),
					0 2px 8px 0 rgb(0 0 0 / 12%);
				cursor: pointer;
			}

			.p-card-body {
				padding: 0 1rem;
			}

			.p-card-content {
				height: 100%;
			}
		}
	}
}
</style>
