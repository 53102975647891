<template>
	<form>
		<div class="form-row">
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionPersonalForm'].firstName?.required }">
					{{ $t("common.forms.lblFirstName") }}
				</label>
				<InputText
					v-model="v$['askAQuestionPersonalForm'].firstName.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionPersonalForm'].firstName) }"
					type="text"
				/>
				<small v-if="shouldShowError(v$['askAQuestionPersonalForm'].firstName)" class="p-error">
					{{ v$["askAQuestionPersonalForm"].firstName?.required?.$message }}
				</small>
			</div>
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionPersonalForm'].lastName?.required }">
					{{ $t("common.forms.lblLastName") }}
				</label>
				<InputText
					v-model="v$['askAQuestionPersonalForm'].lastName.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionPersonalForm'].lastName) }"
					type="text"
				/>
				<small v-if="shouldShowError(v$['askAQuestionPersonalForm'].lastName)" class="p-error">
					{{ v$["askAQuestionPersonalForm"].lastName?.required?.$message }}
				</small>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionPersonalForm'].email?.required }">
					{{ $t("common.forms.lblEmail") }}
				</label>
				<InputText
					v-model="v$['askAQuestionPersonalForm'].email.$model"
					class="mr-1 username-input"
					name="email"
					type="email"
				/>
				<span v-if="shouldShowError(v$['askAQuestionPersonalForm'].email)">
					<small v-if="!v$['askAQuestionPersonalForm'].email.$model" class="p-error">
						{{ v$["askAQuestionPersonalForm"].email?.required?.$message }}
					</small>
					<small v-else-if="v$['askAQuestionPersonalForm'].email.$model" class="p-error">
						{{ v$["askAQuestionPersonalForm"].email.email?.$message }}
					</small>
				</span>
			</div>
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionPersonalForm'].phoneNumber?.required }">
					{{ $t("common.forms.lblPhoneNumber") }}
				</label>
				<vue-tel-input
					v-model="v$['askAQuestionPersonalForm'].phoneNumber.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionPersonalForm'].phoneNumber) }"
					:input-options="{ placeholder: '' }"
					@on-input="onPhoneInputBlur"
					@validate="onPhoneInputValidate"
				/>
				<span v-if="shouldShowError(v$['askAQuestionPersonalForm'].phoneNumber)">
					<small v-if="!v$['askAQuestionPersonalForm'].phoneNumber.$model" class="p-error">
						{{ v$["askAQuestionPersonalForm"].phoneNumber?.required?.$message }}
					</small>
					<small v-else-if="v$['askAQuestionPersonalForm'].phoneNumber.$model" class="p-error">
						{{ v$["askAQuestionPersonalForm"].phoneNumber.validPhone?.$message }}
					</small>
				</span>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionPersonalForm'].company?.required }">
					{{ $t("common.forms.lblCompanyName") }}
				</label>
				<InputText
					v-model="v$['askAQuestionPersonalForm'].company.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionPersonalForm'].company) }"
					type="text"
				/>
				<small v-if="shouldShowError(v$['askAQuestionPersonalForm'].company)" class="p-error">
					{{ v$["askAQuestionPersonalForm"].company?.required?.$message }}
				</small>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop, VModel } from "vue-facing-decorator";
import { Validation } from "@vuelidate/core";
import { IPhoneObject } from "@/types/models/common/phoneObject.interface";

@Component
export default class AskAQuestionPersonalForm extends BaseComponent {
	@Prop({ type: Boolean, required: true, default: false }) submitted!: boolean;
	@VModel() v$!: Validation;

	@Emit() phoneObjectValidationChange(): boolean {
		return this.phoneObject?.valid;
	}

	phoneObject!: IPhoneObject;

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onPhoneInputValidate(phoneObject: IPhoneObject): void {
		if (!this.v$["askAQuestionPersonalForm"]) return;
		this.phoneObject = phoneObject;
		if (phoneObject.number) this.v$["askAQuestionPersonalForm"].phoneNumber?.$validate();
		else this.v$["askAQuestionPersonalForm"].phoneNumber?.$reset();
		this.phoneObjectValidationChange();
	}

	/// Make sure phone input is formatted on blur
	onPhoneInputBlur(_, phoneObject): void {
		if (this.phoneObject?.valid) {
			this.v$["askAQuestionPersonalForm"].phoneNumber.$model = phoneObject.formatted;
		}
	}
}
</script>

<style scoped lang="scss"></style>
