<template>
	<div v-if="isInvoice" class="container invoice-container">
		<div class="row">
			<div class="col">
				<h1 class="">{{ $t("common.invoice") }}</h1>
				<div class="row">
					<div class="col-4">
						<p class="mb-1 text-bold">{{ $t("cart.lblShippingAddress") }}</p>
						<p class="mb-0" v-text="checkoutForm?.companyForm?.companyName" />
						<p class="mb-0" v-text="checkoutForm?.companyForm?.street" />
						<p class="mb-0">
							<span v-text="checkoutForm?.companyForm?.postalCode" />
							<span class="ml-1" v-text="checkoutForm?.companyForm?.city" />
						</p>
						<p class="mb-0" v-text="checkoutForm?.companyForm?.country" />
						<div class="mt-2">
							<p class="mb-1 text-bold">{{ $t("cart.lblContactPerson") }}</p>
							<p>
								{{
									`${checkoutForm?.personalForm?.firstName} ${checkoutForm?.personalForm?.lastName}`
								}}
							</p>
						</div>
					</div>
					<div class="col-4">
						<template
							v-if="checkoutForm?.billingAddressForm && checkoutForm?.useDifferentInvoiceAddress"
						>
							<p class="mb-1 text-bold">{{ $t("cart.lblBillingAddress") }}</p>
							<p class="mb-0" v-text="checkoutForm?.billingAddressForm?.street" />
							<p class="mb-0">
								<span v-text="checkoutForm?.billingAddressForm?.postalCode" />
								<span class="ml-1" v-text="checkoutForm?.billingAddressForm?.city" />
							</p>
							<p class="mb-0" v-text="checkoutForm?.billingAddressForm?.countryCode" />
						</template>

						<template v-else>
							<p class="mb-1 text-bold">{{ $t("cart.lblBillingAddress") }}</p>
							<p class="mb-0" v-text="checkoutForm?.companyForm?.companyName" />
							<p class="mb-0" v-text="checkoutForm?.companyForm?.street" />
							<p class="mb-0">
								<span v-text="checkoutForm?.companyForm?.postalCode" />
								<span class="ml-1" v-text="checkoutForm?.companyForm?.city" />
							</p>
							<p class="mb-0" v-text="checkoutForm?.companyForm?.country" />
						</template>

						<div class="mt-2">
							<p class="mb-1 text-bold">{{ $t("cart.lblPaymentCondition") }}</p>
							<p>
								{{
									$t(
										`checkout.paymentMethod.paymentCondition.${checkoutForm.invoicePaymentConditionCode}`
									)
								}}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-4 ml-auto">
				<svg height="70">
					<use xlink:href="#cadac-group-logo" />
				</svg>
				<div v-if="checkoutForm?.cadacCompanyInfo">
					<p class="mb-0" v-text="checkoutForm?.cadacCompanyInfo?.name" />
					<p class="mb-0 text-preline" v-text="checkoutForm?.cadacCompanyInfo?.formattedAddress" />
					<p class="mb-2" v-text="checkoutForm?.cadacCompanyInfo?.phoneNumber" />

					<p class="mb-0 text-nowrap">
						<span class="mr-1">{{ $t("common.forms.lblCocNumber") }}:</span>
						<span>{{ checkoutForm?.cadacCompanyInfo?.cocNumber }}</span>
					</p>
					<p class="mb-0 text-nowrap">
						<span class="mr-1">{{ $t("common.forms.lblVatNumber") }}:</span>
						<span>{{ checkoutForm?.cadacCompanyInfo?.vatNumber }}</span>
					</p>
					<p class="mb-0 text-nowrap">
						<span class="mr-1">{{ $t("common.forms.lblIban") }}:</span>
						<span>{{ checkoutForm?.cadacCompanyInfo?.iban }}</span>
					</p>
					<p class="mb-0 text-nowrap">
						<span class="mr-1">{{ $t("common.forms.lblBic") }}: </span>
						<span>{{ checkoutForm?.cadacCompanyInfo?.bic }}</span>
					</p>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<CartOverview :cart-client="cartClient" :is-editable="false" />
			</div>
		</div>

		<div class="row print-footer">
			<div class="col-8 ml-auto pr-2 mt-3 mb-4">
				<PriceSummary :vm="priceSummary" />
				<small>{{ $t("cart.lblPrintDisclaimer") }}</small>
			</div>

			<div class="col-6 print-bottom">
				<div class="border border-primary px-2 pt-2 pb-0 mt-2">
					<div v-if="cartClient?.totalWalletAmountEarned">
						<p>
							<i18n-t keypath="cart.lblWalletAmountEarned">
								<b>{{ formatCurrency(cartClient?.totalWalletAmountEarned) }} </b>
							</i18n-t>
						</p>
						<hr class="my-0" />
					</div>
					<div class="questions-block">
						<h4 class="text-primary text-bold mb-1 mt-2">
							{{ $t("common.support.anyQuestions") }}
						</h4>
						<div class="d-flex align-items-end">
							<small class="mb-1">
								<i18n-t keypath="common.support.contactUsAt">
									<a :href="'tel:' + $t('common.support.phoneNumber')">
										{{ $t("common.support.phoneNumber") }}
									</a>
								</i18n-t>
							</small>
						</div>
					</div>
				</div>
			</div>
			<div class="col-5 ml-auto pr-2 mt-2 print-bottom">
				<p class="mb-0 text-preline">{{ $t("cart.lblPrintSignature") }}</p>
				<div style="height: 200px"></div>
				<p class="mb-0">
					{{ `${checkoutForm?.personalForm?.firstName} ${checkoutForm?.personalForm?.lastName}` }}
				</p>
				<p class="mb-0">{{ checkoutForm?.companyForm?.companyName }}</p>
			</div>
		</div>
	</div>
	<div v-else class="row">
		<div class="col-12">
			<svg class="print-top-right" height="75">
				<use xlink:href="#cadacstore-logo" />
			</svg>
			<h1>{{ title }}</h1>
			<CartOverview :cart-client="cartClient" :is-editable="false" />
			<div class="col-8 ml-auto pr-2 mt-2 print-footer">
				<hr />
				<PriceSummary :vm="priceSummary" />
				<small>{{ $t("cart.lblPrintDisclaimer") }}</small>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartOverview from "@/components/commerce/cart/cartOverview.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import { CartClient } from "@/types/models/cart/cartClient";
import { CheckoutForm } from "@/types/models/form/checkoutForm";
import { Component, Prop } from "vue-facing-decorator";
import { CouponCode } from "@/types/generated/Api/couponCode";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { PropType } from "vue";
import { sum } from "lodash";

@Component({ components: { CartOverview, PriceSummary } })
export default class CartPrintPreview extends BaseComponent {
	@Prop({ type: Object as PropType<CartClient>, required: true, default: {} }) cartClient!: CartClient;
	@Prop({ type: Object as PropType<CheckoutForm>, required: false, default: {} }) checkoutForm!: CheckoutForm;
	@Prop({ type: String, required: false, default: "" }) title!: string;
	@Prop({ type: Boolean, required: false, default: false }) isInvoice!: boolean;

	get priceSummary(): IPriceSummary {
		if (!this.cartClient) return {} as IPriceSummary;
		const {
			appliedCouponCodes,
			totalBasePrice,
			subtotalWithoutVat,
			personalDiscount,
			totalPrice,
			totalInstallationPrice,
			vatPercentage,
			vatAmount,
			vatSituation,
		} = this.cartClient;
		const couponDiscount = sum(appliedCouponCodes?.map((x) => x.discount));
		const discountLines = new Map<string, number>();
		appliedCouponCodes?.forEach((coupon: CouponCode) => {
			const name = coupon.isCadacWallet ? this.$t("cart.lblCadacWallet") : coupon.name;
			discountLines.set(name, coupon.discount);
		});

		return {
			totalBasePrice,
			discountLines: discountLines,
			totalCouponDiscount: couponDiscount,
			totalDiscount: personalDiscount + couponDiscount,
			totalPersonalPrice: subtotalWithoutVat,
			totalInstallPrice: totalInstallationPrice,
			totalPrice,
			vatPercentage,
			vatAmount,
			vatSituation,
		} as IPriceSummary;
	}
}
</script>

<style lang="scss" scoped>
@media print {
	.invoice-container {
	}

	.print-top-right {
		position: fixed;
		top: 0;
		right: 0;
	}

	.print-top-left {
		position: fixed;
		top: 0;
		left: 0;
	}

	.print-footer {
		page-break-inside: avoid;
		page-break-before: auto;
	}

	::v-deep(.cart-contract-table, .cart-training-table) {
		page-break-inside: avoid;
		page-break-after: auto;
	}

	::v-deep(.p-datatable-header) {
		padding-left: 0;
	}

	::v-deep(:not(.cart-contract-table, .cart-training-table) div.p-datatable.p-component) {
		page-break-before: auto;
		page-break-inside: avoid;
		page-break-after: auto;

		a {
			text-decoration: none;
		}
	}
}
</style>
