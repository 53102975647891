import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55e99f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.url,
    role: "button",
    target: _ctx.target,
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode(_component_Button, _mergeProps(_ctx.$attrs, {
      class: _ctx.$attrs.class
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 16, ["class"])
  ], 8, _hoisted_1))
}