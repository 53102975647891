import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-md-6 col-xxl-5" }
const _hoisted_3 = { class: "form-group col-md-6 col-xxl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Password = _resolveComponent("Password")!
  const _component_InputWithValidation = _resolveComponent("InputWithValidation")!

  return (_openBlock(), _createElementBlock("form", {
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formStateChanged && _ctx.formStateChanged(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['password'].required,
          label: _ctx.$t('myCadac.accountSettings.lblPassword'),
          name: "password"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_Password, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass(["w-100", { 'p-invalid': field.errors?.length }]),
              feedback: false,
              name: field.name,
              "toggle-mask": "",
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['passwordConfirm'].required,
          label: _ctx.$t('myCadac.accountSettings.lblConfirmPassword'),
          name: "passwordConfirm"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_Password, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass(["w-100", { 'p-invalid': field.errors?.length }]),
              feedback: false,
              name: field.name,
              "toggle-mask": "",
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ])
  ], 32))
}