import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-group col-12 col-xl-6" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-12 col-xl-6" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "form-row" }
const _hoisted_9 = { class: "form-group col-12" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = {
  key: 1,
  class: "p-error"
}
const _hoisted_13 = {
  key: 2,
  class: "p-error"
}
const _hoisted_14 = { class: "form-row" }
const _hoisted_15 = { class: "form-group col-12" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}
const _hoisted_18 = {
  key: 1,
  class: "p-error"
}
const _hoisted_19 = {
  key: 2,
  class: "p-error"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 2 }
const _hoisted_22 = {
  class: "text-preline",
  style: {"word-break":"break-word"}
}
const _hoisted_23 = {
  class: "text-preline",
  style: {"word-break":"break-word"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertSettingsOverviewForm'].expertFunction?.required })
              }, _toDisplayString(_ctx.$t("myCadac.expertSettings.lblFunction")), 3),
              _createVNode(_component_InputText, {
                modelValue: _ctx.v$['expertSettingsOverviewForm'].expertFunction.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['expertSettingsOverviewForm'].expertFunction.$model) = $event)),
                class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].expertFunction) }),
                readonly: !_ctx.isEditing,
                type: "text"
              }, null, 8, ["modelValue", "class", "readonly"]),
              (_ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].expertFunction))
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].expertFunction?.required?.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertSettingsOverviewForm'].shortDescriptionTitle?.required })
              }, _toDisplayString(_ctx.$t("myCadac.expertSettings.lblHeader")), 3),
              _createVNode(_component_InputText, {
                modelValue: _ctx.v$['expertSettingsOverviewForm'].shortDescriptionTitle.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['expertSettingsOverviewForm'].shortDescriptionTitle.$model) = $event)),
                class: _normalizeClass({
						'p-invalid': _ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].shortDescriptionTitle),
					}),
                readonly: !_ctx.isEditing,
                type: "text"
              }, null, 8, ["modelValue", "class", "readonly"]),
              (_ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].shortDescriptionTitle))
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].shortDescriptionTitle?.required?.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertSettingsOverviewForm'].shortDescription?.required })
              }, _toDisplayString(_ctx.$t("myCadac.expertSettings.lblIntroduction")), 3),
              _createVNode(_component_Textarea, {
                modelValue: _ctx.v$['expertSettingsOverviewForm'].shortDescription.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['expertSettingsOverviewForm'].shortDescription.$model) = $event)),
                "auto-resize": true,
                class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].shortDescription) }),
                cols: "30",
                readonly: !_ctx.isEditing,
                rows: "5"
              }, null, 8, ["modelValue", "class", "readonly"]),
              (_ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].shortDescription))
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    (!_ctx.v$['expertSettingsOverviewForm'].shortDescription.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].shortDescription?.required?.$message), 1))
                      : (_ctx.v$['expertSettingsOverviewForm'].shortDescription.minLength.$invalid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].shortDescription.minLength?.$message), 1))
                        : (_ctx.v$['expertSettingsOverviewForm'].shortDescription.maxLength.$invalid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].shortDescription.maxLength?.$message), 1))
                          : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertSettingsOverviewForm'].description?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblDescription")), 3),
              _createVNode(_component_Textarea, {
                modelValue: _ctx.v$['expertSettingsOverviewForm'].description.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$['expertSettingsOverviewForm'].description.$model) = $event)),
                "auto-resize": true,
                class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].description) }),
                cols: "30",
                readonly: !_ctx.isEditing,
                rows: "5"
              }, null, 8, ["modelValue", "class", "readonly"]),
              (_ctx.shouldShowError(_ctx.v$['expertSettingsOverviewForm'].description))
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                    (!_ctx.v$['expertSettingsOverviewForm'].description.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].description?.required?.$message), 1))
                      : (_ctx.v$['expertSettingsOverviewForm'].description.minLength.$invalid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_18, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].description.minLength?.$message), 1))
                        : (_ctx.v$['expertSettingsOverviewForm'].description.maxLength.$invalid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_19, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].description.maxLength?.$message), 1))
                          : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode(_component_Button, {
            class: "",
            disabled: !_ctx.canSubmit,
            label: _ctx.$t(`common.save`),
            onClick: _ctx.onSubmit
          }, null, 8, ["disabled", "label", "onClick"])
        ]))
      : (_openBlock(), _createElementBlock("dl", _hoisted_21, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.expertSettings.lblFunction")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].expertFunction.$model || "-"), 1),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.expertSettings.lblHeader")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].shortDescriptionTitle.$model || "-"), 1),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.expertSettings.lblIntroduction")), 1),
          _createElementVNode("dd", _hoisted_22, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].shortDescription.$model || "-"), 1),
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.forms.lblDescription")), 1),
          _createElementVNode("dd", _hoisted_23, _toDisplayString(_ctx.v$["expertSettingsOverviewForm"].description.$model || "-"), 1)
        ]))
  ], 64))
}