export enum FilterTypes {
	//standard filters
	Query,
	MultiSelect,
	DateRange,

	//expert specific filters
	WorkOnLocation,
	WorkRemote,

	//training specific filters
	CustomTraining,
	TrainingMonths,
}
