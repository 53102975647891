<template>
	<Card class="expert-personal-info-card mb-3">
		<template #header>
			<div
				class="d-flex flex-column flex-sm-row justify-content-center align-items-center justify-content-sm-start p-3"
			>
				<Avatar
					class="mr-0 mr-sm-2 mb-2 mb-sm-0 profile-image"
					:image="expertProfile.imageUrl"
					shape="circle"
					size="xlarge"
				/>
				<div class="d-flex flex-column justify-content-center justify-content-sm-start">
					<h3 v-if="expertProfile?.firstName" class="text-primary text-center text-sm-left mb-1">
						{{ expertProfile?.firstName }}
					</h3>
					<div class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<PopoverIcon :open-delay="500" placement="right">
							<Avatar
								:alt="expertProduct?.displayName"
								class="expert-level"
								:image="expertProduct?.iconUrl"
								shape="circle"
							/>
							<template #content>
								<p class="text-bold" v-html="expertProduct.displayName" />
								<div v-html="expertProduct.description" />
							</template>
						</PopoverIcon>

						<p class="m-0 ml-1">{{ expertProfile?.function }}</p>
					</div>
					<div class="d-flex align-items-center mt-1">
						<i class="pi pi-map-marker text-primary" :title="$t('cart.table.iconLocation_title')" />

						<PopoverIcon :open-delay="500" placement="right">
							<p class="m-0 ml-1 text-bold text-primary">
								{{ `${expertProfile?.region}, ${expertProfile?.country}` }}
							</p>
							<template #content>
								<GMapMap
									:center="{ lat: expertProfile?.latitude, lng: expertProfile?.longitude }"
									:options="{
										zoomControl: true,
										mapTypeControl: false,
										scaleControl: false,
										streetViewControl: false,
										rotateControl: false,
										fullscreenControl: false,
									}"
									style="width: 350px; height: 350px"
									:zoom="7"
								>
									<GMapMarker
										:clickable="false"
										:draggable="false"
										:position="{
											lat: expertProfile?.latitude,
											lng: expertProfile?.longitude,
										}"
									/>
								</GMapMap>
							</template>
						</PopoverIcon>
					</div>
				</div>
			</div>
			<Divider class="m-0" />
		</template>
		<template #content>
			<div class="px-2">
				<p class="lead text-bold mb-0">{{ expertProfile?.shortDescriptionTitle }}</p>
				<p class="text-preline mb-2" style="word-break: break-word">
					{{ expertProfile?.shortDescription }}
				</p>
				<p class="text-preline" style="word-break: break-word">
					{{ expertProfile?.description }}
				</p>
				<Divider />
				<div class="d-flex flex-wrap align-items-center">
					<p class="lead mb-0">{{ $t(`expert.languages`) }} :</p>
					<Chip
						v-for="(language, index) in expertProfile?.spokenLanguages"
						:key="index"
						class="p-chip-primary mx-1 mb-1"
						:label="language"
					/>
				</div>
			</div>
		</template>
	</Card>

	<Card v-if="expertProfile?.expertises?.length" class="expert-expertises-card mb-3">
		<template #content>
			<div class="px-2">
				<h4>{{ $t(`expert.expertises`) }}</h4>
				<div>
					<Chip
						v-for="(expertise, index) in expertProfile?.expertises"
						:key="index"
						class="p-chip-secondary mr-1 mb-1"
						:label="expertise.name"
					/>
				</div>
			</div>
		</template>
	</Card>

	<Card v-if="expertProfile?.reviews.length" class="expert-reviews-card mb-3">
		<template #content>
			<div class="px-2 position-relative">
				<div class="d-flex justify-content-between flex-wrap">
					<div class="d-flex flex-column justify-content-between">
						<div>
							<h4>{{ $t(`expert.reviews`) }}</h4>
						</div>
						<div>
							<div class="d-flex align-items-center">
								<h1 class="mb-0">{{ expertProfile.reviewScoreAverage }}</h1>
								<p class="mb-0 ml-1">/ 5</p>
							</div>
							<Rating
								v-model="expertProfile.reviewScoreAverage"
								:cancel="false"
								class="rating-lg"
								:readonly="true"
							/>
							<p class="lead mb-0">{{ $tc("expert.reviewCount", expertProfile?.reviews.length) }}</p>
						</div>
					</div>
					<Chart
						class="review-chart"
						:data="reviewChartData"
						:height="225"
						:options="reviewChartOptions"
						type="doughnut"
						:width="225"
					/>
				</div>
				<hr />
				<div class="review-area">
					<div v-for="(item, index) in sortedReviews" :key="index">
						<div class="mb-3">
							<div class="d-flex align-items-center flex-wrap">
								<Rating v-model="item.score" :cancel="false" class="mr-1" :readonly="true" />
								<p class="mb-0 lead font-weight-bold">{{ item.title }}</p>
							</div>
							<p class="lead">{{ item.reviewerDisplayName }} - {{ formatElapsedTime(item.date) }}</p>
							<p class="text-preline">{{ item.comment }}</p>
							<div>
								<Chip
									v-for="(expertise, index) in expertProfile?.reviewTags[item.id]"
									:key="index"
									class="p-chip-secondary mr-1 mb-1"
									:label="expertise"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</Card>

	<Card v-if="expertProfile?.experiences?.length" class="expert-experience-card mb-3">
		<template #content>
			<div class="px-2">
				<h4>{{ $t(`expert.experience`) }}</h4>
				<Timeline
					v-if="!isSmallScreen"
					:align="expertProfile?.experiences?.length > 1 ? 'alternate' : 'left'"
					:value="expertProfile?.experiences"
				>
					<template #marker>
						<span class="custom-marker shadow-2">
							<i class="pi pi-briefcase text-primary" style="font-size: 1.5rem"></i>
						</span>
					</template>
					<template #content="slotProps">
						<Card class="mb-2 ml-2 experience-card">
							<template #title>
								{{ slotProps.item.title }}
							</template>
							<template #subtitle>
								{{
									`${slotProps.item.company} ${getExperienceDate(
										slotProps.item.experienceStartDate,
										slotProps.item.experienceEndDate
									)}`
								}}
							</template>
							<template #content>
								<p class="text-preline" style="word-break: break-word">
									{{ slotProps.item.description }}
								</p>
							</template>
						</Card>
					</template>
				</Timeline>
				<template v-else>
					<Card
						v-for="experience in expertProfile?.experiences"
						:key="experience.id"
						class="mb-2 experience-card"
					>
						<template #title>
							{{ experience.title }}
						</template>
						<template #subtitle>
							{{
								`${experience.company} ${getExperienceDate(
									experience.experienceStartDate,
									experience.experienceEndDate
								)}`
							}}
						</template>
						<template #content>
							<p class="text-preline" style="word-break: break-word">
								{{ experience.description }}
							</p>
						</template>
					</Card>
				</template>
			</div>
		</template>
	</Card>
</template>

<script lang="ts">
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import Timeline from "primevue/timeline";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertProductViewModel } from "@/types/models/expert/expertProductViewModel";
import { ExpertProfileViewModel } from "@/types/models/expert/expertProfileViewModel";
import { differenceInMonths } from "date-fns";
import Chart from "primevue/chart";
import { ChartData, ChartOptions } from "chart.js";
import Rating from "primevue/rating";
import { ExpertReviewViewModel } from "@/types/models/expert/expertReviewViewModel";

@Component({
	components: {
		Divider,
		Chip,
		Timeline,
		Chart,
		Rating,
	},
})
export default class ExpertProfile extends BaseComponent {
	@Prop({ type: Object, required: true }) expertProfile!: ExpertProfileViewModel;
	@Prop({ type: Object, required: true }) expertProduct!: ExpertProductViewModel;

	getExperienceDate(startDate: Date, endDate: Date): string {
		const diffInMonths = differenceInMonths(endDate ? new Date(endDate) : new Date(), new Date(startDate));
		const years = Math.floor(diffInMonths / 12);
		const months = diffInMonths % 12;

		const formattedYears = years ? this.$tc("expert.date.years", years) : "";
		const formattedMonths = months ? this.$tc("expert.date.months", months) : "";

		const startDateLabel = this.formatShortDate(startDate);
		const endDateLabel = this.formatShortDate(endDate);
		const presentLabel = this.$t("expert.present");

		return `${startDateLabel} - ${endDate ? endDateLabel : presentLabel} (${formattedYears}${
			formattedMonths && formattedYears ? " " : ""
		}${formattedMonths})`;
	}

	get sortedReviews(): ExpertReviewViewModel[] {
		const reviews = this.expertProfile.reviews;

		reviews.sort(function (a, b) {
			return new Date(b.date).valueOf() - new Date(a.date).valueOf();
		});

		return reviews;
	}

	get reviewChartData(): ChartData {
		const fiveStars = [1, 2, 3, 4, 5];
		return {
			labels: fiveStars.map((x) => x.toString()),
			datasets: [
				{
					data: fiveStars.map((x) => {
						return this.expertProfile.groupedReviewScores[x] || 0;
					}),
					backgroundColor: ["#f29f94", "#fad9d4", "#e9ccdb", "#c880a5", "#91004b"],
				},
			],
		};
	}

	get reviewChartOptions(): ChartOptions {
		return {
			plugins: {
				legend: {
					onClick: () => null,
					labels: {
						color: "#495057",
						boxWidth: 20,
						boxHeight: 20,
						font: {
							size: 12,
						},
					},
				},
			},
		};
	}
}
</script>

<style scoped lang="scss">
.review-area {
	height: 26.5rem;
	overflow-y: scroll;
}
.profile-image {
	height: 6rem;
	width: 6rem;
}

.expert-level {
	height: 1.25rem;
	width: 1.25rem;
}

::v-deep(.experience-card) {
	.p-card-title {
		color: var(--primary-color);
		margin-bottom: 0;
		font-weight: 600;
		font-size: 1.35rem;
	}

	.p-card-subtitle {
		color: var(--text-color-secondary);
		font-weight: 400;
	}

	.p-card-content {
		padding: 0.5rem 0;
	}
}

::v-deep(.p-timeline) {
	&.p-timeline-left .p-timeline-event-opposite {
		display: none;
	}
}

:deep(.p-rating) {
	&.rating-lg {
		.p-rating-icon {
			font-size: 1.4rem;
		}
	}

	.p-rating-icon {
		font-size: 1.05rem;
	}
}
</style>
