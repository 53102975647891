<template>
	<BackButton :label="$t('myCadac.trainingDetail.button.back')" />

	<div v-if="vm.training" class="d-flex align-items-center mt-2 mb-2">
		<img
			v-lazy="vm.training.productIconUrl"
			:alt="vm.training.productIconAlt"
			class="icon-training-item mr-2"
		/>
		<div class="d-flex flex-column justify-content-between">
			<p class="lead text-muted mb-0">{{ vm.training.brand }}</p>
			<h4 class="m-0">{{ vm.training.name }}</h4>
		</div>
	</div>

	<TrainingDetailSummary :is-instructor="vm.isInstructor" :vm="vm.training">
		<div v-if="vm.isParticipant">
			<p class="text-bold">{{ $t("myCadac.trainingSummary.lblPresence") }}</p>
			<span class="mr-1 text-bold lead">{{ attendancePercentage(userAsParticipant) || 0 }}%</span> |
			<Chip
				v-for="(date, dateIndex) in vm.training.dates"
				:key="date"
				:class="isParticipantPresentForDay(userAsParticipant, dateIndex) ? 'text-success' : 'text-danger'"
				:icon="isParticipantPresentForDay(userAsParticipant, dateIndex) ? 'pi pi-check' : 'pi pi-times'"
				:label="formatDate(date.date)"
			/>
		</div>
	</TrainingDetailSummary>

	<Message
		v-if="vm.isParticipant && canDownloadCertificate(userAsParticipant)"
		class="message-with-button"
		:closable="false"
		severity="custom"
	>
		<div class="d-flex align-items-center">
			<i class="pi pi-check-circle text-success message-icon mr-2" />
			<span>{{ $t("myCadac.trainingDetail.message.canDownloadCertificate") }}</span>
		</div>
		<Button
			class="p-button-text p-button-raised m-1"
			icon="pi pi-download"
			:label="$t('myCadac.trainingDetail.button.downloadCertificate')"
			:title="$t('myCadac.trainingDetail.button.downloadCertificate_title')"
			@click="downloadCertificate(userAsParticipant)"
		/>
	</Message>

	<Message
		v-else-if="vm.isParticipant && !canDownloadCertificate(userAsParticipant)"
		:closable="false"
		severity="warn"
	>
		{{ $t("myCadac.trainingDetail.message.cannotDownloadCertificate") }}
	</Message>

	<Message v-if="canDownloadAllCertificates" class="message-with-button" :closable="false" severity="custom">
		<div class="d-flex align-items-center">
			<i class="pi pi-info-circle text-primary message-icon mr-2" />
			<span>{{ $t("myCadac.trainingDetail.message.instructorDownloadAllCertificates") }}</span>
		</div>
		<Button
			class="p-button-text p-button-raised m-1"
			icon="pi pi-download"
			:label="$t('myCadac.trainingDetail.button.downloadAllCertificates')"
			:title="$t('myCadac.trainingDetail.button.downloadAllCertificates_title')"
			@click="downloadAllCertificates()"
		/>
	</Message>

	<div v-if="(vm.isInstructor || vm.isCustomer) && vm.training?.participants" class="mt-3">
		<h3>
			{{
				vm.isInstructor
					? $t("myCadac.trainingDetail.titleUpdateAttendance")
					: $t("myCadac.trainingDetail.titleAttendance")
			}}
		</h3>
		<DataTable auto-layout data-key="id" :value="trainingParticipants">
			<Column key="participant" body-class="" :header="$t('myCadac.trainingDetail.table.participant')">
				<template #body="item">
					{{ participantName(item.data) }}
				</template>
			</Column>
			<Column key="email" field="email" :header="$t('myCadac.trainingDetail.table.email')" />
			<Column
				key="allPresenceDays"
				field="allPresenceDays"
				:header="$t('myCadac.trainingDetail.table.presence')"
			>
				<template #body="item">
					<div v-if="vm.isInstructor" class="d-flex flex-wrap">
						<div class="d-flex flex-column">
							<div class="p-field-checkbox form-group mt-1">
								<Checkbox
									v-model="participantAllDayPresent[item.data.id]"
									:id="`allDaysPresent-${item.index}`"
									:binary="true"
									:disabled="isSpinnerVisible(item.data.id)"
									@click="toggleAllDatesPresent(item.data, item.index)"
								/>
								<label class="ml-2 mb-0" :for="`allDaysPresent-${item.index}`">
									{{ $t("myCadac.trainingDetail.table.lblAllDaysPresent") }}
								</label>
							</div>
							<div v-if="isSpinnerVisible(item.data.id)">
								<i class="pi pi-spin pi-spinner" style="font-size: 1.5rem" />
							</div>
						</div>
						<div class="ml-2">
							<div
								v-for="(date, dateIndex) in vm.training.dates"
								:key="date"
								class="p-field-checkbox form-group mt-1"
							>
								<Checkbox
									v-model="item.data.allPresenceDays[dateIndex]"
									:id="`dateCheckbox-${item.index}-${dateIndex}`"
									:binary="true"
									:disabled="isSpinnerVisible(item.data.id)"
									@change="toggleDatePresent(item.data)"
								/>
								<label class="ml-2 mb-0" :for="`dateCheckbox-${item.index}-${dateIndex}`">
									{{ formatDate(date.date) }}
								</label>
							</div>
						</div>
					</div>
					<div v-else>
						<span class="mr-1 text-bold lead">{{ attendancePercentage(item.data) || 0 }}%</span> |
						<Chip
							v-for="(date, dateIndex) in vm.training.dates"
							:key="date"
							:class="
								isParticipantPresentForDay(item.data, dateIndex) ? 'text-success' : 'text-danger'
							"
							:icon="
								isParticipantPresentForDay(item.data, dateIndex) ? 'pi pi-check' : 'pi pi-times'
							"
							:label="formatDate(date.date)"
						/>
					</div>
				</template>
			</Column>
			<Column
				v-if="vm.isCustomer"
				key="certificate"
				body-class=""
				:header="$t('myCadac.trainingDetail.table.certificate')"
			>
				<template #body="item">
					<Button
						v-if="canDownloadCertificate(item.data)"
						class="p-button-text p-button-raised p-button-rounded"
						icon="pi pi-download"
						:label="isSmallScreen ? $t('myCadac.trainingDetail.button.downloadCertificate') : ''"
						:title="$t('myCadac.trainingDetail.button.downloadCertificate_title')"
						@click="downloadCertificate(item.data)"
					/>
				</template>
			</Column>
		</DataTable>
	</div>
</template>

<script lang="ts">
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import TrainingDetailSummary from "@/components/training/trainingDetailSummary.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IFinishedTrainingDetail } from "@/types/viewModels/myCadac/finishedTrainingDetailViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { TrainingParticipantPresenceRequest } from "@/types/generated/Api/trainingParticipantPresenceRequest";
import { TrainingParticipantViewModel } from "@/types/models/training/trainingParticipantViewModel";
import { cloneDeep, values } from "lodash";

@Component({ components: { TrainingDetailSummary, Checkbox, Chip, BackButton } })
export default class FinishedTrainingDetail extends BaseComponent {
	@Prop({ type: Object as PropType<IFinishedTrainingDetail>, required: true, default: {} })
	vm!: IFinishedTrainingDetail;

	trainingParticipants: TrainingParticipantViewModel[] = [];
	participantAllDayPresent: { [id: string]: boolean } = {};

	get userAsParticipant(): TrainingParticipantViewModel {
		return this.trainingParticipants.find(
			(x) => x.email === this.userProfile?.email
		) as TrainingParticipantViewModel;
	}

	get canDownloadCertificate() {
		return (participant: TrainingParticipantViewModel) => this.isParticipantPresentForAllDays(participant);
	}

	get canDownloadAllCertificates(): boolean {
		return this.vm.isCustomer && values(this.participantAllDayPresent).some(Boolean);
	}

	get attendancePercentage() {
		return (participant: TrainingParticipantViewModel) =>
			(participant?.presenceDays * 100) / this.vm.training.dates?.length;
	}

	get participantName() {
		return (participant: TrainingParticipantViewModel) => `${participant.firstName} ${participant.lastName}`;
	}

	created(): void {
		this.trainingParticipants = this.vm.training.participants;
		this.trainingParticipants.forEach(
			(x) => (this.participantAllDayPresent[x.id] = this.isParticipantPresentForAllDays(x))
		);
	}

	isParticipantPresentForAllDays(participant: TrainingParticipantViewModel): boolean {
		return participant?.presenceDays === this.vm.training.dates?.length;
	}

	isParticipantPresentForDay(participant: TrainingParticipantViewModel, index: number): boolean {
		return participant?.allPresenceDays[index] === true;
	}

	downloadCertificate(participant: TrainingParticipantViewModel): void {
		const basePath = window.location.href.replace(window.location.search, "");
		this.openUrl(
			`${basePath}/GetCertificate/?trainingListId=${this.vm.training.id}&participantId=${participant.id}`,
			"_blank"
		);
	}

	downloadAllCertificates(): void {
		const basePath = window.location.href.replace(window.location.search, "");
		this.openUrl(`${basePath}/GetCertificate/?trainingListId=${this.vm.training.id}`, "_blank");
	}

	toggleAllDatesPresent(participant: TrainingParticipantViewModel, index: number): void {
		if (!this.isParticipantPresentForAllDays(participant)) {
			this.vm.training.dates?.forEach((_date, index) => (participant.allPresenceDays[index] = true));
		} else {
			participant.allPresenceDays = [false, false, false, false, false];
		}

		this.trainingParticipants[index] = cloneDeep(participant);
		this.updateParticipantPresence(participant);
	}

	toggleDatePresent(participant: TrainingParticipantViewModel): void {
		this.updateParticipantPresence(participant);
	}

	protected updateParticipantPresence(participant: TrainingParticipantViewModel): void {
		const model: TrainingParticipantPresenceRequest = {
			trainingListId: this.vm.training.id,
			participantId: participant.id,
			presenceList: participant.allPresenceDays,
		};
		this.loadingStore.showSpinner(participant.id);
		this.axios
			.post("/api/training/updateParticipantPresence", model)
			.then((res) => {
				if (!res?.data) {
					this.restoreParticipantPresence(participant);
					this.showParticipantUpdateErrorToast();
				} else {
					participant.presenceDays = participant.allPresenceDays.filter(Boolean).length;
					this.participantAllDayPresent[participant.id] =
						this.isParticipantPresentForAllDays(participant);
				}
			})
			.catch((err) => {
				Log.error(err);
				this.restoreParticipantPresence(participant);
				this.showParticipantUpdateErrorToast();
			})
			.finally(() => this.loadingStore.hideSpinner(participant.id));
	}

	private showParticipantUpdateErrorToast(): void {
		this.$toast.add({
			severity: "error",
			summary: this.$t("common.messages.titleError"),
			detail: this.$t("myCadac.trainingDetail.toast.updateParticipantPresenceError"),
			closable: false,
			life: 3000,
		});
	}

	private restoreParticipantPresence(participant: TrainingParticipantViewModel): void {
		const participantIndex = this.trainingParticipants.findIndex((x) => x.id === participant.id);
		participant.allPresenceDays = participant.allPresenceDays.map(
			(_, i) => participant[`trainingPresenceDay${i + 1}`]
		);
		participant.presenceDays = participant.allPresenceDays.filter(Boolean).length;
		this.trainingParticipants[participantIndex] = cloneDeep(participant);
		this.participantAllDayPresent[participant.id] = this.isParticipantPresentForAllDays(participant);
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable) {
	.p-datatable-tbody tr td {
		vertical-align: top;
	}
}

::v-deep(.p-chip) {
	background: var(--surface-a);
}

.icon-training-item {
	width: 64px;
	height: 64px;
	object-fit: none;
}
</style>
