import { defineStore } from 'pinia';

interface TrainingStoreState {
	isTrainingAddCourseModalVisible: boolean;
	isTrainingAddParticipantModalVisible: boolean;
}

export type TrainingModalType = 'AddCourse' | 'AddParticipant';

export const useTrainingStore = defineStore('training', {
	state: () =>
		({
			isTrainingAddCourseModalVisible: false,
			isTrainingAddParticipantModalVisible: false,
		}) as TrainingStoreState,
	actions: {
		showModal(type: TrainingModalType) {
			switch (type) {
				case 'AddCourse':
					this.isTrainingAddCourseModalVisible = true;
					break;
				case 'AddParticipant':
					this.isTrainingAddParticipantModalVisible = true;
					break;
			}
		},
		hideModal(type: TrainingModalType) {
			switch (type) {
				case 'AddCourse':
					this.isTrainingAddCourseModalVisible = false;
					break;
				case 'AddParticipant':
					this.isTrainingAddParticipantModalVisible = false;
					break;
			}
		},
	},
});
