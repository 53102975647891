<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
		@select="onSelectedCourseChanged()"
	>
		<template #header>
			<h3 class="mb-0">
				{{ $t("training.dialog.titleAddModule") }}
			</h3>
		</template>
		<div class="mb-3">
			<label>
				{{ $t("training.dialog.lblChooseCourse") }}
			</label>
			<Dropdown
				v-model="selectedCourse"
				append-to="self"
				option-label="label"
				option-value="value"
				:options="availableCourses"
			/>
		</div>
		<label>
			{{ $t("training.dialog.lblChooseModules") }}
		</label>
		<small class="required">
			{{ $t("training.dialog.lblMinimumModuleAmount") }}
		</small>
		<TrainingModuleTable
			:is-editable="true"
			:is-modal="true"
			:training-course="selectedCourse"
			@selected-modules-changed="onSelectedModulesChanged"
		/>
		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :disabled="!canSubmit" :label="$t('common.add')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import TrainingModuleTable, { TrainingModulesChangedEvent } from "@/components/training/trainingModuleTable.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { PropType } from "vue";
import { TrainingModuleViewModel } from "@/types/generated/Training/trainingModuleViewModel";
import { TrainingViewModel } from "@/types/generated/Training/trainingViewModel";
import { sum } from "lodash";
import { useTrainingStore } from "@/store/training/trainingStore";
import { useTrainingProductStore } from "@/store/training/trainingProductStore";

export interface TrainingCourseAddedEvent {
	course: TrainingViewModel;
	modules: TrainingModuleViewModel[];
}

@Component({ components: { TrainingModuleTable }, emits: ["training-course-added"] })
export default class TrainingAddCourseModal extends BaseComponent {
	@Prop({ type: Object as PropType<TrainingViewModel[]>, required: true, default: {} })
	courses!: TrainingViewModel[];

	selectedCourse: TrainingViewModel | null = null;
	selectedModules: TrainingModuleViewModel[] = [];
	availableCourses: IDropdownItem[] = [];

	isVisible = true;

	trainingStore = useTrainingStore();
	trainingProductStore = useTrainingProductStore();

	get canSubmit(): boolean {
		return !!this.selectedModules?.length && sum(this.selectedModules.map((x) => x.durationInHours)) >= 3;
	}

	@Emit() trainingCourseAdded(): TrainingCourseAddedEvent {
		return { course: this.selectedCourse as TrainingViewModel, modules: this.selectedModules };
	}

	created(): void {
		this.availableCourses = this.courses
			?.filter((x) => !this.trainingProductStore.isCourseSelected(x.cadacIdentifier) && x.modules.length)
			.map((x: any) => ({ value: x, label: x.name }));
		this.selectedCourse = this.availableCourses[0]?.value as TrainingViewModel;
	}

	onSelectedCourseChanged(): void {
		this.selectedModules = [];
	}

	onSelectedModulesChanged(event: TrainingModulesChangedEvent): void {
		this.selectedModules = event.modules;
	}

	onCancel(): void {
		this.trainingStore.hideModal("AddCourse");
	}

	onSubmit(): void {
		this.trainingCourseAdded();
	}
}
</script>

<style scoped lang="scss"></style>
