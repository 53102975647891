export enum CtaButtonStyle {
	Default,
	WhiteArrow,
	White,
	GreenArrow,
	Green,
	PurpleArrow,
	Purple,
	LinkArrow,
	Link,
}
