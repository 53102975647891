import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-group col-12" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = {
  key: 2,
  class: "p-field-checkbox mr-2 mb-2"
}
const _hoisted_6 = {
  class: "ml-1 mb-0",
  for: "sendEmailToLicenseUser"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_UserLicenceForm = _resolveComponent("UserLicenceForm")!
  const _component_ServerLicenceForm = _resolveComponent("ServerLicenceForm")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onCancel()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.selectedLicence
						? _ctx.$t("myCadac.licenceManagementDetail.dialog.titleEditLicence")
						: _ctx.$t("myCadac.licenceManagementDetail.dialog.titleNewLicence")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.isEditing ? _ctx.$t('common.save') : _ctx.$t('common.add'),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['licenceForm'].licenceType?.required })
            }, _toDisplayString(_ctx.$t("myCadac.licenceManagementDetail.dialog.lblLicenceType")), 3),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.v$['licenceForm'].licenceType.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['licenceForm'].licenceType.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['licenceForm'].licenceType) }),
              disabled: _ctx.possibleLicenceTypes.length === 1,
              "option-label": "label",
              "option-value": "value",
              options: _ctx.possibleLicenceTypes
            }, null, 8, ["modelValue", "class", "disabled", "options"]),
            (_ctx.shouldShowError(_ctx.v$['licenceForm'].licenceType))
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$["licenceForm"].licenceType?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.isUserLicence)
          ? (_openBlock(), _createBlock(_component_UserLicenceForm, {
              key: 0,
              modelValue: _ctx.v$,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$) = $event)),
              submitted: _ctx.submitted,
              vm: _ctx.vm
            }, null, 8, ["modelValue", "submitted", "vm"]))
          : _createCommentVNode("", true),
        (_ctx.isServerLicence)
          ? (_openBlock(), _createBlock(_component_ServerLicenceForm, {
              key: 1,
              modelValue: _ctx.v$,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$) = $event)),
              submitted: _ctx.submitted,
              vm: _ctx.vm
            }, null, 8, ["modelValue", "submitted", "vm"]))
          : _createCommentVNode("", true),
        (!_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Checkbox, {
                modelValue: _ctx.v$['licenceForm'].sendEmailToLicenseUser.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$['licenceForm'].sendEmailToLicenseUser.$model) = $event)),
                id: "sendEmailToLicenseUser",
                binary: "",
                name: "sendEmailToLicenseUser"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("myCadac.licenceManagementDetail.dialog.lblSendEmailToLicenceUser")), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}