<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<template #header>
			<h3 class="mb-0">
				{{
					canMakeSalesOpportunity
						? $t("cart.dialog.shareCartTitleImpersonated")
						: $t("cart.dialog.shareCartTitle")
				}}
			</h3>
		</template>
		<p class="lead mb-2">
			{{
				canMakeSalesOpportunity
					? $t("cart.dialog.shareCartHelpTextImpersonated")
					: $t("cart.dialog.shareCartHelpText")
			}}
		</p>
		<form class="pb-3" onsubmit="return false;">
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['cartShareForm'].title?.required }">
						{{ $t("cart.dialog.shareCartLblTitle") }}
					</label>
					<InputText
						v-model="v$['cartShareForm'].title.$model"
						:class="{ 'p-invalid': shouldShowError(v$['cartShareForm'].title) }"
						:readonly="!vm.allowChooseSalesOpportunity"
						type="text"
					/>
					<small v-if="shouldShowError(v$['cartShareForm'].title)" class="p-error">
						{{ v$["cartShareForm"].title?.required?.$message }}
					</small>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['cartShareForm'].salesOpportunityName?.required }">
						{{ $t("cart.dialog.shareCartLblOpportunityName") }}
					</label>
					<AutoComplete
						v-if="canMakeSalesOpportunity"
						v-model="v$['cartShareForm'].salesOpportunityName.$model"
						append-to="self"
						class="d-inline"
						:complete-on-focus="true"
						option-label="name"
						:placeholder="$t('cart.dialog.salesOpportunityName_placeholder')"
						:suggestions="salesOpportunitySuggestions"
						@complete="onSearchTrigger($event)"
						@item-select="onSalesOpportunitySelected($event)"
					/>
					<InputText
						v-else
						v-model="v$['cartShareForm'].salesOpportunityName.$model"
						:class="{ 'p-invalid': shouldShowError(v$['cartShareForm'].salesOpportunityName) }"
						readonly
						type="text"
					/>
					<small v-if="shouldShowError(v$['cartShareForm'].salesOpportunityName)" class="p-error">
						{{ v$["cartShareForm"].salesOpportunityName?.required?.$message }}
					</small>
				</div>
			</div>
			<div v-if="canMakeSalesOpportunity" class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['cartShareForm'].responsibleSales?.required }">
						{{ $t("cart.dialog.shareCartLblResponsibleSales") }}
					</label>
					<Dropdown
						v-model="v$['cartShareForm'].responsibleSales.$model"
						option-label="label"
						option-value="value"
						:options="responsibleSalesOptions"
						:placeholder="$t('cart.dialog.responsibleSales_placeholder')"
					/>
					<small v-if="shouldShowError(v$['cartShareForm'].responsibleSales)" class="p-error">
						{{ v$["cartShareForm"].responsibleSales?.required?.$message }}
					</small>
				</div>
			</div>
			<div v-if="canMakeSalesOpportunity" class="p-field-checkbox form-group mt-1">
				<Checkbox v-model="v$['cartShareForm'].sendEmail.$model" id="sendEmail" :binary="true" />
				<label class="ml-2 mb-0" for="sendEmail">{{ $t("cart.dialog.shareCartLblSendEmail") }}</label>
			</div>
			<div v-if="v$['cartShareForm'].sendEmail?.$model">
				<div class="form-row">
					<div class="form-group col-12">
						<label :class="{ required: v$['cartShareForm'].fromEmail?.required }">
							{{ $t("cart.dialog.shareCartLblSenderEmail") }}
						</label>
						<InputText
							v-model="v$['cartShareForm'].fromEmail.$model"
							:class="{ 'p-invalid': shouldShowError(v$['cartShareForm'].fromEmail) }"
							:readonly="userProfile?.isImpersonated || userProfile?.isImpersonationAllowed"
							type="text"
						/>
						<small v-if="shouldShowError(v$['cartShareForm'].fromEmail)" class="p-error">
							{{ v$["cartShareForm"].fromEmail?.email?.$message }}
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-12">
						<label :class="{ required: v$['cartShareForm'].toEmail?.required }">
							{{ $t("cart.dialog.shareCartLblReceiverEmail") }}
						</label>
						<Chips
							v-model="v$['cartShareForm'].toEmail.$model"
							:add-on-blur="true"
							:allow-duplicate="false"
							class="d-inline"
							:class="{ 'p-invalid': shouldShowError(v$['cartShareForm'].toEmail) }"
							@add="onToEmailAdded($event)"
						>
							<template #chip="item">
								<span
									v-if="validToEmailAddresses.includes(item.value)"
									class="p-chips-token-label"
								>
									{{ item.value }}
								</span>
								<s v-else class="p-chips-token-label text-danger"> {{ item.value }} </s>
							</template>
						</Chips>
						<small v-if="shouldShowError(v$['cartShareForm'].toEmail)" class="p-error">
							{{ v$["cartShareForm"].toEmail?.oneValidEmailRequired?.$message }}
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-12">
						<label :class="{ required: v$['cartShareForm'].description?.required }">
							{{ $t("cart.dialog.shareCartLblDescription") }}
						</label>
						<Textarea
							v-model="v$['cartShareForm'].description.$model"
							class="d-inline"
							:class="{ 'p-invalid': shouldShowError(v$['cartShareForm'].description) }"
							rows="5"
						/>
						<small v-if="shouldShowError(v$['cartShareForm'].description)" class="p-error">
							{{ v$["cartShareForm"].description?.required?.$message }}
						</small>
					</div>
				</div>
			</div>
		</form>
		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button
				:disabled="!canSubmit"
				:label="canMakeSalesOpportunity ? $t('common.save') : $t('common.send')"
				@click="onSubmit()"
			/>
		</template>
	</Dialog>
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import Chips from "primevue/chips";
import useVuelidate, { Validation, ValidatorFn } from "@vuelidate/core";
import { CartShareForm } from "@/types/models/form/cartShareForm";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { FilterService } from "primevue/api";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { IShoppingCart } from "@/types/viewModels/commerce/shoppingCartViewModel";
import { OpportunityView } from "@/types/generated/opportunityView";
import { PropType } from "vue";
import { ValidationHelper } from "@/types/helpers/validationHelper";
import { format } from "date-fns";
import { minLength, required, withI18nMessage } from "@/types/utils/i18n-validators";
import { useCartStore } from "@/store/commerce/cartStore";
import { requiredIf } from "@vuelidate/validators";

const validToEmail = (value: string[], siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return vm.cartShareForm.sendEmail ? value.some((x) => ValidationHelper.validateEmail(x)) : true;
};
const validFromEmail = (value: string, siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return vm.cartShareForm.sendEmail ? ValidationHelper.validateEmail(value) : true;
};

const requiredResponsibleSales = (_value: string, siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return vm?.canMakeSalesOpportunity;
};

@Component({
	emits: ["cart-shared"],
	components: { AutoComplete, Checkbox, Chips },
	options: {
		validations: {
			cartShareForm: {
				title: { required, minLength: minLength(2) },
				salesOpportunity: {},
				salesOpportunityName: { required },
				responsibleSales: { required: requiredIf(requiredResponsibleSales as any) },
				toEmail: { oneValidEmailRequired: withI18nMessage(validToEmail as ValidatorFn) },
				fromEmail: { email: withI18nMessage(validFromEmail as ValidatorFn) },
				description: {},
				sendEmail: {},
				canMakeSalesOpportunity: {},
			},
		},
	},
})
export default class ShareCartModal extends BaseComponent {
	@Prop({ type: Object as PropType<IShoppingCart>, required: true, default: {} }) vm!: IShoppingCart;

	v$ = useVuelidate();
	isVisible = true;
	submitted = false;
	cartShareForm!: Partial<CartShareForm>;
	salesOpportunitySuggestions: OpportunityView[] = [];
	responsibleSalesOptions: IDropdownItem[] = [];
	validToEmailAddresses: string[] = [];

	get canSubmit(): boolean {
		return !this.v$["cartShareForm"].$invalid;
	}

	get canMakeSalesOpportunity(): boolean {
		return (
			!!(this.userProfile?.isImpersonated || this.userProfile?.isImpersonationAllowed) &&
			this.vm.allowChooseSalesOpportunity
		);
	}

	@Emit()
	cartShared(): Partial<CartShareForm> {
		const formValue = { ...this.cartShareForm } as Partial<CartShareForm>;
		formValue.toEmail = this.validToEmailAddresses;
		// Delete the sales Opportunity ID if it's a new name
		if (this.salesOpportunitySuggestions.findIndex((x) => x.name === formValue.salesOpportunityName) < 0) {
			delete formValue.salesOpportunity;
		}
		if (!formValue.sendEmail) {
			delete formValue.toEmail;
			delete formValue.fromEmail;
			delete formValue.description;
		}
		return formValue;
	}

	created(): void {
		this.cartShareForm = {
			title: this.vm.cartTitle,
			sendEmail: !this.vm.allowChooseSalesOpportunity,
			fromEmail: this.userProfile?.isImpersonated ? this.userProfile?.userName : this.userProfile?.email,
			toEmail: [this.userProfile?.email],
			salesOpportunityName: this.vm.cartOpportunityName,
			salesOpportunity: "",
			responsibleSales: "",
			description: "",
			canMakeSalesOpportunity: this.canMakeSalesOpportunity,
			timeStamp: format(FormatHelper.getUTCDate(), "yyyyMMddHHmmss"),
		};
		this.validToEmailAddresses =
			this.cartShareForm.toEmail?.filter((x) => ValidationHelper.validateEmail(x)) || [];
		this.responsibleSalesOptions = this.vm.possibleResponsibleSales?.map((x) => ({
			label: x.name,
			value: x.emailAddress,
		}));
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.cartShared();
	}

	onCancel(): void {
		useCartStore().hideModal("ShareCart");
	}

	onSearchTrigger({ query }: { query: string }): void {
		// @ts-ignore
		this.salesOpportunitySuggestions = FilterService.filter(
			this.vm.salesOpportunityViews,
			["name"],
			query,
			"contains",
			this.currentRegion
		);
	}

	onSalesOpportunitySelected({ value }: { value: OpportunityView }): void {
		//set salesOpportunity ID and responsible sales
		this.v$["cartShareForm"].salesOpportunityName.$model = value.name;
		this.v$["cartShareForm"].salesOpportunity.$model = value.id;
		this.v$["cartShareForm"].responsibleSales.$model = this.responsibleSalesOptions.find(
			(x) => x.label === value.responsibleSales
		)?.value;
	}

	onToEmailAdded({ value }: { value: string[] }): void {
		this.validToEmailAddresses = value.filter((x) => ValidationHelper.validateEmail(x));
	}
}
</script>

<style scoped lang="scss"></style>
