<template>
	<h1 v-if="vm.pageTitle" class="">{{ vm.pageTitle }}</h1>

	<Panel class="mb-2">
		<template #header>
			<div>
				<p v-if="vm.introTitle" class="lead mb-0 font-weight-bold" v-html="vm.introTitle" />
				<p v-if="vm.introText" class="lead" v-html="vm.introText" />
			</div>
		</template>

		<div v-if="isEditingTravelSettings">
			<div class="d-flex flex-column form-group">
				<label for="remoteAllowed">{{ $t("myCadac.expertTravelSettings.lblRemoteAllowed") }}</label>
				<InputSwitch v-model="isRemoteAllowed" id="remoteAllowed" />
			</div>
			<div class="d-flex flex-column form-group">
				<label for="availableAbroad">{{ $t("myCadac.expertTravelSettings.lblAvailableAbroad") }}</label>
				<InputSwitch v-model="isAvailableAbroad" id="availableAbroad" />
			</div>
			<div>
				<Button
					class="p-button-text p-button-primary mr-1"
					:label="$t('common.cancel')"
					@click.stop="onCancelEditing"
				/>
				<Button class="" :label="$t(`common.save`)" @click="onSubmit" />
			</div>
		</div>

		<div v-else>
			<dl>
				<dt>{{ $t("myCadac.expertTravelSettings.lblRemoteAllowed") }}</dt>
				<dd>
					<ColumnYesNo :value="isRemoteAllowed" />
				</dd>
				<dt>{{ $t("myCadac.expertTravelSettings.lblAvailableAbroad") }}</dt>
				<dd>
					<ColumnYesNo :value="isAvailableAbroad" />
				</dd>
			</dl>
			<Button
				class="p-button-text px-0"
				icon="pi pi-pencil"
				:label="$t('common.change')"
				@click.stop="isEditingTravelSettings = true"
			/>
		</div>
	</Panel>

	<Panel class="mb-2">
		<template #header>
			<div>
				<p v-if="vm.mapDescription" class="lead" v-html="vm.mapDescription" />
			</div>
		</template>
		<div>
			<GMapAutocomplete
				class="w-100"
				:placeholder="$t(`myCadac.expertTravelSettings.searchLocation_placeholder`)"
				@change="onLocationSelected"
				@place_changed="onPlaceChanged"
			>
			</GMapAutocomplete>
		</div>
		<div class="map-container">
			<GMapMap
				:center="{
					lat: vm?.expertProfile?.latitude,
					lng: vm?.expertProfile?.longitude,
				}"
				:options="{
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
				}"
				:zoom="5"
			>
				<GMapMarker
					v-if="workLocation"
					:clickable="false"
					:draggable="false"
					:position="{
						lat: workLocation?.latitude,
						lng: workLocation?.longitude,
					}"
				/>
				<GMapCircle
					key="minPlaneCompensationDistance"
					:center="{
						lat: vm?.expertProfile?.latitude,
						lng: vm?.expertProfile?.longitude,
					}"
					:options="{
						radius: vm.minPlaneCompensationDistance * 1000,
						strokeColor: '#91004b',
						strokeOpacity: 0.25,
						strokeWeight: 1,
						fillColor: '#91004b',
						fillOpacity: 0.15,
					}"
				/>
				<GMapCircle
					key="minCompensationDistance"
					:center="{
						lat: vm?.expertProfile?.latitude,
						lng: vm?.expertProfile?.longitude,
					}"
					:options="{
						radius: vm.minCompensationDistance * 1000,
						strokeColor: '#91004b',
						strokeOpacity: 0.5,
						strokeWeight: 1,
						fillColor: '#91004b',
						fillOpacity: 0.3,
					}"
				/>
			</GMapMap>
		</div>
	</Panel>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ColumnYesNo from "@/components/commerce/cart/columnYesNo.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertBookingWorkLocation } from "@/types/models/expert/expertBookingWorkLocation";
import { IExpertTravelSettings } from "@/types/viewModels/myCadac/expertTravelSettingsViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SaveExpertTravelSettingsRequest } from "@/types/models/expert/saveExpertTravelSettingsRequest";

@Component({
	components: { ColumnYesNo },
})
export default class ExpertTravelSettings extends BaseComponent {
	@Prop({
		type: Object as PropType<IExpertTravelSettings>,
		required: true,
		default: {},
	})
	vm!: IExpertTravelSettings;

	isRemoteAllowed!: boolean;
	isAvailableAbroad!: boolean;
	isEditingTravelSettings = false;

	workLocation!: ExpertBookingWorkLocation | null;

	created(): void {
		this.isAvailableAbroad = this.vm.expertProfile?.availableAbroad;
		this.isRemoteAllowed = this.vm.expertProfile?.remoteAllowed;
	}

	onLocationSelected(e): void {
		if (!e.target.value) {
			this.workLocation = null;
		}
	}

	onPlaceChanged(place): void {
		if (place?.name) {
			const result = place.address_components.find((component) => {
				return component.types.includes("country");
			});

			this.workLocation = {
				name: place.formatted_address,
				countryCode: result.short_name,
				latitude: place.geometry.location.lat(),
				longitude: place.geometry.location.lng(),
			};
		} else {
			this.workLocation = null;
		}
	}

	onCancelEditing(): void {
		this.isEditingTravelSettings = false;
		this.isAvailableAbroad = this.vm.expertProfile?.availableAbroad;
		this.isRemoteAllowed = this.vm.expertProfile?.remoteAllowed;
	}

	onSubmit(): void {
		this.saveTravelSettings();
	}

	private saveTravelSettings(): void {
		const model: SaveExpertTravelSettingsRequest = {
			workRemote: this.isRemoteAllowed,
			workAbroad: this.isAvailableAbroad,
			editingLanguage: this.currentLanguage,
		};

		this.axios
			.post("/api/expert/settings/save-travel-settings", model, { requestId: "saveTravelSettings" })
			.then(() => {
				this.isEditingTravelSettings = false;
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.exertTravelSettings.toast.updateExperTravelSettingsSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.exertTravelSettings.toast.updateExperTravelSettingsError"),
					life: 3000,
				});
			});
	}
}
</script>

<style lang="scss" scoped>
.map-container {
	margin-top: 1.5rem;
	height: 500px;
}

::v-deep(.vue-map-container) {
	height: 100% !important;
}
</style>
