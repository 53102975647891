import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex py-2 flex-shrink-0 surface-a" }
const _hoisted_2 = { class: "d-flex flex-column ml-1 w-75" }
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Skeleton, {
      height: "6rem",
      width: "6rem"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Skeleton, {
          class: "mb-1",
          height: "1.4rem",
          width: "20%"
        }),
        _createVNode(_component_Skeleton, {
          class: "mb-1",
          height: ".75rem",
          width: "100%"
        }),
        _createVNode(_component_Skeleton, {
          class: "mb-1",
          height: ".75rem",
          width: "90%"
        })
      ])
    ])
  ]))
}