<template>
	<div id="sticky-button" class="sticky-button" :style="isButtonHidden ? 'opacity: 0' : 'opacity: 1'">
		<Button
			class="p-button-success p-button-raised p-button-arrow w-100 px-1 my-1"
			:label="buttonLabel"
			@click="scrollTo"
		/>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import Button from "primevue/button";
@Component({
	components: {
		Button,
	},
})
export default class StickyScrollToButton extends BaseComponent {
	@Prop({ type: String, required: true }) buttonLabel;
	@Prop({ type: String, required: true }) parentContainerId;
	@Prop({ type: String, required: true }) target;
	@Prop({ type: Number, required: false }) offset;

	isButtonHidden = false;

	mounted(): void {
		window.addEventListener("scroll", this.handleScroll);
	}

	beforeUnmount(): void {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll(): void {
		const targetDiv = (document as Document).getElementById(this.parentContainerId);
		const stickyButton = document.getElementById("sticky-button");
		const targetDivPosition = targetDiv?.getBoundingClientRect();
		if (targetDivPosition && stickyButton) {
			this.isButtonHidden = targetDivPosition.top < window.innerHeight;
		}
	}

	scrollTo(): void {
		setTimeout(() => {
			const stepsEl = document.querySelector(this.target);
			if (!stepsEl) return;
			window.scrollTo(0, stepsEl?.getBoundingClientRect().top - this.offset + window.scrollY);
		});
	}
}
</script>

<style scoped lang="scss">
.sticky-button {
	width: 100%;
	position: fixed;
	bottom: 0;
	right: 0;
	transition: opacity 0.3s ease-in-out;
	opacity: 1;
	background: var(--surface-a);
	box-shadow: -5px 0px 5px 1px rgb(221 221 221 / 87%);
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
</style>
