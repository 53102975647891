<template>
	<DataTable breakpoint="1150px" data-key="id" responsive-layout="stack" :value="cartLineClients">
		<Column field="productName" :header="$t('cart.table.colName')" :style="{ width: '25%' }">
			<template #body="item">
				<ColumnProductName :item="productNameColumnModel(item.data)">
					<div class="product-actions align-items-lg-start flex-lg-row ml-lg-0">
						<Button
							v-if="canChangeConfiguration(item.data)"
							class="p-button-secondary p-button-text flex-shrink-0 hidden-print"
							icon="pi pi-cog"
							:label="$t('cart.button.editConfiguration')"
							:title="$t('cart.button.editConfiguration')"
							@click="changeConfiguration(item.data)"
						/>
						<Button
							v-if="canRemoveItem(item.data)"
							class="p-button-secondary p-button-text flex-shrink-0 hidden-print"
							icon="pi pi-times"
							:label="$t('cart.button.removeItem')"
							:title="$t('cart.button.removeItem')"
							@click="confirmRemoveItem(item.data)"
						/>
					</div>
				</ColumnProductName>
			</template>
		</Column>

		<Column>
			<template #body="item">
				<ColumnExpertInfo :item="item.data" />
			</template>
		</Column>

		<Column :header="$t('cart.table.colPrice')" style="width: 35%">
			<template #body="item">
				<div v-if="!isSpinnerVisible(item.data.name)">
					<PriceSummary :vm="priceSummary(item.data)" />
				</div>
				<div v-else-if="isSpinnerVisible(item.data.name)">
					<i class="pi pi-spin pi-spinner" />
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ColumnExpertInfo from "@/components/commerce/cart/columnExpertInfo.vue";
import ColumnProductName from "@/components/commerce/cart/columnProductName.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import { CartClient } from "@/types/models/cart/cartClient";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertCalculationLineType } from "@/types/enum/expertCalculationLineType";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { RemoveExpertProductRequest } from "@/types/models/expert/removeExpertProductRequest";
import { sortBy } from "lodash";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { ColumnExpertInfo, PriceSummary, ColumnProductName } })
export default class CartExpertTable extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient[]>, required: true, default: {} })
	cartLineClients!: CartLineClient[];
	@Prop({ type: Boolean, required: true, default: false }) isEditable!: boolean;

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get productNameColumnModel() {
		return (expertCartLine: CartLineClient) => {
			const { expert, brand, icon, iconAltTag, url } = expertCartLine;
			return {
				title: expert?.name,
				brand,
				icon,
				iconAltTag,
				url,
			} as CartLineClient;
		};
	}

	get priceSummary() {
		return (cartLineClient: CartLineClient) => {
			const { expert, priceSubTotal } = cartLineClient;
			const additionalLines = new Map<string, number>();
			const standardLines = new Map<string, number>();
			sortBy(expert.basePrices, "order")
				.filter((x) => x.totalPrice)
				.forEach((x) => {
					if (x.type === ExpertCalculationLineType.fullDay)
						standardLines.set(
							this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity]),
							x.totalPrice
						);
					if (x.type === ExpertCalculationLineType.dayPart)
						standardLines.set(
							this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity]),
							x.totalPrice
						);
					if (x.type === ExpertCalculationLineType.hour)
						standardLines.set(
							this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity]),
							x.totalPrice
						);
				});
			sortBy(expert.additionalPrices, "order")
				.filter((x) => x.totalPrice)
				.forEach((x) => {
					if (x.type === ExpertCalculationLineType.travel)
						additionalLines.set(
							this.$t(`enum.expertCalculationLineType.${x.type}`, [x.quantity, toFixed(x.price, 2)]),
							x.totalPrice
						);
					if (x.type === ExpertCalculationLineType.accommodation)
						additionalLines.set(
							x.quantity > 1
								? this.$t(`enum.expertCalculationLineType.${x.type}`) +
										" " +
										this.$t(`expert.nights`, [x.quantity])
								: this.$t(`enum.expertCalculationLineType.${x.type}`) +
										" " +
										this.$t(`expert.night`, [x.quantity]),
							x.totalPrice
						);
					if (x.type === ExpertCalculationLineType.flexBooking)
						additionalLines.set(
							this.$t(`enum.expertCalculationLineType.${x.type}`, [
								expert.flexBookingPercentage,
								toFixed(x.price, 2),
							]),
							x.totalPrice
						);
				});
			return { subtotal: priceSubTotal, additionalLines, standardLines } as IPriceSummary;
		};
	}

	canRemoveItem(item: CartLineClient): boolean {
		return item.canRemoveLine && this.isEditable;
	}

	canChangeConfiguration(item: CartLineClient): boolean {
		return item.canChangeConfiguration && this.isEditable;
	}

	changeConfiguration(item: CartLineClient): void {
		this.loadingStore.increaseLoadingCount();
		this.openUrl(`${item.url}?lineItemId=${item.id}`);
	}

	confirmRemoveItem(item: CartLineClient): void {
		this.$confirm.require({
			message: this.$t("cart.dialog.confirmRemoveProduct", [item.title]),
			header: this.$t("common.messages.titleImportant"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.removeItemFromCart({ lineItemId: item.id }, item);
			},
		});
	}

	protected removeItemFromCart(model: RemoveExpertProductRequest, item: CartLineClient): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post<CartClient>("/api/cart/remove-expert", model)
			.then(() => {
				this.setDataLayer.removeFromCart(toFixed(item.priceSubTotal, 2), [item]);

				window.location.reload();
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
			});
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-tbody) {
	tr td {
		vertical-align: top;
	}
}
</style>
