<template>
	<div v-if="!isGridLayout" class="d-flex p-2 m-2 flex-shrink-0 surface-a">
		<!--	List layout-->
		<Skeleton height="250px" width="200px" />
		<div class="d-flex flex-column ml-1 w-75">
			<div class="d-flex mb-2">
				<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
				<div>
					<Skeleton class="mb-2" width="6rem" />
					<Skeleton class="mb-2" width="12rem" />
				</div>
			</div>
			<div class="">
				<Skeleton class="mb-1" height="1.25rem" width="20%" />
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton class="mb-1" height=".75rem" width="90%" />
				<Skeleton class="mb-1" height=".75rem" width="95%" />
				<Skeleton height=".75rem" width="80%" />
			</div>
		</div>
		<div class="w-25 d-flex flex-column ml-2">
			<Skeleton class="mb-1 ml-auto" height="1.25rem" width="50%" />
		</div>
	</div>

	<div v-else class="col-md-6 col-xxl-4">
		<!--		Grid layout-->
		<div class="p-2 m-2 surface-a">
			<Skeleton height="225px" width="100%" />
			<div class="d-flex mt-2">
				<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
				<div>
					<Skeleton class="mb-2" width="6rem" />
					<Skeleton class="mb-2" width="12rem" />
				</div>
			</div>
			<div class="d-flex flex-column mt-2">
				<Skeleton class="mb-1" height="1.25rem" width="40%" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Skeleton from "primevue/skeleton";
import { Component, Prop } from "vue-facing-decorator";
import { OverviewLayoutType } from "@/views/layout/productOverviewLayout.vue";
import { PropType } from "vue";

@Component({ components: { Skeleton } })
export default class ProductOverviewItemCardSkeleton extends BaseComponent {
	@Prop({ type: String as PropType<OverviewLayoutType>, required: true, default: "grid" })
	type!: OverviewLayoutType;

	get isGridLayout(): boolean {
		return this.type === "grid";
	}
}
</script>

<style scoped lang="scss"></style>
