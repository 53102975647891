<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="block-bg-circles">
			<svg viewBox="0 0 1154 1154">
				<g fill="#FFF" fill-rule="evenodd">
					<circle cx="577" cy="577" opacity=".055" r="577" />
					<circle cx="577.5" cy="576.5" opacity=".045" r="405.5" />
				</g>
			</svg>
		</div>
		<div class="container">
			<div class="row" :class="contentAlignmentClass">
				<div class="mb-2" :class="[textAlignmentClass(vm.alignment), colSizeClass]">
					<h2 v-if="vm.title" class="" v-html="vm.title"></h2>
					<h3 v-if="vm.subtitle" class="display-5 mb-0" v-text="vm.subtitle"></h3>
				</div>
				<div
					class="d-flex flex-wrap mb-2 mt-auto col-auto gap-2"
					:class="[
						contentAlignmentClass,
						!vm.ctaRight && vm.alignment === horizontalAlignment.Center ? 'mx-auto' : '',
					]"
				>
					<LinkButton
						v-if="vm.ctaLeft"
						:class="{
							'p-button-lg': vm.size === size.large,
							[ctaButtonClass(vm.ctaLeftStyle)]: ctaButtonClass(vm.ctaLeftStyle),
							'p-button-text p-button-raised p-button-arrow':
								!ctaButtonClass(vm.ctaLeftStyle) && vm.ctaRight,
							'p-button-success p-button-raised p-button-arrow':
								!ctaButtonClass(vm.ctaLeftStyle) && !vm.ctaRight,
						}"
						:label="vm.ctaLeft.text"
						:target="vm.ctaLeft.target"
						:title="vm.ctaLeft.title"
						:url="vm.ctaLeft.url"
					/>
					<LinkButton
						v-if="vm.ctaRight"
						:class="{
							'p-button-lg': vm.size === size.large,
							[ctaButtonClass(vm.ctaRightStyle)]: ctaButtonClass(vm.ctaRightStyle),
							'p-button-success p-button-raised p-button-arrow': !ctaButtonClass(vm.ctaRightStyle),
						}"
						:label="vm.ctaRight.text"
						:target="vm.ctaRight.target"
						:title="vm.ctaRight.title"
						:url="vm.ctaRight.url"
					/>
				</div>
			</div>
			<div class="row">
				<div
					v-if="vm.bottomText || vm.ctaBottom"
					class="col-12 d-flex mt-2"
					:class="contentAlignmentClass"
				>
					<p class="lead pr-2">
						{{ vm.bottomText }}
						<a
							v-if="vm.ctaBottom"
							:class="{
								'text-primary':
									vm.backgroundColor === blockBackgroundColor.Grey ||
									vm.backgroundColor === blockBackgroundColor.Pink ||
									vm.backgroundColor === blockBackgroundColor.White,
								'text-white':
									vm.backgroundColor === blockBackgroundColor.Purple ||
									vm.backgroundColor === blockBackgroundColor.Green,
							}"
							:href="vm.ctaBottom.url"
							:target="vm.ctaBottom.target"
							:title="vm.ctaBottom.title"
						>
							{{ vm.ctaBottom.text }}
						</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { CallToActionDefaultBlockViewModel } from "@/types/viewModels/blocks/callToActionDefaultBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { HorizontalAlignment } from "@/types/enum/horizontalAlignment";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { Size } from "@/types/enum/size";

@Component
export default class CallToActionDefaultBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<CallToActionDefaultBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: CallToActionDefaultBlockViewModel;

	created(): void {
		Log.info("CallToActionDefaultBlock", this.vm);
	}

	get size(): typeof Size {
		return Size;
	}

	get contentAlignmentClass(): string {
		switch (this.vm.alignment) {
			case HorizontalAlignment.Left:
				return "justify-content-start";
			case HorizontalAlignment.Center:
				return "justify-content-center align-items-center";
			case HorizontalAlignment.Right:
				return "justify-content-end";
			default:
				return "";
		}
	}

	get colSizeClass(): string {
		switch (this.vm.alignment) {
			case HorizontalAlignment.Left:
				return "col-auto";
			case HorizontalAlignment.Center:
				return "col-12";
			case HorizontalAlignment.Right:
				return "col-auto";
			default:
				return "";
		}
	}
}
</script>

<style scoped lang="scss">
.block-bg-circles {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;

	svg {
		left: 50%;
		top: 50%;
		position: absolute;
		width: auto;
		height: 300%;
		min-height: 900px;
		transform: translate(-50%, -55%);
	}
}
</style>
