<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '50vw', '1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '40vw' }"
		@hide="cancelTradeIn()"
	>
		<template #header>
			<h3 class="mb-0">{{ $t("myCadac.contractDetail.switchModal.title") }}</h3>
		</template>
		<p v-if="vm.switchTradeInText" class="mb-2" v-html="vm.switchTradeInText"></p>
		<h5>{{ $t("myCadac.contractDetail.switchModal.changeDuration") }}</h5>
		<div class="mb-2">
			<Button
				v-for="option in switchDurationOptions"
				:key="option"
				class="p-button-secondary mr-2"
				:class="{ 'p-button-outlined': selectedDuration !== option }"
				:label="$t('myCadac.contractDetail.switchModal.btnDuration', { duration: option })"
				@click="onDurationChange(option)"
			>
			</Button>
		</div>
		<h5>{{ $t("myCadac.contractDetail.switchModal.chooseContract") }}</h5>
		<DataTable
			v-if="tradeInOptions.length"
			v-model:selection="selectedProduct"
			auto-layout
			data-key="productName"
			selection-mode="single"
			:value="tradeInOptions"
		>
			<Column
				field="productName"
				:header="$t('myCadac.contractDetail.table.colProductName')"
				:sortable="false"
			>
				<template #body="item">
					<div class="d-flex align-items-center">
						<Avatar
							v-if="item.data.icon"
							:alt="item.data.iconAlt"
							class="mr-1 bg-transparent"
							:image="item.data.icon"
							shape="square"
							size="large"
							:title="item.productName"
						>
						</Avatar>
						<div class="d-flex flex-column">
							<small class="mb-0 text-muted d-block">
								{{ item.data.productBrand }}
							</small>
							<p class="mb-0 text-bold">
								{{ formatProductName(item.data.productName, item.data.productBrand) }}
							</p>
						</div>
					</div>
				</template>
			</Column>
			<Column
				field="deployment"
				:header="$t('myCadac.contractDetail.table.colProductLicenceType')"
				style="width: 15%"
			>
				<template #body="item">
					{{ $t(`enum.deployment.${getProductVariation(item.data).licenseAudience}`) }}
				</template>
			</Column>
			<Column
				field="renewSeats"
				:header="$t('myCadac.contractDetail.table.colProductSeats')"
				style="width: 15%"
			/>
			<Column
				body-class="text-lg-right pr-2"
				:header="$t('myCadac.contractDetail.table.colProductPrice')"
				header-class="text-lg-right pr-2"
				style="width: 15%"
			>
				<template #body="item">
					<b>{{
						formatCurrency(getPricePerSeat(getProductVariation(item.data).price, item.data.renewSeats))
					}}</b>
				</template>
			</Column>
		</DataTable>
		<template #footer>
			<div class="col-md-10 col-lg-8 col-xl-6 ml-auto pr-2 pb-1">
				<PriceSummary v-if="priceSummary" :vm="priceSummary" />
			</div>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="cancelTradeIn()" />
			<Button :disabled="!canSubmit" :label="$t('common.confirm')" @click="confirmTradeIn()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Emit, Prop } from "vue-facing-decorator";

import { IContractDetail } from "@/types/viewModels/myCadac/contractDetailViewModel";
import { ContractLineClient } from "@/types/generated/Api/contractLineClient";
import { SwitchProductClient } from "@/types/generated/Api/switchProductClient";
import { reduce, uniq } from "lodash";
import { ProductVariationClient } from "@/types/generated/Api/productVariationClient";
import { addMonths } from "date-fns";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component({ emits: ["cancel-trade-in", "confirm-trade-in"] })
export default class ContractSwitchModal extends BaseComponent {
	@Prop({ type: Object as PropType<IContractDetail>, required: true, default: {} })
	vm!: IContractDetail;
	@Prop()
	contractLine!: ContractLineClient;
	isVisible = true;
	selectedDuration = 12;
	selectedProduct: SwitchProductClient | null = null;

	get tradeInOptions(): SwitchProductClient[] {
		return reduce(
			this.contractLine?.switchProducts as SwitchProductClient[],
			(result, value) => {
				const switchProduct = {
					...value,
					switchVariations: value.switchVariations.filter(
						(variation) => variation.duration === this.selectedDuration
					),
				};
				if (switchProduct.switchVariations.length) result.push(value);
				return result;
			},
			[] as SwitchProductClient[]
		);
	}

	get switchDurationOptions(): number[] {
		return reduce(
			this.contractLine?.switchProducts as SwitchProductClient[],
			(result, value) => {
				const durations = value.switchVariations.map((x) => x.duration);
				result.push(...durations);
				return uniq(result);
			},
			[] as number[]
		);
	}

	get selectedProductVariation(): ProductVariationClient {
		return this.selectedProduct?.switchVariations.find((x) => x.duration === this.selectedDuration);
	}

	get priceSummary(): IPriceSummary | null {
		if (!this.selectedProductVariation) return null;
		const { basePrice, customerPrice } = this.selectedProductVariation;
		const seats = this.selectedProduct?.renewSeats || 0;
		const totalBasePrice = this.getPricePerSeat(basePrice, seats);
		const totalPersonalPrice = this.getPricePerSeat(customerPrice, seats);
		const totalDiscount = totalBasePrice - totalPersonalPrice;

		return {
			totalBasePrice: totalBasePrice,
			totalPersonalPrice: totalPersonalPrice,
			totalDiscount: totalDiscount,
		} as IPriceSummary;
	}

	get newEndDate(): string {
		return FormatHelper.formatDateString(
			addMonths(new Date(), this.vm.contract.duration).toISOString(),
			this.$i18n.locale
		);
	}

	get canSubmit(): boolean {
		return !!this.selectedProduct;
	}

	@Emit()
	cancelTradeIn(): boolean {
		return false;
	}

	@Emit()
	confirmTradeIn(): SwitchProductClient | null {
		const product = this.selectedProduct;
		if (!product) return null;
		product.switchVariations = [this.selectedProductVariation];
		return product;
	}

	created(): void {
		this.selectedDuration = this.switchDurationOptions.includes(this.vm.contract.duration)
			? this.vm.contract.duration
			: this.switchDurationOptions[0];
	}

	getProductVariation(switchProduct: SwitchProductClient): ProductVariationClient {
		return switchProduct.switchVariations.find((x) => x.duration === this.selectedDuration);
	}

	getPricePerSeat(price: number, seats: number): number {
		return price * seats;
	}

	onDurationChange(newDuration: number): void {
		this.selectedDuration = newDuration;
		this.selectedProduct = null;
	}
}
</script>

<style scoped lang="scss"></style>
