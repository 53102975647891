<template>
	<DataTable breakpoint="1150px" data-key="id" responsive-layout="stack" :value="editingCartLines">
		<template v-if="tableTitle" #header>{{ tableTitle }}</template>

		<Column
			field="productName"
			:header="isSmallScreen ? '' : $t('cart.table.colName')"
			:style="showProductInfoColumn ? { width: '20%' } : {}"
		>
			<template #body="item">
				<ColumnProductName :item="item.data">
					<div class="product-actions align-items-lg-start flex-lg-row ml-lg-0">
						<Button
							v-if="canChangeConfiguration(item.data) && item.data.url"
							class="p-button-secondary p-button-text flex-shrink-0 hidden-print px-0 mr-lg-1"
							icon="pi pi-cog"
							:label="$t('cart.button.editConfiguration')"
							:title="$t('cart.button.editConfiguration')"
							@click="
								openUrl(`${item.data.url}?lineitemid=${item.data.id}`);
								$event.target.disabled = true;
							"
						/>
						<Button
							v-if="canRemoveItem(item.data)"
							class="p-button-secondary p-button-text flex-shrink-0 hidden-print px-0 mr-2 mr-lg-0"
							icon="pi pi-times"
							:label="$t('cart.button.removeItem')"
							:title="$t('cart.button.removeItem')"
							@click="confirmRemoveItem(item.data)"
						/>
					</div>
				</ColumnProductName>
			</template>
		</Column>
		<Column v-if="showProductInfoColumn">
			<template #body="item">
				<ColumnProductInfo :item="item.data"></ColumnProductInfo>
			</template>
		</Column>
		<Column
			v-if="showServiceTypeColumn"
			key="serviceType"
			field="serviceType"
			:header="$t('cart.table.colType')"
			style="width: 10%"
		/>
		<Column
			v-if="showUnitColumn"
			key="unit"
			field="unit"
			:header="$t('cart.table.colUnit')"
			style="width: 10%"
		/>
		<Column
			v-if="showSerialNumberColumn"
			key="serialNumber"
			field="serialNumber"
			:header="$t('cart.table.colSerialNumber')"
			style="width: 12.5%"
		/>
		<Column
			v-if="showTrainingTypeColumn"
			key="trainingType"
			field="trainingMethod"
			:header="$t('cart.table.colType')"
			style="width: 12.5%"
		/>
		<Column
			v-if="showAmountColumn"
			key="amount"
			class="flex-wrap"
			field="amount"
			:header="$t('cart.table.colAmount')"
			style="width: 12.5%"
		>
			<template #body="item">
				<div class="d-flex align-items-center">
					<ColumnAmount
						:disabled="isSpinnerVisible(item.data.id)"
						:editable="canAdjustAmount(item.data)"
						:item="item.data"
						@amount-changed="onAmountChanged($event, item.index)"
					/>
				</div>
			</template>
		</Column>
		<Column :header="isSmallScreen ? '' : $t('cart.table.colPrice')" style="width: 25%">
			<template #body="item">
				<div v-if="!isSpinnerVisible(item.data.id)" class="flex-1">
					<PriceSummary :vm="getPriceSummary(item.data)" />
				</div>
				<div v-else-if="isSpinnerVisible(item.data.id)">
					<i class="pi pi-spin pi-spinner" />
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ColumnAmount, { AmountChangedEvent } from "@/components/commerce/cart/columnAmount.vue";
import ColumnProductInfo from "@/components/commerce/cart/columnProductInfo.vue";
import ColumnProductName from "@/components/commerce/cart/columnProductName.vue";
import ColumnYesNo from "@/components/commerce/cart/columnYesNo.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import { CartContractClient } from "@/types/generated/Api/cartContractClient";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { CouponCode } from "@/types/generated/Api/couponCode";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { ProductUpdateInCart } from "@/types/generated/Api/productUpdateInCart";
import { PropType } from "vue";
import { upperFirst } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { ColumnProductName, PriceSummary, ColumnProductInfo, ColumnYesNo, ColumnAmount } })
export default class CartClientTable extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient[]>, required: true, default: {} }) items!: CartLineClient[];
	@Prop() cartLineType!: CartLineType;
	@Prop({ type: Boolean, required: true, default: false }) isEditable!: boolean;
	@Prop({
		type: Object as PropType<CartContractClient>,
		required: false,
		default: {},
	})
	cartContractClient!: CartContractClient;

	editingCartLines: CartLineClient[] = [];
	tableTitle = "";

	cartStore = useCartStore();

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get canMakeChanges(): boolean {
		return (
			this.isEditable &&
			(this.isContractLine(this.cartContractClient?.cartLineType)
				? this.cartContractClient?.showAsContractLine
				: true)
		);
	}

	get showServiceTypeColumn(): boolean {
		return this.cartLineType === CartLineType.servicePassProduct;
	}

	get showUnitColumn(): boolean {
		return this.cartLineType === CartLineType.servicePassProduct;
	}

	get showTrainingTypeColumn(): boolean {
		return this.cartLineType === CartLineType.trainingProduct;
	}

	get showSerialNumberColumn(): boolean {
		return (
			this.isContractLine(this.cartContractClient?.cartLineType) &&
			!this.cartContractClient?.showAsContractLine
		);
	}

	get showProductInfoColumn(): boolean {
		return this.cartLineType !== CartLineType.support;
	}

	get showAmountColumn(): boolean {
		return this.cartLineType !== CartLineType.walletDeposit;
	}

	@Watch("items") onItemsChanged(val: CartLineClient[]): void {
		this.editingCartLines = val;
	}

	created(): void {
		this.init();
		this.editingCartLines = this.items;
	}

	init(): void {
		switch (this.cartLineType) {
			case CartLineType.product:
				this.tableTitle = this.$t(
					`cart.table.title${this.getUpperCaseCartLineType(CartLineType.product)}`
				);
				break;
			case CartLineType.trainingProduct:
				this.tableTitle = this.$t(
					`cart.table.title${this.getUpperCaseCartLineType(CartLineType.trainingProduct)}`
				);
				break;
			case CartLineType.servicePassProduct:
				this.tableTitle = this.$t(
					`cart.table.title${this.getUpperCaseCartLineType(CartLineType.servicePassProduct)}`
				);
				break;
			case CartLineType.expertProduct:
				this.tableTitle = this.$t(
					`cart.table.title${this.getUpperCaseCartLineType(CartLineType.expertProduct)}`
				);
				break;
			case CartLineType.walletDeposit:
				this.tableTitle = this.$t(
					`cart.table.title${this.getUpperCaseCartLineType(CartLineType.walletDeposit)}`
				);
				break;
			case CartLineType.support:
				this.tableTitle = this.$t(
					`cart.table.title${this.getUpperCaseCartLineType(CartLineType.support)}`
				);
				break;
			case CartLineType.invoiceTotals:
			case CartLineType.paymentInstructions:
			default:
				break;
		}
	}

	canRemoveItem(item: CartLineClient): boolean {
		return this.isEditable && item.canRemoveLine;
	}

	canChangeConfiguration(item: CartLineClient): boolean {
		return this.isEditable && item.canChangeConfiguration;
	}

	canAdjustAmount(item: CartLineClient): boolean {
		return this.canMakeChanges && item.canUpdateAmount;
	}

	getUpperCaseCartLineType(cartLineType: CartLineType): string {
		return upperFirst(this.cartLineTypeEnum[cartLineType]);
	}

	getPriceSummary(item: CartLineClient): IPriceSummary {
		const discountLines = new Map<string, number>();
		if (item.appliedCouponCodes?.length) {
			item.appliedCouponCodes.forEach((code: CouponCode) => {
				discountLines.set(code.name, code.discount);
			});
		}
		return {
			basePrice: item.priceBase,
			discount: item.discount,
			discountLines: discountLines,
			additionalLines: this.getAdditionalPriceLines(item),
			subtotal: item.priceSubTotal,
		};
	}

	isContractLine(item: CartLineType | CartLineClient): boolean {
		const cartLineType = (item as CartLineClient)?.cartLineType || (item as CartLineType);
		return (
			cartLineType === CartLineType.contractCotermProlong ||
			cartLineType === CartLineType.contractCoterm ||
			cartLineType === CartLineType.contractAddition ||
			cartLineType === CartLineType.contractChange ||
			cartLineType === CartLineType.contractSwitch ||
			cartLineType === CartLineType.contractProlong
		);
	}

	getAdditionalPriceLines(item: CartLineClient): Map<string, number> {
		const priceLines = new Map<string, number>();

		if (item.activeInstallation && item.priceOnce) {
			priceLines.set(this.$t("common.priceSummary.installHelp"), item.priceOnce);
		}

		return priceLines;
	}

	onAmountChanged(event: AmountChangedEvent, index: number): void {
		const item = this.editingCartLines[index];

		switch (item.cartLineType) {
			case CartLineType.product:
			case CartLineType.trainingProduct:
			case CartLineType.servicePassProduct:
			case CartLineType.contractChange:
			case CartLineType.contractAddition:
			case CartLineType.contractCoterm:
			case CartLineType.contractCotermProlong:
			case CartLineType.walletDeposit:
			case CartLineType.support:
				this.updateProductInCart({ id: item.id, amount: event.value }, item, event.originalValue);
				break;

			//TODO: Figure out if these can update too
			case CartLineType.contractSwitch:
				break;
			case CartLineType.contractProlong:
				break;
			case CartLineType.expertProduct:
				break;
			case CartLineType.invoiceTotals:
				break;
			case CartLineType.paymentInstructions:
				break;
		}
	}

	confirmRemoveItem(item: CartLineClient): void {
		this.$confirm.require({
			message: this.$t("cart.dialog.confirmRemoveProduct", [item.title]),
			header: this.$t("common.messages.titleImportant"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.removeItemFromCart(item);
			},
		});
	}

	removeItemFromCart(item: CartLineClient): void {
		this.updateProductInCart({ id: item.id, amount: 0 }, item, item.amount);
	}

	private updateProductInCart(
		model: ProductUpdateInCart,
		cartClient: CartLineClient,
		originalValue: number
	): void {
		if (this.isSpinnerVisible(model.id?.toString())) return;
		this.loadingStore.showSpinner(model.id);
		this.loadingStore.showSpinner("priceSummary");
		this.cartStore
			.updateProductInCart(model)
			.then(() => {
				const changeValue = cartClient.priceSubTotal / originalValue;

					if (model.amount < originalValue) {
						const amount = originalValue - model.amount;
						this.setDataLayer.removeFromCart(
                            toFixed(changeValue * amount, 2),
							[{
								id: cartClient.sku,
								name: cartClient.title,
                                productCategory: cartClient.productCategory,
								priceSubTotal: cartClient.priceSubTotal / originalValue,
                                priceBase: cartClient.priceSubTotal / originalValue,
								brand: cartClient.brand,
                                amount: amount,
                                discount: cartClient.discount / originalValue
							}],
						);
					} else {
                        const amount = model.amount - originalValue;
						this.setDataLayer.addToCart(
                            toFixed(changeValue * amount, 2),
                            [{
                                id: cartClient.sku,
                                name: cartClient.title,
                                productCategory: cartClient.productCategory,
                                priceSubTotal: cartClient.priceSubTotal / originalValue,
                                priceBase: cartClient.priceSubTotal / originalValue,
                                brand: cartClient.brand,
                                amount: amount,
                                discount: cartClient.discount / originalValue
                            }],
						);
					}
					setTimeout(() => {
						window.location.reload();
					}, 500)
					
				})
				.catch((err) => {
					cartClient.amount = originalValue;
					Log.error(err);
				})
		}
	}
</script>

<style scoped lang="scss">
.product-actions {
	display: flex;
	flex: 1 1 100%;
	align-items: center;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	margin-left: 0.5rem;
}
</style>
