<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:closable="true"
		:header="$t('expert.reviewModal.header')"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<p class="lead mb-2">
			{{ $t("expert.reviewModal.intro") }}
		</p>

		<form>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['expertReviewBookingForm'].score?.required }">
						{{ $t("expert.reviewModal.lblScore") }}
					</label>
					<Rating v-model="v$['expertReviewBookingForm'].score.$model" :cancel="false" />
					<span v-if="shouldShowError(v$['expertReviewBookingForm'].score)">
						<small v-if="!v$['expertReviewBookingForm'].score.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].score?.required?.$message }}
						</small>
						<small v-else-if="v$['expertReviewBookingForm'].score.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].score.minValue?.$message }}
						</small>
					</span>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['expertReviewBookingForm'].title?.required }">
						{{ $t("expert.reviewModal.lblProjectTitle") }}
					</label>
					<InputText v-model="v$['expertReviewBookingForm'].title.$model" />
					<span v-if="shouldShowError(v$['expertReviewBookingForm'].title)">
						<small v-if="!v$['expertReviewBookingForm'].title.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].title?.required?.$message }}
						</small>
						<small v-else-if="v$['expertReviewBookingForm'].title.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].title.minLength?.$message }}
						</small>
					</span>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['expertReviewBookingForm'].description?.required }">
						{{ $t("expert.reviewModal.lblDescription") }}
					</label>
					<Textarea
						v-model="v$['expertReviewBookingForm'].description.$model"
						:class="{ 'p-invalid': shouldShowError(v$['expertReviewBookingForm'].description) }"
						rows="5"
						type="text"
					/>
					<span v-if="shouldShowError(v$['expertReviewBookingForm'].description)">
						<small v-if="!v$['expertReviewBookingForm'].description.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].description?.required?.$message }}
						</small>
						<small v-else-if="v$['expertReviewBookingForm'].description.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].description.minLength?.$message }}
						</small>
					</span>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['expertReviewBookingForm'].expertises?.required }">
						{{ $t("expert.reviewModal.lblExpertises") }}
					</label>
					<div class="p-inputgroup">
						<AutoComplete
							v-model="v$['expertReviewBookingForm'].expertises.$model"
							force-selection
							:min-length="2"
							:multiple="true"
							option-label="name"
							:placeholder="$t('expert.reviewModal.autocomplete_placeholder')"
							:suggestions="searchExpertiseResults"
							@complete="searchExpertises($event)"
						>
							<template #option="{ option }"
								>>
								<div class="d-flex">
									<p class="mb-0 mr-1 text-bold">
										{{ option?.name }}
									</p>
									<p class="mb-0">({{ option?.category }})</p>
								</div>
							</template>
						</AutoComplete>
						<Button class="p-button-primary" icon="pi pi-search" />
					</div>
					<span v-if="shouldShowError(v$['expertReviewBookingForm'].expertises)">
						<small v-if="!v$['expertReviewBookingForm'].expertises.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].expertises?.required?.$message }}
						</small>
						<small v-else-if="v$['expertReviewBookingForm'].expertises.$model" class="p-error">
							{{ v$["expertReviewBookingForm"].expertises.minLength?.$message }}
						</small>
					</span>
				</div>
			</div>
		</form>
		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :label="$t('common.confirm')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import Rating from "primevue/rating";
import Textarea from "primevue/textarea";
import useVuelidate, { Validation } from "@vuelidate/core";
import { Component, Emit } from "vue-facing-decorator";
import { ExpertExpertiseBlockViewModel } from "@/types/models/expert/expertExpertiseBlockViewModel";
import { ExpertiseSearch } from "@/types/generated/Api/expertiseSearch";
import { Log } from "@/types/helpers/logHelper";
import { minLength, minValue, required } from "@/types/utils/i18n-validators";
import { useExpertStore } from "@/store/expert/expertStore";

export interface ExpertReviewBookingForm {
	score: number;
	title: string;
	description: string;
	expertises: number[];
}

@Component({
	components: { Textarea, Rating, AutoComplete },
	options: {
		validations: {
			expertReviewBookingForm: {
				title: { required, minLength: minLength(5) },
				description: { required, minLength: minLength(120) },
				score: { required, minValue: minValue(1) },
				expertises: { required, minLength: minLength(1) },
			},
		},
	},
})
export default class ExpertReviewBookingModal extends BaseComponent {
	@Emit("submit")
	submitForm(): ExpertReviewBookingForm {
		const form = { ...this.expertReviewBookingForm };
		form.expertises = this.v$["expertReviewBookingForm"]?.expertises?.$model?.map((x) => x.id);
		return form;
	}

	expertStore = useExpertStore();
	v$ = useVuelidate();
	expertReviewBookingForm!: ExpertReviewBookingForm;
	submitted = false;
	isVisible = true;

	searchExpertisesQuery = "";
	searchExpertiseResults: ExpertExpertiseBlockViewModel[] = [];

	created(): void {
		this.expertReviewBookingForm = {
			title: "",
			description: "",
			expertises: [],
			score: 0,
		};
	}

	get canSubmit(): boolean {
		return !this.v$["expertReviewBookingForm"].$invalid;
	}

	get hasNoSearchResults(): boolean {
		return !!this.searchExpertiseResults?.length;
	}

	onCancel(): void {
		this.expertStore.hideModal("ReviewBooking");
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.submitForm();
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	searchExpertises({ query }: { query: string }): void {
		this.searchExpertisesQuery = query;
		this.axios
			.get(`/api/search/expertises`, { params: { search: query }, requestId: "searchExpertise" })
			.then(({ data }: { data: ExpertiseSearch[] }) => {
				this.searchExpertiseResults = data
					.map((x) => ({ id: x.value, name: x.name, category: x.category }))
					.filter(
						(x) =>
							this.v$["expertReviewBookingForm"]?.expertises?.$model?.find((y) => y.id !== x.id) ||
							true
					);
			})
			.catch((err) => Log.error(err));
	}
}
</script>

<style scoped lang="scss">
:deep(.p-rating) {
	.p-rating-icon {
		font-size: 1.5rem;
	}
}
</style>
