<template>
	<div class="container mt-3 mb-4">
		<div class="row justify-content-center">
			<div class="col-lg-10">
				<slot name="breadcrumbs" />
				<div v-if="activeStep < 3" class="d-flex align-items-end">
					<div class="mb-2">
						<h1 class="mb-1">
							{{ vm.pageTitle }}
						</h1>
						<p v-if="vm.pageIntro" class="lead text-preline">{{ vm.pageIntro }}</p>

						<Message
							v-if="!layoutStore.isAuthenticated"
							class="p-1"
							:closable="false"
							severity="custom"
						>
							<i18n-t keypath="support.message.loginForFasterAssistance">
								<template #loginLink>
									<a :href="loginPageUrl">{{ $t("common.login") }}</a>
								</template>
							</i18n-t>
						</Message>
					</div>
					<img
						v-if="!isSmallScreen"
						v-lazy="'/Static/images/support.png'"
						alt=""
						class="ml-auto"
						height="144"
						width="150"
					/>
				</div>
				<Card>
					<template v-if="activeStep < 3" #header>
						<h6 class="text-center pt-1">
							{{ $t("common.stepXOfY", [activeStep, 2]) }}
						</h6>
						<ProgressBar
							class="mx-2"
							:show-value="false"
							style="height: 1rem"
							:value="activeStep * 50"
						/>
					</template>
					<template #content>
						<div v-if="activeStep === 1">
							<SelectButton
								v-model="selectedMainCategory"
								class="row m-0"
								data-key="id"
								:options="filteredMainCategories"
								@change="updateQueryParams"
							>
								<template #option="slotProps">
									<div class="d-flex flex-column align-items-center p-3">
										<div
											class="d-flex flex-column align-items-center"
											:class="{
												'p-disabled':
													!layoutStore.isAuthenticated &&
													isSupportCategory(slotProps.option.categoryType),
											}"
										>
											<Avatar
												class="mb-2"
												:image="slotProps.option.icon"
												shape="square"
												size="large"
											/>
											<p class="font-weight-bold lead text-primary">
												{{ slotProps.option.title }}
											</p>
											<p class="mb-0 lead">{{ slotProps.option.subtitle }}</p>
										</div>
										<p
											v-if="
												!layoutStore.isAuthenticated &&
												isSupportCategory(slotProps.option.categoryType)
											"
											class="mt-2 mb-0 font-weight-normal"
										>
											<i18n-t keypath="support.message.loginForSupport">
												<template #loginLink>
													<a :href="loginPageUrl">{{ $t("common.login") }}</a>
												</template>
											</i18n-t>
										</p>
									</div>
								</template>
							</SelectButton>
							<Button
								v-if="otherMainCategory"
								class="my-2 p-0 p-button-text p-button-secondary p-button-link"
								:label="otherMainCategory?.title"
								@click="selectedMainCategory = otherMainCategory"
							/>
							<div v-if="selectedMainCategory">
								<h3 class="my-2">
									{{ selectedMainCategory?.subCategoriesTitle }}
								</h3>
								<SelectButton
									v-show="availableSubCategories?.length > 0"
									v-model="selectedSubCategory"
									class="row m-0"
									data-key="id"
									:options="availableSubCategories"
									@change="updateQueryParams"
								>
									<template #option="slotProps">
										<div class="col d-flex flex-column align-items-center p-3">
											<Avatar class="mb-2" :image="slotProps.option.icon" shape="square" />
											<p class="font-weight-bold lead text-primary">
												{{ slotProps.option.title }}
											</p>
										</div>
									</template>
								</SelectButton>
							</div>

							<div v-if="selectedSubCategory">
								<h3 class="my-2">
									{{ selectedSubCategory?.subCategoriesTitle }}
								</h3>
								<SelectButton
									v-if="availableSubSubCategories?.length > 0"
									v-model="selectedSubSubCategory"
									class="row m-0"
									data-key="id"
									:options="availableSubSubCategories"
									@change="updateQueryParams"
								>
									<template #option="slotProps">
										<div class="col d-flex flex-column align-items-center p-3">
											<Avatar class="mb-2" :image="slotProps.option.icon" shape="square" />
											<p class="font-weight-bold lead text-primary">
												{{ slotProps.option.title }}
											</p>
										</div>
									</template>
								</SelectButton>
							</div>
						</div>

						<div v-else-if="activeStep === 2">
							<div class="d-flex align-items-center justify-content-between">
								<p class="category-breadcrumbs">
									<span v-if="selectedMainCategory">{{ selectedMainCategory.title }}</span>
									<span v-if="selectedSubCategory">{{ selectedSubCategory.title }}</span>
									<span v-if="selectedSubSubCategory">{{ selectedSubSubCategory.title }}</span>
								</p>
								<div v-if="hasSelectedSupportCategory">
									<span class="mb-0 text-bold mr-1">{{ $t("support.amountOfTickets") }}</span>
									<i v-badge.secondary="vm.availableAmountOfSupportTickets" class="mr-2">
										<svg fill="#000" height="30" width="34">
											<use xlink:href="#icon-support-avatar" />
										</svg>
									</i>
								</div>
							</div>
							<hr />
							<div v-if="hasSelectedSupportCategory" class="mb-2">
								<h3 v-if="hasSupportProducts && hasTickets">
									{{ vm.ticketsAndSubscriptionsTitle || vm.ticketsAndSubscriptionsSubTitle }}
								</h3>
								<h3 v-else-if="hasSupportProducts && !hasTickets">
									{{ vm.noTicketsButSubscriptionsTitle || vm.noTicketsButSubscriptionsSubTitle }}
								</h3>
								<h3 v-else-if="!hasSupportProducts && hasTickets">
									{{ vm.noSubscriptionsButTicketsTitle || vm.noSubscriptionsButTicketsSubTitle }}
								</h3>
								<p v-else-if="!hasSupportProducts && !hasTickets" class="">
									{{ vm.noSubscriptionsAndNoTicketsSubtitle }}
								</p>

								<div v-if="hasSupportProducts">
									<SelectButton
										v-model="selectedSupportProduct"
										class="p-select-button-grid"
										data-key="sku"
										:options="vm.supportContractSupportAssets"
										@change="onSupportProductSelected"
									>
										<template #option="slotProps">
											<div class="d-flex flex-column align-items-center p-1 w-100">
												<Avatar
													class="mb-1"
													:image="slotProps.option.icon"
													shape="square"
												/>
												<p class="font-weight-bold">
													{{ slotProps.option.title }}
												</p>
											</div>
										</template>
									</SelectButton>
								</div>

								<div v-if="hasTickets">
									<Divider v-if="hasSupportProducts">
										{{ $t("common.or") }}
									</Divider>

									<h3>
										{{ vm.ticketsAndSubscriptionsOtherSoftwareTitle }}
									</h3>

									<div v-if="!hasSelectedSupportTicketProduct" class="p-inputgroup">
										<AutoComplete
											v-model="selectedSupportTicketProduct"
											force-selection
											:min-length="2"
											:multiple="false"
											option-label="title"
											:placeholder="$t('myCadac.supportOverview.autocomplete.placeholder')"
											:suggestions="searchProductResults"
											@complete="onSearchSoftware($event)"
											@item-select="onSupportTicketProductSelected"
										>
											<template #item="slotProps">
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex align-items-center">
														<Avatar
															v-if="slotProps.item.icon"
															alt="product-icon"
															class="mr-1 bg-transparent"
															:image="slotProps.item.icon"
															shape="square"
														/>
														<Avatar
															v-else
															alt="product-icon"
															class="mr-1 bg-transparent"
															shape="square"
														>
															<svg height="30" width="30">
																<use xlink:href="#icon-cadac"></use>
															</svg>
														</Avatar>
														<p class="mb-0 text-bold">{{ slotProps.item.title }}</p>
													</div>
												</div>
											</template>
										</AutoComplete>
										<Button class="p-button-primary" icon="pi pi-search" />
									</div>
									<div v-else class="d-flex align-items-center">
										<div class="d-flex flex-column align-items-center p-2 border">
											<Avatar
												class="mb-1"
												:image="selectedSupportTicketProduct.icon"
												shape="square"
											/>
											<p class="font-weight-bold">
												{{ selectedSupportTicketProduct.title }}
											</p>
											<Button
												class="p-button-danger p-button-text"
												icon="pi pi-times"
												:title="$t('common.remove')"
												@click="selectedSupportTicketProduct = null"
											/>
										</div>
									</div>
									<Message
										v-if="hasSelectedSupportTicketProduct"
										class=""
										:closable="false"
										severity="warn"
									>
										{{ $t("support.message.ticketProductWarning") }}
									</Message>
									<Message v-if="hasNoSearchResults" :closable="false" severity="warn">
										{{
											$t("myCadac.supportOverview.autocomplete.msgNoResults", {
												query: searchQuery,
											})
										}}
									</Message>
								</div>

								<div v-else-if="!hasSupportProducts && !hasTickets" class="mb-3">
									<Message class="message-with-button my-3" :closable="false" severity="custom">
										<div class="d-flex align-items-center">
											<i class="pi pi-exclamation-circle text-primary message-icon mr-2" />
											<span>{{ vm.ticketsCtaTitle }}</span>
										</div>
										<LinkButton
											v-if="vm.subscriptionsLinkItem"
											class="p-button-success p-button-arrow m-1"
											:label="vm.subscriptionsLinkItem.text"
											:target="vm.subscriptionsLinkItem.target"
											:title="vm.subscriptionsLinkItem.title"
											:url="vm.subscriptionsLinkItem.url"
										/>
										<LinkButton
											v-if="vm.ticketsLinkItem"
											class="p-button-success p-button-arrow m-1"
											:label="vm.ticketsLinkItem.text"
											:target="vm.ticketsLinkItem.target"
											:title="vm.ticketsLinkItem.title"
											:url="vm.ticketsLinkItem.url"
										/>
									</Message>
								</div>
								<hr />
							</div>
							<div v-if="showForms">
								<AskAQuestionPersonalForm
									v-model="v$"
									ref="personalForm"
									:submitted="submitted"
									@phoneObjectValidationChange="onPhoneObjectValidChanged"
								/>

								<AskAQuestionSupportTicketForm
									v-if="showSupportTicketForm"
									v-model="v$"
									:submitted="submitted"
								/>

								<AskAQuestionMessageForm
									v-model="v$"
									:is-support-question="hasSelectedSupportCategory"
									:submitted="submitted"
								/>

								<vue-recaptcha
									size="normal"
									theme="light"
									@expired="recaptchaValid = false"
									@fail="recaptchaValid = false"
									@verify="recaptchaValid = !!$event"
								/>
								<small v-if="!recaptchaValid && submitted" id="`recaptcha-help`" class="p-error">
									{{ $t("validations.recaptcha") }}
								</small>
							</div>
						</div>
						<div v-if="activeStep === 3" class="p-2">
							<p class="lead" v-html="vm.requestSuccessMessage" />
							<LinkButton
								v-if="vm.requestSuccessCtaLinkItem"
								class=""
								:label="vm.requestSuccessCtaLinkItem.text"
								:target="vm.requestSuccessCtaLinkItem.target"
								:title="vm.requestSuccessCtaLinkItem.title"
								:url="vm.requestSuccessCtaLinkItem.url"
							/>
						</div>
					</template>
					<template #footer>
						<div class="d-flex justify-content-end">
							<Button
								v-if="activeStep === 2"
								class="p-button-secondary p-button-outlined px-3 mb-1 mr-1"
								:label="$t(`common.previous`)"
								@click="activeStep = 1"
							/>
							<Button
								v-if="activeStep < 3"
								class="p-button-success p-button-arrow p-button-raised mb-1"
								:disabled="
									activeStep > 1 ? !showForms || (submitted && !formsValid) : !canGoToNextStep
								"
								:label="activeStep > 1 ? $t(`common.send`) : $t(`common.nextStep`)"
								@click="goToNextStep()"
							/>
						</div>
					</template>
				</Card>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import AskAQuestionMessageForm from "@/components/support/askAQuestionMessageForm.vue";
import AskAQuestionPersonalForm from "@/components/support/askAQuestionPersonalForm.vue";
import AskAQuestionSupportTicketForm from "@/components/support/askAQuestionSupportTicketForm.vue";
import AutoComplete from "primevue/autocomplete";
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import Divider from "primevue/divider";
import ProgressBar from "primevue/progressbar";
import SelectButton from "primevue/selectbutton";
import useVuelidate, { ValidatorFn } from "@vuelidate/core";
import { AskAQuestionCategoryType } from "@/types/enum/askAQuestionCategoryType";
import { AskAQuestionMessageFormModel } from "@/types/models/form/askAQuestionTicketMessageForm";
import { AskAQuestionPersonalFormModel } from "@/types/models/form/askAQuestionPersonalForm";
import { AskAQuestionSubCategoryType } from "@/types/enum/askAQuestionSubCategoryType";
import { AskAQuestionSupportTicketFormModel } from "@/types/models/form/askAQuestionSupportTicketForm";
import { Component, Prop, Ref, Watch } from "vue-facing-decorator";
import { ISupportAskQuestion } from "@/types/viewModels/commerce/supportAskQuestionViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SubmitSupportTicketRequest } from "@/types/models/support/submitSupportTicketRequest";
import { SupportVariationViewModel } from "@/types/models/support/supportVariationViewModel";
import { TicketFormHeadCategory } from "@/types/models/support/ticketFormHeadCategory";
import { TicketFormSubCategory } from "@/types/models/support/ticketFormSubCategory";
import { TicketFormSubSubCategory } from "@/types/models/support/ticketFormSubSubCategory";
import { ValidationHelper } from "@/types/helpers/validationHelper";
import { email, maxLength, minLength, required, withI18nMessage } from "@/types/utils/i18n-validators";
import { merge } from "lodash";
import { ScrollHelper } from "../../types/helpers/scrollHelper";

@Component({
	components: {
		AskAQuestionMessageForm,
		AskAQuestionPersonalForm,
		AskAQuestionSupportTicketForm,
		AutoComplete,
		BackButton,
		Divider,
		ProgressBar,
		SelectButton,
	},
	options: {
		validations: {
			askAQuestionSupportTicketForm: {
				impact: { required },
				urgency: { required },
			},
			askAQuestionMessageForm: {
				subject: { required, maxLength: maxLength(255) },
				message: { required, minLength: minLength(120), maxLength: maxLength(15000) },
				attachments: {},
			},
			askAQuestionPersonalForm: {
				firstName: { required },
				lastName: { required },
				email: { required, email },
				company: { required },
				phoneNumber: {
					required,
					validPhone: withI18nMessage(ValidationHelper.validatePhoneObjectValid as ValidatorFn),
				},
				cadacAccountId: {},
			},
		},
	},
})
export default class SupportAskQuestion extends BaseComponent {
	@Prop({ type: Object as PropType<ISupportAskQuestion>, required: true, default: {} })
	vm!: ISupportAskQuestion;
	@Ref readonly personalForm!: AskAQuestionPersonalForm;

	selectedMainCategory!: TicketFormHeadCategory | null;
	selectedSubCategory!: TicketFormSubCategory | null;
	selectedSubSubCategory!: TicketFormSubSubCategory | null;
	selectedSupportProduct!: SupportVariationViewModel | null;
	selectedSupportTicketProduct!: SupportVariationViewModel | null;

	availableSubCategories: TicketFormSubCategory[] = [];
	availableSubSubCategories: TicketFormSubSubCategory[] = [];
	searchProductResults: SupportVariationViewModel[] = [];

	askAQuestionMessageForm!: AskAQuestionMessageFormModel;
	askAQuestionPersonalForm!: AskAQuestionPersonalFormModel;
	askAQuestionSupportTicketForm!: AskAQuestionSupportTicketFormModel;

	activeStep = 1;
	hasNoSearchResults = false;
	phoneObjectValid = false;
	searchQuery = "";
	submitted = false;
	recaptchaValid = false;

	v$ = useVuelidate();

	@Watch("selectedMainCategory") onMainCategoryChanged(
		val: TicketFormHeadCategory,
		prev: TicketFormHeadCategory
	): void {
		if (val && this.isSupportCategory(val.categoryType) && !this.layoutStore.isAuthenticated) {
			this.selectedMainCategory = prev;
		} else {
			this.selectedSubCategory = null;
			this.selectedSubSubCategory = null;
		}
		if (this.selectedMainCategory) {
			if (this.selectedMainCategory.categoryType === AskAQuestionCategoryType.Other) {
				this.updateQueryParams();
				this.goToNextStep();
			}
			this.availableSubCategories = this.selectedMainCategory.subCategories;
		}
	}

	@Watch("selectedSubCategory") onSelectedSubCategoryChanged(): void {
		this.selectedSubSubCategory = null;
		if (this.selectedSubCategory) {
			this.availableSubSubCategories = this.selectedSubCategory.subSubCategories;
		}
	}

	created(): void {
		this.initForm();
		this.initStateFromQuery();
	}

	get hasSelectedSupportCategory(): boolean {
		return this.isSupportCategory(this.selectedMainCategory?.categoryType as AskAQuestionCategoryType);
	}

	get hasSelectedIncidentCategory(): boolean {
		return this.selectedSubCategory?.subCategoryType === AskAQuestionSubCategoryType.Incident;
	}

	get hasSelectedSupportProduct(): boolean {
		return !!this.selectedSupportProduct?.id;
	}

	get hasSelectedSupportTicketProduct(): boolean {
		return !!this.selectedSupportTicketProduct?.id;
	}

	get isSupportCategory(): (categoryType: AskAQuestionCategoryType) => boolean {
		return (categoryType) => categoryType === AskAQuestionCategoryType.Support;
	}

	get hasTickets(): boolean {
		return this.vm.availableAmountOfSupportTickets > 0;
	}

	get showForms(): boolean {
		return (
			!this.hasSelectedSupportCategory ||
			(this.hasSupportProducts && !!this.selectedSupportProduct) ||
			(this.hasTickets && !!this.selectedSupportTicketProduct)
		);
	}

	get showSupportTicketForm(): boolean {
		return this.hasSelectedSupportCategory && this.hasSelectedIncidentCategory;
	}

	get hasSupportProducts(): boolean {
		return this.vm.supportContractSupportAssets?.length > 0;
	}

	get filteredMainCategories(): TicketFormHeadCategory[] {
		return this.vm.ticketFormCategoryTree.filter((x) => x.categoryType !== AskAQuestionCategoryType.Other);
	}

	get otherMainCategory(): TicketFormHeadCategory {
		return this.vm.ticketFormCategoryTree.find(
			(x) => x.categoryType === AskAQuestionCategoryType.Other
		) as TicketFormHeadCategory;
	}

	get subCategoryTitle(): string | undefined {
		return this.selectedMainCategory?.subCategoriesTitle;
	}

	get canGoToNextStep(): boolean {
		return (
			(this.selectedMainCategory && this.selectedMainCategory.subCategories?.length < 1) ||
			(this.selectedSubCategory && this.selectedSubCategory.subSubCategories?.length < 1) ||
			(!!this.selectedSubCategory && !!this.selectedSubSubCategory)
		);
	}

	get formsValid(): boolean {
		return (
			this.recaptchaValid &&
			((!this.v$["askAQuestionPersonalForm"].$invalid &&
				!this.v$["askAQuestionSupportTicketForm"].$invalid &&
				!this.v$["askAQuestionMessageForm"].$invalid &&
				this.showSupportTicketForm) ||
				(!this.v$["askAQuestionMessageForm"].$invalid && !this.showSupportTicketForm))
		);
	}

	get submitSupportTicketRequestModel(): SubmitSupportTicketRequest | null {
		if (!this.selectedMainCategory) return null;
		const model: SubmitSupportTicketRequest = merge(
			this.v$["askAQuestionPersonalForm"]?.$model,
			this.v$["askAQuestionSupportTicketForm"]?.$model,
			this.v$["askAQuestionMessageForm"]?.$model
		);
		if (this.selectedSupportProduct) {
			model.selectedSupportId = this.selectedSupportProduct?.id;
			model.selectedSupportName = this.selectedSupportProduct?.title;
		} else if (this.selectedSupportTicketProduct) {
			model.selectedSupportId = this.selectedSupportTicketProduct?.id;
			model.selectedSupportName = this.selectedSupportTicketProduct?.title;
		}
		model.mainCategoryId = this.selectedMainCategory?.freshDeskId;
		model.subCategoryId = this.selectedSubCategory?.freshDeskId;
		model.itemId = this.selectedSubSubCategory?.freshDeskId;
		return model;
	}

	goToNextStep(): void {
		if (this.activeStep === 2) {
			this.submitQuestion();
		} else {
			this.activeStep = 2;
			ScrollHelper.scrollTo('.p-card-header');
		}
	}

	onSupportProductSelected(): void {
		if (this.selectedSupportProduct != null) this.selectedSupportTicketProduct = null;
		this.updateQueryParams();
	}

	onSupportTicketProductSelected(): void {
		if (this.selectedSupportTicketProduct != null) this.selectedSupportProduct = null;
		this.updateQueryParams();
	}

	onPhoneObjectValidChanged(val: boolean): void {
		this.phoneObjectValid = val;
	}

	onSearchSoftware({ query }: { query: string }): void {
		this.searchQuery = query;
		const existingIds = this.vm.supportContractSupportAssets.map((x) => x.id).join("|");
		this.axios
			.get(`/api/support/searchvariations`, { params: { query, existingIds } })
			.then(({ data }) => {
				this.searchProductResults = data.results;
				this.hasNoSearchResults = data.results.length <= 0;
			})
			.catch((err) => Log.error(err));
	}

	async submitQuestion() {
		this.submitted = true;
		if (!this.formsValid) return;
		this.loadingStore.increaseLoadingCount();
		const formData = new FormData();
		const model = this.submitSupportTicketRequestModel;
		model?.attachments?.forEach((x) => formData.append("file", x));
		// Delete attachments from the model because files are not serializable.
		delete model?.attachments;
		formData.append("form", JSON.stringify(model));

		this.axios
			.post(`/api/${this.layoutStore.vm.localizationPrefix}/freshdesk/submit-ticket`, formData, {
				requestId: "submit-ticket",
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then(() => {
				this.activeStep = 3;
				this.$toast.add({
					severity: "success",
					detail: this.$t("support.toast.submitQuestionSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					detail: this.$t("support.toast.submitQuestionError"),
					life: 3000,
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
			});
	}

	protected updateQueryParams(): void {
		const queryParams = new URLSearchParams("");
		if (this.selectedMainCategory?.id) queryParams.set("cat", this.selectedMainCategory.id.toString());
		if (this.selectedSubCategory?.id) queryParams.set("subcat", this.selectedSubCategory.id.toString());
		if (this.selectedSubSubCategory?.id)
			queryParams.set("subsubcat", this.selectedSubSubCategory.id.toString());
		if (this.selectedSupportProduct) queryParams.set("sku", this.selectedSupportProduct.id.toString());

		const url = new URL(window.location.href);
		url.search = queryParams.toString();

		if (window.history.replaceState) {
			window.history.replaceState({}, document.title, url.toString());
		}
	}

	private initStateFromQuery(): void {
		const params = new URLSearchParams(window.location.search);

		setTimeout(() => {
			if (params.has("cat")) {
				this.selectedMainCategory = this.vm.ticketFormCategoryTree?.find(
					(x) => x.id === parseInt(params.get("cat") as string)
				) as TicketFormHeadCategory;
			}
		}, 10);
		setTimeout(() => {
			if (params.has("subcat")) {
				this.selectedSubCategory = this.availableSubCategories?.find(
					(x) => x.id === parseInt(params.get("subcat") as string)
				) as TicketFormSubCategory;
			}
		}, 10);
		setTimeout(() => {
			if (params.has("subsubcat")) {
				this.selectedSubSubCategory = this.availableSubSubCategories?.find(
					(x) => x.id === parseInt(params.get("subsubcat") as string)
				) as TicketFormSubSubCategory;
			}
		}, 10);
		setTimeout(() => {
			if (params.has("sku")) {
				this.selectedSupportProduct = this.vm.supportContractSupportAssets?.find(
					(x) => x.sku === params.get("sku")
				) as SupportVariationViewModel;
			}
		}, 10);
	}

	private initForm(): void {
		this.askAQuestionSupportTicketForm = {
			urgency: undefined as any,
			impact: undefined as any,
		};
		this.askAQuestionMessageForm = {
			attachments: [],
			message: "",
			subject: "",
		};
		this.askAQuestionPersonalForm = {
			company: this.userProfile?.company?.companyName || "",
			email: this.userProfile?.email || "",
			firstName: this.userProfile?.firstName || "",
			lastName: this.userProfile?.lastName || "",
			phoneNumber: this.userProfile?.phoneNumber || "",
			cadacAccountId: this.userProfile?.cadacAccountId || "",
		};
	}
}
</script>

<style scoped lang="scss">
.category-breadcrumbs {
	font-weight: 500;
	font-size: 1.1rem;

	span {
		&:not(:first-of-type):before {
			content: ">";
			margin: 0 0.25rem;
			color: var(--primary-color);
			font-weight: 500;
		}
	}
}
</style>
