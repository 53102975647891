<template>
	<div class="d-flex flex-wrap flex-column justify-content-between">
		<div class="d-flex" :class="[labelWrapperClass]">
			<label :class="[labelClass]" :for="name" :title="label">
				{{ label }}
				<span v-if="isRequired"
					  :class="{ required: isRequired }"
					  :title="$t('validations.required')">*</span>
			</label>
			
			<PopoverIcon v-if="info" class="d-inline-flex flex-0 ml-0">
				<i class="popover-icon pi pi-question" />
				<template #content>
					<div v-html="info" />
				</template>
			</PopoverIcon>
		</div>
		<div class="d-flex justify-content-end flex-column">
			<slot :field="field" />
		</div>

		<small v-show="shouldShowError" :id="`${name}-help`" class="p-error">
			{{ field?.errorMessage?.value }}
		</small>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { FieldContext, useField } from "vee-validate";

@Component
export default class InputWithValidation extends BaseComponent {
	@Prop({ type: Boolean, required: false, default: false })
	isRequired!: boolean;

	@Prop({ type: String, required: false })
	label!: string;

	@Prop({ type: String, required: true })
	name!: string;

	@Prop({ type: String, required: false })
	info!: string;

	@Prop({ type: String, required: false })
	labelWrapperClass!: string;

	@Prop({ type: String, required: false })
	labelClass!: string;
	

	field!: FieldContext;
	shouldShowError = false;

	created(): void {
		this.field = useField(() => this.name);
	}

	@Watch("field.meta.touched")
	@Watch("field.errors", { deep: true })
	onErrorChange(): void {
		setTimeout(() => {
			this.shouldShowError = this.field.meta.touched && this.field.errors?.value?.length > 0;
		});
	}
}
</script>

<style scoped lang="scss">
label:first-of-type {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

span.required {
	margin-left: 0.25rem;
	color: var(--primary-color);
	font-weight: 600;
}
</style>
