<template>
	<div class="form-row">
		<div class="form-group col-md-6">
			<label :class="{ required: v$['userLicenceForm'].firstName?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblFirstName") }}
			</label>
			<InputText
				v-model="v$['userLicenceForm'].firstName.$model"
				:class="{ 'p-invalid': shouldShowError(v$['userLicenceForm'].firstName) }"
				type="text"
			/>
			<small v-if="shouldShowError(v$['userLicenceForm'].firstName)" class="p-error">
				{{ v$["userLicenceForm"].firstName?.required?.$message }}
			</small>
		</div>
		<div class="form-group col-md-6">
			<label :class="{ required: v$['userLicenceForm'].lastName?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblLastName") }}
			</label>
			<InputText
				v-model="v$['userLicenceForm'].lastName.$model"
				:class="{ 'p-invalid': shouldShowError(v$['userLicenceForm'].lastName) }"
				type="text"
			/>
			<small v-if="shouldShowError(v$['userLicenceForm'].lastName)" class="p-error">
				{{ v$["userLicenceForm"].lastName?.required?.$message }}
			</small>
		</div>
	</div>
	<div class="form-row">
		<div class="form-group col-md-12">
			<label :class="{ required: v$['userLicenceForm'].email?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblEmail") }}
			</label>
			<div class="p-inputgroup">
				<InputText
					v-model="v$['userLicenceForm'].email.$model"
					:class="{ 'p-invalid': shouldShowError(v$['userLicenceForm'].email) }"
					:disabled="isEditing"
					type="email"
				/>
				<Button
					v-if="canSendEmail"
					class="p-button-outlined"
					icon="pi pi-envelope"
					:title="$t('myCadac.licenceManagementDetail.dialog.btnSendEmail')"
					@click="onResendEmailClick()"
				/>
			</div>
			<small
				v-if="shouldShowError(v$['userLicenceForm'].email) && !v$['userLicenceForm'].email.$model"
				class="p-error"
			>
				{{ v$["userLicenceForm"].email?.required?.$message }}
			</small>
			<small
				v-else-if="shouldShowError(v$['userLicenceForm'].email) && v$['userLicenceForm'].email.$model"
				class="p-error"
			>
				{{ v$["userLicenceForm"].email.email?.$message }}
			</small>
		</div>
	</div>
	<div v-if="selectedLicenceProduct.possibleRoles?.length > 1" class="form-row">
		<div class="form-group col-md-12">
			<label :class="{ required: v$['userLicenceForm'].roles?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblRoles") }}
			</label>
			<div class="d-flex align-items-start flex-wrap">
				<div
					v-for="(role, index) in selectedLicenceProduct.possibleRoles"
					:key="index"
					class="p-field-checkbox mr-2 mb-2"
				>
					<Checkbox
						v-model="v$['userLicenceForm'].roles.$model"
						:id="`role${index}`"
						name="roles"
						:value="role"
					/>
					<label class="ml-1 mb-0" :for="`role${index}`">
						{{ $i18n.locale === "nl" ? role.descriptionNL : role.descriptionEN }}
					</label>
				</div>
			</div>
			<small v-if="shouldShowError(v$['userLicenceForm'].roles)" class="p-error">
				{{ v$["userLicenceForm"].roles?.required?.$message }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import { Component, Prop, VModel } from "vue-facing-decorator";
import { ILicenceManagementDetail } from "@/types/viewModels/myCadac/licenceManagementDetailViewModel";
import { Licence } from "@/types/generated/licence";
import { LicenceProduct } from "@/types/generated/licenceProduct";
import { LicenceRole } from "@/types/generated/licenceRole";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { Validation } from "@vuelidate/core";
import { useLicenceStore } from "@/store/software/licenceStore";

@Component({ components: { Checkbox } })
export default class UserLicenceForm extends BaseComponent {
	@VModel() v$!: Validation;
	@Prop({ type: Object as PropType<ILicenceManagementDetail>, required: true, default: {} })
	vm!: ILicenceManagementDetail;
	@Prop({ required: false, default: false }) submitted!: boolean;

	isEditing = false;
	isLoading = false;
	canSendEmail = false;
	licenceStore = useLicenceStore();

	get selectedLicenceProduct(): LicenceProduct | null {
		return this.licenceStore.selectedLicenceProduct;
	}

	get selectedLicence(): Licence | null {
		return this.licenceStore.selectedLicence;
	}

	get possibleRoles(): string[] {
		return (this.selectedLicenceProduct?.possibleRoles as LicenceRole[]).map((x) =>
			this.$i18n.locale === "nl" ? x.descriptionNL : x.descriptionEN
		) as string[];
	}

	created(): void {
		this.v$["userLicenceForm"].roles.$model = [];
		this.v$["userLicenceForm"].$reset();

		this.isEditing = !!this.selectedLicence?.id;
		this.canSendEmail = this.isEditing && this.vm.licenceProduct.sendLicenceNotification;

		if (this.licenceStore.selectedLicenceProduct?.possibleRoles.length === 1)
			this.v$["userLicenceForm"].roles.$model = this.licenceStore.selectedLicenceProduct?.possibleRoles;
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onResendEmailClick(): void {
		if (this.v$["userLicenceForm"].email.$invalid) return;
		this.$confirm.require({
			header: this.$t("myCadac.licenceManagementDetail.dialog.confirmResendEmailTitle"),
			message: this.$t("myCadac.licenceManagementDetail.dialog.confirmResendEmailMessage", [
				this.v$["userLicenceForm"].email.$model,
			]),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.resendEmail();
			},
		});
	}

	private resendEmail(): void {
		this.isLoading = true;
		this.canSendEmail = false;
		this.axios
			.post(`/api/licence/sendlicencenotification/${this.selectedLicence?.id}`)
			.then(() => {
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.resendEmailSuccess", [
						this.v$["userLicenceForm"].email.$model,
					]),
					life: 3000,
				});
			})
			.catch((err) => {
				this.canSendEmail = true;
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.resendEmailError", [
						this.v$["userLicenceForm"].email.$model,
					]),
					life: 3000,
				});
				Log.error(err);
			})
			.finally(() => (this.isLoading = false));
	}
}
</script>

<style scoped lang="scss"></style>
