import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "display-4"
}
const _hoisted_3 = {
  key: 1,
  class: "lead"
}
const _hoisted_4 = { class: "row justify-content-center align-items-center" }
const _hoisted_5 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.vm.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.vm.title), 1))
        : _createCommentVNode("", true),
      (_ctx.vm.intro)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.vm.intro), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.logos, (logo) => {
          return (_openBlock(), _createElementBlock("div", {
            key: logo,
            class: "col-6 col-sm-auto"
          }, [
            _withDirectives(_createElementVNode("img", {
              alt: logo.alt,
              width: "185"
            }, null, 8, _hoisted_5), [
              [_directive_lazy, logo.url]
            ])
          ]))
        }), 128))
      ])
    ])
  ], 2))
}