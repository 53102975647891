import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-column" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "d-flex px-1 flex-column" }
const _hoisted_4 = { class: "mb-0 text-muted d-block" }
const _hoisted_5 = { class: "mb-0 text-bold" }
const _hoisted_6 = {
  key: 1,
  class: "d-flex mr-lg-1 mb-1"
}
const _hoisted_7 = { class: "d-flex px-1 flex-column" }
const _hoisted_8 = { class: "mb-0 text-muted d-block" }
const _hoisted_9 = { class: "mb-0 text-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.item.isHidden && _ctx.item.url)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "d-flex mr-lg-1 mb-1",
          href: _ctx.item.url
        }, [
          (_ctx.item.icon)
            ? (_openBlock(), _createBlock(_component_Avatar, {
                key: 0,
                alt: _ctx.item.iconAltTag,
                class: "flex-shrink-0",
                image: _ctx.item.icon,
                shape: "square",
                size: "large",
                title: _ctx.productName
              }, null, 8, ["alt", "image", "title"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.item.brand), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.productName), 1)
          ])
        ], 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.item.icon)
            ? (_openBlock(), _createBlock(_component_Avatar, {
                key: 0,
                alt: _ctx.item.iconAltTag,
                class: "flex-shrink-0",
                image: _ctx.item.icon,
                shape: "square",
                size: "large",
                title: _ctx.productName
              }, null, 8, ["alt", "image", "title"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.item.brand), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.productName), 1)
          ])
        ])),
    _renderSlot(_ctx.$slots, "default")
  ]))
}