import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "col-lg-3" }
const _hoisted_2 = { class: "overview__controls__search" }
const _hoisted_3 = { class: "autocomplete" }
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Panel, {
      collapsed: _ctx.collapsed,
      "onUpdate:collapsed": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.collapsed) = $event)),
      class: "product-filter-item"
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", {
          class: "p-link lead mb-0 w-100",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, "Zoekterm")
      ]),
      icons: _withCtx(() => [
        _createElementVNode("button", {
          class: "p-panel-header-icon p-link mr-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up')
          }, null, 2)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_InputText, {
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchString) = $event)),
              type: "text"
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["collapsed"]),
    _createVNode(_component_Panel, {
      collapsed: _ctx.collapsed,
      "onUpdate:collapsed": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.collapsed) = $event)),
      class: "product-filter-item"
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", {
          class: "p-link lead mb-0 w-100",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, "Categories")
      ]),
      icons: _withCtx(() => [
        _createElementVNode("button", {
          class: "p-panel-header-icon p-link mr-2",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up')
          }, null, 2)
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
          return (_openBlock(), _createElementBlock("div", {
            key: category.value,
            class: "product-filter-option p-field-checkbox form-group"
          }, [
            _createVNode(_component_Checkbox, {
              id: category.value,
              binary: ""
            }, null, 8, ["id"]),
            _createElementVNode("label", {
              class: _normalizeClass(["ml-2 mb-0 p-link", _ctx.collapsed ? 'font-weight-bold' : 'font-weight-normal']),
              for: category
            }, _toDisplayString(category.label), 11, _hoisted_4)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["collapsed"])
  ]))
}