export class UrlHelper {
	static open(url: string, target?: string): void {
		if (target) window.open(url, target);
		else location.href = url;
	}

	static goBack(): void {
		window.history.back();
	}
}
