import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17ff0c18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "token-card pb-3 pt-2"
}
const _hoisted_3 = { class: "d-flex flex-column justify-content-center align-items-center" }
const _hoisted_4 = { class: "text-primary font-weight-normal text-center" }
const _hoisted_5 = { class: "text-primary" }
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = {
  key: 0,
  class: "token-advice-icon-group d-flex gap-1 mb-3"
}
const _hoisted_8 = {
  key: 1,
  class: "container"
}
const _hoisted_9 = { class: "row mx-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_AdvisorTokenAdviceContent = _resolveComponent("AdvisorTokenAdviceContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tokenAdvice && _ctx.tokenAdvice?.amount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              class: "icon-2",
              icon: ['far', 'coins'],
              size: "3x"
            }),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("myCadac.advisorAdvice.productTitleFlex")), 1),
            _createVNode(_component_i18n_t, {
              class: "mb-2 text-center",
              keypath: "myCadac.advisorAdvice.flexTokenCta",
              scope: "global",
              tag: "p"
            }, {
              amount: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_ctx.tokenAdvice.amount), 1)
              ]),
              price: _withCtx(() => [
                _createElementVNode("b", _hoisted_5, _toDisplayString(_ctx.formatCurrency(_ctx.tokenAdvice.totalPrice)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_i18n_t, {
              class: "mb-2 text-center",
              keypath: "myCadac.advisorAdvice.relatedProductsAdvice",
              scope: "global",
              tag: "p"
            }, {
              firstTokenUse: _withCtx(() => [
                _createElementVNode("b", _hoisted_6, _toDisplayString(_ctx.formatDate(_ctx.tokenAdvice.firstTokenUse)), 1)
              ]),
              _: 1
            }),
            (_ctx.tokenAdvice.relatedProducts.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokenAdvice.relatedProducts.slice(
							0,
							_ctx.maxTokenAdviceRelatedProducts
						), (relatedProduct, index) => {
                    return (_openBlock(), _createBlock(_component_Avatar, {
                      key: `nextStepAdvice_${index}`,
                      image: relatedProduct.iconUrl,
                      size: "large"
                    }, null, 8, ["image"]))
                  }), 128)),
                  (_ctx.tokenAdvice.relatedProducts.length > _ctx.maxTokenAdviceRelatedProducts)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 0,
                        class: "plus-badge",
                        label: `+${_ctx.tokenAdvice.relatedProducts.length - _ctx.maxTokenAdviceRelatedProducts}`,
                        shape: "circle",
                        size: "large"
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.tokenAdvice?.relatedProducts)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_AdvisorTokenAdviceContent, {
                      collapsed: _ctx.collapsed,
                      "related-products": _ctx.tokenAdvice.relatedProducts
                    }, null, 8, ["collapsed", "related-products"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.tokenAdvice && _ctx.tokenAdvice?.amount > 0 && _ctx.tokenAdvice?.relatedProducts)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "expand-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', _ctx.collapsed ? 'chevrons-down' : 'chevrons-up']
          }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true)
  ]))
}