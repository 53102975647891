<template>
	<div class="custom-select-button mb-2">
		<Button
			class="custom-button"
			:class="{ 'p-highlight': isSelected }"
			:disabled="!!$attrs.disabled"
			@click="optionSelected"
		>
			<i v-if="isSelected" class="pi pi-check mr-1" />
			<span class="d-flex flex-wrap align-items-center" style="white-space: pre" v-html="option.label" />
		</Button>

		<div v-for="slotName in uspSlots" :key="slotName" class="select-button-usp">
			<slot :name="slotName" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { keys, startsWith } from "lodash";

@Component
export default class ProductConfigurationOption extends BaseComponent {
	@Prop({
		type: Object as PropType<IDropdownItem>,
		required: true,
		default: {},
	})
	option!: IDropdownItem;

	@Prop({
		type: Boolean,
		required: true,
		default: false,
	})
	isSelected!: boolean;

	@Emit optionSelected(): IDropdownItem {
		return this.option;
	}

	get uspSlots(): string[] {
		return keys(this.$slots).filter((x) => startsWith(x, "usp"));
	}
}
</script>

<style scoped lang="scss">
.custom-select-button {
	width: min-content;
	min-width: fit-content;

	::v-deep(.p-button) {
		background: var(--surface-b);
		border: 1px solid var(--surface-b);
		color: var(--primary-color);
		border-radius: 0.25em;
		padding: 1rem 1.75rem;
		margin-right: 1.25rem;

		&:not(.p-disabled):not(.p-highlight):focus,
		&:not(.p-disabled):not(.p-highlight):hover {
			border-radius: 0.25em;
			background: var(--surface-c);
			border: 1px solid var(--primary-color);
			color: var(--primary-color);
		}

		&.p-highlight {
			border-radius: 0.25em;
			background: var(--surface-e);
			border: 1px solid var(--primary-color);
			color: var(--primary-color);
		}
	}

	.select-button-usp {
		position: relative;
		display: flex;
		align-items: center;
		width: fit-content;
		padding: 0.75rem 0 0 3rem;
		font-size: 0.9rem;
		font-weight: 500;
		text-align: left;
		white-space: nowrap;
		color: var(--primary-color);

		&:empty {
			display: none;
		}

		&::before,
		&::after {
			position: absolute;
			left: 1rem;
			content: "";
			background-color: var(--primary-color);
		}

		&::before {
			top: 0;
			width: 1px;
			height: 1.5rem;
		}

		&::after {
			top: 1.5rem;
			width: 1.5rem;
			height: 1px;
		}

		&:not(:first-of-type) {
			&::before {
				top: -1rem;
				height: 2.5rem;
			}
		}
	}
}
</style>
