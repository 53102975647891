<template>
	<div class="p-2">
		<div class="d-flex mt-2">
			<i class="pi pi-chevron-down p-2 my-auto text-muted"></i>
			<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
			<Skeleton class="mr-2" height="4rem" shape="square" width="2.5rem" />
			<div class="d-flex flex-wrap flex-column flex-1">
				<div class="d-flex align-items-center">
					<Skeleton class="mb-2 mr-1" height="1.6rem" width="5rem" />
					<Skeleton class="mb-2" width="10rem" />
				</div>
				<div class="d-flex flex-1">
					<Skeleton class="mr-1" height="1rem" width="20%" />
					<Skeleton class="" height="1rem" width="80%" />
				</div>
			</div>
		</div>
		<div class="d-flex mt-2">
			<i class="pi pi-chevron-down p-2 my-auto text-muted"></i>
			<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
			<Skeleton class="mr-2" height="4rem" shape="square" width="2.5rem" />
			<div class="d-flex flex-wrap flex-column flex-1">
				<div class="d-flex align-items-center">
					<Skeleton class="mb-2 mr-1" height="1.6rem" width="5rem" />
					<Skeleton class="mb-2" width="10rem" />
				</div>
				<div class="d-flex flex-1">
					<Skeleton class="mr-1" height="1rem" width="60%" />
					<Skeleton class="" height="1rem" width="40%" />
				</div>
			</div>
		</div>
		<div class="d-flex mt-2">
			<i class="pi pi-chevron-down p-2 my-auto text-muted"></i>
			<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
			<Skeleton class="mr-2" height="4rem" shape="square" width="2.5rem" />
			<div class="d-flex flex-wrap flex-column flex-1">
				<div class="d-flex align-items-center">
					<Skeleton class="mb-2 mr-1" height="1.6rem" width="5rem" />
					<Skeleton class="mb-2" width="10rem" />
				</div>
				<div class="d-flex flex-1">
					<Skeleton class="mr-1" height="1rem" width="34%" />
					<Skeleton class="" height="1rem" width="66%" />
				</div>
			</div>
		</div>
		<div class="d-flex mt-2">
			<i class="pi pi-chevron-down p-2 my-auto text-muted"></i>
			<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
			<Skeleton class="mr-2" height="4rem" shape="square" width="2.5rem" />
			<div class="d-flex flex-wrap flex-column flex-1">
				<div class="d-flex align-items-center">
					<Skeleton class="mb-2 mr-1" height="1.6rem" width="5rem" />
					<Skeleton class="mb-2" width="10rem" />
				</div>
				<div class="d-flex flex-1">
					<Skeleton class="mr-1" height="1rem" width="89%" />
					<Skeleton class="" height="1rem" width="11%" />
				</div>
			</div>
		</div>
		<div class="d-flex mt-2">
			<i class="pi pi-chevron-down p-2 my-auto text-muted"></i>
			<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
			<Skeleton class="mr-2" height="4rem" shape="square" width="2.5rem" />
			<div class="d-flex flex-wrap flex-column flex-1">
				<div class="d-flex align-items-center">
					<Skeleton class="mb-2 mr-1" height="1.6rem" width="5rem" />
					<Skeleton class="mb-2" width="10rem" />
				</div>
				<div class="d-flex flex-1">
					<Skeleton class="mr-1" height="1rem" width="34%" />
					<Skeleton class="" height="1rem" width="66%" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";
import Skeleton from "primevue/skeleton";

@Component({ components: { Skeleton } })
export default class AdvisorInsightSkeleton extends BaseComponent {}
</script>

<style scoped lang="scss"></style>
