<template>
	<div class="col-12 mb-3">
		<div v-if="tokenAdvice && tokenAdvice?.amount > 0" class="token-card pb-3 pt-2">
			<div class="d-flex flex-column justify-content-center align-items-center">
				<font-awesome-icon class="icon-2" :icon="['far', 'coins']" size="3x" />
				<h3 class="text-primary font-weight-normal text-center">
					{{ $t("myCadac.advisorAdvice.productTitleFlex") }}
				</h3>
				<i18n-t
					class="mb-2 text-center"
					keypath="myCadac.advisorAdvice.flexTokenCta"
					scope="global"
					tag="p"
				>
					<template #amount>
						<b>{{ tokenAdvice.amount }}</b>
					</template>
					<template #price>
						<b class="text-primary">{{ formatCurrency(tokenAdvice.totalPrice) }}</b>
					</template>
				</i18n-t>

				<i18n-t
					class="mb-2 text-center"
					keypath="myCadac.advisorAdvice.relatedProductsAdvice"
					scope="global"
					tag="p"
				>
					<template #firstTokenUse>
						<b class="text-primary">{{ formatDate(tokenAdvice.firstTokenUse) }}</b>
					</template>
				</i18n-t>

				<div v-if="tokenAdvice.relatedProducts.length" class="token-advice-icon-group d-flex gap-1 mb-3">
					<Avatar
						v-for="(relatedProduct, index) in tokenAdvice.relatedProducts.slice(
							0,
							maxTokenAdviceRelatedProducts
						)"
						:key="`nextStepAdvice_${index}`"
						:image="relatedProduct.iconUrl"
						size="large"
					/>

					<Avatar
						v-if="tokenAdvice.relatedProducts.length > maxTokenAdviceRelatedProducts"
						class="plus-badge"
						:label="`+${tokenAdvice.relatedProducts.length - maxTokenAdviceRelatedProducts}`"
						shape="circle"
						size="large"
					/>
				</div>
				<div v-if="tokenAdvice?.relatedProducts" class="container">
					<div class="row mx-1">
						<AdvisorTokenAdviceContent
							:collapsed="collapsed"
							:related-products="tokenAdvice.relatedProducts"
						/>
					</div>
				</div>

				<!--					<Button-->
				<!--						class="p-button-success p-button-arrow p-button-sm"-->
				<!--						:label="$t('digitalAdvisor.button.contactUs')"-->
				<!--						@click="initChatToken()"-->
				<!--					/>-->
			</div>
		</div>
		<!--		simplify v-if, adding this due to time constraint-->
		<div
			v-if="tokenAdvice && tokenAdvice?.amount > 0 && tokenAdvice?.relatedProducts"
			class="expand-button"
			@click="collapsed = !collapsed"
		>
			<font-awesome-icon :icon="['fas', collapsed ? 'chevrons-down' : 'chevrons-up']" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProgressSpinner from "primevue/progressspinner";
import { Component, Prop } from "vue-facing-decorator";
import { TokenAdviceViewModel } from "@/types/models/advisor/tokenAdviceViewModel";
import AdvisorTokenAdvice from "@/components/advisor/advisorTokenAdvice.vue";
import { PropType } from "vue";
import AdvisorTokenAdviceContent from "@/components/advisor/advisorTokenAdviceContent.vue";

@Component({ components: { AdvisorTokenAdviceContent, AdvisorTokenAdvice, ProgressSpinner } })
export default class AdvisorAdviceOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<TokenAdviceViewModel>,
		required: true,
	})
	tokenAdvice!: TokenAdviceViewModel;
	collapsed = true;

	get maxTokenAdviceRelatedProducts(): number {
		if (this.isMobile) {
			return 4;
		} else if (this.isTablet) {
			return 5;
		} else if (this.isLargeScreen) {
			return 6;
		} else return 6;
	}
}
</script>

<style scoped lang="scss">
.token-card {
	position: relative;
	background: #ffffff;
	color: #495057;
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
}

::v-deep(.p-avatar) {
	img {
		background-color: var(--surface-a);
		width: 100%;
		height: 100%;
	}
}
.plus-badge {
	background-color: #949494;
	color: #ffffff;
}

.expand-button {
	position: absolute;
	bottom: 0;
	left: 50%;
	padding: 1rem;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	box-shadow:
		0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	align-items: center;
	display: flex;
	justify-content: center;
	transform: translate(-50%, 50%);
	background-color: #ffffff;

	&:hover {
		background-color: var(--success-color);
		color: #ffffff;
		cursor: pointer;
	}
}
</style>
