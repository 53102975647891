<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor) || 'surface-a'">
		<div class="container">
			<div v-if="isColumnLayout" class="row">
				<div class="col-md-6">
					<h2 v-if="vm.title" class="display-4 mb-1">{{ vm.title }}</h2>
					<p v-if="vm.intro && vm.introPosition === introPosition.belowTitle" class="lead mb-1">
						{{ vm.intro }}
					</p>
				</div>
				<div class="col-md-6">
					<p v-if="vm.intro && vm.introPosition !== introPosition.belowTitle" class="lead mb-2">
						{{ vm.intro }}
					</p>
					<p v-if="vm.text" class="rte" v-html="vm.text" />
					<LinkButton
						v-if="vm.ctaButton"
						class="mt-3"
						:class="ctaButtonClass(vm.ctaStyle) || 'p-button-raised p-button-text p-button-arrow'"
						:label="vm.ctaButton.text"
						:target="vm.ctaButton.target"
						:title="vm.ctaButton.title"
						:url="vm.ctaButton.url"
					/>
				</div>
			</div>
			<div v-else>
				<h2 v-if="vm.title" class="display-4 mb-1">{{ vm.title }}</h2>
				<p v-if="vm.intro" class="lead mb-2">{{ vm.intro }}</p>
				<p v-if="vm.text" class="rte" v-html="vm.text" />
				<LinkButton
					v-if="vm.ctaButton"
					class="mt-3"
					:class="ctaButtonClass(vm.ctaStyle) || 'p-button-raised p-button-text p-button-arrow'"
					:label="vm.ctaButton.text"
					:target="vm.ctaButton.target"
					:title="vm.ctaButton.title"
					:url="vm.ctaButton.url"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { TextBlockViewModel } from "@/types/viewModels/blocks/textBlockViewModel";
import { TextBlockIntroPosition } from "@/types/enum/textBlockIntroPosition";
import { TextBlockTemplate } from "@/types/enum/textBlockTemplate";
import { Log } from "@/types/helpers/logHelper";

@Component
export default class TextBlock extends BaseBlock {
	@Prop({ type: Object as PropType<TextBlockViewModel>, required: true, default: {} })
	vm!: TextBlockViewModel;

	created(): void {
		Log.info("TextBlock", this.vm);
	}

	get introPosition(): typeof TextBlockIntroPosition {
		return TextBlockIntroPosition;
	}

	get isColumnLayout(): boolean {
		return this.vm.template === TextBlockTemplate.columned;
	}
}
</script>

<style lang="scss" scoped></style>
