<template>
	<section v-if="shouldShowTopLevelContractExpirationWarning" class="subscription-expiration-warning">
		<h2 class="text-primary">{{ $t("myCadac.contractOverview.subscription.expirationWarning.title") }}</h2>
		<p v-html="$t('myCadac.contractOverview.subscription.expirationWarning.text')"></p>

		<ul class="mt-2 expiration-list">
			<li v-for="sub in shouldBeRenewed" :key="sub" class="text-danger">
				<strong>{{ formatDate(sub.endDate) }}</strong> - {{ sub.productName }}
			</li>
		</ul>
		<Button class="p-button-success p-button-sm mt-1"
			:label="$t('myCadac.contractOverview.subscription.expirationWarning.buttonText')" @click="openUrl(vm.contactPageUrl, '_blank');
			$event.target.disabled = true;" />

	</section>
	<DataTable v-if="subscriptions.length" auto-layout data-key="serialNumber" responsive-layout="stack" breakpoint="991px"
		:value="subscriptions" class="subscription-table">
		<Column field="productName" class="name-column" style="width: 40%"
			:header="$t('myCadac.contractOverview.table.colProductName')" :sortable="false">
			<template #body="subscription">
				<div v-for="asset in subscription.data.autodeskContractAssets" :key="asset.serialNumber"
					class="d-flex align-items-center product-name-wrapper">
					<Avatar :alt="asset.productIconAlt" class="mr-1 bg-transparent flex-shrink-0 d-none d-lg-block"
						:image="asset.productIconUrl" shape="square" size="large" :title="asset.productName">
					</Avatar>
					<div class="d-flex flex-column align-items-end align-items-lg-start">
						<p v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
							class="mb-0 text-bold text-right text-lg-left">
							<a :href="asset.productCrmUrl" target="_blank" :title="$t('common.goToCrm')">
								{{ formatProductName(asset.productName, asset.productBrand) }}
							</a>
						</p>

						<p v-else class="mb-0 text-bold text-right text-lg-left">
							{{ formatProductName(asset.productName, asset.productBrand) }}
						</p>

						<span class="badge badge-primary multi-user-badge">
							{{ asset.multiSizeUnitString }}
						</span>
					</div>
				</div>
			</template>
		</Column>

		<Column key="renewalTerm" field="duration" style="width: 15%"
			:header="$t('myCadac.contractOverview.table.colDuration')" :sortable="false">
			<template #body="assetDuration">
				<div v-for="n in assetDuration?.data?.autodeskContractAssets?.length" :key="n"
					class="d-flex align-items-center product-name-wrapper">
					<p class="my-1 text-bold text-right text-lg-left">
						{{ assetDuration.data.duration }}
					</p>
				</div>
			</template>
		</Column>

		<Column field="seats" :header="$t('myCadac.contractOverview.table.colProductSeats')" :sortable="false"
			style="width: 15%">
			<template #body="subscription">
				<div v-for="asset in subscription?.data?.autodeskContractAssets" :key="asset.serialNumber"
					class="d-flex align-items-center product-name-wrapper">
					<p class="my-1 text-bold text-right text-lg-left">
						{{ asset.seats }}
					</p>
				</div>
			</template>

		</Column>

		<Column key="automaticRenewal" field="automaticRenewal" style="width: 15%"
			:header="$t('myCadac.contractOverview.table.colAutomaticRenewal')" :sortable="false">
			<template #body="item">
				<div class="d-flex align-items-center">
					<i :class="item.data.automaticRenewal
						? 'pi pi-check text-success mr-1'
						: 'pi pi-times text-danger mr-1'
						" />
					<span style="line-height: 1.25rem">
						{{ getAutomaticRenewalText(item.data.automaticRenewal) }}
					</span>
				</div>
			</template>
		</Column>
		<Column key="endDate" field="endDate" class="end-date-column" style="width: 15%"
			:header="$t('myCadac.contractOverview.table.colExpiresOn')" :sortable="false">
			<template #body="item">
				<div v-if="shouldShowContractExpirationWarning(item.data)"
					class="text-danger d-flex flex-nowrap overflow-hidden">
					<span class="text-nowrap mr-1">{{ formatDate(item.data.endDate) }}</span>
					<PopoverIcon class="d-inline-flex" style="line-height: 1.75rem">
						<i class="pi pi-exclamation-circle" />
						<template #content>
							<p>
								{{
									$tc("myCadac.contractOverview.table.contractExpirationWarning", {
										count: item.data.remainingDays,
									})
								}}
							</p>
						</template>
					</PopoverIcon>
				</div>
				<span v-else>{{ formatDate(item.data.endDate) }}</span>
			</template>
		</Column>
	</DataTable>
	<Card v-if="!subscriptions.length" class="mx-auto mt-2">
		<template #content>
			<p class="lead">
				{{ $t("myCadac.contractOverview.card.noContracts") }}
			</p>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Prop, Component } from "vue-facing-decorator";
import { Subscription } from "@/types/models/subscriptions/subscription";
import { IContractOverview } from "@/types/viewModels/myCadac/contractOverviewViewModel";
import { PropType } from "vue";

@Component
export default class ContractOverviewTable extends BaseComponent {
	@Prop({ type: Object as PropType<IContractOverview>, required: true, default: () => ({}) })
	vm!: IContractOverview;
	@Prop({ type: Array as PropType<Subscription[]>, required: true, default: () => [] })
	subscriptions!: Subscription[];

	daysBeforeExpirationWarning = 90;

	getAutomaticRenewalText(automaticRenewal: boolean): string {
		return automaticRenewal ? this.$t("common.on").toString() : this.$t("common.off").toString();
	}

	isAlmostExpired(subscription: Subscription): boolean {
		return (
			(subscription.remainingDays <= this.daysBeforeExpirationWarning &&
				subscription.remainingDays > 0 &&
				!subscription.automaticRenewal) ||
			false
		);
	}

	shouldShowContractExpirationWarning(subscription: Subscription): boolean {
		return this.isAlmostExpired(subscription);
	}

	get shouldShowTopLevelContractExpirationWarning(): boolean {
		return this.subscriptions.some((subscription) => this.isAlmostExpired(subscription));
	}
}
</script>

<style lang="scss">
.subscription-table {
	font-size: 80%;

	@media (min-width: 991px) {
		font-size: 100%;
	}

	.multi-user-badge {
		font-size: 80%;

		@media (min-width: 991px) {
			font-size: 100%;
		}
	}

	.name-column {
		padding-top: 1.2rem !important;

		@media (min-width: 991px) {
			padding-top: 0.5rem !important;
		}

		div {
			padding: .2rem;
		}
	}

	.end-date-column {
		padding-bottom: 1.2rem !important;

		@media (min-width: 991px) {
			padding-bottom: 0.5rem !important;
		}
	}
}

.subscription-expiration-warning {
	background: #f9f9f8;
	padding: 2rem;
	margin: 1rem 0 2rem;

	.expiration-list {
		list-style: disc;
		list-style-position: inside;
	}
}

.p-column-title {
	max-width: 50%;

	@media (min-width: 991px) {
		max-width: 100%;
	}
}

.product-name-wrapper {
	max-width: 50%;

	@media (min-width: 991px) {
		max-width: 100%;
	}
}
</style>
