<template>
	<Card class="mb-3">
		<template #content>
			<div class="d-flex flex-column justify-content-center align-items-center flex-wrap gap-1">
				<Avatar class="author-image" shape="circle">
					<img :alt="vm.image?.alt" class="" :src="vm.image?.url" />
				</Avatar>
				<div class="d-flex flex-column justify-content-center justify-content-sm-start">
					<small class="m-0 text-muted">{{ vm.company }}</small>
					<h3 v-if="vm.name" class="text-primary text-center text-sm-left mb-0">{{ vm.name }}</h3>
					<p class="m-0">{{ vm.function }}</p>
				</div>
				<LinkButton
					v-if="vm.linkItem"
					class="w-100 mt-2"
					:class="{
						[ctaButtonClass(vm.linkStyle)]: ctaButtonClass(vm.linkStyle),
						'p-button-success p-button-raised p-button-arrow': !ctaButtonClass(vm.linkStyle),
					}"
					:label="vm.linkItem.text"
					:target="vm.linkItem.target"
					:title="vm.linkItem.title"
					:url="vm.linkItem.url"
				/>
			</div>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { AuthorBlockViewModel } from "@/types/viewModels/blocks/authorBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component
export default class AuthorBlock extends BaseBlock {
	@Prop({ type: Object as PropType<AuthorBlockViewModel>, required: true, default: {} })
	vm!: AuthorBlockViewModel;

	created(): void {
		Log.info("AuthorBlock", this.vm);
	}
}
</script>

<style scoped lang="scss">
.author-image {
	height: 100%;
	width: fit-content;

	img {
		width: 90px;
		height: 90px;
		object-fit: cover;
	}
}
</style>
