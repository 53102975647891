<template>
	<div class="row training-summary">
		<div class="col-md-6 col-xl-4">
			<dl>
				<div>
					<dt>
						{{ $t("myCadac.trainingSummary.lblType") }}
					</dt>
					<dd>
						{{ $t(`enum.trainingType.${vm.trainingType}`) }}
					</dd>
				</div>
				<div>
					<dt>
						{{
							isInstructor
								? $t("myCadac.trainingSummary.lblParticipants")
								: $t("myCadac.trainingSummary.lblTeacher")
						}}
					</dt>
					<dd>
						{{ isInstructor ? vm.participants?.length : vm.instructorName }}
					</dd>
				</div>
				<div>
					<dt>
						{{ $t("myCadac.trainingSummary.lblLocation") }}
					</dt>
					<dd class="text-preline">
						{{ formattedAddress }}
					</dd>
				</div>
			</dl>
		</div>
		<div class="col-md-6">
			<dl class="mb-2">
				<div>
					<dt>
						{{ $t("myCadac.trainingSummary.lblDate") }}
					</dt>
					<dd>{{ vm.formattedDates }}</dd>
				</div>
				<div v-if="vm.moduleNames?.length > 0">
					<dt>
						{{ $t("myCadac.trainingSummary.lblModules") }}
					</dt>
					<dd>
						<Inplace>
							<template #display>
								{{ visibleModules(5) }}
								<span v-if="vm.moduleNames?.length > 5">...</span>
							</template>
							<template #content>
								{{ visibleModules() }}
							</template>
						</Inplace>
					</dd>
				</div>
			</dl>
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { take } from "lodash";
import { TrainingListOverviewItemViewModel } from "@/types/models/training/trainingListOverviewItemViewModel";

@Component
export default class TrainingDetailSummary extends BaseComponent {
	@Prop({ type: Object as PropType<TrainingListOverviewItemViewModel>, required: true, default: {} })
	vm!: TrainingListOverviewItemViewModel;
	@Prop({ type: Boolean, required: true, default: false })
	isInstructor!: boolean;

	get visibleModules() {
		return (count: number) => {
			return take(this.vm.moduleNames, count || this.vm.moduleNames?.length)?.join(", ");
		};
	}

	get formattedAddress() {
		return this.vm.trainingAddress
			? `${this.vm.trainingAddress.street}
			${this.vm.trainingAddress.postalCode} ${this.vm.trainingAddress.city}
			${this.vm.trainingAddress.country}`
			: this.vm.trainingLocation?.name;
	}
}
</script>

<style scoped lang="scss"></style>
