import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ce7006d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-config-summary-item", { counter: _ctx.showCounter }])
  }, [
    _createElementVNode("dt", null, _toDisplayString(_ctx.label), 1),
    (_ctx.$slots.content)
      ? (_openBlock(), _createElementBlock("dd", _hoisted_1, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]))
      : (_ctx.content)
        ? (_openBlock(), _createElementBlock("dd", {
            key: 1,
            innerHTML: _ctx.content
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
  ], 2))
}