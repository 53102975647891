import AskAQuestionBlock from '@/views/blocks/askAQuestionBlock.vue';
import CallToActionCenteredDivideBlock from '@/views/blocks/callToActionCenteredDivideBlock.vue';
import CallToActionDefaultBlock from '@/views/blocks/callToActionDefaultBlock.vue';
import CallToActionOfferBlock from '@/views/blocks/callToActionOfferBlock.vue';
import CallToActionSplitBlock from '@/views/blocks/callToActionSplitBlock.vue';
import CategoriesBlock from '@/views/blocks/categoriesBlock.vue';
import CollectionsGridBlock from '@/views/blocks/collectionsGridBlock.vue';
import FeaturedFaqBlock from '@/views/blocks/featuredFaqBlock.vue';
import HeroDefaultBlock from '@/views/blocks/heroDefaultBlock.vue';
import HeroFaqBlock from '@/views/blocks/heroFaqBlock.vue';
import HighlightsBlock from '@/views/blocks/highlightsBlock.vue';
import LinkToBlock from '@/views/blocks/linkToBlock.vue';
import LogosBlock from '@/views/blocks/logosBlock.vue';
import ProductGridBlock from '@/views/blocks/productGridBlock.vue';
import ProductGridColumnedBlock from '@/views/blocks/productGridColumnedBlock.vue';
import RecentContentBlock from '@/views/blocks/recentContentBlock.vue';
import TextAndMediaBlock from '@/views/blocks/textAndMediaBlock.vue';
import TextBlock from '@/views/blocks/textBlock.vue';

export const blocks = [
	{ element: 'ask-question-block', component: AskAQuestionBlock },
	{ element: 'call-to-action-centered-divide-block', component: CallToActionCenteredDivideBlock },
	{ element: 'call-to-action-default-block', component: CallToActionDefaultBlock },
	{ element: 'call-to-action-offer-block', component: CallToActionOfferBlock },
	{ element: 'call-to-action-split-block', component: CallToActionSplitBlock },
	{ element: 'categories-block', component: CategoriesBlock },
	{ element: 'collection-grid-block', component: CollectionsGridBlock },
	{ element: 'featured-faq-block', component: FeaturedFaqBlock },
	{ element: 'hero-default-block', component: HeroDefaultBlock },
	{ element: 'hero-faq-block', component: HeroFaqBlock },
	{ element: 'highlights-block', component: HighlightsBlock },
	{ element: 'link-to-block', component: LinkToBlock },
	{ element: 'logos', component: LogosBlock },
	{ element: 'logos-block', component: LogosBlock },
	{ element: 'product-grid-block', component: ProductGridBlock },
	{ element: 'product-grid-columned-block', component: ProductGridColumnedBlock },
	{ element: 'recent-content-block', component: RecentContentBlock },
	{ element: 'text-and-media-block', component: TextAndMediaBlock },
	{ element: 'text-block', component: TextBlock },
];
