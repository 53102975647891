import httpClient from '@/types/helpers/apiHelper';
import { AxiosResponse } from 'axios';
import { CartClient } from '@/types/models/cart/cartClient';
import { CartContractClient } from '@/types/generated/Api/cartContractClient';
import { CartLineClient } from '@/types/generated/Api/cartLineClient';
import { CouponCode } from '@/types/generated/Api/couponCode';
import { ProductUpdateInCart } from '@/types/generated/Api/productUpdateInCart';
import { concat, uniqBy } from 'lodash';
import { defineStore } from 'pinia';
import { useLayoutStore } from '@/store/common/layoutStore';

interface CartStoreState {
	cartClient: CartClient;
	showSaveCartModal: boolean;
	showShareCartModal: boolean;
	showProductAddedModal: boolean;
	walletExchangeAmount: number;
	maxWalletAmount: number;
}

export type CartModalType = 'ShareCart' | 'SaveCart' | 'ProductAdded';

export const useCartStore = defineStore('cart', {
	state: () =>
		({
			cartClient: {},
			showSaveCartModal: false,
			showShareCartModal: false,
			showProductAddedModal: false,
			walletExchangeAmount: 0,
			maxWalletAmount: 0,
		}) as CartStoreState,
	getters: {
		isCartEmpty(state): boolean {
			return state?.cartClient?.itemCount === 0;
		},
		hasActiveCoupon(): boolean {
			return (
				(this.cartClient?.appliedCouponCodes as CouponCode[])?.filter((x) => !x.isCadacWallet && !x.isAutodeskLoyalty)?.length > 0
			);
		},
		hasActiveWalletCoupon(): boolean {
			return (
				(this.cartClient?.appliedCouponCodes as CouponCode[])?.filter((x) => x.isCadacWallet)?.length > 0
			);
		},
		hasActiveLoyaltyCoupon(): boolean {
			return (
				(this.cartClient?.appliedCouponCodes as CouponCode[])?.filter((x) => x.isAutodeskLoyalty)?.length > 0
			);
		},
		contractCartClients(): CartContractClient[] {
			return concat(
				this.cartClient.contractAdditions,
				this.cartClient.contractCoterming,
				this.cartClient.contractChanges,
				this.cartClient.contractSwitches,
				this.cartClient.contractProlongations,
				this.cartClient.contractCotermProlongations
			).filter(Boolean);
		},

		cartLineClients(): CartLineClient[] {
			return concat(
				this.cartClient?.products,
				this.cartClient?.servicePassProducts,
				this.cartClient?.support,
				this.cartClient?.walletDeposits
			).filter(Boolean);
		},
		trainingCartClients(): CartLineClient[] {
			return this.cartClient.trainingProducts;
		},
		expertCartClients(): CartLineClient[] {
			return this.cartClient.expertProducts;
		},
		allCartLineClients(): CartLineClient[] {
			const contractLines = this.contractCartClients.map((contract) => contract.assets).flat();

			return concat(
				this.cartLineClients,
				this.trainingCartClients,
				this.expertCartClients,
				contractLines
			).filter(Boolean);
		},
		cartLineClientsByType(): CartLineClient[] {
			return uniqBy(this.cartLineClients, 'cartLineType');
		},
	},
	actions: {
		async addCouponCode(couponCode: string): Promise<{ cartClient: CartClient }> {
			const response: AxiosResponse<CartClient> = await httpClient.post<CartClient>(
				'/api/cart/addcoupon',
				{
					value: couponCode,
				},
				{ requestId: 'addCoupon' }
			);

			this.cartClient = response.data;
			return { cartClient: response.data };
		},
		async removeCouponCode(couponCode: string): Promise<{ cartClient: CartClient }> {
			const response: AxiosResponse<CartClient> = await httpClient.post<CartClient>(
				'/api/cart/removecoupon',
				{
					value: couponCode,
				},
				{ requestId: 'removeCoupon' }
			);

			this.cartClient = response.data;
			this.updateWalletAmount();
			return { cartClient: response.data };
		},
		async exchangeWalletAmount(walletAmount: number): Promise<{ cartClient: CartClient }> {
			const response: AxiosResponse<CartClient> = await httpClient.post<CartClient>(
				'/api/cart/usewallet',
				JSON.stringify(walletAmount),
				{ requestId: 'exchangeWallet' }
			);

			this.cartClient = response.data;
			return { cartClient: response.data };
		},
		async deleteCart(): Promise<{ cartClient: CartClient }> {
			const response: AxiosResponse<CartClient> = await httpClient.post<CartClient>(
				'/api/cart/deletecart',
				undefined,
				{
					requestId: 'removeProductInCart',
				}
			);

			this.cartClient = response.data;
			useLayoutStore().updateItemsInCart(this.cartClient.itemCount);
			return { cartClient: response.data };
		},
		async updateProductInCart(model: ProductUpdateInCart): Promise<{ cartClient: CartClient }> {
			const response: AxiosResponse<CartClient> = await httpClient.post<CartClient>(
				'/api/cart/update',
				model,
				{
					requestId: 'updateProductCart',
				}
			);

			this.cartClient = response.data;
			useLayoutStore().updateItemsInCart(this.cartClient.itemCount);
			this.updateWalletAmount();
			return { cartClient: response.data };
		},
		updateWalletAmount() {
			let maxWalletAmount =
				this.cartClient.maxWalletAmount > this.cartClient.subtotalWithoutVat
					? this.cartClient.subtotalWithoutVat
					: this.cartClient.maxWalletAmount;
			maxWalletAmount = Math.round((maxWalletAmount + Number.EPSILON) * 100) / 100;
			const walletExchangeAmount = this.hasActiveWalletCoupon
				? this.cartClient.appliedCouponCodes[0].discount
				: maxWalletAmount;
			this.$patch({ maxWalletAmount, walletExchangeAmount });
		},
		showModal(type: CartModalType) {
			switch (type) {
				case 'ShareCart':
					this.showShareCartModal = true;
					break;
				case 'SaveCart':
					this.showSaveCartModal = true;
					break;
				case 'ProductAdded':
					this.showProductAddedModal = true;
					break;
			}
		},
		hideModal(type: CartModalType) {
			switch (type) {
				case 'ShareCart':
					this.showShareCartModal = false;
					break;
				case 'SaveCart':
					this.showSaveCartModal = false;
					break;
				case 'ProductAdded':
					this.showProductAddedModal = false;
					break;
			}
		},
	},
});
