<template>
	<div class="row">
		<div v-if="hasSupportAssets" class="col-12 mb-5">
			<div class="d-flex flex-column w-100 contract-table-panel">
				<h3 class="mb-1">{{ $t("myCadac.contractOverview.supportContracts.title") }}</h3>
			</div>
			<ContractOverviewTable :contracts="supportContracts"
								   :type="contractTypeEnum.support"
								   :vm="vm"
								   @cancel-contract="confirmCancelContract" />

		</div>
		<div class="col-12 mb-5">
			<div class="d-flex justify-content-between w-100 contract-table-panel">
				<div class="d-flex flex-column">
					<h3 class="mb-1">{{ $t("myCadac.contractOverview.contracts.title") }}</h3>
				</div>
				<Button v-if="vm.licenceManagementOverviewPageUrl"
						:label="$t('myCadac.contractOverview.button.manageLicences')"
						@click="confirmManageLicences()" />
			</div>
			<ContractOverviewTable :contracts="softwareContracts"
								   :type="contractTypeEnum.software"
								   :vm="vm"
								   @cancel-contract="[]" />
		</div>
		<div class="col-12 mb-3">
			<div class="d-flex justify-content-between w-100 contract-table-panel">
				<div class="d-flex flex-column">
					<h3 class="mb-1">{{ $t("myCadac.contractOverview.subscriptions.title") }}</h3>
				</div>
			</div>
			<SubscriptionOverviewTable :subscriptions="autoDeskSubscriptions" :vm="vm" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ContractOverviewTable from "@/components/contracts/contractOverviewTable.vue";
import SubscriptionOverviewTable from "@/components/contracts/subscriptionOverviewTable.vue"; 
import { Component, Prop } from "vue-facing-decorator";
import { Contract } from "@/types/generated/contract";
import { ContractAsset } from "@/types/generated/contractAsset";
import { ContractType } from "@/types/enum/contractType";
import { IContractOverview } from "@/types/viewModels/myCadac/contractOverviewViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { Subscription } from "../../types/models/subscriptions/subscription";

@Component({ components: { ContractOverviewTable, SubscriptionOverviewTable } })
export default class ContractOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<IContractOverview>,
		required: true,
		default: {},
	})
	vm!: IContractOverview;

	get contractTypeEnum(): typeof ContractType {
		return ContractType;
	}

	get softwareContracts(): Contract[] {
        return this.vm?.cadacContracts || [];
	}

	get supportContracts(): Contract[] {
        const supportContracts = this.vm?.cadacSupportContracts;
		// Filter out support ticket contract
		supportContracts?.map(
			(contract: Contract) =>
				(contract.contractSupportAssets = contract.contractSupportAssets.filter(
					(x: ContractAsset) => !x.isSupportTicketVariation
				))
		);
		return supportContracts || [];
	}

	get autoDeskSubscriptions(): Subscription[] {
        return this.vm?.autodeskSubscriptions || [];
    }

	get hasSupportAssets(): boolean {
		return this.supportContracts.some((x) => x.contractSupportAssets.length);
	}


	confirmCancelContract(contract: Contract): void {
		this.$confirm.require({
			header: this.$t("myCadac.contractOverview.dialog.confirmCancelTitle"),
			acceptLabel: this.$t("myCadac.contractOverview.button.cancelContract"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.axios
					.get(`/api/contract/cancel/${contract.contractNumber}`)
					.then(() => {
						this.$toast.add({
							severity: "success",
							summary: this.$t("common.messages.titleSuccess"),
							detail: this.$t("myCadac.contractOverview.toast.contractCancelSuccess"),
							life: 3000,
						});
					})
					.catch((err) => {
						Log.error(err);
						this.$toast.add({
							severity: "error",
							summary: this.$t("common.messages.titleError"),
							detail: this.$t("common.messages.error.genericException"),
							life: 3000,
						});
					});
			},
		});
	}

	confirmManageLicences(): void {
		// Confirm dialog with reverse buttons
		this.$confirm.require({
			header: this.$t("myCadac.licenceManagementOverview.dialog.confirmUnlockLicencesTitle"),
			message: this.$t("myCadac.licenceManagementOverview.dialog.confirmUnlockLicencesMessage"),
			rejectLabel: this.$t("common.yes"),
			acceptLabel: this.$t("common.no"),
			reject: () => {
				this.openUrl(this.vm.licenceManagementOverviewPageUrl as string);
			},
		});
	}
}
</script>
<style lang="scss">
    .contract-table-panel {
        margin: 1rem 0;

        @media(min-width: 991px) {
            margin: 0 0 2rem;
        }
    }

</style>
