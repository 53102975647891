import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "product-tabs" }
const _hoisted_2 = { class: "product-tabs-header" }
const _hoisted_3 = {
  ref: "nav",
  class: "product-tabs-header-items",
  role: "tablist"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_scroll_spy_active = _resolveDirective("scroll-spy-active")!
  const _directive_scroll_spy_link = _resolveDirective("scroll-spy-link")!
  const _directive_scroll_spy = _resolveDirective("scroll-spy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", {
            key: `tab_${item.label}_${i.toString()}`,
            class: "product-tabs-header-item"
          }, [
            _createElementVNode("a", {
              class: "product-tabs-header-item-link",
              href: _ctx.getLinkForItem(item)
            }, [
              _createElementVNode("span", null, _toDisplayString(item.label), 1)
            ], 8, _hoisted_4)
          ], 512)), [
            [_vShow, !item.isHidden]
          ])
        }), 128))
      ])), [
        [_directive_scroll_spy_active, { selector: '.product-tabs-header-item', class: 'active' }],
        [_directive_scroll_spy_link, { selector: '.product-tabs-header-item-link' }]
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          id: item.id,
          key: `content_${item.label}_${i.toString()}`
        }, [
          (_ctx.$slots[item.id])
            ? _renderSlot(_ctx.$slots, item.id, { key: 0 })
            : _renderSlot(_ctx.$slots, "default", { key: 1 })
        ], 8, _hoisted_5))
      }), 128))
    ])), [
      [_directive_scroll_spy, { allowNoActive: _ctx.items?.length > 1, offset: 120 }]
    ])
  ]))
}