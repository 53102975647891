<template>
	<BackButton :label="$t('myCadac.licenceManagementDetail.button.back')" @click="onBackClick()" />

	<div class="d-flex justify-content-between flex-wrap my-1">
		<div v-if="selectedLicenceProduct" class="d-flex align-items-center">
			<img
				v-lazy="selectedLicenceProduct?.productIconUrl"
				:alt="selectedLicenceProduct?.productName"
				class="mr-2 align-self-start"
				height="67"
				width="67"
			/>
			<div class="d-flex flex-column justify-content-between">
				<p class="text-muted lead mb-0">{{ selectedLicenceProduct?.productBrand }}</p>
				<h1 v-if="selectedLicenceProduct?.productName" class="">
					{{ selectedLicenceProduct?.productName }}
				</h1>
			</div>
		</div>
		<div>
			<Button
				class="p-button-success p-button-raised p-button-arrow mr-1 flex-shrink-0"
				:label="$t('myCadac.licenceManagementDetail.button.buyLicences')"
				@click="
					openUrl(selectedLicenceProduct?.productPageUrl);
					$event.target.disabled = true;
				"
			/>
		</div>
	</div>
	<div class="d-flex flex-column col-lg-6">
		<div class="d-flex text-muted">
			<span class="lead mr-1 flex-grow-1 flex-shrink-0">
				{{ $t("myCadac.licenceManagementDetail.lblNumberOfLicences") }}
			</span>
			<b class="mx-1 text-bold text-black" :class="{ 'text-danger': !hasAvailableLicences }">
				{{ selectedLicenceProduct?.totalActiveLicences }} / {{ selectedLicenceProduct?.totalSeats }}
			</b>
			<span v-if="!hasAvailableLicences">
				<PopoverIcon class="d-inline-flex text-danger" placement="right" style="line-height: 1.75rem">
					<i class="pi pi-exclamation-circle" />
					<template #content>
						<p>
							{{ $t("myCadac.licenceManagementDetail.licenceLimitReached") }}
						</p>
					</template>
				</PopoverIcon>
			</span>
		</div>
		<div
			v-if="selectedLicenceProduct?.applicationKeyAllowed && selectedLicenceProduct?.applicationKey"
			class="text-muted d-flex align-items-center flex-wrap flex-lg-nowrap"
		>
			<span class="lead mr-1 flex-grow-1 flex-shrink-0">
				{{ $t("myCadac.licenceManagementDetail.lblApplicationKey") }}
			</span>
			<Inplace :closable="false">
				<template #display>
					{{ $t("myCadac.licenceManagementDetail.lblShowApplicationKey") }}
				</template>
				<template #content>
					<div class="d-flex align-items-center">
						<p
							class="m-0 limit-line-width text-truncate text-bold text-black"
							:title="selectedLicenceProduct?.applicationKey"
						>
							{{ selectedLicenceProduct?.applicationKey }}
						</p>
						<Button
							class="p-button-outlined p-button-secondary copy-button"
							icon="pi pi-copy"
							@click="copyTextToClipboard(selectedLicenceProduct?.applicationKey)"
						/>
					</div>
				</template>
			</Inplace>
		</div>
	</div>

	<Message
		v-if="!hasAvailableLicences && vm.licenceOveruseAllowed"
		:closable="false"
		icon="pi pi-exclamation-triangle"
		severity="error"
	>
		<i18n-t keypath="myCadac.licenceManagementDetail.message.overUsageAllowed">
			<template #link>
				<a :href="overuseSupportUrl" target="_blank">
					{{ $t("myCadac.licenceManagementDetail.message.overUsageAllowed_linkText") }}
				</a>
			</template>
		</i18n-t>
	</Message>

	<Panel id="panel-licences" class="my-3" :toggleable="false">
		<template #header>
			<div class="d-flex justify-content-between flex-grow-1 flex-wrap">
				<div class="d-flex flex-column">
					<h3 class="mb-1">
						{{ $t("myCadac.licenceManagementDetail.panel.titleLicences") }}
					</h3>
				</div>
				<Button
					v-if="hasAvailableLicences || vm.licenceOveruseAllowed"
					class="p-button-primary mr-lg-1 ml-0 flex-shrink-0"
					icon="pi pi-plus"
					:label="$t('myCadac.licenceManagementDetail.button.addLicence')"
					@click="onAddLicenceClick()"
				/>
			</div>
		</template>
		<DataTable
			v-model:filters="licenceFilters"
			auto-layout
			data-key="id"
			:global-filter-fields="['fullName', 'name', 'email']"
			:paginator="showPagination"
			removable-sort
			responsive-layout="stack"
			:row-class="getRowClass"
			:rows="maxRowsToShow"
			:value="allLicences"
		>
			<template #empty>
				<Message v-if="!allLicences?.length" class="my-0" :closable="false" severity="warn">
					{{ $t("myCadac.licenceManagementDetail.message.noLicences") }}
				</Message>
				<Message v-else-if="licenceFilters['global'].value" class="my-0" :closable="false" severity="warn">
					{{
						$t("myCadac.licenceManagementDetail.message.noLicencesFound", [
							licenceFilters["global"].value,
						])
					}}
				</Message>
			</template>
			<template #header>
				<div class="ml-auto col-md-6 col-xl-4 p-0">
					<span class="p-input-icon-left w-100">
						<i class="pi pi-search" />
						<InputText
							v-model="licenceFilters['global'].value"
							:placeholder="$t('myCadac.licenceManagementDetail.table.searchLicence_placeholder')"
						/>
					</span>
				</div>
			</template>
			<Column
				key="displayName"
				field="displayName"
				:header="$t('myCadac.licenceManagementDetail.table.colName')"
				:sortable="true"
			/>
			<Column
				key="email"
				field="email"
				:header="$t('myCadac.licenceManagementDetail.table.colEmail')"
				:sortable="true"
			/>
			<Column key="roles" :header="$t('myCadac.licenceManagementDetail.table.colRoles')">
				<template #body="item">
					<span>{{ getRoleDescriptions(item.data).join(",") }}</span>
				</template>
			</Column>
			<Column
				key="licenceType"
				field="licenceType"
				:header="$t('myCadac.licenceManagementDetail.table.colType')"
				:sortable="true"
			>
				<template #body="item">
					<span>{{ $t(`enum.licenceType.${item.data.licenceType}`) }}</span>
				</template>
			</Column>
			<Column
				key="endDate"
				field="endDate"
				:header="$t('myCadac.licenceManagementDetail.table.colDate')"
				:sortable="true"
			>
				<template #body="item">
					<div
						v-if="shouldShowContractExpirationWarning(item.data)"
						class="text-danger d-flex flex-nowrap"
					>
						<span class="mr-1">{{ formatDate(getContractExpirationDate(item.data)) }}</span>
						<PopoverIcon class="d-inline-flex" style="line-height: 1.75rem">
							<i class="pi pi-exclamation-circle" />
							<template #content>
								<p>
									{{
										$tc("myCadac.contractOverview.table.contractExpirationWarning", {
											count: getRemainingContractDays(item.data),
										})
									}}
								</p>
							</template>
						</PopoverIcon>
					</div>
					<span v-else>{{ formatDate(getContractExpirationDate(item.data)) }}</span>
				</template>
			</Column>
			<Column key="actions">
				<template #body="item">
					<div
						v-if="!shouldShowPendingDeletionWarning(item.data)"
						class="d-flex justify-content-lg-end justify-content-between w-100"
					>
						<Button
							class="p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text flex-shrink-0"
							icon="pi pi-pencil"
							:label="isSmallScreen ? $t('common.edit') : ''"
							:title="$t('common.edit')"
							@click="onEditLicenceClick(item.data)"
						/>
						<Button
							class="p-button-danger p-button-icon p-button-rounded p-button-raised p-button-text flex-shrink-0 ml-1"
							icon="pi pi-trash"
							:label="isSmallScreen ? $t('common.remove') : ''"
							:title="$t('common.remove')"
							@click="onDeleteLicenceClick(item.data)"
						/>
					</div>
					<div v-else class="text-warning lg-end w-100" :class="{ 'warning-message': !isSmallScreen }">
						{{ $t("myCadac.licenceManagementDetail.table.pendingDeletionWarning") }}
					</div>
				</template>
			</Column>
		</DataTable>
	</Panel>
	<LicenceFormModal
		v-if="licenceStore.isLicenceFormModalVisible"
		:vm="vm"
		@licence-saved="onLicenceSaved($event)"
	/>
</template>

<script lang="ts">
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import LicenceFormModal from "@/components/licenceManagement/licenceFormModal.vue";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import { AxiosResponse } from "axios";
import { Component, Prop } from "vue-facing-decorator";
import { FilterMatchMode } from "primevue/api";
import { ILicenceManagementDetail } from "@/types/viewModels/myCadac/licenceManagementDetailViewModel";
import { Licence } from "@/types/generated/licence";
import { LicenceForm } from "@/types/models/form/licenceForm";
import { LicenceProduct } from "@/types/generated/licenceProduct";
import { LicenseAsset } from "@/types/generated/licenseAsset";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { cloneDeep, concat, orderBy } from "lodash";
import { differenceInCalendarDays, isBefore, sub } from "date-fns";
import { getActiveLocale } from "@/types/utils/i18n";
import { useLicenceStore } from "@/store/software/licenceStore";
import { useSessionStorage } from "@vueuse/core";

@Component({ components: { LicenceFormModal, BackButton, PopoverIcon } })
export default class LicenceManagementDetail extends BaseComponent {
	@Prop({ type: Object as PropType<ILicenceManagementDetail>, required: true, default: {} })
	vm!: ILicenceManagementDetail;

	isLoading = false;
	licenceFilters = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
	maxRowsToShow = 10;
	canSeeLicences = useSessionStorage("licenceViewUnlocked", false);
	allLicences: Licence[] = [];

	licenceStore = useLicenceStore();

	created(): void {
		this.licenceStore.selectedLicenceProduct = this.vm.licenceProduct;
		this.allLicences = this.getAllSortedLicences();
	}

	get showPagination(): boolean {
		return this.allLicences?.length > this.maxRowsToShow;
	}

	get licenceRoleFilterOptions(): string[] {
		return this.selectedLicenceProduct.possibleRoles.map((x) =>
			this.$i18n.locale === "nl" ? x.descriptionNL : x.descriptionEN
		);
	}

	get selectedLicenceProduct(): LicenceProduct {
		return this.licenceStore.selectedLicenceProduct as LicenceProduct;
	}

	get hasAvailableLicences(): boolean {
		return this.selectedLicenceProduct.totalSeats - this.selectedLicenceProduct.totalActiveLicences > 0;
	}

	get overuseSupportUrl(): string {
		return getActiveLocale() === "nl"
			? "/service-support/service/algemeen/cadac-transition-to-named-user-agent-only/uitleg-over-usage-cadac-named-use/"
			: "/service-contact/service/algemeen/cadac-transtion-to-named-user/explanation-over-usage-cadac-named-use/";
	}

	shouldShowContractExpirationWarning(licence: Licence): boolean {
		return this.getRemainingContractDays(licence) < 90 && !this.shouldShowPendingDeletionWarning(licence);
	}

	shouldShowPendingDeletionWarning(licence: Licence): boolean {
		if (!licence.dateDeleted) return false;
		const currentDateMinusGracePeriod = sub(new Date(), { days: 1 });
		return isBefore(currentDateMinusGracePeriod, this.toDate(licence.dateDeleted));
	}

	getRowClass(licence: Licence): string {
		return this.shouldShowPendingDeletionWarning(licence) ? "disabled" : "";
	}

	getContractExpirationDate(licence: Licence): Date {
		return this.toDate(this.getLicenceAssetForLicence(licence.id)?.endDate);
	}

	getRemainingContractDays(licence: Licence): number {
		return differenceInCalendarDays(this.getContractExpirationDate(licence), new Date());
	}

	getLicenceAssetForLicence(licenceId: string): LicenseAsset {
		return (this.selectedLicenceProduct.licenceAssets as LicenseAsset[]).find(
			(licenceAsset) =>
				licenceAsset.licences.findIndex((licenceOfAsset: Licence) => licenceOfAsset.id === licenceId) > -1
		) as LicenseAsset;
	}

	getRoleDescriptions(_licence: Licence): string[] {
		return _licence.roles.map((x) => (this.$i18n.locale === "nl" ? x.descriptionNL : x.descriptionEN));
	}

	onLicenceSaved(_licence: LicenceForm): void {
		if (_licence.id) this.updateLicence(_licence);
		else this.addNewLicence(_licence);
	}

	onAddLicenceClick(): void {
		this.licenceStore.selectedLicence = null;
		this.licenceStore.showModal("LicenceForm");
	}

	onEditLicenceClick(_licence: Licence): void {
		this.licenceStore.selectedLicence = _licence;
		this.licenceStore.showModal("LicenceForm");
	}

	onDeleteLicenceClick(_licence: LicenceForm): void {
		// Confirm dialog with reverse buttons
		this.$confirm.require({
			header: this.$t("myCadac.licenceManagementDetail.dialog.confirmDeleteLicenceTitle"),
			message: this.$t("myCadac.licenceManagementDetail.dialog.confirmDeleteLicenceMessage", [
				_licence.email,
			]),
			rejectLabel: this.$t("common.yes"),
			acceptLabel: this.$t("common.no"),
			reject: () => {
				this.deleteLicence(_licence);
			},
		});
	}

	onBackClick(): void {
		this.licenceStore.setCanSeeLicences(true);
	}

	copyTextToClipboard(text: string): void {
		this.copyToClipboard(text)
			.then(() => {
				this.$toast.add({
					severity: "info",
					detail: this.$t("myCadac.licenceManagementDetail.toast.copiedToClipboard", [
						this.selectedLicenceProduct.applicationKey,
					]),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
			});
	}

	private addNewLicence(_licence: LicenceForm): void {
		this.isLoading = true;
		this.axios
			.post(`/api/licence/create/${this.selectedLicenceProduct.id}`, _licence)
			.then(this.getLicenceProduct)
			.then(() => {
				this.licenceStore.selectedLicence = null;
				this.licenceStore.hideModal("LicenceForm");

				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.createLicenceSuccess", [
						_licence.email,
					]),
					life: 3000,
				});
			})
			.catch((err) => {
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.createLicenceError", [_licence.email]),
					life: 3000,
				});
				Log.error(err);
			})
			.finally(() => (this.isLoading = false));
	}

	private updateLicence(_licence: LicenceForm): void {
		this.isLoading = true;
		this.axios
			.put(`/api/licence/update/${this.selectedLicenceProduct.id}`, _licence)
			.then(this.getLicenceProduct)
			.then(() => {
				this.licenceStore.selectedLicence = null;
				this.licenceStore.hideModal("LicenceForm");

				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.updateLicenceSuccess", [
						_licence.email,
					]),
					life: 3000,
				});
			})
			.catch((err) => {
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.updateLicenceError", [_licence.email]),
					life: 3000,
				});
				Log.error(err);
			})
			.finally(() => (this.isLoading = false));
	}

	private deleteLicence(_licence: LicenceForm): void {
		this.isLoading = true;
		this.axios
			.put(`/api/licence/remove/${this.selectedLicenceProduct.id}`, _licence)
			.then(this.getLicenceProduct)
			.then(() => {
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.deleteLicenceSuccess", [
						_licence.email,
					]),
					life: 3000,
				});
			})
			.catch((err) => {
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.licenceManagementDetail.toast.deleteLicenceError", [_licence.email]),
					life: 3000,
				});
				Log.error(err);
			})
			.finally(() => (this.isLoading = false));
	}

	private getLicenceProduct(): void {
		this.isLoading = true;
		this.axios
			.get<LicenceProduct>(`/api/licenceProduct/${this.selectedLicenceProduct.id}`)
			.then((result: AxiosResponse<LicenceProduct>) => {
				this.licenceStore.selectedLicenceProduct = cloneDeep(result.data);
				this.allLicences = this.getAllSortedLicences();
				this.$forceUpdate();
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => (this.isLoading = false));
	}

	private getAllSortedLicences(): Licence[] {
		return orderBy(
			concat(...this.selectedLicenceProduct.licenceAssets.map((x) => x.licences)),
			"dateDeleted",
			"asc"
		);
	}
}
</script>

<style lang="scss" scoped>
.copy-button {
	max-width: 1.5rem;
	max-height: 1.5rem;
	flex-shrink: 0;
	padding: 0.25rem;
	margin-left: 0.25rem;
}

.limit-line-width {
	min-width: 0;
	max-width: 250px;
}

.warning-message {
	max-width: 240px;
}

::v-deep(.p-inplace .p-inplace-display) {
	padding: 0;

	&:hover {
		color: var(--primary-color);
		background: inherit;
	}
}
</style>
