import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74850f66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-1" }
const _hoisted_2 = { class: "d-flex flex-wrap" }
const _hoisted_3 = { class: "mr-1" }
const _hoisted_4 = { class: "d-flex flex-wrap" }
const _hoisted_5 = { class: "px-2" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "text-muted lead text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ProductConfigurationOption = _resolveComponent("ProductConfigurationOption")!
  const _component_ProductConfigurationStep = _resolveComponent("ProductConfigurationStep")!
  const _component_ProductConfiguration = _resolveComponent("ProductConfiguration")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProductPageContentSection = _resolveComponent("ProductPageContentSection")!
  const _component_ProductOverviewItemCard = _resolveComponent("ProductOverviewItemCard")!
  const _component_ProductTabs = _resolveComponent("ProductTabs")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_ProductConfigurationSummaryItem = _resolveComponent("ProductConfigurationSummaryItem")!
  const _component_ProductConfigurationSummary = _resolveComponent("ProductConfigurationSummary")!
  const _component_WalletMessage = _resolveComponent("WalletMessage")!
  const _component_ProductPageSummary = _resolveComponent("ProductPageSummary")!
  const _component_ProductLayout = _resolveComponent("ProductLayout")!
  const _component_ProductAddedModal = _resolveComponent("ProductAddedModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ProductLayout, {
      modelValue: _ctx.showSideBar,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showSideBar) = $event)),
      "help-block": _ctx.selectedHelpBlock,
      vm: _ctx.vm
    }, {
      tabs: _withCtx(() => [
        _createVNode(_component_ProductTabs, { items: _ctx.productTabs }, _createSlots({
          tabConfiguration: _withCtx(() => [
            _createVNode(_component_ProductPageContentSection, null, {
              default: _withCtx(() => [
                (_ctx.vm.viewModelIsValid)
                  ? (_openBlock(), _createBlock(_component_ProductConfiguration, {
                      key: 0,
                      class: "mb-3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ProductConfigurationStep, { id: "step-serviceType" }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("service.lblServiceType")), 1),
                            _createVNode(_component_Button, {
                              class: "header-explanation-text text-underline py-0 p-button-text p-button-secondary",
                              label: _ctx.vm.helpBlockServiceType?.linkText,
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showHelpSideBar(_ctx.vm.helpBlockServiceType)))
                            }, null, 8, ["label"])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.availableServiceTypes, (option, index) => {
                                return (_openBlock(), _createBlock(_component_ProductConfigurationOption, {
                                  key: index,
                                  disabled: _ctx.isBusy,
                                  "is-selected": option.value === _ctx.productStore.selectedServiceType,
                                  option: option,
                                  onOptionSelected: ($event: any) => (_ctx.onServiceTypeSelected(option.value))
                                }, null, 8, ["disabled", "is-selected", "option", "onOptionSelected"]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ProductConfigurationStep, { id: "step-unit" }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("service.lblUnit")), 1),
                            _createVNode(_component_Button, {
                              class: "header-explanation-text text-underline py-0 p-button-text p-button-secondary",
                              label: _ctx.vm.helpBlockUnit?.linkText,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showHelpSideBar(_ctx.vm.helpBlockUnit)))
                            }, null, 8, ["label"])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.availableUnits, (option, index) => {
                                return (_openBlock(), _createBlock(_component_ProductConfigurationOption, {
                                  key: index,
                                  disabled: _ctx.isBusy,
                                  "is-selected": option.value === _ctx.productStore.selectedUnit,
                                  option: option,
                                  onOptionSelected: ($event: any) => (_ctx.onUnitSelected(option.value))
                                }, null, 8, ["disabled", "is-selected", "option", "onOptionSelected"]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_Message, {
                      key: 1,
                      closable: false,
                      severity: "error"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("product.message.currentlyUnavailable")), 1)
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }),
            (_ctx.vm.relatedUpSellProducts?.length)
              ? (_openBlock(), _createBlock(_component_ProductPageContentSection, {
                  key: 0,
                  type: "upsell"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("product.titleUpSell")), 1),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.relatedUpSellProducts, (product) => {
                        return (_openBlock(), _createElementBlock("div", { key: product }, [
                          _createVNode(_component_ProductOverviewItemCard, {
                            item: product,
                            layout: "grid"
                          }, null, 8, ["item"])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (_ctx.vm.gameRules)
            ? {
                name: "tabGameRules",
                fn: _withCtx(() => [
                  _createVNode(_component_ProductPageContentSection, { type: 'content' }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "rte",
                        innerHTML: _ctx.vm.gameRules
                      }, null, 8, _hoisted_7)
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["items"])
      ]),
      summary: _withCtx(() => [
        _createVNode(_component_ProductPageSummary, {
          "can-submit": true,
          "is-product-available": _ctx.vm.viewModelIsValid,
          "max-amount": _ctx.productStore.selectedVariation?.maxQuantity,
          "min-amount": _ctx.productStore.selectedVariation?.minQuantity,
          "show-amount-input": "",
          "total-price": _ctx.priceSummary?.totalPrice,
          value: _ctx.productStore?.selectedAmount,
          onAddToCart: _ctx.addProductToCart,
          onAmountChanged: _ctx.onSelectedAmountChanged
        }, {
          priceSummary: _withCtx(() => [
            _createVNode(_component_PriceSummary, { vm: _ctx.priceSummary }, null, 8, ["vm"]),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("common.productSummary.allPricesExclVat")), 1)
          ]),
          configurationSummary: _withCtx(() => [
            _createVNode(_component_ProductConfigurationSummary, {
              header: _ctx.$t('service.titleSummary')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ProductConfigurationSummaryItem, {
                  content: _ctx.$t(`enum.serviceType.${_ctx.productStore.selectedServiceType}`),
                  label: _ctx.$t('service.lblServiceType')
                }, null, 8, ["content", "label"]),
                _createVNode(_component_ProductConfigurationSummaryItem, {
                  content: _ctx.$t(`enum.serviceUnit.${_ctx.productStore.selectedUnit}`),
                  label: _ctx.$t('service.lblUnit')
                }, null, 8, ["content", "label"]),
                (_ctx.layoutStore.canImpersonate)
                  ? (_openBlock(), _createBlock(_component_ProductConfigurationSummaryItem, {
                      key: 0,
                      content: _ctx.productStore.selectedVariation?.sku,
                      label: "SKU"
                    }, null, 8, ["content"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          walletMessage: _withCtx(() => [
            _withDirectives(_createVNode(_component_WalletMessage, {
              "wallet-amount": _ctx.productStore.selectedVariation?.walletAmount
            }, null, 8, ["wallet-amount"]), [
              [_vShow, 
							!_ctx.isSmallScreen && _ctx.vm?.canUseWallet && _ctx.productStore?.selectedVariation?.walletAmount
						]
            ])
          ]),
          _: 1
        }, 8, ["is-product-available", "max-amount", "min-amount", "total-price", "value", "onAddToCart", "onAmountChanged"])
      ]),
      _: 1
    }, 8, ["modelValue", "help-block", "vm"]),
    (_ctx.cartStore.showProductAddedModal)
      ? (_openBlock(), _createBlock(_component_ProductAddedModal, {
          key: 0,
          vm: _ctx.productAddedVm
        }, null, 8, ["vm"]))
      : _createCommentVNode("", true)
  ], 64))
}