import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16aae665"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-icons-wrapper ml-lg-1 align-items-lg-start align-content-lg-start" }
const _hoisted_2 = {
  key: 0,
  class: "product-info-line flex-lg-row"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "text-bold ml-1" }
const _hoisted_5 = {
  key: 1,
  class: "product-info-line flex-lg-row"
}
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "text-bold ml-1" }
const _hoisted_8 = {
  key: 2,
  class: "product-info-line flex-lg-row align-items-start"
}
const _hoisted_9 = ["title"]
const _hoisted_10 = { class: "text-bold ml-1 text-preline" }
const _hoisted_11 = {
  key: 3,
  class: "product-info-line flex-lg-row"
}
const _hoisted_12 = ["title"]
const _hoisted_13 = { class: "text-bold ml-1 text-preline" }
const _hoisted_14 = {
  key: 4,
  class: "product-info-line flex-lg-row"
}
const _hoisted_15 = ["title"]
const _hoisted_16 = { class: "text-bold ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.duration)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("i", {
            class: "pi pi-clock text-primary",
            title: _ctx.$t('cart.table.iconDuration_title')
          }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.duration), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.dates)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("i", {
            class: "pi pi-calendar text-primary",
            title: _ctx.$t('cart.table.iconDates_title')
          }, null, 8, _hoisted_6),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.item.date), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.item.address)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("i", {
            class: "pi pi-map-marker text-primary",
            style: {"margin-top":"0.3rem"},
            title: _ctx.$t('cart.table.iconLocation_title')
          }, null, 8, _hoisted_9),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formattedAddress), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.item.location)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("i", {
            class: "pi pi-map-marker text-primary",
            title: _ctx.$t('cart.table.iconLocation_title')
          }, null, 8, _hoisted_12),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.item.location), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.item.numberOfParticipants)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("i", {
            class: "pi pi-user text-primary",
            title: _ctx.$t('cart.table.iconParticipants_title')
          }, null, 8, _hoisted_15),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$tc("cart.table.lblParticipantCount", { count: _ctx.item.numberOfParticipants })), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}