import httpClient from '@/types/helpers/apiHelper';
import i18n from '@/types/utils/i18n';
import { AddToCartResponse } from '@/types/generated/Api/addToCartResponse';
import { AxiosResponse } from 'axios';
import { IDropdownItem } from '@/types/models/common/dropdownItem.interface';
import { IServicePassProductViewModel } from '@/types/viewModels/commerce/servicePassProductViewModel';
import { ProductAddToCart } from '@/types/generated/Api/productAddToCart';
import { ServicePassVariationViewModel } from '@/types/models/service/servicePassVariationViewModel';
import { ServiceType } from '@/types/enum/serviceType';
import { Unit } from '@/types/enum/unit';
import { defineStore } from 'pinia';
import { filter, find, first, sortBy, uniqBy } from 'lodash';

interface ServicePassProductState {
	selectedServiceType?: ServiceType;
	selectedUnit?: Unit;
	selectedVariation?: ServicePassVariationViewModel;
	selectedAmount: number;

	availableServiceTypes: IDropdownItem[];
	availableUnits: IDropdownItem[];

	vm: IServicePassProductViewModel | null;
}

export const useServicePassProductStore = defineStore('servicePassProduct', {
	state: () =>
		({
			selectedServiceType: undefined,
			selectedUnit: undefined,
			selectedVariation: undefined,
			availableServiceTypes: [],
			availableUnits: [],
			vm: null,
			selectedAmount: 1,
		}) as ServicePassProductState,
	getters: {
		walletAmount(): number {
			return (this.selectedVariation?.walletAmount || 0) * this.selectedAmount;
		},
		currentVariation(
			state: ServicePassProductState
		): (filterOptions?: Partial<ServicePassVariationViewModel>) => ServicePassVariationViewModel {
			return (filterOptions?: Partial<ServicePassVariationViewModel>) => {
				if (!filterOptions)
					filterOptions = {
						serviceType:
							state.selectedServiceType || (first(this.availableServiceTypes)?.value as ServiceType),
						unit: state.selectedUnit || (first(this.availableUnits)?.value as Unit),
					};
				return find(state.vm?.servicePassVariations, filterOptions) as ServicePassVariationViewModel;
			};
		},
	},
	actions: {
		init(): void {
			this.availableServiceTypes =
				this.vm?.distinctServiceType.map((x) => ({
					value: x,
					label: i18n.global.t(`enum.serviceType.${x}`),
				})) || [];
			this.availableUnits =
				this.vm?.distinctUnit.map((x) => ({
					value: x,
					label: i18n.global.t(`enum.serviceUnit.${x}`),
				})) || [];
			this.selectedVariation =
				this.vm?.selectedServicePassVariation ||
				(this.vm?.servicePassVariations[0] as ServicePassVariationViewModel);
			this.selectedServiceType =
				this.vm?.selectedServicePassVariation?.serviceType ||
				(this.availableServiceTypes[0]?.value as ServiceType);
			this.selectedAmount = this.vm?.selectedSeats || 1;
			this.updateConfigurationOptions();
		},
		async addToCart() {
			const payLoad: ProductAddToCart = {
				seats: this.selectedAmount,
				oldLineItemId: this.vm?.oldLineItemId,
				walletAmount: this.selectedVariation?.walletAmount || 0,
				types: [this.selectedVariation?.id] as number[],
			};
			const response: AxiosResponse<AddToCartResponse> = await httpClient.post<AddToCartResponse>(
				'/api/cart/add',
				payLoad
			);
			return response.data;
		},
		updateConfigurationOptions(): void {
			this.availableUnits = (
				filter(this.vm?.servicePassVariations, {
					serviceType: this.selectedServiceType,
				}) as ServicePassVariationViewModel[]
			).map((x) => ({
				value: x.unit,
				label: i18n.global.t(`enum.serviceUnit.${x.unit}`),
			}));
			this.availableUnits = sortBy(uniqBy(this.availableUnits, 'value'), 'value');

			this.selectedUnit = this.selectedVariation?.unit || (this.availableUnits[0]?.value as number);
			this.selectedServiceType =
				this.selectedVariation?.serviceType || (this.availableServiceTypes[0]?.value as number);
		},
	},
});
