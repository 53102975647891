<template>
	<div class="product-icons-wrapper ml-lg-1 align-items-lg-start align-content-lg-start">
		<Panel v-if="duration" v-model:collapsed="datesCollapsed">
			<template #header>
				<div>
					<i class="pi pi-clock text-primary" :title="$t('cart.table.iconDuration_title')" />
					<span class="text-bold ml-1">{{ duration }}</span>
				</div>
			</template>
			<template #icons>
				<Button
					class="p-button-text p-panel-header-icon"
					:icon="datesCollapsed ? 'pi pi-chevron-left' : 'pi pi-chevron-down'"
					@click="datesCollapsed = !datesCollapsed"
				/>
			</template>
			<div v-if="item.expert?.dates" class="product-info-line flex-lg-row align-items-start">
				<i
					class="pi pi-calendar text-primary"
					style="margin-top: 0.3rem"
					:title="$t('cart.table.iconDates_title')"
				/>
				<div class="d-flex flex-column ml-1">
					<span v-for="(date, index) in item.expert?.dates" :key="index" class="text-bold">
						{{ formattedBookingDate(date) }}
					</span>
				</div>
			</div>
		</Panel>

		<div v-if="location" class="product-info-line flex-lg-row align-items-start">
			<i
				class="pi pi-map-marker text-primary"
				style="margin-top: 0.3rem"
				:title="$t('cart.table.iconLocation_title')"
			/>
			<span class="text-bold ml-1 text-preline">{{ location }}</span>
		</div>

		<div v-if="item.expert?.isFlexBooking" class="product-info-line flex-lg-row">
			<i class="pi pi-check text-success mr-1" />
			<span class="text-bold">{{ $t("cart.table.colFlexBooking") }}</span>
		</div>
	</div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { ExpertBookingDate } from "@/types/models/expert/expertBookingDate";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";

@Component
export default class ColumnExpertInfo extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient>, required: true, default: {} }) item!: CartLineClient;
	datesCollapsed = true;

	get duration(): string {
		return this.$tc("cart.table.lblDurationDays", this.item.expert.dates?.length);
	}

	get location(): string {
		return this.item.expert?.workLocation?.name || this.$t("expert.remote");
	}

	get formattedBookingDate() {
		return (date: ExpertBookingDate) => {
			return `${this.formatDate(date.start)}  ${this.formatTime(date.start)} - ${this.formatTime(date.end)}`;
		};
	}
}
</script>

<style scoped lang="scss">
.product-icon {
	width: 47px;
	height: 47px;
	background: transparent;
	flex-shrink: 0;
}

.product-icons-wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	flex-basis: 100%;
	padding: 0 0.5rem;

	.product-info-line {
		display: flex;
		flex: 0;
		align-items: center;
		flex-direction: row;
		margin-bottom: 0.25rem;
	}
}

@media print {
	.product-icons-wrapper {
		align-content: flex-start;
	}
}

::v-deep(.p-panel) {
	.p-panel-header {
		justify-content: flex-start;
		border: 0;
		padding: 0;

		.p-button-icon {
			font-size: 0.65rem;
		}
	}

	.p-panel-content {
		padding: 0;
	}
}
</style>
