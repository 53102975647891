<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<div class="row">
				<div class="col mb-2">
					<h2 v-if="vm.title" class="display-4 mb-0">{{ vm.title }}</h2>
					<p v-if="vm.subtitle" class="lead">{{ vm.subtitle }}</p>
				</div>
			</div>
			<CallToActionOfferBlock v-if="vm.ctaOffer" :vm="vm.ctaOffer" />
			<div class="row gap-y-3">
				<div v-for="product in vm.products" :key="product" class="col col-12 col-sm-6 col-lg-4">
					<BaseProductOverviewItemCard :vm="product" />
				</div>
			</div>
			<div class="row mt-2">
				<div class="col">
					<LinkButton
						v-if="vm.overviewButtonCTA"
						class="p-button-arrow p-button-raised p-button-text"
						:label="vm.overviewButtonText"
						:url="ctaUrl"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import CallToActionOfferBlock from "@/views/blocks/callToActionOfferBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { ProductGridBlockViewModel } from "@/types/viewModels/blocks/productGridBlockViewModel";
import { PropType } from "vue";

@Component({ components: { BaseProductOverviewItemCard, CallToActionOfferBlock } })
export default class ProductGridBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<ProductGridBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: ProductGridBlockViewModel;

	created(): void {
		Log.info("ProductGridBlock", this.vm);
	}

	get ctaUrl(): string {
		return this.vm.overviewButtonCTAQuerystring
			? `${this.vm.overviewButtonCTA}${this.vm.overviewButtonCTAQuerystring}`
			: this.vm.overviewButtonCTA;
	}
}
</script>

<style lang="scss" scoped></style>
