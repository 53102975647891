<template>
	<div v-if="collapsed && nextStepAdvices.length > 1" class="advice-section mx-2 py-2 px-3">
		<i18n-t class="text-center" keypath="myCadac.advisorAdvice.adviceAmount" scope="global" tag="p">
			<template #amount>
				<b>{{ nextStepAdvices.length }}</b>
			</template>
		</i18n-t>
	</div>
	<div
		v-for="(nextStepAdvice, index) in nextStepAdvices"
		v-show="!collapsed || nextStepAdvices.length < 2"
		:key="`nextStepAdvice_${index}`"
		class="advice-section mx-3 py-2 px-2 mb-2"
	>
		<div
			v-if="nextStepAdvices.length > 1"
			class="d-flex flex-row align-items-center justify-content-center mb-2"
		>
			<Avatar :image="nextStepAdvice.iconUrl" size="large" />
		</div>

		<b class="text-center text-primary font-weight-normal lead">{{ productCardTitle(nextStepAdvice) }}</b>

		<i18n-t
			v-if="nextStepAdvice.adviceType === productAdviceType.Unsupported"
			class="text-center"
			keypath="myCadac.advisorAdvice.productCta"
			scope="global"
			tag="p"
		>
			<template #product>
				<b>{{ nextStepAdvice.name }}</b>
			</template>
		</i18n-t>
		<i18n-t
			v-else-if="nextStepAdvice.adviceType === productAdviceType.New"
			class="text-center"
			keypath="myCadac.advisorAdvice.productCtaNew"
			scope="global"
			tag="p"
		>
			<template #duration>
				<b>{{ nextStepAdvice.durationInMonths }}</b>
			</template>
			<template #start>
				<b>{{ formatDate(nextStepAdvice.startDate) }}</b>
			</template>
			<template #seats>
				<b>{{ nextStepAdvice.seats }}</b>
			</template>
		</i18n-t>
		<i18n-t
			v-else-if="nextStepAdvice.adviceType === productAdviceType.Renew"
			class="text-center"
			keypath="myCadac.advisorAdvice.productCtaRenew"
			scope="global"
			tag="p"
		>
			<template #duration>
				<b>{{ nextStepAdvice.durationInMonths }}</b>
			</template>
			<template #start>
				<b>{{ formatDate(nextStepAdvice.startDate) }}</b>
			</template>
			<template #seats>
				<b>{{ nextStepAdvice.seats }}</b>
			</template>
		</i18n-t>
		<i18n-t
			v-else-if="nextStepAdvice.adviceType === productAdviceType.TradeIn"
			class="text-center"
			keypath="myCadac.advisorAdvice.productCtaRenew"
			scope="global"
			tag="p"
		>
			<template #duration>
				<b>{{ nextStepAdvice.durationInMonths }}</b>
			</template>
			<template #start>
				<b>{{ formatDate(nextStepAdvice.startDate) }}</b>
			</template>
			<template #seats>
				<b>{{ nextStepAdvice.seats }}</b>
			</template>
		</i18n-t>
		<i18n-t
			v-else-if="nextStepAdvice.adviceType === productAdviceType.Running"
			class="text-center"
			keypath="myCadac.advisorAdvice.productCtaRunning"
			scope="global"
			tag="p"
		>
			<template #product>
				<b>{{ nextStepAdvice.name }}</b>
			</template>
		</i18n-t>
	</div>

	<div v-if="!nextStepAdvices.length" class="advice-section mx-3 py-2 px-2">
		<b class="text-center text-primary font-weight-normal lead">{{
			$t("myCadac.advisorAdvice.productTitleTerminate", { product: currentProduct.name })
		}}</b>

		<i18n-t class="text-center" keypath="myCadac.advisorAdvice.productCtaTerminate" scope="global" tag="p">
		</i18n-t>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProgressSpinner from "primevue/progressspinner";
import { Component, Prop } from "vue-facing-decorator";
import AdvisorTokenAdvice from "@/components/advisor/advisorTokenAdvice.vue";
import { PropType } from "vue";
import { ProductAdviceType } from "@/types/enum/productAdviceType";
import { NextStepAdvicesViewModel } from "@/types/models/advisor/nextStepAdvicesViewModel";
import Divider from "primevue/divider";
import { CurrentProductViewModel } from "@/types/models/advisor/currentProductViewModel";

@Component({ components: { AdvisorTokenAdvice, ProgressSpinner, Divider } })
export default class AdvisorAdviceOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<NextStepAdvicesViewModel[]>,
		required: true,
	})
	nextStepAdvices!: NextStepAdvicesViewModel[];
	@Prop({
		type: Object as PropType<CurrentProductViewModel>,
		required: true,
	})
	currentProduct!: CurrentProductViewModel;

	@Prop({
		type: Boolean,
		required: false,
	})
	collapsed!: boolean;

	get productAdviceType(): typeof ProductAdviceType {
		return ProductAdviceType;
	}

	get productCardTitle(): (product: NextStepAdvicesViewModel) => string {
		return (product: NextStepAdvicesViewModel) => {
			switch (product.adviceType) {
				case ProductAdviceType.New:
					return this.$t("myCadac.advisorAdvice.productTitleNew", { product: product.name });
				case ProductAdviceType.Renew:
					return this.$t("myCadac.advisorAdvice.productTitleRenew", { product: product.name });
				case ProductAdviceType.Running:
					return this.$t("myCadac.advisorAdvice.productTitleRunning", { product: product.name });
				case ProductAdviceType.TradeIn:
					return this.$t("myCadac.advisorAdvice.productTitleRenew", { product: product.name });
				default:
					return "";
			}
		};
	}
}
</script>

<style scoped lang="scss">
.advice-section {
	display: flex;
	flex-direction: column;
	background-color: rgba(32, 176, 101, 0.06);
	border-radius: 4px;
}
</style>
