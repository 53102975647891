export enum GaEvent {
	Page = 'page',
	ViewItem = 'view_item',
	AddToCart = 'add_to_cart',
	RemoveFromCart = 'remove_from_cart',
	ViewCart = 'view_cart',
	BeginCheckout = 'begin_checkout',
	AddShippingInfo = 'add_shipping_info',
	AddPaymentInfo = 'add_payment_info',
	Purchase = 'purchase',
	GenerateLead = 'generate_lead',
	LoginEvent = 'login_event',
	NewsletterSubscribe = 'newsletter_subscribe',
	AccountVerified = 'account_verified',
	UploadQuotation = 'upload_quotation',
	ErrorEvent = 'error_event',
	Search = 'search',
	CustomizeProduct = 'customize_product',
	UploadDigitalAdvisor = 'Upload digital advisor',
	Undefined = '',
}
