export type LocalazyLanguage = {
    language: string;
    region: string;
    script: string;
    isRtl: boolean;
    localizedName: string;
    name: string;
    pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};
export type LocalazyFile = {
    cdnHash: string;
    file: string;
    path: string;
    library: string;
    module: string;
    buildType: string;
    productFlavors: string[];
    cdnFiles: { [lang:string]: string };
};
export type LocalazyMetadata = {
    projectUrl: string;
    baseLocale: string;
    languages: LocalazyLanguage[];
    files: LocalazyFile[];
};
     
const localazyMetadata: LocalazyMetadata = {
  projectUrl: "https://localazy.com/p/cadac-store",
  baseLocale: "en",
  languages: [
    {
      language: "da",
      region: "",
      script: "",
      isRtl: false,
      name: "Danish",
      localizedName: "Dansk",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "es",
      region: "",
      script: "",
      isRtl: false,
      name: "Spanish",
      localizedName: "Español",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fr",
      region: "",
      script: "",
      isRtl: false,
      name: "French",
      localizedName: "Français",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "it",
      region: "",
      script: "",
      isRtl: false,
      name: "Italian",
      localizedName: "Italiano",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "nb",
      region: "",
      script: "",
      isRtl: false,
      name: "Norwegian Bokmål",
      localizedName: "Norsk Bokmål",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "nl",
      region: "",
      script: "",
      isRtl: false,
      name: "Dutch",
      localizedName: "Nederlands",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "pt",
      region: "",
      script: "",
      isRtl: false,
      name: "Portuguese",
      localizedName: "Português",
      pluralType: (n) => { return (n>=0 && n<=1) ? "one" : "other"; }
    },
    {
      language: "sv",
      region: "",
      script: "",
      isRtl: false,
      name: "Swedish",
      localizedName: "Svenska",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    }
  ],
  files: [
    {
      cdnHash: "2c57f827a9353c5c6764d03d9772b4f66a5a5d16",
      file: "file.json",
      path: "",
      library: "",
      module: "",
      buildType: "",
      productFlavors: [],
      cdnFiles: {
        "da": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/da/file.json",
        "de": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/de/file.json",
        "en": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/en/file.json",
        "es": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/es/file.json",
        "fr": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/fr/file.json",
        "it": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/it/file.json",
        "nb": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/nb/file.json",
        "nl": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/nl/file.json",
        "pt": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/pt/file.json",
        "sv": "https://delivery.localazy.com/_a8338055222696799880e0769b89/_a8328594099755747718/2c57f827a9353c5c6764d03d9772b4f66a5a5d16/sv/file.json"
      }
    }
  ]
};

export default localazyMetadata;