<template>
	<DataTable
		v-if="vm?.training"
		v-model:editingRows="editingRows"
		v-model:expanded-rows="expandedRows"
		auto-layout
		data-key="id"
		edit-mode="row"
		:row-class="highlightRow"
		:row-hover="vm?.training?.canBeEdited"
		:value="currentRows"
		@row-click.self="onRowClick"
	>
		<template #header>
			<div class="d-flex justify-content-end">
				<Button
					v-if="canAddParticipant"
					class="p-button-raised p-button-primary p-button-sm"
					icon="pi pi-plus-circle"
					icon-pos="left"
					:label="$t('myCadac.trainingDetail.button.addParticipant')"
					@click="onAddParticipantClick()"
				/>
			</div>
		</template>
		<Column header-style="width: 1.75rem">
			<template #body="item">
				<Button
					v-if="canExpandRow(item.data)"
					:class="isSmallScreen ? 'p-button-link text-primary' : 'p-row-toggler p-link'"
					:icon="isExpandedRow(item.data) ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
					:label="
						isSmallScreen
							? isExpandedRow(item.data)
								? $t('myCadac.trainingDetail.table.lblHideExpandButton')
								: $t('myCadac.trainingDetail.table.lblExpandButton')
							: ''
					"
					:title="
						isExpandedRow(item.data)
							? $t('myCadac.trainingDetail.table.lblHideExpandButton')
							: $t('myCadac.trainingDetail.table.lblExpandButton')
					"
					@click="toggleRowExpansion(item.data)"
				/>
			</template>
		</Column>

		<Column
			key="firstName"
			body-class=""
			field="firstName"
			:header="$t('myCadac.trainingDetail.table.firstName')"
		>
			<template #editor="participant">
				<InputText v-model="participant.data[participant.column.props.field]" />
			</template>
		</Column>

		<Column
			key="lastName"
			body-class=""
			field="lastName"
			:header="$t('myCadac.trainingDetail.table.lastName')"
		>
			<template #editor="participant">
				<InputText v-model="participant.data[participant.column.props.field]" />
			</template>
		</Column>

		<Column key="email" body-class="" field="email" :header="$t('myCadac.trainingDetail.table.email')">
			<template #editor="participant">
				<InputText v-model="participant.data[participant.column.props.field]" />
			</template>
		</Column>
		<Column key="actions" body-class="text-right" :sortable="false">
			<template #body="item">
				<Button
					v-if="canEditParticipant"
					class="p-button-icon p-button-rounded p-button-text p-button-raised"
					icon="pi pi-pencil"
					:label="isSmallScreen ? $t('myCadac.trainingDetail.table.lblEditButton') : ''"
					:title="$t('myCadac.trainingDetail.table.lblEditButton')"
					@click="onEditClick(item.data)"
				/>
				<Button
					v-if="canRemoveParticipant"
					class="p-button-icon p-button-rounded p-button-text p-button-raised p-button-danger ml-1"
					icon="pi pi-trash"
					:label="isSmallScreen ? $t('common.remove') : ''"
					:title="$t('common.remove')"
					@click="confirmRemoveParticipant(item.data)"
				/>
			</template>
			<template #editor="item">
				<Button
					class="p-button-icon p-button-rounded p-button-text p-button-sm p-button-secondary"
					icon="pi pi-times"
					:title="$t('myCadac.trainingDetail.table.lblCancelRowEditButon')"
					@click="cancelEditingRows(item.data)"
				/>
				<Button
					class="p-button-icon p-button-rounded p-button-sm p-button-success ml-2"
					icon="pi pi-check"
					:loading="isSpinnerVisible(item.data.id)"
					:title="$t('myCadac.trainingDetail.table.lblSaveRowEditButon')"
					@click="updateParticipant(item.data)"
				/>
			</template>
		</Column>
		<template #expansion="item">
			<div class="row-expansion-content">
				<Message v-if="vm.howToStartTrainingLink" class="mt-0" :closable="false" severity="info">
					<i18n-t keypath="myCadac.trainingDetail.table.vdiInfo.firstTimeHelp">
						<a :href="vm.howToStartTrainingLink" target="_blank">
							{{ $t("myCadac.trainingDetail.table.vdiInfo.firstTimeHelpLink") }}
						</a>
					</i18n-t>
				</Message>
				<div class="d-flex flex-wrap">
					<dl v-if="canShowVdiInfo(item.data)" class="training-summary">
						<dt class="mb-1">
							<span class="text-underline">
								{{
									vm.isInstructor
										? $t("myCadac.trainingDetail.table.vdiInfo.lblAccountDetails")
										: $t("myCadac.trainingDetail.table.vdiInfo.lblYourAccountDetails")
								}}
							</span>
							<PopoverIcon class="d-inline-flex">
								<i class="ml-1 popover-icon pi pi-question" />
								<template #content>
									<span
										v-html="
											vm.isInstructor
												? $t(
														'myCadac.trainingDetail.table.vdiInfo.accountDetailsPopOverText'
													)
												: $t(
														'myCadac.trainingDetail.table.vdiInfo.yourAccountDetailsPopOverText'
													)
										"
									/>
								</template>
							</PopoverIcon>
						</dt>
						<div>
							<dt>
								{{ $t("myCadac.trainingDetail.table.vdiInfo.lblParticipant") }}
							</dt>
							<dd>
								{{ item.data.email }}
							</dd>
						</div>
						<div>
							<dt>
								{{ $t("myCadac.trainingDetail.table.vdiInfo.lblAccountName") }}
							</dt>
							<dd>
								{{ item.data.vmAccount }}
							</dd>
						</div>
						<div>
							<dt>
								{{ $t("myCadac.trainingDetail.table.vdiInfo.lblPassword") }}
							</dt>
							<dd>
								<Inplace :closable="false">
									<template #display>
										{{ $t("myCadac.trainingDetail.table.vdiInfo.lblShowPassword") }}
									</template>
									<template #content>
										<div>
											<p
												class="m-0 limit-line-width text-truncate"
												:title="$t('myCadac.trainingDetail.table.vdiInfo.lblPassword')"
											>
												{{ item.data.vmPassword }}
											</p>
											<Button
												class="p-button-outlined p-button-secondary copy-button"
												icon="pi pi-copy"
												@click="copyTextToClipboard(item.data.vmPassword)"
											/>
										</div>
									</template>
								</Inplace>
							</dd>
						</div>
					</dl>

					<dl class="training-summary">
						<dt class="mb-1">
							<span class="text-underline">{{
								$t("myCadac.trainingDetail.table.vdiInfo.lblLinks")
							}}</span>
							<PopoverIcon class="d-inline-flex">
								<i class="ml-1 popover-icon pi pi-question" />
								<template #content>
									<span
										v-html="
											canShowVdiInfo(item.data)
												? vm.isInstructor
													? $t('myCadac.trainingDetail.table.vdiInfo.linksPopOverText')
													: $t(
															'myCadac.trainingDetail.table.vdiInfo.yourLinksPopOverText'
														)
												: $t('myCadac.trainingDetail.table.vdiInfo.bookCodesPopOverText')
										"
									/>
								</template>
							</PopoverIcon>
						</dt>

						<div v-if="vm.training?.teamUrl">
							<dt>
								{{ $t("myCadac.trainingDetail.table.vdiInfo.lblMicrosoftTeams") }}
							</dt>
							<dd>
								<div>
									<p class="m-0 limit-line-width text-truncate" :title="vm.training.teamUrl">
										{{ vm.training.teamUrl }}
									</p>
									<Button
										class="p-button-outlined p-button-secondary copy-button"
										icon="pi pi-copy"
										@click="copyTextToClipboard(vm.training.teamUrl)"
									/>
								</div>
							</dd>
						</div>

						<div v-if="canShowVdiInfo(item.data)">
							<dt>
								{{ $t("myCadac.trainingDetail.table.vdiInfo.lblRemoteDesktop") }}
							</dt>
							<dd>
								<div>
									<p class="m-0 limit-line-width text-truncate" :title="item.data.vmUrl">
										{{ item.data.vmUrl }}
									</p>
									<Button
										class="p-button-outlined p-button-secondary copy-button"
										icon="pi pi-copy"
										@click="copyTextToClipboard(item.data.vmUrl)"
									/>
								</div>
							</dd>
						</div>

						<div v-if="canShowBookCodes(item.data)">
							<dt>
								{{ $t("myCadac.trainingDetail.table.vdiInfo.lblStudyBookCodes") }}
							</dt>
							<dd>
								<ol>
									<li v-for="code in item.data.studyBookCodes" :key="code">
										<div v-if="code">
											<p class="m-0 limit-line-width text-truncate" :title="code">
												{{ code }}
											</p>
											<Button
												class="p-button-outlined p-button-secondary copy-button ml-1"
												icon="pi pi-copy"
												@click="copyTextToClipboard(code)"
											/>
										</div>
									</li>
								</ol>
							</dd>
						</div>
					</dl>
					<Button
						v-if="vm.canRestartVdi"
						class="p-button-danger"
						:disabled="isRestartVdiButtonLocked"
						icon="pi pi-refresh"
						:label="$t('myCadac.trainingDetail.button.restartMachine')"
						:title="$t('myCadac.trainingDetail.button.restartMachine')"
						@click="restartVdi(item.data)"
					/>
				</div>
			</div>
		</template>
	</DataTable>
	<TrainingAddParticipantsModal
		v-if="trainingStore.isTrainingAddParticipantModalVisible"
		@add-participant="addParticipant"
	/>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import TrainingAddParticipantsModal from "@/components/training/trainingAddParticipantsModal.vue";
import { AddTrainingParticipantRequest } from "@/types/models/training/addTrainingParticipantRequest";
import { CartClient } from "@/types/models/cart/cartClient";
import { Component, Prop } from "vue-facing-decorator";
import { DeleteTrainingParticipantRequest } from "@/types/models/training/deleteTrainingParticipantRequest";
import { IPlannedTrainingDetail } from "@/types/viewModels/myCadac/plannedTrainingDetailViewModel";
import { LocalTrainingType } from "@/types/enum/localTrainingType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { TrainingParticipantViewModel } from "@/types/models/training/trainingParticipantViewModel";
import { cloneDeep } from "lodash";
import { useTrainingStore } from "@/store/training/trainingStore";

@Component({ components: { TrainingAddParticipantsModal } })
export default class TrainingParticipantsTable extends BaseComponent {
	@Prop({
		type: Object as PropType<IPlannedTrainingDetail>,
		required: true,
		default: {},
	})
	vm!: IPlannedTrainingDetail;

	isRestartVdiButtonLocked = false;

	currentRows: TrainingParticipantViewModel[] = [];
	originalRows: TrainingParticipantViewModel[] = [];
	editingRows: TrainingParticipantViewModel[] = [];
	expandedRows: TrainingParticipantViewModel[] = [];
	trainingStore = useTrainingStore();

	created(): void {
		this.currentRows = this.vm.training.participants;
		this.originalRows = cloneDeep(this.currentRows);
	}

	mounted(): void {
		// Expand row for current user
		if (this.vm.isParticipant) {
			const userParticipant = this.currentRows.find((x) => x.email === this.userProfile?.email);
			if (userParticipant) this.toggleRowExpansion(userParticipant);
		}
	}

	get canAddParticipant(): boolean {
		const { canBeEdited, trainingType, maxParticipants } = this.vm.training;
		return (
			(this.vm.isOwningCustomer || this.vm.isInstructor) &&
			canBeEdited &&
			this.currentRows.length < maxParticipants &&
			(trainingType === LocalTrainingType.companyTrainingLocal ||
				trainingType === LocalTrainingType.companyTrainingRemote)
		);
	}

	get canEditParticipant(): boolean {
		const { canBeEdited, trainingType } = this.vm.training;
		return (
			(this.vm.isOwningCustomer || this.vm.isInstructor) &&
			canBeEdited &&
			(trainingType === LocalTrainingType.companyTrainingLocal ||
				trainingType === LocalTrainingType.companyTrainingRemote)
		);
	}

	get canRemoveParticipant(): boolean {
		const { canBeEdited, trainingType } = this.vm.training;
		return (
			(this.vm.isOwningCustomer || this.vm.isInstructor) &&
			canBeEdited &&
			(trainingType === LocalTrainingType.companyTrainingLocal ||
				trainingType === LocalTrainingType.companyTrainingRemote)
		);
	}

	canShowVdiInfo(participant: TrainingParticipantViewModel): boolean {
		return (this.vm.isParticipant || this.vm.isInstructor) && participant.vmUrl?.length > 0;
	}

	canShowBookCodes(participant: TrainingParticipantViewModel): boolean {
		return (this.vm.isParticipant || this.vm.isInstructor) && participant.studyBookCodes?.length > 0;
	}

	canExpandRow(participant: TrainingParticipantViewModel): boolean {
		return this.canShowVdiInfo(participant) || this.canShowBookCodes(participant);
	}

	isExpandedRow(data: TrainingParticipantViewModel): boolean {
		return this.expandedRows.findIndex((x) => x.id === data.id) > -1;
	}

	highlightRow(rowToHighlight: TrainingParticipantViewModel): string {
		return this.isExpandedRow(rowToHighlight) ? "highlight" : "";
	}

	onEditClick(rowToEdit: TrainingParticipantViewModel): void {
		this.editingRows.push(rowToEdit);
		this.editingRows = [...this.editingRows];
	}

	onAddParticipantClick(): void {
		this.trainingStore.showModal("AddParticipant");
	}

	toggleRowExpansion(rowToAdd: TrainingParticipantViewModel): void {
		const existingRowIndex = this.expandedRows.findIndex((x) => x.id === rowToAdd.id);
		if (existingRowIndex > -1) {
			this.expandedRows.splice(existingRowIndex, 1);
		} else {
			this.expandedRows.push(rowToAdd);
		}
		this.expandedRows = [...this.expandedRows];
	}

	onRowClick(event: { data: TrainingParticipantViewModel; index: number }): void {
		if (!this.canExpandRow(event.data)) return;
		this.toggleRowExpansion(event.data);
	}

	copyTextToClipboard(text: string): void {
		this.copyToClipboard(text)
			.then(() => {
				this.$toast.add({
					severity: "info",
					detail: this.$t("myCadac.trainingDetail.toast.copiedToClipboard"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
			});
	}

	confirmRemoveParticipant(item: TrainingParticipantViewModel): void {
		this.$confirm.require({
			message: this.$t("myCadac.trainingDetail.message.confirmRemoveParticipant", [
				`${item.firstName} ${item.lastName}`,
			]),
			header: this.$t("common.messages.titleImportant"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.removeParticipant({ trainingParticipantId: item.id, trainingListId: this.vm.training.id });
			},
		});
	}

	protected removeParticipant(model: DeleteTrainingParticipantRequest): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post<CartClient>("/api/training/delete-participant", model)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
			});
	}

	addParticipant(participant: TrainingParticipantViewModel): void {
		this.loadingStore.increaseLoadingCount();
		if (this.currentRows.map((x) => x.email).includes(participant.email)) {
			this.$toast.add({
				severity: "error",
				summary: this.$t("common.messages.titleError"),
				detail: this.$t("myCadac.trainingDetail.toast.updateParticipantUniqueEmailError"),
				life: 3000,
			});
			return;
		}

		const payload: AddTrainingParticipantRequest = { ...participant, trainingListId: this.vm.training.id };
		this.loadingStore.showSpinner(participant.id);
		this.axios
			.post(`/api/training/add-participant`, payload, { requestId: "addParticipant" })
			.then(() => {
				this.trainingStore.hideModal("AddParticipant");
				window.location.reload();
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.trainingDetail.toast.addParticipantError"),
					life: 3000,
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
				this.loadingStore.hideSpinner(participant.id);
			});
	}

	updateParticipant(participant: TrainingParticipantViewModel): void {
		const existingRowIndex = this.currentRows.findIndex((x) => x.id === participant.id);
		if (
			this.currentRows[existingRowIndex].email !== participant.email &&
			this.currentRows.map((x) => x.email).includes(participant.email)
		) {
			this.$toast.add({
				severity: "error",
				summary: this.$t("common.messages.titleError"),
				detail: this.$t("myCadac.trainingDetail.toast.updateParticipantUniqueEmailError"),
				life: 3000,
			});
			return;
		}
		this.loadingStore.showSpinner(participant.id);
		this.axios
			.post(`/api/training/updateParticipant`, participant)
			.then(() => {
				this.saveEditingRows(participant);
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.trainingDetail.toast.updateParticipantSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.cancelEditingRows(participant);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.trainingDetail.toast.updateParticipantError"),
					life: 3000,
				});
			})
			.finally(() => this.loadingStore.hideSpinner(participant.id));
	}

	saveEditingRows(participant: TrainingParticipantViewModel): void {
		const participantListIndex = this.currentRows.findIndex((x) => x.id === participant.id);
		const editingRowIndex = this.editingRows.findIndex((x) => x.id === participant.id);

		this.currentRows[participantListIndex] = participant;
		this.originalRows[participantListIndex] = participant;

		if (editingRowIndex > -1) {
			this.editingRows.splice(editingRowIndex, 1);
		}
		this.editingRows = [...this.editingRows];
		this.currentRows = [...this.currentRows];
	}

	cancelEditingRows(participant: TrainingParticipantViewModel): void {
		const participantListIndex = this.currentRows.findIndex((x) => x.id === participant.id);
		const editingRowIndex = this.editingRows.findIndex((x) => x.id === participant.id);

		this.currentRows[participantListIndex] = cloneDeep(this.originalRows[participantListIndex]);

		if (editingRowIndex > -1) {
			this.editingRows.splice(editingRowIndex, 1);
		}
		this.editingRows = [...this.editingRows];
		this.currentRows = [...this.currentRows];
	}

	restartVdi(participant: TrainingParticipantViewModel): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/training/restartVirtualMachineForParticipant`, participant)
			.then((res) => {
				if (res?.data) {
					this.isRestartVdiButtonLocked = true;
					this.$toast.add({
						severity: "success",
						summary: this.$t("common.messages.titleSuccess"),
						detail: this.$t("myCadac.trainingDetail.toast.restartMachineSuccess", [
							`${participant.firstName} ${participant.lastName}`,
						]),
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: "error",
						summary: this.$t("common.messages.titleError"),
						detail: this.$t("myCadac.trainingDetail.toast.restartMachineError", [
							`${participant.firstName} ${participant.lastName}`,
						]),
					});
				}
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.trainingDetail.toast.restartMachineError", [
						`${participant.firstName} ${participant.lastName}`,
					]),
				});
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
			});
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-header) {
	background-color: var(--surface-a);
	border: none;
}

.copy-button {
	max-width: 1.5rem;
	max-height: 1.5rem;
	flex-shrink: 0;
	padding: 0.25rem;
	margin-left: 0.25rem;
}

.limit-line-width {
	min-width: 0;
	max-width: 300px;
}

dl.training-summary {
	flex: 0 0 50%;

	div {
		display: flex;

		dt {
			display: inline-flex;
			flex: 1 0 15%;
		}

		dd {
			display: inline-flex;
			flex: 3 1 15%;
			margin-bottom: 0.25rem;
		}
	}
}
</style>
