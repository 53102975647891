import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, _mergeProps({
    arrow: "",
    "close-delay": "0",
    hover: "",
    "open-delay": "0",
    placement: "top"
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }), {
    content: _withCtx((props) => [
      _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(props)), undefined, true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16))
}