export interface RequestManagerOptions {
	debug: boolean;
}

export class RequestManager {
	options: RequestManagerOptions;
	pendingRequests: any;

	constructor(options: RequestManagerOptions) {
		this.options = options || {};
		this.pendingRequests = {};
	}

	// Add request
	addRequest(
		requestId: string,
		cancelFn: () => unknown,
		reason = `cancelRequest request from addRequest`
	): void {
		this.log(`addRequest: ${requestId}`);
		if (requestId === undefined) return;
		// The same request exists - cancel request first
		this.has(requestId) && this.cancelRequest(requestId, reason);
		this.pendingRequests[requestId] = cancelFn;
	}

	// Delete request
	removeRequest(requestId: string): void {
		this.log(`removeRequest: ${requestId}`);
		if (requestId === undefined) return;
		delete this.pendingRequests[requestId];
	}

	// Cancel request
	cancelRequest(requestId: string, reason?: string): void {
		if (requestId === undefined) return;

		if (Array.isArray(requestId)) {
			requestId.forEach((id) => {
				this.cancelRequestSingle(id, reason);
			});
		} else {
			this.cancelRequestSingle(requestId, reason);
		}
	}

	// Cancel request single
	cancelRequestSingle(requestId: string, reason?: string): void {
		if (requestId === undefined) return;

		if (this.has(requestId)) {
			this.log(`cancelRequest: ${requestId}`);

			if (typeof this.pendingRequests[requestId] !== 'function') return;

			this.pendingRequests[requestId](`${reason} \`requestId: ${requestId}\``);
			this.removeRequest(requestId);
		}
	}

	// Cancel all requests
	cancelAllRequests(reason: string): void {
		for (const requestId in this.pendingRequests) {
			this.cancelRequest(requestId, reason);
		}
	}

	// Check if the requestId exists
	has(requestId: string): boolean {
		return this.pendingRequests[requestId];
	}

	// Console log
	log(message: string): void {
		this.options.debug && console.log(message);
	}
}
