<template>
	<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>

	<Card v-for="[trainingLinkId, trainings] in trainingsGroupedByLinkId" :key="trainingLinkId" class="mb-3">
		<template #header>
			<div class="text-right text-lg-left pb-0 pt-2 px-2">
				<h5 class="mb-0 ml-1 text-bold text-primary">
					{{ trainings[0].name }}
				</h5>
			</div>
		</template>
		<template #content>
			<DataTable auto-layout responsive-layout="stack" :value="trainings">
				<Column key="trainingName" :header="$t('training.lblCourse')">
					<template #body="item">
						<div
							class="d-flex flex-wrap flex-lg-nowrap flex-row-reverse flex-lg-row text-right text-lg-left"
						>
							<Avatar
								v-if="item.data.productIconUrl"
								:alt="item.data.productIconAlt || item.data.subject"
								class="flex-shrink-0"
								:image="item.data.productIconUrl"
								shape="square"
								size="large"
								:title="item.data.subject"
							/>
							<div class="d-flex px-1 flex-column">
								<small class="mb-0 text-muted d-block">
									{{ item.data.brand }}
								</small>
								<span class="mb-0 text-bold">
									{{ item.data.name }}
								</span>
								<div
									class="d-flex align-items-center justify-content-end justify-content-lg-start"
								>
									<i
										class="pi pi-user text-primary mr-1"
										:title="$t('myCadac.trainingOverview.table.colParticipants')"
									/>
									<span>
										{{
											$tc("myCadac.trainingOverview.table.lblParticipantsCount", {
												count: item.data.participants?.length,
											})
										}}
									</span>
								</div>
							</div>
						</div>
					</template>
				</Column>

				<Column
					key="trainingType"
					field="trainingType"
					:header="$t('myCadac.trainingOverview.table.trainingInfo.colType')"
					style="width: 12.5%"
				>
					<template #body="item">
						<span class="">
							{{ $t(`enum.trainingType.${item.data.trainingType}`) }}
						</span>
					</template>
				</Column>

				<Column
					key="location"
					body-class="text-nowrap "
					field="location"
					:header="$t('myCadac.trainingOverview.table.colLocation')"
					style="width: 17.5%"
				>
					<template #body="item">
						<span class="text-preline">
							{{ formattedAddress(item.data) }}
						</span>
					</template>
				</Column>

				<Column
					key="date"
					field="date"
					:header="$t('myCadac.trainingOverview.table.colDate')"
					style="width: 22.5%"
				>
					<template #body="item">
						<div class="d-flex align-items-center">
							<div class="d-flex flex-column text-right text-lg-left">
								<p class="mb-0 text-bold">
									{{
										$tc("myCadac.trainingOverview.table.lblDays", {
											count: item.data.dates?.length,
										})
									}}
								</p>
								<p class="mb-0">{{ item.data?.formattedDates }}</p>
								<small v-if="item.data.remainingDays > 0" class="text-info">
									{{
										$tc("myCadac.trainingOverview.table.lblDaysRemaining", {
											count: item.data.remainingDays,
										})
									}}
								</small>
							</div>
						</div>
					</template>
				</Column>

				<Column key="actions" body-class="text-right">
					<template #body="training">
						<Button
							v-if="!vm.isInstructorOfATraining"
							class="p-button-primary p-button-raised p-button-text p-button-rounded ml-auto"
							icon="pi pi-eye"
							:title="$t('myCadac.trainingOverview.table.lblView')"
							@click="openUrl(`${vm.finishedTrainingDetailUrl}?traininglistid=${training.data.id}`)"
						/>
						<Button
							v-else
							class="p-button-success ml-auto"
							:label="$t('myCadac.trainingOverview.table.lblEdit')"
							@click="openUrl(`${vm.finishedTrainingDetailUrl}?traininglistid=${training.data.id}`)"
						/>
					</template>
				</Column>
			</DataTable>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IFinishedTrainingOverview } from "@/types/viewModels/myCadac/finishedTrainingOverviewViewModel";
import { PropType } from "vue";
import { TrainingListOverviewItemViewModel } from "@/types/models/training/trainingListOverviewItemViewModel";
import { entries, groupBy } from "lodash";

@Component({ components: { MyCadacMenu } })
export default class FinishedTrainingOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<IFinishedTrainingOverview>,
		required: true,
		default: {},
	})
	vm!: IFinishedTrainingOverview;

	get trainingsGroupedByLinkId() {
		return entries(groupBy(this.vm.finishedTrainings, "trainingLinkId"));
	}

	get formattedAddress() {
		return (training: TrainingListOverviewItemViewModel) => {
			return training.trainingAddress
				? `${training.trainingAddress.street}
			${training.trainingAddress.postalCode} ${training.trainingAddress.city}
			${training.trainingAddress.country}`
				: training.trainingLocation?.name;
		};
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-card-body) {
	padding: 0.5rem 1rem;
}
</style>
