<template>
	<ProductOverviewLayout
		:vm="overviewVm"
		@on-filter-change="onFilterChange"
		@on-search="onSearch"
		@on-sort-change="onSortChange"
	>
		<template #header>
			<div class="pt-3 pb-2">
				<slot name="breadcrumbs" />
				<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
			</div>
		</template>
		<template #grid-item="{ data }">
			<div v-if="!isSpinnerVisible('product-overview')" class="col-md-6 col-xxl-4 mb-2">
				<TrainingOverviewItemCard :compact="isSmallScreen" :item="data" layout="grid" />
			</div>
			<div v-else class="col-md-6 col-xxl-4">
				<TrainingOverviewItemCardSkeleton type="grid" />
			</div>
		</template>
		<template #list-item="{ data }">
			<TrainingOverviewItemCard v-if="!isSpinnerVisible('product-overview')" :item="data" layout="list" />
			<TrainingOverviewItemCardSkeleton v-else type="list" />
		</template>
	</ProductOverviewLayout>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProductOverviewLayout, { IProductOverviewLayout } from "@/views/layout/productOverviewLayout.vue";
import TrainingOverviewItemCard from "@/components/training/trainingOverviewItemCard.vue";
import TrainingOverviewItemCardSkeleton from "@/components/training/trainingOverviewItemCardSkeleton.vue";
import { Component, Prop } from "vue-facing-decorator";
import { ITrainingProductOverview } from "@/types/viewModels/commerce/trainingProductOverviewViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { OverviewFilterViewModel } from "@/types/models/common/filters/overviewFilterViewModel";
import { OverviewSortType } from "@/types/enum/overviewSortType";
import { PropType } from "vue";
import { useProductOverviewFilterStore } from "@/store/common/productOverviewFiltersStore";
import { TrainingItemViewModel } from "@/types/models/training/trainingItemViewModel";

@Component({
	components: {
		ProductOverviewLayout,
		TrainingOverviewItemCard,
		TrainingOverviewItemCardSkeleton,
	},
})
export default class TrainingProductOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ITrainingProductOverview>,
		required: true,
		default: {},
	})
	vm!: ITrainingProductOverview;

	overviewItems: TrainingItemViewModel[] = [];
	filterStore = useProductOverviewFilterStore();

	created(): void {
		Log.info("TrainingProductOverview", this.vm);

		this.overviewItems = this.vm.overviewItems;
		this.filterStore.init(this.vm.filters);
	}

	get overviewVm(): IProductOverviewLayout {
		return { productType: ItemProductType.Training, items: this.overviewItems, defaultViewLayout: "list" };
	}

	onSortChange(sortType: OverviewSortType): void {
		this.filterStore.sortType = sortType;
		this.updateFilters();
	}

	onFilterChange(_filter: OverviewFilterViewModel): void {
		this.updateFilters();
	}

	onSearch(query: string): void {
		if (!this.filterStore.hasQueryFilter) return;
		this.filterStore.updateSearchQuery(query);
		this.updateFilters();
	}

	private updateFilters(): void {
		this.loadingStore.showSpinner("product-overview");
		this.loadingStore.increaseLoadingCount();
		this.filterStore
			.updateTrainingFilters()
			.then((data) => {
				this.overviewItems = data?.overviewItems ? [...data.overviewItems] : [];
			})
			.catch((err) => Log.error(err))
			.finally(() => {
				this.loadingStore.hideSpinner("product-overview");
				this.loadingStore.decreaseLoadingCount();
			});
	}
}
</script>

<style lang="scss" scoped>
:deep(.p-dataview) {
	.p-dataview-header {
		top: var(--navbarHeightInPx);
	}
}
</style>
