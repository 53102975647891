<template>
	<div class="status-badge d-inline-block d-lg-block" :class="getBadgeClass()">
		<span>{{ getTranslatedStatus() }}</span>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { ExpertBookingStatus } from "@/types/enum/expertBookingStatus";
import { ExpertPageState } from "@/types/enum/expertPageState";
import { PropType } from "vue";

export enum StatusBadgeType {
	TicketStatus,
	ExpertBookingStatus,
	ExpertPageState,
}

@Component
export default class StatusBadge extends Vue {
	@Prop({ type: Number, required: true }) status!: number;
	@Prop({ type: String, required: true, default: "" }) translationPrefix!: string;
	@Prop({ type: Number as PropType<StatusBadgeType>, required: false, default: StatusBadgeType.TicketStatus })
	type!: StatusBadgeType;

	getTranslatedStatus(): string {
		return this.$t(`${this.translationPrefix}.${this.status}`);
	}

	getBadgeClass(): { [name: string]: boolean } {
		switch (this.type) {
			case StatusBadgeType.TicketStatus:
				switch (this.status) {
					case 0:
						return { "status-warning": true, outlined: true };
					case 1:
						return { "status-success": true, outlined: true };
					case 2:
						return { "status-info": true, outlined: true };
					default:
						return { "status-secondary": true, outlined: true };
				}
			case StatusBadgeType.ExpertPageState:
				const expertPageStatus = this.status as ExpertPageState; /* eslint-disable-line */
				switch (expertPageStatus) {
					case ExpertPageState.appliedForApproval:
						return { "status-warning": true, outlined: true };
					case ExpertPageState.waitingForContract:
						return { "status-info": true, outlined: true };
					case ExpertPageState.approved:
						return { "status-success": true, outlined: true };
					case ExpertPageState.revoked:
						return { "status-danger": true, outlined: true };
					default:
						return { "status-secondary": true };
				}
			case StatusBadgeType.ExpertBookingStatus:
				const bookingStatus = this.status as ExpertBookingStatus; /* eslint-disable-line */
				switch (bookingStatus) {
					case ExpertBookingStatus.Declined:
						return { "status-danger": true };
					case ExpertBookingStatus.Rejected:
						return { "status-danger": true };
					case ExpertBookingStatus.Stopped:
						return { "status-danger": true };
					case ExpertBookingStatus.InProgress:
						return { "status-info": true };
					case ExpertBookingStatus.Cancelled:
						return { "status-secondary": true };
					case ExpertBookingStatus.Completed:
						return { "status-success": true };
					case ExpertBookingStatus.DeclineRequested:
						return { "status-danger": true, outlined: true };
					case ExpertBookingStatus.CancellationRequested:
						return { "status-secondary": true, outlined: true };
					case ExpertBookingStatus.AwaitingPayment:
						return { "status-warning": true, outlined: true };
					case ExpertBookingStatus.Planned:
						return { "status-success": true, outlined: true };
					case ExpertBookingStatus.Requested:
						return { "status-info": true, outlined: true };
					default:
						return { "status-secondary": true };
				}
		}
		return {};
	}
}
</script>

<style lang="scss" scoped>
.status-badge {
	padding: 0.4rem 0.6rem;
	font-size: 0.8rem;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: 1px solid #dfdfdf;
	border-radius: 4px;

	&.status-secondary {
		color: var(--primary-color-text);
		border-color: var(--secondary-color);
		background-color: var(--secondary-color);

		&.outlined {
			color: var(--secondary-color);
			border-color: var(--secondary-color);
			background-color: var(--surface-a);
		}
	}

	&.status-success {
		color: var(--primary-color-text);
		border-color: var(--success-color);
		background-color: var(--success-color);

		&.outlined {
			color: var(--success-color);
			border-color: var(--success-color);
			background-color: var(--surface-a);
		}
	}

	&.status-info {
		color: var(--primary-color-text);
		border-color: var(--info-color);
		background-color: var(--info-color);

		&.outlined {
			color: var(--info-color);
			border-color: var(--info-color);
			background-color: var(--surface-a);
		}
	}

	&.status-danger {
		color: var(--primary-color-text);
		border-color: var(--danger-color);
		background-color: var(--danger-color);

		&.outlined {
			color: var(--danger-color);
			border-color: var(--danger-color);
			background-color: var(--surface-a);
		}
	}

	&.status-warning {
		color: var(--primary-color-text);
		border-color: var(--warning-color);
		background-color: var(--warning-color);

		&.outlined {
			color: var(--warning-color);
			border-color: var(--warning-color);
			background-color: var(--surface-a);
		}
	}
}
</style>
