import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d3e8a4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column justify-content-center align-items-center flex-wrap gap-1" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "d-flex flex-column justify-content-center justify-content-sm-start" }
const _hoisted_4 = { class: "m-0 text-muted" }
const _hoisted_5 = {
  key: 0,
  class: "text-primary text-center text-sm-left mb-0"
}
const _hoisted_6 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mb-3" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Avatar, {
          class: "author-image",
          shape: "circle"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: _ctx.vm.image?.alt,
              class: "",
              src: _ctx.vm.image?.url
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.vm.company), 1),
          (_ctx.vm.name)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.vm.name), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.vm.function), 1)
        ]),
        (_ctx.vm.linkItem)
          ? (_openBlock(), _createBlock(_component_LinkButton, {
              key: 0,
              class: _normalizeClass(["w-100 mt-2", {
						[_ctx.ctaButtonClass(_ctx.vm.linkStyle)]: _ctx.ctaButtonClass(_ctx.vm.linkStyle),
						'p-button-success p-button-raised p-button-arrow': !_ctx.ctaButtonClass(_ctx.vm.linkStyle),
					}]),
              label: _ctx.vm.linkItem.text,
              target: _ctx.vm.linkItem.target,
              title: _ctx.vm.linkItem.title,
              url: _ctx.vm.linkItem.url
            }, null, 8, ["class", "label", "target", "title", "url"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}