<template>
	<a class="training-card-item" :href="item.productPageUrl" style="text-decoration: none">
		<Card
			v-if="!isGridLayout"
			class="training-list-card"
			:style="{ backgroundColor: item.backgroundColor }"
			:title="formatProductName(item.name, item.brand)"
			@click="openUrl(item.productPageUrl)"
		>
			<template #content>
				<div class="h-100">
					<img
						v-if="showHeaderImage && item.overviewImageVerticalUrl"
						v-lazy="item.overviewImageVerticalUrl"
						:alt="item.iconAlt"
						class="overview-image"
						:title="item.name"
					/>
					<div class="">
						<div class="d-flex mb-2">
							<div class="align-self-center mr-1">
								<img
									v-if="item.iconUrl"
									v-lazy="item.iconUrl"
									:alt="item.iconAlt"
									class="overview-product-icon"
									:title="item.name"
								/>
							</div>
							<div class="d-flex flex-column">
								<p class="mb-0 text-muted lead">
									{{ item.brand }}
								</p>
								<h4 class="mb-0 productname">
									{{ formatProductName(item.name, item.brand) }}
								</h4>
							</div>
						</div>
						<div class="mt-2 mt-lg-0 d-flex">
							<p v-if="item.ecommercePricePerPerson" class="lead text-muted d-flex flex-wrap text-right justify-content-end">
								<span>
									<span class="">{{ $t("common.from") }}</span>
									<s v-if="item.ecommercePricePerPerson !== item.ecommercePricePerPerson" class="ml-1 text-faded">
										{{ formatCurrency(item.ecommercePricePerPerson) }}
									</s>
								</span>
								<span>
									<b class="text-primary ml-1">{{ formatCurrency(item.ecommercePricePerPerson) }}</b>
									<span class="ml-1">{{ $t("common.perPerson") }}</span>
								</span>
							</p>
							<p v-else-if="(item.availableOnRequest || !item.ecommercePricePerPerson) && !item.label"
							   class="lead text-muted text-lg-right">
								{{ $t("product.availableOnRequest") }}
							</p>
						</div>
					</div>
					<Divider />
					<p class="mb-0 intro-text text-preline" v-html="item.intro" />
				</div>
			</template>
		</Card>
		<Card
			v-else
			class="training-grid-card"
			:class="{ compact: compact, 'cross-sell': isCrossSell }"
			:title="formatProductName(item.name, item.brand)"
			@click="openUrl(item.productPageUrl)"
		>
			<template #content>
				<div class="d-flex flex-wrap flex-column justify-content-between">
					<div class="d-flex justify-content-between flex-1">
						<div class="mr-1">
							<img
								v-if="item.iconUrl"
								v-lazy="item.iconUrl"
								:alt="item.iconAlt"
								class="overview-product-icon"
								:title="item.name"
							/>
						</div>
						<div class="d-flex flex-column flex-1">
							<p class="mb-0 text-muted lead">
								{{ item.brand }}
							</p>
							<h4 class="mb-0 productname">
								{{ formatProductName(item.name, item.brand) }}
							</h4>
						</div>
						<Tag
							v-if="isCrossSell"
							class="mb-auto"
							:value="$t('enum.itemProductType.' + item.productType)"
						/>
					</div>
					<div v-if="item.ecommercePricePerPerson"
						 class="d-flex text-muted"
						 :class="isCrossSell ? 'flex-column-reverse ml-auto text-right' : 'flex-column'">
						<p class="lead mb-0">
							{{ $t("common.from") }}
							<b class="text-primary ml-1"> {{ formatCurrency(item.ecommercePricePerPerson) }}</b>
							<span class="ml-1">{{ $t("common.perPerson") }}</span>
						</p>
						<p v-if="item.longestDuration" class="mb-0">
							<i class="pi pi-clock" style="margin-right: 0.25rem" />
							{{
								$t("training.trainingDurationMinMax", [
									item.shortestDuration,
									item.longestDuration,
								])
							}}
						</p>
					</div>
				</div>
				<template v-if="!isCrossSell">
					<Divider />
					<p class="intro-text text-preline" v-html="item.intro" />
				</template>
			</template>
		</Card>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Divider from "primevue/divider";
import Tag from "primevue/tag";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { TrainingItemViewModel } from "@/types/models/training/trainingItemViewModel";

@Component({ components: { Divider, Tag } })
export default class TrainingOverviewItemCard extends BaseComponent {
	@Prop({
		type: Object as PropType<TrainingItemViewModel>,
		required: true,
		default: {},
	})
	item!: TrainingItemViewModel;

	@Prop({
		type: String,
		required: true,
		default: "grid",
	})
	layout!: string;

	@Prop({
		type: Boolean,
		required: false,
		default: false,
	})
	compact!: boolean;

	@Prop({ type: Boolean, default: false, required: false })
	isCrossSell!: boolean;

	showHeaderImage = true; // TODO

	get isGridLayout(): boolean {
		return this.layout === "grid";
	}
}
</script>

<style scoped lang="scss">
.training-card-item {
	display: block;
}

.productname {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 1.4rem;
	line-height: 1.2;
	height: 2.4em;
	overflow: hidden;
}

::v-deep(.training-list-card) {
	.course-names {
		font-size: 1rem;
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.intro-text {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.overview-product-icon {
		height: 67px;
		width: 67px;
	}

	&.p-card {
		display: flex;
		flex-wrap: wrap;
		margin: 0.75rem;
		border: var(--surface-border);
		transition: all 0.4s;

		&:hover {
			box-shadow:
				4px 4px 2px 0px rgb(0 0 0 / 20%),
				0 1px 4px 0 rgb(0 0 0 / 14%),
				0 2px 8px 0 rgb(0 0 0 / 12%);
			cursor: pointer;
		}

		.p-card-body {
			padding: 0 1rem;
		}
	}
}

::v-deep(.training-grid-card) {
	&.compact {
		&.p-card {
			height: auto;
		}

		.p-card-content {
			padding: 0;
		}
	}

	&.cross-sell {
		&.p-card,
		.p-card-body {
			height: 100%;
		}

		.p-card-footer {
			display: none;
		}

		.p-card-content {
			padding: 0;
			height: 100%;
			min-height: 150px;

			.overview-product-icon {
				height: 54px;
				width: 54px;
				margin-right: 0.5rem;
			}

			.lead {
				font-size: 1.1rem;
			}

			h4 {
				font-size: 1.25rem;
			}
		}
	}

	.course-names {
		font-size: 1rem;
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.intro-text {
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.5;
		height: 6em;
	}

	.overview-product-icon {
		height: 67px;
		width: 67px;
	}

	&.p-card {
		border: var(--surface-border);
		transition: all 0.4s;
		position: relative;

		.p-card-header {
			img {
				height: 225px;
				object-fit: cover;
			}
		}

		.p-card-footer {
			position: absolute;
			bottom: 0;
			padding: 1rem 1rem 1rem 0;
		}

		&:hover {
			box-shadow:
				0px 6px 1px -1px rgb(0 0 0 / 20%),
				0px 1px 12px 0px rgb(0 0 0 / 14%),
				0px 1px 3px 0px rgb(0 0 0 / 12%);
			cursor: pointer;
		}
	}
}
</style>
