import axios, { AxiosError, AxiosInstance } from 'axios';
import { FormatHelper } from '@/types/helpers/formatHelper';
import { RequestManager, RequestManagerOptions } from '@/types/utils/httpRequestManager';

const httpClient = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	paramsSerializer: {
		// @ts-ignore
		indexes: true,
	},
	transformResponse: (data) => {
		let response: unknown;
		if (!data) return {};
		try {
			response = JSON.parse(data, FormatHelper.formatJsonAsCamelCase);
		} catch {
			response = data;
		}
		return response;
	},
});

useCancel(httpClient);

/// Custom cancel extension for Axios
/// Give requestId in the requestConfig to enable auto cancellation of repeat requests.
function useCancel(instance: AxiosInstance, options?: RequestManagerOptions) {
	const defaultOptions = {
		debug: false,
	};
	options && Object.assign(defaultOptions, options);

	const { CancelToken } = axios;
	const requestManager = new RequestManager(defaultOptions);

	instance.interceptors.request.use((config) => {
		const { requestId } = config || '';
		if (requestId) {
			const source = CancelToken.source();
			config.cancelToken = source.token;
			requestManager.addRequest(requestId, source.cancel);
		}

		return config;
	});

	instance.interceptors.response.use((response) => {
		const { requestId } = (response && response.config) || '';
		requestId && requestManager.removeRequest(requestId);

		return response;
	});

	instance.isCancelled = (error: AxiosError) => {
		return axios.isCancel(error);
	};

	instance.cancel = (requestId: string, reason?: string) => {
		requestId && requestManager.cancelRequest(requestId, reason);
	};

	instance.cancelAll = (reason: string) => {
		requestManager.cancelAllRequests(reason);
	};

	instance.hasPendingRequest = (requestId: string) => {
		return requestManager.has(requestId);
	};
}

declare module 'axios' {
	export interface AxiosInstance {
		cancel: (requestId: string, reason?: string) => void;
		cancelAll: (reason: string) => void;
		hasPendingRequest: (requestId: string) => boolean;
		isCancelled: (err) => boolean;
	}

	export interface AxiosRequestConfig {
		// This requestID is used as a unique identifier to allow cancelling of subsequent requests with the same ID.
		requestId?: string;
	}
}
export default httpClient;
