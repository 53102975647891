<template>
	<slot v-if="$slots.card" name="card" />
	<Panel
		v-if="isSmallScreen"
		class="col-lg-4 shadow-left sticky-bottom-panel"
		:collapsed="isMobilePriceSummaryCollapsed"
	>
		<template #header>
			<div class="header-col">
				<div
					class="d-flex justify-content-between align-items-center w-100 pt-2"
					@click="isMobilePriceSummaryCollapsed = !isMobilePriceSummaryCollapsed"
				>
					<ProgressSpinner
						v-if="isSpinnerVisible('priceSummary')"
						animation-duration=".75s"
						class="m-0"
						stroke-width="3"
						style="width: 2.25rem; height: 2.25rem"
					/>
					<h4 v-else class="text-primary mb-0">
						{{ formatCurrency(totalPrice) }}
					</h4>

					<span class="expander">----</span>

					<div>
						<div class="d-flex align-items-center justify-content-end" @click.stop>
							<Button
								class="p-button-success p-button-raised p-button-sm p-button-arrow w-100 px-2 mb-1"
								:disabled="!canSubmit"
								:label="buttonText"
								@click="clickAction()"
							/>
						</div>
					</div>
				</div>
				<small v-if="showTerms">
					<i18n-t keypath="checkout.lblAgreeWithTerms">
						<template #link>
							<!-- TODO get link for current locale-->
							<a :href="checkoutStore.vm.termsAndConditionsLink" target="_blank">{{
								$t("common.termsAndConditions")
							}}</a>
						</template>
					</i18n-t>
				</small>
			</div>
		</template>
		<template #default>
			<ScrollPanel style="width: 100%; height: clamp(200px, 30vh, 300px)">
				<ProgressSpinner
					v-if="isSpinnerVisible('priceSummary')"
					animation-duration=".75s"
					class="d-block ml-auto mr-auto"
					stroke-width="3"
				/>
				<slot v-else-if="$slots.priceSummary" name="priceSummary" />
			</ScrollPanel>
		</template>
	</Panel>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import ProgressSpinner from "primevue/progressspinner";
import ScrollPanel from "primevue/scrollpanel";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";

@Component({ components: { ScrollPanel, ProgressSpinner } })
export default class CartPageSummary extends BaseComponent {
	@Prop({ type: Number, required: true, default: 0 }) totalPrice!: number;
	@Prop({ type: Boolean, required: false, default: false }) canSubmit!: boolean;
	@Prop({ type: String, required: true, default: "" }) buttonText!: string;
	@Prop({ type: Boolean, default: false }) showTerms = false;

	isMobilePriceSummaryCollapsed = true;
	checkoutStore = useCheckoutStore();

	@Emit() clickAction(): void {
		return;
	}
}
</script>

<style scoped lang="scss">
.summary-sticky-top {
	position: sticky;
	top: var(--navbarHeightInPx);
	margin-bottom: 1rem;

	::v-deep(.p-card-footer) {
		padding-top: 0;
	}
}

.expander {
	color: var(--text-color-secondary);
	font-size: 2rem;
	font-weight: 600;
	cursor: pointer;
	position: absolute;
	top: -1.2rem;
	left: 50%;
	letter-spacing: -5px;
}

.shadow-left {
	box-shadow: -5px 0px 5px 1px #dddd;
}

.sticky-bottom-panel {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    background: var(--surface-a);
    box-shadow: 0px -4px 8px -7px rgba(0, 0, 0, 0.75);
}

.header-col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
</style>
