import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2759d66d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "advice-section mx-2 py-2 text-center w-100"
}
const _hoisted_2 = { class: "advice-section py-2 px-2" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex flex-row align-items-center justify-content-center mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.collapsed && _ctx.relatedProducts.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_i18n_t, {
            class: "text-center",
            keypath: "myCadac.advisorAdvice.adviceAmount",
            scope: "global",
            tag: "p"
          }, {
            amount: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(_ctx.relatedProducts.length), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedProducts, (relatedProduct, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: `nextStepAdvice_${index}`,
        class: "col-6 mb-3"
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.relatedProducts.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Avatar, {
                  image: relatedProduct.iconUrl,
                  size: "large"
                }, null, 8, ["image"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_i18n_t, {
            class: "text-center text-primary font-weight-normal",
            keypath: "myCadac.advisorAdvice.tokenAdviceTitle",
            scope: "global",
            tag: "b"
          }, {
            product: _withCtx(() => [
              _createTextVNode(_toDisplayString(relatedProduct.name), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_i18n_t, {
            class: "text-center",
            keypath: "myCadac.advisorAdvice.tokenAdviceMessage",
            scope: "global",
            tag: "p"
          }, {
            tokenAmount: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(relatedProduct.tokenAmount), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ])), [
        [_vShow, !_ctx.collapsed || _ctx.relatedProducts.length < 2]
      ])
    }), 128))
  ], 64))
}