<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<div class="row">
				<div :class="vm.hideInfoAndImage ? 'd-none' : 'col-md-6'">
					<Card>
						<template #header>
							<img v-if="vm.image" v-lazy="vm.image?.url" :alt="vm.image?.alt" />
						</template>
						<template #content>
							<p class="" v-html="vm.intro"></p>
						</template>
					</Card>
				</div>
				<div class="pt-2 pt-md-0" :class="vm.hideInfoAndImage ? 'col-12' : 'col-md-6 '">
					<h2 v-if="vm.title" class="display-4">{{ vm.title }}</h2>
					<ul class="faq-items">
						<li v-for="item in vm.faqItems" :key="`item_${item.faqId}`" class="faq-item">
							<a class="p-button p-button-arrow p-button-outlined" :href="item.url">
								{{ item.title }}
							</a>
						</li>
					</ul>
					<LinkButton
						v-if="vm.faqLinkItem"
						:class="
							ctaButtonClass(vm.linkItemStyle)
								? ctaButtonClass(vm.linkItemStyle)
								: 'p-button-text p-button-raised p-button-arrow'
						"
						:label="vm.faqLinkItem.text"
						:target="vm.faqLinkItem.target"
						:title="vm.faqLinkItem.title"
						:url="vm.faqLinkItem.url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { FeaturedFaqBlockViewModel } from "@/types/viewModels/blocks/featuredFaqBlockViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component
export default class FeaturedFaqBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<FeaturedFaqBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: FeaturedFaqBlockViewModel;

	created(): void {
		Log.info("FeaturedFaqBlock", this.vm);
	}
}
</script>

<style scoped lang="scss">
.faq-item {
	&:hover {
		a {
			background-color: var(--surface-e);
		}
	}

	a {
		background-color: var(--surface-a);
		border: 1px solid var(--surface-d);
		font-size: 1.1rem;
		text-decoration: none;
		justify-content: space-between;
		text-align: left;
		width: 100%;
		margin-left: 0;
		margin-bottom: 0.5rem;
	}
}
</style>
