<template>
	<footer class="hidden-print">
		<div v-if="vm" class="container">
			<div
				v-if="!layoutStore.isAuthenticated || !vm?.currentUser?.company?.companyName"
				class="region-container"
			>
				<Button
					class="p-button-text p-button-secondary btn-change-region"
					:disabled="vm?.availableLocationOptions?.length < 1"
					@click="onShowRegionModalClick()"
				>
					<font-awesome-icon :icon="['far', 'location-dot']" size="1x" />
					<h5 class="m-0 ml-1">{{ `${$t("footer.region")}:` }}</h5>
					<p class="m-0 ml-2 lead">{{ currentRegionName }}</p>
				</Button>
			</div>

			<Divider />

			<div class="row">
				<div
					v-for="(block, i) in vm.mainFooterBlocks"
					:key="i"
					class="col-md-6 col-lg-3 d-flex flex-column mb-2"
				>
					<h2 v-if="block.header">{{ block.header }}</h2>
					<a v-for="(item, itemIndex) in block.items" :key="itemIndex" :href="item.url">{{
						item.label
					}}</a>
				</div>
				<div class="col-12 mt-3">
					<p>{{ $t("footer.allPricesExclVat") }}</p>
				</div>
			</div>

			<Divider />

			<div class="row align-items-center">
				<div class="col my-1">
					<div class="cadac-logo">
						<a href="/">
							<svg height="43" width="237">
								<use xlink:href="#cadac-logo-white" />
							</svg>
						</a>
					</div>
				</div>

				<div class="col my-1 d-flex flex-wrap">
					<a class="mr-2" href="/">{{ $t("footer.copyright") }}</a>
					<a class="mr-2" :href="vm.privacyPolicyUrl" target="_blank">{{ $t("footer.privacy") }}</a>
					<a v-for="term in vm.termsAndConditions" class="mr-2" :key="term.text" :href="term.href" :target="term.target">
						{{ term.text }}
					</a>
				</div>

				<div class="col my-1">
					<div class="iso-icons justify-content-lg-end" v-if="vm.isoIconsBlock.items && vm.isoIconsBlock.items.length && vm.isoIconsBlock.isVisible">
						<div v-for="isoItem in vm.isoIconsBlock.items" :key="isoItem.standardNumber" align="center" class="ml-2">
							<img :src="isoItem.icon.url" :alt="isoItem.icon.alt" />
						</div>
					</div>
				</div>

				<div class="col my-1">
					<div class="social-icons justify-content-lg-end">
						<div class="twitter">
							<font-awesome-icon :icon="['fab', 'twitter']"
											   size="lg"
											   @click="openUrl(vm.twitterUrl, ' _blank')" />
						</div>
						<div class="facebook ml-2">
							<font-awesome-icon :icon="['fab', 'facebook-f']"
											   @click="openUrl(vm.facebookUrl, ' _blank')" />
						</div>
						<div class="linkedin ml-2">
							<font-awesome-icon :icon="['fab', 'linkedin-in']"
											   @click="openUrl(vm.linkedInUrl, ' _blank')" />
						</div>
						<div class="youtube ml-2">
							<font-awesome-icon :icon="['fab', 'youtube']"
											   @click="openUrl(vm.youtubeUrl, ' _blank')" />
						</div>
					</div>
				</div>
			</div>

			<Divider />

			<div v-if="vm.subFooterBlock" class="row">
				<div class="col-12 d-flex flex-wrap">
					<span class="font-weight-500 m-0 mr-2 ml-0">{{ vm.subFooterBlock?.header }}</span>
					<a v-for="(item, index) in vm.subFooterBlock.items" :key="index" class="mr-2" :href="item.url">
						{{ item.label }}
					</a>
				</div>
			</div>
		</div>
	</footer>
	<ChangeRegionModal
		v-if="layoutStore.changeRegionModalVisible"
		:should-confirm-region="vm.showRedirect"
		:vm="vm.availableLocationOptions"
	/>
</template>

<script lang="ts">
import ChangeRegionModal from "@/components/common/menu/changeRegionModal.vue";
import Divider from "primevue/divider";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";
import { IMainLayout } from "@/types/viewModels/common/mainLayoutViewModel";

@Component({ components: { Divider, ChangeRegionModal } })
export default class MainFooter extends BaseComponent {
	get vm(): IMainLayout {
		return this.layoutStore.vm;
	}

	get currentRegionName(): string {
		return this.layoutStore.vm?.currentLocation?.value || "";
	}

	onShowRegionModalClick(): void {
		if (this.layoutStore.vm.availableLocationOptions?.length < 1) return;
		this.layoutStore.showModal("ChangeRegion");
	}
}
</script>

<style scoped lang="scss">
    footer {
        display: flex;
        flex-direction: column;
        background-color: var(--surface-footer);
        color: var(--primary-color-text);
        padding: 1rem 0;

        a {
            color: var(--primary-color-text);
        }

        a:hover {
            color: var(--bluegray-200);
            cursor: pointer;
            text-decoration: underline;
        }

        .p-divider.p-divider-horizontal:before {
            border: 0.5px solid #535353;
        }

        .btn-change-region {
            &.p-button {
                color: var(--primary-color-text);
            }

            &.p-disabled {
                opacity: 1;
            }
        }

        .social-icons {
            display: flex;
            align-items: center;

            div {
                cursor: pointer;
                border: 2px solid #fff;
                border-radius: 50%;
                height: 2em;
                width: 2em;
                display: flex;
                justify-content: center;
                flex-shrink: 0;
                align-items: center;
                transition: all 0.2s ease-in-out;
            }

            .twitter:hover {
                color: #1da1f2;
                border-color: #1da1f2;
            }

            .facebook:hover {
                color: #4267b2;
                border-color: #4267b2;
            }

            .linkedin:hover {
                color: #0077b5;
                border-color: #0077b5;
            }

            .youtube:hover {
                color: #ff0000;
                border-color: #ff0000;
            }
        }

        .iso-icons {
            display: flex;
            align-items: center;

            div {
                height: 2em;
                width: 2em;
                display: flex;
                justify-content: center;
                flex-shrink: 0;
                align-items: center;

				img{
					width:100%;
				}
            }
        }
    }
</style>
