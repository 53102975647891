import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db3e845a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "expander"
}
const _hoisted_3 = { class: "pt-1" }
const _hoisted_4 = {
  key: 0,
  class: "text-right mb-1 mt-1"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "amount-input d-flex align-items-center" }
const _hoisted_8 = {
  key: 1,
  class: "text-right"
}
const _hoisted_9 = {
  key: 3,
  class: "summary-sticky-top mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SupportCard = _resolveComponent("SupportCard")!

  return (_ctx.isSmallScreen && _ctx.isProductAvailable && _ctx.isAutodeskProduct)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isSpinnerVisible('priceSummary'))
          ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
              key: 0,
              "animation-duration": ".75s",
              class: "d-block ml-auto mr-0",
              "stroke-width": "3"
            }))
          : (_ctx.$slots.priceSummary)
            ? _renderSlot(_ctx.$slots, "priceSummary", { key: 1 }, undefined, true)
            : _createCommentVNode("", true)
      ]))
    : (_ctx.isSmallScreen && _ctx.isProductAvailable)
      ? (_openBlock(), _createBlock(_component_Panel, {
          key: 1,
          collapsed: _ctx.isMobilePriceSummaryCollapsed
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", {
              class: "d-flex justify-content-between align-items-center w-100",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isMobilePriceSummaryCollapsed = !_ctx.isMobilePriceSummaryCollapsed))
            }, [
              (_ctx.isSpinnerVisible('priceSummary'))
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    "animation-duration": ".75s",
                    class: "m-0",
                    "stroke-width": "3",
                    style: {"width":"2.25rem","height":"2.25rem"}
                  }))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("h4", { class: "text-primary mb-0 h5" }, _toDisplayString(_ctx.formatCurrency(_ctx.totalPrice)), 513), [
                [_vShow, !_ctx.isSpinnerVisible('priceSummary')]
              ]),
              (!_ctx.isAutodeskProduct)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "----"))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "d-flex align-items-center justify-content-end",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                }, [
                  (_ctx.showAmountInput)
                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 0,
                        modelValue: _ctx.selectedAmount,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAmount) = $event)),
                        class: "mx-1",
                        "decrement-button-class": "p-button-outlined",
                        "increment-button-class": "p-button-outlined",
                        max: _ctx.maxAmount,
                        min: _ctx.minAmount,
                        "show-buttons": "",
                        style: {"width":"90px"},
                        "use-grouping": false,
                        onInput: _ctx.amountChanged
                      }, null, 8, ["modelValue", "max", "min", "onInput"]))
                    : _createCommentVNode("", true),
                  (!_ctx.isAutodeskProduct)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "p-button-success p-button-raised p-button-lg ml-1",
                        disabled: !_ctx.canSubmit,
                        icon: "pi pi-shopping-cart",
                        "icon-pos": "right",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addToCart()))
                      }, null, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.offerRequestFormUrl && !_ctx.isAutodeskProduct && _ctx.canSubmit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("a", {
                        class: "quote-btn-url",
                        href: _ctx.requestOfferForThisProductVariation(true),
                        target: "_blank"
                      }, _toDisplayString(_ctx.$t("product.button.quote")), 9, _hoisted_5)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          default: _withCtx(() => [
            (!_ctx.isAutodeskProduct)
              ? (_openBlock(), _createBlock(_component_ScrollPanel, {
                  key: 0,
                  style: {"width":"100%","height":"clamp(200px, 30vh, 300px)"}
                }, {
                  default: _withCtx(() => [
                    (_ctx.isSpinnerVisible('priceSummary'))
                      ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                          key: 0,
                          "animation-duration": ".75s",
                          class: "d-block ml-auto mr-0",
                          "stroke-width": "3"
                        }))
                      : (_ctx.$slots.priceSummary)
                        ? _renderSlot(_ctx.$slots, "priceSummary", { key: 1 }, undefined, true)
                        : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["collapsed"]))
      : (_ctx.isProductAvailable)
        ? (_openBlock(), _createBlock(_component_Card, {
            key: 2,
            class: "summary-sticky-top shadow-none"
          }, {
            title: _withCtx(() => [
              (_ctx.$slots.configurationSummary)
                ? _renderSlot(_ctx.$slots, "configurationSummary", { key: 0 }, undefined, true)
                : _createCommentVNode("", true)
            ]),
            content: _withCtx(() => [
              (_ctx.isSpinnerVisible('priceSummary'))
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    "animation-duration": ".75s",
                    class: "d-block ml-auto mr-0",
                    "stroke-width": "3"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.$slots.priceSummary)
                      ? _renderSlot(_ctx.$slots, "priceSummary", { key: 0 }, undefined, true)
                      : _createCommentVNode("", true)
                  ]))
            ]),
            footer: _withCtx(() => [
              (_ctx.$slots.validationMessage)
                ? _renderSlot(_ctx.$slots, "validationMessage", { key: 0 }, undefined, true)
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.showAmountInput && !_ctx.isAutodeskProduct)
                  ? (_openBlock(), _createBlock(_component_InputNumber, {
                      key: 0,
                      modelValue: _ctx.selectedAmount,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedAmount) = $event)),
                      class: "mr-2",
                      "decrement-button-class": "p-button-outlined",
                      "increment-button-class": "p-button-outlined",
                      max: _ctx.maxAmount,
                      min: _ctx.minAmount,
                      "show-buttons": "",
                      style: {"width":"clamp(125px, 25%, 175px)","height":"59px","margin-top":"-8px"},
                      "use-grouping": false,
                      onInput: _ctx.amountChanged
                    }, null, 8, ["modelValue", "max", "min", "onInput"]))
                  : _createCommentVNode("", true),
                (!_ctx.isAutodeskProduct)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      class: "p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1",
                      disabled: !_ctx.canSubmit,
                      label: _ctx.addToCartButtonLabel(),
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addToCart()))
                    }, null, 8, ["disabled", "label"]))
                  : _createCommentVNode("", true),
                (_ctx.isAutodeskProduct)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 2,
                      class: "p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 quote-btn",
                      disabled: !_ctx.canSubmit,
                      label: _ctx.$t(`product.button.quote`),
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.requestAutodeskQuote()))
                    }, null, 8, ["disabled", "label"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.offerRequestFormUrl)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (!_ctx.isAutodeskProduct)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: "p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1 quote-btn",
                          disabled: !_ctx.canSubmit,
                          label: _ctx.$t(`product.button.quote`),
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.requestOfferForThisProductVariation()))
                        }, null, 8, ["disabled", "label"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.$slots.walletMessage)
                ? _renderSlot(_ctx.$slots, "walletMessage", { key: 2 }, undefined, true)
                : _createCommentVNode("", true)
            ]),
            _: 3
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
            (_ctx.showSupportCard)
              ? (_openBlock(), _createBlock(_component_SupportCard, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
}