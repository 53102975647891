import {
	faArrowRightArrowLeft,
	faArrowRightLong,
	faArrowRightFromBracket,
	faBars,
	faBinarySlash,
	faBox,
	faBurger,
	faCartShopping,
	faCheckCircle,
	faCircleUser as farCircleUser,
	faCircleXmark,
	faCoins,
	faComputerMouse,
	faEarth,
	faEarthEurope,
	faFile,
	faFileContract,
	faFolders,
	faGraduationCap,
	faHardDrive,
	faHatWizard,
	faLifeRing,
	faLightbulbOn,
	faLocationDot,
	faMagnifyingGlass,
	faMapLocationDot,
	faQuestion,
	faRotateRight,
	faSuitcase,
	faUser as farUser,
	faUserCog,
	faUserSecret,
	faUserShield,
	faUserSlash,
	faCircleExclamation,
	faTriangleExclamation,
	faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faCircleUser as fasCircleUser,
	faUser,
	faListCheck,
	faPartyHorn,
	faChevronsDown,
	faChevronsUp,
	faPhone,
	faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons';
import { faTwitter, faFacebookF, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

const FONT_AWESOME_ICONS = [
	faArrowRightArrowLeft,
	faArrowRightLong,
	faArrowRightFromBracket,
	faBars,
	faBinarySlash,
	faBox,
	faBurger,
	faCartShopping,
	faCheckCircle,
	faCircleXmark,
	faCoins,
	faComputerMouse,
	faEarth,
	faEarthEurope,
	faFacebookF,
	faFile,
	faFileContract,
	faFolders,
	faGraduationCap,
	faHardDrive,
	faHatWizard,
	faLifeRing,
	faLightbulbOn,
	faLinkedinIn,
	faListCheck,
	faLocationDot,
	faMagnifyingGlass,
	faMapLocationDot,
	faQuestion,
	faRotateRight,
	faSuitcase,
	faTwitter,
	faUser,
	faUserCog,
	faUserSecret,
	faUserShield,
	faUserSlash,
	faYoutube,
	farCircleUser,
	farUser,
	fasCircleUser,
	faPartyHorn,
	faCircleExclamation,
	faTriangleExclamation,
	faChevronsDown,
	faChevronsUp,
	faCheck,
	faPhone,
	faPaperPlane,
];

export const loadIcons = (): void => {
	library.add(...FONT_AWESOME_ICONS);
};
