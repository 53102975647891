<template>
	<div>confirm</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";

@Component
export default class CheckoutOrderConfirmation extends BaseComponent {}
</script>

<style lang="scss" scoped></style>
