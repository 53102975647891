<template>
	<div class="ribbon" :class="{ [position]: position }">
		<span>{{ text }}</span>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";

type RibbonPosition = "top-left " | "top-right" | "bottom-left" | "bottom-right";

@Component
export default class Ribbon extends BaseComponent {
	@Prop({ type: String, required: true, default: "" }) text!: string;
	@Prop({ type: String as PropType<RibbonPosition>, required: false, default: "top-left" })
	position!: RibbonPosition;
}
</script>

<style scoped lang="scss">
.ribbon {
	position: absolute;
	right: 10px;
	top: -5px;
	overflow: hidden;
	width: 235px;
	height: 185px;

	& span {
		position: absolute;
		display: block;
		width: 235px;
		transform: rotate(45deg);
		top: 57px;
		right: -44px;

		white-space: pre-line;
		text-transform: uppercase;
		text-align: center;
		font-size: 0.8rem;
		font-weight: bold;
		line-height: 32px;

		color: var(--primary-color-text);
		background: linear-gradient(to bottom, var(--primary-color), var(--purple-600));
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.8);

		&::before,
		&::after {
			content: "";
			position: absolute;
			z-index: -1;
		}
	}

	&.top-left,
	&.bottom-left {
		right: auto;
		left: 5px;
	}

	&.bottom-left,
	&.bottom-right {
		top: auto;
		bottom: 0px;
	}

	&.top-left span,
	&.top-left span {
		&::before {
			left: 0;
			top: 100%;
		}

		&::after {
			right: 0;
			top: 100%;
		}
	}

	&.top-right span,
	&.top-right span {
		&::before {
			left: 0;
			top: 100%;
			border-left: 3px solid var(--primary-color-dark);
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid var(--primary-color-dark);
		}

		&::after {
			right: 0;
			top: 100%;
			border-right: 3px solid var(--primary-color-dark);
			border-left: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid var(--primary-color-dark);
		}
	}

	&.bottom-left span,
	&.bottom-left span {
		top: auto;
		bottom: 55px;

		&::before {
			right: 0;
			top: 100%;
			border-right: 3px solid var(--primary-color-dark);
			border-left: 3px solid transparent;
			border-top: 3px solid transparent;
			border-bottom: 3px solid var(--primary-color-dark);
		}

		&::after {
			left: 0;
			top: 100%;
			border-right: 3px solid var(--primary-color-dark);
			border-left: 3px solid transparent;
			border-top: 3px solid transparent;
			border-bottom: 3px solid var(--primary-color-dark);
		}
	}

	&.bottom-right span,
	&.bottom-right span {
		top: auto;
		bottom: 55px;

		&::before {
			right: 0;
			bottom: 100%;
			border-right: 3px solid var(--primary-color-dark);
			border-left: 3px solid transparent;
			border-top: 3px solid transparent;
			border-bottom: 3px solid var(--primary-color-dark);
		}

		&::after {
			left: 0;
			bottom: 100%;
			border-right: 3px solid var(--primary-color-dark);
			border-left: 3px solid transparent;
			border-top: 3px solid transparent;
			border-bottom: 3px solid var(--primary-color-dark);
		}
	}

	&.top-left span,
	&.top-left span,
	&.bottom-right span,
	&.bottom-right span {
		transform: rotate(-45deg);
	}
}
</style>
