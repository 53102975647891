<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '50vw', '1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '90vw' }"
		:closable="!mustChooseDefault"
		:close-on-escape="mustChooseDefault"
		:draggable="false"
		:header="$t('menu.dialog.chooseCompanyHeader')"
		:modal="true"
		:style="{ width: '40vw' }"
		@hide="onCancelClick()"
	>
		<p v-if="mustChooseDefault" class="lead">
			{{ $t("menu.dialog.chooseDefaultCompanyIntro") }}
		</p>
		<p v-else class="lead">
			{{ $t("menu.dialog.chooseCompanyIntro") }}
		</p>

		<DataTable
			v-model:selection="selectedCompany"
			auto-layout
			:paginator="showPagination"
			responsive-layout="stack"
			:rows="maxRowsToShow"
			selection-mode="single"
			:value="vm"
		>
			<Column
				v-if="!isSmallScreen"
				class="text-lg-center"
				selection-mode="single"
				style="padding: 0.75rem; overflow: hidden"
			/>
			<Column>
				<template #body="item">
					<div class="d-flex flex-row justify-center ml-0 ml-lg-2">
						<b>
							{{ item.data.name }} <span class="text-fade">({{ item.data.accountNumber }})</span>
						</b>
						<Chip v-if="item.data.isPreferredOrganization" class="p-chip-primary ml-1"
							>{{ $t("common.sortDefault") }}
						</Chip>
					</div>
				</template>
			</Column>
			<Column field="address" />
		</DataTable>

		<div v-if="!mustChooseDefault && canSetPreferredCompany" class="p-field-checkbox mt-3">
			<Checkbox v-model="setAsDefault" id="setAsDefaultCompany" :binary="true" name="setAsDefaultCompany" />
			<label class="ml-1 mb-0" :for="'setAsDefaultCompany'">
				{{ $t("menu.dialog.lblSetAsDefaultCompany") }}
			</label>
		</div>

		<template #footer>
			<Button
				v-if="!mustChooseDefault"
				class="p-button-text p-button-plain"
				:label="$t('common.cancel')"
				@click="onCancelClick()"
			/>
			<Button :disabled="!canSubmit" :label="$t('common.confirm')" @click="onConfirmClick()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import { Component, Prop } from "vue-facing-decorator";
import { CookieType } from "@/types/enum/cookieType";
import { Log } from "@/types/helpers/logHelper";
import { OrganizationOptionViewModel } from "@/types/models/menu/organizationOptionViewModel";
import { PropType } from "vue";
import { SetCookieRequest } from "@/types/models/menu/setCookieRequest";

@Component({ components: { Checkbox, Chip } })
export default class ChangeCompanyModal extends BaseComponent {
	@Prop({ type: Object as PropType<OrganizationOptionViewModel[]>, required: true, default: [] })
	vm!: OrganizationOptionViewModel[];

	selectedCompany: OrganizationOptionViewModel | null = null;
	isVisible = true;
	setAsDefault = false;
	mustChooseDefault = false;
	maxRowsToShow = 8;

	created(): void {
		this.selectedCompany = this.currentCompany || this.preferredCompany || this.vm[0];
		this.mustChooseDefault = !this.preferredCompany && this.canSetPreferredCompany;
	}

	get showPagination(): boolean {
		return this.vm?.length > this.maxRowsToShow;
	}

	get canSubmit(): boolean {
		return !!this.selectedCompany;
	}

	get canSetPreferredCompany(): boolean {
		return !this.userProfile?.isImpersonated && this.layoutStore.vm.isDebug;
	}

	get preferredCompany(): OrganizationOptionViewModel | null {
		return this.layoutStore.preferredCompany;
	}

	get currentCompany(): OrganizationOptionViewModel | null {
		return this.vm.find((x) => x.id === this.userProfile?.cadacOrganizationId) as OrganizationOptionViewModel;
	}

	onCancelClick(): void {
		localStorage.setItem("showedCompanyModal", "true");
		this.layoutStore.hideModal("ChangeCompany");
	}

	onConfirmClick() {
		if (!this.canSubmit) return;
		// Set preferred org if it's not been done before.
		localStorage.setItem("showedCompanyModal", "true");
		if (this.canSetPreferredCompany && (!this.preferredCompany || this.setAsDefault)) {
			this.setPreferredOrganization()
				.then(() => {
					this.changeToSelectedCompany();
				})
				.catch((err) => Log.error(err));
		} else {
			this.changeToSelectedCompany();
		}
	}

	private setPreferredOrganization(): Promise<void> {
		const { id } = this.selectedCompany as OrganizationOptionViewModel;
		const payload: SetCookieRequest = {
			type: CookieType.PreferredOrganization,
			value: id,
		};
		return this.axios.put("/api/cookie", payload);
	}

	private changeToSelectedCompany(): void {
		this.loadingStore.increaseLoadingCount();
		window.location.href = `/MyCadacStore/SwitchCustomer?cadacOrganizationId=${this.selectedCompany?.id}`;
		this.layoutStore.hideModal("ChangeCompany");
	}
}
</script>

<style scoped lang="scss"></style>
