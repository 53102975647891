import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-group col-12 col-xl-6" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-12" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "form-row" }
const _hoisted_9 = { class: "form-group col-12" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = { class: "form-row" }
const _hoisted_12 = { class: "form-group col-6" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "form-group col-6" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 0,
  class: "p-error"
}
const _hoisted_17 = {
  key: 1,
  class: "p-error"
}
const _hoisted_18 = { class: "form-row" }
const _hoisted_19 = { class: "form-group col-12" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 0,
  class: "p-error"
}
const _hoisted_22 = {
  key: 1,
  class: "p-error"
}
const _hoisted_23 = { key: 2 }
const _hoisted_24 = {
  class: "text-preline",
  style: {"word-break":"break-word"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertExperienceForm'].type?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblType")), 3),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.v$['expertExperienceForm'].type.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['expertExperienceForm'].type.$model) = $event)),
                disabled: !_ctx.canEdit,
                "option-label": "label",
                "option-value": "value",
                options: _ctx.typeOptions
              }, null, 8, ["modelValue", "disabled", "options"]),
              (_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].type))
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$["expertExperienceForm"].type?.required?.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertExperienceForm'].title?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblTitle")), 3),
              _createVNode(_component_InputText, {
                modelValue: _ctx.v$['expertExperienceForm'].title.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['expertExperienceForm'].title.$model) = $event)),
                class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertExperienceForm'].title) }),
                disabled: !_ctx.canEdit,
                type: "text"
              }, null, 8, ["modelValue", "class", "disabled"]),
              (_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].title))
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$["expertExperienceForm"].title?.required?.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertExperienceForm'].company?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblCompanyName")), 3),
              _createVNode(_component_InputText, {
                modelValue: _ctx.v$['expertExperienceForm'].company.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['expertExperienceForm'].company.$model) = $event)),
                class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertExperienceForm'].company) }),
                disabled: !_ctx.canEdit,
                type: "text"
              }, null, 8, ["modelValue", "class", "disabled"]),
              (_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].company))
                ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.v$["expertExperienceForm"].company?.required?.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertExperienceForm'].experienceStartDate?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblStartDate")), 3),
              _createVNode(_component_Calendar, {
                modelValue: _ctx.v$['expertExperienceForm'].experienceStartDate.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$['expertExperienceForm'].experienceStartDate.$model) = $event)),
                autocomplete: "off",
                class: "w-100",
                "date-format": "mm/yy",
                disabled: !_ctx.canEdit,
                "input-class": 
						_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].experienceStartDate) ? 'p-invalid' : ''
					,
                "manual-input": false,
                "show-icon": "",
                view: "month"
              }, null, 8, ["modelValue", "disabled", "input-class"]),
              (_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].experienceStartDate))
                ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.v$["expertExperienceForm"].experienceStartDate?.required?.$message), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertExperienceForm'].experienceEndDate?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblEndDate")), 3),
              _createVNode(_component_Calendar, {
                modelValue: _ctx.v$['expertExperienceForm'].experienceEndDate.$model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$['expertExperienceForm'].experienceEndDate.$model) = $event)),
                autocomplete: "off",
                class: "w-100",
                "date-format": "mm/yy",
                disabled: !_ctx.canEdit,
                "input-class": _ctx.shouldShowError(_ctx.v$['expertExperienceForm'].experienceEndDate) ? 'p-invalid' : '',
                "manual-input": false,
                "show-icon": "",
                view: "month"
              }, null, 8, ["modelValue", "disabled", "input-class"]),
              (_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].experienceEndDate))
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                    (!_ctx.v$['expertExperienceForm'].experienceEndDate.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.v$["expertExperienceForm"].experienceEndDate?.required?.$message), 1))
                      : (_ctx.v$['expertExperienceForm'].experienceEndDate.$model)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.v$["expertExperienceForm"].experienceEndDate.endDateAfterStartDate?.$message), 1))
                        : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("label", {
                class: _normalizeClass({ required: _ctx.v$['expertExperienceForm'].description?.required })
              }, _toDisplayString(_ctx.$t("common.forms.lblDescription")), 3),
              _createVNode(_component_Textarea, {
                modelValue: _ctx.v$['expertExperienceForm'].description.$model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$['expertExperienceForm'].description.$model) = $event)),
                "auto-resize": true,
                class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertExperienceForm'].description) }),
                cols: "30",
                disabled: !_ctx.canEdit,
                rows: "5"
              }, null, 8, ["modelValue", "class", "disabled"]),
              (_ctx.shouldShowError(_ctx.v$['expertExperienceForm'].description))
                ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                    (!_ctx.v$['expertExperienceForm'].description.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_21, _toDisplayString(_ctx.v$["expertExperienceForm"].description?.required?.$message), 1))
                      : (_ctx.v$['expertExperienceForm'].description.$model)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_22, _toDisplayString(_ctx.v$["expertExperienceForm"].description.minLength?.$message), 1))
                        : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["d-flex", { 'justify-content-end': _ctx.isDialog }])
        }, [
          (_ctx.isDialog)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "p-button-text",
                label: _ctx.$t(`common.cancel`),
                onClick: _ctx.onCancel
              }, null, 8, ["label", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            class: "p-button-primary",
            disabled: !_ctx.canSubmit,
            label: _ctx.$t(`common.save`),
            onClick: _ctx.onSubmit
          }, null, 8, ["disabled", "label", "onClick"])
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createElementVNode("dl", null, [
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.forms.lblType")), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.$t(`enum.expertExperienceType.${_ctx.vm.type}`)), 1),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.forms.lblTitle")), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.vm.title), 1),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.forms.lblCompanyName")), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.vm.company), 1),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.forms.lblDate")), 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.formatExperienceDate(_ctx.vm.experienceStartDate)) + " - " + _toDisplayString(_ctx.formatExperienceDate(_ctx.vm.experienceEndDate)), 1),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t("common.forms.lblDescription")), 1),
            _createElementVNode("dd", _hoisted_24, _toDisplayString(_ctx.vm.description), 1)
          ])
        ]))
  ], 64))
}