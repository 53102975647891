<template>
	<div class="form-row">
		<div class="form-group col-12">
			<label :class="{ required: v$['serverLicenceForm'].serverName?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblName") }}
			</label>
			<InputText
				v-model="v$['serverLicenceForm'].serverName.$model"
				:class="{ 'p-invalid': shouldShowError(v$['serverLicenceForm'].serverName) }"
				type="text"
			/>
			<small v-if="shouldShowError(v$['serverLicenceForm'].serverName)" class="p-error">
				{{ v$["serverLicenceForm"].serverName?.required?.$message }}
			</small>
		</div>
	</div>
	<div class="form-row">
		<div class="form-group col-md-12">
			<label :class="{ required: v$['serverLicenceForm'].email?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblEmail") }}
			</label>
			<InputText
				v-model="v$['serverLicenceForm'].email.$model"
				:class="{ 'p-invalid': shouldShowError(v$['serverLicenceForm'].email) }"
				:disabled="isEditing"
				type="email"
			/>
			<small
				v-if="shouldShowError(v$['serverLicenceForm'].email) && !v$['serverLicenceForm'].email.$model"
				class="p-error"
			>
				{{ v$["serverLicenceForm"].email?.required?.$message }}
			</small>
			<small
				v-else-if="shouldShowError(v$['serverLicenceForm'].email) && v$['serverLicenceForm'].email.$model"
				class="p-error"
			>
				{{ v$["serverLicenceForm"].email.email?.$message }}
			</small>
		</div>
	</div>
	<div v-if="selectedLicenceProduct.possibleRoles?.length > 1" class="form-row">
		<div class="form-group col-md-12">
			<label :class="{ required: v$['serverLicenceForm'].roles?.required }">
				{{ $t("myCadac.licenceManagementDetail.dialog.lblRoles") }}
			</label>
			<div class="d-flex align-items-start flex-wrap">
				<div
					v-for="(role, index) in selectedLicenceProduct.possibleRoles"
					:key="index"
					class="p-field-checkbox mr-2 mb-2"
				>
					<Checkbox
						v-model="v$['serverLicenceForm'].roles.$model"
						:id="`role${index}`"
						name="roles"
						:value="role"
					/>
					<label class="ml-1 mb-0" :for="`role${index}`">
						{{ $i18n.locale === "nl" ? role.descriptionNL : role.descriptionEN }}
					</label>
				</div>
			</div>
			<small v-if="shouldShowError(v$['serverLicenceForm'].roles)" class="p-error">
				{{ v$["serverLicenceForm"].roles?.required?.$message }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import { Component, Prop, VModel } from "vue-facing-decorator";
import { ILicenceManagementDetail } from "@/types/viewModels/myCadac/licenceManagementDetailViewModel";
import { Licence } from "@/types/generated/licence";
import { LicenceProduct } from "@/types/generated/licenceProduct";
import { LicenceRole } from "@/types/generated/licenceRole";
import { PropType } from "vue";
import { Validation } from "@vuelidate/core";
import { useLicenceStore } from "@/store/software/licenceStore";

@Component({ components: { Checkbox } })
export default class ServerLicenceForm extends BaseComponent {
	@VModel() v$!: Validation;
	@Prop({ type: Object as PropType<ILicenceManagementDetail>, required: true, default: {} })
	vm!: ILicenceManagementDetail;
	@Prop({ required: false, default: false }) submitted!: boolean;

	isEditing = false;
	licenceStore = useLicenceStore();

	created(): void {
		this.v$["serverLicenceForm"].roles.$model = [];
		this.v$["serverLicenceForm"].$reset();
		this.isEditing = !!this.selectedLicence?.id;
		if (this.licenceStore.selectedLicenceProduct?.possibleRoles.length === 1)
			this.v$["serverLicenceForm"].roles.$model = this.licenceStore.selectedLicenceProduct?.possibleRoles;
	}

	get selectedLicenceProduct(): LicenceProduct | null {
		return this.licenceStore.selectedLicenceProduct;
	}

	get selectedLicence(): Licence | null {
		return this.licenceStore.selectedLicence;
	}

	get possibleRoles(): string[] {
		return (this.selectedLicenceProduct?.possibleRoles as LicenceRole[]).map((x) =>
			this.$i18n.locale === "nl" ? x.descriptionNL : x.descriptionEN
		) as string[];
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}
}
</script>

<style scoped lang="scss"></style>
