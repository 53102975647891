import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-613645d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("p", {
          class: "text-preline",
          innerHTML: _ctx.vm.intro
        }, null, 8, _hoisted_1),
        (_ctx.vm.link)
          ? (_openBlock(), _createBlock(_component_LinkButton, {
              key: 0,
              class: _normalizeClass(["my-2", {
						[_ctx.ctaButtonClass(_ctx.vm.linkStyle)]: _ctx.ctaButtonClass(_ctx.vm.linkStyle),
						'p-button-success p-button-raised p-button-arrow': !_ctx.ctaButtonClass(_ctx.vm.linkStyle),
					}]),
              href: _ctx.vm.link.url,
              label: _ctx.vm.link.text,
              target: _ctx.vm.link.target,
              title: _ctx.vm.link.title,
              url: _ctx.vm.link.url
            }, null, 8, ["class", "href", "label", "target", "title", "url"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}