import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5a2abde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-list-item content-grid-card-item" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "contentcategory-tag" }
const _hoisted_6 = {
  key: 0,
  class: "title"
}
const _hoisted_7 = { class: "d-flex flex-row justify-content-between align-items-center" }
const _hoisted_8 = { class: "d-flex flex-column flex-sm-row" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.item?.url,
      style: {"text-decoration":"none"}
    }, [
      _createVNode(_component_Card, { class: "flex-1" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("img", {
              alt: _ctx.item.image?.alt,
              class: "image"
            }, null, 8, _hoisted_4), [
              [_directive_lazy, _ctx.item.image?.url]
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Tag, {
                value: _ctx.$t('contentCategory.lblCategoryTags.' + _ctx.item.contentCategory)
              }, null, 8, ["value"])
            ])
          ])
        ]),
        content: _withCtx(() => [
          (_ctx.item.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(_ctx.item.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.showUpcomingEventTag)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_Tag, {
                      class: "mr-1",
                      severity: "success",
                      value: _ctx.$t('contentCategory.lblUpcomingTag')
                    }, null, 8, ["value"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.contentDate), 1)
            ]),
            _createVNode(_component_Button, {
              class: "overlay p-button-link p-button-arrow",
              label: _ctx.$t('contentCategory.lblContinueReading')
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      })
    ], 8, _hoisted_2)
  ]))
}