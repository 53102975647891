<template>
	<form @change="formStateChanged">
		<div class="form-row">
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['gender'].required"
					:label="$t('common.forms.lblSalutation')"
					name="gender"
				>
					<div class="input-group">
						<div
							v-for="gender in availableGenderOptions"
							:key="`gender_${gender.value}`"
							class="form-check-inline"
						>
							<RadioButton
								v-model="field.value.value"
								:class="{ 'p-invalid': field.errors?.length }"
								:input-id="`gender_${gender.value}`"
								:name="field.name.value"
								:value="gender.value"
								@change="field.setTouched(true)"
							/>
							<span class="ml-1 mb-0" :for="`gender_${gender.value}`">
								{{ gender.label }}
							</span>
						</div>
					</div>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['firstName'].required"
					:label="$t('common.forms.lblFirstName')"
					name="firstName"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:feedback="false"
						:name="field.name"
						toggle-mask
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['lastName'].required"
					:label="$t('common.forms.lblLastName')"
					name="lastName"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:feedback="false"
						:name="field.name"
						toggle-mask
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['phone'].required"
					:label="$t('common.forms.lblPhoneNumber')"
					name="phone"
				>
					<vue-tel-input
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:input-options="{ placeholder: '' }"
						@change="field.setTouched(true)"
						@validate="onPhoneInputValidate"
					/>
				</InputWithValidation>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import InputWithValidation from "@/components/common/inputWithValidation.vue";
import { Component, Emit, Model, Watch } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { FormContext, FormMeta, useForm } from "vee-validate";
import { Gender } from "@/types/enum/gender";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { IPhoneObject } from "@/types/models/common/phoneObject.interface";
import { PersonalForm as PersonalFormModel } from "@/types/models/form/personalForm";
import { cloneDeep } from "lodash";
import { computed, PropType, reactive, unref } from "vue";

@Component({ components: { InputWithValidation }, emits: ["update:modelValue"] })
export default class PersonalForm extends BaseComponent implements FormComponent<PersonalFormModel> {
	@Model({
		type: Object as PropType<PersonalFormModel>,
		required: true,
		default: {},
	})
	model!: PersonalFormModel;

	@Emit() formStateChanged(): FormMeta<PersonalFormModel> {
		const { meta, values } = this.form;
		this.model = cloneDeep(values);
		return unref(meta);
	}

	phoneObject!: IPhoneObject;
	availableGenderOptions: IDropdownItem[] = [];

	form!: FormContext<PersonalFormModel>;

	created(): void {
		this.availableGenderOptions = this.layoutStore.vm.genderOptions
			.filter((x) => x.key != Gender.Unknown)
			.map((x) => ({
				value: x.key,
				label: this.$t(`enum.salutation.${x.key}`),
			}));
		this.form = useForm({ initialValues: this.model, validationSchema: this.validations });
	}

	get validations(): Record<string, unknown> {
		//Use reactive and computed to make sure the property gets updated properly.
		return reactive({
			gender: {
				required: true,
				gender: true,
			},
			firstName: { required: true },
			lastName: { required: true },
			phone: {
				required: true,
				validPhone: computed(() => this.phoneObject),
			},
		});
	}

	@Watch("phoneObject", { deep: true })
	@Watch("form.values.gender", { deep: true })
	onChange(): void {
		this.formStateChanged();
	}

	get isValid(): boolean {
		return unref(this.form.meta)?.valid;
	}

	get isDirty(): boolean {
		return unref(this.form.meta)?.dirty;
	}

	onPhoneInputValidate(phoneObject: IPhoneObject): void {
		this.phoneObject = phoneObject;
	}
}
</script>

<style scoped lang="scss"></style>
