import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42fa7329"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row my-3" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "col-lg-8 col-xl-9" }
const _hoisted_6 = {
  key: 0,
  class: "col-lg-4 col-xl-3"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckoutSteps = _resolveComponent("CheckoutSteps")!
  const _component_Message = _resolveComponent("Message")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_Button = _resolveComponent("Button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Card = _resolveComponent("Card")!
  const _component_CartPageSummary = _resolveComponent("CartPageSummary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.checkoutStore.vm.pageTitle)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.checkoutStore.vm.pageTitle), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CheckoutSteps),
          (_ctx.vm.paymentFailed)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                closable: false,
                severity: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.paymentFailedMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.vm.allowPlaceOrder)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 1,
                closable: false,
                severity: "warn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("checkout.message.orderNotAllowed")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view)
        ]),
        (!_ctx.isSmallScreen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Card, { class: "checkout-summary" }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("checkout.titleSummary")), 1)
                ]),
                content: _withCtx(() => [
                  _createVNode(_component_PriceSummary, { vm: _ctx.priceSummary }, null, 8, ["vm"])
                ]),
                footer: _withCtx(() => [
                  _createVNode(_component_Button, {
                    class: "p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 mb-1",
                    disabled: !_ctx.canGoToStep(_ctx.activeStep + 1),
                    label: _ctx.$t(`checkout.button.goToStep${_ctx.activeStep === -1 ? 2 : _ctx.activeStep + 2}`),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToNextStep()))
                  }, null, 8, ["disabled", "label"]),
                  (_ctx.isOverviewStep)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_7, [
                        _createVNode(_component_i18n_t, { keypath: "checkout.lblAgreeWithTerms" }, {
                          link: _withCtx(() => [
                            _createElementVNode("a", {
                              href: _ctx.checkoutStore.vm.termsAndConditionsLink,
                              target: "_blank"
                            }, _toDisplayString(_ctx.$t("common.termsAndConditions")), 9, _hoisted_8)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isSmallScreen)
      ? (_openBlock(), _createBlock(_component_CartPageSummary, {
          key: 0,
          "button-text": _ctx.$t(`checkout.button.goToStep${_ctx.activeStep === -1 ? 2 : _ctx.activeStep + 2}`),
          "can-submit": _ctx.canGoToStep(_ctx.activeStep + 1),
          "show-terms": _ctx.isOverviewStep,
          "total-price": _ctx.priceSummary?.totalPrice,
          onClickAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToNextStep()))
        }, {
          priceSummary: _withCtx(() => [
            _createVNode(_component_PriceSummary, { vm: _ctx.priceSummary }, null, 8, ["vm"])
          ]),
          _: 1
        }, 8, ["button-text", "can-submit", "show-terms", "total-price"]))
      : _createCommentVNode("", true)
  ], 64))
}