<template>
	<div>
		<Dialog
			v-model:visible="isVisible"
			:breakpoints="{
				'2000px': '50vw',
				'1600px': '60vw',
				'1200px': '70vw',
				'992px': '75vw',
				'768px': '80vw',
				'576px': '90vw',
			}"
			class="p-dialog-secondary advisor-upload-modal"
			:closable="canClose"
			:draggable="false"
			:modal="true"
			:style="{ width: '40vw' }"
			@hide="hideModal()"
		>
			<template v-if="canClose" #header>
				<div class="d-flex flex-column pt-2">
					<h3 v-if="vm.title" class="mb-0">{{ vm.title }}</h3>
				</div>
			</template>
			<div v-show="currentStep === 1" class="step-wrapper" style="gap: 1.5rem">
				<p class="lead w-100 mb-0">{{ vm.introText }}</p>
				<div class="upload-container" :style="isSmallScreen ? 'order:2' : 'order:1'">
					<FileUpload
						ref="fileUploader"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						:auto="true"
						:custom-upload="true"
						:invalid-file-type-message="$t('digitalAdvisor.dialog.msgInvalidFileType')"
						:max-file-size="sizeLimit"
						mode="advanced"
						:multiple="false"
						name="advisorUpload"
						:show-cancel-button="false"
						:show-upload-button="false"
						@uploader="onFileSelected"
					>
						<template #header></template>
						<template #empty>
							<i class="pi pi-file-excel"></i>
							<i18n-t
								class="mb-0"
								keypath="digitalAdvisor.dialog.lblDragFile"
								scope="global"
								tag="p"
							>
								<template #action>
									<Button
										class="p-button-link text-primary p-0"
										:label="$t('common.chooseFile')"
										@click="onFileUpload"
									></Button>
								</template>
							</i18n-t>
						</template>
					</FileUpload>
					<div
						v-if="selectedFile"
						class="p-1 d-flex align-items-center w-100 flex-wrap justify-content-center"
					>
						<div>
							<img
								v-if="selectedFile?.objectURL"
								:alt="selectedFile?.name"
								class=""
								height="50"
								role="presentation"
								:src="selectedFile?.objectURL"
								width="100"
							/>
							<font-awesome-icon v-else class="text-primary" :icon="['far', 'file']" size="1x" />
						</div>
						<span class="text-bold ml-2 flex-1">{{ selectedFile?.name }}</span>
						<div class="ml-2">{{ formatSize(selectedFile?.size) }}</div>
						<Button
							class="p-button-text p-button-danger p-button-rounded ml-2"
							icon="pi pi-trash"
							:label="isSmallScreen ? $t('common.remove') : ''"
							:title="$t('common.remove')"
							@click="onFileRemoved(selectedFile)"
						/>
					</div>
				</div>
				<div v-if="hasVideo" class="video-container" :style="isSmallScreen ? 'order:1' : 'order:2'">
					<iframe
						v-if="showVideo"
						allowfullscreen
						height="100%"
						:src="`https://www.youtube.com/embed/${vm.youtubeId}?autoplay=1`"
						type="video/mp4"
						width="100%"
					/>
					<div v-else class="video-overlay">
						<button class="play-button" @click="onPlayVideoClick">
							<svg>
								<use xlink:href="#icon-play"></use>
							</svg>
						</button>
						<img
							v-lazy="`https://img.youtube.com/vi/${vm.youtubeId}/maxresdefault.jpg`"
							alt="Youtube thumbnail"
							class="video-image"
						/>
					</div>
				</div>
				<Message
					v-if="!uploadSuccess && selectedFile && !isBusy"
					class="my-0 w-100"
					:closable="false"
					severity="error"
					style="order: 3"
				>
					{{ $t("digitalAdvisor.dialog.msgUploadError") }}
				</Message>
			</div>
			<div v-show="currentStep === 2" class="step-wrapper flex-column">
				<div
					v-if="selectedFile"
					class="mb-2 p-1 d-flex align-items-center w-100 flex-wrap justify-content-center"
				>
					<div>
						<img
							v-if="selectedFile?.objectURL"
							:alt="selectedFile?.name"
							class=""
							height="50"
							role="presentation"
							:src="selectedFile?.objectURL"
							width="100"
						/>
						<font-awesome-icon v-else class="text-primary" :icon="['far', 'file']" size="1x" />
					</div>
					<div class="text-bold flex-1">
						<span class="ml-2">{{ selectedFile?.name }}</span>
						<span v-if="uploadSuccess" class="text-success ml-2">
							{{ $t("digitalAdvisor.dialog.lblUploadSuccess") }}
						</span>
						<span v-else class="text-danger ml-2">
							{{ $t("digitalAdvisor.dialog.lblUploadFailed") }}
						</span>
					</div>

					<div class="ml-2">{{ formatSize(selectedFile?.size) }}</div>
					<Button
						class="p-button-text p-button-danger p-button-rounded ml-2"
						icon="pi pi-trash"
						:label="isSmallScreen ? $t('common.remove') : ''"
						:title="$t('common.remove')"
						@click="onFileRemoved(selectedFile)"
					/>
				</div>
				<template v-if="uploadSuccess">
					<p class="text-preline" v-html="vm.emailIntroText"></p>
					<div class="form-group">
						<label :class="{ required: v$['usageUploadForm'].email?.required }">
							{{ $t("common.forms.lblEmail") }}
						</label>
						<InputText
							v-model="v$['usageUploadForm'].email.$model"
							:class="{ 'p-invalid': shouldShowError(v$['usageUploadForm'].email) }"
							type="email"
						/>
						<small
							v-if="
								shouldShowError(v$['usageUploadForm'].email) && !v$['usageUploadForm'].email.$model
							"
							class="p-error"
						>
							{{ v$["usageUploadForm"].email?.required?.$message }}
						</small>
						<small
							v-else-if="
								shouldShowError(v$['usageUploadForm'].email) && v$['usageUploadForm'].email.$model
							"
							class="p-error"
						>
							{{ v$["usageUploadForm"].email.email?.$message }}
						</small>
					</div>
				</template>
			</div>
			<div v-show="currentStep === 3" class="step-wrapper p-2 p-lg-3">
				<div
					v-if="isSpinnerVisible('processing')"
					class="d-flex flex-column justify-content-center align-items-center text-center mx-auto"
				>
					<ProgressSpinner animation-duration="1.75s" stroke-width="4" />
					<h3>{{ $t("digitalAdvisor.dialog.processingTitle") }}</h3>
					<p>{{ $t("digitalAdvisor.dialog.processingText") }}</p>
				</div>
				<div
					v-else-if="hasPollingError"
					class="d-flex flex-column justify-content-center align-items-center text-center mx-auto"
				>
					<font-awesome-icon class="text-danger mb-2" :icon="['far', 'circle-xmark']" size="4x" />
					<h3 class="mb-0">{{ $t("digitalAdvisor.dialog.processingErrorTitle") }}</h3>
					<p>{{ $t("digitalAdvisor.dialog.processingErrorText") }}</p>
				</div>

				<div
					v-else-if="hasPollingFinished && !hasAdviceCalculationError"
					class="d-flex flex-column justify-content-center align-items-center text-center mx-auto"
				>
					<font-awesome-icon class="text-success mb-2" :icon="['far', 'check-circle']" size="4x" />
					<h3 class="mb-0">{{ $t("digitalAdvisor.dialog.processingSuccessTitle") }}</h3>
					<p>{{ $t("digitalAdvisor.dialog.processingSuccessText") }}</p>
				</div>

				<div
					v-else-if="hasPollingFinished && hasAdviceCalculationError"
					class="d-flex flex-column justify-content-center align-items-center text-center mx-auto"
				>
					<font-awesome-icon class="text-danger mb-2" :icon="['far', 'circle-xmark']" size="4x" />
					<h3 class="mb-0">{{ $t("digitalAdvisor.dialog.processingErrorTitle") }}</h3>
					<p>{{ $t("digitalAdvisor.dialog.processingErrorText") }}</p>
				</div>

				<Message v-else class="message-with-button w-100" :closable="false" severity="warn">
					<span>{{ $t("digitalAdvisor.dialog.msgPollProcessingTimeout") }}</span>
					<Button
						class="p-button-success p-button-sm m-1"
						:label="$t('common.tryAgain')"
						@click="() => resumePolling()"
					/>
				</Message>
			</div>
			<template v-if="canClose" #footer>
				<div class="d-flex align-items-center justify-content-end">
					<Button
						class="p-button-text p-button-plain"
						:label="$t('common.cancel')"
						@click="hideModal()"
					/>
					<Button
						v-if="!hasPollingError"
						class="p-button-success p-button-arrow p-button-sm"
						:disabled="!canSubmit"
						:label="$t('common.send')"
						@click="onSubmit()"
					/>
					<Button
						v-else
						class="p-button-primary p-button-arrow p-button-sm"
						:disabled="!canSubmit"
						:label="$t('common.tryAgain')"
						@click="onReset()"
					/>
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import FileUpload from "primevue/fileupload";
import ProgressSpinner from "primevue/progressspinner";
import useVuelidate, { Validation } from "@vuelidate/core";
import { AdvisorLogStatus } from "@/types/enum/advisorLogStatus";
import { Component, Emit, Prop, Ref } from "vue-facing-decorator";
import { Pausable, useIntervalFn } from "@vueuse/core";
import { PropType } from "vue";
import { email, required } from "@/types/utils/i18n-validators";
import { useAdvisorStore } from "@/store/advisor/advisorStore";
import { Log } from "@/types/helpers/logHelper";

export interface AdvisorUploadModalBlockViewModel {
	title: string;
	introText: string;
	emailIntroText: string;
	youtubeId: string;
}

@Component({
	components: { FileUpload, ProgressSpinner },
	options: {
		validations: {
			usageUploadForm: {
				email: { email, required },
			},
		},
	},
})
export default class AdvisorUploadUsageModal extends BaseComponent {
	@Prop({
		type: Object as PropType<AdvisorUploadModalBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: AdvisorUploadModalBlockViewModel;

	@Prop({ type: String, required: true, default: "" })
	resultsPageUrl!: string;

	@Prop({ type: String, required: true, default: "" })
	apiVersion!: string;

	@Ref()
	readonly fileUploader;

	usageUploadForm!: { email: string };
	currentStep = 1;
	isVisible = true;
	showVideo = false;
	submitted = false;
	uploadSuccess = false;

	//20MB
	sizeLimit = 20971520;
	selectedFile!: File;
	blobName!: string;

	fileProcessingPoll!: Pausable;
	pollStatus!: AdvisorLogStatus;
	pollCount = 0;
	pollIntervalMs = 10000;
	pollIntervalLimit = 5;

	processingLogId = "";
	adviceLogId = "";
	hasAdviceCalculationError = false;
	v$ = useVuelidate();
	advisorStore = useAdvisorStore();

	@Emit()
	hideModal(): boolean {
		this.advisorStore.hideModal("UploadUsage");
		return false;
	}

	@Emit()
	submitUsageReport(): void {
		const httpParams = new URLSearchParams();
		httpParams.set("processingLogId", this.processingLogId);
		httpParams.set("adviceLogId", this.adviceLogId);
		httpParams.set("apiVersion", this.apiVersion);
		this.setDataLayer.generateLead(this.layoutStore.vm?.currentUser, {
			form_title: "Advisor usage report",
			form_type: "advisor",
		});
		setTimeout(() => {
			this.loadingStore.increaseLoadingCount();
			this.hideModal();
			this.openUrl(`${this.resultsPageUrl}?` + httpParams);
		}, 2000);
	}

	created(): void {
		this.usageUploadForm = { email: "" };
	}

	get hasVideo(): boolean {
		return !!this.vm.youtubeId;
	}

	get canSubmit(): boolean {
		return (
			(!this.isPolling && this.currentStep === 2 && !this.submitted) ||
			(!this.v$["usageUploadForm"].$invalid && !!this.selectedFile)
		);
	}

	get canClose(): boolean {
		return !this.isPolling && !this.hasPollingFinished;
	}

	get hasPollingError(): boolean {
		return this.pollStatus === AdvisorLogStatus.Error;
	}

	get hasPollingFinished(): boolean {
		return this.pollStatus === AdvisorLogStatus.Finished;
	}

	get isPolling(): boolean {
		return (
			this.fileProcessingPoll?.isActive &&
			(this.pollStatus === AdvisorLogStatus.Queued || this.pollStatus === AdvisorLogStatus.Processing)
		);
	}

	formatSize(bytes: number): string {
		if (bytes === 0) {
			return "0 B";
		}

		let k = 1024,
			dm = 0,
			sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
			i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat(Math.floor(bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onPlayVideoClick(): void {
		this.showVideo = true;
	}

	onFileSelected({ files }: { files: File[] }): void {
		this.selectedFile = files[0];
		this.uploadFile();
	}

	onFileUpload(): void {
		this.fileUploader.$refs.fileInput.click();
	}

	onFileRemoved(): void {
		this.currentStep = 1;
		this.fileUploader.remove(0);
		this.selectedFile = undefined as never;
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.requestFileProcessing();
	}

	onReset(): void {
		this.submitted = false;
		this.onFileRemoved();
		this.currentStep = 1;
		this.pollStatus = AdvisorLogStatus.Unsupported;
	}

	pausePolling(): void {
		this.loadingStore.hideSpinner("processing");
		this.fileProcessingPoll?.pause();
	}

	resumePolling(): void {
		this.loadingStore.showSpinner("processing");
		this.fileProcessingPoll?.resume();
	}

	private uploadFile(): void {
		this.loadingStore.increaseLoadingCount();
		const formData = new FormData();
		formData.append("file", this.selectedFile);

		this.axios
			.post(`/api/advisor/upload-file`, formData, {
				requestId: "upload-file",
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				if (this.layoutStore.vm.isDebug) console.debug(res);
				this.uploadSuccess = res.data.success;
				this.currentStep = this.uploadSuccess ? 2 : 1;
				this.blobName = res.data.blobName;
			})
			.catch((err) => {
				if (this.layoutStore.vm.isDebug) console.error(err);
				this.uploadSuccess = false;
				this.currentStep = 1;
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
			});
	}

	private requestFileProcessing(): void {
		this.loadingStore.increaseLoadingCount();
		const payload = {
			userEmail: this.usageUploadForm.email,
			blobName: this.blobName || this.selectedFile?.name,
		};
		this.axios
			.post(`/api/advisor/request-processing`, payload, {
				requestId: "request-processing",
			})
			.then((res) => {
				this.currentStep = 3;
				this.processingLogId = res.data.processingLogId;
				this.pollFileProcessing();
			})
			.catch((err) => {
				Log.error(err);
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
			});
	}

	private pollFileProcessing(): void {
		this.loadingStore.showSpinner("processing");
		this.fileProcessingPoll = useIntervalFn(
			() => {
				this.advisorStore.pollInsightProcessing(this.processingLogId).then((x) => {
					this.pollStatus = x.uploadProcessingStatus;
					if (x.uploadProcessingStatus === AdvisorLogStatus.Finished) {
						this.requestAdviceCalculation();
						this.pausePolling();
					}
					if (x.uploadProcessingStatus === AdvisorLogStatus.Error) {
						this.pausePolling();
					}
				});
				this.pollCount++;
				if (this.pollCount % this.pollIntervalLimit === 0) {
					this.pausePolling();
				}
			},
			this.pollIntervalMs,
			{ immediate: true, immediateCallback: true }
		);
	}

	private requestAdviceCalculation(): void {
		this.loadingStore.increaseLoadingCount();
		const payload = {
			requesterEmail: this.usageUploadForm.email,
			processingLogId: this.processingLogId,
		};
		this.axios
			.post(`/api/advisor/request-advice-calculation`, payload, {
				requestId: "request-advice-calculation",
			})
			.then((res) => {
				this.adviceLogId = res.data.adviceLogId;
				this.submitUsageReport();
			})
			.catch((err) => {
				Log.error(err);
				this.hasAdviceCalculationError = true;
				// this.submitUsageReport();
			})
			.finally(() => {
				this.loadingStore.decreaseLoadingCount();
			});
	}
}
</script>

<style lang="scss">
.advisor-upload-modal {
	.p-dialog-header {
		border-bottom: none;
	}

	.p-dialog-content {
		padding: 0 1.5rem 1.5rem 1.5rem;
	}

	.p-dialog-header-icons {
		align-self: flex-start;
		padding-top: 0.5rem;
	}

	.p-fileupload-buttonbar {
		display: none;
	}

	.p-fileupload-content {
		background: var(--surface-b);
		border: 3px dotted var(--gray-400);
		border-radius: 6px;
	}

	.p-fileupload-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		min-height: 225px;

		i.pi {
			font-size: 1.5rem;
			background-color: var(--surface-a);
			color: var(--primary-color);
			padding: 1.5rem;
			border-radius: 50%;
		}

		p {
			font-size: 1rem;
			font-weight: 500;
		}
	}

	.step-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.video-container {
		border: 1px solid var(--primary-color);
		flex: 1 1 50%;
	}

	.upload-container {
		display: flex;
		flex-direction: column;
		flex: 0 1 35%;
		text-align: center;
		height: 100%;
	}
}
</style>
