import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f0c25fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid container-lg" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductPageHeader = _resolveComponent("ProductPageHeader")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProductNewVersionBanner = _resolveComponent("ProductNewVersionBanner")!
  const _component_ProductPageContentSection = _resolveComponent("ProductPageContentSection")!
  const _component_SupportCard = _resolveComponent("SupportCard")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["col px-0", { 'col-lg-8': _ctx.$slots.summary }])
        }, [
          _createVNode(_component_ProductPageHeader, {
            vm: _ctx.vm,
            showTeachingsMaterialButton: _ctx.showTeachingsMaterialButton
          }, null, 8, ["vm", "showTeachingsMaterialButton"]),
          (!_ctx.vm.availableInCurrentMarket)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                closable: false,
                severity: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.productUnavailableInMarket")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.vm.latestVersionOfProduct)
            ? (_openBlock(), _createBlock(_component_ProductNewVersionBanner, {
                key: 1,
                vm: _ctx.vm.latestVersionOfProduct
              }, null, 8, ["vm"]))
            : _createCommentVNode("", true),
          (_ctx.$slots.configuration)
            ? (_openBlock(), _createBlock(_component_ProductPageContentSection, { key: 2 }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "configuration", {}, undefined, true)
                ]),
                _: 3
              }))
            : (_ctx.$slots.tabs)
              ? _renderSlot(_ctx.$slots, "tabs", { key: 3 }, undefined, true)
              : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2),
        (_ctx.$slots.summary)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["col-lg-4", { 'sticky-bottom-panel': _ctx.isSmallScreen }])
            }, [
              _renderSlot(_ctx.$slots, "summary", {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Sidebar, {
      visible: _ctx.showSideBar,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSideBar) = $event)),
      class: "p-sidebar-md p-sidebar-primary",
      position: "left"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpBlock?.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            (item.title)
              ? (_openBlock(), _createElementBlock("h3", {
                  key: 0,
                  innerHTML: item.title
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              innerHTML: item.text
            }, null, 8, _hoisted_4)
          ]))
        }), 128)),
        _createVNode(_component_SupportCard)
      ]),
      _: 1
    }, 8, ["visible"]),
    (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent('script'), { type: "application/ld+json" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.jsonLinkedData), 1)
        ]),
        _: 1
      }))
    ]))
  ], 64))
}