import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd549d0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "d-flex justify-content-between px-3 pt-3" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "mb-0 mr-1"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "m-0 ml-1 text-bold text-primary" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "text-muted m-0 ml-1 text-preline" }
const _hoisted_13 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_14 = { class: "text-lg-right" }
const _hoisted_15 = {
  key: 0,
  class: "lead text-muted"
}
const _hoisted_16 = { class: "mr-1 text-primary" }
const _hoisted_17 = {
  key: 1,
  class: "mt-1"
}
const _hoisted_18 = { class: "mb-0" }
const _hoisted_19 = { class: "row h-100" }
const _hoisted_20 = { class: "col-12 mt-2 mt-lg-0 px-3 d-flex justify-content-start flex-row" }
const _hoisted_21 = { class: "d-flex flex-column h-100" }
const _hoisted_22 = { class: "d-flex flex-wrap" }
const _hoisted_23 = { class: "d-flex flex-column flex-1 ml-2" }
const _hoisted_24 = { class: "d-flex align-items-center justify-content-between flex-wrap-reverse" }
const _hoisted_25 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "text-muted m-0 ml-1 text-preline" }
const _hoisted_30 = { class: "d-flex align-items-center" }
const _hoisted_31 = ["title"]
const _hoisted_32 = {
  key: 0,
  class: "m-0 ml-1 text-bold text-primary"
}
const _hoisted_33 = { class: "m-0 ml-1 text-bold text-primary" }
const _hoisted_34 = { class: "mr-1 text-primary" }
const _hoisted_35 = {
  key: 1,
  class: "mt-1"
}
const _hoisted_36 = { class: "text-right mb-0" }
const _hoisted_37 = {
  key: 2,
  class: "mt-1"
}
const _hoisted_38 = { class: "" }
const _hoisted_39 = { class: "profile-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_PopoverIcon = _resolveComponent("PopoverIcon")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Inplace = _resolveComponent("Inplace")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("a", {
    class: "expert-card-item",
    href: _ctx.item.productPageUrl,
    style: {"text-decoration":"none"}
  }, [
    (!_ctx.isGridLayout)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          class: "expert-list-card w-100"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Avatar, {
                  class: "mr-2 profile-image",
                  image: _ctx.item?.expertProfile?.imageUrl,
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.item?.expertProfile?.firstName)
                      ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.item?.expertProfile?.firstName), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("i", {
                      class: "pi pi-map-marker text-primary",
                      title: _ctx.$t('cart.table.iconLocation_title')
                    }, null, 8, _hoisted_7),
                    _createVNode(_component_PopoverIcon, {
                      "open-delay": 500,
                      placement: "right"
                    }, {
                      content: _withCtx(() => []),
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(`${_ctx.item.expertProfile?.region}, ${_ctx.item?.expertProfile?.country}`), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_PopoverIcon, {
                      "open-delay": 500,
                      placement: "right"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("p", {
                          class: "text-bold",
                          innerHTML: _ctx.item?.expertLevel.displayName
                        }, null, 8, _hoisted_10),
                        _createElementVNode("div", {
                          innerHTML: _ctx.item?.expertLevel.description
                        }, null, 8, _hoisted_11)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_Avatar, {
                          alt: _ctx.item?.expertLevel?.displayName,
                          class: "expert-level",
                          image: _ctx.item?.expertLevel?.iconUrl,
                          shape: "square"
                        }, null, 8, ["alt", "image"])
                      ]),
                      _: 1
                    }),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.item.expertProfile?.function), 1)
                  ]),
                  (_ctx.item.numberOfDaysAvailable || _ctx.item.numberOfDaysOnRequest)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        (_ctx.item.numberOfDaysAvailable > 0)
                          ? (_openBlock(), _createBlock(_component_Chip, {
                              key: 0,
                              class: "p-chip-secondary mr-1 mb-1",
                              label: _ctx.$tc('expert.daysAvailable', _ctx.item.numberOfDaysAvailable)
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.item.numberOfDaysOnRequest > 0)
                          ? (_openBlock(), _createBlock(_component_Chip, {
                              key: 1,
                              class: "p-chip-primary",
                              label: _ctx.$t('expert.availableOnRequest')
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                (_ctx.item.hourlyRate)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                      _createVNode(_component_i18n_t, { keypath: "common.pricePerHour" }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", _hoisted_16, _toDisplayString(_ctx.formatCurrency(_ctx.item.hourlyRate)), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.item.expertProfile?.reviewScoreAverage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_component_Rating, {
                        modelValue: _ctx.item.expertProfile.reviewScoreAverage,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.expertProfile.reviewScoreAverage) = $event)),
                        cancel: false,
                        class: "rating-lg",
                        readonly: true
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("small", _hoisted_18, " (" + _toDisplayString(_ctx.$tc("expert.reviewCount", _ctx.item.expertProfile?.reviews?.length)) + ") ", 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_Inplace, {
                  onOpen: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"]))
                }, {
                  display: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVisibleExpertises(
										_ctx.item.expertProfile?.expertises
									), (expertise, index) => {
                      return (_openBlock(), _createBlock(_component_Chip, {
                        key: index,
                        class: "p-chip-secondary mr-1 mb-1",
                        label: expertise.name
                      }, null, 8, ["label"]))
                    }), 128)),
                    (_ctx.item.expertProfile?.expertises?.length > _ctx.maxExpertisesToShow)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: "p-button-text p-button-rounded p-button-secondary mb-1",
                          label: _ctx.$t('expert.button.showAllExpertises')
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  content: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.expertProfile?.expertises, (expertise, index) => {
                      return (_openBlock(), _createBlock(_component_Chip, {
                        key: index,
                        class: "p-chip-secondary mr-1 mb-1",
                        label: expertise.name
                      }, null, 8, ["label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          class: _normalizeClass(["expert-grid-card", { 'cross-sell': _ctx.isCrossSell }])
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_Avatar, {
                  class: "profile-image",
                  image: _ctx.item?.expertProfile?.imageUrl,
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    (_ctx.item?.expertProfile?.firstName)
                      ? (_openBlock(), _createElementBlock("h3", _hoisted_25, _toDisplayString(_ctx.item?.expertProfile?.firstName), 1))
                      : _createCommentVNode("", true),
                    (_ctx.isCrossSell)
                      ? (_openBlock(), _createBlock(_component_Tag, {
                          key: 1,
                          class: "mb-auto ml-auto",
                          value: _ctx.$t('enum.itemProductType.' + _ctx.item.productType)
                        }, null, 8, ["value"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_PopoverIcon, {
                      "open-delay": 500,
                      placement: "right"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("p", {
                          class: "text-bold",
                          innerHTML: _ctx.item?.expertLevel?.displayName
                        }, null, 8, _hoisted_27),
                        _createElementVNode("div", {
                          innerHTML: _ctx.item?.expertLevel?.description
                        }, null, 8, _hoisted_28)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_Avatar, {
                          alt: _ctx.item?.expertLevel?.displayName,
                          class: "expert-level",
                          image: _ctx.item?.expertLevel?.iconUrl,
                          shape: "square"
                        }, null, 8, ["alt", "image"])
                      ]),
                      _: 1
                    }),
                    _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.item?.expertProfile?.function), 1)
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("i", {
                      class: "pi pi-map-marker text-primary",
                      title: _ctx.$t('cart.table.iconLocation_title')
                    }, null, 8, _hoisted_31),
                    (_ctx.isCrossSell)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(`${_ctx.item.expertProfile?.region}, ${_ctx.item?.expertProfile?.country}`), 1))
                      : (_openBlock(), _createBlock(_component_PopoverIcon, {
                          key: 1,
                          "open-delay": 500,
                          placement: "right"
                        }, {
                          content: _withCtx(() => [
                            _createVNode(_component_GMapMap, {
                              center: {
												lat: _ctx.item.expertProfile?.latitude,
												lng: _ctx.item.expertProfile?.longitude,
											},
                              options: {
												zoomControl: true,
												mapTypeControl: false,
												scaleControl: false,
												streetViewControl: false,
												rotateControl: false,
												fullscreenControl: false,
											},
                              style: {"width":"300px","height":"300px"},
                              zoom: 7
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_GMapMarker, {
                                  clickable: false,
                                  draggable: false,
                                  position: {
													lat: _ctx.item.expertProfile?.latitude,
													lng: _ctx.item.expertProfile?.longitude,
												}
                                }, null, 8, ["position"])
                              ]),
                              _: 1
                            }, 8, ["center"])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_33, _toDisplayString(`${_ctx.item.expertProfile?.region}, ${_ctx.item?.expertProfile?.country}`), 1)
                          ]),
                          _: 1
                        }))
                  ])
                ])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.isCrossSell ? 'mt-auto' : 'mt-2')
              }, [
                (_ctx.item.hourlyRate)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: _normalizeClass(["lead text-muted mb-0", { 'text-right': _ctx.isCrossSell }])
                    }, [
                      _createVNode(_component_i18n_t, { keypath: "common.pricePerHour" }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", _hoisted_34, _toDisplayString(_ctx.formatCurrency(_ctx.item.hourlyRate)), 1)
                        ]),
                        _: 1
                      })
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.item.expertProfile?.reviewScoreAverage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      _createVNode(_component_Rating, {
                        modelValue: _ctx.item.expertProfile.reviewScoreAverage,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.expertProfile.reviewScoreAverage) = $event)),
                        cancel: false,
                        readonly: true
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("small", _hoisted_36, " (" + _toDisplayString(_ctx.$tc("expert.reviewCount", _ctx.item.expertProfile?.reviews.length)) + ") ", 1)
                    ]))
                  : _createCommentVNode("", true),
                ((_ctx.item.numberOfDaysAvailable || _ctx.item.numberOfDaysOnRequest) && !_ctx.isCrossSell)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      (_ctx.item.numberOfDaysAvailable > 0)
                        ? (_openBlock(), _createBlock(_component_Chip, {
                            key: 0,
                            class: "p-chip-secondary mr-1 mb-1",
                            label: _ctx.$tc('expert.daysAvailable', _ctx.item.numberOfDaysAvailable)
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true),
                      (_ctx.item.numberOfDaysOnRequest > 0)
                        ? (_openBlock(), _createBlock(_component_Chip, {
                            key: 1,
                            class: "p-chip-primary",
                            label: _ctx.$t('expert.availableOnRequest')
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_38, [
              _createVNode(_component_Divider, { class: "m-0" }),
              _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.item?.expertProfile?.shortDescription), 1),
              _createVNode(_component_Inplace, {
                onOpen: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop","prevent"]))
              }, {
                display: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVisibleExpertises(_ctx.item.expertProfile?.expertises), (expertise, index) => {
                    return (_openBlock(), _createBlock(_component_Chip, {
                      key: index,
                      class: "p-chip-secondary mr-1 mb-1",
                      label: expertise.name
                    }, null, 8, ["label"]))
                  }), 128)),
                  (_ctx.item.expertProfile.expertises?.length > _ctx.maxExpertisesToShow)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "p-button-text p-button-rounded p-button-secondary mb-1",
                        label: _ctx.$t('expert.button.showAllExpertises')
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                content: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.expertProfile?.expertises, (expertise, index) => {
                    return (_openBlock(), _createBlock(_component_Chip, {
                      key: index,
                      class: "p-chip-secondary mr-1 mb-1",
                      label: expertise.name
                    }, null, 8, ["label"]))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["class"]))
  ], 8, _hoisted_1))
}