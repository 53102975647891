<template>
	<div
		:id="vm.subTitle?.replaceAll(' ', '_').toLowerCase()"
		class="block"
		:class="blockBackgroundClass(vm.backgroundColor)"
	>
		<div class="container">
			<div class="row gap-2">
				<div v-if="hasVideo" class="col-12" :class="vm.imageSize === size.large ? 'col-lg-6' : 'col-lg-4'">
					<iframe
						v-if="showVideo"
						allowfullscreen
						height="100%"
						:src="`https://www.youtube.com/embed/${vm.youTubeId}?autoplay=1`"
						style="min-height: 400px"
						type="video/mp4"
						width="100%"
					/>
					<div v-else class="video-overlay">
						<button class="play-button" @click="onPlayVideoClick">
							<svg>
								<use xlink:href="#icon-play"></use>
							</svg>
						</button>
						<img
							v-lazy="`https://img.youtube.com/vi/${vm.youTubeId}/maxresdefault.jpg`"
							alt="Youtube thumbnail"
							class="video-image"
						/>
					</div>
				</div>

				<div
					v-else-if="hasImage"
					class="col-12 media-img"
					:class="vm.imageSize === size.large ? 'col-lg-6' : 'col-lg-4'"
				>
					<img v-lazy="vm.imageUrl" :alt="vm.imageAlt" />
				</div>
				<div class="col-lg mb-1" :class="{ 'order-lg-first': vm.imagePosition !== imagePosition.left }">
					<template v-if="vm.subTitle">
						<p class="lead mb-0" v-html="vm.subTitle" />
						<h2 class="" v-html="vm.title" />
					</template>
					<template v-else>
						<h2 class="" v-html="vm.title" />
					</template>
					<div class="rte" v-html="vm.text" />
					<LinkButton
						v-if="vm.ctaLinkItem"
						class="mt-2"
						:class="ctaButtonClass(vm.ctaLinkStyle) || 'p-button-raised p-button-text p-button-arrow'"
						:label="vm.ctaLinkItem.text"
						:target="vm.ctaLinkItem.target"
						:title="vm.ctaLinkItem.title"
						:url="vm.ctaLinkItem.url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import { ImagePosition } from "@/types/enum/imagePosition";
import { PropType } from "vue";
import { Size } from "@/types/enum/size";
import { TextAndMediaBlockViewModel } from "@/types/viewModels/blocks/textAndMediaBlockViewModel";
import BaseBlock from "@/components/base/baseBlock.vue";

@Component
export default class TextAndMediaBlock extends BaseBlock {
	@Prop({ type: Object as PropType<TextAndMediaBlockViewModel>, required: true, default: {} })
	vm!: TextAndMediaBlockViewModel;

	showVideo = false;

	get imagePosition(): typeof ImagePosition {
		return ImagePosition;
	}

	get size(): typeof Size {
		return Size;
	}

	get hasVideo(): boolean {
		return !!this.vm.youTubeId;
	}

	get hasImage(): boolean {
		return !!this.vm.imageUrl;
	}

	onPlayVideoClick(): void {
		this.showVideo = true;
	}
}
</script>

<style lang="scss" scoped>
.media-img img {
	object-fit: cover;
	object-position: 50% 50%;
	width: 100%;
	height: 100%;
}
.video-overlay {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	height: 100%;

	&::before {
		content: "";
		top: 0;
		left: 0;
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.3s ease-out 0s;
		background: black;
	}

	&:focus-within {
		.play-button {
			outline: none;
			box-shadow: 0 0 0 2px var(--primary-color);
		}
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		transition: transform 0.3s ease-out 0s;
		transform-origin: center;
	}

	.play-button {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		position: absolute;
		z-index: 2;
		margin: auto;
		transition: transform 0.3s ease-out 0.1s;
		transform-origin: center;
	}

	&:hover,
	&:active,
	&:focus-within {
		.play-button {
			transform: scale(1.1);
		}

		&::before {
			opacity: 0.25;
		}

		.img {
			transform: scale(1.05);
		}
	}
}
</style>
