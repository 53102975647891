<template>
	<BackButton :label="$t('myCadac.contractDetail.button.back')" />

	<h1 class="mb-0">
		{{ vm.editing ? $t("myCadac.contractDetail.titleChange") : $t("myCadac.contractDetail.titleView") }}
	</h1>
	<p class="text-muted lead">
		{{
			$t("myCadac.contractDetail.contractNumber", {
				contractNumber: vm.contract.contractId,
			})
		}}
	</p>

	<hr class="mt-1" />

	<ContractDetailTable :contracts="[vm.contract]" :vm="vm" />
	<ContractDetailChangeCard v-if="vm.contract.contractLines.length" :vm="vm" />
</template>

<script lang="ts">
import BackButton from "@/components/common/backButton.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import ContractDetailChangeCard from "@/components/contracts/contractDetailChangeCard.vue";
import ContractDetailTable from "@/components/contracts/contractDetailTable.vue";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IContractDetail } from "@/types/viewModels/myCadac/contractDetailViewModel";
import { PropType } from "vue";

@Component({ components: { BackButton, ContractDetailChangeCard, ContractDetailTable, MyCadacMenu } })
export default class ContractDetail extends BaseComponent {
	@Prop({ type: Object as PropType<IContractDetail>, required: true, default: {} })
	vm!: IContractDetail;
}
</script>

<style lang="scss" scoped></style>
