import { GaDataLayer } from '@/types/models/googleAnalytics/events';
import {
	AddPaymentInfo,
	AddToCart,
	BeginCheckout,
	GenerateLead,
	Purchase,
	RemoveFromCart,
	ViewCart,
	ViewItem,
} from '@/types/models/googleAnalytics/events/eCommerce/eCommerceModel';
import {
	AccountVerified,
	CustomizeProduct,
	ErrorEvent,
	LoginEvent,
	NewsletterSubscribe,
	Search,
	UploadQuotation,
} from '@/types/models/googleAnalytics/events/custom/customModel';

export const pushToGaDataLayer = {
	viewItem: (obj: ViewItem): void => setDataLayer(obj),
	addToCart: (obj: AddToCart): void => setDataLayer(obj),
	removeFromCart: (obj: RemoveFromCart): void => setDataLayer(obj),
	viewCart: (obj: ViewCart): void => setDataLayer(obj),
	beginCheckout: (obj: BeginCheckout): void => setDataLayer(obj),
	addShippingInfo: (obj: AddToCart): void => setDataLayer(obj),
	addPaymentInfo: (obj: AddPaymentInfo): void => setDataLayer(obj),
	purchase: (obj: Purchase): void => setDataLayer(obj),
	generateLead: (obj: GenerateLead): void => setDataLayer(obj),
	loginEvent: (obj: LoginEvent): void => setDataLayer(obj),
	newsLetterSubscribe: (obj: NewsletterSubscribe): void => setDataLayer(obj),
	accountVerified: (obj: AccountVerified): void => setDataLayer(obj),
	uploadQuotation: (obj: UploadQuotation): void => setDataLayer(obj),
	errorEvent: (obj: ErrorEvent): void => setDataLayer(obj),
	search: (obj: Search): void => setDataLayer(obj),
	customizeProduct: (obj: CustomizeProduct): void => setDataLayer(obj),
};

const setDataLayer = (obj: GaDataLayer) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(obj);
};
