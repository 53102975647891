<template>
	<div class="container">
		<div class="row mt-3 pb-3">
			<div class="col-lg-8">
				<div v-if="!vm.error">
					<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>
					<p class="lead" v-html="vm.pageIntro" />
				</div>
				<!-- Checkout Success -->
				<div v-if="!vm.error" class="mt-3">
					<h3 v-if="vm.stepsTitle">{{ vm.stepsTitle }}</h3>
					<hr />
					<Timeline class="mb-3" :value="timeLineSteps">
						<template #marker="slotProps">
							<svg class="" height="48" width="48">
								<use :xlink:href="slotProps.item.icon" />
							</svg>
						</template>
						<template #content="slotProps">
							<h5>
								{{ slotProps.item.title }}
							</h5>
							<p v-html="slotProps.item.content"></p>
						</template>
					</Timeline>
					<div v-if="vm.hasSoftware" class="mb-2 text-break" v-html="vm.softwareInstructions"></div>
					<div v-if="vm.hasTrainings" class="mb-2 text-break" v-html="vm.trainingInstructions"></div>
					<div v-if="vm.hasExperts" class="mb-2 text-break" v-html="vm.expertInstructions"></div>
					<div v-if="vm.hasSupport" class="mb-2 text-break" v-html="vm.supportInstructions"></div>
					<div
						v-if="vm.hasServicePasses"
						class="mb-2 text-break"
						v-html="vm.servicePassInstructions"
					></div>
					<Button
						class="p-button-success p-button-lg p-button-raised mb-2"
						icon="pi pi-arrow-right"
						icon-pos="right"
						:label="$t('checkout.button.goToMyCadac')"
						@click="
							goToMyCadac();
							$event.target.disabled = true;
						"
					/>
				</div>
				<!-- Checkout Failed -->
				<div v-else class="mt-3">
					<h1>{{ $t("checkout.titleOrderFailed") }}</h1>
					<Message :closable="false" severity="error">
						<i18n-t keypath="checkout.message.orderFailed">
							<b v-if="vm.orderNumber">#{{ vm.orderNumber }}</b>
						</i18n-t>
					</Message>
					<!-- <Button
						class="p-button-success p-button-lg p-button-raised mb-2"
						icon="pi pi-arrow-right"
						icon-pos="right"
						:label="$t('checkout.button.goToSoftwareOverview')"
						@click="
							goToSoftwareOverview();
							$event.target.disabled = true;
						"
					/> -->
				</div>
			</div>
			<div class="col-lg-4">
				<SupportCard :support-page-url="vm.supportPageUrl" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CheckoutSteps, { CheckoutStep } from "@/components/commerce/checkout/checkoutSteps.vue";
import SupportCard from "@/components/commerce/supportCard.vue";
import Timeline from "primevue/timeline";
import { Component, Prop } from "vue-facing-decorator";
import { ICheckoutComplete } from "@/types/viewModels/commerce/checkoutCompleteViewModel";
import { PropType } from "vue";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";

export interface CheckoutTimelineStep {
	icon: string;
	title: string;
	content: string;
}

@Component({ components: { SupportCard, CheckoutSteps, Timeline } })
export default class CheckoutComplete extends BaseComponent {
	@Prop({ type: Object as PropType<ICheckoutComplete>, required: true, default: {} }) vm!: ICheckoutComplete;

	timeLineSteps: CheckoutTimelineStep[] = [];
	checkoutStore = useCheckoutStore();

	created(): void {
		this.timeLineSteps = [
			{
				icon: `#checkout-step-1`,
				title: this.$t("common.step", [1]),
				content: this.vm.step1,
			},
			{
				icon: `#checkout-step-2`,
				title: this.$t("common.step", [2]),
				content: this.vm.step2,
			},
			{
				icon: `#checkout-step-3`,
				title: this.$t("common.step", [3]),
				content: this.vm.step3,
			},
		];

		this.checkoutStore.activeStep = CheckoutStep.Confirmation;

        const datalayer = sessionStorage.getItem("purchase-datalayer");
        const persistedDataLayer = datalayer ? JSON.parse(datalayer) : null;

		if (!persistedDataLayer) return;

		sessionStorage.removeItem("purchase-datalayer");

		if (this.vm.orderImpression) {
			const orderDataDataLayer = {
				...persistedDataLayer.orderDataDataLayer,
				transaction_id: this.vm.orderNumber,
			};

			this.setDataLayer.purchase(persistedDataLayer.eCommerceDataLayer, orderDataDataLayer);
		}
	}

	goToMyCadac(): void {
		this.openUrl(this.vm.myCadacPageUrl);
	}

	goToSoftwareOverview(): void {
		this.openUrl(this.vm.softwareOverviewPageUrl);
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-timeline-event) {
	min-height: 100px;

	.p-timeline-event-opposite {
		display: none;
	}
}
</style>
