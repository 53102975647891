import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-547a1915"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "d-flex flex-column form-group" }
const _hoisted_6 = { for: "remoteAllowed" }
const _hoisted_7 = { class: "d-flex flex-column form-group" }
const _hoisted_8 = { for: "availableAbroad" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "map-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ColumnYesNo = _resolveComponent("ColumnYesNo")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_GMapAutocomplete = _resolveComponent("GMapAutocomplete")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapCircle = _resolveComponent("GMapCircle")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.pageTitle)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.vm.pageTitle), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Panel, { class: "mb-2" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.vm.introTitle)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "lead mb-0 font-weight-bold",
                innerHTML: _ctx.vm.introTitle
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.vm.introText)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: "lead",
                innerHTML: _ctx.vm.introText
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.isEditingTravelSettings)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("myCadac.expertTravelSettings.lblRemoteAllowed")), 1),
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.isRemoteAllowed,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isRemoteAllowed) = $event)),
                  id: "remoteAllowed"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("myCadac.expertTravelSettings.lblAvailableAbroad")), 1),
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.isAvailableAbroad,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isAvailableAbroad) = $event)),
                  id: "availableAbroad"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_Button, {
                  class: "p-button-text p-button-primary mr-1",
                  label: _ctx.$t('common.cancel'),
                  onClick: _withModifiers(_ctx.onCancelEditing, ["stop"])
                }, null, 8, ["label", "onClick"]),
                _createVNode(_component_Button, {
                  class: "",
                  label: _ctx.$t(`common.save`),
                  onClick: _ctx.onSubmit
                }, null, 8, ["label", "onClick"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("dl", null, [
                _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.expertTravelSettings.lblRemoteAllowed")), 1),
                _createElementVNode("dd", null, [
                  _createVNode(_component_ColumnYesNo, { value: _ctx.isRemoteAllowed }, null, 8, ["value"])
                ]),
                _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.expertTravelSettings.lblAvailableAbroad")), 1),
                _createElementVNode("dd", null, [
                  _createVNode(_component_ColumnYesNo, { value: _ctx.isAvailableAbroad }, null, 8, ["value"])
                ])
              ]),
              _createVNode(_component_Button, {
                class: "p-button-text px-0",
                icon: "pi pi-pencil",
                label: _ctx.$t('common.change'),
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isEditingTravelSettings = true), ["stop"]))
              }, null, 8, ["label"])
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "mb-2" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.vm.mapDescription)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "lead",
                innerHTML: _ctx.vm.mapDescription
              }, null, 8, _hoisted_10))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_GMapAutocomplete, {
            class: "w-100",
            placeholder: _ctx.$t(`myCadac.expertTravelSettings.searchLocation_placeholder`),
            onChange: _ctx.onLocationSelected,
            onPlace_changed: _ctx.onPlaceChanged
          }, null, 8, ["placeholder", "onChange", "onPlace_changed"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_GMapMap, {
            center: {
					lat: _ctx.vm?.expertProfile?.latitude,
					lng: _ctx.vm?.expertProfile?.longitude,
				},
            options: {
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
				},
            zoom: 5
          }, {
            default: _withCtx(() => [
              (_ctx.workLocation)
                ? (_openBlock(), _createBlock(_component_GMapMarker, {
                    key: 0,
                    clickable: false,
                    draggable: false,
                    position: {
						lat: _ctx.workLocation?.latitude,
						lng: _ctx.workLocation?.longitude,
					}
                  }, null, 8, ["position"]))
                : _createCommentVNode("", true),
              _createVNode(_component_GMapCircle, {
                key: "minPlaneCompensationDistance",
                center: {
						lat: _ctx.vm?.expertProfile?.latitude,
						lng: _ctx.vm?.expertProfile?.longitude,
					},
                options: {
						radius: _ctx.vm.minPlaneCompensationDistance * 1000,
						strokeColor: '#91004b',
						strokeOpacity: 0.25,
						strokeWeight: 1,
						fillColor: '#91004b',
						fillOpacity: 0.15,
					}
              }, null, 8, ["center", "options"]),
              _createVNode(_component_GMapCircle, {
                key: "minCompensationDistance",
                center: {
						lat: _ctx.vm?.expertProfile?.latitude,
						lng: _ctx.vm?.expertProfile?.longitude,
					},
                options: {
						radius: _ctx.vm.minCompensationDistance * 1000,
						strokeColor: '#91004b',
						strokeOpacity: 0.5,
						strokeWeight: 1,
						fillColor: '#91004b',
						fillOpacity: 0.3,
					}
              }, null, 8, ["center", "options"])
            ]),
            _: 1
          }, 8, ["center"])
        ])
      ]),
      _: 1
    })
  ], 64))
}