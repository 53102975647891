import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ca83942"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row hidden-print" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_6 = { class: "text-primary text-bold" }
const _hoisted_7 = ["href", "target", "onClick"]
const _hoisted_8 = { class: "p-menuitem-text" }
const _hoisted_9 = { class: "d-flex flex-nowrap flex-grow-1 justify-content-between justify-content-lg-end" }
const _hoisted_10 = { class: "row-expansion-content" }
const _hoisted_11 = {
  key: 0,
  class: "visible-print"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CartOverview = _resolveComponent("CartOverview")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CartPrintPreview = _resolveComponent("CartPrintPreview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.vm.pageTitle)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.vm.pageTitle), 1))
          : _createCommentVNode("", true),
        (!_ctx.vm.shoppingCarts.length)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              closable: false,
              severity: "warn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("myCadac.cartOverview.noCarts")), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_DataTable, {
              key: 2,
              "expanded-rows": _ctx.expandedRows,
              "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedRows) = $event)),
              "auto-layout": !_ctx.isSmallScreen,
              class: "mb-3",
              "responsive-layout": "stack",
              "row-class": _ctx.highlightRow,
              "row-hover": true,
              value: _ctx.vm.shoppingCarts,
              onRowClick: _withModifiers(_ctx.onRowClick, ["self"])
            }, {
              expansion: _withCtx((item) => [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_CartOverview, {
                    "cart-client": item.data.cartClient,
                    "is-editable": false
                  }, null, 8, ["cart-client"])
                ])
              ]),
              default: _withCtx(() => [
                (!_ctx.isSmallScreen)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: 0,
                      expander: "",
                      "header-style": "width:1.75rem"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.userProfile?.isImpersonated || _ctx.userProfile?.isCadacSales)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: "sales",
                      header: _ctx.$t('myCadac.cartOverview.table.colSalesOpportunity')
                    }, {
                      body: _withCtx((item) => [
                        (_ctx.userProfile?.isImpersonatedByCadacSales && item.data.opportunityUrl)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "text-bold",
                              href: item.data.opportunityUrl,
                              target: "_blank",
                              title: _ctx.$t('common.goToCrm')
                            }, _toDisplayString(item.data.opportunityName), 9, _hoisted_4))
                          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(item.data.opportunityName || item.data.title), 1)),
                        _createElementVNode("small", null, _toDisplayString(item.data.responsibleSales), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_Column, {
                  key: "title",
                  field: "title",
                  header: _ctx.$t('myCadac.cartOverview.table.colCartTitle'),
                  sortable: true
                }, null, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "created",
                  field: "created",
                  header: _ctx.$t('myCadac.cartOverview.table.colCreatedDate'),
                  sortable: true
                }, {
                  body: _withCtx((item) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(item.data.created)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "createdBy",
                  field: "createdBy",
                  header: _ctx.$t('myCadac.cartOverview.table.colCreatedBy'),
                  sortable: true
                }, null, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "nrOfProducts",
                  field: "nrOfProducts",
                  header: _ctx.$t('myCadac.cartOverview.table.colNrOfProducts'),
                  sortable: true
                }, null, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "total",
                  field: "totalPrice",
                  header: _ctx.$t('myCadac.cartOverview.table.colTotal'),
                  sortable: true
                }, {
                  body: _withCtx((item) => [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatCurrency(item.data.totalPrice || 0)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]),
                _createVNode(_component_Column, null, {
                  body: _withCtx((item) => [
                    _createVNode(_component_Menu, {
                      ref: `menu${item.index}`,
                      class: "hidden-print",
                      model: _ctx.optionsMenu[item.index],
                      popup: true
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          class: "p-menuitem-link",
                          href: item.url,
                          role: "menuitem",
                          target: item.target,
                          onClick: item.command
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass(['p-menuitem-icon', item.icon])
                          }, null, 2),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(item.label), 1)
                        ], 8, _hoisted_7)
                      ]),
                      _: 2
                    }, 1032, ["model"]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_Button, {
                        class: "p-button-success mr-2",
                        disabled: _ctx.isSpinnerVisible(`btnCheckoutCart_${item.data.cartName}`),
                        icon: "pi pi-shopping-cart",
                        label: _ctx.isSmallScreen ? _ctx.$t('common.order') : '',
                        loading: _ctx.isSpinnerVisible(`btnCheckoutCart_${item.data.cartName}`),
                        title: _ctx.$t('common.order'),
                        onClick: ($event: any) => (_ctx.checkoutCart(item.data))
                      }, null, 8, ["disabled", "label", "loading", "title", "onClick"]),
                      _createVNode(_component_Button, {
                        class: "p-button-primary p-button-raised p-button-text p-button-sm surface-a",
                        icon: "pi pi-ellipsis-v",
                        onClick: ($event: any) => (_ctx.toggleMenu($event, item.index))
                      }, null, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["expanded-rows", "auto-layout", "row-class", "value", "onRowClick"]))
      ])
    ]),
    (_ctx.cartToPrint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_CartPrintPreview, {
            "cart-client": _ctx.cartToPrint?.cartClient,
            title: _ctx.cartToPrint?.title
          }, null, 8, ["cart-client", "title"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}