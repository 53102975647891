export enum ItemProductType {
	Unknown = 0,
	Other = 1,
	Software = 2,
	Collection = 3,
	Training = 4,
	Expert = 5,
	Service = 6,
	Support = 7,
}
