import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "lead mb-2" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-12" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    closable: true,
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCancel()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.isCancelType ? _ctx.$t("expert.cancelModal.header") : _ctx.$t("expert.declineModal.header")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.$t('common.confirm'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, [
        (_ctx.isCancelType)
          ? (_openBlock(), _createBlock(_component_i18n_t, {
              key: 0,
              keypath: "expert.cancelModal.intro"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: 'tel:' + _ctx.$t('common.support.phoneNumber')
                }, _toDisplayString(_ctx.$t("common.support.phoneNumber")), 9, _hoisted_3)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_i18n_t, {
              key: 1,
              keypath: "expert.declineModal.intro"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: 'tel:' + _ctx.$t('common.support.phoneNumber')
                }, _toDisplayString(_ctx.$t("common.support.phoneNumber")), 9, _hoisted_4)
              ]),
              _: 1
            }))
      ]),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['expertDeclineBookingForm'].message?.required })
            }, _toDisplayString(_ctx.isCancelType
								? _ctx.$t("expert.cancelModal.lblDescription")
								: _ctx.$t("expert.declineModal.lblDescription")), 3),
            _createVNode(_component_Textarea, {
              modelValue: _ctx.v$['expertDeclineBookingForm'].message.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['expertDeclineBookingForm'].message.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['expertDeclineBookingForm'].message) }),
              rows: "8",
              type: "text"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.shouldShowError(_ctx.v$['expertDeclineBookingForm'].message))
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  (!_ctx.v$['expertDeclineBookingForm'].message.$model)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$["expertDeclineBookingForm"].message?.required?.$message), 1))
                    : (_ctx.v$['expertDeclineBookingForm'].message.$model)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.v$["expertDeclineBookingForm"].message.minLength?.$message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}