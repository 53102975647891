<template>
	<Card>
		<template #title>
			<div class="d-flex justify-content-between">
				<h4 class="mb-0">
					{{
						vm.editing
							? $t("myCadac.contractDetail.card.titleChange")
							: $t("myCadac.contractDetail.card.titleView")
					}}
				</h4>
				<Button
					v-if="vm.supportProductUrl && vm.contract.isSupportContract"
					class="p-button-success"
					:label="$t('myCadac.contractDetail.button.addProduct')"
					@click="
						openUrl(vm.supportProductUrl);
						$event.target.disabled = true;
					"
				/>
			</div>
		</template>
		<template #content>
			<DataTable
				auto-layout
				data-key="serialNumber"
				:edit-mode="vm.editing ? 'cell' : ''"
				responsive-layout="stack"
				:value="editingContractLines"
				@cellEditComplete="onCellEditComplete"
			>
				<Column field="productName" :header="$t('myCadac.contractDetail.table.colProductName')">
					<template #body="item">
						<div class="d-flex align-items-center ml-1 ml-lg-0">
							<Avatar
								v-if="item.data.icon"
								:alt="item.data.iconAlt"
								class="mr-1 bg-transparent flex-shrink-0"
								:image="item.data.icon"
								shape="square"
								size="large"
								:title="item.productName"
							>
							</Avatar>
							<div class="d-flex flex-column">
								<small class="mb-0 text-muted d-block">
									{{ item.data.productBrand }}
								</small>
								<p
									v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
									class="mb-0 text-bold"
								>
									<a :href="item.data.crmUrl" target="_blank" :title="$t('common.goToCrm')">
										{{ formatProductName(item.data.productName, item.data.productBrand) }}
									</a>
								</p>
								<p v-else class="mb-0 text-bold">
									{{ formatProductName(item.data.productName, item.data.productBrand) }}
								</p>
							</div>
						</div>
					</template>
				</Column>
				<Column
					:class="{ 'text-right': isSupportContract }"
					field="serialNumber"
					:header="$t('myCadac.contractDetail.table.colProductSerialNumber')"
				/>
				<Column
					v-if="!isSupportContract"
					field="deployment"
					:header="$t('myCadac.contractDetail.table.colProductLicenceType')"
				>
					<template #body="item">
						{{ $t(`enum.deployment.${item.data.deployment}`) }}
					</template>
				</Column>
				<Column
					v-if="!isSupportContract"
					field="seats"
					:header="$t('myCadac.contractDetail.table.colProductSeats')"
				>
					<template #editor="item">
						<InputNumber
							v-if="vm.editing && item.data.canAddSeats"
							v-model="item.data.seats"
							v-debounce:300ms="onInputChanged"
							:disabled="showSpinner[item.index] || isLoading"
							:max="999"
							:min="item.data.seatsMinimal"
							show-buttons
						/>
						<span v-else>{{ item.data.seats }}</span>
					</template>
					<template #body="item">
						<InputNumber
							v-if="vm.editing && item.data.canAddSeats"
							:disabled="showSpinner[item.index] || isLoading"
							:model-value="item.data.seats"
							readonly
							show-buttons
						/>
						<span v-else>{{ item.data.seats }}</span>
					</template>
				</Column>
				<Column
					v-if="vm.editing && !isSupportContract && (isLoading || priceChanged)"
					class="text-right pr-2"
					:header="$t('myCadac.contractDetail.table.colProductPrice')"
				>
					<template #body="item">
						<div
							v-if="
								!showSpinner[item.index] &&
								item.data.canAddSeats &&
								item.data.price > 0 &&
								seatsChangedForItem(item.data)
							"
						>
							<small v-if="item.data.priceBase !== item.data.price" class="d-block text-muted">
								<s>{{ formatCurrency(item.data.priceBase) }}</s>
							</small>
							<small v-if="item.data.discount > 0" class="d-block text-success prefix-min">
								{{ formatCurrency(item.data.discount) }}
							</small>
							<b>{{ formatCurrency(item.data.price) }}</b>
						</div>
						<div v-else-if="showSpinner[item.index]">
							<i class="pi pi-spin pi-spinner" />
						</div>
					</template>
				</Column>
			</DataTable>
		</template>
		<template v-if="vm.editing && priceChanged" #footer>
			<div class="col-md-10 col-lg-8 col-xl-6 ml-auto pr-2">
				<PriceSummary :vm="priceSummary" />
				<Button
					class="p-button-success p-button-raised p-button-lg d-block ml-auto"
					:disabled="!canCheckout"
					:label="$t('myCadac.contractDetail.button.goToCheckout')"
					:loading="isLoading"
					@click="onCheckoutClick()"
				/>
			</div>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { ContractAddToCart } from "@/types/generated/Api/contractAddToCart";
import { ContractAddToCartLine } from "@/types/generated/Api/contractAddToCartLine";
import { ContractClient } from "@/types/generated/Api/contractClient";
import { ContractLineClient } from "@/types/generated/Api/contractLineClient";
import { IContractDetail } from "@/types/viewModels/myCadac/contractDetailViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { reduce } from "lodash";

@Component({ components: { PriceSummary } })
export default class ContractDetailChangeCard extends BaseComponent {
	@Prop({
		type: Object as PropType<IContractDetail>,
		required: true,
		default: {},
	})
	vm!: IContractDetail;

	showSpinner: { [number: string]: boolean } = {};
	isLoading = false;
	editingContractLines: ContractLineClient[] = [];
	contractId = "";

	@Watch("isLoading") onLoadingChanged(loading: boolean): void {
		if (!loading)
			for (let key in this.showSpinner) {
				this.showSpinner[key] = false;
			}
	}

	created(): void {
		this.editingContractLines = this.vm.contract.contractLines;
		this.contractId = this.vm.contract.contractId;
	}

	get isSupportContract(): boolean {
		return this.vm.contract.isSupportContract;
	}

	get contractUpdateData(): ContractAddToCart {
		return {
			contractId: this.contractId,
			contractLines: reduce(
				this.editingContractLines.filter((x) => x.canAddSeats),
				(result, value) => {
					result.push({
						checked: value.checked,
						addSeatVariationCode: value.addSeatVariationCode,
						renewVariationCode: value.renewVariationCode,
						serialNumber: value.serialNumber,
						seats: value.seats,
						switchDuration: 0,
						switchRenewVariationCode: "",
						switchSeats: 0,
					});
					return result;
				},
				[] as ContractAddToCartLine[]
			),
		};
	}

	get priceSummary(): IPriceSummary {
		const { vatPercentage } = this.vm.contract;
		return reduce(
			this.editingContractLines.filter((x) => x.canAddSeats),
			(result, contractLine) => {
				const vatAmount = contractLine.price * (vatPercentage / 100);
				const totalPrice = contractLine.price + vatAmount;
				result.vatAmount = result.vatAmount ? result.vatAmount + vatAmount : vatAmount;
				result.totalDiscount = result.totalDiscount
					? result.totalDiscount + contractLine.discount
					: contractLine.discount;
				result.totalPersonalPrice = result.totalPersonalPrice
					? result.totalPersonalPrice + contractLine.price
					: contractLine.price;
				result.totalBasePrice = result.totalBasePrice
					? result.totalBasePrice + contractLine.priceBase
					: contractLine.priceBase;
				result.totalPrice = result.totalPrice ? result.totalPrice + totalPrice : totalPrice;
				return result;
			},
			{ vatPercentage: vatPercentage } as IPriceSummary
		);
	}

	get priceChanged(): boolean {
		return this.editingContractLines.some(
			(x, index) => x.price !== this.vm.contract.contractLines[index].price && x.price > 0
		);
	}

	get seatsChanged(): boolean {
		return this.editingContractLines.some(
			(x, index) => x.seats !== this.vm.contract.contractLines[index].seatsOriginal
		);
	}

	get canCheckout(): boolean {
		return (
			!!(this.userProfile?.isEmailVerified || this.userProfile?.isImpersonated) &&
			this.priceChanged &&
			this.seatsChanged
		);
	}

	seatsChangedForItem(contractLineClient: ContractLineClient): boolean {
		return contractLineClient.seats !== contractLineClient.seatsOriginal;
	}

	onCheckoutClick(): void {
		this.addContractChangeToCart();
	}

	onCellEditComplete(event: { data: ContractLineClient; newData: ContractLineClient; index: number }): void {
		if (event.newData.seats === event.data.seats) return;
		this.editingContractLines[event.index].seats = event.newData.seats;
		this.showSpinner[event.index] = true;
		this.recalculatePrices();
	}

	onInputChanged(event: { value: number }, index: number): void {
		if (event.value === this.editingContractLines[index].seats) return;
		this.editingContractLines[index].seats = event.value;
		this.showSpinner[index] = true;
		this.recalculatePrices();
	}

	recalculatePrices(): void {
		if (this.isLoading) return;
		this.isLoading = true;
		const updateData: ContractAddToCart = this.contractUpdateData;
		this.axios
			.post("/api/contract/change/recalculate", updateData)
			.then(({ data }: { data: ContractClient }) => {
				this.editingContractLines = [...data.contractLines];
			})
			.catch((err) => Log.error(err))
			.finally(() => (this.isLoading = false));
	}

	addContractChangeToCart(): void {
		this.isLoading = true;
		const updateData = this.contractUpdateData;
		this.axios
			.post("/api/cart/add-assets", updateData)
			.then(() => {
				this.openUrl(this.vm.checkoutPageUrl);
			})
			.catch((err) => Log.error(err))
			.finally(() => (this.isLoading = false));
	}
}
</script>

<style scoped lang="scss"></style>
