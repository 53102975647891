<template>
	<div class="product-config-summary-item" :class="{ counter: showCounter }">
		<dt>{{ label }}</dt>
		<dd v-if="$slots.content">
			<slot name="content" />
		</dd>
		<dd v-else-if="content" v-html="content"></dd>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component
export default class ProductConfigurationSummaryItem extends BaseComponent {
	@Prop({
		type: String,
		required: true,
		default: "",
	})
	label!: string;

	@Prop({
		type: String,
		required: false,
		default: "",
	})
	content!: string;

	@Prop({
		type: Boolean,
		required: false,
		default: false,
	})
	showCounter!: boolean;
}
</script>

<style lang="scss" scoped>
.product-config-summary-item {
	counter-increment: product-summary-options-count;
	display: flex;
	flex: 1 1 100%;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin-top: 0.5rem;

	dt {
		flex: 1;
	}

	dd {
		flex: 0.6;
		padding-left: 0.25rem;
		margin-bottom: 0;
	}

	&.counter {
		&::before {
			content: counter(product-summary-options-count);
			display: inline-block;
			padding: 0 0.5rem;
			height: fit-content;
			font-size: 1rem;
			margin-right: 0.75rem;
			border-radius: 50%;
			background: var(--surface-a);
			color: var(--primary-color);
			border: 1px solid var(--surface-d);
			text-align: center;
		}
	}
}
</style>
