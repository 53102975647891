<template>
	<div class="product-configuration">
		<slot />
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";

@Component
export default class ProductConfiguration extends BaseComponent {}
</script>

<style lang="scss" scoped>
.product-configuration {
	counter-reset: product-config-step-counter;
	padding: 1rem 0 0 3.5rem;
}
</style>
