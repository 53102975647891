import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bf957e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-3 pb-2" }
const _hoisted_2 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_3 = {
  key: 0,
  class: "col-md-6 col-xxl-4 mb-2"
}
const _hoisted_4 = {
  key: 1,
  class: "col-md-6 col-xxl-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrainingOverviewItemCard = _resolveComponent("TrainingOverviewItemCard")!
  const _component_TrainingOverviewItemCardSkeleton = _resolveComponent("TrainingOverviewItemCardSkeleton")!
  const _component_ProductOverviewLayout = _resolveComponent("ProductOverviewLayout")!

  return (_openBlock(), _createBlock(_component_ProductOverviewLayout, {
    vm: _ctx.overviewVm,
    onOnFilterChange: _ctx.onFilterChange,
    onOnSearch: _ctx.onSearch,
    onOnSortChange: _ctx.onSortChange
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "breadcrumbs", {}, undefined, true),
        (_ctx.vm.pageTitle)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.vm.pageTitle), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    "grid-item": _withCtx(({ data }) => [
      (!_ctx.isSpinnerVisible('product-overview'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_TrainingOverviewItemCard, {
              compact: _ctx.isSmallScreen,
              item: data,
              layout: "grid"
            }, null, 8, ["compact", "item"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_TrainingOverviewItemCardSkeleton, { type: "grid" })
          ]))
    ]),
    "list-item": _withCtx(({ data }) => [
      (!_ctx.isSpinnerVisible('product-overview'))
        ? (_openBlock(), _createBlock(_component_TrainingOverviewItemCard, {
            key: 0,
            item: data,
            layout: "list"
          }, null, 8, ["item"]))
        : (_openBlock(), _createBlock(_component_TrainingOverviewItemCardSkeleton, {
            key: 1,
            type: "list"
          }))
    ]),
    _: 3
  }, 8, ["vm", "onOnFilterChange", "onOnSearch", "onOnSortChange"]))
}