/* Auto Generated */

export enum LicenseClass {
	singleUser,
	multiUser,
	maintenancePlan,
	subscription,
	cloudSubscription,
	other,
}
