<template>
	<div v-if="!isGridLayout" class="p-2 m-2 surface-a">
		<div class="p-4">
			<Skeleton height="150px" width="100%" />
			<div class="d-flex mt-2">
				<Skeleton class="mr-2" height="4rem" shape="square" width="4rem" />
				<div>
					<Skeleton class="mb-2" width="10rem" />
					<Skeleton class="mb-2" width="5rem" />
				</div>
			</div>
			<div class="d-flex flex-column mt-2">
				<Skeleton class="mb-1" height="1.25rem" width="20%" />
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton height=".75rem" width="80%" />
			</div>
		</div>
	</div>
	<div v-else class="d-flex flex-shrink-0 p-2 m-2 surface-a">
		<Skeleton height="120px" width="200px" />
		<div class="d-flex flex-column ml-1 w-50">
			<Skeleton class="mb-1" height="4rem" shape="square" width="4rem" />
			<div class="">
				<Skeleton class="mb-1" width="20%" />
				<Skeleton class="mb-2" width="35%" />
				<Skeleton class="mb-1" height="1.25rem" width="20%" />
				<Skeleton class="mb-1" height=".75rem" width="100%" />
				<Skeleton height=".75rem" width="80%" />
			</div>
		</div>
		<div class="w-50 d-flex flex-column ml-2">
			<Skeleton class="mb-1" height="1.25rem" width="20%" />
			<Skeleton class="mb-1" height=".75rem" width="100%" />
			<Skeleton class="mb-1" height=".75rem" width="100%" />
			<Skeleton class="mb-1" height=".75rem" width="100%" />
			<Skeleton class="mb-1" height=".75rem" width="100%" />
			<Skeleton height=".75rem" width="80%" />
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { OverviewLayoutType } from "@/views/layout/productOverviewLayout.vue";
import Skeleton from "primevue/skeleton";

@Component({ components: { Skeleton } })
export default class ServiceOverviewItemCardSkeleton extends BaseComponent {
	@Prop({ type: String as PropType<OverviewLayoutType>, required: true, default: "grid" })
	type!: OverviewLayoutType;

	get isGridLayout(): boolean {
		return this.type === "grid";
	}
}
</script>

<style scoped lang="scss"></style>
