import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c70c21c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "image-container" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "d-flex content-list-item-right col-8" }
const _hoisted_5 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_6 = { class: "item-content" }
const _hoisted_7 = {
  key: 0,
  class: "mb-0 mr-5"
}
const _hoisted_8 = { class: "text-muted" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "contentcategory-tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("a", {
    class: "content-list-item content-list-card-item row text-decoration-none",
    href: _ctx.item?.url
  }, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("img", {
        alt: _ctx.item.image?.alt,
        class: "image"
      }, null, 8, _hoisted_3), [
        [_directive_lazy, _ctx.item.image?.url]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.item.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(_ctx.item.title), 1))
            : _createCommentVNode("", true),
          (_ctx.showUpcomingEventTag)
            ? (_openBlock(), _createBlock(_component_Tag, {
                key: 1,
                class: "mr-1",
                severity: "success",
                value: _ctx.$t('contentCategory.lblUpcomingTag')
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.contentDate), 1),
          _createElementVNode("p", {
            class: "lead mt-2",
            innerHTML: _ctx.item.intro
          }, null, 8, _hoisted_9)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_Button, { class: "overlay p-button-link p-button-arrow" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("contentCategory.lblContinueReading")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_Tag, {
        value: _ctx.$t('contentCategory.lblCategoryTags.' + _ctx.item.contentCategory)
      }, null, 8, ["value"])
    ])
  ], 8, _hoisted_1))
}