<template>
	<DataTable
		v-if="contracts.length"
		auto-layout
		class="mb-3"
		data-key="contractNumber"
		responsive-layout="stack"
		:value="contracts"
	>
		<Column
			key="contractManager"
			field="contractManager"
			:header="$t('myCadac.contractDetail.table.colContractManager')"
			:sortable="false"
		>
			<template #body="item">
				<p v-if="userProfile?.isCadacSales || userProfile?.isImpersonated" class="mb-0 text-bold">
					<a :href="item.data.contractManagerCrmUrl" target="_blank" :title="$t('common.goToCrm')">
						{{ item.data.contractManager }}
					</a>
				</p>
				<p v-else class="mb-0 text-bold">
					{{ item.data.contractNumber }}
				</p>
			</template>
		</Column>
		<Column
			key="duration"
			field="duration"
			:header="$t('myCadac.contractDetail.table.colDuration')"
			:sortable="false"
		>
			<template #body="item">
				{{ $tc("myCadac.contractDetail.table.duration", item.data.duration) }}
			</template>
		</Column>
		<Column
			key="contractType"
			field="contractType"
			:header="$t('myCadac.contractDetail.table.colContractType')"
			:sortable="false"
		/>
		<Column
			key="automaticRenewal"
			field="automaticRenewal"
			:header="$t('myCadac.contractDetail.table.colAutomaticRenewal')"
			:sortable="false"
		>
			<template #body="item">
				<span v-if="!item.data.isDatechAutoRenewal && vm.editing" class="d-flex align-items-center">
					<span v-show="!isLoading">
						<InputSwitch v-model="item.data.automaticRenewal" @click="changeAutoRenewal(item.data)" />
					</span>
					<i v-show="isLoading" class="pi pi-spin pi-spinner" />
				</span>
				<span v-else class="d-flex align-items-center">
					<i
						:class="
							item.data.automaticRenewal
								? 'pi pi-check text-success mr-1'
								: 'pi pi-times text-danger mr-1'
						"
					/>
					<span>{{ getAutomaticRenewalText(item.data.automaticRenewal) }}</span>
				</span>
			</template>
		</Column>
		<Column
			key="endDate"
			field="endDate"
			:header="$t('myCadac.contractDetail.table.colExpiresOn')"
			:sortable="false"
		>
			<template #body="item">
				<span v-if="shouldShowContractExpirationWarning(item.data)" class="text-danger">
					{{ formatDate(item.data.endDate) }}
					<PopoverIcon
						class="d-inline-flex"
						:content="
							$tc('myCadac.contractDetail.table.contractExpirationWarning', {
								count: item.data.remainingDays,
							})
						"
					>
						<i class="ml-1 pi pi-exclamation-circle" />
					</PopoverIcon>
				</span>
				<span v-else>{{ formatDate(item.data.endDate) }}</span>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { Contract } from "@/types/generated/contract";
import { ContractClient } from "@/types/generated/Api/contractClient";
import { IContractDetail } from "@/types/viewModels/myCadac/contractDetailViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { isAfter } from "date-fns";

@Component({ emits: ["change-auto-renewal"] })
export default class ContractDetailTable extends BaseComponent {
	@Prop({ type: Object as PropType<IContractDetail>, required: true, default: {} })
	vm!: IContractDetail;
	@Prop({ type: Object as PropType<ContractClient[]>, required: true, default: {} })
	contracts!: ContractClient[];

	isLoading = false;

	getAutomaticRenewalText(automaticRenewal: boolean): string {
		return automaticRenewal ? this.$t("common.on") : this.$t("common.off");
	}

	changeAutoRenewal(contract: ContractClient): void {
		const newValue = !contract.automaticRenewal;
		this.isLoading = true;
		this.axios
			.put(
				`/api/contract/change/renewal
					?contractnumber=${contract.contractId}
					&automatic=${newValue}`
			)
			.then(() => {
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: newValue
						? this.$t("myCadac.contractDetail.toast.contractEnableRenewalSuccess")
						: this.$t("myCadac.contractDetail.toast.contractDisableRenewalSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("common.messages.error.genericException"),
					life: 3000,
				});
			})
			.finally(() => (this.isLoading = false));
	}

	shouldShowContractExpirationWarning(contract: Contract): boolean {
		return contract.supportsProlongation && contract.remainingDays > 0;
	}

	isContractExpired(contract: Contract): boolean {
		return isAfter(new Date(), new Date(contract.endDate));
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-table) {
	.p-datatable-tbody {
		tr > td {
			border: none;
			padding: 0.125rem;
		}
	}

	.p-datatable-thead {
		border: none;

		tr > th {
			border: none;
			padding: 0.125rem;
		}
	}
}
</style>
