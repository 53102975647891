<template>
	<div class="row">
		<div class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1">{{ $t("checkout.titleYourCompany") }}</h4>
					<Button class="p-button-raised p-button-text p-button-rounded ml-auto"
							icon="pi pi-pencil"
							:label="isSmallScreen ? $t('common.edit') : ''"
							:title="$t('common.edit')"
							@click="goToStep(0)" />
				</template>
				<p v-text="vm.checkoutForm.companyForm.companyName" />
				<p class="mb-0" v-text="vm.checkoutForm.companyForm.street" />
				<p class="mb-0">
					<span v-text="vm.checkoutForm.companyForm.postalCode" />
					<span class="ml-1" v-text="vm.checkoutForm.companyForm.city" />
				</p>
				<p class="mb-0" v-text="formatCountry(vm.checkoutForm.companyForm.countryCode)" />
			</Panel>
		</div>
		<div v-if="vm.checkoutForm.useDifferentInvoiceAddress && vm.checkoutForm.billingAddressForm"
			 class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1">{{ $t("checkout.titleBillingAddress") }}</h4>
					<Button class="p-button-raised p-button-text p-button-rounded ml-auto"
							icon="pi pi-pencil"
							:label="isSmallScreen ? $t('common.edit') : ''"
							:title="$t('common.edit')"
							@click="goToStep(0)" />
				</template>
				<p class="mb-0" v-text="vm.checkoutForm.billingAddressForm.street" />
				<p class="mb-0">
					<span v-text="vm.checkoutForm.billingAddressForm.postalCode" />
					<span class="ml-1" v-text="vm.checkoutForm.billingAddressForm.city" />
				</p>
				<p class="mb-0" v-text="formatCountry(vm.checkoutForm.billingAddressForm.countryCode)" />
			</Panel>
		</div>
		<div class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1">{{ $t("checkout.titlePersonalInfo") }}</h4>
					<Button class="p-button-raised p-button-text p-button-rounded ml-auto"
							icon="pi pi-pencil"
							:label="isSmallScreen ? $t('common.edit') : ''"
							:title="$t('common.edit')"
							@click="goToStep(0)" />
				</template>
				<p class="mb-0" v-text="salutationAndName"></p>
				<p class="mb-0" v-text="vm.checkoutForm.personalForm.phone" />
			</Panel>
		</div>
		<div v-if="this.orderSummaryForm?.maxAutodeskWalletToSpend > 0" class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1">{{ $t("checkout.cashback.yourCashback") }}</h4>
					<Button class="p-button-raised p-button-text p-button-rounded ml-auto"
							icon="pi pi-pencil"
							:label="isSmallScreen ? $t('common.edit') : ''"
							:title="$t('common.edit')"
							@click="goToStep(1)" />
				</template>
				<div class="d-flex align-items-center">
					<p>{{this.orderSummaryForm?.applyAutodeskWallet && this.orderSummaryForm?.maxAutodeskWalletToSpend > 0 ? $t("checkout.cashback.apply", { amount: formatCurrency(this.orderSummaryForm?.maxAutodeskWalletToSpend) }) : $t("checkout.cashback.none") }}</p>
				</div>
			</Panel>
		</div>
		<div class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1">{{ $t("checkout.titlePaymentMethod") }}</h4>
					<Button class="p-button-raised p-button-text p-button-rounded ml-auto"
							icon="pi pi-pencil"
							:label="isSmallScreen ? $t('common.edit') : ''"
							:title="$t('common.edit')"
							@click="goToStep(1)" />
				</template>
				<div class="d-flex align-items-center">
					<svg class="mr-1" height="67" width="67">
						<use :xlink:href="paymentIcon" />
					</svg>
					<p v-text="formattedPaymentMethod"></p>
				</div>
			</Panel>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1" :class="{ required: orderSummaryForm.isReferenceNumberRequired }">
						{{ $t("checkout.titleReferenceNumber") }}
					</h4>
				</template>
				<InputText
					v-model="v$['orderSummaryForm'].reference.$model"
					:placeholder="$t('checkout.referenceNumber_placeholder')"
				/>
			</Panel>
		</div>
		<div class="col-12 col-lg-6 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1" :class="{ required: orderSummaryForm.isInvoiceCopyEmailRequired }">
						{{ $t("checkout.titleCreditorAdministration") }}
					</h4>
				</template>
				<InputText
					v-model="v$['orderSummaryForm'].invoiceCopyEmail.$model"
					:placeholder="$t('checkout.invoiceCopyEmail_placeholder')"
				/>
			</Panel>
		</div>
	</div>
	<div class="row">
		<div v-if="vm?.cartClient?.itemCount" class="col-12 mb-2">
			<Panel :toggleable="false">
				<template #header>
					<h4 class="mb-1">{{ $t("checkout.titleCart") }}</h4>
					<Button
						v-if="!canEditCart"
						class="p-button-text hidden-print p-0"
						:disabled="isBusy"
						icon="pi pi-pencil"
						:label="$t('common.edit')"
						:title="$t('common.edit')"
						@click="canEditCart = !canEditCart"
					/>
					<Button
						v-else-if="canEditCart"
						class="p-button-text p-button-success hidden-print p-0"
						:disabled="isBusy"
						icon="pi pi-check"
						:label="$t('checkout.button.doneEditingCart')"
						:title="$t('checkout.button.doneEditingCart')"
						@click="canEditCart = !canEditCart"
					/>
				</template>
				<CartOverview :cart-client="vm?.cartClient" :is-editable="canEditCart" />
			</Panel>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartOverview from "@/components/commerce/cart/cartOverview.vue";
import { CartClient } from "@/types/models/cart/cartClient";
import { CheckoutStep } from "@/components/commerce/checkout/checkoutSteps.vue";
import { Component, Watch } from "vue-facing-decorator";
import { ICheckout } from "@/types/viewModels/commerce/checkoutViewModel";
import { PaymentMethod } from "@/types/enum/paymentMethod";
import { useCartStore } from "@/store/commerce/cartStore";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import { OrderSummaryForm } from "@/types/models/form/orderSummaryForm";

const requiredReferenceNumber = (_value: string, siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return vm?.isReferenceNumberRequired;
};

const requiredInvoiceNumber = (_value: string, siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return vm?.isInvoiceCopyEmailRequired;
};

@Component({
	options: {
		validations: {
			orderSummaryForm: {
				reference: { required: requiredIf(requiredReferenceNumber as any) },
				invoiceCopyEmail: { required: requiredIf(requiredInvoiceNumber as any) },
				isReferenceNumberRequired: {},
				isInvoiceCopyEmailRequired: {},
			},
		},
	},
	components: { CartOverview },
})
export default class OrderSummary extends BaseComponent {
	canEditCart = false;

	cartStore = useCartStore();
	checkoutStore = useCheckoutStore();
	orderSummaryForm!: Partial<OrderSummaryForm>;
	v$ = useVuelidate();

	@Watch("v$.$invalid") onValidationChanged(val: boolean): void {
		this.checkoutStore.isOverviewStepValid = !val;
	}

	get vm(): ICheckout {
		return this.checkoutStore.vm as ICheckout;
	}

	created(): void {
		this.orderSummaryForm = this.vm.checkoutForm;

		this.orderSummaryForm.isReferenceNumberRequired = this.vm.checkoutForm.referenceRequired;
		this.orderSummaryForm.isInvoiceCopyEmailRequired = false;
	}

	get editingCartClient(): CartClient {
		return this.canEditCart ? this.cartStore.cartClient : this.vm?.cartClient;
	}

	get salutationAndName(): string {
		const { gender, firstName, lastName } = this.vm.checkoutForm.personalForm;
		const salutation = this.$t(`enum.salutation.${gender}`);
		return `${salutation} ${firstName} ${lastName}`;
	}

	get paymentIcon(): string {
		const { paymentMethod, selectedPaymentMethod, selectedPaymentProvider } = this.vm.checkoutForm;
		return selectedPaymentMethod === PaymentMethod.creditcard
			? `#payment-${selectedPaymentProvider}`
			: `#payment-${paymentMethod}`;
	}

	get formattedPaymentMethod(): string {
		const { selectedPaymentMethod, selectedPaymentProvider } = this.vm.checkoutForm;
		const selectedPaymentMethodName = this.$t(`enum.paymentMethod.${selectedPaymentMethod}`);
		const selectedPaymentProviderName =
			selectedPaymentMethod === PaymentMethod.ideal
				? this.vm.idealIssuers.find((x) => x.key === selectedPaymentProvider)?.value
				: selectedPaymentMethod === PaymentMethod.creditcard
					? this.vm.creditCardMethods.find((x) => x.key === selectedPaymentProvider)?.value
					: "";
		return selectedPaymentProviderName
			? `${selectedPaymentMethodName} - ${selectedPaymentProviderName}`
			: selectedPaymentMethodName;
	}

	formatCountry(countryCode: string): string {
		return this.vm.countries?.find((x) => x.key === countryCode)?.value as string;
	}

	goToStep(step: CheckoutStep): void {
		if (this.checkoutStore.canGoToStep(step)) {
			this.checkoutStore.activeStep = step;
			const stepsEl = document.querySelector(".checkout-steps");
			window.scrollTo(0, stepsEl?.getBoundingClientRect().bottom || 80);
		}
	}
}
</script>

<style scoped lang="scss"></style>
