<template>
	<div class="d-flex flex-wrap flex-column">
		<a v-if="!item.isHidden && item.url" class="d-flex mr-lg-1 mb-1" :href="item.url">
			<Avatar
				v-if="item.icon"
				:alt="item.iconAltTag"
				class="flex-shrink-0"
				:image="item.icon"
				shape="square"
				size="large"
				:title="productName"
			/>

			<div class="d-flex px-1 flex-column">
				<small class="mb-0 text-muted d-block">
					{{ item.brand }}
				</small>
				<span class="mb-0 text-bold">
					{{ productName }}
				</span>
			</div>
		</a>
		<div v-else class="d-flex mr-lg-1 mb-1">
			<Avatar
				v-if="item.icon"
				:alt="item.iconAltTag"
				class="flex-shrink-0"
				:image="item.icon"
				shape="square"
				size="large"
				:title="productName"
			/>

			<div class="d-flex px-1 flex-column">
				<small class="mb-0 text-muted d-block">
					{{ item.brand }}
				</small>
				<span class="mb-0 text-bold">
					{{ productName }}
				</span>
			</div>
		</div>
		<slot />
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";

@Component
export default class ColumnProductName extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient>, required: true, default: {} }) item!: CartLineClient;

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get productName(): string {
		switch (this.item.cartLineType) {
			case CartLineType.expertProduct:
				return this.item.expert?.name;
			default:
				return this.item.title;
		}
	}
}
</script>

<style lang="scss" scoped></style>
