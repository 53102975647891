import httpClient from '@/types/helpers/apiHelper';
import i18n from '@/types/utils/i18n';
import { DateRangeFilterViewModel } from '@/types/models/common/filters/dateRangeFilterViewModel';
import { ExpertOverviewFilterResponse } from '@/types/models/expert/expertOverviewFilterResponse';
import { FilterPropsViewModel } from '@/types/models/common/filters/filterPropsViewModel';
import { FilterTypes } from '@/types/enum/filterTypes';
import { FormatHelper } from '@/types/helpers/formatHelper';
import { IDropdownItem } from '@/types/models/common/dropdownItem.interface';
import { LocationFilterViewModel } from '@/types/models/common/filters/locationFilterViewModel';
import { OverviewFilterViewModel } from '@/types/models/common/filters/overviewFilterViewModel';
import { OverviewSortType } from '@/types/enum/overviewSortType';
import { ProductOverviewFilterRequest } from '@/types/models/software/productOverviewFilterRequest';
import { ProductOverviewFilterResponse } from '@/types/models/software/productOverviewFilterResponse';
import { TrainingOverviewFilterRequest } from '@/types/models/training/trainingOverviewFilterRequest';
import { TrainingOverviewFilterResponse } from '@/types/models/training/trainingOverviewFilterResponse';
import { defineStore } from 'pinia';
import { entries, isEmpty, reduce } from 'lodash';
import { format, isSameDay } from 'date-fns';
import { useLayoutStore } from '@/store/common/layoutStore';

interface OverviewFiltersStoreState {
	queryParams: URLSearchParams;
	// Used for primeVue dataview sorting
	sortKey: string;
	sortOrder: number | null;
	sortField: string | null;
	sortOptions: IDropdownItem[];
	// Used for sorting via API
	sortType: OverviewSortType;
	filters: OverviewFilterViewModel[];
}

export const useProductOverviewFilterStore = defineStore('productOverviewFilters', {
	state: () =>
		({
			filters: [],
			queryParams: new URLSearchParams(),
			sortKey: '',
			sortOrder: null,
			sortField: null,
			sortOptions: [],
			sortType: 0,
		}) as OverviewFiltersStoreState,
	getters: {
		hasFilters(): boolean {
			return !!this.filters?.length;
		},
		hasOptionFilters(): boolean {
			return !!this.optionFilters?.length;
		},
		hasActiveFilters(): boolean {
			return this.activeFilterCount > 0;
		},
		hasQueryFilter(): boolean {
			return !!this.queryFilter?.props;
		},
		hasActiveQueryFilter(): boolean {
			return !!this.queryFilter?.props?.queryString && this.queryFilter.isActive;
		},
		hasWorkRemoteFilter(): boolean {
			return !!this.workRemoteFilter?.props;
		},
		hasActiveWorkRemoteFilter(): boolean {
			return !!this.workRemoteFilter?.props?.location?.workRemote;
		},
		hasWorkOnLocationFilter(): boolean {
			return !!this.workOnLocationFilter?.props;
		},
		hasActiveWorkOnLocationFilter(): boolean {
			return !!this.workOnLocationFilter?.props?.location?.workOnLocation;
		},
		hasAvailabilityFilter(): boolean {
			return !!this.availabilityFilter?.props;
		},
		hasActiveAvailabilityFilter(): boolean {
			return !isEmpty(this.availabilityFilter?.props?.availability) && this.availabilityFilter.isActive;
		},
		queryFilter(state): OverviewFilterViewModel {
			return state.filters?.find((x) => x.type === FilterTypes.Query) as OverviewFilterViewModel;
		},
		workOnLocationFilter(state): OverviewFilterViewModel {
			return state.filters?.find((x) => x.type === FilterTypes.WorkOnLocation) as OverviewFilterViewModel;
		},
		workRemoteFilter(state): OverviewFilterViewModel {
			return state.filters?.find((x) => x.type === FilterTypes.WorkRemote) as OverviewFilterViewModel;
		},
		availabilityFilter(state): OverviewFilterViewModel {
			return state.filters?.find((x) => x.type === FilterTypes.DateRange) as OverviewFilterViewModel;
		},
		optionFilters(state): OverviewFilterViewModel[] {
			return state.filters?.filter((x) => x.type === FilterTypes.MultiSelect && x.options.length);
		},
		activeFilterCount(): number {
			return reduce(
				this.filters.filter((x) => x.category !== FilterTypes.Query),
				(result, value) => {
					if (value.isActive) result++;
					else {
						const isActiveOptions = value.options?.map((x) => x.isActive);
						result += isActiveOptions?.filter(Boolean).length;
					}
					return result;
				},
				0
			);
		},
	},
	actions: {
		async init(filters: OverviewFilterViewModel[]): Promise<void> {
			this.filters = filters;
			this.sortOptions = [
				{ label: i18n.global.t('common.sortDefault'), value: OverviewSortType.Default },
				{ label: i18n.global.t('common.sortNameAsc'), value: OverviewSortType.NameAsc },
				{ label: i18n.global.t('common.sortNameDesc'), value: OverviewSortType.NameDesc },
			];
		},
		async updateTrainingFilters(): Promise<TrainingOverviewFilterResponse> {
			const payload: TrainingOverviewFilterRequest = {
				filters: this.filters,
				sortType: this.sortType,
			};
			const response = await httpClient.post(
				`/api/${useLayoutStore().vm.localizationPrefix}/training/updateOverviewFilters`,
				payload
			);

			this.filters = [...response.data.filters];
			this.updateQueryParams();

			return response.data;
		},
		async updateProductFilters(): Promise<ProductOverviewFilterResponse> {
			const payload: ProductOverviewFilterRequest = {
				filters: this.filters,
				sortType: this.sortType,
			};
			const response = await httpClient.post(
				`/api/${useLayoutStore().vm.localizationPrefix}/product/updateOverviewFilters`,
				payload
			);

			this.filters = [...response.data.filters];
			this.updateQueryParams();

			return response.data;
		},
		async updateExpertFilters(): Promise<ExpertOverviewFilterResponse> {
			const payload: ProductOverviewFilterRequest = {
				filters: this.filters,
				sortType: this.sortType,
			};
			const response = await httpClient.post(
				`/api/${useLayoutStore().vm.localizationPrefix}/expert/updateOverviewFilters`,
				payload
			);
			this.$patch({ filters: response.data.filters });
			this.updateQueryParams();

			return response.data;
		},
		addPriceSortOptions(): void {
			this.sortOptions.push({
				label: i18n.global.t('common.sortPriceAsc'),
				value: OverviewSortType.PriceAsc,
			});
			this.sortOptions.push({
				label: i18n.global.t('common.sortPriceDesc'),
				value: OverviewSortType.PriceDesc,
			});
		},
		addExpertSortOptions(): void {
			if (this.hasActiveAvailabilityFilter) {
				this.sortOptions.push({
					label: i18n.global.t('common.sortAvailability'),
					value: OverviewSortType.Availability,
				});
			}

			if (this.hasActiveWorkOnLocationFilter) {
				this.sortOptions.push({
					label: i18n.global.t('common.sortLocation'),
					value: OverviewSortType.Location,
				});
			}

			this.sortOptions.push({ label: i18n.global.t('common.sortScore'), value: OverviewSortType.Score });
		},
		updateSort(sortKey: string, sortField: string): void {
			if (sortField.indexOf('!') === 0) {
				this.sortOrder = -1;
				this.sortField = sortField.substring(1, sortField.length);
				this.sortKey = sortKey;
			} else {
				this.sortOrder = 1;
				this.sortField = sortField;
				this.sortKey = sortKey;
			}
		},
		updateSearchQuery(query: string): void {
			const { queryString } = this.queryFilter.props;
			if (query !== queryString) {
				this.queryFilter.props.queryString = query;
				this.queryFilter.isActive = true;
			}
		},
		updateWorkOnLocationFilter(newLocation: LocationFilterViewModel): void {
			const { location } = this.workOnLocationFilter.props;
			if (newLocation?.locationName !== location?.locationName || !newLocation?.workOnLocation) {
				this.workOnLocationFilter.props.location = newLocation;
				this.workOnLocationFilter.isActive = true;
			}
		},
		updateWorkRemoteLocationFilter(newLocation: LocationFilterViewModel): void {
			const { location } = this.workRemoteFilter.props;
			if (newLocation?.workRemote !== location?.workRemote) {
				this.workRemoteFilter.props.location = newLocation;
				this.workRemoteFilter.isActive = true;
			}
		},
		updateAvailabilityFilter(newDateRange: DateRangeFilterViewModel): void {
			const { availability } = this.availabilityFilter.props;
			if (
				!isSameDay(newDateRange.startDate, FormatHelper.toDate(availability.startDate)) &&
				!isSameDay(newDateRange.endDate, FormatHelper.toDate(availability.endDate))
			) {
				this.availabilityFilter.props.availability = newDateRange;
				this.availabilityFilter.isActive = true;
			}
		},
		updateQueryParams(): void {
			const queryParams = new URLSearchParams('');
			this.optionFilters.forEach((filter) => {
				const activeOptions = filter.options.filter((x) => x.isActive);
				if (activeOptions.length > 0)
					queryParams.append(filter.label, activeOptions.map((x) => x.id + '-' + x.label).join(','));
			});

			if (this.hasActiveQueryFilter) {
				queryParams.set(this.queryFilter.label, this.queryFilter.props.queryString);
			}

			if (this.hasActiveAvailabilityFilter) {
				entries(this.availabilityFilter.props.availability).forEach(([key, value]) => {
					if (value) queryParams.set(key, format(FormatHelper.toDate(value), 'yyyy-MM-dd'));
				});
			}

			if (this.hasActiveWorkRemoteFilter) {
				entries(this.workRemoteFilter.props.location)
					.filter(([key]) => key === 'workRemote')
					.forEach(([key, value]) => {
						if (value) queryParams.set(key, value);
					});
			}

			if (this.hasActiveWorkOnLocationFilter) {
				entries(this.workOnLocationFilter.props.location)
					.filter(([key]) => key !== 'workRemote')
					.forEach(([key, value]) => {
						if (value) queryParams.set(key, value);
					});
			}

			this.queryParams = queryParams;
			const url = new URL(window.location.href);
			url.search = queryParams.toString();

			if (window.history.replaceState) {
				window.history.replaceState({}, document.title, url.toString());
			}
		},
		resetFilters(): void {
			this.filters.forEach((x) => {
				x.props = {} as FilterPropsViewModel;
				x.isActive = false;
				x.options.forEach((y) => (y.isActive = false));
			});
		},
	},
});
