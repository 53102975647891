import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf8ea0c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-select-button mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-check mr-1"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: _normalizeClass(["custom-button", { 'p-highlight': _ctx.isSelected }]),
      disabled: !!_ctx.$attrs.disabled,
      onClick: _ctx.optionSelected
    }, {
      default: _withCtx(() => [
        (_ctx.isSelected)
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: "d-flex flex-wrap align-items-center",
          style: {"white-space":"pre"},
          innerHTML: _ctx.option.label
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["class", "disabled", "onClick"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uspSlots, (slotName) => {
      return (_openBlock(), _createElementBlock("div", {
        key: slotName,
        class: "select-button-usp"
      }, [
        _renderSlot(_ctx.$slots, slotName, {}, undefined, true)
      ]))
    }), 128))
  ]))
}