<template>
	<h1 v-if="vm.pageTitle" class="mb-1">{{ vm.pageTitle }}</h1>
	<p v-if="vm.introText" class="lead" v-html="vm.introText" />

	<div class="row my-2">
		<div class="col mb-1">
			<Panel id="panel-supportSubscriptions" :toggleable="false">
				<template #header>
					<div class="d-flex flex-column">
						<h3 class="mb-1">
							{{ $t("myCadac.supportOverview.panel.titleSupportSubscriptions") }}
							<PopoverIcon
								v-if="vm.supportSubscriptionsHelpText"
								class="d-inline-flex"
								:content="vm.supportSubscriptionsHelpText"
								placement="right"
							>
								<i class="popover-icon pi pi-question" />
							</PopoverIcon>
						</h3>
						<p class="mb-0 pr-1">
							{{ $t("myCadac.supportOverview.panel.subtitleSupportSubscriptions") }}
						</p>
					</div>
					<Button
						v-if="vm.supportContractSupportAssets?.length"
						class="p-button-raised p-button-success p-button-arrow p-button-sm"
						:disabled="!urlToCreateTicketForAsset"
						:label="$t('myCadac.supportOverview.button.submitSupportRequest')"
						@click="
							openUrl(urlToCreateTicketForAsset);
							$event.target.disabled = true;
						"
					/>
				</template>
				<DataTable
					v-if="vm.supportContractSupportAssets?.length"
					v-model:selection="selectedSupportContractAsset"
					removable-sort
					responsive-layout="scroll"
					selection-mode="single"
					:value="vm.supportContractSupportAssets"
				>
					<Column field="title" :sortable="false">
						<template #body="item">
							<div class="d-flex">
								<Avatar
									v-if="item.data.icon"
									alt="product-icon"
									class="mr-1 bg-transparent"
									:image="item.data.icon"
									shape="square"
								/>
								<Avatar v-else alt="product-icon" class="mr-1 bg-transparent" shape="square">
									<svg height="30" width="30">
										<use xlink:href="#icon-cadac"></use>
									</svg>
								</Avatar>
								<div>
									<p class="mb-0 text-bold">{{ item.data.title }}</p>
								</div>
							</div>
						</template>
					</Column>
				</DataTable>
				<Message v-if="!vm.supportContractSupportAssets?.length" :closable="false" severity="warn">
					{{ $t("myCadac.supportOverview.panel.noSupportSubscriptionsMessage") }}
				</Message>
			</Panel>
		</div>
	</div>

	<div class="row mb-2">
		<div class="col mb-1">
			<Panel id="panel-supportTickets" :toggleable="false">
				<template #header>
					<div class="d-flex flex-column">
						<h3 class="mb-1">
							{{ $t("myCadac.supportOverview.panel.titleTickets") }}

							<PopoverIcon
								v-if="vm.supportTicketsHelpText"
								class="d-inline-flex"
								:content="vm.supportTicketsHelpText"
								placement="right"
							>
								<i class="popover-icon pi pi-question" />
							</PopoverIcon>
						</h3>
						<p class="mb-0 pr-1">
							{{ $t("myCadac.supportOverview.panel.subtitleTickets") }}
						</p>
					</div>
					<Button
						class="p-button-raised p-button-success p-button-arrow p-button-sm"
						:label="$t('myCadac.supportOverview.button.submitSupportRequest')"
						@click="
							openUrl(vm.supportTicketsCreatePageUrl);
							$event.target.disabled = true;
						"
					/>
				</template>
				<div v-if="vm.availableAmountOfSupportTickets" class="d-flex align-items-center">
					<div class="icon-available-tickets">
						<svg fill="#000" height="22" width="22">
							<use xlink:href="#icon-support-avatar"></use>
						</svg>
						<strong>{{ vm.availableAmountOfSupportTickets }}</strong>
					</div>
					<span>
						{{
							$tc(
								"myCadac.supportOverview.panel.availableTickets",
								vm.availableAmountOfSupportTickets
							)
						}}
					</span>
				</div>
				<Message v-if="!vm.availableAmountOfSupportTickets" :closable="false" severity="warn">
					{{ $t("myCadac.supportOverview.panel.noTicketsMessage") }}
				</Message>
				<Button
					v-if="vm.supportTicketsBuyPageUrl"
					class="p-button-raised p-button-primary"
					:label="$t('myCadac.supportOverview.button.buyTickets')"
					@click="
						openUrl(vm.supportTicketsBuyPageUrl);
						$event.target.disabled = true;
					"
				/>
			</Panel>
		</div>
	</div>

	<div class="row mb-2">
		<div class="col mb-1">
			<Panel id="panel-addSoftware" :toggleable="false">
				<template #header>
					<div class="d-flex flex-column">
						<h3 class="mb-1">
							{{ $t("myCadac.supportOverview.panel.titleAddSoftware") }}
						</h3>
						<p class="mb-0 pr-1">
							{{ $t("myCadac.supportOverview.panel.subtitleAddSoftware") }}
						</p>
					</div>
					<Button
						class="p-button-raised p-button-success p-button-arrow p-button-sm ml-auto"
						:disabled="!productsToAdd.length"
						:label="$t('myCadac.supportOverview.button.addProductsToCart')"
						@click="addSupportProductToCart()"
					/>
				</template>
				<div class="p-inputgroup">
					<AutoComplete
						v-model="selectedProducts"
						force-selection
						:min-length="2"
						:multiple="true"
						option-label="title"
						:placeholder="$t('myCadac.supportOverview.autocomplete.placeholder')"
						:suggestions="searchProductResults"
						@complete="onSearchTrigger($event)"
					>
						<template #option="{ option }">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-items-center">
									<Avatar
										v-if="option.icon"
										alt="product-icon"
										class="mr-1 bg-transparent"
										:image="option.icon"
										shape="square"
									/>
									<Avatar v-else alt="product-icon" class="mr-1 bg-transparent" shape="square">
										<svg height="30" width="30">
											<use xlink:href="#icon-cadac"></use>
										</svg>
									</Avatar>
									<p class="mb-0 text-bold">{{ option.title }}</p>
								</div>
								<p class="mb-0 text-bold">
									{{
										$t("myCadac.supportOverview.autocomplete.yearPrice", {
											price: formatCurrency(option.yearPrice),
										})
									}}
								</p>
							</div>
						</template>
					</AutoComplete>
					<Button class="p-button-primary" icon="pi pi-search" />
				</div>
				<Message v-if="searchProductNoResults" :closable="false" severity="warn">
					{{ $t("myCadac.supportOverview.autocomplete.msgNoResults", { query: searchQuery }) }}
				</Message>
			</Panel>
		</div>
	</div>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
</template>

<script lang="ts">
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
import { BaseProductItemViewModel } from "@/types/models/common/baseProductItemViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
import { ISupportOverview } from "@/types/viewModels/myCadac/supportOverviewViewModel";
import { ItemProductType } from "@/types/enum/itemProductType";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SupportAddToCart } from "@/types/generated/Api/supportAddToCart";
import { SupportVariationViewModel } from "@/types/models/support/supportVariationViewModel";
import { useCartStore } from "@/store/commerce/cartStore";

@Component({ components: { AutoComplete, ProductAddedModal } })
export default class SupportOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ISupportOverview>,
		required: true,
		default: {},
	})
	vm!: ISupportOverview;

	isLoading = false;
	showProductAddedModal = false;
	searchProductNoResults = false;
	searchQuery = "";
	searchProductResults: SupportVariationViewModel[] = [];
	selectedSupportContractAsset!: SupportVariationViewModel;
	selectedProducts: SupportVariationViewModel[] = [];

	cartStore = useCartStore();

	get urlToCreateTicketForAsset(): string {
		return this.selectedSupportContractAsset?.sku
			? `${this.vm.supportTicketsCreatePageUrl}?cat=${this.vm.askAQuestionSupportCategoryId}&sku=${this.selectedSupportContractAsset.sku}`
			: this.selectedSupportContractAsset?.id
				? `${this.vm.supportTicketsCreatePageUrl}?cat=${this.vm.askAQuestionSupportCategoryId}&sku=null`
				: "";
	}

	get productAddedVm(): IProductAddedModal {
		return {
			product: {
				...this.vm.supportProductViewModel,
				productType: ItemProductType.Other,
			} as BaseProductItemViewModel,
			crossSellProducts: this.vm.supportProductViewModel?.relatedCrossSellProducts,
		};
	}

	get productsToAdd(): SupportVariationViewModel[] {
		return this.selectedProducts.filter((x) => !x.addedToCart);
	}

	get productAddedToCart(): SupportVariationViewModel[] {
		return this.selectedProducts.filter((x) => x.addedToCart);
	}

	onSearchTrigger({ query }: { query: string }): void {
		this.isLoading = true;
		this.searchQuery = query;
		const existingIds = this.vm.supportContractSupportAssets.map((x) => x.id).join("|");
		this.axios
			.get(`/api/${this.vm.localizationPrefix}/support/searchvariations`, { params: { query, existingIds } })
			.then(({ data }) => {
				this.searchProductResults = data.results;
				this.searchProductNoResults = data.results?.length === 0;
			})
			.catch((err) => Log.error(err))
			.finally(() => (this.isLoading = false));
	}

	addSupportProductToCart(): void {
		this.isLoading = true;
		const data = {
			supportVariationCodes: this.productsToAdd.map((x) => x.id),
		} as SupportAddToCart;
		this.axios
			.post(`/api/cart/add-support`, data)
			.then(() => {
				this.cartStore.showModal("ProductAdded");
				this.selectedProducts = [
					...this.selectedProducts.map((x) => {
						x.addedToCart = true;
						return x;
					}),
				];
			})
			.catch(() =>
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("common.messages.addToCartError"),
					life: 3000,
				})
			)
			.finally(() => (this.isLoading = false));
	}
}
</script>

<style lang="scss" scoped>
.icon-available-tickets {
	display: flex;
	align-items: center;
	padding: 0.5em 0.75em;
	border: 1px solid var(--surface-d);
	border-radius: 0.25em;
	margin: 1rem 0.5rem 1rem 0;
	color: var(--primary);

	svg {
		color: black;
		margin-right: 5px;
	}
}

::v-deep(#panel-supportSubscriptions) {
	.p-datatable-thead {
		display: none;
	}

	.p-datatable-tbody > tr.p-highlight {
		background: var(--bg-highlight);
		color: var(--primary);
		outline-color: var(--primary);
	}
}
</style>
