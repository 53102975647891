import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-md-12 col-lg-10 col-xxl-8" }
const _hoisted_3 = { class: "form-row" }
const _hoisted_4 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_5 = { class: "form-group col-md-6 col-lg-5 col-xxl-4" }
const _hoisted_6 = { class: "form-row" }
const _hoisted_7 = { class: "form-group col-md-12 col-lg-10 col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputWithValidation = _resolveComponent("InputWithValidation")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("form", {
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formStateChanged && _ctx.formStateChanged(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['street'].required,
          label: _ctx.$t('common.forms.lblAddress'),
          name: "street"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['postalCode'].required,
          label: _ctx.$t('common.forms.lblPostalCode'),
          name: "postalCode"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['city'].required,
          label: _ctx.$t('common.forms.lblCity'),
          name: "city"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_InputText, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              name: field.name,
              onBlur: ($event: any) => (field.setTouched(true))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "name", "onBlur"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_InputWithValidation, {
          "is-required": _ctx.validations['countryCode'].required,
          label: _ctx.$t('common.forms.lblCountry'),
          name: "countryCode"
        }, {
          default: _withCtx(({ field }) => [
            _createVNode(_component_Dropdown, {
              modelValue: field.value.value,
              "onUpdate:modelValue": ($event: any) => ((field.value.value) = $event),
              class: _normalizeClass({ 'p-invalid': field.errors?.length }),
              disabled: _ctx.countries?.length === 1,
              "option-label": "value",
              "option-value": "key",
              options: _ctx.countries,
              onChange: ($event: any) => {
							_ctx.formStateChanged;
							field.setTouched(true);
						}
            }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "options", "onChange"])
          ]),
          _: 1
        }, 8, ["is-required", "label"])
      ])
    ])
  ], 32))
}