import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42a5c754"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-right text-lg-left pb-0 pt-2 px-2" }
const _hoisted_3 = { class: "mb-0 ml-1 text-bold text-primary" }
const _hoisted_4 = { class: "d-flex flex-wrap flex-lg-nowrap flex-row-reverse flex-lg-row text-right text-lg-left" }
const _hoisted_5 = { class: "d-flex px-1 flex-column" }
const _hoisted_6 = { class: "mb-0 text-muted d-block" }
const _hoisted_7 = { class: "mb-0 text-bold" }
const _hoisted_8 = { class: "d-flex align-items-center justify-content-end justify-content-lg-start" }
const _hoisted_9 = ["title"]
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "text-preline" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "d-flex flex-column text-right text-lg-left" }
const _hoisted_14 = { class: "mb-0 text-bold" }
const _hoisted_15 = { class: "mb-0" }
const _hoisted_16 = {
  key: 0,
  class: "text-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.pageTitle)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.vm.pageTitle), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingsGroupedByLinkId, ([trainingLinkId, trainings]) => {
      return (_openBlock(), _createBlock(_component_Card, {
        key: trainingLinkId,
        class: "mb-3"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(trainings[0].name), 1)
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_DataTable, {
            "auto-layout": "",
            "responsive-layout": "stack",
            value: trainings
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                key: "trainingName",
                header: _ctx.$t('training.lblCourse')
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("div", _hoisted_4, [
                    (item.data.productIconUrl)
                      ? (_openBlock(), _createBlock(_component_Avatar, {
                          key: 0,
                          alt: item.data.productIconAlt || item.data.subject,
                          class: "flex-shrink-0",
                          image: item.data.productIconUrl,
                          shape: "square",
                          size: "large",
                          title: item.data.subject
                        }, null, 8, ["alt", "image", "title"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("small", _hoisted_6, _toDisplayString(item.data.brand), 1),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(item.data.name), 1),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("i", {
                          class: "pi pi-user text-primary mr-1",
                          title: _ctx.$t('myCadac.trainingOverview.table.colParticipants')
                        }, null, 8, _hoisted_9),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$tc("myCadac.trainingOverview.table.lblParticipantsCount", {
												count: item.data.participants?.length,
											})), 1)
                      ])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "trainingType",
                field: "trainingType",
                header: _ctx.$t('myCadac.trainingOverview.table.trainingInfo.colType'),
                style: {"width":"12.5%"}
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t(`enum.trainingType.${item.data.trainingType}`)), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "location",
                "body-class": "text-nowrap ",
                field: "location",
                header: _ctx.$t('myCadac.trainingOverview.table.colLocation'),
                style: {"width":"17.5%"}
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formattedAddress(item.data)), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "date",
                field: "date",
                header: _ctx.$t('myCadac.trainingOverview.table.colDate'),
                style: {"width":"22.5%"}
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$tc("myCadac.trainingOverview.table.lblDays", {
											count: item.data.dates?.length,
										})), 1),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(item.data?.formattedDates), 1),
                      (item.data.remainingDays > 0)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$tc("myCadac.trainingOverview.table.lblDaysRemaining", {
											count: item.data.remainingDays,
										})), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "actions",
                "body-class": "text-right"
              }, {
                body: _withCtx((training) => [
                  (!_ctx.vm.isInstructorOfATraining)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "p-button-primary p-button-raised p-button-text p-button-rounded ml-auto",
                        icon: "pi pi-eye",
                        title: _ctx.$t('myCadac.trainingOverview.table.lblView'),
                        onClick: ($event: any) => (_ctx.openUrl(`${_ctx.vm.finishedTrainingDetailUrl}?traininglistid=${training.data.id}`))
                      }, null, 8, ["title", "onClick"]))
                    : (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "p-button-success ml-auto",
                        label: _ctx.$t('myCadac.trainingOverview.table.lblEdit'),
                        onClick: ($event: any) => (_ctx.openUrl(`${_ctx.vm.finishedTrainingDetailUrl}?traininglistid=${training.data.id}`))
                      }, null, 8, ["label", "onClick"]))
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["value"])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 64))
}