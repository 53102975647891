<template>
	<form @change="formStateChanged">
		<div class="form-row">
			<div class="form-group col-md-6 col-xxl-5">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['password'].required"
					:label="$t('myCadac.accountSettings.lblPassword')"
					name="password"
				>
					<Password
						v-model="field.value.value"
						class="w-100"
						:class="{ 'p-invalid': field.errors?.length }"
						:feedback="false"
						:name="field.name"
						toggle-mask
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
			<div class="form-group col-md-6 col-xxl-5">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['passwordConfirm'].required"
					:label="$t('myCadac.accountSettings.lblConfirmPassword')"
					name="passwordConfirm"
				>
					<Password
						v-model="field.value.value"
						class="w-100"
						:class="{ 'p-invalid': field.errors?.length }"
						:feedback="false"
						:name="field.name"
						toggle-mask
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import InputWithValidation from "@/components/common/inputWithValidation.vue";
import Password from "primevue/password";
import { Component, Emit, Model } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { FormContext, FormMeta, useForm } from "vee-validate";
import { ProfilePassword } from "@/types/models/form/profilePassword";
import { PropType, unref } from "vue";
import { cloneDeep } from "lodash";

@Component({
	components: { Password, InputWithValidation },
})
export default class NewPasswordForm extends BaseComponent implements FormComponent<ProfilePassword> {
	@Model({
		type: Object as PropType<ProfilePassword>,
		required: true,
		default: {},
	})
	model!: ProfilePassword;

	@Emit() formStateChanged(): FormMeta<ProfilePassword> {
		const { meta, values } = this.form;
		this.model = cloneDeep(values);
		return unref(meta);
	}

	form!: FormContext<ProfilePassword>;

	created(): void {
		this.form = useForm({ initialValues: this.model, validationSchema: this.validations });
	}

	get validations(): Record<string, unknown> {
		return {
			password: { required: true, validPassword: true },
			passwordConfirm: { required: true, samePassword: "@password" },
		};
	}

	get isValid(): boolean {
		return unref(this.form.meta)?.valid;
	}

	get isDirty(): boolean {
		return unref(this.form.meta)?.dirty;
	}
}
</script>

<style scoped lang="scss"></style>
