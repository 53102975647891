/* Auto Generated */

export enum PaymentMethod {
	ideal,
	bancontactMrCash,
	paypal,
	creditcard,
	onAccount,
	sofort,
}
