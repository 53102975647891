<template>
	<div></div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { IExpertAvailability } from "@/types/viewModels/myCadac/expertAvailabilityViewModel";

export default class ExpertAvailability extends BaseComponent {
	@Prop({ type: Object as PropType<IExpertAvailability>, required: true, default: {} })
	vm!: IExpertAvailability;
}
</script>

<style lang="scss" scoped></style>
