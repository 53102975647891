import i18n from '@/types/utils/i18n';
import { AddressForm as AddressFormModel } from '@/types/models/form/addressForm';
import { IDropdownItem } from '@/types/models/common/dropdownItem.interface';
import { LocalTrainingType } from '@/types/enum/localTrainingType';
import { ScheduledTrainingViewModel } from '@/types/generated/Training/scheduledTrainingViewModel';
import { TrainingConfigurationOption } from '@/types/enum/trainingConfigurationOption';
import { TrainingCourseAddToCartRequest } from '@/types/generated/Api/trainingCourseAddToCartRequest';
import { TrainingLocationOption } from '@/types/enum/trainingLocationOption';
import { TrainingLocationViewModel } from '@/types/models/training/trainingLocationViewModel';
import { TrainingMethod } from '@/types/enum/trainingMethod';
import { TrainingParticipantForm as TrainingParticipantFormModel } from '@/types/models/form/trainingParticipantForm';
import { TrainingProductAddToCartRequest } from '@/types/generated/Api/trainingProductAddToCartRequest';
import { TrainingProductGroupConfigurationViewModel } from '@/types/generated/Training/trainingProductGroupConfigurationViewModel';
import { TrainingViewModel } from '@/types/generated/Training/trainingViewModel';
import { concat, isNumber } from 'lodash';
import { defineStore } from 'pinia';

interface TrainingProductState {
	trainingViewModel: TrainingViewModel | null;
	trainingProductForm: TrainingProductAddToCartRequest;
	isInitializing: boolean;

	availableTrainingLocationOptions: IDropdownItem[];
	availableTrainingMethods: IDropdownItem[];
	availableTrainingLocations: IDropdownItem[];
	availableTrainingConfigurationOptions: IDropdownItem[];
	availableScheduledTrainings: IDropdownItem[];

	selectedTrainingCourse: TrainingViewModel | null;
	selectedTrainingMethod: TrainingMethod | null;
	selectedTrainingConfigurationOption: TrainingConfigurationOption | null;
	selectedTrainingLocationOption: TrainingLocationOption | null;
	selectedTrainingLocation: TrainingLocationViewModel | null;
	selectedScheduledTraining: ScheduledTrainingViewModel | null;
	trainingAddress: AddressFormModel | null;
	trainingParticipants: TrainingParticipantFormModel[];
}

export const useTrainingProductStore = defineStore('trainingProduct', {
	state: () =>
		({
			trainingViewModel: null,
			trainingProductForm: {} as TrainingProductAddToCartRequest,
			isInitializing: false,
			availableTrainingLocationOptions: [],
			availableTrainingMethods: [],
			availableTrainingLocations: [],
			availableTrainingConfigurationOptions: [],
			availableScheduledTrainings: [],
			selectedTrainingCourse: null,
			selectedTrainingMethod: null,
			selectedTrainingConfigurationOption: null,
			selectedTrainingLocationOption: null,
			selectedTrainingLocation: null,
			selectedScheduledTraining: null,
			selectedPreferredStartDate: null,
			trainingAddress: null,
			trainingParticipants: [],
		}) as TrainingProductState,
	getters: {
		trainingCourseById() {
			return (id: string) =>
				this.trainingProductForm.allCourses.find((x) => x?.id === id) as TrainingCourseAddToCartRequest;
		},
		allTrainingCourses(): TrainingViewModel[] {
			const trainingProductFormCourses = concat(
				this.trainingProductForm.course,
				this.trainingProductForm.additionalCourses
			);
			const mappedCourses = trainingProductFormCourses.map((course) => {
				if (course?.id == this.trainingViewModel?.cadacIdentifier) {									
					return this.trainingViewModel
				}
				return (<any>this.trainingViewModel)?.relatedProductModules?.find(
					(x) => x.cadacIdentifier === course?.id
				) as TrainingViewModel;
			});
			const filteredCourses = mappedCourses.filter(Boolean);

			return filteredCourses;
		},
		isAdditionalCourse(state: TrainingProductState) {
			return (id: string) => state.trainingProductForm.course?.id !== id;
		},
		isCourseSelected(state: TrainingProductState) {
			return (id: string) => !!state.trainingProductForm.allCourses?.find((x) => x.id === id);
		},
		isModuleSelected() {
			return (courseId: string, moduleSku: string) =>
				this.trainingCourseById(courseId)?.selectedModuleSkus?.includes(moduleSku);
		},
		canChooseLocation(): boolean {
			return (
				this.selectedTrainingMethod !== TrainingMethod.elearning &&
				!!this.availableTrainingLocationOptions?.length
			);
		},
		canAddParticipants(): boolean {
			return this.trainingParticipants?.length < this.maxParticipants;
		},
		maxParticipants(state: TrainingProductState): number {
			return (
				state.selectedScheduledTraining?.availablePlaces ||
				state.selectedTrainingLocation?.maxParticipants ||
				8
			);
		},
		trainingTypeForCurrentConfiguration(): LocalTrainingType {
			switch (this.selectedTrainingMethod) {
				case TrainingMethod.inCompany:
					if (
						this.selectedTrainingLocationOption === TrainingLocationOption.OnlineRemote ||
						this.selectedTrainingLocationOption === TrainingLocationOption.OwnLocation
					) {
						return LocalTrainingType.companyTrainingRemote;
					} else {
						return LocalTrainingType.companyTrainingLocal;
					}
				case TrainingMethod.inCompanyModular:
					return LocalTrainingType.modular;
				case TrainingMethod.training:
					return LocalTrainingType.openRoster;
				case TrainingMethod.elearning:
					return LocalTrainingType.eLearning;
				default:
					return LocalTrainingType.companyTrainingLocal;
			}
		},
		getAvailableTrainingLocationOptions(state: TrainingProductState): IDropdownItem[] {
			if (state.selectedTrainingMethod === TrainingMethod.training) {
				return state.availableTrainingLocationOptions.filter(
					(location) => location.value === TrainingLocationOption.Cadac
				);
			} else {
				return state.availableTrainingLocationOptions;
			}
		},
	},
	actions: {
		initProductPage(trainingProductViewModel: TrainingViewModel): void {
			this.trainingViewModel = trainingProductViewModel;
			if (!this.trainingViewModel) return;
			this.trainingProductForm = {
				oldLineItemIds: [],
				trainingProductGroupId: '',
				isVdiTraining: false,
				course: { participants: [], selectedModuleSkus: [], id: '' },
				additionalCourses: [],
				allCourses: [],
				trainingListId: '',
				trainingLocationId: '',
				type: LocalTrainingType.companyTrainingLocal,
				address: null,
			};

			this.changeTrainingCourse(trainingProductViewModel);

			this.setupConfigurationOptions();
		},
		initPreviousConfiguration(
			trainingProductGroupConfiguration: TrainingProductGroupConfigurationViewModel
		): void {
			this.changeTrainingCourse(<TrainingViewModel>this.trainingViewModel);

			this.trainingProductForm = {
				oldLineItemIds: trainingProductGroupConfiguration.oldLineItemIds || [],
				trainingProductGroupId: trainingProductGroupConfiguration.course?.id,
				isVdiTraining: trainingProductGroupConfiguration.isVdiTraining,
				course: (trainingProductGroupConfiguration.course as TrainingCourseAddToCartRequest) || {},
				additionalCourses:
					(trainingProductGroupConfiguration.additionalCourses as TrainingCourseAddToCartRequest[]) ||
					[],
				allCourses: [],
				trainingListId: trainingProductGroupConfiguration.trainingListId,
				trainingLocationId: trainingProductGroupConfiguration.trainingLocationId,
				type: trainingProductGroupConfiguration.trainingType,
				address: trainingProductGroupConfiguration.address,
			};
			this.trainingProductForm.allCourses = [
				this.trainingProductForm.course,
				...this.trainingProductForm.additionalCourses,
			];
			switch (trainingProductGroupConfiguration.trainingType) {
				case LocalTrainingType.openRoster:
					this.selectedTrainingLocationOption = TrainingLocationOption.Cadac;
					this.selectedScheduledTraining =
						this.selectedTrainingCourse?.scheduledTrainings?.find(
							(x) => x.id === trainingProductGroupConfiguration.trainingListId
						) || null;
					this.selectedTrainingMethod = TrainingMethod.training;
					break;
				case LocalTrainingType.companyTrainingLocal:
					this.selectedTrainingLocationOption = TrainingLocationOption.Cadac;
					this.selectedTrainingMethod = TrainingMethod.inCompany;
					break;
				case LocalTrainingType.companyTrainingRemote:
					this.selectedTrainingMethod = TrainingMethod.inCompany;
					this.selectedTrainingLocationOption = trainingProductGroupConfiguration.address
						? TrainingLocationOption.OwnLocation
						: TrainingLocationOption.OnlineRemote;
					break;
				case LocalTrainingType.eLearning:
					this.selectedTrainingMethod = TrainingMethod.elearning;
					this.selectedTrainingLocationOption = TrainingLocationOption.Cadac;
					break;
				case LocalTrainingType.modular:
					this.selectedTrainingMethod = TrainingMethod.inCompanyModular;
					if (trainingProductGroupConfiguration.address) {
						this.selectedTrainingLocationOption = TrainingLocationOption.OwnLocation;
					} else if (
						trainingProductGroupConfiguration.trainingLocationId ===
						this.selectedTrainingCourse?.remoteLocation?.id
					) {
						this.selectedTrainingLocationOption = TrainingLocationOption.OnlineRemote;
					} else if (trainingProductGroupConfiguration.trainingListId) {
						this.selectedTrainingLocationOption = TrainingLocationOption.Cadac;
						this.selectedScheduledTraining = this.selectedTrainingCourse?.scheduledTrainings?.find(
							(x) => x.id === trainingProductGroupConfiguration.trainingListId
						) as ScheduledTrainingViewModel;
					} else {
						this.selectedTrainingLocationOption = TrainingLocationOption.Cadac;
						this.selectedTrainingLocation = this.availableTrainingLocations.find(
							(x) =>
								(x.value as TrainingLocationViewModel).id ===
								trainingProductGroupConfiguration.trainingLocationId
						)?.value as TrainingLocationViewModel;
					}
					break;
			}
			if (trainingProductGroupConfiguration.participants)
				this.trainingParticipants = trainingProductGroupConfiguration.participants;
			if (trainingProductGroupConfiguration.address)
				this.trainingAddress = trainingProductGroupConfiguration.address;
			if (trainingProductGroupConfiguration.desiredDate) {
				this.trainingProductForm.desiredDate = trainingProductGroupConfiguration.desiredDate;
			}
			this.setupConfigurationOptions();
		},
		setupConfigurationOptions(): void {
			if (
				this.selectedTrainingLocationOption === null ||
				this.selectedTrainingLocationOption === TrainingLocationOption.None ||
				this.selectedTrainingLocationOption === TrainingLocationOption.Unknown ||
				this.availableTrainingLocationOptions.findIndex(
					(x) => x.value === this.selectedTrainingLocationOption
				) < 0
			) {
				this.selectedTrainingLocationOption = this.availableTrainingLocationOptions[0]
					?.value as TrainingLocationOption;
			}

			if (this.selectedTrainingMethod === null) {
				this.selectedTrainingMethod = this.availableTrainingMethods[0]?.value as TrainingMethod;
			} else if (
				this.availableTrainingMethods.findIndex((x) => x.value === this.selectedTrainingMethod) < 0
			) {
				this.selectedTrainingMethod = this.availableTrainingMethods[0]?.value as TrainingMethod;
			}
			if (
				this.selectedTrainingCourse?.modules?.length &&
				this.selectedTrainingMethod === TrainingMethod.inCompanyModular
			) {
				this.selectedTrainingConfigurationOption = TrainingConfigurationOption.Custom;
			} else if (
				this.selectedTrainingCourse?.modules?.length &&
				this.selectedTrainingMethod === TrainingMethod.training
			) {
				this.availableTrainingConfigurationOptions = [
					{ value: 0, label: i18n.global.t(`enum.trainingConfiguration.0`) },
				];
				this.selectedTrainingConfigurationOption = TrainingConfigurationOption.Default;
			} else {
				this.availableTrainingConfigurationOptions = [];
				this.selectedTrainingConfigurationOption = TrainingConfigurationOption.Default;
			}

			switch (this.selectedTrainingMethod) {
				case TrainingMethod.training:
					this.availableTrainingLocations =
						this.selectedTrainingCourse?.scheduledTrainingLocations?.map((x) => ({
							value: x,
							label: x.name,
						})) || [];
					if (this.availableTrainingLocations[0]) {
						this.selectedTrainingLocation = this.availableTrainingLocations[0]
							.value as TrainingLocationViewModel;
						if (this.selectedTrainingCourse?.scheduledTrainings[0]) {
							this.selectedScheduledTraining = this.selectedTrainingCourse?.scheduledTrainings[0];
							this.availableScheduledTrainings =
								this.selectedTrainingCourse?.scheduledTrainings
									?.filter((x) => x.location.id === this.selectedTrainingLocation?.id)
									.map((x) => ({ label: x.datesString, value: x })) || [];
						}
					}
					break;
				case TrainingMethod.inCompany:
				case TrainingMethod.inCompanyModular:
					this.availableScheduledTrainings = [];
					this.availableTrainingLocations =
						this.selectedTrainingCourse?.cadacLocations?.map((x) => ({
							value: x,
							label: x.name,
						})) || [];
					if (this.availableTrainingLocations[0])
						this.selectedTrainingLocation = this.availableTrainingLocations[0]
							.value as TrainingLocationViewModel;
					break;
				case TrainingMethod.elearning:
					this.availableTrainingConfigurationOptions = [];
					this.availableScheduledTrainings = [];
					break;
				default:
					this.availableTrainingConfigurationOptions = [];
					break;
			}
		},
		setSelectedTrainingType(trainingType: LocalTrainingType): void {
			this.trainingProductForm.type = trainingType;
		},
		changeTrainingLocationOption(locationOption: TrainingLocationOption) {
			if (locationOption === TrainingLocationOption.OnlineRemote && this.selectedTrainingCourse) {
				this.selectedTrainingLocation = this.selectedTrainingCourse.remoteLocation;
			} else {
				this.selectedTrainingLocation = this.availableTrainingLocations[0]
					?.value as TrainingLocationViewModel;
			}
			if (locationOption === TrainingLocationOption.OwnLocation) {
				this.trainingAddress = {
					countryCode: '',
					city: '',
					postalCode: '',
					street: '',
				} as AddressFormModel;

				this.selectedTrainingLocation = null;
			} else {
				this.trainingProductForm.isVdiTraining = false;
				this.trainingAddress = null;
			}
		},
		changeTrainingCourse(trainingCourse: TrainingViewModel, isExistingConfiguration = false): void {
			this.selectedTrainingCourse = trainingCourse;

			this.availableTrainingMethods =
				this.selectedTrainingCourse?.methods?.map((x) => ({
					value: x,
					label: i18n.global.t(`enum.trainingMethod.${x}`),
				})) || [];

			if (
				this.selectedTrainingCourse?.modules?.length &&
				this.selectedTrainingCourse?.methods.includes(TrainingMethod.inCompany)
			) {
				this.availableTrainingMethods.push({
					value: TrainingMethod.inCompanyModular,
					label: i18n.global.t(`enum.trainingMethod.${TrainingMethod.inCompanyModular}`),
				});
			}

			if (
				!isExistingConfiguration &&
				this.availableTrainingMethods.find((x) => x.value === TrainingMethod.training)
			) {
				this.selectedTrainingMethod = TrainingMethod.training;
			}

			this.availableTrainingLocationOptions =
				this.selectedTrainingCourse?.availableTrainingLocationOptions?.sort()?.map((x) => ({
					value: x,
					label: i18n.global.t(`enum.trainingLocation.${x}`),
				})) || [];

			this.trainingProductForm.course = {
				id: trainingCourse?.cadacIdentifier,
				selectedModuleSkus: trainingCourse?.modules.map((x) => x.sku),
				participants: this.trainingProductForm.course?.participants || [],
			} as TrainingCourseAddToCartRequest;

			if (this.isInitializing) {
				this.trainingProductForm.additionalCourses = [];
				this.trainingProductForm.allCourses = [this.trainingProductForm.course];
			}
		},
		changeTrainingLocation(trainingLocation: TrainingLocationViewModel): void {
			this.selectedTrainingLocation = trainingLocation;
			if (this.selectedTrainingMethod === TrainingMethod.training) {
				const scheduledTrainingsForLocation =
					this.selectedTrainingCourse?.scheduledTrainings?.filter(
						(x) => x.location.id === this.selectedTrainingLocation?.id
					) || [];
				this.availableScheduledTrainings = scheduledTrainingsForLocation.map((x) => ({
					label: x.datesString,
					value: x,
				}));
				this.selectedScheduledTraining = scheduledTrainingsForLocation[0];
			} else {
				this.availableScheduledTrainings = [];
			}
		},
		addAdditionalCourse(trainingCourse: TrainingCourseAddToCartRequest): void {
			this.trainingProductForm.additionalCourses.push(trainingCourse);
			this.trainingProductForm.allCourses.push(trainingCourse);
		},
		updateCourse(trainingCourse: TrainingCourseAddToCartRequest): void {
			if (this.isAdditionalCourse(trainingCourse?.id)) {
				const index = this.trainingProductForm.additionalCourses?.findIndex(
					(x) => x?.id === trainingCourse.id
				);
				if (isNumber(index) && index > -1 && this.trainingProductForm)
					this.trainingProductForm.additionalCourses[index] = trainingCourse;
			} else {
				this.trainingProductForm.course = trainingCourse;
			}
		},
		removeCourse(trainingCourseId: string): void {
			const index = this.trainingProductForm.additionalCourses?.findIndex((x) => x?.id === trainingCourseId);
			if (isNumber(index) && index > -1) {
				this.trainingProductForm.additionalCourses.splice(index, 1);
			}
			const index2 = this.trainingProductForm.allCourses?.findIndex((x) => x?.id === trainingCourseId);
			if (isNumber(index) && index2 > -1) {
				this.trainingProductForm.allCourses.splice(index2, 1);
			}
		},
		resetCourse(): void {
			if (this.isInitializing) return;
			if (this.trainingProductForm && this.selectedTrainingCourse) {
				this.trainingProductForm.additionalCourses = [];
				this.trainingProductForm.course.selectedModuleSkus = this.selectedTrainingCourse?.modules.map(
					(x) => x.sku
				);
			}
		},
	},
});
