<template>
	<div class="product-content-section pl-2" :class="bgColor">
		<slot />
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";

export type ProductPageContentSectionType = "upsell" | "content" | "media";

@Component
export default class ProductPageContentSection extends BaseComponent {
	@Prop({
		type: String as PropType<ProductPageContentSectionType>,
		required: false,
		default: "",
	})
	type!: ProductPageContentSectionType;

	get bgColor() {
		switch (this.type) {
			case "upsell":
				return { "surface-e": true };
			case "content":
				return { "surface-b": true };
			case "media":
				return { "surface-a": true };
			default:
				return "";
		}
	}
}
</script>

<style scoped lang="scss">
.product-content-section {
	position: relative;
	padding: 2rem 2rem 2rem 1rem;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 100%;
		width: 200%;
		height: 100%;
		background: inherit;
	}

	:deep(img) {
		max-width: 100%;
	}

	::v-deep(.block) {
		background: inherit !important;
	}
}
</style>
