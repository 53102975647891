/* Auto Generated */

export enum ExpertCalculationLineType {
	fullDay,
	dayPart,
	hour,
	travel,
	accommodation,
	flexBooking,
}
