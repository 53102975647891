<template>
	<div class="slide-sidebar" :class="{ collapsed: !isVisible }">
		<div class="slide-sidebar-wrapper">
			<div class="slide-sidebar-header">
				<slot name="header" />
				<Button
					class="p-button-secondary p-button-text p-button-rounded"
					icon="pi pi-times"
					@click="close()"
				/>
			</div>

			<div class="slide-sidebar-content">
				<slot name="content" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Emit, VModel } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component
export default class SlideSidebar extends BaseComponent {
	@VModel({ type: Boolean, default: false }) isVisible!: boolean;

	@Emit()
	close(): boolean {
		return !this.isVisible;
	}
}
</script>

<style lang="scss" scoped>
.slide-sidebar {
	width: clamp(15rem, 20vw, 20rem);
	padding: 1.25rem 1rem;
	transition: width 0.5s;
	flex-shrink: 0;
	background: var(--surface-b);

	&.collapsed {
		width: 0;
		padding: 0;

		.slide-sidebar-wrapper {
			opacity: 0;
			transition: opacity 0s;
		}
	}

	.slide-sidebar-wrapper {
		position: sticky;
		opacity: 1;
		transition: opacity 0.95s;
		top: calc(var(--navbarHeightInPx) + 1rem);
	}

	.slide-sidebar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 0.75rem;
		margin-bottom: 1.5rem;
	}
}
</style>
