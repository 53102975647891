import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67234a43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "d-flex flex-column flex-1 p-2" }
const _hoisted_5 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", _ctx.blockBackgroundClass(_ctx.vm.backgroundColor)])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.vm.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass(["display-4 mb-1", _ctx.textAlignmentClass(_ctx.vm.titleAlignment)])
          }, _toDisplayString(_ctx.vm.title), 3))
        : _createCommentVNode("", true),
      (_ctx.vm.subtitle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass(["lead mb-3", _ctx.textAlignmentClass(_ctx.vm.titleAlignment)])
          }, _toDisplayString(_ctx.vm.subtitle), 3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `recentContentItem_${index}`,
            class: "col-12"
          }, [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _withDirectives(_createElementVNode("img", {
                  alt: item.image?.alt,
                  class: ""
                }, null, 8, _hoisted_3), [
                  [_directive_lazy, item.image?.url]
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (item.title)
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(item.title), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", {
                    class: "intro-text text-muted",
                    innerHTML: item.intro
                  }, null, 8, _hoisted_6),
                  _createElementVNode("div", _hoisted_7, [
                    (item.pageUrl)
                      ? (_openBlock(), _createBlock(_component_LinkButton, {
                          key: 0,
                          class: "p-button-link p-button-arrow mt-2",
                          label: _ctx.vm.buttonText,
                          title: _ctx.vm.buttonText,
                          url: item.pageUrl
                        }, null, 8, ["label", "title", "url"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      (_ctx.vm.cta)
        ? (_openBlock(), _createBlock(_component_LinkButton, {
            key: 2,
            class: _normalizeClass(_ctx.ctaButtonClass(_ctx.vm.ctaStyle) || 'p-button-arrow p-button-raised p-button-text'),
            label: _ctx.vm.cta.text,
            target: _ctx.vm.cta.target,
            title: _ctx.vm.cta.title,
            url: _ctx.vm.cta.url
          }, null, 8, ["class", "label", "target", "title", "url"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}