import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67f3ec1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mycadac-banner" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "d-flex align-items-center flex-1 justify-content-center p-2" }
const _hoisted_5 = { class: "lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.vm.imageUrl)
        ? _withDirectives((_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: _ctx.vm.imageAlt,
            class: "banner-image"
          }, null, 8, _hoisted_3)), [
            [_directive_lazy, _ctx.vm.imageUrl]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.vm.text), 1),
        (_ctx.vm.cta)
          ? (_openBlock(), _createBlock(_component_LinkButton, {
              key: 0,
              class: "p-button-link p-button-primary text-primary ml-auto flex-shrink-0",
              label: _ctx.vm.cta.text,
              target: _ctx.vm.cta.target,
              title: _ctx.vm.cta.title,
              url: _ctx.vm.cta.url
            }, null, 8, ["label", "target", "title", "url"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}