import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24869c57"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center mt-2 mb-2"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = { class: "d-flex flex-column justify-content-between" }
const _hoisted_4 = { class: "lead text-muted mb-0" }
const _hoisted_5 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TrainingDetailSummary = _resolveComponent("TrainingDetailSummary")!
  const _component_TrainingParticipantsTable = _resolveComponent("TrainingParticipantsTable")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BackButton, {
      label: _ctx.$t('myCadac.trainingDetail.button.back')
    }, null, 8, ["label"]),
    (_ctx.vm.training)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("img", {
            alt: _ctx.vm.training.productIconAlt,
            class: "icon-training-item mr-2"
          }, null, 8, _hoisted_2), [
            [_directive_lazy, _ctx.vm.training.productIconUrl]
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.vm.training.brand), 1),
            _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.vm.training.name), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_TrainingDetailSummary, {
      class: "mb-2",
      "is-instructor": _ctx.vm.isInstructor,
      vm: _ctx.vm.training
    }, {
      default: _withCtx(() => [
        (_ctx.vm.isParticipant && _ctx.vm.isVdiTraining)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "p-button-success",
              icon: "pi pi-play",
              label: _ctx.$t('myCadac.trainingDetail.button.startTeams'),
              title: _ctx.$t('myCadac.trainingDetail.button.startTeams_title'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startTraining()))
            }, null, 8, ["label", "title"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-instructor", "vm"]),
    _createVNode(_component_TrainingParticipantsTable, { vm: _ctx.vm }, null, 8, ["vm"])
  ], 64))
}