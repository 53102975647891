<template>
	<div class="support-summary">
		<Card>
			<template #title>
				{{ $t("common.support.anyQuestions") }}
			</template>
			<template #content>
				<div class="support-text">
					<h5 v-if="supportPageUrl" class="text-bold">
						<i18n-t keypath="common.support.visitOurSupportPage">
							<a :href="supportPageUrl">
								{{ $t("common.support.visitOurSupportPage_link") }}
							</a>
						</i18n-t>
					</h5>
					<small class="mb-1">
						<i18n-t
							:keypath="
								supportPageUrl ? 'common.support.orContactUsAt' : 'common.support.contactUsAt'
							"
						>
							<a :href="'tel:' + $t('common.support.phoneNumber')">
								{{ $t("common.support.phoneNumber") }}
							</a>
						</i18n-t>
					</small>
					<small class="d-block">{{ $t("common.support.officeHours") }}</small>
				</div>

				<img v-lazy="'/Static/images/support.png'" alt="Cadac Group" class="support-img" />
			</template>
		</Card>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component
export default class SupportCard extends BaseComponent {
	@Prop({ required: false, default: "" }) supportPageUrl!: string;
}
</script>

<style lang="scss" scoped>
:deep(.p-card) {
	.p-card-body {
		position: relative;
	}

	.support-img {
		position: absolute;
		bottom: 0;
		right: 0.5rem;
		width: 135px;
	}

	.support-text {
		padding-right: 135px;
	}
}
</style>
