export enum ExpertBookingStatus {
	Requested = 0,
	Planned = 1,
	DeclineRequested = 2,
	Declined = 3,
	Rejected = 4,
	Stopped = 5,
	//Done, // Status and it's usage is retired. Don't use value!
	InProgress = 7,
	Cancelled = 8,
	Completed = 9,
	CancellationRequested = 10,
	AwaitingPayment = 11,
	Denied = 12,
}
