import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23395e9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-wrapper" }
const _hoisted_2 = { class: "circular-icon-wrapper" }
const _hoisted_3 = {
  ref: "progressCircle",
  class: "circular-progress"
}
const _hoisted_4 = {
  ref: "innerCircle",
  class: "inner-circle"
}
const _hoisted_5 = ["alt", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512),
      _createElementVNode("div", _hoisted_4, null, 512),
      (_ctx.item.iconUrl || _ctx.item.icon.url)
        ? _withDirectives((_openBlock(), _createElementBlock("img", {
            key: 0,
            ref: "icon",
            alt: _ctx.item.iconAlt || _ctx.item.icon.alt,
            class: "circular-icon",
            title: _ctx.item.name || _ctx.item.cashbackText
          }, null, 8, _hoisted_5)), [
            [_directive_lazy, _ctx.item.iconUrl || _ctx.item.icon.url]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}