import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 mb-5"
}
const _hoisted_3 = { class: "d-flex flex-column w-100 contract-table-panel" }
const _hoisted_4 = { class: "mb-1" }
const _hoisted_5 = { class: "col-12 mb-5" }
const _hoisted_6 = { class: "d-flex justify-content-between w-100 contract-table-panel" }
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = { class: "mb-1" }
const _hoisted_9 = { class: "col-12 mb-3" }
const _hoisted_10 = { class: "d-flex justify-content-between w-100 contract-table-panel" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractOverviewTable = _resolveComponent("ContractOverviewTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SubscriptionOverviewTable = _resolveComponent("SubscriptionOverviewTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasSupportAssets)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("myCadac.contractOverview.supportContracts.title")), 1)
          ]),
          _createVNode(_component_ContractOverviewTable, {
            contracts: _ctx.supportContracts,
            type: _ctx.contractTypeEnum.support,
            vm: _ctx.vm,
            onCancelContract: _ctx.confirmCancelContract
          }, null, 8, ["contracts", "type", "vm", "onCancelContract"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("myCadac.contractOverview.contracts.title")), 1)
        ]),
        (_ctx.vm.licenceManagementOverviewPageUrl)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('myCadac.contractOverview.button.manageLicences'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmManageLicences()))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ContractOverviewTable, {
        contracts: _ctx.softwareContracts,
        type: _ctx.contractTypeEnum.software,
        vm: _ctx.vm,
        onCancelContract: ($event: any) => ([])
      }, null, 8, ["contracts", "type", "vm"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t("myCadac.contractOverview.subscriptions.title")), 1)
        ])
      ]),
      _createVNode(_component_SubscriptionOverviewTable, {
        subscriptions: _ctx.autoDeskSubscriptions,
        vm: _ctx.vm
      }, null, 8, ["subscriptions", "vm"])
    ])
  ]))
}