<template>
	<ProductOverviewLayout :vm="overviewVm"
						   @on-filter-change="onFilterChange"
						   @on-search="onSearch"
						   @on-sort-change="onSortChange">
		<template #header>
			<div class="pt-3 pb-2">
				<slot name="breadcrumbs" />
				<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
			</div>
		</template>
		<template #grid-item="{ data }">
			<div v-if="!isSpinnerVisible('product-overview')" class="col-md-6 col-xxl-4 mb-2">
				<ServiceOverviewItemCard :compact="isSmallScreen" :item="data" layout="grid" />
			</div>
			<div v-else class="col-md-6 col-xxl-4">
				<ServiceOverviewItemCardSkeleton type="list" />
			</div>
		</template>
		<template #list-item="{ data }">
			<ServiceOverviewItemCard v-if="!isSpinnerVisible('product-overview')" :item="data" layout="list" />
			<ServiceOverviewItemCardSkeleton v-else type="list" />
		</template>
	</ProductOverviewLayout>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { IServiceOverview } from "@/types/viewModels/myCadac/serviceOverviewViewModel";
import { PropType } from "vue";
import ServiceOverviewItemCardSkeleton from "@/components/service/serviceOverviewItemCardSkeleton.vue";
import ServiceOverviewItemCard from "@/components/service/serviceOverviewItemCard.vue";
import ProductOverviewLayout, { IProductOverviewLayout } from "@/views/layout/productOverviewLayout.vue";
import { ItemProductType } from "@/types/enum/itemProductType";


    @Component({
        components: {
			ProductOverviewLayout,
			ServiceOverviewItemCard,
            ServiceOverviewItemCardSkeleton
        },
    })
export default class ServiceOverview extends BaseComponent {
	@Prop({ type: Object as PropType<IServiceOverview>, required: true, default: {} })
	vm!: IServiceOverview;

	showArchived: { [title: string]: boolean } = {};
	isActivating: { [number: string]: boolean } = {};
	hasActivated: { [number: string]: boolean } = {};

	isLoading = false;
	hasError = false;

    created(): void {
        console.log(this.vm)
	}

    get overviewVm(): IProductOverviewLayout {
        return { productType: ItemProductType.Service, items: this.vm.overviewItems, defaultViewLayout: "list" };
    }


}
</script>

<style lang="scss" scoped>

</style>
