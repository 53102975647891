<template>
	<div class="product-config-summary">
		<Panel v-model:collapsed="panelCollapsed" :header="header">
			<template #icons>
				<Button
					class="p-button-text p-panel-header-icon"
					:icon="panelCollapsed ? 'pi pi-chevron-left' : 'pi pi-chevron-down'"
					@click="panelCollapsed = !panelCollapsed"
				/>
			</template>
			<dl>
				<slot />
			</dl>
		</Panel>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

@Component
export default class ProductConfigurationSummary extends BaseComponent {
	@Prop({ type: String, default: "", required: true }) header!: string;
	panelCollapsed = false;

	created(): void {
		this.panelCollapsed = this.breakpoints.isSmaller("xl");
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-panel) {
	.p-panel-header {
		padding: 0;
		border: none;
		background: inherit;
	}

	.p-panel-content {
		padding: 0;
		background: inherit;
	}
}

.product-config-summary {
	background: var(--surface-e);
	border: 1px solid var(--primary-color);
	border-radius: var(--border-radius);
	counter-reset: product-summary-options-count;
	padding: 0.25rem 0.75rem;
}
</style>
