<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:closable="true"
		:header="$t('expert.completeBookingModal.header')"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<p class="lead text-bold mb-0">{{ $t("expert.completeBookingModal.introTitle") }}</p>
		<p class="lead mb-2">
			{{ $t("expert.completeBookingModal.intro") }}
		</p>

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :label="$t('common.confirm')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import { Component, Emit } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { useExpertStore } from "@/store/expert/expertStore";

@Component
export default class ExpertCompleteBookingModal extends BaseComponent {
	@Emit("submit")
	submitForm(): boolean {
		return true;
	}

	submitted = false;
	isVisible = true;

	expertStore = useExpertStore();

	onCancel(): void {
		this.expertStore.hideModal("CompleteBooking");
	}

	onSubmit(): void {
		this.submitForm();
	}
}
</script>

<style scoped lang="scss"></style>
