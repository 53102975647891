import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 1,
  class: "d-flex align-items-center"
}
const _hoisted_8 = ["title"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.pageTitle)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.vm.pageTitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.tableData.length)
          ? (_openBlock(), _createBlock(_component_DataTable, {
              key: 0,
              "auto-layout": "",
              class: "mt-2",
              paginator: _ctx.showPagination,
              "removable-sort": "",
              "responsive-layout": "stack",
              "row-class": _ctx.getRowClass,
              rows: _ctx.maxRowsToShow,
              value: _ctx.tableData
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  key: "orderNumber",
                  field: "orderNumber",
                  header: _ctx.$t('myCadac.orderOverview.table.colOrderNumber'),
                  sortable: false
                }, {
                  body: _withCtx((item) => [
                    (_ctx.canGenerateInvoice(item.data))
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: "text-bold",
                          href: item.data.urlToGenerateInvoice
                        }, _toDisplayString(item.data.orderNumber), 9, _hoisted_4))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.data.orderNumber), 1))
                  ]),
                  _: 1
                }, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "customerName",
                  field: "customerName",
                  header: _ctx.$t('myCadac.orderOverview.table.colOrganisation'),
                  sortable: false
                }, null, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "invoiceDateTime",
                  field: "invoiceDateTime",
                  header: _ctx.$t('myCadac.orderOverview.table.colInvoiceDate'),
                  sortable: true
                }, {
                  body: _withCtx((item) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.data.invoiceDateTime)), 1)
                  ]),
                  _: 1
                }, 8, ["header"]),
                _createVNode(_component_Column, {
                  key: "isPaid",
                  field: "isPaid",
                  header: _ctx.$t('myCadac.orderOverview.table.colPaid'),
                  sortable: true
                }, {
                  body: _withCtx((item) => [
                    (_ctx.layoutStore.vm.isImpersonatedByCadacSales && item.data.paymentMethod)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "d-flex align-items-center",
                          title: 'Betaalmethode: ' + item.data.paymentMethod
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(
									item.data.isPaid
										? 'pi pi-check text-success mr-1'
										: 'pi pi-times text-danger mr-1'
								)
                          }, null, 2),
                          _createElementVNode("span", null, _toDisplayString(item.data.isPaid ? _ctx.$t("common.yes") : _ctx.$t("common.no")), 1)
                        ], 8, _hoisted_6))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("i", {
                            class: _normalizeClass(
									item.data.isPaid
										? 'pi pi-check text-success mr-1'
										: 'pi pi-times text-danger mr-1'
								)
                          }, null, 2),
                          _createElementVNode("span", null, _toDisplayString(item.data.isPaid ? _ctx.$t("common.yes") : _ctx.$t("common.no")), 1)
                        ]))
                  ]),
                  _: 1
                }, 8, ["header"]),
                (_ctx.showTotalColumn)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: "total",
                      field: "total",
                      header: _ctx.$t('myCadac.orderOverview.table.colTotalAmount'),
                      sortable: true
                    }, {
                      body: _withCtx((item) => [
                        (_ctx.layoutStore.vm.isImpersonatedByCadacSales && item.data.orderStatus)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              title: 'Orderstatus: ' + item.data.orderStatus
                            }, _toDisplayString(_ctx.formatCurrency(item.data.total)), 9, _hoisted_8))
                          : (!item.data.isInvoicedByPartner)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.formatCurrency(item.data.total)), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_10, "-"))
                      ]),
                      _: 1
                    }, 8, ["header"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_Column, {
                  key: "invoice",
                  header: _ctx.$t('myCadac.orderOverview.table.lblInvoice')
                }, {
                  body: _withCtx((item) => [
                    (_ctx.canSeeInvoiceForOrder(item.data))
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: "p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text",
                          icon: "pi pi-file-pdf",
                          onClick: ($event: any) => (_ctx.viewPdf(item.data.orderNumber))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (item.data.isInvoicedByPartner)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("myCadac.orderOverview.table.lblInvoicedByPartner")), 1))
                      : _createCommentVNode("", true),
                    (item.data.isCancelled)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("myCadac.orderOverview.table.lblCancelled")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["header"])
              ]),
              _: 1
            }, 8, ["paginator", "row-class", "rows", "value"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}