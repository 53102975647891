<template>
	<Card class="my-3">
		<template #title>
			<div class="d-flex justify-content-between">
				<h4 class="mb-0">
					{{ $t("myCadac.contractDetail.card.titleProlong") }}
				</h4>
				<Button
					v-if="canChangeContractDuration && !isSwitchingContract"
					class="p-button-primary"
					:label="$t('myCadac.contractDetail.button.changeDuration')"
					@click="showExtendOptions()"
				/>
			</div>
		</template>
		<template #content>
			<Message v-if="isChangingDuration" class="mt-0 mb-1" :closable="false" severity="warn">
				<i18n-t keypath="myCadac.contractDetail.message.newExpirationDateDurationChange">
					<template #date>
						<b>{{ newEndDate }}</b>
					</template>
				</i18n-t>
			</Message>
			<Message
				v-else-if="prolongableContractLines.length && selectedContractLines?.length"
				class="mt-0 mb-1"
				:closable="false"
				severity="info"
			>
				<i18n-t keypath="myCadac.contractDetail.message.newExpirationDate">
					<template #date>
						<b>{{ newEndDate }}</b>
					</template>
				</i18n-t>
			</Message>
			<DataTable
				v-if="visibleContractLines?.length"
				v-model:expanded-rows="expandedRows"
				v-model:selection="selectedContractLines"
				auto-layout
				class="mt-2"
				data-key="serialNumber"
				responsive-layout="stack"
				:row-class="rowClass"
				:value="visibleContractLines"
			>
				<Column
					body-style="width:2rem"
					class="text-lg-center"
					:header="$t('myCadac.contractDetail.table.colProductName')"
					selection-mode="multiple"
				/>
				<Column field="productName" :sortable="false">
					<template #body="item">
						<div class="d-flex align-items-center">
							<Avatar
								v-if="item.data.icon"
								:alt="item.data.iconAlt"
								class="mr-1 bg-transparent"
								:image="item.data.icon"
								shape="square"
								size="large"
								:title="item.productName"
							>
							</Avatar>
							<div class="d-flex flex-column">
								<small class="mb-0 text-muted d-block">
									{{ item.data.productBrand }}
								</small>
								<p
									v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
									class="mb-0 text-bold"
								>
									<a :href="item.data.crmUrl" target="_blank" :title="$t('common.goToCrm')">
										{{ formatProductName(item.data.productName, item.data.productBrand) }}
									</a>
								</p>
								<p v-else class="mb-0 text-bold">
									{{ formatProductName(item.data.productName, item.data.productBrand) }}
								</p>
								<small v-if="isSwitching(item.data)" class="text-success text-bold">
									{{ $t("myCadac.contractDetail.table.productTradeInMessage") }}
								</small>
								<small
									v-if="!isSwitching(item.data) && item.data.isSwitched"
									class="text-info text-bold"
								>
									{{ $t("myCadac.contractDetail.table.productSwitchedMessage") }}
								</small>
							</div>
						</div>
					</template>
				</Column>
				<Column
					field="deployment"
					:header="$t('myCadac.contractDetail.table.colProductLicenceType')"
					:sortable="false"
				>
					<template #body="item">
						{{ $t(`enum.deployment.${item.data.deployment}`) }}
					</template>
				</Column>
				<Column
					field="seats"
					:header="$t('myCadac.contractDetail.table.colProductSeats')"
					:sortable="false"
				/>

				<Column
					class="text-lg-right pr-lg-2"
					:header="$t('myCadac.contractDetail.table.colProductPrice')"
					:sortable="false"
				>
					<template #body="item">
						<small v-if="isRowDisabled(item.data)" class="text-danger text-bold">
							{{ $t("myCadac.contractDetail.table.productNotProlongableMessage") }}
						</small>
						<span v-else-if="!isSwitching(item.data) && isRowSelected(item.data)" class="text-right">
							<small v-if="item.data.priceBase !== item.data.price" class="d-block text-muted">
								<s>{{ formatCurrency(item.data.priceBase) }}</s>
							</small>
							<b>{{ formatCurrency(item.data.price) }}</b>
						</span>
					</template>
				</Column>
				<Column class="text-center" :sortable="false">
					<template #body="item">
						<Button
							v-if="canSwitch(item.data)"
							class="p-button-raised p-button-primary p-button-text p-button-sm"
							:label="$t('myCadac.contractDetail.button.tradeIn')"
							@click="showTradeInOptions(item.data)"
						/>

						<div v-else-if="isSwitching(item.data)">
							<div class="d-flex flex-column">
								<div class="d-flex flex-column">
									<Button
										class="p-button-link text-primary p-button-sm ml-1"
										:label="$t('common.cancel')"
										@click="removeTradeIn(item.data)"
									/>

									<Button
										class="p-button-raised p-button-primary p-button-text p-button-sm ml-1"
										:label="$t('common.change')"
										@click="showTradeInOptions(item.data)"
									/>
								</div>
							</div>
						</div>
					</template>
				</Column>
				<template #expansion="item">
					<div class="row-expansion-content">
						<p class="text-bold">
							{{ $tc("myCadac.contractDetail.titleLicenseTradeInOverview", isSwitchingContract) }}
						</p>
						<DataTable
							auto-layout
							data-key="serialNumber"
							responsive-layout="stack"
							:value="getSwitchingContractLine(item.data)"
						>
							<Column
								field="productName"
								:header="$t('myCadac.contractDetail.table.colLicenses')"
								:sortable="false"
							>
								<template #body="item">
									<div class="d-flex align-items-center ml-1 ml-lg-0">
										<Avatar
											v-if="getSwitchProduct(item.data).productIcon"
											:alt="getSwitchProduct(item.data).productName"
											class="mr-1 bg-transparent flex-shrink-0"
											:image="getSwitchProduct(item.data).productIcon"
											shape="square"
											size="large"
											:title="item.productName"
										>
										</Avatar>
										<div class="d-flex flex-column">
											<small class="mb-0 text-muted d-block">
												{{ getSwitchProduct(item.data).productBrand }}
											</small>
											<p class="mb-0 text-bold">
												{{
													formatProductName(
														getSwitchProduct(item.data).productName,
														getSwitchProduct(item.data).productBrand
													)
												}}
											</p>
										</div>
									</div>
								</template>
							</Column>
							<Column
								key="duration"
								field="duration"
								:header="$t('myCadac.contractDetail.table.colDuration')"
								:sortable="false"
							>
								<template #body="item">
									{{
										$tc(
											"myCadac.contractDetail.table.duration",
											item.data.selectedSwitchDuration
										)
									}}
								</template>
							</Column>
							<Column
								field="deployment"
								:header="$t('myCadac.contractDetail.table.colProductLicenceType')"
								:sortable="false"
							>
								<template #body="item">
									{{ $t(`enum.deployment.${item.data.deployment}`) }}
								</template>
							</Column>
							<Column
								field="selectedSwitchSeats"
								:header="$t('myCadac.contractDetail.table.colProductSeats')"
								:sortable="false"
							/>
							<Column
								body-class="text-right pr-2"
								:header="$t('myCadac.contractDetail.table.colProductPrice')"
								header-class="text-right pr-2"
								:sortable="false"
							>
								<template #body="item">
									<div class="text-right">
										<small
											v-if="item.data.priceBase !== item.data.price"
											class="d-block text-muted"
										>
											<s>{{ formatCurrency(item.data.priceBase) }}</s>
										</small>
										<b>{{ formatCurrency(item.data.price) }}</b>
									</div>
								</template>
							</Column>
						</DataTable>
					</div>
				</template>
			</DataTable>
		</template>
		<template #footer>
			<Message
				v-if="prolongableContractLines.length && !selectedContractLines?.length"
				:closable="false"
				severity="warn"
			>
				{{ $t("myCadac.contractDetail.message.cancelContract") }}
			</Message>
			<div class="col-md-10 col-lg-8 col-xl-6 ml-auto pr-2">
				<PriceSummary :vm="priceSummary" />
				<Button
					class="p-button-success p-button-raised p-button-lg d-block ml-auto"
					:disabled="!canCheckout"
					:label="$t('myCadac.contractDetail.button.goToCheckout')"
					:loading="loadingStore.isBusy"
					@click="onCheckoutClick()"
				/>
			</div>
		</template>
	</Card>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { ContractAddToCart } from "@/types/generated/Api/contractAddToCart";
import { ContractAddToCartLine } from "@/types/generated/Api/contractAddToCartLine";
import { ContractClient } from "@/types/generated/Api/contractClient";
import { ContractLineClient } from "@/types/generated/Api/contractLineClient";
import { ContractProlongationOptionType } from "@/types/enum/contractProlongationOptionType";
import { Deployment } from "@/types/enum/deployment";
import { IContractExtend } from "@/types/viewModels/myCadac/contractExtendViewModel";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { Log } from "@/types/helpers/logHelper";
import { ProductVariationClient } from "@/types/generated/Api/productVariationClient";
import { PropType } from "vue";
import { SwitchProductClient } from "@/types/generated/Api/switchProductClient";
import { addMonths } from "date-fns";
import { max, reduce, sortBy, unionBy } from "lodash";
import { useContractStore } from "@/store/software/contractStore";
import toFixed from "accounting-js/lib/toFixed.js";
import { ProductCategoryType } from "@/types/enum/productCategoryType";
import { GaEvent } from "@/types/models/googleAnalytics/enum/gaEvent";

@Component
export default class ContractDetailSwitchCard extends BaseComponent {
	@Prop({
		type: Object as PropType<IContractExtend>,
		required: true,
		default: {},
	})
	vm!: IContractExtend;

	contractStore = useContractStore();

	expandedRows: ContractLineClient[] = [];
	selectedContractLines: ContractLineClient[] = [];
	editingContractLines: ContractLineClient[] = [];
	visibleContractLines: ContractLineClient[] = [];

	get prolongableContractLines(): ContractLineClient[] {
		return this.vm.contract.contractLines.filter((x) => x.canProlong);
	}

	get nonProlongableContractLines(): ContractLineClient[] {
		return this.vm.contract.contractLines.filter((x) => !x.canProlong);
	}

	get mergedSwitchingAndSelectedContractLines(): ContractLineClient[] {
		return unionBy(this.contractStore.switchingContractLines, this.selectedContractLines, "serialNumber");
	}

	get newContractDuration(): number {
		return this.editingContractLines.length === 1
			? max(this.contractStore.switchingContractLines.map((x) => x.selectedSwitchDuration)) ||
					this.vm.contract.duration
			: this.vm.contract.duration;
	}

	get priceSummary(): IPriceSummary {
		const { vatPercentage } = this.vm.contract;
		return reduce(
			this.editingContractLines,
			(result, contractLine) => {
				const vatAmount = contractLine.price * (vatPercentage / 100);
				const totalPrice = contractLine.price + vatAmount;
				result.vatAmount = result.vatAmount ? result.vatAmount + vatAmount : vatAmount;
				result.totalDiscount = result.totalDiscount
					? result.totalDiscount + contractLine.discount
					: contractLine.discount;
				result.totalPersonalPrice = result.totalPersonalPrice
					? result.totalPersonalPrice + contractLine.price
					: contractLine.price;
				result.totalBasePrice = result.totalBasePrice
					? result.totalBasePrice + contractLine.priceBase
					: contractLine.priceBase;
				result.totalPrice = result.totalPrice ? result.totalPrice + totalPrice : totalPrice;
				return result;
			},
			{ vatPercentage: vatPercentage } as IPriceSummary
		);
	}

	get contractUpdateData(): ContractAddToCart {
		return {
			contractId: this.vm.contract.contractId,
			contractLines: reduce(
				this.editingContractLines,
				(result, value) => {
					result.push({
						checked: value.checked,
						addSeatVariationCode: value.addSeatVariationCode,
						renewVariationCode: value.renewVariationCode,
						serialNumber: value.serialNumber,
						seats: value.seats,
						switchDuration: value.selectedSwitchDuration,
						switchRenewVariationCode: value.selectedSwitchSku,
						switchSeats: value.selectedSwitchSeats,
					});
					return result;
				},
				[] as ContractAddToCartLine[]
			),
		};
	}

	get newEndDate(): string {
		return this.isChangingDuration
			? this.formatDate(addMonths(this.toDate(this.vm.contract.endDate), this.newContractDuration))
			: this.formatDate(this.toDate(this.vm.contract.newProlongationDate));
	}

	get isChangingDuration(): boolean {
		return this.newContractDuration !== this.vm.contract.duration;
	}

	get canCheckout(): boolean {
		return (
			!!(this.userProfile?.isEmailVerified || this.userProfile?.isImpersonated) &&
			this.mergedSwitchingAndSelectedContractLines.length > 0
		);
	}

	get canChangeContractDuration(): boolean {
		return this.canCoTerm || this.canExtend;
	}

	get canCoTerm(): boolean {
		return this.contractStore.prolongationOptions.some(
			(x) => x.type === ContractProlongationOptionType.coterm
		);
	}

	get canExtend(): boolean {
		return (
			!!(this.userProfile?.isCadacSales || this.userProfile?.isImpersonated) &&
			this.contractStore.prolongationOptions.some((x) => x.type === ContractProlongationOptionType.extend)
		);
	}

	get isSwitchingContract(): boolean {
		return this.contractStore.switchingContractLines?.length > 0;
	}

	// On trade-in added / removed
	@Watch("contractStore.switchingContractLines", { deep: true })
	onContractSwitch(switchingContractLines: ContractLineClient[]): void {
		this.expandedRows = [...switchingContractLines];
		this.editingContractLines = this.mergedSwitchingAndSelectedContractLines;
		this.recalculatePrices();
	}

	@Watch("selectedContractLines", { deep: true })
	onContractLineSelectionChanged(): void {
		this.editingContractLines = this.mergedSwitchingAndSelectedContractLines;
	}

	created(): void {
		this.selectedContractLines = this.prolongableContractLines;
		this.editingContractLines = this.mergedSwitchingAndSelectedContractLines;
		this.visibleContractLines = sortBy(this.vm.contract.contractLines, "canProlong");

		const checkoutDataLayer = this.constructCheckoutData(0, "shopping_cart", 4);
		const eCommerceDataLayer = this.constructECommerceDataLayer(
			GaEvent.ViewCart,
			toFixed(this.priceSummary.totalPersonalPrice, 2) || null,
			this.editingContractLines.map((item) => ({
				id: item.renewVariationCode || item.addSeatVariationCode || "0000-0000-0000-0000",
				name: item.productName,
				productCategory: ProductCategoryType.Product,
				basePrice: item.priceBase,
				personalPrice: item.price,
				brand: item.productBrand,
				amount: item.seats,
			}))
		);
		this.setDataLayer.viewCart(eCommerceDataLayer, checkoutDataLayer);
	}

	getSwitchProduct(contractLine: ContractLineClient): SwitchProductClient {
		return (contractLine.switchProducts as SwitchProductClient[]).find(
			(product) =>
				(product.switchVariations as ProductVariationClient[]).findIndex(
					(variation) => variation.sku === contractLine.selectedSwitchSku
				) > -1
		) as SwitchProductClient;
	}

	getSwitchingContractLine(contractLine: ContractLineClient): ContractLineClient[] {
		return this.contractStore.switchingContractLines.filter(
			(x) => x.serialNumber === contractLine.serialNumber
		);
	}

	canSwitch(contractLine: ContractLineClient): boolean {
		return (
			contractLine.switchProducts.length > 0 &&
			!this.isSwitching(contractLine) &&
			(this.isPerpetualAndMultiUserContractLine(contractLine) || !this.vm.contract.isPerpetual)
		);
	}

	isPerpetualAndMultiUserContractLine(contractLine: ContractLineClient): boolean {
		return this.vm.contract.isPerpetual && contractLine.deployment == Deployment.multiUser;
	}

	isSwitching(contractLine: ContractLineClient): boolean {
		return (
			this.contractStore.switchingContractLines.findIndex(
				(x) => x.serialNumber === contractLine.serialNumber
			) > -1 || contractLine.serialNumber === this.contractStore.selectedTradeInContractLine?.serialNumber
		);
	}

	isRowExpanded(contract: ContractLineClient): boolean {
		return this.expandedRows.findIndex((x) => x.serialNumber === contract.serialNumber) > -1;
	}

	isRowDisabled(contract: ContractLineClient): boolean {
		return !contract.canProlong;
	}

	isRowSelected(contract: ContractLineClient): boolean {
		return this.selectedContractLines.findIndex((x) => x.serialNumber === contract.serialNumber) > -1;
	}

	rowClass(contract: ContractLineClient): string {
		return this.isRowDisabled(contract) && !this.canSwitch(contract) && !this.isSwitching(contract)
			? "disabled"
			: this.isRowDisabled(contract) && (this.canSwitch(contract) || this.isSwitching(contract))
				? "disabled-checkbox"
				: this.isRowExpanded(contract)
					? "highlight"
					: "";
	}

	showExtendOptions(): void {
		this.contractStore.showModal("Extend");
	}

	showTradeInOptions(contractLineClient: ContractLineClient): void {
		this.selectContractLine(contractLineClient);
		this.contractStore.startTradeIn(contractLineClient);
	}

	removeTradeIn(contractLine: ContractLineClient): void {
		this.contractStore.removeTradeIn(contractLine);
	}

	selectContractLine(contractLineClient: ContractLineClient): void {
		const originalContractLine = this.prolongableContractLines.find(
			(x) => x.serialNumber === contractLineClient.serialNumber
		);
		// Select the row if it was not selected yet
		if (
			originalContractLine &&
			this.selectedContractLines.findIndex((x) => x.serialNumber === contractLineClient.serialNumber) === -1
		) {
			this.selectedContractLines.push(originalContractLine);
			// Reassign the array to trigger view change.
			this.selectedContractLines = [...this.selectedContractLines];
		}
	}

	onCheckoutClick(): void {
		this.addContractProlongationToCart();
	}

	addContractProlongationToCart(): void {
		this.loadingStore.increaseLoadingCount();
		const data: ContractAddToCart = this.contractUpdateData;
		this.axios
			.post("/api/cart/add-prolongation", data)
			.then(() => {
				const checkoutDataLayer = this.constructCheckoutData(0, "begin_checkout", 4);
				const eCommerceDataLayer = this.constructECommerceDataLayer(
					GaEvent.BeginCheckout,
					toFixed(this.priceSummary.totalPersonalPrice, 2) || null,
					this.editingContractLines.map((item) => ({
						id: item.renewVariationCode || item.addSeatVariationCode || "0000-0000-0000-0000", // sku missing here in data
						name: item.productName,
						productCategory: ProductCategoryType.Product,
						basePrice: item.priceBase,
						personalPrice: item.price,
						brand: item.productBrand,
						amount: item.seats,
					}))
				);
				this.setDataLayer.beginCheckout(eCommerceDataLayer, checkoutDataLayer, {});

				setTimeout(() => {
					this.openUrl(this.vm.checkoutPageUrl);
				}, 100);
			})
			.catch((err) => Log.error(err))
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	recalculatePrices(): void {
		if (this.loadingStore.isBusy) return;
		this.loadingStore.increaseLoadingCount();
		const updateData: ContractAddToCart = this.contractUpdateData;
		this.axios
			.post("/api/contract/recalculate", updateData)
			.then(({ data }: { data: ContractClient }) => {
				this.editingContractLines = [...data.contractLines];
			})
			.catch((err) => Log.error(err))
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable .p-column-header-content) {
	.p-checkbox {
		display: none;
	}
}

::v-deep(.disabled-checkbox) {
	.p-selection-column {
		.p-checkbox {
			display: none !important;
		}
	}
}

::v-deep(.p-datatable-table) {
	.p-datatable-tbody {
		> tr.p-highlight {
			background: var(--surface-a);
		}

		> tr.highlight {
			background: var(--surface-c);

			.p-checkbox {
				pointer-events: none;
				filter: grayscale(100%);

				&:hover {
					cursor: not-allowed;
				}
			}
		}

		> tr.disabled {
			.p-checkbox {
				pointer-events: none;
				filter: grayscale(100%);

				&:hover {
					cursor: not-allowed;
				}
			}
		}
	}
}
</style>
