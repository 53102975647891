<template>
	<form v-if="isEditing" class="">
		<div class="form-row">
			<div class="form-group col-12 col-xl-6">
				<label :class="{ required: v$['expertSettingsOverviewForm'].expertFunction?.required }">
					{{ $t("myCadac.expertSettings.lblFunction") }}
				</label>
				<InputText
					v-model="v$['expertSettingsOverviewForm'].expertFunction.$model"
					:class="{ 'p-invalid': shouldShowError(v$['expertSettingsOverviewForm'].expertFunction) }"
					:readonly="!isEditing"
					type="text"
				/>
				<small v-if="shouldShowError(v$['expertSettingsOverviewForm'].expertFunction)" class="p-error">
					{{ v$["expertSettingsOverviewForm"].expertFunction?.required?.$message }}
				</small>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12 col-xl-6">
				<label :class="{ required: v$['expertSettingsOverviewForm'].shortDescriptionTitle?.required }">
					{{ $t("myCadac.expertSettings.lblHeader") }}
				</label>
				<InputText
					v-model="v$['expertSettingsOverviewForm'].shortDescriptionTitle.$model"
					:class="{
						'p-invalid': shouldShowError(v$['expertSettingsOverviewForm'].shortDescriptionTitle),
					}"
					:readonly="!isEditing"
					type="text"
				/>
				<small
					v-if="shouldShowError(v$['expertSettingsOverviewForm'].shortDescriptionTitle)"
					class="p-error"
				>
					{{ v$["expertSettingsOverviewForm"].shortDescriptionTitle?.required?.$message }}
				</small>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label :class="{ required: v$['expertSettingsOverviewForm'].shortDescription?.required }">
					{{ $t("myCadac.expertSettings.lblIntroduction") }}
				</label>
				<Textarea
					v-model="v$['expertSettingsOverviewForm'].shortDescription.$model"
					:auto-resize="true"
					:class="{ 'p-invalid': shouldShowError(v$['expertSettingsOverviewForm'].shortDescription) }"
					cols="30"
					:readonly="!isEditing"
					rows="5"
				/>
				<span v-if="shouldShowError(v$['expertSettingsOverviewForm'].shortDescription)">
					<small v-if="!v$['expertSettingsOverviewForm'].shortDescription.$model" class="p-error">
						{{ v$["expertSettingsOverviewForm"].shortDescription?.required?.$message }}
					</small>
					<small
						v-else-if="v$['expertSettingsOverviewForm'].shortDescription.minLength.$invalid"
						class="p-error"
					>
						{{ v$["expertSettingsOverviewForm"].shortDescription.minLength?.$message }}
					</small>
					<small
						v-else-if="v$['expertSettingsOverviewForm'].shortDescription.maxLength.$invalid"
						class="p-error"
					>
						{{ v$["expertSettingsOverviewForm"].shortDescription.maxLength?.$message }}
					</small>
				</span>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label :class="{ required: v$['expertSettingsOverviewForm'].description?.required }">
					{{ $t("common.forms.lblDescription") }}
				</label>
				<Textarea
					v-model="v$['expertSettingsOverviewForm'].description.$model"
					:auto-resize="true"
					:class="{ 'p-invalid': shouldShowError(v$['expertSettingsOverviewForm'].description) }"
					cols="30"
					:readonly="!isEditing"
					rows="5"
				/>
				<span v-if="shouldShowError(v$['expertSettingsOverviewForm'].description)">
					<small v-if="!v$['expertSettingsOverviewForm'].description.$model" class="p-error">
						{{ v$["expertSettingsOverviewForm"].description?.required?.$message }}
					</small>
					<small
						v-else-if="v$['expertSettingsOverviewForm'].description.minLength.$invalid"
						class="p-error"
					>
						{{ v$["expertSettingsOverviewForm"].description.minLength?.$message }}
					</small>
					<small
						v-else-if="v$['expertSettingsOverviewForm'].description.maxLength.$invalid"
						class="p-error"
					>
						{{ v$["expertSettingsOverviewForm"].description.maxLength?.$message }}
					</small>
				</span>
			</div>
		</div>
	</form>
	<div v-if="isEditing">
		<Button class="" :disabled="!canSubmit" :label="$t(`common.save`)" @click="onSubmit" />
	</div>
	<dl v-else>
		<dt>{{ $t("myCadac.expertSettings.lblFunction") }}</dt>
		<dd>{{ v$["expertSettingsOverviewForm"].expertFunction.$model || "-" }}</dd>
		<dt>{{ $t("myCadac.expertSettings.lblHeader") }}</dt>
		<dd>{{ v$["expertSettingsOverviewForm"].shortDescriptionTitle.$model || "-" }}</dd>
		<dt>{{ $t("myCadac.expertSettings.lblIntroduction") }}</dt>
		<dd class="text-preline" style="word-break: break-word">
			{{ v$["expertSettingsOverviewForm"].shortDescription.$model || "-" }}
		</dd>
		<dt>{{ $t("common.forms.lblDescription") }}</dt>
		<dd class="text-preline" style="word-break: break-word">
			{{ v$["expertSettingsOverviewForm"].description.$model || "-" }}
		</dd>
	</dl>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Emit, Prop, Watch } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import useVuelidate, { Validation } from "@vuelidate/core";
import { ExpertSettingsOverviewForm } from "@/types/models/form/expertSettingsOverviewForm";
import { maxLength, minLength, required } from "@/types/utils/i18n-validators";
import { ExpertProfileViewModel } from "@/types/models/expert/expertProfileViewModel";

@Component({
	components: {},
	options: {
		validations: {
			expertSettingsOverviewForm: {
				expertFunction: { required, maxLength: maxLength(255) },
				shortDescriptionTitle: { required, maxLength: maxLength(255) },
				shortDescription: { required, minLength: minLength(120), maxLength: maxLength(500) },
				description: { required, minLength: minLength(120), maxLength: maxLength(1500) },
			},
		},
	},
})
export default class ExpertOverviewForm extends BaseComponent {
	@Prop({ type: Object as PropType<ExpertProfileViewModel>, required: true, default: {} })
	vm!: ExpertProfileViewModel;

	@Prop({ type: Boolean, required: true, default: false })
	isEditing!: boolean;

	@Emit() submit(): ExpertSettingsOverviewForm {
		return this.expertSettingsOverviewForm;
	}

	@Watch("isEditing") onIsEditingChanged(_val: boolean): void {
		this.initForm();
	}

	@Watch("vm") onVmChanged(_val: boolean): void {
		this.initForm();
	}

	submitted = false;
	v$ = useVuelidate();
	expertSettingsOverviewForm!: ExpertSettingsOverviewForm;

	created(): void {
		this.initForm();
	}

	get canSubmit(): boolean {
		return this.v$["expertSettingsOverviewForm"].$anyDirty && !this.v$["expertSettingsOverviewForm"].$invalid;
	}

	initForm(): void {
		this.expertSettingsOverviewForm = {
			expertFunction: this.vm?.function,
			shortDescriptionTitle: this.vm?.shortDescriptionTitle,
			shortDescription: this.vm?.shortDescription,
			description: this.vm?.description,
		};
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.submit();
	}
}
</script>

<style scoped lang="scss"></style>
