import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4120e2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "text-primary" }
const _hoisted_3 = { class: "text-preline project-description" }
const _hoisted_4 = {
  key: 0,
  class: "text-bold"
}
const _hoisted_5 = { class: "text-preline" }
const _hoisted_6 = { class: "text-bold mb-0" }
const _hoisted_7 = { class: "d-flex align-items-center justify-content-end" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_9 = {
  key: 1,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusBadge = _resolveComponent("StatusBadge")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ExpertDeclineBookingModal = _resolveComponent("ExpertDeclineBookingModal")!
  const _component_ExpertAcceptBookingModal = _resolveComponent("ExpertAcceptBookingModal")!
  const _component_ExpertCompleteBookingModal = _resolveComponent("ExpertCompleteBookingModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.vm.bookings?.length)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            "auto-layout": "",
            breakpoint: "991px",
            class: "mt-2",
            paginator: _ctx.showPagination,
            "responsive-layout": "scroll",
            rows: _ctx.maxRowsToShow,
            value: _ctx.vm?.bookings
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                key: "status",
                header: _ctx.$t('myCadac.supportTicketOverview.table.colStatus'),
                style: {}
              }, {
                body: _withCtx((item) => [
                  _createVNode(_component_StatusBadge, {
                    status: item.data.status,
                    "translation-prefix": "enum.expertBookingStatus",
                    type: 1
                  }, null, 8, ["status"])
                ]),
                _: 1
              }, 8, ["header"]),
              (!_ctx.isExpert)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: "expert",
                    header: _ctx.$t('expert.booking.expert')
                  }, {
                    body: _withCtx((item) => [
                      _createElementVNode("div", _hoisted_1, [
                        (item.data.expertProfile?.imageUrl)
                          ? (_openBlock(), _createBlock(_component_Avatar, {
                              key: 0,
                              class: "mr-2 profile-image",
                              image: item.data.expertProfile?.imageUrl,
                              shape: "circle",
                              size: "lg"
                            }, null, 8, ["image"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(item.data.expertProfile?.firstName) + " " + _toDisplayString(item.data.expertProfile?.lastName), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["header"]))
                : _createCommentVNode("", true),
              (_ctx.isExpert)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: "projectDescription",
                    field: "projectDescription",
                    header: _ctx.$t('expert.booking.projectDescription'),
                    style: {"width":"30%"}
                  }, {
                    body: _withCtx((item) => [
                      _createElementVNode("span", _hoisted_3, _toDisplayString(item.data.projectDescription), 1)
                    ]),
                    _: 1
                  }, 8, ["header"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Column, {
                key: "location",
                header: _ctx.$t('expert.booking.workLocation'),
                style: {"width":"20%"}
              }, {
                body: _withCtx((item) => [
                  (_ctx.isExpert)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(item.data.customerDisplayName), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(item.data.isRemote ? _ctx.$t("expert.remote") : item.data.workLocation), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "date",
                header: _ctx.$t('expert.booking.dates')
              }, {
                body: _withCtx((item) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedDates(item.data.dates), (date) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: JSON.stringify(date),
                      class: "d-flex flex-column"
                    }, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(`${_ctx.formatDate(date.date)} `), 1),
                      _createElementVNode("small", null, _toDisplayString(`${_ctx.formatTime(date.start)} - ${_ctx.formatTime(date.end)}`), 1)
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, { key: "actions" }, {
                body: _withCtx((item) => [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.canAcceptOrRejectBooking(item.data))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_Button, {
                            class: "p-button-success p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0",
                            icon: "pi pi-check",
                            label: _ctx.isSmallScreen ? _ctx.$t('common.accept') : '',
                            title: _ctx.$t('common.accept'),
                            onClick: ($event: any) => (_ctx.onAcceptClick(item.data))
                          }, null, 8, ["label", "title", "onClick"]),
                          _createVNode(_component_Button, {
                            class: "p-button-danger p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0",
                            icon: "pi pi-times",
                            label: _ctx.isSmallScreen ? _ctx.$t('common.reject') : '',
                            title: _ctx.$t('common.reject'),
                            onClick: ($event: any) => (_ctx.onRejectClick(item.data))
                          }, null, 8, ["label", "title", "onClick"]),
                          _createVNode(_component_Divider, { layout: "vertical" })
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.canCompleteBooking(item.data))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_Button, {
                            class: "p-button-success p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0",
                            icon: "pi pi-check",
                            label: _ctx.$t('common.complete'),
                            title: _ctx.$t('common.complete'),
                            onClick: ($event: any) => (_ctx.onCompleteClick(item.data))
                          }, null, 8, ["label", "title", "onClick"]),
                          _createVNode(_component_Divider, { layout: "vertical" })
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.canSeeInvoiceForBooking(item.data))
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 2,
                          class: "p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0",
                          icon: "pi pi-file-pdf",
                          label: _ctx.isSmallScreen ? _ctx.$t('expert.button.viewInvoice') : '',
                          title: _ctx.$t('expert.button.viewInvoice'),
                          onClick: ($event: any) => (_ctx.onViewInvoiceClick(item.data))
                        }, null, 8, ["label", "title", "onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Button, {
                      class: "p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0",
                      icon: "pi pi-eye",
                      label: _ctx.isSmallScreen ? _ctx.$t('common.view') : '',
                      title: _ctx.$t('common.view'),
                      onClick: ($event: any) => (_ctx.onViewDetailClick(item.data))
                    }, null, 8, ["label", "title", "onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["paginator", "rows", "value"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.expertStore.isCancelOrDeclineBookingModalVisible)
      ? (_openBlock(), _createBlock(_component_ExpertDeclineBookingModal, {
          key: 0,
          onSubmit: _ctx.declineBooking
        }, null, 8, ["onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.expertStore.isAcceptBookingModalVisible)
      ? (_openBlock(), _createBlock(_component_ExpertAcceptBookingModal, {
          key: 1,
          onSubmit: _ctx.acceptBooking
        }, null, 8, ["onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.expertStore.isCompleteBookingModalVisible)
      ? (_openBlock(), _createBlock(_component_ExpertCompleteBookingModal, {
          key: 2,
          onSubmit: _ctx.completeBooking
        }, null, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}