<template>
	<div class="my-2">
		<p class="text-muted m-0 text-preline">{{ $t("expert.selectStartEndTime") }}</p>
		<p class="mb-0 text-bold text-primary">
			{{ formatTime(selectedDates[0]) }} - {{ formatTime(selectedDates[1]) }} ({{
				$tc("common.hour", [selectedTotalTime])
			}})
		</p>
	</div>

	<div class="bubble-container">
		<div
			v-for="(selectedHour, i) in selectedHours"
			:key="`slider-bubble-${selectedHour}`"
			class="bubble"
			:style="{ left: `${bubbleStyle(selectedHour)}%` }"
		>
			<div class="slider-bubble">
				<small>{{ formatTime(selectedDates[i]) }}</small>
			</div>
		</div>
	</div>
	<div class="slider-container">
		<Slider
			:max="workDayEndHour"
			:min="workDayStartHour"
			:model-value="selectedHours"
			:range="true"
			:step="1"
			@slideend="onValidityChange"
			@update:model-value="onSliderValueChange"
		></Slider>
		<span
			v-for="(unavailableHour, index) in unavailableHours"
			:key="`striped-overlay${index}`"
			class="p-slider-range-striped"
			:style="unavailableRangeStyle(unavailableHour)"
		></span>
	</div>
	<div class="slider-time mt-1">
		<b class="slider-time-start">
			<small>{{ `${workDayStartHour}:00` }}</small>
		</b>
		<b class="slider-time-end">
			<small>{{ `${24 > workDayEndHour ? workDayEndHour : workDayEndHour - 24}:00` }}</small>
		</b>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Slider from "primevue/slider";
import { Component, Emit, Model, Prop } from "vue-facing-decorator";
import { set } from "date-fns";
import { UnavailableHours } from "@/types/models/expert/expertBookingSlider";

interface UnavailableRangeStyle {
	left: string;
	width: string;
}

@Component({
	components: { Slider },
	emits: ["update:modelValue", "onValidityChange"],
})
export default class ExpertBookingSlider extends BaseComponent {
	@Model({ type: Array, required: true })
	selectedHours!: Array<number>;

	@Prop({ type: Array, required: true })
	unavailableHours!: Array<UnavailableHours>;

	@Prop({ type: Number, required: false, default: 9 })
	workDayStartHour!: number;

	@Prop({ type: Number, required: false, default: 17 })
	workDayEndHour!: number;

	@Prop({ type: Date, required: true }) selectedDate!: Date;

	get selectedDates(): Date[] {
		const dates: Date[] = [];
		dates.push(
			set(new Date(this.selectedDate), {
				hours: this.selectedHours[0],
				minutes: 0,
				seconds: 0,
				milliseconds: 0,
			})
		);
		dates.push(
			set(new Date(this.selectedDate), {
				hours: this.selectedHours[1],
				minutes: 0,
				seconds: 0,
				milliseconds: 0,
			})
		);
		return dates;
	}

	// available time window. 9:00 to 17:00 is 8 total hours
	get availableTotalTime(): number {
		return this.workDayEndHour - this.workDayStartHour;
	}

	get selectedTotalTime(): number {
		return this.selectedHours[1] - this.selectedHours[0];
	}

	// range slider is divided in x% segments
	get rangeSegmentWidth(): number {
		return 100 / this.availableTotalTime;
	}

	get isValidSelection(): boolean {
		return this.unavailableHours.every(
			(unavailableHour) =>
				(this.selectedHours[0] < unavailableHour.start.position &&
					this.selectedHours[1] <= unavailableHour.start.position) ||
				(this.selectedHours[0] >= unavailableHour.end.position &&
					this.selectedHours[1] > unavailableHour.end.position)
		);
	}

	@Emit()
	onValidityChange(): boolean {
		return this.isValidSelection;
	}

	mounted(): void {
		this.onValidityChange();
	}

	onSliderValueChange(value: number[]): void {
		// Round the numbers to prevent weird positioning in the slider.
		const fixedValue = value.map((x) => Math.round(x));
		// Make sure the two values are not the same
		if (fixedValue[0] !== fixedValue[1]) this.selectedHours = fixedValue;
	}

	// based on rangeSegmentWidth the unavailable range starting point in % and its width is calculated
	unavailableRangeStyle(unavailableHour: UnavailableHours): UnavailableRangeStyle {
		// starting position of the unavailable range, if the day starts at 9, and the expert is unavailable at 9, the range starts of 0% to the left

		const rangeStartingPosition =
			this.rangeSegmentWidth * (unavailableHour.start.position - this.workDayStartHour + 1) -
			this.rangeSegmentWidth;
		// total width in %
		const rangeWidth =
			unavailableHour.end.position === unavailableHour.start.position
				? this.rangeSegmentWidth
				: this.rangeSegmentWidth * (unavailableHour.end.position - unavailableHour.start.position);

		return { left: rangeStartingPosition + "%", width: rangeWidth + "%" };
	}

	// position of the handle bubble, matches handle
	bubbleStyle(selectedHour: number): number {
		return this.rangeSegmentWidth * (selectedHour - this.workDayStartHour + 1) - this.rangeSegmentWidth;
	}
}
</script>

<style scoped lang="scss">
.slider-container {
	position: relative;
}

.bubble-container {
	position: relative;
	height: 1rem;
	margin-bottom: 1.1rem;
	width: 100%;

	.bubble {
		position: absolute;
		transform: translateX(-50%);
		bottom: -6px;
	}

	.slider-bubble {
		position: relative;
		background: var(--surface-a);
		border: 1px solid var(--surface-d);
		border-radius: var(--border-radius);
		padding: 0 4px;

		&:after {
			content: "";
			position: absolute;
			border: 1px solid rgba(51, 51, 51, 0.19);
			bottom: -6px;
			left: 33%;
		}

		&:before {
			content: "";
			position: absolute;
			border: 1px solid #333;
			bottom: -7px;
			left: 33%;
		}

		&:after {
			border-color: white transparent;
			border-width: 7px 7px 0 6px;
		}

		&:before {
			border-color: #999 transparent;
			border-width: 7px 7px 0 6px;
		}
	}
}

.slider-time {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--primary-color);

	.slider-time-start {
		transform: translateX(-50%);
	}

	.slider-time-end {
		transform: translateX(50%);
	}
}
</style>
