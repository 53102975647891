import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-096f3bd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid container-lg pb-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-10 col-xxl-8 mx-auto px-0" }
const _hoisted_4 = { class: "product-configuration-card p-1 p-lg-2 p-xl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$slots.header)
          ? _renderSlot(_ctx.$slots, "header", { key: 0 }, undefined, true)
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.$slots.configuration)
            ? _renderSlot(_ctx.$slots, "configuration", { key: 0 }, undefined, true)
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ])
  ]))
}