import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row p-lg-2 p-md-1"
}
const _hoisted_2 = { class: "col-md-6 col-lg-10 mb-3" }
const _hoisted_3 = { class: "lead mb-2" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "col-12 d-flex justify-content-end" }
const _hoisted_6 = {
  key: 1,
  class: "row p-lg-2 p-md-1"
}
const _hoisted_7 = { class: "col-md-6 col-lg-7 mb-3" }
const _hoisted_8 = { class: "d-flex align-items-center mb-2 text-muted" }
const _hoisted_9 = { class: "ml-2 mb-0" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "m-0 ml-2 text-left" }
const _hoisted_12 = { class: "col-md-6 col-lg-5" }
const _hoisted_13 = { class: "d-flex align-items-center mb-2 text-muted" }
const _hoisted_14 = { class: "ml-2 ml-2 mb-0" }
const _hoisted_15 = { class: "d-flex flex-column" }
const _hoisted_16 = { class: "m-0 ml-2 text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.isVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
      breakpoints: {
				'2000px': '35vw',
				'1600px': '40vw',
				'1200px': '50vw',
				'992px': '60vw',
				'768px': '75vw',
				'576px': '90vw',
			},
      class: "change-region-dialog p-dialog-secondary",
      header: 
				_ctx.shouldConfirmRegion
					? _ctx.$t('footer.dialog.confirmCurrentRegionTitle')
					: _ctx.$t('footer.dialog.chooseRegionTitle')
			,
      modal: true,
      style: { width: '28vw' },
      onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCancel()))
    }, {
      default: _withCtx(() => [
        (_ctx.shouldConfirmRegion)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(`${_ctx.$t("footer.dialog.confirmCurrentRegionSubtitle", {
								country: _ctx.layoutStore.vm.preferredLocationOption?.name,
							})}`), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_SelectButton, {
                    modelValue: _ctx.selectedLocationOption,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLocationOption) = $event)),
                    class: "custom-select-button",
                    "option-label": "name",
                    options: _ctx.redirectCountries
                  }, null, 8, ["modelValue", "options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  class: "p-button-raised",
                  label: _ctx.$t('footer.dialog.confirm'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRegionSelected(_ctx.selectedLocationOption)))
                }, null, 8, ["label"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['far', 'earth-europe'],
                    size: "2x"
                  }),
                  _createElementVNode("h5", _hoisted_9, _toDisplayString(`${_ctx.$t("footer.dialog.regionEurope")}`), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.europeCountries?.availableLocations, (option, index) => {
                    return (_openBlock(), _createBlock(_component_Button, {
                      key: index,
                      class: "p-button-text p-button-secondary ml-0",
                      onClick: ($event: any) => (_ctx.onRegionSelected(option))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'location-dot'],
                          size: "lg"
                        }),
                        _createElementVNode("p", _hoisted_11, _toDisplayString(option.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['far', 'earth'],
                    size: "2x"
                  }),
                  _createElementVNode("h5", _hoisted_14, _toDisplayString(`${_ctx.$t("footer.dialog.regionOther")}`), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherCountries?.availableLocations, (option, index) => {
                    return (_openBlock(), _createBlock(_component_Button, {
                      key: index,
                      class: "p-button-text p-button-secondary ml-0",
                      onClick: ($event: any) => (_ctx.onRegionSelected(option))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'location-dot'],
                          size: "lg"
                        }),
                        _createElementVNode("p", _hoisted_16, _toDisplayString(option.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ])
              ])
            ]))
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ]))
}