<template>
	<div class="flex-license-container d-flex flex-column">
		<Card>
			<template #content>
				<p class="lead font-weight-bold mb-3">
					{{ $tc("product.advice.titleProductAdvice", { product: productName }) }}
				</p>
				<div class="slider-wrapper flex-1 col-md-6 shadow-sm">
					<div class="bubble-container">
						<div class="bubble" :style="{ left: `${bubbleStyle(selectedDays)}%` }">
							<div class="slider-bubble">
								<b class="slider-bubble-selected">{{ selectedDays }}</b>
							</div>
						</div>
					</div>
					<Slider v-model="selectedDays" :max="30" :min="1" :step="1" />

					<div class="slider-time">
						<b class="slider-time-start"> 1 </b>
						<b class="slider-time-end"> 30+ </b>
					</div>
				</div>

				<div>
					<Message
						v-if="!isPositiveFlexAdvice"
						class="advice-banner mb-0"
						:closable="false"
						severity="error"
					>
						<div class="d-flex flex-column mx-1">
							<div class="content">{{ $t("product.advice.message.negativeSummary") }}</div>
						</div>

						<Button
							class="advice-button p-button-success p-button-primary mx-1"
							:label="$t('digitalAdvisor.button.lblSelectSubscription')"
							@click="selectSubscription"
						/>
					</Message>

					<Message
						v-if="isPositiveFlexAdvice"
						class="advice-banner"
						:closable="false"
						severity="success"
					>
						<div class="mx-1">
							{{ $t("product.advice.message.positiveSummary") }}
						</div>
					</Message>

					<div v-if="isPositiveFlexAdvice" class="d-flex justify-content-start flex-wrap">
						<div class="token-price-wrapper d-flex flex-column mr-4 my-1">
							<div class="token-price-wrapper-title">
								<span>{{ productName }} | </span>
								{{ $tc("product.advice.titleProductTokenUsage", productUsageAdvice.tokensPerDay) }}
							</div>
							<div class="d-flex flex-column">
								<div class="token-price-wrapper-subtitle">
									{{ $t("product.advice.estimatedUsage") }}
								</div>
								<div class="d-flex flex-row justify-content-between mt-1 w-full">
									<div class="token-price-wrapper-left my-auto">
										<i18n-t keypath="product.advice.lblYearlyTokenUsage">
											<template #amount>
												<b>{{ productUsageAdvice.tokensPerDay * selectedDays * 12 }}</b>
											</template>
										</i18n-t>
									</div>
									<div class="divider"></div>
									<div class="d-flex flex-column token-price-wrapper-right my-auto">
										<b>{{
											formatCurrency(productUsageAdvice.totalCostsPerDay * selectedDays * 12)
										}}</b>
										<small>{{ $t("common.priceSummary.noVat") }} </small>
									</div>
								</div>
							</div>
						</div>

						<div class="d-flex flex-column my-1">
							<h5>{{ $t("product.advice.buyTokens") }}</h5>
							<Button
								v-if="layoutStore.chatWidgetAvailable"
								class="p-button-success p-button-raised w-100 mb-1"
								:label="$t('product.advice.button.chatWithEmployee')"
								@click="initChat"
							/>
							<small class="mb-1">
								<i18n-t keypath="common.support.contactUsAt">
									<a :href="'tel:' + $t('common.support.phoneNumber')">
										{{ $t("common.support.phoneNumber") }}
									</a>
								</i18n-t>
							</small>
						</div>
					</div>
				</div>
			</template>
		</Card>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Message from "primevue/message";
import Slider from "primevue/slider";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { ProductUsageAdvice } from "@/types/models/software/productUsageAdvice";
import { PropType } from "vue";
import { useSoftwareProductStore } from "@/store/software/softwareProductStore";

@Component({
	components: {
		Slider,
		Message,
	},
})
export default class ProductConfigurationFlexLicense extends BaseComponent {
	@Prop({
		type: Object as PropType<ProductUsageAdvice>,
		required: true,
		default: {},
	})
	productUsageAdvice!: ProductUsageAdvice;

	@Prop({
		type: String,
		required: true,
		default: {},
	})
	productName;

	selectedDays!: number;
	productStore = useSoftwareProductStore();

	@Emit("select-subscription") selectSubscription(): void {
		return;
	}

	get isPositiveFlexAdvice(): boolean {
		return this.selectedDays <= this.productUsageAdvice.monthlyThreshold;
	}

	mounted(): void {
		this.selectedDays = Math.floor(this.productUsageAdvice.monthlyThreshold);
	}

	bubbleStyle(selectedDays: number): number {
		return (selectedDays / (30 - 1) - 1 / (30 - 1)) * 100;
	}

	initChat(): void {
		this.chatWidget.open({ name: "Support", replyText: "I would like to purchase flex tokens" });
	}
}
</script>

<style scoped lang="scss">
.slider-wrapper {
	background: #ffffff;
	border: 1px solid var(--surface-b);
	color: var(--primary-color);
	border-radius: 0.25em;
	padding: 0 1.75rem;
	margin-right: 1.25rem;
}

.token-price-wrapper {
	background: #ffffff;
	margin-right: 1.25rem;
	width: 33%;

	.token-price-wrapper-left {
		width: 45%;

		b {
			font-size: 1.2rem;
		}
	}

	.token-price-wrapper-right {
		width: 45%;

		b {
			font-size: 1.2rem;
			color: var(--primary-color);
		}
	}

	.token-price-wrapper-title {
		color: var(--primary-color);
		font-size: 0.9rem;
		font-weight: 600;
	}

	.token-price-wrapper-subtitle {
		color: var(--secondary-color);
		font-size: 1.1rem;
		font-weight: 400;
	}

	.divider {
		border-left: 2px solid var(--secondary-color);
		height: 100%;
	}
}

.bubble-container {
	position: relative;
	margin-bottom: 1.1rem;
	width: 100%;

	.bubble {
		position: absolute;
		transform: translateX(-50%);
		bottom: -6px;
	}

	.slider-bubble {
		position: relative;
		background: var(--surface-a);
		border: 1px solid var(--surface-d);
		border-radius: var(--border-radius);
		padding: 0 12px;

		.slider-bubble-selected {
			font-size: 0.8rem;
		}

		&:after {
			content: "";
			position: absolute;
			border: 1px solid rgba(51, 51, 51, 0.19);
			bottom: -6px;
			left: 33%;
		}

		&:before {
			content: "";
			position: absolute;
			border: 1px solid #333;
			bottom: -7px;
			left: 33%;
		}

		&:after {
			border-color: white transparent;
			border-width: 7px 7px 0 6px;
		}

		&:before {
			border-color: #999 transparent;
			border-width: 7px 7px 0 6px;
		}
	}
}

.slider-time {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--primary-color);

	.slider-time-start {
		transform: translateX(-50%);
		font-size: 0.8rem;
	}

	.slider-time-end {
		transform: translateX(50%);
		font-size: 0.8rem;
	}
}

.advice-button-info {
	width: fit-content;
	color: var(--primary-color);
	font-weight: 500;
}

.advice-banner {
	border: none;
	border-radius: var(--border-radius);
	padding: 0.25rem 0.75rem;

	.negative {
		color: var(--primary-color);

		::v-deep(.p-message-icon) {
			color: var(--primary-color);
		}
	}

	::v-deep(.p-message-text) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-weight: 400;

		@media (max-width: 960px) {
			flex-direction: column;
			padding-right: 1rem;
			padding-left: 1rem;
			gap: 1rem;
			align-items: start;
		}
	}

	::v-deep(.p-button) {
		width: 16rem;

		@media (max-width: 576px) {
			width: 100%;
		}
	}

	.title {
		font-weight: 500;
	}

	.content {
		font-weight: 400;
	}
}
</style>
