<template>
	<div>
		<Dialog
			v-model:visible="isVisible"
			:breakpoints="{
				'2000px': '35vw',
				'1600px': '40vw',
				'1200px': '50vw',
				'992px': '60vw',
				'768px': '75vw',
				'576px': '90vw',
			}"
			class="change-region-dialog p-dialog-secondary"
			:header="
				shouldConfirmRegion
					? $t('footer.dialog.confirmCurrentRegionTitle')
					: $t('footer.dialog.chooseRegionTitle')
			"
			:modal="true"
			:style="{ width: '28vw' }"
			@hide="onCancel()"
		>
			<div v-if="shouldConfirmRegion" class="row p-lg-2 p-md-1">
				<div class="col-md-6 col-lg-10 mb-3">
					<p class="lead mb-2">
						{{
							`${$t("footer.dialog.confirmCurrentRegionSubtitle", {
								country: layoutStore.vm.preferredLocationOption?.name,
							})}`
						}}
					</p>
					<div class="d-flex align-items-center">
						<SelectButton
							v-model="selectedLocationOption"
							class="custom-select-button"
							option-label="name"
							:options="redirectCountries"
						/>
					</div>
				</div>

				<div class="col-12 d-flex justify-content-end">
					<Button
						class="p-button-raised"
						:label="$t('footer.dialog.confirm')"
						@click="onRegionSelected(selectedLocationOption)"
					/>
				</div>
			</div>

			<div v-else class="row p-lg-2 p-md-1">
				<div class="col-md-6 col-lg-7 mb-3">
					<div class="d-flex align-items-center mb-2 text-muted">
						<font-awesome-icon :icon="['far', 'earth-europe']" size="2x" />
						<h5 class="ml-2 mb-0">{{ `${$t("footer.dialog.regionEurope")}` }}</h5>
					</div>

					<div class="d-flex flex-column">
						<Button
							v-for="(option, index) in europeCountries?.availableLocations"
							:key="index"
							class="p-button-text p-button-secondary ml-0"
							@click="onRegionSelected(option)"
						>
							<font-awesome-icon :icon="['far', 'location-dot']" size="lg" />
							<p class="m-0 ml-2 text-left">{{ option.name }}</p>
						</Button>
					</div>
				</div>

				<div class="col-md-6 col-lg-5">
					<div class="d-flex align-items-center mb-2 text-muted">
						<font-awesome-icon :icon="['far', 'earth']" size="2x" />
						<h5 class="ml-2 ml-2 mb-0">{{ `${$t("footer.dialog.regionOther")}` }}</h5>
					</div>
					<div class="d-flex flex-column">
						<Button
							v-for="(option, index) in otherCountries?.availableLocations"
							:key="index"
							class="p-button-text p-button-secondary ml-0"
							@click="onRegionSelected(option)"
						>
							<font-awesome-icon :icon="['far', 'location-dot']" size="lg" />
							<p class="m-0 ml-2 text-left">{{ option.name }}</p>
						</Button>
					</div>
				</div>
			</div>
		</Dialog>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import SelectButton from "primevue/selectbutton";
import { Component, Prop } from "vue-facing-decorator";
import { Continent } from "@/types/enum/continent";
import { ContinentViewModel } from "@/types/models/menu/continentViewModel";
import { CookieType } from "@/types/enum/cookieType";
import { LocationOptionViewModel } from "@/types/models/menu/locationOptionViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SetCookieRequest } from "@/types/models/menu/setCookieRequest";

@Component({
	components: { Checkbox, SelectButton },
})
export default class ChangeRegionModal extends BaseComponent {
	@Prop({ required: false, default: false }) shouldConfirmRegion!: boolean;
	@Prop({ type: Object as PropType<ContinentViewModel[]>, required: true, default: [] })
	vm!: ContinentViewModel[];

	isVisible = true;

	selectedLocationOption!: LocationOptionViewModel;

	created(): void {
		if (this.shouldConfirmRegion) this.selectedLocationOption = this.layoutStore.vm.preferredLocationOption;
	}

	get europeCountries() {
		return this.vm.find((x) => x.continent === Continent.Europe);
	}

	get otherCountries() {
		return this.vm.find((x) => x.continent === Continent.Other);
	}

	get redirectCountries() {
		const { preferredLocationOption, pageLocationOption } = this.layoutStore.vm;
		return [pageLocationOption, preferredLocationOption];
	}

	onCancel(): void {
		this.layoutStore.hideModal("ChangeRegion");
	}

	onRegionSelected(region: LocationOptionViewModel): void {
		const payload: SetCookieRequest = {
			type: CookieType.PreferredLocation,
			value: region.code,
		};
		this.axios
			.put("/api/cookie", payload)
			.then(() => {
				if (region.redirectUrl) {
					window.location.href = region.redirectUrl;
				} else {
					window.location.reload();
				}
			})
			.catch((err) => Log.error(err));
	}
}
</script>

<style lang="scss">
.change-region-dialog {
	.p-dialog-content {
		padding: 2rem;
	}

	.p-dialog-footer {
		display: none;
	}

	.p-button-text.p-button-secondary {
		flex: 1;
		flex-basis: 50%;
		font-size: 1.05rem;
	}
}
</style>
