import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-2 m-2 surface-a"
}
const _hoisted_2 = { class: "d-flex mt-2" }
const _hoisted_3 = { class: "d-flex flex-column mt-2" }
const _hoisted_4 = {
  key: 1,
  class: "d-flex flex-shrink-0 p-2 m-2 surface-a"
}
const _hoisted_5 = { class: "d-flex flex-column ml-1 w-50" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "w-50 d-flex flex-column ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (!_ctx.isGridLayout)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Skeleton, {
          height: "150px",
          width: "100%"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Skeleton, {
            class: "mr-2",
            height: "4rem",
            shape: "square",
            width: "4rem"
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_Skeleton, {
              class: "mb-2",
              width: "10rem"
            }),
            _createVNode(_component_Skeleton, {
              class: "mb-2",
              width: "5rem"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: "1.25rem",
            width: "20%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: ".75rem",
            width: "100%"
          }),
          _createVNode(_component_Skeleton, {
            height: ".75rem",
            width: "80%"
          })
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_Skeleton, {
          height: "120px",
          width: "200px"
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: "4rem",
            shape: "square",
            width: "4rem"
          }),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Skeleton, {
              class: "mb-1",
              width: "20%"
            }),
            _createVNode(_component_Skeleton, {
              class: "mb-2",
              width: "35%"
            }),
            _createVNode(_component_Skeleton, {
              class: "mb-1",
              height: "1.25rem",
              width: "20%"
            }),
            _createVNode(_component_Skeleton, {
              class: "mb-1",
              height: ".75rem",
              width: "100%"
            }),
            _createVNode(_component_Skeleton, {
              height: ".75rem",
              width: "80%"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: "1.25rem",
            width: "20%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: ".75rem",
            width: "100%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: ".75rem",
            width: "100%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: ".75rem",
            width: "100%"
          }),
          _createVNode(_component_Skeleton, {
            class: "mb-1",
            height: ".75rem",
            width: "100%"
          }),
          _createVNode(_component_Skeleton, {
            height: ".75rem",
            width: "80%"
          })
        ])
      ]))
}