<template>
	<div class="col-lg-3">
		<Panel v-model:collapsed="collapsed" class="product-filter-item">
			<template #header>
				<p class="p-link lead mb-0 w-100" @click="collapsed = !collapsed">Zoekterm</p>
			</template>

			<template #icons>
				<button class="p-panel-header-icon p-link mr-2" @click="collapsed = !collapsed">
					<i :class="collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" />
				</button>
			</template>

			<div class="overview__controls__search">
				<div class="autocomplete">
					<InputText v-model="searchString" type="text" />
				</div>
			</div>
		</Panel>

		<Panel v-model:collapsed="collapsed" class="product-filter-item">
			<template #header>
				<p class="p-link lead mb-0 w-100" @click="collapsed = !collapsed">Categories</p>
			</template>
			<template #icons>
				<button class="p-panel-header-icon p-link mr-2" @click="collapsed = !collapsed">
					<i :class="collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" />
				</button>
			</template>
			<div
				v-for="category in categories"
				:key="category.value"
				class="product-filter-option p-field-checkbox form-group"
			>
				<Checkbox :id="category.value" binary />
				<label
					class="ml-2 mb-0 p-link"
					:class="collapsed ? 'font-weight-bold' : 'font-weight-normal'"
					:for="category"
				>
					{{ category.label }}
				</label>
			</div>
		</Panel>

		<!--		<div id="searchFilters" class="dialog dialog&#45;&#45;aside">-->
		<!--			<div class="dialog__body">-->
		<!--				<h2 class="heading-3 mt-1">Categorie</h2>-->
		<!--				<div class="overview__filters mt-1">-->
		<!--					<template v-for="(category, index) in categories" :key="category + index">-->
		<!--						<label>{{ category }}</label>-->
		<!--					</template>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</div>-->
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import SearchPageResultItem from "@/components/general/search/searchPageResultItem.vue";
import Checkbox from "primevue/checkbox";

import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { SearchCategoryObject } from "@/types/models/search/searchCategory";

@Component({
	components: { SearchPageResultItem, Checkbox },
})
export default class SearchPageSidebar extends BaseComponent {
	@Prop({
		type: Object as PropType<SearchCategoryObject[]>,
		required: true,
		default: {},
	})
	categories!: SearchCategoryObject[];
	collapsed = false;

	searchString = "";
}
</script>

<style scoped lang="scss"></style>
