<template>
	<a :href="url" role="button" :target="target" @click.stop>
		<Button v-bind="$attrs" :class="$attrs.class">
			<slot />
		</Button>
	</a>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";


    @Component({})
	export default class LinkButton extends BaseComponent {
		@Prop({ type: String, required: true, default: "" }) url!: string;
		@Prop({ type: String, required: false, default: "_self" }) target!: string;
	}
</script>

<style scoped lang="scss">
a {
	text-decoration: none;
	color: inherit;
}
::v-deep(.p-button) {
	text-align: left;
}
</style>
