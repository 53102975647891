<template>
	<div class="content-list-item content-grid-card-item">
		<a :href="item?.url" style="text-decoration: none">
			<Card class="flex-1">
				<template #header>
					<div class="image-container">
						<img v-lazy="item.image?.url" :alt="item.image?.alt" class="image" />
						<div class="contentcategory-tag">
							<Tag :value="$t('contentCategory.lblCategoryTags.' + item.contentCategory)" />
						</div>
					</div>
				</template>
				<template #content>
					<h3 v-if="item.title" class="title">{{ item.title }}</h3>
					<div class="d-flex flex-row justify-content-between align-items-center">
						<div class="d-flex flex-column flex-sm-row">
							<div v-if="showUpcomingEventTag">
								<Tag
									class="mr-1"
									severity="success"
									:value="$t('contentCategory.lblUpcomingTag')"
								/>
							</div>

							<small class="text-muted">
								{{ contentDate }}
							</small>
						</div>

						<Button
							class="overlay p-button-link p-button-arrow"
							:label="$t('contentCategory.lblContinueReading')"
						/>
					</div>
				</template>
			</Card>
		</a>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { ContentCategoryOverviewItemViewModel } from "@/types/viewModels/general/contentCategoryOverviewItemViewModel";
import Tag from "primevue/tag";
import { ContentCategory } from "@/types/enum/contentCategory";
import { isFuture, isToday } from "date-fns";

@Component({
	components: {
		Tag,
	},
})
export default class ContentCategoryOverviewItemGridCard extends BaseComponent {
	@Prop({ type: Object as PropType<ContentCategoryOverviewItemViewModel>, required: true, default: {} })
	item!: ContentCategoryOverviewItemViewModel;

	get contentDate(): string {
		return this.formatLongDate(
			this.item.contentCategory === ContentCategory.Event ? this.item.relatedDate : this.item.dateCreated
		);
	}

	get showUpcomingEventTag(): boolean {
		return (
			this.item.contentCategory === ContentCategory.Event &&
			!!this.item.relatedDate &&
			(isFuture(this.toDate(this.item.relatedDate)) || isToday(this.toDate(this.item.relatedDate)))
		);
	}
}
</script>

<style scoped lang="scss">
.content-grid-card-item {
	position: relative;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	top: 0;
	z-index: 1;
	transition: all 0.4s;
	cursor: pointer;

	::v-deep(.p-card) {
		box-shadow: none;

		.p-card-body {
			padding: 0;
		}
	}

	.title {
		margin-bottom: 0.5rem;
	}

	.p-card-content {
		margin-bottom: 0;
	}

	.overlay {
		transition: 0.5s ease;
		opacity: 0;
	}

	&:hover {
		.image {
			transform: scale(1.03);
		}

		.overlay {
			cursor: pointer;
			opacity: 1;
		}
	}
}

.image-container {
	position: relative;
	overflow: hidden;
	border-radius: 4px;

	.image {
		opacity: 1;
		display: block;
		aspect-ratio: 16 / 9;
		transition: 0.5s ease;
		backface-visibility: hidden;
		object-fit: cover;
	}

	.contentcategory-tag {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.5rem;
	}
}
</style>
