import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72ce2f6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mx-auto" }
const _hoisted_3 = { class: "d-flex flex-column justify-content-center align-items-center text-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AdvisorTokenAdvice = _resolveComponent("AdvisorTokenAdvice")!
  const _component_AdvisorProductAdvices = _resolveComponent("AdvisorProductAdvices")!
  const _component_BlockUI = _resolveComponent("BlockUI")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isSpinnerVisible('advice'))
          ? (_openBlock(), _createBlock(_component_Card, { key: 0 }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ProgressSpinner, {
                    "animation-duration": "1.75s",
                    "stroke-width": "4"
                  }),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myCadac.advisorAdvice.loadingTitle")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("myCadac.advisorAdvice.loadingText")), 1)
                ])
              ]),
              _: 1
            }))
          : (_ctx.hasAdviceError && !_ctx.isBusy)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_Message, {
                  class: "my-0 w-100",
                  closable: false,
                  severity: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("myCadac.advisorInsights.message.getInsightsError")), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_ctx.showPollMessage)
              ? (_openBlock(), _createBlock(_component_Message, {
                  key: 2,
                  class: "message-with-button w-100",
                  closable: false,
                  severity: "warn"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("myCadac.advisorAdvice.message.pollAdviceTimeout")), 1),
                    _createVNode(_component_Button, {
                      class: "p-button-success p-button-sm m-1",
                      label: _ctx.$t('common.tryAgain'),
                      onClick: _cache[0] || (_cache[0] = () => _ctx.resumePolling())
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }))
              : (_ctx.showNoAdviceMessage)
                ? (_openBlock(), _createBlock(_component_Message, {
                    key: 3,
                    closable: false,
                    severity: "warn"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("myCadac.advisorAdvice.message.noAdvice")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
      ])
    ]),
    (!_ctx.isSpinnerVisible('advice') && _ctx.hasAdvices && _ctx.advisorStore.isAccessRestricted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Message, {
            class: "message-with-button w-100",
            closable: false,
            severity: "warn"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("myCadac.advisorAdvice.message.contentRestricted")), 1),
              _createVNode(_component_Button, {
                class: "col-4 p-button-success p-button-sm",
                label: _ctx.$t('digitalAdvisor.button.contactUs'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.initChat(`${_ctx.$t('myCadac.advisorAdvice.chatMessage.unlockAccess')}`)))
              }, null, 8, ["label"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isSpinnerVisible('advice') && _ctx.hasAdvices)
      ? (_openBlock(), _createBlock(_component_BlockUI, {
          key: 1,
          "auto-z-index": "",
          blocked: _ctx.advisorStore.isAccessRestricted,
          class: "blurred-content"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.tokenAdvice)
                ? (_openBlock(), _createBlock(_component_AdvisorTokenAdvice, {
                    key: 0,
                    class: "mb-2",
                    "token-advice": _ctx.tokenAdvice
                  }, null, 8, ["token-advice"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productWithAdvices, (product, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `product_advice_${index}`,
                  class: "col-md-6 mb-3"
                }, [
                  _createVNode(_component_AdvisorProductAdvices, { product: product }, null, 8, ["product"])
                ]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productWithoutAdvices, (product, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `product_termination_${index}`,
                  class: "col-md-6 mb-3"
                }, [
                  _createVNode(_component_AdvisorProductAdvices, { product: product }, null, 8, ["product"])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["blocked"]))
      : _createCommentVNode("", true)
  ], 64))
}