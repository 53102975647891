<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container text-center">
			<h2 v-if="vm.title" class="display-4" v-html="vm.title"></h2>
			<p class="lead mb-3">{{ vm.text }}</p>
			<div class="row justify-content-center px-2">
				<div class="col-md-4 block-card">
					<p class="text-primary font-weight-bold">
						{{ vm.leftPreTitle }}
					</p>
					<h3 v-if="vm.leftTitle">{{ vm.leftTitle }}</h3>
					<p>{{ vm.leftText }}</p>
					<LinkButton
						v-if="vm.leftCta"
						class="mt-2"
						:class="ctaButtonClass(vm.leftCtaStyle) || 'p-button-link p-button-arrow'"
						:label="vm.leftCta.text"
						:target="vm.leftCta.target"
						:title="vm.leftCta.title"
						:url="vm.leftCta.url"
					/>
				</div>
				<div class="col-md-4 block-card">
					<p class="text-primary font-weight-bold">
						{{ vm.rightPreTitle }}
					</p>
					<h3 v-if="vm.rightTitle">{{ vm.rightTitle }}</h3>
					<p>{{ vm.rightText }}</p>
					<LinkButton
						v-if="vm.rightCta"
						class="mt-2"
						:class="ctaButtonClass(vm.rightCtaStyle) || 'p-button-link p-button-arrow'"
						:label="vm.rightCta.text"
						:target="vm.rightCta.target"
						:title="vm.rightCta.title"
						:url="vm.rightCta.url"
					/>
				</div>
				<div class="col-md-8 py-2">
					<h3 v-if="vm.bottomTitle" class="text-primary">{{ vm.bottomTitle }}</h3>
					<LinkButton
						v-if="vm.bottomCta"
						class="p-button-lg"
						:class="
							ctaButtonClass(vm.bottomCtaStyle) || 'p-button-success p-button-raised p-button-arrow'
						"
						:label="vm.bottomCta.text"
						:target="vm.bottomCta.target"
						:title="vm.bottomCta.title"
						:url="vm.bottomCta.url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { CallToActionCenteredDivideBlockViewModel } from "@/types/viewModels/blocks/callToActionCenteredDivideBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";

@Component
export default class CallToActionCenteredDivideBlock extends BaseBlock {
	@Prop({ type: Object as PropType<CallToActionCenteredDivideBlockViewModel>, required: true, default: {} })
	vm!: CallToActionCenteredDivideBlockViewModel;
}
</script>

<style lang="scss" scoped>
.block-card {
	background: var(--surface-a);
	border: 1px solid var(--surface-d);
	padding: 1rem;
	margin-bottom: 1rem;
}
</style>
