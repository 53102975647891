import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    id: "sticky-button",
    class: "sticky-button",
    style: _normalizeStyle(_ctx.isButtonHidden ? 'opacity: 0' : 'opacity: 1')
  }, [
    _createVNode(_component_Button, {
      class: "p-button-success p-button-raised p-button-arrow w-100 px-1 my-1",
      label: _ctx.buttonLabel,
      onClick: _ctx.scrollTo
    }, null, 8, ["label", "onClick"])
  ], 4))
}