<template>
	<!--	Contract Related products-->
	<div v-if="contractCartClients?.length">
		<CartContractClientTable v-for="contractCart in contractCartClients"
								 :key="contractCart"
								 :cart-client="contractCart"
								 :is-editable="isEditable" />
	</div>
	<!--	Training Related products-->
	<div v-if="trainingCartClients?.length">
		<!--	Mock datatable header for consistent header styling-->
		<div class="p-datatable">
			<div class="p-datatable-header">
				<span>
					{{
					$t(`cart.table.title${getUpperCaseCartLineType(cartLineTypeEnum.trainingProduct)}`)
					}}
				</span>
			</div>
		</div>
		<CartTrainingTable v-for="training in trainingCartClients"
						   :key="training"
						   :cart-line-client="training"
						   :is-editable="isEditable" />
	</div>
	
	<!--	Microservice Related products-->
	<div v-if="microServiceProducts?.length">
		<!--	Mock datatable header for consistent header styling-->
		<div class="p-datatable">
			<div class="p-datatable-header">
				<span>
					{{
						$t(`cart.table.title${getUpperCaseCartLineType(cartLineTypeEnum.serviceProduct)}`)
					}}
				</span>
			</div>
		</div>
		<CartMircoServiceTable v-for="mircoservice in microServiceProducts"
			:key="mircoservice"
			:cart-line-client="mircoservice"
			:is-editable="isEditable" />
	</div>
	<!--	Expert Related products-->
	<div v-if="expertCartClients?.length">
		<!--	Mock datatable header for consistent header styling-->
		<div class="p-datatable">
			<div class="p-datatable-header">
				<span>
					{{
					$t(`cart.table.title${getUpperCaseCartLineType(cartLineTypeEnum.expertProduct)}`)
					}}
				</span>
			</div>
		</div>
		<CartExpertTable :cart-line-clients="expertCartClients" :is-editable="isEditable" />
	</div>

	<!--	Regular products-->
	<div v-if="cartLineClientsByType?.length">
		<CartClientTable v-for="cartLineClient in cartLineClientsByType"
						 :key="cartLineClient"
						 :cart-line-type="cartLineClient.cartLineType"
						 :is-editable="isEditable"
						 :items="getCartLinesForType(cartLineClient.cartLineType)" />
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartClientTable from "@/components/commerce/cart/cartClientTable.vue";
import CartContractClientTable from "@/components/commerce/cart/cartContractTable.vue";
import CartMircoServiceTable from "@/components/commerce/cart/cartMircoServiceTable.vue";
import CartExpertTable from "@/components/commerce/cart/cartExpertTable.vue";
import CartTrainingTable from "@/components/commerce/cart/cartTrainingTable.vue";
import { CartClient } from "@/types/models/cart/cartClient";
import { CartContractClient } from "@/types/generated/Api/cartContractClient";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { PropType } from "vue";
import { concat, uniqBy, upperFirst } from "lodash";
import { useCartStore } from "@/store/commerce/cartStore";

@Component({ components: { CartExpertTable, CartTrainingTable, CartClientTable, CartContractClientTable, CartMircoServiceTable } })
export default class CartOverview extends BaseComponent {
	@Prop({ type: Object as PropType<CartClient>, required: true, default: {} }) cartClient!: CartClient;

	@Prop({ type: Boolean, required: false, default: true })
	isEditable!: boolean;

	cartStore = useCartStore();

	get cartLineTypeEnum(): typeof CartLineType {
        return CartLineType;
	}

	get editingCartClient(): CartClient {
		return this.isEditable ? this.cartStore.cartClient : this.cartClient;
	}

	get contractCartClients(): CartContractClient[] {
		return concat(
			this.editingCartClient.contractAdditions,
			this.editingCartClient.contractCoterming,
			this.editingCartClient.contractChanges,
			this.editingCartClient.contractSwitches,
			this.editingCartClient.contractProlongations,
			this.editingCartClient.contractCotermProlongations
		).filter(Boolean);
	}

	get trainingCartClients(): CartLineClient[] {
		return this.editingCartClient.trainingProducts;
	}

    get microServiceProducts(): CartLineClient[] {
        return this.editingCartClient.microServiceProducts;
    }

	get expertCartClients(): CartLineClient[] {
		return this.editingCartClient.expertProducts;
	}

	get cartLineClientsByType(): CartLineClient[] {
		return uniqBy(this.cartLineClients, "cartLineType");
	}

	get cartLineClients(): CartLineClient[] {
		return concat(
			this.editingCartClient.products,
			this.editingCartClient.servicePassProducts,
			this.editingCartClient.support,
			this.editingCartClient.walletDeposits,
		).filter(Boolean);
	}

	@Watch("isEditable") isEditableChanged(val: boolean): void {
		if (val) {
			this.cartStore.cartClient = this.cartClient;
		}
	}

	created(): void {
		this.cartStore.cartClient = this.cartClient;
	}

	getCartLinesForType(cartLineType: CartLineType): CartLineClient[] {
		return this.cartLineClients.filter((x) => x.cartLineType === cartLineType);
	}

	getUpperCaseCartLineType(cartLineType: CartLineType): string {
		return upperFirst(this.cartLineTypeEnum[cartLineType]);
	}
}
</script>

<style scoped lang="scss"></style>
