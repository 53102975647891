<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<h2 v-if="vm.title" class="display-4" :class="{ 'mb-0': vm.subTitle }" v-html="vm.title"></h2>
			<p v-if="vm.subTitle" class="lead mb-2">{{ vm.subTitle }}</p>
			<div class="row">
				<div
					v-for="(item, index) in vm.items"
					:key="`linkToItem_${index}`"
					class="col-md-6"
					:class="{ 'col-lg-3': vm.itemsPerRow === 4, 'col-lg-4': vm.itemsPerRow === 3 }"
				>
					<Card>
						<template #header>
							<img v-lazy="item.imageUrl" :alt="item.imageAlt" height="250" />
						</template>
						<template #content>
							<h3 v-if="item.title" class="mb-1">{{ item.title }}</h3>
							<p v-html="item.text"></p>
							<LinkButton
								v-if="item.linkItem"
								class="p-button-link p-button-arrow mt-2"
								:label="item.linkItem.text"
								:target="item.linkItem.target"
								:title="item.linkItem.title"
								:url="item.linkItem.url"
							/>
						</template>
					</Card>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { LinkToBlockViewModel } from "@/types/viewModels/blocks/linkToBlockViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component
export default class LinkToBlock extends BaseBlock {
	@Prop({ type: Object as PropType<LinkToBlockViewModel>, required: true, default: {} })
	vm!: LinkToBlockViewModel;

	created(): void {
		Log.info("LinkToBlock", this.vm);
	}
}
</script>

<style lang="scss" scoped>
:deep(.p-card) {
	padding: 0;
	box-shadow: none;
	background: initial;

	.p-card-body {
		padding: 0;
	}

	.p-card-content {
		padding: 1rem 0;
	}

	.p-card-header {
		img {
			object-fit: cover;
		}
	}
}
</style>
