import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lead text-bold mb-0" }
const _hoisted_2 = { class: "lead mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    closable: true,
    header: _ctx.$t('expert.completeBookingModal.header'),
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCancel()))
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.confirm'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("expert.completeBookingModal.introTitle")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("expert.completeBookingModal.intro")), 1)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}