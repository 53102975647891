<template>
	<Message v-if="showCompletionSteps" class="message-with-button" :closable="false" severity="custom">
		<div>
			<p class="mb-0">{{ vm.stepsTitle }}</p>
			<span class="font-weight-normal mb-1" v-html="vm.steps[activeStepIndex]?.text"></span>
			<small class="font-weight-normal d-block">
				{{ $t("common.stepXOfY", [activeStepIndex + 1, vm.steps.length]) }}
			</small>
		</div>
		<div class="d-flex flex-column mx-1">
			<Button
				v-if="activeStepIndex > 0"
				class="p-button-sm p-button-text flex-shrink-0"
				:label="$t('common.previous')"
				@click="activeStepIndex--"
			/>
			<Button
				v-if="activeStepIndex < vm.steps.length - 1"
				class="p-button-sm flex-shrink-0"
				:label="$t('common.nextStep')"
				@click="activeStepIndex++"
			/>
		</div>
	</Message>

	<div class="d-flex justify-content-between flex-wrap">
		<h1 v-if="vm.pageTitle" class="mb-0">{{ vm.pageTitle }}</h1>
		<LinkButton
			v-if="isProfileApproved && expertProfile?.contentLinkUrl"
			class="flex-shrink-0 p-button-text"
			icon="pi pi-external-link"
			:label="$t('myCadac.expertProfile.btnViewProfile')"
			target="_blank"
			:url="expertProfile?.contentLinkUrl"
		/>
	</div>
	<div class="d-flex align-items-center mb-2">
		<p class="font-weight-bold mb-0">{{ $t("myCadac.expertSettings.titleChooseLanguage") }}</p>
		<LanguageDropdown :languages="availableLanguages" @language-selected="onProfileLanguageChanged" />
	</div>

	<Panel class="mb-2">
		<template #header>
			<div class="d-flex align-items-start">
				<h3 class="mb-1">{{ $t("myCadac.expertSettings.titleExpertLevel") }}</h3>
			</div>
		</template>

		<div class="">
			<div v-if="expertProfile?.expertLevelProduct">
				<label>{{ $t("myCadac.expertSettings.lblCurrentLevel") }}</label>
				<div class="d-flex">
					<PopoverIcon :open-delay="500" placement="right">
						<Avatar
							:alt="expertProfile?.expertLevelProduct?.iconAlt"
							:image="expertProfile?.expertLevelProduct?.iconUrl"
							shape="square"
							size="large"
						/>
						<template #content>
							<p class="text-bold mb-0" v-html="expertProfile?.expertLevelProduct?.displayName" />
							<div v-html="expertProfile?.expertLevelProduct?.description" />
						</template>
					</PopoverIcon>
					<div class="ml-1">
						<h4 class="text-bold mb-0">{{ expertProfile?.expertLevelProduct?.displayName }}</h4>
						<p class="mb-0">
							{{
								$t("expert.expertLevelHourlyPriceRange", [
									formatCurrency(expertProfile?.expertLevelProduct?.minPrice),
									formatCurrency(expertProfile?.expertLevelProduct?.maxPrice),
								])
							}}
						</p>
					</div>
				</div>
			</div>
			<Button
				v-if="!expertProfile?.expertLevelProduct"
				class="p-button-success"
				:label="$t('myCadac.expertSettings.btnChooseLevel')"
				@click="expertStore.showModal('ExpertLevel')"
			/>
			<Button
				v-else
				class="p-button-text px-0"
				:label="$t('myCadac.expertSettings.btnChooseOtherLevel')"
				@click="expertStore.showModal('ExpertLevel')"
			/>
			<div v-if="expertProfile?.requestedLevelProduct" class="mt-2">
				<label>{{ $t("myCadac.expertSettings.lblRequestedLevel") }}</label>
				<div class="d-flex">
					<PopoverIcon :open-delay="500" placement="right">
						<Avatar
							:alt="expertProfile?.requestedLevelProduct?.iconAlt"
							:image="expertProfile?.requestedLevelProduct?.iconUrl"
							shape="square"
							size="large"
						/>
						<template #content>
							<p class="text-bold mb-0" v-html="expertProfile?.requestedLevelProduct?.displayName" />
							<div v-html="expertProfile?.requestedLevelProduct?.description" />
						</template>
					</PopoverIcon>
					<div class="ml-1">
						<h4 class="text-bold mb-0">{{ expertProfile?.requestedLevelProduct?.displayName }}</h4>
						<p class="mb-0">
							{{
								$t("expert.expertLevelHourlyPriceRange", [
									formatCurrency(expertProfile?.requestedLevelProduct?.minPrice),
									formatCurrency(expertProfile?.requestedLevelProduct?.maxPrice),
								])
							}}
						</p>
						<small class="text-info">{{
							$t("myCadac.expertSettings.message.awaitingExpertLevelApproval")
						}}</small>
					</div>
				</div>
			</div>
		</div>
	</Panel>

	<Panel class="mb-2">
		<template #header>
			<div class="d-flex align-items-start">
				<h3 class="mb-1">{{ $t("myCadac.expertSettings.titleOverview") }}</h3>
			</div>
		</template>
		<template #icons>
			<Button
				v-if="!isEditingExpertOverview"
				class="p-button-text px-0"
				icon="pi pi-pencil"
				:label="$t('common.edit')"
				@click.stop="isEditingExpertOverview = true"
			/>
			<Button
				v-else
				class="p-button-text p-button-primary"
				:label="$t('common.cancel')"
				@click.stop="isEditingExpertOverview = false"
			/>
		</template>
		<Message v-if="!isEditingExpertOverview && hasEmptyOverview" :closable="false" severity="warn">
			{{ $t("myCadac.expertSettings.message.noOverview") }}
		</Message>
		<ExpertOverviewForm
			v-else
			:is-editing="isEditingExpertOverview"
			:vm="expertProfile"
			@submit="saveExpertOverview"
		/>
	</Panel>

	<Panel class="mb-2">
		<template #header>
			<div class="d-flex align-items-start">
				<h3 class="mb-1">{{ $t("myCadac.expertSettings.titleLanguages") }}</h3>
			</div>
		</template>
		<template #icons>
			<Button
				v-if="!isEditingExpertLanguages"
				class="p-button-text"
				icon="pi pi-pencil"
				:label="$t('common.edit')"
				@click="isEditingExpertLanguages = true"
			/>
			<Button
				v-else
				class="p-button-text p-button-primary"
				:label="$t('common.cancel')"
				@click="
					isEditingExpertLanguages = false;
					initLanguages();
				"
			/>
		</template>
		<Message v-if="!isEditingExpertLanguages && !selectedLanguages?.length" :closable="false" severity="warn">
			{{ $t("myCadac.expertSettings.message.noLanguages") }}
		</Message>
		<div v-if="!isEditingExpertLanguages" class="flex align-items-center flex-column">
			<Chip
				v-for="language in selectedLanguages"
				:key="language"
				class="mr-1 mb-1 p-chip-secondary"
				:label="language"
			/>
		</div>
		<div v-else class="pl-1 pt-2 d-flex flex-column">
			<label>{{ `${$t("myCadac.expertSettings.selectLanguage")}` }}</label>
			<MultiSelect
				v-if="isEditingExpertLanguages"
				v-model="selectedLanguages"
				display="chip"
				:filter="false"
				option-label="value"
				option-value="value"
				:options="vm.languageOptions"
				:placeholder="$t('myCadac.expertSettings.selectLanguage_placeholder')"
				:show-toggle-all="false"
			>
				<template #value="slotProps">
					<Chip
						v-for="language in slotProps.value"
						:key="language"
						class="mr-1 p-chip-primary"
						:label="language"
					/>
					<template v-if="!slotProps.value || slotProps.value.length === 0">
						{{ $t("myCadac.expertSettings.selectLanguage_placeholder") }}
					</template>
				</template>
				<template #option="slotProps">
					<div class="country-item">
						<div>{{ slotProps.option.value }}</div>
					</div>
				</template>
			</MultiSelect>
			<div class="mt-2">
				<Button class="" :label="$t(`common.save`)" @click="saveExpertLanguages" />
			</div>
		</div>
	</Panel>

	<!-- panel 4-->
	<Panel class="mb-2">
		<template #header>
			<div class="d-flex align-items-start">
				<h3 class="mb-1">{{ $t("myCadac.expertSettings.titleExpertises") }}</h3>
			</div>
		</template>
		<template #icons>
			<Button
				v-if="!isEditingExpertises"
				class="p-button-text"
				icon="pi pi-pencil"
				:label="$t('common.edit')"
				@click="isEditingExpertises = true"
			/>
			<Button
				v-else
				class="p-button-text p-button-primary"
				:label="$t('common.cancel')"
				@click="
					isEditingExpertises = false;
					initExpertises();
				"
			/>
		</template>
		<Message v-if="!isEditingExpertises && !selectedExpertises?.length" :closable="false" severity="warn">
			{{ $t("myCadac.expertSettings.message.noExpertises") }}
		</Message>
		<div v-if="!isEditingExpertises" class="flex align-items-center flex-column">
			<Chip
				v-for="expertise in selectedExpertises"
				:key="expertise.name"
				class="mr-1 mb-1 p-chip-secondary"
				:label="expertise.name"
			/>
		</div>
		<div v-else class="pl-1 pt-2 d-flex flex-column">
			<label class="mt-1">{{ `${$t("myCadac.expertSettings.selectExpertises")}` }}</label>
			<div class="p-inputgroup mb-2">
				<AutoComplete
					v-model="selectedExpertises"
					force-selection
					:min-length="2"
					:multiple="true"
					option-label="name"
					:placeholder="$t('myCadac.expertSettings.selectExpertises_placeholder')"
					:suggestions="searchExpertiseResults"
					@complete="searchExpertises($event)"
				>
					<template #option="{ option }">
						<div class="d-flex">
							<p class="mb-0 mr-1 text-bold">
								{{ option?.name }}
							</p>
							<p class="mb-0">({{ option?.category }})</p>
						</div>
					</template>
				</AutoComplete>
				<span class="p-inputgroup-addon">
					<i class="pi pi-search" />
				</span>
			</div>
			<div class="mt-2">
				<Button class="" :label="$t(`common.save`)" @click="saveExpertExpertises" />
			</div>
		</div>
	</Panel>

	<Panel class="mb-2">
		<template #header>
			<div class="d-flex align-items-start">
				<h3 class="mb-1">{{ $t("myCadac.expertSettings.titleExperience") }}</h3>
			</div>
		</template>
		<template #icons>
			<Button
				class="p-button-text px-0"
				icon="pi pi-plus"
				:label="$t('common.add')"
				@click.stop="onAddExperienceClick()"
			/>
		</template>
		<Accordion
			v-if="expertExperiences?.length"
			v-model:active-index="experiencesActiveTabIndexes"
			:multiple="true"
		>
			<AccordionTab v-for="experience in expertExperiences" :key="experience">
				<template #header>
					<div class="d-flex justify-content-between align-items-center w-100">
						<div class="d-flex align-items-center">
							<p class="mr-1 mb-0">
								{{ experience.title }}
							</p>
						</div>
					</div>
					<Button
						v-if="!editingExperiences[experience.id]"
						class="p-button-outlined p-button-rounded border"
						icon="pi pi-pencil"
						@click.stop="editingExperiences[experience.id] = true"
					/>
					<Button
						v-else
						class="p-button-text"
						:label="$t('common.cancel')"
						@click.stop="editingExperiences[experience.id] = false"
					/>
					<Button
						v-if="!editingExperiences[experience.id]"
						class="p-button-danger p-button-outlined p-button-rounded border"
						icon="pi pi-times"
						@click.stop="confirmRemoveExperience(experience)"
					/>
				</template>
				<ExpertExperienceForm
					:is-editing="editingExperiences[experience.id]"
					:vm="experience"
					@submit="saveExpertExperience"
				/>
			</AccordionTab>
		</Accordion>
		<Message v-else :closable="false" severity="warn">
			{{ $t("myCadac.expertSettings.message.noExperiences") }}
		</Message>
	</Panel>

	<Panel class="mb-2">
		<template #header>
			<div class="d-flex align-items-start">
				<h3 class="mb-1">{{ $t("myCadac.expertSettings.titleCertifications") }}</h3>
			</div>
		</template>
		<template #icons>
			<Button
				v-if="!isEditingCertifications"
				class="p-button-text"
				icon="pi pi-pencil"
				:label="$t('common.edit')"
				@click="isEditingCertifications = true"
			/>
			<Button
				v-else
				class="p-button-text"
				:label="$t('common.cancel')"
				@click.stop="
					isEditingCertifications = false;
					initCertifications();
				"
			/>
		</template>
		<div v-if="isEditingCertifications" class="pl-1 pt-2 d-flex flex-column">
			<label>{{ `${$t("myCadac.expertSettings.selectCertifications")}` }}</label>
			<MultiSelect
				v-if="isEditingCertifications"
				v-model="selectedCertifications"
				:filter="false"
				option-label="label"
				option-value="value"
				:options="availableCertifications"
				:placeholder="$t('myCadac.expertSettings.selectCertifications_placeholder')"
				:show-toggle-all="false"
			>
				<template #value="slotProps">
					<Chip
						v-for="certification in slotProps.value"
						:key="certification"
						class="mr-1 p-chip-primary"
						:label="certification.level"
					/>
					<template v-if="!slotProps.value || slotProps.value.length === 0">
						{{ $t("myCadac.expertSettings.selectCertifications_placeholder") }}
					</template>
				</template>
				<template #option="slotProps">
					<p>{{ slotProps.option.label }}</p>
				</template>
			</MultiSelect>

			<div class="mt-2">
				<Button :label="$t(`common.save`)" @click="saveExpertCertifications" />
			</div>
		</div>
		<div v-else-if="selectedCertifications?.length" class="flex align-items-center flex-column">
			<Chip
				v-for="certification in selectedCertifications"
				:key="certification"
				class="mr-1 mb-1 p-chip-primary"
				:label="certification.level"
			/>
		</div>
		<Message v-else :closable="false" severity="warn">
			{{ $t("myCadac.expertSettings.message.noCertifications") }}
		</Message>
	</Panel>

	<ExpertLevelModal
		v-if="expertStore.isLevelModalVisible"
		:current-level="expertProfile?.expertLevelProduct"
		:options="vm.allExpertLevelProducts"
		:requested-level="expertProfile?.requestedLevelProduct"
		@level-selected="saveExpertLevel"
	/>
</template>

<script lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AutoComplete from "primevue/autocomplete";
import BaseComponent from "@/components/base/baseComponent.vue";
import Chip from "primevue/chip";
import CtaBlockModal from "@/components/common/ctaBlockModal.vue";
import ExpertExperienceForm from "@/components/experts/expertExperienceForm.vue";
import ExpertLevelModal from "@/components/experts/expertLevelModal.vue";
import ExpertOverviewForm from "@/components/experts/expertOverviewForm.vue";
import LanguageDropdown from "@/components/common/menu/languageDropdown.vue";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import ProgressBar from "primevue/progressbar";
import useVuelidate from "@vuelidate/core";
import { AddOrUpdateExperienceExpertSettingsRequest } from "@/types/models/expert/addOrUpdateExperienceExpertSettingsRequest";
import { Component, Prop } from "vue-facing-decorator";
import { DeleteExperienceExpertSettingsRequest } from "@/types/models/expert/deleteExperienceExpertSettingsRequest";
import { ExpertCertification } from "@/types/generated/PropertyListTypes/expertCertification";
import { ExpertExperienceBlockViewModel } from "@/types/models/expert/expertExperienceBlockViewModel";
import { ExpertExperienceType } from "@/types/enum/expertExperienceType";
import { ExpertExpertiseBlockViewModel } from "@/types/models/expert/expertExpertiseBlockViewModel";
import { ExpertLevelProductViewModel } from "@/types/models/expert/expertLevelProductViewModel";
import { ExpertPageState } from "@/types/enum/expertPageState";
import { ExpertProfileViewModel } from "@/types/models/expert/expertProfileViewModel";
import { ExpertSettingsOverviewForm } from "@/types/models/form/expertSettingsOverviewForm";
import { ExpertiseSearch } from "@/types/generated/Api/expertiseSearch";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { IExpertSettings } from "@/types/viewModels/myCadac/expertSettingsViewModel";
import { LocationOptionViewModel } from "@/types/models/menu/locationOptionViewModel";
import { PropType } from "vue";
import { SaveExpertisesExpertSettingsRequest } from "@/types/models/expert/saveExpertisesExpertSettingsRequest";
import { SaveOverviewExpertSettingsRequest } from "@/types/models/expert/saveOverviewExpertSettingsRequest";
import { SaveSpokenLanguagesExpertSettingsRequest } from "@/types/models/expert/saveSpokenLanguagesExpertSettingsRequest";
import { UpdateCertificationsExpertSettingsRequest } from "@/types/models/expert/updateCertificationsExpertSettingsRequest";
import { UpdateExpertLevelExpertSettingsRequest } from "@/types/models/expert/updateExpertLevelExpertSettingsRequest";
import { reduce } from "lodash";
import { useDialog } from "primevue/usedialog";
import { useExpertStore } from "@/store/expert/expertStore";
import { zonedTimeToUtc } from "date-fns-tz";
import { Log } from "@/types/helpers/logHelper";

@Component({
	components: {
		CtaBlockModal,
		Accordion,
		AccordionTab,
		AutoComplete,
		Chip,
		ExpertExperienceForm,
		ExpertLevelModal,
		ExpertOverviewForm,
		LanguageDropdown,
		MultiSelect,
		Panel,
		PopoverIcon,
		ProgressBar,
	},
})
export default class ExpertSettings extends BaseComponent {
	@Prop({ type: Object as PropType<IExpertSettings>, required: true, default: {} })
	vm!: IExpertSettings;

	editingExperiences = {};
	isEditingCertifications = false;
	isEditingExpertLanguages = false;
	isEditingExpertLevel = false;
	isEditingExpertOverview = false;
	isEditingExpertises = false;

	activeStepIndex = 0;
	availableCertifications: IDropdownItem[] = [];
	availableLanguages: LocationOptionViewModel[] = [];
	experiencesActiveTabIndexes: number[] = [];
	expertExperiences!: ExpertExperienceBlockViewModel[];
	searchExpertiseResults: ExpertExpertiseBlockViewModel[] = [];
	searchExpertisesQuery = "";
	selectedCertifications: ExpertCertification[] = [];
	selectedExpertises: ExpertExpertiseBlockViewModel[] = [];
	selectedLanguages: string[] = [];
	selectedProfileLanguage!: LocationOptionViewModel;

	v$ = useVuelidate();
	expertStore = useExpertStore();
	dialog = useDialog();

	created(): void {
		this.availableCertifications = this.vm.availableCertifications.map((x) => ({ label: x.level, value: x }));
		this.availableLanguages = this.vm.languageOptions.map((x) => ({
			code: x.key,
			name: x.value,
			redirectUrl: "",
		}));
		this.selectedProfileLanguage = this.availableLanguages.find(
			(x) => x.code === this.layoutStore.vm.currentLanguage?.key
		) as LocationOptionViewModel;

		this.initOverview();
		this.initLanguages();
		this.initExpertises();
		this.initExperiences();
		this.initCertifications();
	}

	get expertProfile(): ExpertProfileViewModel {
		return this.expertStore.expertProfile as ExpertProfileViewModel;
	}

	get editingLanguage(): string {
		return this.selectedProfileLanguage?.code;
	}

	get showCompletionSteps(): boolean {
		return this.expertProfile.state === ExpertPageState.created && !this.isProfileComplete;
	}

	get isProfileComplete(): boolean {
		return this.expertProfile?.profileCompletionPercentage === 100;
	}

	get isProfileApproved(): boolean {
		return this.expertProfile.state === ExpertPageState.approved;
	}

	get hasEmptyOverview(): boolean {
		return (
			!this.expertProfile?.description &&
			!this.expertProfile?.function &&
			!this.expertProfile?.shortDescription &&
			!this.expertProfile?.shortDescriptionTitle
		);
	}

	initOverview(): void {
		this.expertStore.expertProfile = this.vm.expertProfile;
	}

	initLanguages(): void {
		this.selectedLanguages = this.expertProfile.spokenLanguages;
	}

	initCertifications(): void {
		this.selectedCertifications = this.expertProfile.certifications;
	}

	initExperiences(): void {
		this.expertExperiences = reduce(
			this.expertProfile.experiences,
			(val, next) => {
				next.experienceStartDate = zonedTimeToUtc(next.experienceStartDate, "UTC");
				if (next.experienceEndDate) next.experienceEndDate = zonedTimeToUtc(next.experienceEndDate, "UTC");
				val.push(next);
				return val;
			},
			[] as ExpertExperienceBlockViewModel[]
		);
	}

	initExpertises(): void {
		this.selectedExpertises = this.expertProfile.expertises;
	}

	onAddExperienceClick(): void {
		this.dialog.open(ExpertExperienceForm, {
			props: {
				header: this.$t("myCadac.expertSettings.titleNewExperience"),
				modal: true,
			},
			data: {
				vm: {} as ExpertExperienceBlockViewModel,
			},
			onClose: (options) => {
				const data = options?.data;
				if (data) {
					this.saveExpertExperience(data);
				}
			},
		});
	}

	saveExpertLevel(level: ExpertLevelProductViewModel): void {
		const model: UpdateExpertLevelExpertSettingsRequest = {
			editingLanguage: this.editingLanguage,
			expertLevelContentId: level.expertProductContentId,
		};
		this.axios
			.post("/api/expert/settings/update-expertLevel", model, { requestId: "saveExpertLevel" })
			.then(this.fetchExpertProfile)
			.then(() => {
				this.isEditingExpertLevel = false;
				this.expertStore.hideModal("ExpertLevel");
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertLevelSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertLevelError"),
					life: 3000,
				});
			});
	}

	saveExpertOverview(expertOverviewForm: ExpertSettingsOverviewForm): void {
		const model: SaveOverviewExpertSettingsRequest = {
			expertFunction: expertOverviewForm.expertFunction,
			shortDescriptionTitle: expertOverviewForm.shortDescriptionTitle,
			shortDescription: expertOverviewForm.shortDescription,
			description: expertOverviewForm.description,
			editingLanguage: this.editingLanguage,
		};
		this.axios
			.post("/api/expert/settings/save-overview", model, { requestId: "saveOverview" })
			.then(this.fetchExpertProfile)
			.then(() => {
				this.isEditingExpertOverview = false;
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertOverviewSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertOverviewError"),
					life: 3000,
				});
			});
	}

	saveExpertLanguages(): void {
		const model: SaveSpokenLanguagesExpertSettingsRequest = {
			editingLanguage: this.editingLanguage,
			spokenLanguages: this.selectedLanguages,
		};

		this.axios
			.post("/api/expert/settings/save-languages", model, { requestId: "saveLanguages" })
			.then(this.fetchExpertProfile)
			.then(() => {
				this.isEditingExpertLanguages = false;
				this.initLanguages();
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertLanguagesSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertLanguagesError"),
					life: 3000,
				});
			});
	}

	saveExpertExpertises(): void {
		const model: SaveExpertisesExpertSettingsRequest = {
			editingLanguage: this.editingLanguage,
			expertiseIds: this.selectedExpertises.map((x) => x.id),
		};
		this.axios
			.post("/api/expert/settings/save-expertise", model, { requestId: "saveExpertises" })
			.then(this.fetchExpertProfile)
			.then(() => {
				this.isEditingExpertises = false;
				this.initExpertises();
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertExpertisesSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertExpertisesError"),
					life: 3000,
				});
			});
	}

	saveExpertExperience(experience: ExpertExperienceBlockViewModel): void {
		const model: AddOrUpdateExperienceExpertSettingsRequest = {
			editingLanguage: this.editingLanguage,
			blockId: experience.id,
			company: experience.company,
			description: experience.description,
			experienceStartDateMonth: experience.experienceStartDate.getMonth() + 1,
			experienceStartDateYear: experience.experienceStartDate.getFullYear(),
			experienceEndDateMonth: experience.experienceEndDate
				? experience.experienceEndDate?.getMonth() + 1
				: undefined,
			experienceEndDateYear: experience.experienceEndDate?.getFullYear(),
			title: experience.title,
			type: ExpertExperienceType[experience.type],
		};

		this.axios
			.post("/api/expert/settings/save-experience", model, { requestId: "saveExperience" })
			.then(this.fetchExpertProfile)
			.then(() => {
				if (model.blockId) this.editingExperiences[model.blockId] = false;
				this.initExperiences();
				this.experiencesActiveTabIndexes.push(
					this.expertExperiences.findIndex((x) => x.title === model.title)
				);
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: model.blockId
						? this.$t("myCadac.expertSettings.toast.updateExpertExperienceSuccess", [model.title])
						: this.$t("myCadac.expertSettings.toast.addExpertExperienceSuccess", [model.title]),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: model.blockId
						? this.$t("myCadac.expertSettings.toast.updateExpertExperienceError", [model.title])
						: this.$t("myCadac.expertSettings.toast.addExpertExperienceError", [model.title]),
					life: 3000,
				});
			});
	}

	confirmRemoveExperience(experience: ExpertExperienceBlockViewModel): void {
		const model: DeleteExperienceExpertSettingsRequest = {
			blockId: experience.id || 0,
			editingLanguage: this.editingLanguage,
		};
		this.$confirm.require({
			message: this.$t("myCadac.expertSettings.confirmRemoveExperience", [experience.title]),
			header: this.$t("common.messages.titleImportant"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.axios
					.post("/api/expert/settings/delete-experience", model, { requestId: "removeExperience" })
					.then(this.fetchExpertProfile)
					.then(() => {
						this.initExperiences();
						this.$toast.add({
							severity: "success",
							summary: this.$t("common.messages.titleSuccess"),
							detail: this.$t("myCadac.expertSettings.toast.deleteExpertExperienceSuccess", [
								experience.title,
							]),
							life: 3000,
						});
					})
					.catch((err) => {
						Log.error(err);
						this.$toast.add({
							severity: "error",
							summary: this.$t("common.messages.titleError"),
							detail: this.$t("myCadac.expertSettings.toast.deleteExpertExperienceError", [
								experience.title,
							]),
							life: 3000,
						});
					});
			},
		});
	}

	saveExpertCertifications(): void {
		const model: UpdateCertificationsExpertSettingsRequest = {
			certifications: this.selectedCertifications,
			editingLanguage: this.editingLanguage,
		};
		this.axios
			.post("/api/expert/settings/save-certifications", model, { requestId: "saveCertifications" })
			.then(this.fetchExpertProfile)
			.then(() => {
				this.isEditingCertifications = false;
				this.initCertifications();
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertCertificationsSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.updateExpertCertificationsError"),
					life: 3000,
				});
			});
	}

	searchExpertises({ query }: { query: string }): void {
		this.searchExpertisesQuery = query;
		this.axios
			.get(`/api/search/expertises`, { params: { search: query }, requestId: "searchExpertise" })
			.then(({ data }: { data: ExpertiseSearch[] }) => {
				this.searchExpertiseResults = data
					.map((x) => ({ id: x.value, name: x.name, category: x.category }))
					.filter((x) => this.selectedExpertises?.find((y) => y.id !== x.id) || true);
			})
			.catch((err) => Log.error(err));
	}

	onProfileLanguageChanged(lang: LocationOptionViewModel): void {
		this.selectedProfileLanguage = lang;
		this.fetchExpertProfile().then(() => {
			this.initExpertises();
			this.initExperiences();
			this.initCertifications();
		});
	}

	private fetchExpertProfile() {
		return this.expertStore.fetchExpertProfile(this.expertProfile.cadacAccountId, this.editingLanguage);
	}
}
</script>

<style scoped lang="scss"></style>
