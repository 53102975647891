<template>
	<ProductLayout v-if="isAutodeskProduct" v-model="showSideBar" :help-block="selectedHelpBlock" :vm="vm">
		<template #tabs>
			<div class="container my-5" v-if="vm.autodeskInfoBlock?.isVisible && vm.autodeskInfoBlock?.video && vm.autodeskInfoBlock?.title">
				<div class="left-pane">
					<video autoplay loop muted playsinline class="animation-video">								
						<source :src="vm.autodeskInfoBlock.video" type="video/mp4">
						Your browser does not support the video tag.
					</video>						
				</div>
				<div class="right-pane d-none d-sm-flex">							
					<h2>{{ vm.autodeskInfoBlock.title }}</h2>
					<p>{{ vm.autodeskInfoBlock.text }}</p>
				</div>
			</div>
		
			<ProductPageContentSection v-if="hasTab('tabInfo')">
				<h3>{{ vm.productInfoTabTitle || $t("product.tabProductInfoTitle") }} {{ vm.name }}</h3>
				<div class="rte" v-html="vm.productInfoTab" />
			</ProductPageContentSection>
			<ProductPageContentSection v-if="$slots.blocks" type="content">
				<slot name="blocks" />
			</ProductPageContentSection>
			<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
				<h3 class="">{{ $t("product.titleUpSell") }}</h3>
				<div class="row">
					<div v-for="product in vm.relatedUpSellProducts" :key="product" class="col-md-6 mb-2">
						<BaseProductOverviewItemCard is-cross-sell :vm="product" />
					</div>
				</div>
			</ProductPageContentSection>

			
			<ProductPageContentSection v-if="vm.autodeskSupportBlock?.isVisible && vm.autodeskSupportBlock?.items?.length" type="content">

				<h3 class="">{{ vm.autodeskSupportBlock.title }}</h3>
				<div class="row">
					<div v-for="item in vm.autodeskSupportBlock.items" :key="item" class="col-md-6 mb-2">
						<Card class="support-card">
							<template #header>
								<font-awesome-icon class="support-icon mt-3" :icon="item.icon" size="2x" />
							</template>
							<template #title>
								{{item.title}}
							</template>
							<template #content>
								{{item.text}}
							</template>
							<template #footer>
								<Button class="search-button w-100 p-button-raised"
										:label="item.buttonText"
										:title="item.buttonText"
										:url="item.buttonLink?.url" />
							</template>
						</Card>
					</div>
				</div>
			</ProductPageContentSection>
		</template>

		<template #summary>
			<ProductPageSummary :can-submit="!submitted && validateProductConfiguration() && vm.availableInCurrentMarket"
								:is-in-edit-mode="isInEditMode"
								:is-autodesk-product="isAutodeskProduct"
								:is-product-available="vm.viewModelIsValid && !isFlexLicense"
								:max-amount="productStore.selectedVariation?.maxQuantity"
								:min-amount="productStore.selectedVariation?.minQuantity"
								:offer-request-form-url="vm.productGuid"
								show-amount-input
								:total-price="priceSummary?.totalPrice"
								:value="productStore?.selectedAmount"
								:variation-guid="productStore.selectedVariation?.guid"
								:variation-id="productStore.selectedVariation?.id"
								@addToCart="addProductToCart"
								@amountChanged="onSelectedAmountChanged"
								@requestAutodeskQuote="onRequestAutodeskQuote">
				<template #priceSummary>
					<div v-if="isSmallScreen">
						<div class="p-2">
							<div class="d-flex flex-column">
								<div class="d-flex flex-row w-100 pt-2 justify-content-between">
									<div class="price-text">
										{{ formatCurrency(priceSummary.basePrice)  }}	
									</div>
									<InputNumber v-model="selectedAmount"
												 class=""
												 decrement-button-class="p-button-outlined"
												 increment-button-class="p-button-outlined"
												 :max="maxAmount"
												 :min="minAmount"
												 show-buttons
												 style="width: clamp(125px, 25%, 175px); height: 59px; margin-top: -8px"
												 :use-grouping="false"
												 @input="amountChanged" />
								</div>
								<!-- duration 12 or 36 months; commented because not in design -->
								<!--<div class="d-flex flex-row pt-1 justify-content-around">
									<ProductConfigurationOption v-for="(option, index) in productStore.availableDurations"
																class="duration"
																:key="index"
																:disabled="isBusy"
																:is-selected="option.value === productStore.selectedDuration"
																:option="option"
																@option-selected="onDurationSelected(option.value)">
									</ProductConfigurationOption>
								</div>-->

								<div v-if="vm.autodeskLoyaltyCashbackBlock?.isVisible && parseInt(productStore.walletAmount) !== 0"
									 class="wallet-text-small">
									{{$t('quotation.product.wallet.label')}} {{formatCurrency(productStore.walletAmount) }}
								</div>
								<Button v-if="isAutodeskProduct"
										class="p-button-success p-button-lg p-button-raised p-button-arrow w-100 px-1 my-1"
										:label="$t(`product.button.quote`)"
										:disabled="submitted || !validateProductConfiguration() || !vm.availableInCurrentMarket"
										@click="onRequestAutodeskQuote()" />								
							</div>
						</div>
					</div>
					<div v-else>
						<p class="mb-0">
							{{ vm.brand }}
						</p>
						<h1>{{ formatProductName(vm.name, vm.brand) }}</h1>

						<div class="my-3">
							<p class="text-bold mb-1">{{ $t("common.productSummary.amount") }}</p>
							<InputNumber v-model="selectedAmount"
										 class="mr-2"
										 decrement-button-class="p-button-outlined"
										 increment-button-class="p-button-outlined"
										 :max="maxAmount"
										 :min="minAmount"
										 show-buttons
										 style="width: clamp(125px, 25%, 175px); height: 59px; margin-top: -8px"
										 :use-grouping="false"
										 @input="amountChanged" />
						</div>
						<div class="my-3">
							<p class="text-bold mb-1">{{ $t("common.productSummary.duration") }}</p>
							<div class="duration">
								<ProductConfigurationOption v-for="(option, index) in productStore.availableDurations"
															class="duration"
															:key="index"
															:disabled="isBusy"
															:is-selected="option.value === productStore.selectedDuration"
															:option="option"
															@option-selected="onDurationSelected(option.value)">
								</ProductConfigurationOption>
							</div>
						</div>

						<hr class="my-2" />

						<h4 v-if="vm.basePrice" class="text-primary autodesk-price">
							<span class="price-label">
								{{ $t("common.priceSummary.basePrice") }}
							</span>
							<span>
								<b>{{ formatCurrency(priceSummary.basePrice) }}</b>
							</span>
						</h4>

					</div>
				</template>
				<template #configurationSummary>
				</template>
				<template #validationMessage>
					<Message v-if="validationErrors.length && submitted"
							 :closable="false"
							 severity="warn"
							 style="cursor: pointer"
							 @click="scrollToFirstValidationError()">
						{{ $tc("common.productSummary.msgValidationErrors", validationErrors.length) }}
					</Message>
				</template>
				<template #walletMessage>
					<div class="wallet-card-container"
						 v-if="vm.autodeskLoyaltyCashbackBlock?.isVisible && parseInt(productStore.walletAmount) !== 0"
						 :style="{'background-image': `url('/Static/images/stars-background.jpg')`}">
						<div class="wallet-card-extended">
							<img v-if="vm.autodeskLoyaltyCashbackBlock?.icon" v-lazy="vm.autodeskLoyaltyCashbackBlock.icon?.url" :alt="vm.autodeskLoyaltyCashbackBlock?.icon?.alt" class="wallet-icon" />

							<div class="wallet-text">
								{{vm.autodeskLoyaltyCashbackBlock?.text}}
							</div>
							<span class="wallet-amount">{{ formatCurrency(productStore.walletAmount) }}</span>
						</div>
						<hr class="mb-1 mt-2 wallet-card-line" />
						<a :href="vm.autodeskLoyaltyCashbackBlock?.link?.url" class="wallet-card-link">
							{{vm.autodeskLoyaltyCashbackBlock?.link?.text}}
						</a>
					</div>
				</template>
			</ProductPageSummary>			
		</template>
		</ProductLayout>
	<ProductLayout v-if="!isAutodeskProduct" v-model="showSideBar" :help-block="selectedHelpBlock" :vm="vm">
		<template #tabs>
			<ProductTabs :items="productTabs">
				<template #tabConfiguration>
					<ProductPageContentSection>
						<Message v-if="vm.advisorInfoPageUrl && vm.brand?.toLowerCase() === 'autodesk'"
									class="advice-banner"
									:closable="false"
									severity="info">
							<div class="d-flex flex-column mx-1">
								<div class="title">{{ $t("digitalAdvisor.banner.titleDigitalAdvisor") }}</div>
								<div class="content">
									{{ $t("digitalAdvisor.banner.digitalAdvisorSummary") }}
								</div>
							</div>

							<Button class="advice-button p-button-success p-button-primary mx-1"
									:label="$t('digitalAdvisor.button.adviceMe')"
									@click="
									openUrl(vm.advisorInfoPageUrl);
									$event.target.disabled = true;
								" />
						</Message>

						<ProductConfiguration v-if="vm.viewModelIsValid">
							<ProductConfigurationStep v-if="productStore.availableLicenseModels?.length > 1"
														id="step-type">
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseLicenceModel") }}</span>
									<Button v-if="vm.helpBlockLicenseType"
											class="header-explanation-text text-underline py-0 p-button-text p-button-secondary text-underline"
											:label="vm.helpBlockLicenseType?.linkText"
											@click="showHelpSideBar(vm.helpBlockLicenseType)" />
								</template>
								<template #error>
									<Message v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
												:id="`validation-msg-${productValidationEnum.ValidContract}`"
												:closable="false"
												severity="error">
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption v-for="(option, index) in productStore.availableLicenseModels"
																:key="index"
																:disabled="isBusy"
																:is-selected="option.value === productStore.selectedLicenseModel"
																:option="option"
																@option-selected="onLicenseModelSelected(option.value)">
									</ProductConfigurationOption>

									<ProductConfigurationFlexLicense v-if="isFlexLicense"
																		:product-name="vm.name"
																		:product-usage-advice="productStore.productUsageAdvice"
																		@select-subscription="onLicenseModelSelected(0)" />

									<Message class="advice-banner" :closable="false" severity="info">
										<div class="d-flex flex-column mx-1">
											<div class="title">
												{{ $t("digitalAdvisor.banner.titleDigitalAdvisor") }}
											</div>
											<div class="content">
												{{ $t("digitalAdvisor.banner.digitalAdvisorSummary") }}
											</div>
										</div>

										<Button class="advice-button p-button-success p-button-raised p-button-primary mx-1"
												:label="$t('digitalAdvisor.button.adviceMe')"
												@click="
												openUrl(vm.advisorInfoPageUrl);
												$event.target.disabled = true;
											" />
									</Message>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep v-if="productStore.availableLicenseClasses?.length > 0 && !isFlexLicense"
														id="step-type">
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseLicenceType") }}</span>
									<Button v-if="vm.helpBlockLicenseType"
											class="header-explanation-text text-underline py-0 p-button-text p-button-secondary text-underline"
											:label="vm.helpBlockLicenseType?.linkText"
											@click="showHelpSideBar(vm.helpBlockLicenseType)" />
								</template>
								<template #error>
									<Message v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
												:id="`validation-msg-${productValidationEnum.ValidContract}`"
												:closable="false"
												severity="error">
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption v-for="(option, index) in productStore.availableLicenseClasses"
																:key="index"
																:disabled="isBusy"
																:is-selected="option.value === productStore.selectedLicenseClass"
																:option="option"
																@option-selected="onLicenseClassSelected(option.value)">
									</ProductConfigurationOption>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep v-if="productStore.availableLicenseSizes?.length > 0 && !isFlexLicense"
														id="step-size">
								<template #header>
									<span class="mr-1">
										{{ vm.unitSelectionTitle || $t("product.lblChooseLicenceSize") }}
									</span>
									<Button v-if="vm.helpBlockProductSize"
											class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
											:label="vm.helpBlockProductSize?.linkText"
											@click="showHelpSideBar(vm.helpBlockProductSize)" />
								</template>
								<template #error>
									<Message v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
												:id="`validation-msg-${productValidationEnum.ValidContract}`"
												:closable="false"
												severity="error">
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption v-for="(option, index) in productStore.availableLicenseSizes"
																:key="index"
																:disabled="isBusy"
																:is-selected="option.value === productStore.selectedLicenseSize"
																:option="option"
																@option-selected="onLicenseSizeSelected(option.value)">
									</ProductConfigurationOption>
								</template>
							</ProductConfigurationStep>

							<ProductConfigurationStep v-if="
									productStore.canAddToContract &&
									productStore.isAddingToContract &&
									!isFlexLicense
								"
														id="step-contract">
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseContract") }}</span>
									<Button v-if="vm.helpBlockContract"
											class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
											:label="vm.helpBlockContract?.linkText"
											@click="showHelpSideBar(vm.helpBlockContract)" />
								</template>
								<ProgressSpinner v-if="isSpinnerVisible('contractTable')"
													animation-duration=".75s"
													class="d-block mr-auto ml-0"
													stroke-width="3" />
								<DataTable v-else
											v-model:selection="productStore.selectedContract"
											auto-layout
											data-key="contractNumber"
											selection-mode="single"
											:value="productStore.contractsForProduct"
											@row-select="onContractSelected">
									<Column class="text-lg-center"
											selection-mode="single"
											style="width: 5%; padding: 1rem; overflow: hidden" />
									<Column key="contractNumber"
											field="contractNumber"
											:header="$t('contract.contractNumber')">
										<template #body="item">
											<p v-if="userProfile?.isCadacSales || userProfile?.isImpersonated"
												class="mb-0 text-bold">
												<a href="javascript:void(0)"
													:title="$t('common.goToCrm')"
													@click="openUrl(item.data.crmUrl, '_blank')">
													{{ item.data.contractNumber }}
												</a>
											</p>
											<p v-else class="mb-0 text-bold">
												{{ item.data.contractNumber }}
											</p>
										</template>
									</Column>
									<Column key="endDate" field="endDate" :header="$t('contract.endDate')">
										<template #body="item">
											<span>
												{{ formatDate(item.data.endDate) }}
											</span>
										</template>
									</Column>
									<Column key="remainingTerm" :header="$t('contract.remainingDuration')">
										<template #body="item">
											<span>
												{{ $tc("common.months", item.data.duration) }}
											</span>
										</template>
									</Column>
									<Column :header="`${$t('common.costs')} ${$t('common.pricePerMonth')}`">
										<template #body="item">
											<span class="text-bold">
												{{ formatCurrency(item.data.customerPrice) }}
											</span>
										</template>
									</Column>
								</DataTable>
								<Button v-if="productStore.canAddToContract"
										class="py-0 mt-2 p-button-text p-button-link"
										:label="$t('product.button.dontAddToExistingContract')"
										@click="toggleAddToContract" />
							</ProductConfigurationStep>

							<ProductConfigurationStep v-else-if="!isFlexLicense" id="step-duration">
								<template #header>
									<span class="mr-1"> {{ $t("product.lblChooseDuration") }}</span>
									<Button v-if="vm.helpBlockDuration"
											class="header-explanation-text text-underline py-0 p-button-text p-button-secondary"
											:label="vm.helpBlockDuration?.linkText"
											@click="showHelpSideBar(vm.helpBlockDuration)" />
								</template>
								<template #error>
									<Message v-if="
											!productValidation.get(productValidationEnum.ValidContract) &&
											submitted
										"
												:id="`validation-msg-${productValidationEnum.ValidContract}`"
												:closable="false"
												severity="error">
										{{ $t("product.message.atleastOneAsset") }}
									</Message>
								</template>
								<template #options>
									<ProductConfigurationOption v-for="(option, index) in productStore.availableDurations"
																:key="index"
																:disabled="isBusy"
																:is-selected="option.value === productStore.selectedDuration"
																:option="option"
																@option-selected="onDurationSelected(option.value)">
										<template #usp-pricePerMonth>
											<span :class="{ 'text-muted': !isCheapestDuration(option.value) }">
												{{
													$t("common.pricePerMonth", [
														formatCurrency(getPricePerMonthForDuration(option.value)),
													])
												}}
											</span>
										</template>
										<template #usp-cheapest>
											<span v-if="isCheapestDuration(option.value)" class="text-success">
												<i class="pi pi-check-circle mr-1" />
												{{ $t("product.bestPrice") }}
											</span>
										</template>
									</ProductConfigurationOption>
								</template>

								<Message v-if="!layoutStore.isAuthenticated && !productStore.canAddToContract"
											:closable="false"
											severity="custom">
									<i18n-t keypath="product.message.loginToAddToContract">
										<template #loginLink>
											<a :href="loginPageUrl">{{ $t("common.login") }}</a>
										</template>
									</i18n-t>
								</Message>
								<Button v-if="productStore.canAddToContract"
										class="py-0 mt-2 p-button-text p-button-link"
										:label="$t('product.button.addToExistingContract')"
										@click="toggleAddToContract" />
							</ProductConfigurationStep>
						</ProductConfiguration>
						<Message v-else :closable="false" severity="error">
							{{ $t("product.message.currentlyUnavailable") }}
						</Message>
					</ProductPageContentSection>
					<ProductPageContentSection v-if="vm.relatedUpSellProducts?.length" type="upsell">
						<h3 class="">{{ $t("product.titleUpSell") }}</h3>
						<div class="row">
							<div v-for="product in vm.relatedUpSellProducts" :key="product" class="col-md-6 mb-2">
								<BaseProductOverviewItemCard is-cross-sell :vm="product" />
							</div>
						</div>
					</ProductPageContentSection>
				</template>
				<template #tabInfo>
					<ProductPageContentSection v-if="hasTab('tabInfo')">
						<h3>{{ vm.productInfoTabTitle || $t("product.tabProductInfoTitle") }} {{ vm.name }}</h3>
						<div class="rte" v-html="vm.productInfoTab" />
					</ProductPageContentSection>
					<ProductPageContentSection v-if="$slots.blocks" type="content">
						<slot name="blocks" />
					</ProductPageContentSection>
				</template>
				<template #tabSpecifications>
					<ProductPageContentSection v-if="hasTab('tabSpecifications')" type="content">
						<h3>
							{{ vm.specificationsTabTitle || $t("product.tabSpecificationsTitle") }} {{ vm.name }}
						</h3>
						<div class="product-specification" v-html="vm.specificationsTab" />
					</ProductPageContentSection>
				</template>
				<template #tabContent>
					<ProductPageContentSection v-if="hasTab('tabContent')" type="content">
						<h3>{{ $t("product.tabContentTitle") }} {{ vm.name }}</h3>
						<div class="" v-html="contentTabVm" />
					</ProductPageContentSection>
				</template>
				<template #tabMedia>
					<ProductPageContentSection v-if="hasTab('tabMedia')" type="media">
						<h3>{{ mediaEmbedCaption }}</h3>
						<embed height="100%"
								:src="mediaEmbedSrc"
								style="min-height: 400px"
								:title="mediaEmbedCaption"
								type="video/mp4"
								width="100%"
								wmode="transparent" />
					</ProductPageContentSection>
				</template>
			</ProductTabs>
		</template>
		<template #summary>
			<ProductPageSummary
								:can-submit="!submitted && validateProductConfiguration() && vm.availableInCurrentMarket"
								:is-in-edit-mode="isInEditMode"
								:is-autodesk-product="isAutodeskProduct"
								:is-product-available="vm.viewModelIsValid && !isFlexLicense"
								:max-amount="productStore.selectedVariation?.maxQuantity"
								:min-amount="productStore.selectedVariation?.minQuantity"
								:offer-request-form-url="vm.productGuid"
								show-amount-input
								:total-price="priceSummary?.totalPrice"
								:value="productStore?.selectedAmount"
								:variation-guid="productStore.selectedVariation?.guid"
								:variation-id="productStore.selectedVariation?.id"
								@addToCart="addProductToCart"
								@amountChanged="onSelectedAmountChanged"
								@requestAutodeskQuote="onRequestAutodeskQuote">
				<template #priceSummary>
					<PriceSummary :vm="priceSummary" />
					<p class="text-muted lead text-right">{{ $t("common.productSummary.allPricesExclVat") }}</p>
				</template>
				<template #configurationSummary>
					<ProductConfigurationSummary :header="$t('product.titleSummaryProductSubscription', [vm.name])">
						<ProductConfigurationSummaryItem :content="$t(`enum.licenseClass.${productStore.selectedLicenseClass || 0}`)"
															:label="$t('product.lblLicenceType')" />
						<ProductConfigurationSummaryItem v-if="vm.unitSelectionTitle && productStore.selectedLicenseSize"
															:content="productStore.selectedLicenseSizeLabel"
															:label="vm.unitSelectionTitle" />
						<ProductConfigurationSummaryItem :content="$tc(`common.months`, productStore.selectedDuration)"
															:label="$t('product.lblDuration')" />
						<ProductConfigurationSummaryItem v-if="layoutStore.canImpersonate"
															:content="productStore.selectedVariation?.sku"
															label="SKU" />
					</ProductConfigurationSummary>
					<div v-if="vm.currentPartner">
						<p class="lead mt-2">{{ $t("product.lblYourPartner") }}</p>
						<Button class="p-button-text p-button-arrow btn-partner-info"
								@click="showPartnerInfo = true">
							<div class="align-self-center mr-1">
								<Avatar shape="circle" size="large">
									<img v-if="vm.currentPartner?.iconUrl"
											v-lazy="vm.currentPartner?.iconUrl"
											:alt="vm.currentPartner?.iconAlt"
											:title="vm.currentPartner?.name" />
								</Avatar>
							</div>
							<div class="d-flex flex-column align-items-start flex-1 text-left">
								<p class="mb-0 font-weight-bold">
									{{ vm.currentPartner?.name }}
								</p>
								<p class="mb-0 text-muted font-weight-normal">
									{{ vm.currentPartner?.addressString }}
								</p>
							</div>
						</Button>
					</div>
				</template>
				<template #validationMessage>
					<Message v-if="validationErrors.length && submitted"
								:closable="false"
								severity="warn"
								style="cursor: pointer"
								@click="scrollToFirstValidationError()">
						{{ $tc("common.productSummary.msgValidationErrors", validationErrors.length) }}
					</Message>
				</template>
				<template #walletMessage>
					<WalletMessage v-show="!isSmallScreen && vm?.canUseWallet && parseInt(productStore.walletAmount) !== 0"
									:wallet-amount="productStore.walletAmount" />
				</template>
			</ProductPageSummary>
		</template>
	</ProductLayout>
	<ProductAddedModal v-if="cartStore.showProductAddedModal" :vm="productAddedVm" />
	<PartnerInfoModal v-if="showPartnerInfo" :vm="vm.currentPartner" @cancel="showPartnerInfo = false" />
</template>

<script lang="ts">
	import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
	import BaseComponent from "@/components/base/baseComponent.vue";
	import PartnerInfoModal from "@/components/partners/partnerInfoModal.vue";
	import PopoverIcon from "@/components/common/popoverIcon.vue";
	import PriceSummary from "@/components/common/priceSummary.vue";
	import ProductAddedModal from "@/components/commerce/productAddedModal.vue";
	import ProductConfiguration from "@/components/product/productConfiguration.vue";
	import ProductConfigurationOption from "@/components/product/productConfigurationOption.vue";
	import ProductConfigurationStep from "@/components/product/productConfigurationStep.vue";
	import ProductConfigurationSummary from "@/components/product/productConfigurationSummary.vue";
	import ProductConfigurationSummaryItem from "@/components/product/productConfigurationSummaryItem.vue";
	import ProductLayout from "@/views/layout/productLayout.vue";
	import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
	import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
	import ProductPageSummary from "@/components/product/productPageSummary.vue";
	import ProductTabs, { ProductTab } from "@/components/product/productTabs.vue";
	import ProgressSpinner from "primevue/progressspinner";
	import WalletMessage from "@/components/common/WalletMessage.vue";
	import toFixed from "accounting-js/lib/toFixed.js";
	import { Component, Prop } from "vue-facing-decorator";
	import { ContractsForProduct } from "@/types/generated/Api/contractsForProduct";
	import { HelpBlockViewModel } from "@/types/generated/Common/helpBlockViewModel";
	import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
	import { IProductAddedModal } from "@/types/viewModels/commerce/productAddedModalViewModel";
	import { ISoftwareProduct } from "@/types/viewModels/commerce/softwareProductViewModel";
	import { ItemProductType } from "@/types/enum/itemProductType";
	import { LicenseClass } from "@/types/enum/licenseClass";
	import { Log } from "@/types/helpers/logHelper";
	import { ProductVariation } from "@/types/models/software/productVariation";
	import { PropType } from "vue";
	import { SoftwareItemViewModel } from "@/types/models/software/softwareItemViewModel";
	import { SoftwareProductValidation } from "@/types/enum/softwareProductValidation";
	import { min } from "lodash";
	import { useCartStore } from "@/store/commerce/cartStore";
	import { useSoftwareProductStore } from "@/store/software/softwareProductStore";
	import ProductConfigurationFlexLicense from "@/components/product/productConfigurationFlexLicense.vue";
	import { LicenseModel } from "@/types/enum/licenseModel";
	import { ProductCategoryType } from "@/types/enum/productCategoryType";

	// PRICE EXPLANATIONS
	// basePrice = Advies prijs
	// basePriceMonthly = Advies prijs gedeeld door aantal maanden van duration
	// ecommerceBasePrice = Rekenprijs e - commerce.Is het minimale dat Cadac moet verdienen om quitte te spelen op dit product. (inkoop prijs - kickback fee schatting Autodesk)
	// ecommercePrice = Goedkoopste prijs op de markt -> is wat je als niet ingelogde gebruiker zou moeten zien.
	// ecommerceBasePriceMonthly = commerceBasePrice gedeeld door aantal maanden van duration
	// ecommercePriceMonthly = ecommercePriceMonthly gedeeld door aantal maanden van duration
	// personalPrice = te betalen prijs(als je bent ingelogd)
	// personalPriceMonthly te betalen prijs(als je bent ingelogd) gedeeld door aantal maanden van duration

	@Component({
		components: {
			PartnerInfoModal,
			ProductLayout,
			ProductPageSummary,
			PopoverIcon,
			PriceSummary,
			ProductAddedModal,
			ProductConfiguration,
			ProductConfigurationOption,
			ProductConfigurationStep,
			ProductConfigurationSummary,
			ProductConfigurationSummaryItem,
			ProductOverviewItemCard,
			ProductPageContentSection,
			ProductTabs,
			ProgressSpinner,
			WalletMessage,
			ProductConfigurationFlexLicense,
			BaseProductOverviewItemCard,
		},
	})
	export default class SoftwareProduct extends BaseComponent {
		@Prop({ type: Object as PropType<ISoftwareProduct>, required: true, default: {} }) vm!: ISoftwareProduct;

		@Prop({ type: Number, required: false, default: 1 }) value!: number;
		@Prop({ type: Number, required: false, default: 1 }) minAmount!: number;
		@Prop({ type: Number, required: false, default: 999 }) maxAmount!: number;
		selectedAmount!: number;

		showSideBar = false;
		showPartnerInfo = false;
		submitted = false;
		selectedHelpBlock!: HelpBlockViewModel | null;

		productValidation: Map<SoftwareProductValidation, boolean> = new Map<SoftwareProductValidation, boolean>();

		cartStore = useCartStore();
		productStore = useSoftwareProductStore();

	created(): void {
		this.productStore.vm = this.vm;

			Log.info("SoftwareProduct", this.vm);

			if (this.vm.viewModelIsValid) {
				this.productStore.init();
			} else {
				Log.error(new Error(this.vm.viewModelErrors?.join(", ")));
			}


			this.selectedAmount = this.value ?? this.minAmount;

			this.setDataLayer.viewItem(
				toFixed(
					this.layoutStore.isAuthenticated
						? this.priceSummary.totalPersonalPrice
						: this.productStore.selectedVariation?.basePrice,
					2
				),
				this.productStore.selectedVariation ? [this.productStore.selectedVariation] : [],
				this.productStore?.selectedAmount.toString(),
				this.vm.brand,
				this.vm.name,
				ProductCategoryType[this.vm.productCategory]
			);
		}

		get isFlexLicense(): boolean {
			return this.productStore.selectedLicenseModel === LicenseModel.flex;
		}

		get productValidationEnum(): typeof SoftwareProductValidation {
			return SoftwareProductValidation;
		}

		get productAddedVm(): IProductAddedModal {
			return {
				product: {
					...this.vm,
					productType: ItemProductType.Software,
				} as unknown as SoftwareItemViewModel,
				crossSellProducts: this.vm.relatedCrossSellProducts,
			};
		}

		get isInEditMode(): boolean {
			return this.vm.oldLineItemId != null && this.vm.oldLineItemId > 0;
		}

		get isAutodeskProduct(): boolean {
			return this.vm.isAutodeskProduct;
		}

		get contentTabVm(): string {
			if (!this.vm.relatedProducts || !this.vm.relatedNotForSaleProducts?.length) return "";
			const allProductNames = [
				...this.vm.relatedProducts.map((x) => x.name),
				...this.vm.relatedNotForSaleProducts,
			];
			return allProductNames?.map((x) => `<li>${x}</li>`).join("");
		}

		get hasTab(): (id: string) => boolean {
			return (id: string) => this.productTabs.findIndex((x) => x.id === id) > -1;
		}

		get productTabs(): ProductTab[] {
			const productTabs: ProductTab[] = [];
			if (!this.vm.latestVersionOfProduct) {
				productTabs.push({
					label: this.$t("product.configureSubscription"),
					id: "tabConfiguration",
				});
			}
			if (this.vm.productInfoTab) {
				productTabs.push({
					label: this.vm.productInfoTabTitle || this.$t("product.tabProductInfoTitle"),
					id: "tabInfo",
				});
			}
			if (this.vm.specificationsTab) {
				productTabs.push({
					label: this.vm.specificationsTabTitle || this.$t("product.tabSpecificationsTitle"),
					id: "tabSpecifications",
				});
			}
			if (this.vm.mediaTab) {
				productTabs.push({
					label: this.vm.mediaTabTitle || this.$t("product.tabMediaTitle"),
					id: "tabMedia",
				});
			}
			if (this.contentTabVm) {
				productTabs.push({
					label: this.$t("product.tabContentTitle"),
					id: "tabContent",
				});
			}
			return productTabs;
		}

		get validationErrors(): boolean[] {
			return [...this.productValidation.values()].filter((x) => !x);
		}

			get priceSummary(): IPriceSummary {
				if (!this.productStore.selectedVariation) return {} as IPriceSummary;
				const { selectedAmount, selectedVariation, selectedDuration } = this.productStore;
				let { basePriceMonthly, ecommercePriceMonthly, personalPriceMonthly, duration } = selectedVariation;

				if (this.productStore.isAddingToContract) {
					duration = selectedDuration;
				}

			const totalBasePrice = (basePriceMonthly || 0) * (selectedAmount || 1) * (duration || 1);
			const totalEcommercePrice = (ecommercePriceMonthly || 0) * (selectedAmount || 1) * (duration || 1);

			const totalPersonalPrice = (personalPriceMonthly || 0) * (selectedAmount || 1) * (duration || 1);

			let totalDiscount = 0;
			let totalPrice = 0;

			if (this.layoutStore.isAuthenticated) {
				totalDiscount = totalBasePrice - totalPersonalPrice;
				totalPrice = totalPersonalPrice;
			} else if (this.vm.hasDifferentEcommercePrice) {
				totalDiscount = totalBasePrice - totalEcommercePrice;
				totalPrice = totalEcommercePrice;
			} else {
				totalPrice = totalBasePrice;
			}

			return {
				basePrice: totalBasePrice,
				totalDiscount,
				totalPersonalPrice: totalDiscount ? totalPersonalPrice : 0,
				totalPrice,
			} as IPriceSummary;
		}

		get mediaEmbedSrc(): string {
			const mediaTabContent = this.vm.mediaTab?.replace("<p>", "").replace("</p>", "");
			let videoUrl = mediaTabContent;
			if (mediaTabContent.includes("|")) {
				const parts = mediaTabContent.split("|");
				videoUrl = parts.length > 1 ? parts[1] : parts[0];
			}
			return videoUrl;
		}

		get mediaEmbedCaption(): string {
			const mediaTabContent = this.vm.mediaTab?.replace("<p>", "").replace("</p>", "");
			let caption = `${this.$t("product.tabMediaTitle")} ${this.vm.name}`;
			if (mediaTabContent.includes("|")) {
				const parts = mediaTabContent.split("|");
				caption = parts[0];
			}
			return caption;
		}

		toggleAddToContract(): void {
			this.productStore.isAddingToContract = !this.productStore.isAddingToContract;
			this.productStore.updateConfiguration();
		}

		onRequestAutodeskQuote(): void {
			const quotationPageUrl = this?.vm?.mainLayout?.quotationPageUrl;
			var url = `${window.location.origin}${quotationPageUrl}`;

			//call the [POST] `localhost/Api/Quote/Queue/` endpoint and submit QuoteCartLineItem.cs as body
			this.loadingStore.increaseLoadingCount();
			this.axios
				.post("/api/quote/queue",
					// QuoteCartLineItem
					{
						sku: this.productStore.selectedVariation?.sku,
						amount: this.productStore.selectedAmount
					}
				)
				.then(() => {
					// redirect the user to the QuotationPage, URL can be found in MainLayoutViewModel.ts as quotationPageUrl
					window.open(url, "_self");
				})
				.catch((err) => Log.error(err))
				.finally(() => this.loadingStore.decreaseLoadingCount());
		}

		addProductToCart(): void {
			this.submitted = true;
			if (!this.validateProductConfiguration()) {
				setTimeout(() => this.scrollToFirstValidationError(), 50);
				return;
			}
			this.loadingStore.increaseLoadingCount();
			this.productStore
				.addToCart()
				.then(() => {
					this.cartStore.showModal("ProductAdded");

					const priceNotLoggedIn =
						(this.productStore.selectedVariation?.basePrice || 0) * this.productStore?.selectedAmount;

					this.setDataLayer.addToCart(
						this.layoutStore.isAuthenticated
							? toFixed(this.priceSummary.totalPersonalPrice, 2)
							: toFixed(priceNotLoggedIn, 2),
						this.productStore.selectedVariation ? [this.productStore.selectedVariation] : [],
						this.productStore?.selectedAmount.toString(),
						this.vm.brand,
						this.vm.name,
						ProductCategoryType[this.vm.productCategory]
					);
				})
				.catch(() =>
					this.$toast.add({
						severity: "error",
						summary: this.$t("common.messages.titleError"),
						detail: this.$t("common.messages.addToCartError"),
						life: 3000,
					})
				)
				.finally(() => this.loadingStore.decreaseLoadingCount());
		}

			getPricePerMonthForDuration(duration: number): number {
				const productVariation = this.vm.productVariations?.find(
					(x) =>
						x.duration === duration &&
						x.licenseClass === this.productStore.selectedLicenseClass &&
						x.licenseSize === this.productStore.selectedLicenseSize
				);

				const price = this.layoutStore.isAuthenticated ? productVariation?.personalPriceMonthly : productVariation?.ecommercePriceMonthly;
				return price || 0;
			}

		getPricesPerMonthForAvailableDurations(): number[] {
			return this.productStore.availableDurations.map((x) =>
				this.getPricePerMonthForDuration(x.value as number)
			);
		}

		isCheapestDuration(duration: number): boolean {
			const pricesPerMonth = this.getPricesPerMonthForAvailableDurations();
			//Check if prices are the same, return false if so.
			if (new Set(pricesPerMonth)?.size === 1) return false;
			return this.getPricePerMonthForDuration(duration) === min(pricesPerMonth);
		}

		showHelpSideBar(helpBlock: HelpBlockViewModel) {
			this.selectedHelpBlock = helpBlock;
			this.showSideBar = true;
		}

		onContractSelected({ data }: { data: ContractsForProduct }) {
			this.productStore.updateConfiguration(data);
		}

		amountChanged({ value }: { value: number }): void {
			this.selectedAmount = value > this.maxAmount ? this.maxAmount : value < this.minAmount ? this.minAmount : value;
			this.onSelectedAmountChanged(this.selectedAmount);
		}

		onSelectedAmountChanged(value: number): void {
			this.productStore.selectedAmount = value;
			this.productStore.updateConfiguration();
		}

		onLicenseModelSelected(value: LicenseModel) {
			this.productStore.selectedLicenseModel = value;
		}

		onLicenseClassSelected(value: LicenseClass) {
			if (value === this.productStore.selectedLicenseClass) return;
			this.productStore.selectedLicenseClass = value;
			const filterOptions = this.productStore.isAddingToContract
				? {
						licenseClass: value,
					}
				: {
						duration: this.productStore.selectedDuration,
						licenseClass: this.productStore.selectedLicenseClass,
					};
			this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
			this.productStore.updateConfiguration();
		}

		onLicenseSizeSelected(value: number) {
			if (value === this.productStore.selectedLicenseSize) return;
			this.productStore.selectedLicenseSize = value;
			const filterOptions = {
				licenseSize: value,
				licenseClass: this.productStore.selectedLicenseClass,
			};
			this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
			this.productStore.updateConfiguration();
		}

		onDurationSelected(value: number) {
			if (value === this.productStore.selectedDuration) return;
			this.productStore.selectedDuration = value;
			const filterOptions: Partial<ProductVariation> = this.productStore.isAddingToContract
				? {
						licenseClass: this.productStore.selectedLicenseClass,
						licenseSize: this.productStore.selectedLicenseSize,
					}
				: {
						duration: value,
						licenseClass: this.productStore.selectedLicenseClass,
						licenseSize: this.productStore.selectedLicenseSize,
					};
			this.productStore.selectedVariation = this.productStore.currentVariation(filterOptions);
			this.productStore.updateConfiguration();
			this.setDataLayer.customizeProduct(this.vm.name, this.productStore.selectedVariation);
		}

		private validateProductConfiguration(): boolean {
			this.productValidation.set(SoftwareProductValidation.ValidContract, true);
			return this.validationErrors.length === 0;
		}
	}
	</script>

<style scoped lang="scss">
	.product-config-step-subtitle {
		font-size: 1rem;
		font-weight: 400;
		color: var(--text-color-secondary);
	}

	.header-explanation-text {
		font-size: 0.9rem !important;
	}

	.btn-partner-info {
		width: 100%;

		&:hover {
			background-color: inherit !important;
			box-shadow:
				0px 3px 1px -2px rgb(0 0 0 / 20%),
				0px 2px 2px 0px rgb(0 0 0 / 14%),
				0px 1px 5px 0px rgb(0 0 0 / 12%);
		}
	}

	.advice-banner {
		background: #dcf6e5;
		border: none;
		border-radius: var(--border-radius);
		padding: 0.25rem 0.75rem;
		color: var(--success);

		::v-deep(.p-message-text) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			@media (max-width: 960px) {
				flex-direction: column;
				padding-right: 1rem;
				padding-left: 1rem;
				gap: 1rem;
				align-items: start;
			}
		}

		::v-deep(.p-message-icon) {
			color: var(--success);
			padding-right: 0.75rem;
		}

		::v-deep(.p-button) {
			width: 16rem;

			@media (max-width: 576px) {
				width: 100%;
			}
		}

		.title {
			font-weight: 500;
		}

		.content {
			font-weight: 400;
		}
	}

	.container {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.left-pane {
		flex: 3;
	}

	.right-pane {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 2rem 3rem 2rem 0rem;
		flex: 2;
	}

    .animation-video {
        max-width: 100%;
        max-height: 100%;
		/*trick to hide lines around*/
        outline: 4px solid white;
        outline-offset: -4px;
    }

	.support-card {
		text-align: center;
	}

	.support-icon {
		width: 30px;
		height: 30px;
	}

	:deep(.support-card) {
		.p-card-title {
			font-size: 1rem;
			font-weight: 500;
			margin: 0;
		}

		.p-card-body {
			display: flex;
			flex-direction: column;
		}
	}

	.price-label {
		text-align: left;
		margin-right: 1rem;
	}

	.autodesk-price {
		margin: 2rem 0;
		display: flex;
		justify-content: space-between;
	}

	.duration {
		display: block;
		@media (min-width: 1200px) {
			display: flex;
		}
	}

    .wallet-card-container {
        padding: 0 0 1rem 0;
        margin: 2rem 0;
        width: 100%;
        background-color: #b72a5a;
		        
        background-position: center;
        background-size: cover;
				
        border-radius: 0.3125rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
    }

	.wallet-card-extended {
		margin-top: 1.5rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.wallet-card-line {
		border-top: 1px dashed white;
		width: 80%;
	}

    .wallet-card-link {
        color: white;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }

    .wallet-text {
        font-size: 1rem;
        margin-right: 1rem;

        @media (min-width: 36rem) {
            font-size: 1.3rem;
        }
    }
	
    .wallet-amount {
        display: inline-block;
        font-size: 1.35rem;
        font-weight: 450;
        -webkit-mask-image: linear-gradient( -75deg, rgba(207, 114, 52, 0.6) 30%, rgba(207, 114, 52) 50%, rgba(207, 114, 52, 0.6) 70% );
        -webkit-mask-size: 200%;
        animation: shine 3s linear infinite;
        color: #ffcc81;

        @media (min-width: 2000px) {
            font-size: 2rem;
        }
    }

    @keyframes shine {
        from {
            -webkit-mask-position: 150%;
        }

        to {
            -webkit-mask-position: -50%;
        }
    }

    .wallet-icon {
        width: 2rem;
        height: 2rem;
        float: left;
        margin-right: 1rem;
    }

    .price-text {
        font-size: 1.625rem;
        font-weight: 500;
        line-height: 0.8;
        color: #91004b;
		padding-top: 0.5rem;
    }

    .wallet-text-small {
        color: #91004b;
        font-size: 1rem;
        font-weight: 400;		
    }

</style>
