<template>
	<Popper arrow close-delay="0" hover open-delay="0" placement="top" v-bind="$attrs" @click.stop>
		<slot />
		<template #content="props">
			<slot name="content" v-bind="props" />
		</template>
	</Popper>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
export default class PopoverIcon extends Vue {}
</script>

<style lang="scss" scoped>
::v-deep(.popper) {
	font-weight: 400;
	font-size: 0.88rem;
	max-width: clamp(225px, 20vw, 400px);
}
</style>
