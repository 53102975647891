import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ea7650c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "advice-section mx-2 py-2 px-3"
}
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row align-items-center justify-content-center mb-2"
}
const _hoisted_3 = { class: "text-center text-primary font-weight-normal lead" }
const _hoisted_4 = {
  key: 1,
  class: "advice-section mx-3 py-2 px-2"
}
const _hoisted_5 = { class: "text-center text-primary font-weight-normal lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.collapsed && _ctx.nextStepAdvices.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_i18n_t, {
            class: "text-center",
            keypath: "myCadac.advisorAdvice.adviceAmount",
            scope: "global",
            tag: "p"
          }, {
            amount: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(_ctx.nextStepAdvices.length), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nextStepAdvices, (nextStepAdvice, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: `nextStepAdvice_${index}`,
        class: "advice-section mx-3 py-2 px-2 mb-2"
      }, [
        (_ctx.nextStepAdvices.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Avatar, {
                image: nextStepAdvice.iconUrl,
                size: "large"
              }, null, 8, ["image"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("b", _hoisted_3, _toDisplayString(_ctx.productCardTitle(nextStepAdvice)), 1),
        (nextStepAdvice.adviceType === _ctx.productAdviceType.Unsupported)
          ? (_openBlock(), _createBlock(_component_i18n_t, {
              key: 1,
              class: "text-center",
              keypath: "myCadac.advisorAdvice.productCta",
              scope: "global",
              tag: "p"
            }, {
              product: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(nextStepAdvice.name), 1)
              ]),
              _: 2
            }, 1024))
          : (nextStepAdvice.adviceType === _ctx.productAdviceType.New)
            ? (_openBlock(), _createBlock(_component_i18n_t, {
                key: 2,
                class: "text-center",
                keypath: "myCadac.advisorAdvice.productCtaNew",
                scope: "global",
                tag: "p"
              }, {
                duration: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(nextStepAdvice.durationInMonths), 1)
                ]),
                start: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(nextStepAdvice.startDate)), 1)
                ]),
                seats: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(nextStepAdvice.seats), 1)
                ]),
                _: 2
              }, 1024))
            : (nextStepAdvice.adviceType === _ctx.productAdviceType.Renew)
              ? (_openBlock(), _createBlock(_component_i18n_t, {
                  key: 3,
                  class: "text-center",
                  keypath: "myCadac.advisorAdvice.productCtaRenew",
                  scope: "global",
                  tag: "p"
                }, {
                  duration: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString(nextStepAdvice.durationInMonths), 1)
                  ]),
                  start: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(nextStepAdvice.startDate)), 1)
                  ]),
                  seats: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString(nextStepAdvice.seats), 1)
                  ]),
                  _: 2
                }, 1024))
              : (nextStepAdvice.adviceType === _ctx.productAdviceType.TradeIn)
                ? (_openBlock(), _createBlock(_component_i18n_t, {
                    key: 4,
                    class: "text-center",
                    keypath: "myCadac.advisorAdvice.productCtaRenew",
                    scope: "global",
                    tag: "p"
                  }, {
                    duration: _withCtx(() => [
                      _createElementVNode("b", null, _toDisplayString(nextStepAdvice.durationInMonths), 1)
                    ]),
                    start: _withCtx(() => [
                      _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(nextStepAdvice.startDate)), 1)
                    ]),
                    seats: _withCtx(() => [
                      _createElementVNode("b", null, _toDisplayString(nextStepAdvice.seats), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (nextStepAdvice.adviceType === _ctx.productAdviceType.Running)
                  ? (_openBlock(), _createBlock(_component_i18n_t, {
                      key: 5,
                      class: "text-center",
                      keypath: "myCadac.advisorAdvice.productCtaRunning",
                      scope: "global",
                      tag: "p"
                    }, {
                      product: _withCtx(() => [
                        _createElementVNode("b", null, _toDisplayString(nextStepAdvice.name), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
      ])), [
        [_vShow, !_ctx.collapsed || _ctx.nextStepAdvices.length < 2]
      ])
    }), 128)),
    (!_ctx.nextStepAdvices.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("b", _hoisted_5, _toDisplayString(_ctx.$t("myCadac.advisorAdvice.productTitleTerminate", { product: _ctx.currentProduct.name })), 1),
          _createVNode(_component_i18n_t, {
            class: "text-center",
            keypath: "myCadac.advisorAdvice.productCtaTerminate",
            scope: "global",
            tag: "p"
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}