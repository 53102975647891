import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b4527a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide-sidebar-wrapper" }
const _hoisted_2 = { class: "slide-sidebar-header" }
const _hoisted_3 = { class: "slide-sidebar-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slide-sidebar", { collapsed: !_ctx.isVisible }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true),
        _createVNode(_component_Button, {
          class: "p-button-secondary p-button-text p-button-rounded",
          icon: "pi pi-times",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ])
  ], 2))
}