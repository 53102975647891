import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "lead mb-2" }
const _hoisted_3 = { onsubmit: "return false;" }
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = { class: "form-group col-12" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "p-field-checkbox mr-2 my-1" }
const _hoisted_8 = {
  class: "ml-1 mb-0",
  for: 'deleteAfterSave'
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' },
    modal: true,
    style: { width: '30vw' },
    onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCancel()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("cart.dialog.saveCartTitle")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCancel()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.$t('common.save'),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSubmit()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("cart.dialog.saveCartHelpText")), 1),
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['cartSaveForm'].title?.required })
            }, _toDisplayString(_ctx.$t("cart.dialog.saveCartLblCartTitle")), 3),
            _createVNode(_component_InputText, {
              modelValue: _ctx.v$['cartSaveForm'].title.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['cartSaveForm'].title.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['cartSaveForm'].title) }),
              type: "text"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.shouldShowError(_ctx.v$['cartSaveForm'].title))
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$["cartSaveForm"].title?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.v$['cartSaveForm'].deleteAfterSave.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['cartSaveForm'].deleteAfterSave.$model) = $event)),
            id: 'deleteAfterSave',
            binary: true,
            name: "roles"
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("cart.dialog.lblDeleteAfterSave")), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}