import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7777e1c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "support-summary" }
const _hoisted_2 = { class: "support-text" }
const _hoisted_3 = {
  key: 0,
  class: "text-bold"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "d-block" }
const _hoisted_8 = {
  alt: "Cadac Group",
  class: "support-img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("common.support.anyQuestions")), 1)
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.supportPageUrl)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_3, [
                _createVNode(_component_i18n_t, { keypath: "common.support.visitOurSupportPage" }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", { href: _ctx.supportPageUrl }, _toDisplayString(_ctx.$t("common.support.visitOurSupportPage_link")), 9, _hoisted_4)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("small", _hoisted_5, [
            _createVNode(_component_i18n_t, {
              keypath: 
								_ctx.supportPageUrl ? 'common.support.orContactUsAt' : 'common.support.contactUsAt'
							
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: 'tel:' + _ctx.$t('common.support.phoneNumber')
                }, _toDisplayString(_ctx.$t("common.support.phoneNumber")), 9, _hoisted_6)
              ]),
              _: 1
            }, 8, ["keypath"])
          ]),
          _createElementVNode("small", _hoisted_7, _toDisplayString(_ctx.$t("common.support.officeHours")), 1)
        ]),
        _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
          [_directive_lazy, '/Static/images/support.png']
        ])
      ]),
      _: 1
    })
  ]))
}