import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "lead" }
const _hoisted_3 = { class: "py-1 d-flex justify-content-between" }
const _hoisted_4 = { class: "field mt-2" }
const _hoisted_5 = { class: "lead" }
const _hoisted_6 = { class: "product-filter-option p-field-checkbox form-group" }
const _hoisted_7 = { class: "product-filter-option p-field-checkbox form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_GMapAutocomplete = _resolveComponent("GMapAutocomplete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t(`enum.overviewFilterCategory.${_ctx.filterStore.availabilityFilter?.category}`)), 1),
      _createVNode(_component_Divider, { class: "mt-0" }),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.selectedDates,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDates) = $event)),
        ref: "periodCalendar",
        class: "w-100",
        "manual-input": false,
        placeholder: _ctx.$t('expert.selectPeriod'),
        "selection-mode": "range"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.selectedDates?.length > 0)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "p-button-text p-button-secondary",
                  label: _ctx.$t('common.resetFilters'),
                  onClick: _ctx.resetDateFilter
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, {
              class: "p-button-text ml-auto",
              label: _ctx.$t('common.apply'),
              onClick: _ctx.applyDateFilter
            }, null, 8, ["label", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t(`enum.overviewFilterCategory.${_ctx.filterStore.workOnLocationFilter?.category}`)), 1),
      _createVNode(_component_Divider, { class: "mt-0" }),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.workLocationActiveRemote,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.workLocationActiveRemote) = $event)),
          id: `filter-remote`,
          binary: "",
          onChange: _ctx.onRemoteLocationChanged
        }, null, 8, ["modelValue", "onChange"]),
        _createElementVNode("label", {
          class: _normalizeClass(["ml-2 mb-0 p-link", _ctx.workLocationActiveRemote ? 'font-weight-bold' : 'font-weight-normal']),
          for: `filter-remote`
        }, _toDisplayString(_ctx.$t("expert.remote")), 3)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.workLocationActive,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.workLocationActive) = $event)),
          id: `filter-onLocation`,
          binary: ""
        }, null, 8, ["modelValue"]),
        _createElementVNode("label", {
          class: _normalizeClass(["ml-2 mb-0 p-link", _ctx.workLocationActive ? 'font-weight-bold' : 'font-weight-normal']),
          for: `filter-onLocation`
        }, _toDisplayString(_ctx.$t("expert.onLocation")), 3)
      ]),
      _withDirectives(_createVNode(_component_GMapAutocomplete, {
        ref: "locationInput",
        class: "p-inputtext p-component",
        placeholder: _ctx.$t(`expert.searchYourLocation`),
        onPlace_changed: _ctx.onLocationChanged
      }, null, 8, ["placeholder", "onPlace_changed"]), [
        [_vShow, _ctx.workLocationActive]
      ])
    ])
  ], 64))
}