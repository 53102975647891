import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "p-datatable" }
const _hoisted_4 = { class: "p-datatable-header" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "p-datatable" }
const _hoisted_7 = { class: "p-datatable-header" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "p-datatable" }
const _hoisted_10 = { class: "p-datatable-header" }
const _hoisted_11 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CartContractClientTable = _resolveComponent("CartContractClientTable")!
  const _component_CartTrainingTable = _resolveComponent("CartTrainingTable")!
  const _component_CartMircoServiceTable = _resolveComponent("CartMircoServiceTable")!
  const _component_CartExpertTable = _resolveComponent("CartExpertTable")!
  const _component_CartClientTable = _resolveComponent("CartClientTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.contractCartClients?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractCartClients, (contractCart) => {
            return (_openBlock(), _createBlock(_component_CartContractClientTable, {
              key: contractCart,
              "cart-client": contractCart,
              "is-editable": _ctx.isEditable
            }, null, 8, ["cart-client", "is-editable"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.trainingCartClients?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(`cart.table.title${_ctx.getUpperCaseCartLineType(_ctx.cartLineTypeEnum.trainingProduct)}`)), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingCartClients, (training) => {
            return (_openBlock(), _createBlock(_component_CartTrainingTable, {
              key: training,
              "cart-line-client": training,
              "is-editable": _ctx.isEditable
            }, null, 8, ["cart-line-client", "is-editable"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.microServiceProducts?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(`cart.table.title${_ctx.getUpperCaseCartLineType(_ctx.cartLineTypeEnum.serviceProduct)}`)), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.microServiceProducts, (mircoservice) => {
            return (_openBlock(), _createBlock(_component_CartMircoServiceTable, {
              key: mircoservice,
              "cart-line-client": mircoservice,
              "is-editable": _ctx.isEditable
            }, null, 8, ["cart-line-client", "is-editable"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.expertCartClients?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(`cart.table.title${_ctx.getUpperCaseCartLineType(_ctx.cartLineTypeEnum.expertProduct)}`)), 1)
            ])
          ]),
          _createVNode(_component_CartExpertTable, {
            "cart-line-clients": _ctx.expertCartClients,
            "is-editable": _ctx.isEditable
          }, null, 8, ["cart-line-clients", "is-editable"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.cartLineClientsByType?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartLineClientsByType, (cartLineClient) => {
            return (_openBlock(), _createBlock(_component_CartClientTable, {
              key: cartLineClient,
              "cart-line-type": cartLineClient.cartLineType,
              "is-editable": _ctx.isEditable,
              items: _ctx.getCartLinesForType(cartLineClient.cartLineType)
            }, null, 8, ["cart-line-type", "is-editable", "items"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}