/* Auto Generated */

export enum SearchCategory {
	all,
	software,
	training,
	experts,
	services,
	content,
	faq,
}
