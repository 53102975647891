import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-089139ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "align-self-center mr-2" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mb-0 text-muted lead" }
const _hoisted_7 = { class: "mb-0 pr-5" }
const _hoisted_8 = {
  key: 0,
  class: "mb-0 text-muted"
}
const _hoisted_9 = {
  key: 0,
  class: "cross-sell"
}
const _hoisted_10 = { class: "cross-sell-wrapper" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "row gap-y-3" }
const _hoisted_13 = { class: "d-flex align-items-center justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_BaseProductOverviewItemCard = _resolveComponent("BaseProductOverviewItemCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: {
			'2000px': '40vw',
			'1600px': '50vw',
			'1200px': '60vw',
			'992px': '70vw',
			'768px': '80vw',
			'576px': '90vw',
		},
    class: "p-dialog-secondary",
    draggable: false,
    modal: true,
    style: { width: '35vw' },
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hideModal()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("cart.dialog.titleProductAdded")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.vm.product.iconUrl)
              ? (_openBlock(), _createBlock(_component_Avatar, {
                  key: 0,
                  alt: _ctx.vm.product.iconAlt,
                  class: "overview-product-icon",
                  image: _ctx.vm.product.iconUrl,
                  size: "lg",
                  title: _ctx.vm.product.name
                }, null, 8, ["alt", "image", "title"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.vm.product.brand), 1),
            _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.formatProductName(_ctx.vm.product.name, _ctx.vm.product.brand)), 1),
            (_ctx.expertProduct?.expertProfile)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.expertProduct.expertProfile.function), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Button, {
          class: "p-button-text p-button-plain",
          label: _ctx.$t('cart.dialog.button.continueShopping'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideModal()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button-primary p-button-arrow p-button-sm",
          label: _ctx.$t('cart.dialog.button.goToCart'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToCart()))
        }, null, 8, ["label"])
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.vm.crossSellProducts?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("product.titleCrossSell")), 1),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vm.crossSellProducts, (product) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: product,
                    class: "col-12 col-lg-6"
                  }, [
                    _createVNode(_component_BaseProductOverviewItemCard, {
                      "is-cross-sell": "",
                      vm: product
                    }, null, 8, ["vm"])
                  ]))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}