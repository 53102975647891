import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-581aff5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]
const _hoisted_2 = { class: "d-flex flex-1 justify-content-center" }
const _hoisted_3 = { class: "align-self-center mr-1" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "mb-0 font-weight-light" }
const _hoisted_8 = {
  key: 1,
  class: "cta-offer-block flex-md-row align-items-md-center"
}
const _hoisted_9 = { class: "d-flex flex-1" }
const _hoisted_10 = { class: "align-self-center mr-1" }
const _hoisted_11 = ["alt"]
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "mb-0 lead" }
const _hoisted_15 = { class: "mt-2 mt-lg-0 align-self-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_ctx.vm.backgroundUrl)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "cta-offer-block has-image flex-md-row align-items-md-center",
        href: _ctx.vm.linkItem.url,
        style: _normalizeStyle({
			'background-image': `url(${_ctx.vm.backgroundUrl}`,
		}),
        target: _ctx.vm.linkItem.target
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Avatar, {
              class: "bg-transparent",
              shape: "square",
              size: _ctx.vm.useLargeIcon ? 'xlarge' : 'large'
            }, {
              default: _withCtx(() => [
                (_ctx.vm.iconUrl)
                  ? _withDirectives((_openBlock(), _createElementBlock("img", {
                      key: 0,
                      alt: _ctx.vm.iconAlt
                    }, null, 8, _hoisted_4)), [
                      [_directive_lazy, _ctx.vm.iconUrl]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["size"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.vm.title), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.vm.subTitle), 1)
          ])
        ])
      ], 12, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Avatar, {
              class: "bg-transparent",
              shape: "square",
              size: _ctx.vm.useLargeIcon ? 'xlarge' : 'large'
            }, {
              default: _withCtx(() => [
                (_ctx.vm.iconUrl)
                  ? _withDirectives((_openBlock(), _createElementBlock("img", {
                      key: 0,
                      alt: _ctx.vm.iconAlt
                    }, null, 8, _hoisted_11)), [
                      [_directive_lazy, _ctx.vm.iconUrl]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["size"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("h4", _hoisted_13, _toDisplayString(_ctx.vm.title), 1),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.vm.subTitle), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.vm.linkItem)
            ? (_openBlock(), _createBlock(_component_LinkButton, {
                key: 0,
                class: "p-button-arrow p-button-raised p-button-text",
                label: _ctx.vm.linkItem.text,
                target: _ctx.vm.linkItem.target,
                title: _ctx.vm.linkItem.title,
                url: _ctx.vm.linkItem.url
              }, null, 8, ["label", "target", "title", "url"]))
            : _createCommentVNode("", true)
        ])
      ]))
}