import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69888893"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block mt-0" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "mt-2" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "col-6 split-block",
          style: _normalizeStyle(_ctx.getBackgroundStyle(_ctx.vm.backgroundColor, 0))
        }, [
          (_ctx.vm.leftTitle)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 0,
                class: "display-5 mb-1",
                textContent: _toDisplayString(_ctx.vm.leftTitle)
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: "lead",
            textContent: _toDisplayString(_ctx.vm.leftText)
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.vm.leftCta)
              ? (_openBlock(), _createBlock(_component_LinkButton, {
                  key: 0,
                  class: _normalizeClass(
								_ctx.ctaButtonClass(_ctx.vm.leftCtaStyle) || 'p-button-raised p-button-text p-button-arrow'
							),
                  label: _ctx.vm.leftCta.text,
                  target: _ctx.vm.leftCta.target,
                  title: _ctx.vm.leftCta.title,
                  url: _ctx.vm.leftCta.url
                }, null, 8, ["class", "label", "target", "title", "url"]))
              : _createCommentVNode("", true)
          ])
        ], 4),
        _createElementVNode("div", {
          class: "col-6 split-block",
          style: _normalizeStyle(_ctx.getBackgroundStyle(_ctx.vm.backgroundColor, 1))
        }, [
          (_ctx.vm.rightTitle)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 0,
                class: "display-5 mb-1",
                textContent: _toDisplayString(_ctx.vm.rightTitle)
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: "lead",
            textContent: _toDisplayString(_ctx.vm.rightText)
          }, null, 8, _hoisted_8),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.vm.rightCta)
              ? (_openBlock(), _createBlock(_component_LinkButton, {
                  key: 0,
                  class: _normalizeClass(
								_ctx.ctaButtonClass(_ctx.vm.rightCtaStyle) || 'p-button-raised p-button-text p-button-arrow'
							),
                  label: _ctx.vm.rightCta.text,
                  target: _ctx.vm.rightCta.target,
                  title: _ctx.vm.rightCta.title,
                  url: _ctx.vm.rightCta.url
                }, null, 8, ["class", "label", "target", "title", "url"]))
              : _createCommentVNode("", true)
          ])
        ], 4)
      ])
    ])
  ]))
}