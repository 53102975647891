<template>
	<div class="container">
		<div class="row my-2">
			<div class="col-12 hidden-print">
				<div class="d-flex flex-row align-items-center">
					<div>
						<ProfileImage
							:can-change-image="!!expertStore.expertProfile"
							:first-letter-avatar="firstLetterAvatar"
							:profile-image-url="profileImageUrl"
							@onProfilePictureChange="onProfilePictureChange"
						/>
					</div>

					<div class="px-1 d-flex flex-column">
						<h2 class="mb-0 ml-1">
							{{ userFullName }}
						</h2>
						<p v-if="displayUser?.isEmailVerified" class="d-block mb-0 ml-1 mt-1">
							{{ displayUser?.company?.companyName }}
						</p>
						<hr class="mt-1 mb-2" />
						<div class="d-flex flex-wrap gap-1">
							<Button v-if="displayUser?.company?.walletBalance?.earnedWalletAmount  > 0 || displayUser?.company?.usedWalletBalances?.hasUsedEarnedWalletAmount"
									class="custom-button"
									@click="this.openUrl(vm.walletOverviewPageUrl)">
								<img v-lazy="'/Static/images/loyalty-silver.png'"
									 alt="cadac loyalty discount icon"
									 class="coin-image" />
								<span class="button-text">{{ formatCurrency(displayUser?.company?.walletBalance?.earnedWalletAmount) }}</span>
								<i class="pi pi-angle-right"></i>
							</Button>

							<Button
									v-if="displayUser?.company?.walletBalance?.depositedWalletAmount > 0 || displayUser?.company?.usedWalletBalances?.hasUsedDepositedWalletAmount"
									class="custom-button"
									@click="this.openUrl(vm.walletOverviewPageUrl)"
									>
								<img v-lazy="'/Static/images/loyalty-gold.png'"
									 alt="cadac prepaid icon"
									 class="coin-image" />
								<span class="button-text">{{ formatCurrency(displayUser?.company?.walletBalance?.depositedWalletAmount) }}</span>
								<i class="pi pi-angle-right"></i>
							</Button>

							<Button v-if="displayUser?.company?.walletBalance?.autodeskWalletAmount  > 0 || displayUser?.company?.usedWalletBalances?.hasUsedLoyaltyCashbackWalletAmount"
									class="custom-button"
									@click="this.openUrl(vm.walletOverviewPageUrl)">
								<img v-lazy="'/Static/images/autodesk-coin.png'"
									 alt="autodesk loyalty cashback icon"
									 class="coin-image" />
								<span class="button-text">{{ formatCurrency(displayUser?.company?.walletBalance?.autodeskWalletAmount) }}</span>
								<i class="pi pi-angle-right"></i>
							</Button>
						</div>
					</div>

					<MyCadacBannerBlock v-if="!isSmallScreen && vm.bannerBlock" :vm="vm.bannerBlock" />

					<div v-else-if="showProfileProgress" class="profile-progress">
						<label v-if="expertStore.expertProfile?.profileCompletionPercentage === 100" class="mb-0">
							{{ $t("myCadac.expertSettings.profileComplete") }}
						</label>
						<label v-else class="mb-0 text-info">{{
							$t("myCadac.expertSettings.profileIncomplete")
						}}</label>
						<ProgressBar
							class="mb-1"
							style="height: 1.25rem"
							:value="expertStore.expertProfile?.profileCompletionPercentage"
						>
							<b>{{ expertStore.expertProfile?.profileCompletionPercentage }}%</b>
						</ProgressBar>
						<Button
							v-if="isProfileComplete && cta"
							class="flex-shrink-0 p-button-success"
							icon="pi pi-check-circle"
							:label="$t('myCadac.expertProfile.btnSendProfile')"
							@click="expertStore.showModal('SendProfile')"
						/>
					</div>
					<div
						v-else-if="expertStore?.expertProfile && expertStore.expertProfile.state"
						class="profile-status"
					>
						<StatusBadge
							:status="expertStore.expertProfile.state"
							translation-prefix="enum.expertPageState"
							:type="2"
						/>
					</div>
				</div>
				<hr class="my-1 my-lg-3" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-3 hidden-print">
				<MyCadacMenu :menu-items="vm.menuItems" />
			</div>
			<div class="col-md-9 col-print-12 pb-3">
				<slot />
			</div>
		</div>
	</div>
	<CtaBlockModal
		v-if="expertStore.isSendProfileModalVisible"
		:header="$t('myCadac.expertSettings.dialogRequestApprovalHeader')"
		:vm="cta"
		@cancel="expertStore.hideModal('SendProfile')"
		@submit="onApprovalRequested"
	/>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CtaBlockModal from "@/components/common/ctaBlockModal.vue";
import MyCadacBannerBlock from "@/views/blocks/myCadacBannerBlock.vue";
import MyCadacMenu from "@/components/common/menu/myCadacMenu.vue";
import ProgressBar from "primevue/progressbar";
import StatusBadge from "@/components/common/statusBadge.vue";
import { CadacUserProfileViewModel } from "@/types/models/common/cadacUserProfileViewModel";
import { CallToActionModalBlockViewModel } from "@/types/models/common/callToActionModalBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { ExpertPageState } from "@/types/enum/expertPageState";
import { Log } from "@/types/helpers/logHelper";
import { MyCadacPageViewModel } from "@/types/viewModels/common/myCadacPageViewModel";
import { PropType } from "vue";
import { useExpertStore } from "@/store/expert/expertStore";
import ProfileImage from "@/components/common/profileImage.vue";

@Component({
	components: { ProfileImage, MyCadacBannerBlock, MyCadacMenu, ProgressBar, StatusBadge, CtaBlockModal },
})
export default class MyCadacLayout extends BaseComponent {
	@Prop({ type: Object as PropType<MyCadacPageViewModel>, required: true, default: {} })
	vm!: MyCadacPageViewModel;
	cta!: CallToActionModalBlockViewModel;

	validProfileImage = false;

	expertStore = useExpertStore();

	created(): void {
		this.expertStore.fetchExpertProfile(this.displayUser.cadacAccountId, this.currentLanguage).then(() => {
			this.validProfileImage = !!this.profileImageUrl;
		});
		this.cta = (this.vm as any)?.requestApprovalBlock;
	}

	get displayUser(): CadacUserProfileViewModel {
		return this.layoutStore.isImpersonated
			? this.layoutStore.vm.impersonatedUser
			: this.layoutStore.vm.currentUser;
	}

	get userFullName(): string {
		return this.displayUser?.firstName
			? `${this.displayUser?.firstName} ${this.displayUser?.lastName}`
			: this.displayUser.displayName;
	}

	get firstLetterAvatar(): string {
		return this.displayUser?.firstName ? this.displayUser?.firstName?.substr(0, 1) : "*";
	}

	get profileImageUrl(): string {
		return this.expertStore.expertProfile?.imageUrl as string;
	}

	get showProfileProgress(): boolean {
		return this.expertStore?.expertProfile?.state === ExpertPageState.created;
	}

	get isProfileComplete(): boolean {
		return this.expertStore?.expertProfile?.profileCompletionPercentage === 100;
	}

	onProfilePictureError(): void {
		this.validProfileImage = false;
	}

	onProfilePictureChange(evt): void {
		this.loadingStore.showSpinner("avatar");
		this.updateProfilePicture(evt);
	}

	onApprovalRequested(): void {
		this.sendApprovalRequest();
	}

	private updateProfilePicture(file: File): void {
		let formData = new FormData();
		formData.append("file", file);
		this.axios
			.post("/api/expert/updateprofilepicture", formData, {
				requestId: "updateProfilePicture",
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(() => this.expertStore.fetchExpertProfile(this.displayUser.cadacAccountId, this.currentLanguage))
			.then(() => {
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.updateProfilePictureSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.updateProfilePictureError"),
					life: 3000,
				});
			})
			.finally(() => this.loadingStore.hideSpinner("avatar"));
	}

	private sendApprovalRequest(): void {
		this.axios
			.post("/api/expert/request-approval", {}, { requestId: "requestApproval" })
			.then(() => this.expertStore.fetchExpertProfile(this.displayUser.cadacAccountId, this.currentLanguage))
			.then(() => {
				this.$toast.add({
					severity: "success",
					summary: this.$t("common.messages.titleSuccess"),
					detail: this.$t("myCadac.expertSettings.toast.requestApprovalSuccess"),
					life: 3000,
				});
			})
			.catch((err) => {
				Log.error(err);
				this.$toast.add({
					severity: "error",
					summary: this.$t("common.messages.titleError"),
					detail: this.$t("myCadac.expertSettings.toast.requestApprovalError"),
					life: 3000,
				});
			})
			.finally(() => this.expertStore.hideModal("SendProfile"));
	}
}
</script>

<style scoped lang="scss">
.avatar {
	width: 4rem;
	height: 4rem;
	font-weight: 700;
	font-size: 1.75rem;
	background: var(--surface-c);

	&:hover {
		background: var(--surface-c);
	}

	&.avatar--large {
		width: 6rem;
		height: 6rem;
		font-size: 3rem;
	}
}

.profile-status {
	margin-left: auto;
	width: clamp(300px, 15%, 600px);
}

.profile-progress {
	position: relative;
	margin-left: auto;
	width: clamp(600px, 25%, 900px);
	text-align: center;
	margin-bottom: 1rem;

	::v-deep(.p-progressbar) {
		background: var(--surface-b);

		.p-progressbar-value {
			background: var(--secondary-color);
		}
	}

	&::before {
		content: "0%";
		font-size: 85%;
		left: 0;
		top: 2px;
		position: absolute;
	}

	&::after {
		font-size: 85%;
		content: "100%";
		right: 0;
		top: 2px;
		position: absolute;
	}
}

.profile-figure {
	position: relative;
	width: 6rem;
	height: 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profile-avatar {
	cursor: pointer;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-radius: 100%;
	border: 2px solid transparent;
	transition: all ease-in-out 0.3s;
	object-fit: cover;

	.profile-avatar-loading {
		font-size: 1.5rem;
	}
}

.profile-figcaption {
	cursor: pointer;
	position: absolute;
	font-size: 1.2rem;
	font-weight: 500;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primary-color-text);
	border-radius: 100%;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: all ease-in-out 0.3s;

	&:hover {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.4);
	}
}

    .custom-button {
        display: flex;
        align-items: center;
        background-color: #f3f3f3;
        border-radius: 50px;
        padding: 0 5px;
        border: none;
        color: #8e234a;
        font-weight: 500;
        gap: 4px;
        margin-left: 0 !important;
    }

    .coin-image {
        width: 1rem;
    }
</style>
