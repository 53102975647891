<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<h2 v-if="vm.title" class="display-4" :class="{ 'mb-0': vm.subtitle }" v-html="vm.title"></h2>
			<p v-if="vm.subtitle" class="lead mb-2">{{ vm.subtitle }}</p>
			<div>
				<SelectButton class="row m-0" data-key="id" :options="filteredMainCategories">
					<template #option="slotProps">
						<a :href="getUrlForCategory(slotProps.option)">
							<div class="d-flex flex-column align-items-center p-3">
								<div class="d-flex flex-column align-items-center">
									<Avatar
										class="mb-2"
										:image="slotProps.option.icon"
										shape="square"
										size="large"
									/>
									<p class="font-weight-bold lead text-primary">
										{{ slotProps.option.title }}
									</p>
									<p class="mb-0 lead">{{ slotProps.option.subtitle }}</p>
								</div>
							</div>
						</a>
					</template>
				</SelectButton>
				<LinkButton
					v-if="otherMainCategory"
					class="my-2 p-0 p-button-text p-button-secondary p-button-link"
					:label="otherMainCategory?.title"
					:url="getUrlForCategory(otherMainCategory)"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import SelectButton from "primevue/selectbutton";
import { AskAQuestionBlockViewModel } from "@/types/viewModels/blocks/askAQuestionBlockViewModel";
import { AskAQuestionCategoryType } from "@/types/enum/askAQuestionCategoryType";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { TicketFormHeadCategory } from "@/types/models/support/ticketFormHeadCategory";

@Component({ components: { SelectButton } })
export default class AskAQuestionBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<AskAQuestionBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: AskAQuestionBlockViewModel;

	created(): void {
		Log.info("AskAQuestionBlock", this.vm);
	}

	get filteredMainCategories(): TicketFormHeadCategory[] {
		return this.vm.ticketFormCategoryTree.filter((x) => x.categoryType !== AskAQuestionCategoryType.Other);
	}

	get otherMainCategory(): TicketFormHeadCategory {
		return this.vm.ticketFormCategoryTree.find(
			(x) => x.categoryType === AskAQuestionCategoryType.Other
		) as TicketFormHeadCategory;
	}

	getUrlForCategory(cat: TicketFormHeadCategory): string {
		const queryParams = new URLSearchParams("");
		if (cat?.id) queryParams.set("cat", cat.id.toString());
		const url = new URL(this.vm.askAQuestionPageUrl);
		url.search = queryParams.toString();
		return url.toString();
	}
}
</script>

<style scoped lang="scss">
a {
	text-decoration: none;
	color: var(--text-color);
}
</style>
