<template>
	<form class="position-relative" @change="formStateChanged">
		<div
			v-if="isSpinnerVisible('autofill')"
			class="loader d-flex flex-column justify-content-center align-items-center text-center mx-auto form-group col-md-12 col-lg-10 col-xxl-8"
		>
			<ProgressSpinner animation-duration="1.75s" stroke-width="4" />
		</div>

		<div class="form-row">
			<div class="form-group col-md-12 col-lg-10 col-xxl-8">
				<template v-if="autoComplete">
					<InputWithValidation
						v-slot="{ field }"
						:is-required="validations['companyName'].required"
						:label="$t('common.forms.lblCompanyName')"
						name="companyName"
					>
						<AutoComplete
							v-model="field.value.value"
							class="w-100"
							:class="{ 'p-invalid': field.errors?.length }"
							:min-length="2"
							option-label="name"
							:suggestions="companySuggestions"
							@blur="field.setTouched(true)"
							@change="autoFillOptOut()"
							@complete="searchCompanies($event.query)"
							@item-select="onSuggestionSelection($event.value.dunsNumber)"
						>
							<template #option="{ option }">
								<div class="d-flex">
									<p class="mb-0 mr-1 text-bold">
										{{ option?.name }}
									</p>
									<p class="mb-0">in {{ option?.mainAddress.city }}</p>
								</div>
							</template>
						</AutoComplete>
					</InputWithValidation>
				</template>
				<InputWithValidation
					v-else
					v-slot="{ field }"
					:is-required="validations['companyName'].required"
					:label="$t('common.forms.lblCompanyName')"
					name="companyName"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12 col-lg-10 col-xxl-8">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['street'].required"
					:label="$t('common.forms.lblAddress')"
					name="street"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:disabled="!canEditCompany"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['postalCode'].required"
					:label="$t('common.forms.lblPostalCode')"
					name="postalCode"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:disabled="!canEditCompany"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['city'].required"
					:label="$t('common.forms.lblCity')"
					name="city"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:disabled="!canEditCompany"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12 col-lg-10 col-xxl-8">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['countryCode'].required"
					:label="$t('common.forms.lblCountry')"
					name="countryCode"
				>
					<Dropdown
						:class="{ 'p-invalid': field.errors?.length }"
						:disabled="(countries?.length === 1 && canEditCompany) || !canEditCompany"
						:model-value="field.value.value"
						option-label="value"
						option-value="key"
						:options="countries"
						@change="field.setTouched(true)"
						@update:modelValue="
							(e) => {
								field.value.value = e;
								field.setTouched(true);
								formStateChanged();
							}
						"
					/>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div v-if="useIban" class="form-group col-md-12 col-lg-10 col-xxl-8">
				<InputWithValidation
					v-slot="{ field }"
					:info="$t('iban.infoIcon')"
					:is-required="validations['iban'].required"
					:label="$t('common.forms.lblIban')"
					name="iban"
				>
					<div class="p-input-icon-right w-100">
						<InputText
							v-model.lazy.trim="field.value.value"
							:class="{ 'p-invalid': field.errors?.length }"
							:disabled="!canEditCompany"
							:name="field.name"
							@blur="
								formatIban(field.value.value);
								field.setTouched(true);
							"
						/>
						<i v-if="field.meta.pending" class="pi pi-spin pi-spinner" />
					</div>
				</InputWithValidation>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:is-required="validations['cocNumber'].required"
					:label="$t('common.forms.lblCocNumber')"
					name="cocNumber"
				>
					<InputText
						v-model="field.value.value"
						:class="{ 'p-invalid': field.errors?.length }"
						:disabled="!canEditCompany"
						:name="field.name"
						@blur="field.setTouched(true)"
					/>
				</InputWithValidation>
			</div>
			<div class="form-group col-md-6 col-lg-5 col-xxl-4">
				<InputWithValidation
					v-slot="{ field }"
					:info="$t('vat.infoIcon')"
					:is-required="validations['vatNumber'].required"
					:label="$t('common.forms.lblVatNumber')"
					name="vatNumber"
				>
					<div class="p-input-icon-right w-100">
						<InputText
							v-model.trim.lazy="field.value.value"
							:class="{ 'p-invalid': field.errors?.length }"
							:disabled="!canEditCompany"
							:name="field.name"
							@blur="field.setTouched(true)"
						/>
						<i v-if="field.meta.pending" class="pi pi-spin pi-spinner" />
					</div>
				</InputWithValidation>

				<!--<small v-else-if="v$.companyForm.vatNumber.validVatNumber?.$invalid" class="p-error">
					{{ v$.companyForm.vatNumber.validVatNumber?.$message }}
					<i18n-t keypath="vat.invalidInfo">
						<template #link>
							<a :href="$t('vat.invalidLink')" target="_blank">{{ $t("vat.invalidLinkPlaceholder") }}</a>
						</template>
					</i18n-t>
				</small>-->
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import InputWithValidation from "@/components/common/inputWithValidation.vue";
import { CompanyForm as CompanyFormModel } from "@/types/models/form/companyForm";
import { Component, Emit, Model, Prop, Watch } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { FormContext, FormMeta, useForm } from "vee-validate";
import { PropType, computed, reactive, unref } from "vue";
import { ValidationHelper } from "@/types/helpers/validationHelper";
import { cloneDeep } from "lodash";
import { friendlyFormatIBAN } from "ibantools";
import { Log } from "@/types/helpers/logHelper";
import AutoComplete from "primevue/autocomplete";
import ProgressSpinner from "primevue/progressspinner";

@Component({
	components: { InputWithValidation, AutoComplete, ProgressSpinner },
	emits: ["update:modelValue"],
})
export default class CompanyForm extends BaseComponent implements FormComponent<CompanyFormModel> {
	@Model({
		type: Object as PropType<CompanyFormModel>,
		required: true,
		default: {},
	})
	model!: CompanyFormModel;

	@Prop({ type: Boolean, required: false, default: false }) useVat!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) useIban!: boolean;
	@Prop({
		type: Object as PropType<{ [key: string]: string }>,
		required: true,
		default: {},
	})
	countries!: { [key: string]: string }[];
	@Prop({ type: Boolean, default: false }) autoComplete!: boolean;
	@Prop({ type: Boolean, default: false }) hasPreviouslyOrdered!: boolean;

	@Emit()
	formStateChanged(): FormMeta<CompanyFormModel> {
		const { meta, values } = this.form;
		this.model = cloneDeep(values);
		return unref(meta);
	}

	form!: FormContext<CompanyFormModel>;
	companySuggestions: any = [];
	isKnownInCrm = false;

	created(): void {
		if (!this.model.countryCode && this.countries.length === 1) {
			this.model.countryCode = this.countries[0].key;
		}

		if (this.model.dunsNumber) this.isKnownInCrm = true;
		this.form = useForm({ initialValues: this.model, validationSchema: this.validations });
	}

	vatRequired = true;

	get validations(): Record<string, unknown> {
		return reactive({
			companyName: { required: true },
			cocNumber: {},
			vatNumber: {
				required: computed(() => this.vatRequired),
				validVatNumber: [this.model.countryCode, this.useVat],
			},
			iban: {
				required: this.isIbanRequired,
				validIbanNumber: this.useIban,
			},
			street: { required: true },
			postalCode: {
				required: true,
				validPostalCode: "@countryCode",
			},
			city: { required: true },
			countryCode: { required: this.countries?.length > 1 },
			dunsNumber: {},
		});
	}

	get isValid(): boolean {
		return unref(this.form.meta)?.valid;
	}

	get isDirty(): boolean {
		return unref(this.form.meta)?.dirty;
	}

	get isPending(): boolean {
		return unref(this.form.meta)?.pending;
	}

	@Watch("form.values.countryCode")
	isVatRequired() {
		this.vatRequired = this.useVat && ValidationHelper.checkIfVatIsRequired(this.model.countryCode || "");
	}

	get isIbanRequired(): boolean {
		return this.useIban;
	}

	get canEditCompany(): boolean {
		return !this.isKnownInCrm || (this.hasPreviouslyOrdered && this.isKnownInCrm);
	}

	formatIban(value: string): void {
		this.form.setFieldValue("iban", friendlyFormatIBAN(value) || this.model.iban);
	}

	searchCompanies(query: string): void {
		const countries = ["nl", "be", "de", "ch", "at", "fr", "pt", "es", "it", "gb", "nb"];
		const countryISOAlpha2Code = countries.includes(this.currentLocation) ? this.currentLocation : null;

		this.axios
			.get(`/api/altares/organizations/by-name`, {
				params: { searchTerm: query.trim(), countryISOAlpha2Code: countryISOAlpha2Code },
			})
			.then((res) => {
				this.companySuggestions = res.data;
			})
			.catch((err) => Log.error(err))
			.finally();
	}

	autoFillOptOut(): void {
		this.form.setFieldValue("dunsNumber", "");
		this.isKnownInCrm = false;
	}

	onSuggestionSelection(dunsNumber: string): void {
		this.loadingStore.showSpinner("autofill");

		this.axios
			.get(`/api/altares/organization/by-duns`, {
				params: { dunsNumber: dunsNumber },
			})
			.then((res) => {
				const { name, coCNumber, vatNumber, iban, mainAddress, isKnownInCrm, dunsNumber } = res.data;

				this.form.setValues({
					companyName: name,
					cocNumber: coCNumber,
					vatNumber: vatNumber,
					iban: iban,
					street: mainAddress.addressLine1,
					postalCode: mainAddress.postalCode,
					city: mainAddress.city,
					countryCode: mainAddress.country.code,
					dunsNumber: dunsNumber,
				});
				this.isKnownInCrm = isKnownInCrm;

				this.form.setTouched({
					companyName: true,
					cocNumber: true,
					vatNumber: true,
					iban: true,
					street: true,
					postalCode: true,
					city: true,
					countryCode: true,
				});

				this.formStateChanged();
			})
			.catch((err) => Log.error(err))
			.finally(() => {
				this.loadingStore.hideSpinner("autofill");
			});
	}
}
</script>

<style scoped lang="scss">
.loader {
	position: absolute;
	height: 100%;
	z-index: 10;
}
</style>
