<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<div class="row">
				<div class="col">
					<h2 v-if="vm.title" class="display-4 mb-0">{{ vm.title }}</h2>
					<p v-if="vm.subtitle" class="lead">{{ vm.subtitle }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<slot />
				</div>
			</div>
			<div class="row gap-y-3">
				<div v-for="collection in vm.collections" :key="collection" class="col-12 col-lg-6">
					<BaseProductOverviewItemCard :vm="collection" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import BaseProductOverviewItemCard from "@/components/product/baseProductOverviewItemCard.vue";
import { CollectionGridBlockViewModel } from "@/types/viewModels/blocks/collectionGridBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";

@Component({ components: { BaseProductOverviewItemCard } })
export default class CollectionsGridBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<CollectionGridBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: CollectionGridBlockViewModel;

	created(): void {
		Log.info("CollectionsGridBlock", this.vm);
	}
}
</script>

<style lang="scss" scoped></style>
