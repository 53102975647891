<template>
	<div v-show="languages?.length">
		<Dropdown
			v-model="selectedLanguage"
			append-to="self"
			class="language-menu"
			:class="{ 'w-100': useFullWidth, 'pr-2': useFullWidth }"
			option-label="name"
			:options="languages"
			placeholder=" "
			scrollheight="500px"
			@change="onLangSelected"
		>
			<template #value="slotProps">
				<div v-if="slotProps.value" class="d-flex flex-row align-items-center">
					<country-flag
						class="flag-icon rounded-lg"
						:country="mapLanguageToCountryCode(slotProps.value.code)"
						size="normal"
					/>
					<div v-if="useFullWidth">{{ languages.find((x) => x.code === slotProps.value)?.name }}</div>
				</div>
			</template>
			<template #option="slotProps">
				<div class="d-flex flex-row align-items-center">
					<country-flag
						class="rounded-lg mr-1 my-0"
						:country="mapLanguageToCountryCode(slotProps.option.code)"
						size="normal"
					/>
					<div>{{ slotProps.option.name }}</div>
				</div>
			</template>
		</Dropdown>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ChangeCompanyModal from "@/components/common/menu/changeCompanyModal.vue";
import Dropdown from "primevue/dropdown";
import MainSearchBar from "@/components/common/menu/mainSearchBar.vue";
import OverlayPanel from "primevue/overlaypanel";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { CookieType } from "@/types/enum/cookieType";
import { LocationOptionViewModel } from "@/types/models/menu/locationOptionViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SetCookieRequest } from "@/types/models/menu/setCookieRequest";

@Component({
	components: { ChangeCompanyModal, OverlayPanel, MainSearchBar, Dropdown },
	emits: ["update-language"],
})
export default class LanguageDropdown extends BaseComponent {
	@Prop({ type: Object as PropType<LocationOptionViewModel[]>, required: true, default: [] })
	languages!: LocationOptionViewModel[];
	@Prop({ type: Boolean, required: false, default: false }) useFullWidth!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) updateCookie!: boolean;

	selectedLanguage!: LocationOptionViewModel;

	created(): void {
		if (this.layoutStore.vm?.currentLanguage?.key) {
			this.selectedLanguage =
				(this.languages.find(
					(x) => x.code === this.layoutStore.vm?.currentLanguage?.key
				) as LocationOptionViewModel) || this.languages[0];
		}
	}

	get mapLanguageToCountryCode() {
		return (lang: string) => (lang === "en" ? "gb" : lang);
	}

	@Emit()
	languageSelected(language: LocationOptionViewModel): LocationOptionViewModel {
		return language;
	}

	onLangSelected(): void {
		const payload: SetCookieRequest = {
			type: CookieType.Language,
			value: this.selectedLanguage?.code,
		};
		if (this.updateCookie) {
			this.loadingStore.increaseLoadingCount();
			this.axios
				.put("/api/cookie", payload)
				.then(() => {
					if (this.selectedLanguage?.redirectUrl) {
						window.location.href = this.selectedLanguage.redirectUrl;
					} else {
						window.location.reload();
					}
				})
				.catch((err) => Log.error(err));
		} else {
			this.languageSelected(this.selectedLanguage);
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
	.p-dropdown-label {
		padding: 0;
	}
	.pi {
		font-size: 0.8rem;
	}
	.p-dropdown-trigger {
		width: 0 !important;
		margin-left: 0.2rem;

		&:focus {
			outline: none;
		}
	}

	&:focus {
		box-shadow: none !important;
		outline: none !important;
	}
}

.language-menu {
	border: none;
	width: auto;

	.flag-icon {
		margin: 0.35rem 0;
	}
}

::v-deep(.p-dropdown-panel) {
	.p-dropdown-items {
		padding: 0;

		.p-dropdown-item {
			padding: 0.4rem 0.8rem;

			&.p-highlight {
				background: var(--surface-b);
			}
		}
	}
}
</style>
