import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9563cbde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "contract-info" }
const _hoisted_3 = {
  key: 0,
  class: "mb-0 text-bold"
}
const _hoisted_4 = ["title", "onClick"]
const _hoisted_5 = {
  key: 0,
  class: "d-block text-muted"
}
const _hoisted_6 = {
  key: 1,
  class: "mb-0 text-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_AvatarGroup = _resolveComponent("AvatarGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '50vw', '1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '90vw' },
    modal: true,
    style: { width: '40vw' },
    onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancelExtend()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.title")), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelExtend()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.$t('common.confirm'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmExtend()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.currentContract")), 1),
      _createElementVNode("dl", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.colContractNumber")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.vm.contract.contractId), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.colDuration")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.vm.contract.duration), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.colRemainingDuration")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.$tc("myCadac.contractDetail.table.duration", _ctx.vm.contract.remainingMonths)), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.colExpiresOn")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.formatDate(_ctx.vm.contract.endDate)), 1)
        ])
      ]),
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.chooseContract")), 1),
      (_ctx.extendOptions.length)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            selection: _ctx.selectedProlongationOption,
            "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProlongationOption) = $event)),
            "auto-layout": "",
            "data-key": "contractNumber",
            "row-class": _ctx.rowClass,
            "selection-mode": "single",
            value: _ctx.extendOptions
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                key: "contractNumber",
                field: "contractNumber",
                header: _ctx.$t('myCadac.contractDetail.extendModal.colContractNumber')
              }, {
                body: _withCtx((item) => [
                  (_ctx.userProfile?.isCadacSales || _ctx.userProfile?.isImpersonated)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                        _createElementVNode("a", {
                          href: "javascript:void(0);",
                          title: _ctx.$t('common.goToCrm'),
                          onClick: ($event: any) => (_ctx.openUrl(item.data.contractClient.crmUrl, '_blank'))
                        }, _toDisplayString(item.data.contractNumber), 9, _hoisted_4),
                        (item.data.contractClient.crmBusinessUnit)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t("myCadac.contractDetail.extendModal.lblCrmBusinessUnit", {
									name: item.data.contractClient.crmBusinessUnit.name,
								})), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.data.contractNumber), 1))
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "products",
                header: _ctx.$t('myCadac.contractDetail.extendModal.colProducts')
              }, {
                body: _withCtx((item) => [
                  _createVNode(_component_AvatarGroup, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data.contractClient?.contractLines?.slice(
								0,
								_ctx.maxContractAssetsToShow
							), (asset, index) => {
                        return (_openBlock(), _createBlock(_component_Avatar, {
                          key: index,
                          alt: asset.iconAlt,
                          class: "ml-0 bg-transparent",
                          image: asset.icon,
                          shape: "square",
                          size: "small",
                          title: asset.productName
                        }, null, 8, ["alt", "image", "title"]))
                      }), 128)),
                      (item.data.contractClient?.contractLines?.length > _ctx.maxContractAssetsToShow)
                        ? (_openBlock(), _createBlock(_component_Avatar, {
                            key: 0,
                            class: "text-bold ml-0",
                            label: `+${item.data.contractClient?.contractLines?.length - _ctx.maxContractAssetsToShow}`,
                            shape: "square",
                            size: "small"
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "renewalTerm",
                field: "duration",
                header: _ctx.$t('myCadac.contractDetail.extendModal.colDuration')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "endDate",
                field: "endDate",
                header: _ctx.$t('myCadac.contractDetail.extendModal.colExpiresOn')
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.data.endDate)), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                key: "remainingTerm",
                header: _ctx.$t('myCadac.contractDetail.table.colRemainingDuration')
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$tc("myCadac.contractDetail.table.duration", item.data.remainingMonthsForOrder)), 1)
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["selection", "row-class", "value"]))
        : _createCommentVNode("", true),
      (_ctx.isRowDisabled(_ctx.selectedProlongationOption))
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 1,
            class: "mb-0",
            closable: false,
            severity: "error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("myCadac.contractDetail.message.selectedOptionNotAvailable")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}