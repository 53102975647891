<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<template #header>
			<h3 class="mb-0">
				{{ $t("training.dialog.titleAddParticipant") }}
			</h3>
		</template>

		<TrainingParticipantForm v-model="participantForm" ref="trainingParticipantForm" :submitted="true" />

		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :disabled="!canSubmit" :label="$t('common.add')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import TrainingParticipantForm from "@/components/common/forms/trainingParticipantForm.vue";
import { Component, Emit, Ref, Watch } from "vue-facing-decorator";
import { TrainingParticipantForm as TrainingParticipantFormModel } from "@/types/models/form/trainingParticipantForm";
import { useTrainingStore } from "@/store/training/trainingStore";

@Component({
	components: { TrainingParticipantForm },
})
export default class TrainingAddParticipantsModal extends BaseComponent {
	@Ref() readonly trainingParticipantForm!: TrainingParticipantForm;

	participantForm!: TrainingParticipantFormModel;

	isVisible = true;
	submitted = false;
	canSubmit = false;

	@Emit addParticipant(): TrainingParticipantFormModel {
		return this.participantForm as TrainingParticipantFormModel;
	}

	@Watch("participantForm", { deep: true }) onParticipantFormChange(): void {
		this.canSubmit = this.trainingParticipantForm?.isAnyDirty && this.trainingParticipantForm?.isValid;
	}

	created() {
		this.participantForm = {
			firstName: "",
			lastName: "",
			email: "",
			id: "",
			displayName: "",
			isUnknown: true,
		};
	}

	onCancel(): void {
		useTrainingStore().hideModal("AddParticipant");
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.addParticipant();
	}
}
</script>

<style scoped lang="scss"></style>
