import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap" }
const _hoisted_4 = { class: "d-flex align-items-center ml-1 ml-lg-0" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mb-0 text-muted d-block" }
const _hoisted_7 = {
  key: 0,
  class: "mb-0 text-bold"
}
const _hoisted_8 = ["href", "title"]
const _hoisted_9 = {
  key: 1,
  class: "mb-0 text-bold"
}
const _hoisted_10 = { class: "text-right" }
const _hoisted_11 = {
  key: 0,
  class: "d-block text-muted"
}
const _hoisted_12 = { class: "col-md-10 col-lg-8 col-xl-6 ml-auto pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ContractSummary = _resolveComponent("ContractSummary")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "my-3" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("myCadac.contractDetail.card.titleExtend")), 1),
        _createVNode(_component_Button, {
          class: "p-button-text",
          label: _ctx.$t('common.cancel'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onExtendCancelled()))
        }, null, 8, ["label"])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ContractSummary, {
          class: "mb-1",
          title: _ctx.$t('myCadac.contractDetail.card.lblCurrentContract'),
          vm: _ctx.currentContractSummary
        }, null, 8, ["title", "vm"]),
        _createVNode(_component_ContractSummary, {
          title: _ctx.$t('myCadac.contractDetail.card.lblCoTermContract'),
          vm: _ctx.selectedContractSummary
        }, null, 8, ["title", "vm"])
      ]),
      _createVNode(_component_Message, {
        class: "mt-0",
        closable: false,
        severity: "warn"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_i18n_t, { keypath: "myCadac.contractDetail.message.newExpirationDateDurationChange" }, {
            date: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(_ctx.newEndDate)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_DataTable, {
        "auto-layout": "",
        "data-key": "serialNumber",
        "responsive-layout": "stack",
        value: _ctx.prolongableContractLines
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "productName",
            header: _ctx.$t('myCadac.contractDetail.table.colProductName'),
            sortable: false
          }, {
            body: _withCtx((item) => [
              _createElementVNode("div", _hoisted_4, [
                (item.data.icon)
                  ? (_openBlock(), _createBlock(_component_Avatar, {
                      key: 0,
                      alt: item.data.iconAlt,
                      class: "mr-1 bg-transparent flex-shrink-0",
                      image: item.data.icon,
                      shape: "square",
                      size: "large",
                      title: item.productName
                    }, null, 8, ["alt", "image", "title"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("small", _hoisted_6, _toDisplayString(item.data.productBrand), 1),
                  (_ctx.userProfile?.isCadacSales || _ctx.userProfile?.isImpersonated)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                        _createElementVNode("a", {
                          href: item.data.crmUrl,
                          target: "_blank",
                          title: _ctx.$t('common.goToCrm')
                        }, _toDisplayString(_ctx.formatProductName(item.data.productName, item.data.productBrand)), 9, _hoisted_8)
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.formatProductName(item.data.productName, item.data.productBrand)), 1))
                ])
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "deployment",
            header: _ctx.$t('myCadac.contractDetail.table.colProductLicenceType'),
            sortable: false
          }, {
            body: _withCtx((item) => [
              _createTextVNode(_toDisplayString(_ctx.$t(`enum.deployment.${item.data.deployment}`)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "seats",
            header: _ctx.$t('myCadac.contractDetail.table.colProductSeats'),
            sortable: false
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "endDate",
            header: _ctx.$t('myCadac.contractDetail.table.colRemainingDuration'),
            sortable: false
          }, {
            body: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tc(
								"myCadac.contractDetail.table.duration",
								_ctx.selectedExtendContract?.remainingMonthsForOrder
							)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            class: "text-lg-right",
            header: _ctx.$t('myCadac.contractDetail.table.colProductPrice'),
            sortable: false
          }, {
            body: _withCtx((item) => [
              _createElementVNode("div", _hoisted_10, [
                (item.data.priceBase !== item.data.price)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_11, [
                      _createElementVNode("s", null, _toDisplayString(_ctx.formatCurrency(_ctx.calcProRatePrice(item.data.priceBase, _ctx.vm.contract.duration))), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.calcProRatePrice(item.data.price, _ctx.vm.contract.duration))), 1)
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_PriceSummary, { vm: _ctx.priceSummary }, null, 8, ["vm"]),
        _createVNode(_component_Button, {
          class: "p-button-success p-button-raised p-button-lg d-block ml-auto",
          disabled: !_ctx.canCheckout,
          label: _ctx.$t('myCadac.contractDetail.button.goToCheckout'),
          loading: _ctx.isLoading,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCheckoutClick()))
        }, null, 8, ["disabled", "label", "loading"])
      ])
    ]),
    _: 1
  }))
}