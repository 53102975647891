import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d49d2730"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center font-weight-bold text-primary justify-content-between flex-1" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-check ml-1"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "row-expansion-content" }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PopoverIcon = _resolveComponent("PopoverIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", {
    class: "training-course-table",
    style: _normalizeStyle({ height: _ctx.maxTableHeight })
  }, [
    (_ctx.items.length)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          "expanded-rows": _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedRows) = $event)),
          selection: _ctx.selectedRow,
          "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRow) = $event)),
          "data-key": "id",
          "row-class": _ctx.highlightRow,
          "row-hover": true,
          "scroll-height": "flex",
          scrollable: "",
          "selection-mode": "single",
          value: _ctx.items,
          onRowClick: _withModifiers(_ctx.onRowClick, ["self"])
        }, {
          expansion: _withCtx((item) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", {
                class: "text-preline",
                textContent: _toDisplayString(item.data.intro)
              }, null, 8, _hoisted_5)
            ])
          ]),
          default: _withCtx(() => [
            (!_ctx.isSmallScreen)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  expander: "",
                  style: {"min-width":"1.75rem","max-width":"2.5rem"}
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              key: "name",
              field: "name",
              sortable: false
            }, {
              body: _withCtx((item) => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(item.data.name) + " ", 1),
                    (_ctx.isRowSelected(item.data))
                      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                      : _createCommentVNode("", true)
                  ]),
                  (item.data.modules.length)
                    ? (_openBlock(), _createBlock(_component_PopoverIcon, {
                        key: 0,
                        class: "d-inline-flex m-0 border-0"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: _ctx.$t('training.modularTraining')
                          }, null, 8, _hoisted_3)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fas', 'list-check'],
                            style: {"color":"var(--secondary-color)","line-height":"100%"}
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["expanded-rows", "selection", "row-class", "value", "onRowClick"]))
      : _createCommentVNode("", true)
  ], 4))
}