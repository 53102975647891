import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56fe4cd3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-icons-wrapper ml-lg-1" }
const _hoisted_2 = {
  key: 0,
  class: "product-info-line flex-lg-row"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "text-bold ml-1" }
const _hoisted_5 = {
  key: 1,
  class: "product-info-line flex-lg-row"
}
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "text-bold ml-1" }
const _hoisted_8 = {
  key: 2,
  class: "product-info-line flex-lg-row"
}
const _hoisted_9 = { class: "badge badge-primary multi-user-badge" }
const _hoisted_10 = {
  key: 3,
  class: "product-info-line flex-lg-row"
}
const _hoisted_11 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.licenseType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("i", {
            class: _normalizeClass(["text-primary", _ctx.getLicenceIcon(_ctx.item.licenceClass)]),
            title: _ctx.$t('cart.table.iconLicenceType_title')
          }, null, 10, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.licenseType), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.duration)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("i", {
            class: "pi pi-clock text-primary",
            title: _ctx.$t('cart.table.iconDuration_title')
          }, null, 8, _hoisted_6),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.duration), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.multiUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.multiUser), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.walletDepositTypes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.walletDepositTypes), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}