import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16f72696"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 mb-1" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "d-flex align-items-center ml-1 ml-lg-0" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "mb-0 text-muted d-block" }
const _hoisted_10 = { class: "mb-0 text-bold" }
const _hoisted_11 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_BlockUI = _resolveComponent("BlockUI")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vm.pageTitle)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.vm.pageTitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.licenceProducts?.length)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 0,
              closable: false,
              severity: "warn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("myCadac.licenceManagementOverview.message.noLicences")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.licencePanelUnlocked && _ctx.licenceProducts?.length)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              class: "message-with-button",
              closable: false,
              severity: "warn"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_i18n_t, { keypath: "myCadac.licenceManagementOverview.message.confirmUnlock" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, {
                      class: "p-button-sm p-button-secondary ml-auto",
                      label: _ctx.$t('common.confirm'),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unlockPanel()))
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.licenceProducts?.length)
          ? (_openBlock(), _createBlock(_component_BlockUI, {
              key: 2,
              "base-z-index": 1,
              blocked: !_ctx.licencePanelUnlocked,
              class: _normalizeClass(!_ctx.licencePanelUnlocked ? 'p-2' : '')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Panel, {
                  id: "panel-softwareLicences",
                  toggleable: false
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("myCadac.licenceManagementOverview.panel.titleSoftware")), 1),
                      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("myCadac.licenceManagementOverview.panel.subtitleSoftware")), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    (_ctx.licenceProducts?.length)
                      ? (_openBlock(), _createBlock(_component_DataTable, {
                          key: 0,
                          "removable-sort": "",
                          "responsive-layout": "stack",
                          value: _ctx.licenceProducts
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Column, {
                              field: "productName",
                              header: _ctx.$t('myCadac.licenceManagementOverview.table.colProduct'),
                              sortable: false
                            }, {
                              body: _withCtx((item) => [
                                _createElementVNode("div", _hoisted_7, [
                                  (item.data.productIcon)
                                    ? (_openBlock(), _createBlock(_component_Avatar, {
                                        key: 0,
                                        alt: item.data.productName,
                                        class: "mr-1 bg-transparent flex-shrink-0",
                                        image: item.data.productIconUrl,
                                        shape: "square",
                                        size: "large",
                                        title: item.data.productName
                                      }, null, 8, ["alt", "image", "title"]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("small", _hoisted_9, _toDisplayString(item.data.productBrand), 1),
                                    _createElementVNode("p", _hoisted_10, _toDisplayString(item.data.productName), 1)
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["header"]),
                            _createVNode(_component_Column, {
                              key: "licenceAmount",
                              header: _ctx.$t('myCadac.licenceManagementOverview.table.colLicences')
                            }, {
                              body: _withCtx((item) => [
                                _createElementVNode("span", {
                                  class: _normalizeClass(_ctx.isOutOfLicences(item.data) ? 'text-danger' : '')
                                }, _toDisplayString(item.data.totalActiveLicences) + " / " + _toDisplayString(item.data.totalSeats), 3)
                              ]),
                              _: 1
                            }, 8, ["header"]),
                            _createVNode(_component_Column, { key: "actions" }, {
                              body: _withCtx((item) => [
                                _createElementVNode("div", _hoisted_11, [
                                  (_ctx.showBuyLicencesButton(item.data))
                                    ? (_openBlock(), _createBlock(_component_Button, {
                                        key: 0,
                                        class: "p-button-success p-button-sm mr-auto mr-lg-1 flex-shrink-0",
                                        label: _ctx.$t('myCadac.licenceManagementOverview.button.buyLicences'),
                                        onClick: ($event: any) => {
											_ctx.openUrl(item.data.productPageUrl);
											$event.target.disabled = true;
										}
                                      }, null, 8, ["label", "onClick"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_Button, {
                                    class: "p-button-primary p-button-icon p-button-rounded p-button-raised p-button-text ml-1 flex-shrink-0",
                                    icon: "pi pi-pencil",
                                    label: 
											_ctx.isSmallScreen
												? _ctx.$t('myCadac.licenceManagementOverview.button.viewDetails')
												: ''
										,
                                    title: _ctx.$t('myCadac.licenceManagementOverview.button.viewDetails'),
                                    onClick: ($event: any) => {
											_ctx.openUrl(
												`${_ctx.vm.softwareLicenceDetailUrl}?productGroupId=${item.data.id}`
											);
											$event.target.disabled = true;
										}
                                  }, null, 8, ["label", "title", "onClick"])
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["blocked", "class"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}