<template>
	<div class="product-icons-wrapper ml-lg-1">
		<div v-if="item.licenseType" class="product-info-line flex-lg-row">
			<i
				class="text-primary"
				:class="getLicenceIcon(item.licenceClass)"
				:title="$t('cart.table.iconLicenceType_title')"
			/>
			<span class="text-bold ml-1">{{ item.licenseType }}</span>
		</div>

		<div v-if="duration" class="product-info-line flex-lg-row">
			<i class="pi pi-clock text-primary" :title="$t('cart.table.iconDuration_title')" />
			<span class="text-bold ml-1">{{ duration }}</span>
		</div>

		<div v-if="multiUser" class="product-info-line flex-lg-row">
			<span class="badge badge-primary multi-user-badge">{{ multiUser }}</span>
		</div>

		<div v-if="walletDepositTypes" class="product-info-line flex-lg-row">
			<span class="">{{ walletDepositTypes }}</span>
		</div>
	</div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { LicenseClass } from "@/types/enum/licenseClass";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";

@Component
export default class ColumnProductInfo extends BaseComponent {
	@Prop({ type: Object as PropType<CartLineClient>, required: true, default: {} }) item!: CartLineClient;

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get duration(): string {
		return this.item.remainingDuration
			? this.$tc("cart.table.lblDurationTillEndOfContract", this.item.remainingDuration)
			: this.item.duration
				? this.$tc("cart.table.lblDurationMonths", this.item.duration)
				: "";
	}

	get multiUser(): string {
		return this.item.isMultiSize ? this.item?.multiSizeUnitString : "";
	}

	get walletDepositTypes(): string {
		return this.item.walletDepositTypes
			?.filter(Boolean)
			.map((x) => this.$t(`enum.walletDepositType.${x}`))
			.join(", ");
	}

	getLicenceIcon(licenceClass: LicenseClass): string {
		switch (licenceClass) {
			case LicenseClass.singleUser:
				return "pi pi-user";
			case LicenseClass.multiUser:
				return "pi pi-users";
			case LicenseClass.maintenancePlan:
				return "pi pi-desktop";
			case LicenseClass.subscription:
				return "pi pi-file";
			case LicenseClass.cloudSubscription:
				return "pi pi-cloud";
			case LicenseClass.other:
			default:
				return "";
		}
	}
}
</script>

<style lang="scss" scoped>
.product-icon {
	width: 47px;
	height: 47px;
	background: transparent;
	flex-shrink: 0;
}

.product-icons-wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	flex-basis: 100%;
	padding: 0 0.5rem;

	.product-info-line {
		display: flex;
		flex: 0;
		align-items: center;
		flex-direction: row;
		margin-bottom: 0.25rem;
	}
}

@media print {
	.product-icons-wrapper {
		align-content: flex-start;
	}
}
</style>
