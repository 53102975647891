import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f9f5d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "text-muted m-0 text-preline" }
const _hoisted_3 = { class: "mb-0 text-bold text-primary" }
const _hoisted_4 = { class: "bubble-container" }
const _hoisted_5 = { class: "slider-bubble" }
const _hoisted_6 = { class: "slider-container" }
const _hoisted_7 = { class: "slider-time mt-1" }
const _hoisted_8 = { class: "slider-time-start" }
const _hoisted_9 = { class: "slider-time-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("expert.selectStartEndTime")), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.formatTime(_ctx.selectedDates[0])) + " - " + _toDisplayString(_ctx.formatTime(_ctx.selectedDates[1])) + " (" + _toDisplayString(_ctx.$tc("common.hour", [_ctx.selectedTotalTime])) + ") ", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedHours, (selectedHour, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `slider-bubble-${selectedHour}`,
          class: "bubble",
          style: _normalizeStyle({ left: `${_ctx.bubbleStyle(selectedHour)}%` })
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("small", null, _toDisplayString(_ctx.formatTime(_ctx.selectedDates[i])), 1)
          ])
        ], 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Slider, {
        max: _ctx.workDayEndHour,
        min: _ctx.workDayStartHour,
        "model-value": _ctx.selectedHours,
        range: true,
        step: 1,
        onSlideend: _ctx.onValidityChange,
        "onUpdate:modelValue": _ctx.onSliderValueChange
      }, null, 8, ["max", "min", "model-value", "onSlideend", "onUpdate:modelValue"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unavailableHours, (unavailableHour, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: `striped-overlay${index}`,
          class: "p-slider-range-striped",
          style: _normalizeStyle(_ctx.unavailableRangeStyle(unavailableHour))
        }, null, 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("b", _hoisted_8, [
        _createElementVNode("small", null, _toDisplayString(`${_ctx.workDayStartHour}:00`), 1)
      ]),
      _createElementVNode("b", _hoisted_9, [
        _createElementVNode("small", null, _toDisplayString(`${24 > _ctx.workDayEndHour ? _ctx.workDayEndHour : _ctx.workDayEndHour - 24}:00`), 1)
      ])
    ])
  ], 64))
}