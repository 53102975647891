<template>
	<nav class="top-menu hidden-print">
		<div class="container d-flex flex-row justify-content-between">
			<div class="top-menu-impersonate">
				<ImpersonationForm v-if="!layoutStore.isImpersonated && layoutStore.canImpersonate" />
			</div>
			<div class="top-menu-nav">
				<ul class="top-menu-items">
					<li v-for="menuItem in layoutStore.vm.subMenuItems" :key="menuItem" class="">
						<a :href="menuItem.url" :title="menuItem.title"> {{ menuItem.label }}</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
	<nav ref="mainMenu" class="main-menu hidden-print">
		<div class="container">
			<div v-if="!isLargeScreen" class="main-menu-logo">
				<a :href="layoutStore.vm.homePageUrl">
					<svg height="46.45" width="253.08">
						<use xlink:href="#main-logo" />
					</svg>
				</a>
			</div>
			<div v-else class="main-menu-logo-mobile">
				<img
					v-lazy="'/Static/images/cadacgroup-logo-mobile.svg'"
					style="cursor: pointer"
					@click="openUrl(layoutStore.vm.homePageUrl)"
				/>
			</div>
			<div class="main-menu-nav">
				<ul class="main-menu-nav-items">
					<li v-for="menuItem in layoutStore.vm.mainMenuItems" :key="menuItem" class="">
						<a :href="menuItem.url" :title="menuItem.title"> {{ menuItem.label }}</a>
					</li>
				</ul>
				<div class="main-menu-search" :class="{ searchActive: layoutStore.showSearchBar }">
					<MainSearchBar :vm="layoutStore.vm" />
				</div>
				<div class="main-menu-icons">
					<font-awesome-icon
						:class="{ 'text-primary': layoutStore.showSearchBar }"
						:icon="['far', 'magnifying-glass']"
						:title="$t('menu.button.search_title')"
						@click="onToggleSearchClick()"
					/>
					<font-awesome-layers
						v-if="layoutStore.vm.itemsInCart"
						class="fa-fw fa-2x"
						@click="onCartClick()"
					>
						<font-awesome-icon
							:icon="['far', 'cart-shopping']"
							:title="$t('menu.button.cart_title')"
						/>
						<font-awesome-layers-text
							class="fa-layers-counter"
							transform="up-1 right-15"
							:value="layoutStore.vm.itemsInCart"
						/>
					</font-awesome-layers>
					<font-awesome-icon
						v-else
						:icon="['far', 'cart-shopping']"
						:title="$t('menu.button.cartEmpty_title')"
						@click="onCartClick()"
					/>
					<font-awesome-icon
						:class="{ 'text-info': layoutStore.isImpersonated }"
						:icon="
							layoutStore.isImpersonated
								? ['far', 'user-shield']
								: layoutStore.isAuthenticated
									? ['fas', 'circle-user']
									: ['far', 'circle-user']
						"
						:title="$t('menu.button.userMenu_title')"
						@click="onToggleUserMenuClick($event)"
					/>
					<LanguageDropdown
						:languages="layoutStore.vm.availableLanguageOptions"
						:title="$t('menu.chooseLanguage_title')"
						update-cookie
					/>
				</div>
			</div>
		</div>
		<div v-if="layoutStore.isImpersonated" class="container">
			<Message class="m-0 w-100 message-with-button" :closable="false" severity="info">
				<div>
					<i18n-t keypath="menu.loggedInAsCustomer">
						<template #user>
							<span>
								<b>{{ layoutStore.vm?.impersonatedUser?.userName }}</b>
								{{
									`- ${layoutStore.vm?.impersonatedUser?.company?.companyName} (${layoutStore.vm?.impersonatedUser?.company?.cadacOrganizationCode})`
								}}
							</span>
						</template>
					</i18n-t>
					<InlineMessage v-if="!layoutStore.vm?.impersonatedUser?.isEmailVerified" severity="warn">
						{{ $t("menu.unverifiedEmail") }}
					</InlineMessage>
				</div>
				<div>
					<ImpersonationForm />
				</div>
			</Message>
		</div>
	</nav>
	<div
		v-if="
			layoutStore.vm.uspBlock.items &&
			layoutStore.vm.uspBlock.items.length &&
			layoutStore.vm.uspBlock.isVisible"
		class="usp-bar w-100 hidden-print"
	>
		<div class="container d-flex">
			<ul>
				<li v-for="item in layoutStore.vm.uspBlock.items" :key="item">
					<template v-if="item.uspCollectionLink">
						<a :href="item.uspCollectionLink.url">
							<font-awesome-icon class="usp-icon" :icon="item.uspIcon" />
							{{ item.uspCollectionTitle }}
							<span class="usp-text-small">{{ item.uspSubText }}</span>
						</a>
					</template>
					<template v-if="!item.uspCollectionLink">
						<font-awesome-icon class="usp-icon" :icon="item.uspIcon" />
						{{ item.uspCollectionTitle }}
						<span class="usp-text-small">{{ item.uspSubText }}</span>
					</template>
				</li>
			</ul>
		</div>
	</div>

	<OverlayPanel
		ref="userMenuPanel"
		append-to=".main-menu"
		:breakpoints="{ '1400px': '250px' }"
		class="main-menu-overlay"
		:dismissable="true"
		:show-close-icon="false"
		:style="{ width: '300px' }"
	>
		<MainUserMenu />
	</OverlayPanel>
	<ChangeCompanyModal v-if="layoutStore.changeCompanyModalVisible" :vm="layoutStore.vm.organizationOptions" />
</template>

<script lang="ts">
import ChangeCompanyModal from "@/components/common/menu/changeCompanyModal.vue";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InlineMessage from "primevue/inlinemessage";
import ImpersonationForm from "@/components/common/forms/impersonationForm.vue";
import LanguageDropdown from "@/components/common/menu/languageDropdown.vue";
import MainSearchBar from "@/components/common/menu/mainSearchBar.vue";
import MainUserMenu from "@/components/common/menu/mainUserMenu.vue";
import OverlayPanel from "primevue/overlaypanel";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Ref } from "vue-facing-decorator";

@Component({
	components: {
		ChangeCompanyModal,
		MainSearchBar,
		Dropdown,
		LanguageDropdown,
		Divider,
		MainUserMenu,
		OverlayPanel,
		ImpersonationForm,
		InlineMessage,
	},
})
export default class MainMenuBar extends BaseComponent {
	@Ref readonly userMenuPanel!: OverlayPanel;
	@Ref readonly mainMenu!: HTMLElement;

	mounted(): void {
		this.layoutStore.navBarHeight = this.mainMenu?.clientHeight;
		const showedCompanyModal: string | null = localStorage.getItem("showedCompanyModal");

		if (
			this.layoutStore.isAuthenticated &&
			// process.env.NODE_ENV === "production" &&
			this.layoutStore.vm.organizationOptions.length > 1
		) {
			if (showedCompanyModal === "false") {
				this.layoutStore.showModal("ChangeCompany");
			}
		}
	}

	onToggleUserMenuClick(event: Event): void {
		this.layoutStore.toggleUserMenu();
		this.userMenuPanel.toggle(event);
	}

	onToggleSearchClick(): void {
		this.layoutStore.toggleSearchBar();
	}

	onCartClick(): void {
		this.openUrl(this.layoutStore.vm.cartPageUrl);
	}
}
</script>

<style scoped lang="scss">
.top-menu {
	background-color: #fff;
	display: flex;
	align-items: center;
	padding: 0.5rem 0.5rem;
	border-bottom: 0.8px solid rgba(0, 0, 0, 0.15);

	.top-menu-nav {
		display: flex;
		align-items: center;

		.top-menu-items {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			li {
				flex-shrink: 0;
				margin-right: 2rem;

				a {
					color: rgba(51, 51, 51, 0.6);
					font-size: 1.05rem;
					font-weight: 400;
					letter-spacing: 0.2px;
					cursor: pointer;

					&:hover {
						color: rgba(51, 51, 51, 1);
					}
				}
			}
		}
	}

	.top-menu-impersonate {
		display: flex;
		align-items: center;
		padding-right: 1.5rem;

		::v-deep(.p-button) {
			flex-shrink: 0;
		}
	}
}

.main-menu {
	padding: 0.5rem;
	position: sticky;
	top: 0;
	z-index: 120;
	background: var(--surface-a);
	border-bottom: 0.8px solid rgba(0, 0, 0, 0.15);

	.container {
		display: flex;
		align-items: center;
	}

	.main-menu-logo-mobile {
		display: flex;
		flex: 1;
		height: 3rem;
	}

	.main-menu-nav {
		display: flex;
		align-items: center;
		margin-left: auto;
		position: relative;

		.main-menu-nav-items {
			display: flex;
			align-items: center;
			margin-bottom: 0;
			flex: 1 1 0;
			min-width: 0;
			text-align: center;

			li {
				flex-shrink: 0;
				margin-right: 1rem;

				a {
					font-size: 1.35rem;
					font-weight: 500;
					letter-spacing: 0.25px;
					color: var(--text-color);
					position: relative;
					cursor: pointer;

					&::after {
						content: "";
						bottom: -6px;
						left: 0;
						position: absolute;
						width: 100%;
						height: 2px;
						background-color: var(--primary-color);
						transition: transform 0.3s;
						transform: scaleX(0);
						transform-origin: right;
					}

					&:hover {
						text-decoration: none;
						box-shadow: none;

						&::after {
							opacity: 1;
							transform: scaleX(1);
							transform-origin: left;
						}
					}
				}
			}
		}

		.main-menu-icons {
			flex-shrink: 0;
			z-index: 2;
			background: var(--surface-a);
			padding: 0.25rem 0.75rem;
			border-left: 1px solid var(--surface-d);
			display: flex;
			align-items: center;

			::v-deep(.p-button) {
				&.p-button-icon-only .p-button-icon {
					font-size: 1.35rem;
				}
			}

			i,
			svg {
				font-size: 1.35rem;
				color: var(--secondary-color);
				padding: 0.25rem 0.5rem;
				margin-right: 0.25rem;
				cursor: pointer;
			}

			svg {
				font-size: 1.35rem;
			}

			::v-deep(.p-badge) {
				transform: translate(30%, -45%);
				min-width: 1.2rem;
				height: 1.2rem;
				line-height: 1.2rem;
			}
		}

		.main-menu-search {
			flex: 0 1 0;
			min-width: 0;
			width: 0;
			transition: all 0.3s ease-in-out;
			z-index: 2;
			background: var(--surface-a);

			.header-search {
				width: 100%;
			}

			&.searchActive {
				min-width: 200px;
				flex-basis: 100%;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

.usp-bar {
	background: #373737;

	ul {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		height: 2.5rem;
		font-size: 0.8125rem;

		@media (min-width: 75rem) {
			font-size: 0.9375rem;
		}

		li {
			flex-grow: 1;
			color: #fff;
			text-overflow: ellipsis;
			white-space: nowrap;
			min-width: 0px;
			overflow: hidden;
			display: block;
		}

		a {
			cursor: pointer;
			color: #fff;

			&:hover {
				text-decoration: underline;
			}
		}

		.usp-text-small {
			font-size: 0.6875rem;

			@media (min-width: 75rem) {
				font-size: 0.75rem;
			}

			margin-left: 0.25rem;
		}

		.usp-icon {
			color: #fff;
			margin: 0.2rem 0.25rem;
			float: left;
		}
	}
}

.p-inline-message {
	padding: 0 0.5rem;
	margin-left: 1rem;
	border-radius: 3px;
	background: white;
}

::v-deep(.message-with-button) {
	.p-message-wrapper {
		padding: 0.5rem 1rem;
	}
}

::v-deep(.fa-layers-counter) {
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	min-width: 1.2rem;
	height: 1.2rem;
	padding: 0 0.25rem;
	font-size: 0.7rem;
	font-weight: 500;
	line-height: inherit;
	content: "";
	background-color: var(--primary-color);
	border-radius: 0.75rem;
}

::v-deep(.main-menu-overlay) {
	top: 60px !important;
	right: 0;
	left: auto;
}
</style>
