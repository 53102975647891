<template>
	<div class="container">
		<div class="row my-4 gap-y-2">
			<div class="col-12">
				<BackButton :label="$t('common.back')" />
			</div>
			<article class="col-md-8 col-xl-9 mx-auto">
				<h1 v-if="vm.title" class="display-4">{{ vm.title }}</h1>
				<hr class="mb-0 mt-2" />
				<div class="text-color-secondary text-right mb-2">
					<small>{{ formatLongDate(vm.publishedOn) }}</small>
				</div>
				<p class="lead" v-html="vm.intro"></p>
				<img v-if="vm.image" v-lazy="vm.image?.url" :alt="vm.image?.alt" class="content-image" />
				<p class="rte" v-html="vm?.body" />
				<div v-if="$slots.default" class="article-block">
					<slot />
				</div>
			</article>
			<div v-if="vm.author" class="col-md-4 col-xl-3">
				<div :class="{ 'sticky-sidebar': !isSmallScreen }">
					<AuthorBlock :vm="vm.author" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { ContentCategoryDetailPageViewModel } from "@/types/viewModels/general/contentCategoryDetailPageViewModel";
import { Log } from "@/types/helpers/logHelper";
import AuthorBlock from "@/views/blocks/authorBlock.vue";

@Component({
	components: { AuthorBlock },
})
export default class ContentCategoryDetail extends BaseComponent {
	@Prop({ type: Object as PropType<ContentCategoryDetailPageViewModel>, required: true, default: {} })
	vm!: ContentCategoryDetailPageViewModel;

	created(): void {
		Log.info("ContentCategoryDetail", this.vm);
	}
}
</script>

<style scoped lang="scss">
.sticky-sidebar {
	position: sticky;
	top: calc(var(--navbarHeightInPx) + 2rem);
}

.content-image {
	width: 100%;
	margin: 1.5rem 0;
}

::v-deep(.content-body) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 0;
	}
}

::v-deep(.article-block) {
	margin-top: 3rem;
	.container,
	.block {
		margin: 0;
		padding: 0;
	}
}
</style>
