<template>
	<form v-if="canEdit" class="">
		<div class="form-row">
			<div class="form-group col-12 col-xl-6">
				<label :class="{ required: v$['expertExperienceForm'].type?.required }">
					{{ $t("common.forms.lblType") }}
				</label>
				<Dropdown
					v-model="v$['expertExperienceForm'].type.$model"
					:disabled="!canEdit"
					option-label="label"
					option-value="value"
					:options="typeOptions"
				/>
				<small v-if="shouldShowError(v$['expertExperienceForm'].type)" class="p-error">
					{{ v$["expertExperienceForm"].type?.required?.$message }}
				</small>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label :class="{ required: v$['expertExperienceForm'].title?.required }">
					{{ $t("common.forms.lblTitle") }}
				</label>
				<InputText
					v-model="v$['expertExperienceForm'].title.$model"
					:class="{ 'p-invalid': shouldShowError(v$['expertExperienceForm'].title) }"
					:disabled="!canEdit"
					type="text"
				/>
				<small v-if="shouldShowError(v$['expertExperienceForm'].title)" class="p-error">
					{{ v$["expertExperienceForm"].title?.required?.$message }}
				</small>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label :class="{ required: v$['expertExperienceForm'].company?.required }">
					{{ $t("common.forms.lblCompanyName") }}
				</label>
				<InputText
					v-model="v$['expertExperienceForm'].company.$model"
					:class="{ 'p-invalid': shouldShowError(v$['expertExperienceForm'].company) }"
					:disabled="!canEdit"
					type="text"
				/>
				<small v-if="shouldShowError(v$['expertExperienceForm'].company)" class="p-error">
					{{ v$["expertExperienceForm"].company?.required?.$message }}
				</small>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-6">
				<label :class="{ required: v$['expertExperienceForm'].experienceStartDate?.required }">
					{{ $t("common.forms.lblStartDate") }}
				</label>
				<Calendar
					v-model="v$['expertExperienceForm'].experienceStartDate.$model"
					autocomplete="off"
					class="w-100"
					date-format="mm/yy"
					:disabled="!canEdit"
					:input-class="
						shouldShowError(v$['expertExperienceForm'].experienceStartDate) ? 'p-invalid' : ''
					"
					:manual-input="false"
					show-icon
					view="month"
				/>
				<small v-if="shouldShowError(v$['expertExperienceForm'].experienceStartDate)" class="p-error">
					{{ v$["expertExperienceForm"].experienceStartDate?.required?.$message }}
				</small>
			</div>

			<div class="form-group col-6">
				<label :class="{ required: v$['expertExperienceForm'].experienceEndDate?.required }">
					{{ $t("common.forms.lblEndDate") }}
				</label>
				<Calendar
					v-model="v$['expertExperienceForm'].experienceEndDate.$model"
					autocomplete="off"
					class="w-100"
					date-format="mm/yy"
					:disabled="!canEdit"
					:input-class="shouldShowError(v$['expertExperienceForm'].experienceEndDate) ? 'p-invalid' : ''"
					:manual-input="false"
					show-icon
					view="month"
				/>
				<span v-if="shouldShowError(v$['expertExperienceForm'].experienceEndDate)">
					<small v-if="!v$['expertExperienceForm'].experienceEndDate.$model" class="p-error">
						{{ v$["expertExperienceForm"].experienceEndDate?.required?.$message }}
					</small>
					<small v-else-if="v$['expertExperienceForm'].experienceEndDate.$model" class="p-error">
						{{ v$["expertExperienceForm"].experienceEndDate.endDateAfterStartDate?.$message }}
					</small>
				</span>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-12">
				<label :class="{ required: v$['expertExperienceForm'].description?.required }">
					{{ $t("common.forms.lblDescription") }}
				</label>
				<Textarea
					v-model="v$['expertExperienceForm'].description.$model"
					:auto-resize="true"
					:class="{ 'p-invalid': shouldShowError(v$['expertExperienceForm'].description) }"
					cols="30"
					:disabled="!canEdit"
					rows="5"
				/>
				<span v-if="shouldShowError(v$['expertExperienceForm'].description)">
					<small v-if="!v$['expertExperienceForm'].description.$model" class="p-error">
						{{ v$["expertExperienceForm"].description?.required?.$message }}
					</small>
					<small v-else-if="v$['expertExperienceForm'].description.$model" class="p-error">
						{{ v$["expertExperienceForm"].description.minLength?.$message }}
					</small>
				</span>
			</div>
		</div>
	</form>
	<div v-if="canEdit" class="d-flex" :class="{ 'justify-content-end': isDialog }">
		<Button v-if="isDialog" class="p-button-text" :label="$t(`common.cancel`)" @click="onCancel" />
		<Button class="p-button-primary" :disabled="!canSubmit" :label="$t(`common.save`)" @click="onSubmit" />
	</div>
	<div v-else>
		<dl>
			<dt>{{ $t("common.forms.lblType") }}</dt>
			<dd>{{ $t(`enum.expertExperienceType.${vm.type}`) }}</dd>
			<dt>{{ $t("common.forms.lblTitle") }}</dt>
			<dd>{{ vm.title }}</dd>
			<dt>{{ $t("common.forms.lblCompanyName") }}</dt>
			<dd>{{ vm.company }}</dd>
			<dt>{{ $t("common.forms.lblDate") }}</dt>
			<dd>
				{{ formatExperienceDate(vm.experienceStartDate) }} -
				{{ formatExperienceDate(vm.experienceEndDate) }}
			</dd>
			<dt>{{ $t("common.forms.lblDescription") }}</dt>
			<dd class="text-preline" style="word-break: break-word">{{ vm.description }}</dd>
		</dl>
	</div>
</template>

<script lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import BaseComponent from "@/components/base/baseComponent.vue";
import Button from "primevue/button";
import Calendar, { CalendarProps } from "primevue/calendar";
import Chip from "primevue/chip";
import LanguageDropdown from "@/components/common/menu/languageDropdown.vue";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import PopoverIcon from "@/components/common/popoverIcon.vue";
import useVuelidate, { Validation, ValidatorFn } from "@vuelidate/core";
import { Component, Emit, Inject, Prop } from "vue-facing-decorator";
import { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { ExpertExperienceBlockViewModel } from "@/types/models/expert/expertExperienceBlockViewModel";
import { ExpertExperienceType } from "@/types/enum/expertExperienceType";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { PropType } from "vue";
import { isAfter, isDate } from "date-fns";
import { maxLength, minLength, required, withI18nMessage } from "@/types/utils/i18n-validators";

const checkEndDateIsAfterStartDate = (_value: string, siblings: any, vm: any): boolean => {
	if (!vm && siblings) vm = siblings;
	return (
		!vm["expertExperienceForm"].experienceEndDate ||
		isAfter(vm["expertExperienceForm"].experienceEndDate, vm["expertExperienceForm"].experienceStartDate)
	);
};

@Component({
	components: {
		Accordion,
		AccordionTab,
		Avatar,
		Button,
		Calendar,
		Chip,
		LanguageDropdown,
		MultiSelect,
		Panel,
		PopoverIcon,
	},
	options: {
		validations: {
			expertExperienceForm: {
				type: { required },
				title: { required, maxLength: maxLength(255) },
				company: { required, maxLength: maxLength(255) },
				experienceStartDate: { required },
				experienceEndDate: {
					endDateAfterStartDate: withI18nMessage(checkEndDateIsAfterStartDate as ValidatorFn),
				},
				description: { required, minLength: minLength(120), maxLength: maxLength(1500) },
			},
		},
	},
})
export default class ExpertExperienceFrom extends BaseComponent {
	@Prop({ type: Object as PropType<ExpertExperienceBlockViewModel>, required: false, default: {} })
	vm!: ExpertExperienceBlockViewModel;

	@Prop({ type: Boolean, required: false, default: false })
	isEditing!: boolean;

	@Emit() submit(): ExpertExperienceBlockViewModel {
		return this.expertExperienceForm;
	}

	@Inject({
		default: undefined,
	})
	dialogRef!: DynamicDialogInstance;

	expertExperienceForm!: ExpertExperienceBlockViewModel;
	submitted = false;
	isDialog = false;
	typeOptions: IDropdownItem[] = [];

	v$ = useVuelidate();

	created(): void {
		if (this.vm) {
			this.expertExperienceForm = {
				id: this.vm?.id,
				type: this.vm?.type,
				title: this.vm?.title,
				company: this.vm?.company,
				experienceStartDate: this.vm?.experienceStartDate,
				experienceEndDate: this.vm?.experienceEndDate,
				description: this.vm?.description,
			};
		}
		this.typeOptions = [
			{
				label: this.$t("enum.expertExperienceType.0"),
				value: ExpertExperienceType.Experience,
			},
			{
				label: this.$t("enum.expertExperienceType.1"),
				value: ExpertExperienceType.Education,
			},
		];
	}

	mounted(): void {
		if (this.dialogRef?.data?.vm) {
			this.isDialog = true;
			const vm = this.dialogRef.data?.vm;
			this.expertExperienceForm = {
				id: 0,
				type: vm?.type,
				title: vm?.title,
				company: vm?.company,
				experienceStartDate: vm?.experienceStartDate,
				experienceEndDate: vm?.experienceEndDate,
				description: vm?.description,
			};
		}
	}

	get endDateOptions(): Partial<CalendarProps> {
		return {
			minDate: this.expertExperienceForm?.experienceStartDate,
			touchUI: this.isSmallScreen as any,
		};
	}

	get canEdit(): boolean {
		return this.isEditing || this.isDialog;
	}

	get canSubmit(): boolean {
		return this.v$["expertExperienceForm"].$anyDirty && !this.v$["expertExperienceForm"].$invalid;
	}

	formatExperienceDate(date: string | Date | undefined): string {
		const dateToFormat = isDate(date) ? (date as Date).toISOString() : (date as string);
		return FormatHelper.formatDateAsMonthYearString(dateToFormat, this.currentRegion);
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		if (this.dialogRef) {
			this.dialogRef.close(this.expertExperienceForm);
		} else {
			this.submit();
		}
	}

	onCancel(): void {
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}
}
</script>

<style scoped lang="scss"></style>
