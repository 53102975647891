export enum LogLevel {
	Default,
	Info,
	Warn,
	Error,
}

class LogHelper {
	private static colors = {
		[LogLevel.Default]: '#444',
		[LogLevel.Info]: '#007bff',
		[LogLevel.Warn]: '#fabc3c',
		[LogLevel.Error]: '#dc3545',
	};

	private static get logDebug(): boolean {
		return `${process.env.VUE_APP_MODE}` !== 'production';
	}

	static log(...args: unknown[]): void {
		if (!this.logDebug) return;
		const color = LogHelper.colors[LogLevel.Default];
		const prefix = `%c[${LogLevel[LogLevel.Default].toUpperCase()}]`;
		const style = `color: ${color};`;

		console.log(prefix, style, ...args);
	}

	static info(...args: unknown[]): void {
		if (!this.logDebug) return;
		const color = LogHelper.colors[LogLevel.Info];
		const prefix = `%c[${LogLevel[LogLevel.Info].toUpperCase()}]`;
		const style = `color: ${color}; font-weight: bold;`;

		console.info(prefix, style, ...args);
	}

	static warn(...args: unknown[]): void {
		if (!this.logDebug) return;
		const color = LogHelper.colors[LogLevel.Warn];
		const style = `color: ${color}; font-weight: bold;`;
		const prefix = `%c[${LogLevel[LogLevel.Warn].toUpperCase()}]`;

		console.warn(prefix, style, ...args);
	}

	static error(error: Error): void {
		if (!this.logDebug) return;
		const callerName = this.getCallerName(error);
		const color = LogHelper.colors[LogLevel.Error];
		const style = `color: ${color}; font-weight: bold;`;
		let prefix = `%c[${LogLevel[LogLevel.Error].toUpperCase()}]`;
		if (callerName) prefix += ` ${callerName} ->`;
		console.error(prefix, style, error);
	}

	private static getCallerName(error: Error): string {
		const stackTrace = error.stack || '';
		const match = stackTrace.match(/at Proxy\.(.*)\s+\(/);
		return match ? match[1] : '';
	}
}

export const Log = LogHelper;
