<template>
	<InputNumber
		v-if="editable"
		v-model="item.amount"
		:class="isSmallScreen ? 'w-50 ml-auto' : 'w-75'"
		decrement-button-class="p-button-outlined"
		increment-button-class="p-button-outlined"
		:max="maxAmount"
		:min="item.minAmount"
		show-buttons
		style="max-width: 200px; min-width: 95px"
		:use-grouping="false"
	/>
	<span v-else class="px-lg-2">{{ item.amount }}</span>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { PropType } from "vue";
import { watchDebounced } from "@vueuse/core";

export interface AmountChangedEvent {
	originalValue: number;
	value: number;
}

@Component
export default class ColumnAmount extends BaseComponent {
	@Prop({ type: Object as PropType<{ amount: number; minAmount: number }>, required: true, default: {} })
	item!: {
		amount: number;
		minAmount: number;
	};
	@Prop({ type: Boolean, default: false }) disabled!: boolean;
	@Prop({ type: Boolean, default: false }) editable!: boolean;

	originalValue = 0;
	maxAmount = 999;

	created(): void {
		this.originalValue = this.item?.amount;
		watchDebounced(
			this.item,
			() => {
				this.onInputChange();
			},
			{
				debounce: 750,
			}
		);
	}

	@Emit amountChanged(amount: number): AmountChangedEvent {
		const changeEvent = {
			value: amount,
			originalValue: this.originalValue,
		};
		this.originalValue = amount;
		return changeEvent;
	}

	onInputChange() {
		if (this.item.amount > this.maxAmount) return;
		if (this.item.amount !== this.originalValue) this.amountChanged(this.item.amount);
	}
}
</script>

<style lang="scss" scoped>
::v-deep(.p-button.p-button-outlined) {
	background-color: var(--surface-50);

	&:hover {
		background-color: var(--surface-e);
	}
}
</style>
