/* Auto Generated */

export enum WalletTransactionOrigin {
	storeOrder = 229380000,
	purchased = 229380001,
	cancelledOrder = 229380002,
	expired = 229380003,
    cRMEdit = 229380004,
    projectOrder = 229380005,
    autodeskQuote = 229380006
}