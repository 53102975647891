import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-359e2d90"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-row align-items-center"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex flex-row align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_country_flag = _resolveComponent("country-flag")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.selectedLanguage,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
      "append-to": "self",
      class: _normalizeClass(["language-menu", { 'w-100': _ctx.useFullWidth, 'pr-2': _ctx.useFullWidth }]),
      "option-label": "name",
      options: _ctx.languages,
      placeholder: " ",
      scrollheight: "500px",
      onChange: _ctx.onLangSelected
    }, {
      value: _withCtx((slotProps) => [
        (slotProps.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_country_flag, {
                class: "flag-icon rounded-lg",
                country: _ctx.mapLanguageToCountryCode(slotProps.value.code),
                size: "normal"
              }, null, 8, ["country"]),
              (_ctx.useFullWidth)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.languages.find((x) => x.code === slotProps.value)?.name), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_country_flag, {
            class: "rounded-lg mr-1 my-0",
            country: _ctx.mapLanguageToCountryCode(slotProps.option.code),
            size: "normal"
          }, null, 8, ["country"]),
          _createElementVNode("div", null, _toDisplayString(slotProps.option.name), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "class", "options", "onChange"])
  ], 512)), [
    [_vShow, _ctx.languages?.length]
  ])
}