<template>
	<div class="container-fluid container-lg">
		<div class="row">
			<div class="col px-0" :class="{ 'col-lg-8': $slots.summary }">
				<ProductPageHeader :vm="vm" :showTeachingsMaterialButton="showTeachingsMaterialButton" />
				<Message v-if="!vm.availableInCurrentMarket" :closable="false" severity="error">
					{{ $t("common.productUnavailableInMarket") }}
				</Message>
				<ProductNewVersionBanner v-if="vm.latestVersionOfProduct" :vm="vm.latestVersionOfProduct" />
				<ProductPageContentSection v-if="$slots.configuration">
					<slot name="configuration" />
				</ProductPageContentSection>
				<template v-else-if="$slots.tabs">
					<slot name="tabs" />
				</template>
				<!-- Default content goes here-->
				<slot />
			</div>
			<div v-if="$slots.summary" class="col-lg-4" :class="{ 'sticky-bottom-panel': isSmallScreen }">
				<slot name="summary" />
			</div>
		</div>
	</div>
	<Sidebar v-model:visible="showSideBar" class="p-sidebar-md p-sidebar-primary" position="left">
		<div v-for="(item, index) in helpBlock?.items" :key="index">
			<h3 v-if="item.title" v-html="item.title"></h3>
			<p v-html="item.text"></p>
		</div>
		<SupportCard />
	</Sidebar>
	<!--	JSON For Linked Data (Displays additional info to google search results) -->
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ jsonLinkedData }}
		</component>
	</teleport>
</template>

<script lang="ts">
	import BaseComponent from "@/components/base/baseComponent.vue";
	import ProductOverviewItemCard from "@/components/product/productOverviewItemCard.vue";
	import ProductPageContentSection from "@/components/product/productPageContentSection.vue";
	import ProductPageHeader from "@/components/product/productPageHeader.vue";
	import Sidebar from "primevue/sidebar";
	import SupportCard from "@/components/commerce/supportCard.vue";
	import { Component, Model, Prop } from "vue-facing-decorator";
	import { HelpBlockViewModel } from "@/types/generated/Common/helpBlockViewModel";
	import { IProductViewModel } from "@/types/models/common/productViewModel.interface";
	import { ProductServiceType } from "@/types/enum/productServiceType";
	import { PropType } from "vue";
	import ProductNewVersionBanner from "@/components/product/productNewVersionBanner.vue";

	@Component({
		emits: ["update:modelValue"],
		components: {
			ProductNewVersionBanner,
			ProductPageHeader,
			ProductPageContentSection,
			ProductOverviewItemCard,
			SupportCard,
			Sidebar,
		},
	})
	export default class ProductLayout extends BaseComponent {
		@Prop({ type: Object as PropType<IProductViewModel>, required: true, default: {} })
		vm!: IProductViewModel;

		@Prop({ type: Object as PropType<HelpBlockViewModel>, required: false, default: {} })
		helpBlock!: HelpBlockViewModel;

		@Prop({ type: Boolean, required: false, default: false }) showTeachingsMaterialButton!: boolean;

		@Model({ type: Boolean, required: false, default: false }) showSideBar!: boolean;

		get productCategory(): string {
			return this.vm.serviceType === ProductServiceType.ownSoftware ||
				this.vm.serviceType === ProductServiceType.thirdPartySoftware
				? "Software"
				: ProductServiceType[this.vm.serviceType];
		}

		get jsonLinkedData() {
			return {
				"@context": "https://schema.org",
				"@type": "Product",
				url: window.location.href,
				name: this.formatProductName(this.vm.name, this.vm.brand),
				image: this.vm.iconUrl,
				description: this.vm.intro,
				brand: this.vm.brand,
				offers: {
					"@type": "Offer",
					url: window.location.href,
					pricecurrency: this.currentCurrency.toLowerCase(),
					price: this.vm.lowestPersonalPrice,
					availability: "https://schema.org/InStock",
				},
			};
		}
	}
</script>

<style scoped lang="scss">
	:deep(.product-tabs-header) {
		top: var(--navbarHeightInPx);
	}

	.shadow-left {
		box-shadow: -5px 0px 5px 1px #dddd;
	}

    .sticky-bottom-panel {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 101;
        background: var(--surface-a);
        box-shadow: 0px -4px 8px -7px rgba(0, 0, 0, 0.75);
    }
</style>
