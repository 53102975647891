import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25e280d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "h-100" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "d-flex mb-2" }
const _hoisted_5 = { class: "align-self-center mr-1" }
const _hoisted_6 = ["alt", "title"]
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = { class: "mb-0 text-muted lead" }
const _hoisted_9 = { class: "mb-0 productname" }
const _hoisted_10 = { class: "mt-2 mt-lg-0 d-flex" }
const _hoisted_11 = {
  key: 0,
  class: "lead text-muted d-flex flex-wrap text-right justify-content-end"
}
const _hoisted_12 = { class: "text-primary ml-1" }
const _hoisted_13 = {
  key: 1,
  class: "lead text-muted d-flex flex-wrap text-right justify-content-end"
}
const _hoisted_14 = { class: "lead price-text text-muted ml-1" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "d-flex flex-wrap flex-column justify-content-between" }
const _hoisted_17 = { class: "d-flex justify-content-between flex-1" }
const _hoisted_18 = { class: "mr-1" }
const _hoisted_19 = ["alt", "title"]
const _hoisted_20 = { class: "d-flex flex-column flex-1" }
const _hoisted_21 = { class: "mb-0 text-muted lead" }
const _hoisted_22 = { class: "mb-0 productname" }
const _hoisted_23 = {
  key: 0,
  class: "lead mb-0"
}
const _hoisted_24 = { class: "text-primary ml-1" }
const _hoisted_25 = {
  key: 1,
  class: "lead mb-0"
}
const _hoisted_26 = { class: "text-primary ml-1" }
const _hoisted_27 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Tag = _resolveComponent("Tag")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("a", {
    class: "service-card-item",
    href: _ctx.item.productPageUrl,
    style: {"text-decoration":"none"}
  }, [
    (!_ctx.isGridLayout)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          class: "service-list-card",
          style: _normalizeStyle({ backgroundColor: _ctx.item.backgroundColor }),
          title: _ctx.formatProductName(_ctx.item.name, _ctx.item.brand),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUrl(_ctx.item.productPageUrl)))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.item.iconUrl)
                      ? _withDirectives((_openBlock(), _createElementBlock("img", {
                          key: 0,
                          alt: _ctx.item.iconAlt,
                          class: "overview-product-icon",
                          title: _ctx.item.name
                        }, null, 8, _hoisted_6)), [
                          [_directive_lazy, _ctx.item.iconUrl]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.item.brand), 1),
                    _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.formatProductName(_ctx.item.name, _ctx.item.brand)), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.item.basePrice)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                        _createElementVNode("span", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t("common.from")) + " ", 1),
                          _createElementVNode("b", _hoisted_12, _toDisplayString(_ctx.formatCurrency(_ctx.item.basePrice)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.item.basePrice)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                        _createElementVNode("span", null, [
                          _createElementVNode("b", _hoisted_14, _toDisplayString(_ctx.$t("product.availableOnRequest")), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(_component_Divider),
              _createElementVNode("p", {
                class: "mb-0 intro-text text-preline",
                innerHTML: _ctx.item.intro
              }, null, 8, _hoisted_15)
            ])
          ]),
          _: 1
        }, 8, ["style", "title"]))
      : (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          class: _normalizeClass(["service-grid-card", { compact: _ctx.compact, 'cross-sell': _ctx.isCrossSell }]),
          title: _ctx.formatProductName(_ctx.item.name, _ctx.item.brand),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openUrl(_ctx.item.productPageUrl)))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  (_ctx.item.iconUrl)
                    ? _withDirectives((_openBlock(), _createElementBlock("img", {
                        key: 0,
                        alt: _ctx.item.iconAlt,
                        class: "overview-product-icon",
                        title: _ctx.item.name
                      }, null, 8, _hoisted_19)), [
                        [_directive_lazy, _ctx.item.iconUrl]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.item.brand), 1),
                  _createElementVNode("h4", _hoisted_22, _toDisplayString(_ctx.formatProductName(_ctx.item.name, _ctx.item.brand)), 1)
                ]),
                (_ctx.isCrossSell)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      class: "mb-auto",
                      value: _ctx.$t('enum.itemProductType.' + _ctx.item.productType)
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.item.basePrice)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["d-flex text-muted", _ctx.isCrossSell ? 'flex-column-reverse ml-auto text-right' : 'flex-column'])
                  }, [
                    (_ctx.item.basePrice)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                          _createTextVNode(_toDisplayString(_ctx.$t("common.from")) + " ", 1),
                          _createElementVNode("b", _hoisted_24, _toDisplayString(_ctx.formatCurrency(_ctx.item.basePrice)), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.item.basePrice)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_25, [
                          _createElementVNode("b", _hoisted_26, _toDisplayString(_ctx.$t("product.availableOnRequest")), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isCrossSell)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_Divider),
                  _createElementVNode("p", {
                    class: "intro-text text-preline",
                    innerHTML: _ctx.item.intro
                  }, null, 8, _hoisted_27)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "title"]))
  ], 8, _hoisted_1))
}