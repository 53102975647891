import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "row training-summary" }
const _hoisted_2 = { class: "col-md-6 col-xl-4" }
const _hoisted_3 = { class: "text-preline" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Inplace = _resolveComponent("Inplace")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("dl", null, [
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.trainingSummary.lblType")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.$t(`enum.trainingType.${_ctx.vm.trainingType}`)), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.isInstructor
								? _ctx.$t("myCadac.trainingSummary.lblParticipants")
								: _ctx.$t("myCadac.trainingSummary.lblTeacher")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.isInstructor ? _ctx.vm.participants?.length : _ctx.vm.instructorName), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.trainingSummary.lblLocation")), 1),
          _createElementVNode("dd", _hoisted_3, _toDisplayString(_ctx.formattedAddress), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("dl", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.trainingSummary.lblDate")), 1),
          _createElementVNode("dd", null, _toDisplayString(_ctx.vm.formattedDates), 1)
        ]),
        (_ctx.vm.moduleNames?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("dt", null, _toDisplayString(_ctx.$t("myCadac.trainingSummary.lblModules")), 1),
              _createElementVNode("dd", null, [
                _createVNode(_component_Inplace, null, {
                  display: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.visibleModules(5)) + " ", 1),
                    (_ctx.vm.moduleNames?.length > 5)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "..."))
                      : _createCommentVNode("", true)
                  ]),
                  content: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.visibleModules()), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}