// or import { createI18nMessage } from '@vuelidate/validators'
import * as validators from '@vuelidate/validators';
import i18n from '@/types/utils/i18n';
import { ValidationHelper } from '@/types/helpers/validationHelper';
import { ValidatorFn } from '@vuelidate/core';

const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = i18n.global || i18n;
// pass `t` and create your i18n message instance
export const withI18nMessage = createI18nMessage({ t });

// wrap each validator.
export const required = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email);
export const minLength = (minLength: number) => withI18nMessage(validators.minLength(minLength));
export const maxLength = (maxLength: number) => withI18nMessage(validators.maxLength(maxLength));
export const minValue = (minValue: number) => withI18nMessage(validators.minValue(minValue));
export const validPassword = withI18nMessage(ValidationHelper.validatePassword as ValidatorFn);
export const validCoCNumber = withI18nMessage(ValidationHelper.validateCocNumber as ValidatorFn);
export const validPhone = withI18nMessage(ValidationHelper.validatePhoneObject as ValidatorFn);
export const gender = withI18nMessage(ValidationHelper.validateGender as ValidatorFn);
