import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mb-0 text-muted d-block" }
const _hoisted_7 = { class: "mb-0 text-bold" }
const _hoisted_8 = { class: "col-md-10 col-lg-8 col-xl-6 ml-auto pr-2 pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVisible) = $event)),
    breakpoints: { '1600px': '50vw', '1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '90vw' },
    modal: true,
    style: { width: '40vw' },
    onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancelTradeIn()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("myCadac.contractDetail.switchModal.title")), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.priceSummary)
          ? (_openBlock(), _createBlock(_component_PriceSummary, {
              key: 0,
              vm: _ctx.priceSummary
            }, null, 8, ["vm"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Button, {
        class: "p-button-text p-button-plain",
        label: _ctx.$t('common.cancel'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelTradeIn()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        disabled: !_ctx.canSubmit,
        label: _ctx.$t('common.confirm'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmTradeIn()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      (_ctx.vm.switchTradeInText)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "mb-2",
            innerHTML: _ctx.vm.switchTradeInText
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.switchModal.changeDuration")), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.switchDurationOptions, (option) => {
          return (_openBlock(), _createBlock(_component_Button, {
            key: option,
            class: _normalizeClass(["p-button-secondary mr-2", { 'p-button-outlined': _ctx.selectedDuration !== option }]),
            label: _ctx.$t('myCadac.contractDetail.switchModal.btnDuration', { duration: option }),
            onClick: ($event: any) => (_ctx.onDurationChange(option))
          }, null, 8, ["class", "label", "onClick"]))
        }), 128))
      ]),
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("myCadac.contractDetail.switchModal.chooseContract")), 1),
      (_ctx.tradeInOptions.length)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 1,
            selection: _ctx.selectedProduct,
            "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProduct) = $event)),
            "auto-layout": "",
            "data-key": "productName",
            "selection-mode": "single",
            value: _ctx.tradeInOptions
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "productName",
                header: _ctx.$t('myCadac.contractDetail.table.colProductName'),
                sortable: false
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("div", _hoisted_4, [
                    (item.data.icon)
                      ? (_openBlock(), _createBlock(_component_Avatar, {
                          key: 0,
                          alt: item.data.iconAlt,
                          class: "mr-1 bg-transparent",
                          image: item.data.icon,
                          shape: "square",
                          size: "large",
                          title: item.productName
                        }, null, 8, ["alt", "image", "title"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("small", _hoisted_6, _toDisplayString(item.data.productBrand), 1),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.formatProductName(item.data.productName, item.data.productBrand)), 1)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "deployment",
                header: _ctx.$t('myCadac.contractDetail.table.colProductLicenceType'),
                style: {"width":"15%"}
              }, {
                body: _withCtx((item) => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`enum.deployment.${_ctx.getProductVariation(item.data).licenseAudience}`)), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "renewSeats",
                header: _ctx.$t('myCadac.contractDetail.table.colProductSeats'),
                style: {"width":"15%"}
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                "body-class": "text-lg-right pr-2",
                header: _ctx.$t('myCadac.contractDetail.table.colProductPrice'),
                "header-class": "text-lg-right pr-2",
                style: {"width":"15%"}
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(_ctx.getPricePerSeat(_ctx.getProductVariation(item.data).price, item.data.renewSeats))), 1)
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["selection", "value"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}