<template>
	<div class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<h2 v-if="vm.title" class="display-4">{{ vm.title }}</h2>
			<p v-if="vm.intro" class="lead">{{ vm.intro }}</p>
			<div class="row justify-content-center align-items-center">
				<div v-for="logo in vm.logos" :key="logo" class="col-6 col-sm-auto">
					<img v-lazy="logo.url" :alt="logo.alt" width="185" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-facing-decorator";
import { LogosBlockViewModel } from "@/types/viewModels/blocks/logosBlockViewModel";
import { PropType } from "vue";
import BaseBlock from "@/components/base/baseBlock.vue";

@Component
export default class LogosBlock extends BaseBlock {
	@Prop({ type: Object as PropType<LogosBlockViewModel>, required: true, default: {} })
	vm!: LogosBlockViewModel;
}
</script>

<style lang="scss" scoped></style>
