<template>
	<h5 v-if="title" class="mb-1">{{ title }}</h5>
	<dl class="contract-summary" v-bind="$attrs">
		<div>
			<dt>{{ $t("common.contractSummary.lblContractNumber") }}</dt>
			<dd>{{ vm.contractNumber }}</dd>
		</div>
		<div>
			<dt>{{ $t("common.contractSummary.lblContractType") }}</dt>
			<dd>{{ vm.contractType }}</dd>
		</div>
		<div v-if="vm.contractManager">
			<dt>{{ $t("common.contractSummary.lblContractManager") }}</dt>
			<dd>{{ vm.contractManager }}</dd>
		</div>
		<div>
			<dt>{{ $t("common.contractSummary.lblEndDate") }}</dt>
			<dd>
				<s v-if="vm.contractProlongationDate" class="text-muted">
					{{ formatDate(vm.contractEndDate) }}
				</s>
				<span v-else>{{ formatDate(vm.contractEndDate) }}</span>
			</dd>
		</div>
		<div v-if="vm.contractProlongationDate">
			<dt>{{ $t("common.contractSummary.lblProlongationDate") }}</dt>
			<dd>{{ formatDate(vm.contractProlongationDate) }}</dd>
		</div>
	</dl>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";

export interface IContractSummary {
	contractNumber: string;
	contractType: string;
	contractEndDate: Date | string;
	contractProlongationDate?: Date | string;
	contractManager?: string;
}

@Component
export default class ContractSummary extends BaseComponent {
	@Prop({ type: Object as PropType<IContractSummary>, required: true, default: {} }) vm!: IContractSummary;
	@Prop({ type: String, required: false, default: "" }) title!: string;
}
</script>

<style lang="scss" scoped>
dl.contract-summary {
	display: flex;
	flex: 1 1 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 1rem;
	margin-bottom: 0;
	background: var(--surface-50);

	@media (max-width: 960px) {
		padding: 0.5rem;
		> div {
			padding: 0;
		}
	}

	dt {
		color: var(--text-color);
	}

	dd {
		color: var(--text-color-secondary);
	}

	> dt {
		text-decoration: underline;
	}
}
</style>
