import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9bea785c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero-search-bar" }
const _hoisted_2 = { class: "pt-2 pl-2" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  alt: "Icon",
  height: "28",
  width: "28"
}
const _hoisted_7 = { class: "ml-2" }
const _hoisted_8 = {
  key: 0,
  class: "px-2 pb-2"
}
const _hoisted_9 = {
  key: 1,
  class: "my-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.vm.searchCategoryOptions?.length)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 0,
          modelValue: _ctx.searchCategory,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCategory) = $event)),
          "append-to": "self",
          class: "search-category-dropdown mr-1",
          "option-label": "label",
          "option-value": "category",
          options: _ctx.vm.searchCategoryOptions,
          "scroll-height": "300px"
        }, null, 8, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AutoComplete, {
      "append-to": "self",
      class: "search-input",
      "model-value": _ctx.searchString,
      "option-label": "name",
      "panel-class": "search-panel",
      placeholder: _ctx.vm.searchPlaceholder || _ctx.$t('menu.search_placeholder'),
      "scroll-height": "600px",
      suggestions: _ctx.searchResults,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trackSearch(_ctx.searchString))),
      onComplete: _ctx.onSearch,
      onItemSelect: _ctx.onSearchResultSelected,
      "onUpdate:modelValue": _ctx.updateSearchstring
    }, {
      header: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createVNode(_component_i18n_t, { keypath: "menu.searchResultHeader" }, {
              query: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(slotProps.value.name ?? slotProps.value), 1)
              ]),
              count: _withCtx(() => [
                (!_ctx.hasNoResults)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.searchResult?.totalResults), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ])
        ])
      ]),
      option: _withCtx((slotProps) => [
        (!_ctx.hasNoResults)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "d-flex justify-content-start flex-row px-2 py-1",
              title: slotProps.option.intro || slotProps.option.name
            }, [
              (slotProps.option.hasIconUrl)
                ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_6, null, 512)), [
                    [_directive_lazy, slotProps.option.iconUrl]
                  ])
                : (_openBlock(), _createBlock(_component_Badge, {
                    key: 1,
                    value: slotProps.option.type.charAt(0)
                  }, null, 8, ["value"])),
              _createElementVNode("div", _hoisted_7, _toDisplayString(slotProps.option.name), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_Chip, { class: "p-chip-secondary ml-2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(slotProps.option.type), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ], 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        (_ctx.hasNoResults)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("menu.noSearchResults")), 1))
          : _createCommentVNode("", true),
        (_ctx.showDisplayAllResultsButton)
          ? (_openBlock(), _createBlock(_component_LinkButton, {
              key: 1,
              class: "p-button-text p-button-secondary ml-2 mb-1",
              label: _ctx.$t('menu.button.showAllSearchResults'),
              url: _ctx.searchPageUrl
            }, null, 8, ["label", "url"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model-value", "placeholder", "suggestions", "onComplete", "onItemSelect", "onUpdate:modelValue"]),
    (!_ctx.vm.hideAdvancedSearchButton && !_ctx.isSmallScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_Button, {
            class: "search-button ml-2 p-button-raised",
            label: _ctx.$t('common.advancedSearch'),
            title: _ctx.$t('common.advancedSearch'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (this.openUrl(_ctx.searchPageUrl)))
          }, null, 8, ["label", "title"])
        ]))
      : _createCommentVNode("", true)
  ]))
}