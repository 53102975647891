<template>
	<div class="product-icons-wrapper ml-lg-1 align-items-lg-start align-content-lg-start">
		<div v-if="duration" class="product-info-line flex-lg-row">
			<i class="pi pi-clock text-primary" :title="$t('cart.table.iconDuration_title')" />
			<span class="text-bold ml-1">{{ duration }}</span>
		</div>
		<div v-if="dates" class="product-info-line flex-lg-row">
			<i class="pi pi-calendar text-primary" :title="$t('cart.table.iconDates_title')" />
			<span class="text-bold ml-1">{{ item.date }}</span>
		</div>
		<div v-if="item.address" class="product-info-line flex-lg-row align-items-start">
			<i
				class="pi pi-map-marker text-primary"
				style="margin-top: 0.3rem"
				:title="$t('cart.table.iconLocation_title')"
			/>
			<span class="text-bold ml-1 text-preline">{{ formattedAddress }}</span>
		</div>
		<div v-if="item.location" class="product-info-line flex-lg-row">
			<i class="pi pi-map-marker text-primary" :title="$t('cart.table.iconLocation_title')" />
			<span class="text-bold ml-1 text-preline">{{ item.location }}</span>
		</div>
		<div v-if="item.numberOfParticipants" class="product-info-line flex-lg-row">
			<i class="pi pi-user text-primary" :title="$t('cart.table.iconParticipants_title')" />
			<span class="text-bold ml-1">
				{{ $tc("cart.table.lblParticipantCount", { count: item.numberOfParticipants }) }}
			</span>
		</div>
	</div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Prop } from "vue-facing-decorator";
import BaseComponent from "@/components/base/baseComponent.vue";
import { TrainingCartLineClient } from "@/types/generated/Api/trainingCartLineClient";

@Component
export default class ColumnTrainingInfo extends BaseComponent {
	@Prop({ type: Object as PropType<TrainingCartLineClient>, required: true, default: {} })
	item!: TrainingCartLineClient;

	get duration(): string {
		return this.$tc("cart.table.lblDurationDays", this.item.duration);
	}

	get dates(): string {
		return this.item.date;
	}

	get formattedAddress(): string {
		return `${this.item.address.street}
			${this.item.address.postalCode} ${this.item.address.city}
			${this.item.address.country}`;
	}
}
</script>

<style lang="scss" scoped>
.product-icon {
	width: 47px;
	height: 47px;
	background: transparent;
	flex-shrink: 0;
}

.product-icons-wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	flex-basis: 100%;
	padding: 0 0.5rem;

	.product-info-line {
		display: flex;
		flex: 0;
		align-items: center;
		flex-direction: row;
		margin-bottom: 0.25rem;
	}
}

@media print {
	.product-icons-wrapper {
		align-content: flex-start;
	}
}
</style>
