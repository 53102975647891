<template>
	<h5 v-if="isModularTraining" class="mb-1">
		{{ trainingTitle }}
	</h5>
	<div v-if="vm?.courses?.length" class="training-price-summary">
		<Panel
			v-for="(course, i) in vm.courses"
			v-model:collapsed="panelCollapse[i]"
			:key="i"
			class="mb-1"
			:header="courseById(course.id)?.name"
		>
			<!-- TODO; add Group name above -->
			<p v-for="line in sortedLines(course)" :key="line" class="text-muted">
				<span class="price-label"> {{ lineText(line) }} </span>
				<span>{{ formatCurrency(line.totalPrice) }}</span>
			</p>
			<p class="line-discount text-bold">
				<b>{{ $t("common.priceSummary.subtotal") }}</b>
				<b>{{ formatCurrency(course.totalPrice) }}</b>
			</p>
			<Message
				v-if="
					!vm.isValid &&
					vm.errorCode === TrainingCalculationErrorCodeEnum.durationError &&
					minimumRequiredDurationToBeValid(course)
				"
				:closable="false"
				severity="warn"
			>
				<i18n-t keypath="training.message.minimumRequiredDurationToBeValid">
					<b>{{ $t("common.hour", [minimumRequiredDurationToBeValid(course)]) }}</b>
				</i18n-t>
			</Message>
			<template #icons>
				<Button
					class="p-button-text p-panel-header-icon"
					:icon="panelCollapse[i] ? 'pi pi-chevron-left' : 'pi pi-chevron-down'"
					@click="panelCollapse[i] = !panelCollapse[i]"
				/>
			</template>
		</Panel>
	</div>
	<hr class="my-1" />
	<PriceSummary :vm="totalPriceSummary" />
	<p class="text-muted lead text-right">{{ $t("common.productSummary.allPricesExclVat") }}</p>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import PriceSummary from "@/components/common/priceSummary.vue";
import ScrollPanel from "primevue/scrollpanel";
import { Component, Prop } from "vue-facing-decorator";
import { IPriceSummary } from "@/types/models/common/priceSummary.interface";
import { LocalTrainingType } from "@/types/enum/localTrainingType";
import { PropType } from "vue";
import { TrainingCalculationCourseResponse } from "@/types/generated/Api/trainingCalculationCourseResponse";
import { TrainingCalculationErrorCode } from "@/types/enum/trainingCalculationErrorCode";
import { TrainingCalculationLineResponse } from "@/types/generated/Api/trainingCalculationLineResponse";
import { TrainingCalculationLineType } from "@/types/enum/trainingCalculationLineType";
import { TrainingCalculationResponse } from "@/types/generated/Api/trainingCalculationResponse";
import { TrainingViewModel } from "@/types/generated/Training/trainingViewModel";
import { orderBy } from "lodash";
import { useTrainingProductStore } from "@/store/training/trainingProductStore";

@Component({ components: { PriceSummary, ScrollPanel } })
export default class TrainingPriceSummary extends BaseComponent {
	@Prop({ type: Object as PropType<TrainingCalculationResponse>, required: true, default: {} })
	vm!: TrainingCalculationResponse;

	panelCollapse: { [index: number]: boolean } = {};
	trainingProductStore = useTrainingProductStore();

	get TrainingCalculationErrorCodeEnum(): typeof TrainingCalculationErrorCode {
		return TrainingCalculationErrorCode;
	}

	get trainingTitle(): string {
		let title = this.trainingProductStore.trainingViewModel?.name || "";
		if (this.isModularTraining) title += ` (${this.$t("training.lblModular")})`;
		return title;
	}

	get selectedCourse(): TrainingViewModel {
		return this.trainingProductStore.selectedTrainingCourse as TrainingViewModel;
	}

	get isModularTraining(): boolean {
		return this.trainingProductStore.trainingProductForm?.type === LocalTrainingType.modular;
	}

	get courseById() {
		return (id: string) => {
			if (this.trainingProductStore.allTrainingCourses?.[0].cadacIdentifier == id) {
				return this.trainingProductStore.allTrainingCourses?.[0];
			}
			const relatedCourses = (<any>this.trainingProductStore.allTrainingCourses?.[0])?.relatedProductModules;
			const course = relatedCourses.find((x) => x.cadacIdentifier === id);
			return course;
		};
	}

	get sortedLines() {
		return (course: TrainingCalculationCourseResponse) =>
			orderBy(course.lines, "order", "asc").filter((x) => x.totalPrice > 0);
	}

	get minimumRequiredDurationToBeValid() {
		return (course?: TrainingCalculationCourseResponse) => {
			if (!course?.totalDurationInDays) return 0;
			const decimals = course.totalDurationInDays % 1;
			if (!decimals || decimals >= 0.5) return 0;
			const durationInHours = (0.5 - decimals) * 6;
			return Math.ceil(durationInHours);
		};
	}

	get lineText() {
		return (line: TrainingCalculationLineResponse) => {
			switch (line.type) {
				case TrainingCalculationLineType.module:
					return `${line.quantity} x ${this.$tc(
						`enum.trainingCalculationLineType.${line.type}`,
						line.quantity
					)} (${this.$tc("common.days", line.totalDurationInDays || 0)})`;
				case TrainingCalculationLineType.vdiEnvironment:
					return `${line.quantity} x ${this.$t(`enum.trainingCalculationLineType.${line.type}`)}`;
				case TrainingCalculationLineType.trainingMaterial:
					return `${line.quantity} x ${this.$t(`enum.trainingCalculationLineType.${line.type}`)}`;
				case TrainingCalculationLineType.trainingRoom:
					return `${line.quantity} x ${this.$t(`enum.trainingCalculationLineType.${line.type}`)}`;
				case TrainingCalculationLineType.standardTraining:
					return this.$t(`enum.trainingType.${this.trainingProductStore.trainingProductForm?.type}`);
				case TrainingCalculationLineType.intake:
					return `${line.quantity} x ${this.$t(`enum.trainingCalculationLineType.${line.type}`)}`;
				default:
					return "";
			}
		};
	}

	get totalPriceSummary() {
		const { totalPrice } = this.vm as any;
		return { totalPrice } as IPriceSummary;
	}

	created(): void {
		this.vm?.courses?.forEach((_, i) => (this.panelCollapse[i] = i > 0));
	}
}
</script>

<style lang="scss" scoped>
.training-price-summary {
	text-align: right;

	.price-label {
		text-align: left;
		margin-right: 1rem;
	}

	p {
		font-size: 1.05rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.25rem;
	}

	h4 {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		margin-top: 0.75rem;
	}
}

::v-deep(.p-panel) {
	.p-panel-header {
		padding: 0;
		border: none;
		text-align: left;
	}

	.p-panel-content {
		padding: 0;
	}

	.p-card-footer {
		padding: 0;
	}
}
</style>
