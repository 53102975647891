<template>
	<div class="row">
		<div class="col-12 mx-auto">
			<Card v-if="isSpinnerVisible('advice')">
				<template #content>
					<div class="d-flex flex-column justify-content-center align-items-center text-center">
						<ProgressSpinner animation-duration="1.75s" stroke-width="4" />
						<h3>{{ $t("myCadac.advisorAdvice.loadingTitle") }}</h3>
						<p>{{ $t("myCadac.advisorAdvice.loadingText") }}</p>
					</div>
				</template>
			</Card>
			<div v-else-if="hasAdviceError && !isBusy">
				<Message class="my-0 w-100" :closable="false" severity="error">
					{{ $t("myCadac.advisorInsights.message.getInsightsError") }}
				</Message>
			</div>
			<Message
				v-else-if="showPollMessage"
				class="message-with-button w-100"
				:closable="false"
				severity="warn"
			>
				<span> {{ $t("myCadac.advisorAdvice.message.pollAdviceTimeout") }}</span>
				<Button
					class="p-button-success p-button-sm m-1"
					:label="$t('common.tryAgain')"
					@click="() => resumePolling()"
				/>
			</Message>
			<Message v-else-if="showNoAdviceMessage" :closable="false" severity="warn">
				{{ $t("myCadac.advisorAdvice.message.noAdvice") }}
			</Message>
		</div>
	</div>

	<div v-if="!isSpinnerVisible('advice') && hasAdvices && advisorStore.isAccessRestricted" class="mb-3">
		<Message class="message-with-button w-100" :closable="false" severity="warn">
			<div class="col-8">
				{{ $t("myCadac.advisorAdvice.message.contentRestricted") }}
			</div>
			<Button
				class="col-4 p-button-success p-button-sm"
				:label="$t('digitalAdvisor.button.contactUs')"
				@click="initChat(`${$t('myCadac.advisorAdvice.chatMessage.unlockAccess')}`)"
			/>
		</Message>
	</div>

	<BlockUI
		v-if="!isSpinnerVisible('advice') && hasAdvices"
		auto-z-index
		:blocked="advisorStore.isAccessRestricted"
		class="blurred-content"
	>
		<div class="row">
			<AdvisorTokenAdvice v-if="tokenAdvice" class="mb-2" :token-advice="tokenAdvice" />

			<div
				v-for="(product, index) in productWithAdvices"
				:key="`product_advice_${index}`"
				class="col-md-6 mb-3"
			>
				<AdvisorProductAdvices :product="product" />
			</div>
			<div
				v-for="(product, index) in productWithoutAdvices"
				:key="`product_termination_${index}`"
				class="col-md-6 mb-3"
			>
				<AdvisorProductAdvices :product="product" />
			</div>
		</div>
	</BlockUI>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ProgressSpinner from "primevue/progressspinner";
import { Component, Prop } from "vue-facing-decorator";
import { ProductAdviceViewModel } from "@/types/models/advisor/productAdviceViewModel";
import { useAdvisorStore } from "@/store/advisor/advisorStore";
import { AdvisorLogStatus } from "@/types/enum/advisorLogStatus";
import { Pausable, useIntervalFn } from "@vueuse/core";
import { TokenAdviceViewModel } from "@/types/models/advisor/tokenAdviceViewModel";
import { Log } from "@/types/helpers/logHelper";
import AdvisorTokenAdvice from "@/components/advisor/advisorTokenAdvice.vue";
import AdvisorProductAdvices from "@/components/advisor/advisorProductAdvices.vue";

@Component({ components: { AdvisorProductAdvices, AdvisorTokenAdvice, ProgressSpinner } })
export default class AdvisorAdviceOverview extends BaseComponent {
	@Prop({
		type: String,
		required: true,
		default: "",
	})
	adviceLogId!: string;

	@Prop({
		type: String,
		required: true,
		default: "",
	})
	apiVersion!: string;

	hasAdviceError = false;

	adviceProcessingPoll!: Pausable;
	pollCount = 0;
	pollStatus!: AdvisorLogStatus;
	pollIntervalMs = 30000;
	pollIntervalLimit = 3;
	advisorStore = useAdvisorStore();

	created(): void {
		this.pollAdviceProcessing();
	}

	get isPolling(): boolean {
		return (
			this.adviceProcessingPoll?.isActive &&
			(this.pollStatus === AdvisorLogStatus.Queued || this.pollStatus === AdvisorLogStatus.Processing)
		);
	}

	get productWithAdvices(): ProductAdviceViewModel[] | undefined {
		return this.advisorStore.productAdvices?.filter((x) => x.nextStepAdvices.length);
	}

	get productWithoutAdvices(): ProductAdviceViewModel[] | undefined {
		return this.advisorStore.productAdvices?.filter((x) => !x.nextStepAdvices.length);
	}

	get tokenAdvice(): TokenAdviceViewModel | undefined {
		return this.advisorStore.tokenAdvice;
	}

	get showPollMessage(): boolean {
		return !this.isPolling && this.pollStatus !== AdvisorLogStatus.Finished;
	}

	get showNoAdviceMessage(): boolean {
		return !this.isPolling && !this.advisorStore.hasAdviceToShow && !this.advisorStore.hasTokenAdviceToShow;
	}

	get hasAdvices(): boolean {
		return this.advisorStore.hasAdviceToShow || this.advisorStore.hasTokenAdviceToShow;
	}

	initChat(message: string): void {
		this.chatWidget.open({ name: message });
	}

	pausePolling(): void {
		this.loadingStore.hideSpinner("advice");
		this.adviceProcessingPoll?.pause();
	}

	resumePolling(): void {
		this.loadingStore.showSpinner("advice");
		this.adviceProcessingPoll?.resume();
	}

	private pollAdviceProcessing(): void {
		this.loadingStore.showSpinner("advice");
		this.adviceProcessingPoll = useIntervalFn(
			() => {
				this.advisorStore
					.pollAdviceCalculation(this.adviceLogId)
					.then((x) => {
						this.pollStatus = x.status;
						if (x.status === AdvisorLogStatus.Finished) {
							this.pausePolling();
							this.fetchAdvice(x.adviceId);
						}
						if (x.status === AdvisorLogStatus.Error) {
							this.pausePolling();
							this.hasAdviceError = true;
						}
					})
					.catch((e) => {
						this.pausePolling();
						Log.error(e);
						this.hasAdviceError = true;
					});
				this.pollCount++;
				if (this.pollCount % this.pollIntervalLimit === 0) {
					this.pausePolling();
				}
			},
			this.pollIntervalMs,
			{ immediate: true, immediateCallback: true }
		);
	}

	private fetchAdvice(adviceId: string): void {
		this.loadingStore.showSpinner("advice");
		this.advisorStore
			.getAdvice(adviceId, this.adviceLogId, this.apiVersion)
			.then(() => {
				this.hasAdviceError = false;
			})
			.catch((err) => {
				Log.error(err);
				this.hasAdviceError = true;
			})
			.finally(() => {
				this.loadingStore.hideSpinner("advice");
			});
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-component-overlay-enter) {
	animation: p-component-overlay-content-lock-animation-enter 150ms forwards;
}

::v-deep(.p-component-overlay-leave) {
	animation: p-component-overlay-content-lock-animation-leave 150ms forwards;
}

@keyframes p-component-overlay-content-lock-animation-enter {
	from {
		background-color: transparent;
	}
	to {
		transform-style: preserve-3d;
		backdrop-filter: blur(4px);
		background-color: rgba(0, 0, 0, 0.02);
		border-radius: 10px;
		box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
	}
}

@keyframes p-component-overlay-content-lock-animation-leave {
	from {
		transform-style: preserve-3d;
		backdrop-filter: blur(4px);
		background-color: rgba(0, 0, 0, 0.02);
		border-radius: 10px;
		box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
	}
	to {
		background-color: transparent;
	}
}

::v-deep(.blurred-content) {
	height: 100%;
	width: 100%;
	overflow: visible;

	.p-component-overlay:before {
		content: "\e95f";
		font-family: primeicons;
		font-style: normal;
		font-size: 3.25rem;
		font-weight: 400;
		font-variant: normal;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

::v-deep(.p-avatar) {
	img {
		background-color: var(--surface-a);
		width: 100%;
		height: 100%;
	}
}
</style>
