import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "form-group col-md-6" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "form-group col-md-6" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "form-row" }
const _hoisted_7 = { class: "form-group col-12" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['trainingParticipantForm'].firstName?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblFirstName")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['trainingParticipantForm'].firstName.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['trainingParticipantForm'].firstName.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].firstName) }),
          type: "text"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].firstName))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$["trainingParticipantForm"].firstName?.required?.$message), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['trainingParticipantForm'].lastName?.required })
        }, _toDisplayString(_ctx.$t("common.forms.lblLastName")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['trainingParticipantForm'].lastName.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['trainingParticipantForm'].lastName.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].lastName) }),
          type: "text"
        }, null, 8, ["modelValue", "class"]),
        (_ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].lastName))
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$["trainingParticipantForm"].lastName?.required?.$message), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", {
          class: _normalizeClass({ required: _ctx.v$['trainingParticipantForm'].email?.required })
        }, _toDisplayString(_ctx.$t("myCadac.licenceManagementDetail.dialog.lblEmail")), 3),
        _createVNode(_component_InputText, {
          modelValue: _ctx.v$['trainingParticipantForm'].email.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$['trainingParticipantForm'].email.$model) = $event)),
          class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].email) }),
          type: "email"
        }, null, 8, ["modelValue", "class"]),
        (
						_ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].email) &&
						!_ctx.v$['trainingParticipantForm'].email.$model
					)
          ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$["trainingParticipantForm"].email?.required?.$message), 1))
          : (
						_ctx.shouldShowError(_ctx.v$['trainingParticipantForm'].email) &&
						_ctx.v$['trainingParticipantForm'].email.$model
					)
            ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.v$["trainingParticipantForm"].email.email?.$message), 1))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}