<template>
	<div class="container">
		<div class="row my-4">
			<div class="col-12 mb-2">
				<BackButton :label="$t('common.back')" />
			</div>
			<div class="col-md-8 col-xl-9">
				<h1 v-if="article.title" class="display-4">{{ article.title }}</h1>
				<hr class="mb-0 mt-2" />
				<div class="text-color-secondary text-right mb-3">
					<small>{{ $t("common.lastModified", { date: formatLongDate(article.modifiedDate) }) }}</small>
				</div>
				<p class="rte" v-html="article.description" />
			</div>
			<div v-if="vm.questionBlockViewModel" class="col-md-4 col-xl-3">
				<div :class="{ 'sticky-sidebar': !isSmallScreen }">
					<CallToActionSimpleBlock :vm="vm.questionBlockViewModel" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CallToActionSimpleBlock from "@/views/blocks/callToActionSimpleBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { FaqArticlePageViewModel } from "@/types/models/support/faqArticlePageViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SupportFaqArticleViewModel } from "@/types/viewModels/commerce/supportFaqArticleViewModel";
import BackButton from "@/components/common/backButton.vue";

@Component({
	components: { BackButton, CallToActionSimpleBlock },
})
export default class SupportFaqArticle extends BaseComponent {
	@Prop({ type: Object as PropType<SupportFaqArticleViewModel>, required: true, default: {} })
	vm!: SupportFaqArticleViewModel;

	created(): void {
		Log.info("supportFaqArticle", this.vm);
	}

	get article(): FaqArticlePageViewModel {
		return this.vm.faqArticle;
	}
}
</script>

<style scoped lang="scss">
.sticky-sidebar {
	position: sticky;
	top: calc(var(--navbarHeightInPx) + 2rem);
}
</style>
