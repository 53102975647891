export enum OverviewSortType {
	Default,
	Category,
	NameAsc,
	NameDesc,
	PriceAsc,
	PriceDesc,
	Location,
	Availability,
	Score,
}
