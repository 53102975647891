<template>
	<div
		v-if="advisorStore.overview"
		class="p-3 mb-3"
		:style="{ 'background-color': scoreStyleObject.ctaBgColor, 'border-radius': '5px' }"
	>
		<Panel :collapsed="panelCollapsed">
			<template #header>
				<div class="d-flex align-items-center flex-1" @click="panelCollapsed = !panelCollapsed">
					<h2 class="p-panel-title flex-1">{{ scoreStyleObject.ctaTitle }}</h2>
					<i
						class="p-panel-header-icon"
						:class="panelCollapsed ? 'pi pi-chevron-left' : 'pi pi-chevron-down'"
					/>
				</div>
			</template>
			<Message :closable="false" icon="none" :severity="scoreStyleObject.ctaMessageStyle">
				<div class="row no-gutters py-2">
					<div class="col-1">
						<font-awesome-icon
							class="cta-icon"
							:class="scoreStyleObject.ctaTextColor"
							:icon="[scoreStyleObject.ctaIcon.style, scoreStyleObject.ctaIcon.icon]"
						/>
					</div>
					<div class="col px-2">
						<p class="ml-1 font-weight-bold lead">{{ scoreStyleObject.ctaMessage }}</p>
						<p class="ml-1">{{ $t("digitalAdvisor.cta.messageSubtitle") }}</p>
					</div>
				</div>
			</Message>
			<div class="d-flex gap-3">
				<div>
					<p class="lead">
						{{ $t("digitalAdvisor.cta.savingsTitle") }}
					</p>
					<i18n-t keypath="product.advice.lblYearlyTokenUsage" scope="global">
						<template #amount>
							<h4 class="d-inline-block">
								{{ formatCurrency(overview.amountSavedPerYear || 0) }}
							</h4>
						</template>
					</i18n-t>
					<p class="text-muted">
						{{ $t("digitalAdvisor.cta.savingsSubtitle") }}
					</p>
				</div>
				<div class="flex-1">
					<h3>{{ vm.ctaTitle }}</h3>
					<div class="d-flex align-items-center gap-1">
						<LinkButton
							v-if="vm.primaryCta"
							class="p-button-raised"
							:class="
								ctaButtonClass(vm.primaryCtaStyle) ||
								'p-button-raised p-button-success p-button-arrow'
							"
							:label="vm.primaryCta.text"
							:target="vm.primaryCta.target"
							:title="vm.primaryCta.title"
							:url="vm.primaryCta.url"
						/>

						<Button
							v-if="layoutStore.chatWidgetAvailable"
							class="p-button-secondary"
							:label="$t('product.advice.button.chatWithEmployee')"
							@click="initChat(`${$t('myCadac.advisorAdvice.chatMessage.contactSupport')}`)"
						/>
					</div>
					<p class="text-primary mt-2">
						<i18n-t keypath="common.support.contactUsAt" scope="global">
							<a :href="'tel:' + $t('common.support.phoneNumber')">
								{{ $t("common.support.phoneNumber") }}
							</a>
						</i18n-t>
					</p>
				</div>
			</div>
		</Panel>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import { Component, Prop } from "vue-facing-decorator";
import { CtaButtonStyle } from "@/types/enum/ctaButtonStyle";
import { LinkItemViewModel } from "@/types/models/common/linkItemViewModel";
import { PropType } from "vue";
import { useAdvisorStore } from "@/store/advisor/advisorStore";
import { OverviewViewModel } from "@/types/models/advisor/OverviewViewModel";

export interface IAdvisorResultCta {
	score: number;
	savings: number;

	ctaTitle: string;
	primaryCta: LinkItemViewModel;
	primaryCtaStyle: CtaButtonStyle;
	secondaryCta: LinkItemViewModel;
	secondaryCtaStyle: CtaButtonStyle;
}

@Component
export default class AdvisorResultCta extends BaseBlock {
	@Prop({ type: Object as PropType<IAdvisorResultCta>, required: true, default: {} }) vm!: IAdvisorResultCta;

	advisorStore = useAdvisorStore();

	panelCollapsed = false;

	ctaColors = {
		success: "rgba(32, 176, 101, 0.06)",
		warning: "rgba(255, 242, 226, 0.5)",
		error: "rgba(255, 231, 230, 0.5)",
	};

	get ctaBGColor(): string {
		return this.ctaColors.success;
	}

	get overview(): OverviewViewModel | undefined {
		return this.advisorStore.overview;
	}

	get scoreStyleObject(): any {
		const score = Math.round(this.advisorStore?.insight?.score || 1);

		if (!score) return {};
		switch (true) {
			case score <= 5:
				return {
					ctaTitle: this.$t("digitalAdvisor.cta.titleBadScore"),
					ctaMessage: this.$tc("digitalAdvisor.cta.messageTitleBad", { score: score }),
					ctaMessageStyle: "error",
					ctaTextColor: "text-danger",
					ctaBgColor: this.ctaColors.error,
					ctaIcon: {
						style: "far",
						icon: "triangle-exclamation",
					},
				};
			case score > 5 && score < 7:
				return {
					ctaTitle: this.$t("digitalAdvisor.cta.titleMediumScore"),
					ctaMessage: this.$tc("digitalAdvisor.cta.messageTitleMedium", { score: score }),
					ctaMessageStyle: "warn",
					ctaTextColor: "text-warning",
					ctaBgColor: this.ctaColors.warning,
					ctaIcon: {
						style: "far",
						icon: "circle-exclamation",
					},
				};
			case score >= 7:
				return {
					ctaTitle: this.$t("digitalAdvisor.cta.titleGoodScore"),
					ctaMessage: this.$tc("digitalAdvisor.cta.messageTitleGood", { score: score }),
					ctaMessageStyle: "success",
					ctaTextColor: "text-success",
					ctaBgColor: this.ctaColors.success,
					ctaIcon: {
						style: "fas",
						icon: "party-horn",
					},
				};
			default:
				return {};
		}
	}

	initChat(message: string): void {
		this.chatWidget.open({ name: message });
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-panel) {
	.p-panel-header {
		padding: 0;
		border: none;
		border-radius: 3px;
		text-align: left;
		background-color: transparent;
	}

	.p-panel-content {
		background: transparent;
		padding: 0;
	}

	.p-panel-title {
		margin-bottom: 0;
	}

	.p-message {
		.p-message-success {
			border: none;
		}
	}
}

.cta-icon {
	width: 100%;
	height: 100%;
}

::v-deep(.p-button.p-button-secondary) {
	border: none;
}
</style>
