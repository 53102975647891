<template>
	<div class="search-page mt-4 mb-2">
		<div class="container">
			<div>
				<h1>{{ $t("searchPage.searchPageResults") }}</h1>

				<DataView
					:first="(filter.page - 1) * filter.pageSize"
					layout="list"
					:lazy="true"
					:paginator="true"
					:rows="parseInt(filter.pageSize)"
					:total-records="searchResult?.totalResults"
					:value="searchResult?.items"
					@page="onPageChange"
				>
					<template #header>
						<div class="search-results d-flex flex-wrap justify-content-start w-100">
							<div
								class="d-flex flex-lg-row flex-column header-left justify-content-start justify-content-lg-start flex-grow-1 flex-lg-grow-0"
							>
								<Dropdown
									v-model="filter.category"
									append-to="self"
									class="search-categories"
									option-label="label"
									option-value="value"
									:options="searchCategoryOptions"
									scroll-height="400px"
									:show-toggle-all="false"
									@change="onCategoryChange"
								/>
								<span class="p-input-icon-left p-input-icon-right search-bar">
									<i v-if="isSpinnerVisible('searchPageOnSearch')" class="pi pi-spinner spin" />
									<i v-else class="pi pi-search text-primary" />
									<InputText
										v-model="filter.query"
										class="mr-1"
										:placeholder="$t('searchPage.input.searchPlaceholder')"
										type="search"
										@blur="onSearch"
										@keyup.enter="onSearch"
										@submit="onSearch"
									/>
									<i v-if="filter.query" class="pi pi-times icon-clear" @click="onClear" />
								</span>
							</div>
						</div>
					</template>
					<template #empty>
						<Message :closable="false" severity="warn">
							{{ $t("searchPage.noResults") }}
						</Message>
					</template>
					<template #list="item">
						<SearchPageResultItemSkeleton v-if="isSpinnerVisible('searchPageOnSearch')" />
						<SearchPageResultItem v-else :item="item.data" />
					</template>
				</DataView>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import DataView from "primevue/dataview";
import Divider from "primevue/divider";
import SearchPageResultItem from "@/components/general/search/searchPageResultItem.vue";
import SearchPageResultItemSkeleton from "@/components/general/search/searchPageResultItemSkeleton.vue";
import SearchPageSidebar from "@/components/general/search/searchPageSidebar.vue";
import { Component, Prop } from "vue-facing-decorator";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SearchCategory } from "@/types/enum/searchCategory";
import { SearchCategoryObject } from "@/types/models/search/searchCategory";
import { SearchPageViewModel } from "@/types/viewModels/general/searchPageViewModel";
import { SearchResultViewModel } from "@/types/models/search/searchResult";
import { toNumber, values } from "lodash";
import { ScrollHelper } from "../../types/helpers/scrollHelper";

@Component({
	components: { SearchPageResultItem, SearchPageSidebar, DataView, Divider, SearchPageResultItemSkeleton },
})
export default class SearchPage extends BaseComponent {
	@Prop({
		type: Object as PropType<SearchPageViewModel>,
		required: true,
		default: {},
	})
	vm!: SearchPageViewModel;

	searchResult!: SearchResultViewModel;
	searchCategoryOptions: SearchCategoryObject[] = [];

	filter = {
		query: "",
		category: "0",
		page: "1",
		pageSize: "20",
	};

	created(): void {
		Log.info("SearchPage", this.vm);
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		this.filter.query = params.query;
		this.filter.category = params.category || "0";
		this.filter.page = params.page || "1";
		this.filter.pageSize = params.pageSize || "20";

		this.searchCategoryOptions = values(SearchCategory)
			.filter((x) => Number.isFinite(toNumber(x)) || toNumber(x) === 0)
			.map((x) => ({
				value: `${x}`,
				label: this.$t(`enum.searchCategory.${x}`),
			}));

		this.onSearch();
	}

	onSearch(): void {
		if (!this.filter.query) {
			this.onClear();
			return;
		}
		this.loadingStore.showSpinner("searchPageOnSearch");

		const defaultResult = {} as SearchResultViewModel;
		const queryParams = new URLSearchParams("");

		queryParams.append("query", this.filter.query);
		queryParams.append("category", `${this.filter.category}`);
		queryParams.append("page", `${this.filter.page}`);
		queryParams.append("pageSize", `${this.filter.pageSize}`);

		const url = new URL(`${window.location.origin}/api/${this.vm.localizationPrefix}/search/`);
		url.search = queryParams.toString();

		this.axios
			.get<SearchResultViewModel>(url.toString(), { requestId: "searchPage" })
			.then((res) => {
				this.searchResult = res.data;
				this.updateUrl(queryParams);
			})
			.catch(() => {
				this.searchResult = defaultResult;
			})
			.finally(() => {
				this.loadingStore.hideSpinner("searchPageOnSearch");
			});

		this.trackSearch(this.filter.query);
	}

	onClear(): void {
		const defaultResult = {} as SearchResultViewModel;
		const queryParams = new URLSearchParams("");

		this.filter.query = "";
		this.filter.page = "1";

		queryParams.append("query", this.filter.query);
		queryParams.append("page", this.filter.page);

		this.updateUrl(queryParams);
		this.searchResult = defaultResult;
		ScrollHelper.scrollTo('.search-results', +this.layoutStore.navBarHeight);
	}

	updateUrl(queryParams: URLSearchParams): void {
		const url = new URL(window.location.href);
		url.search = queryParams.toString();

		window.history.pushState({}, document.title, url.toString());
	}

	onPageChange(e: any): void {
		this.filter.page = e.page + 1;
		this.onSearch();
		ScrollHelper.scrollTo('.search-results', +this.layoutStore.navBarHeight);
	}

	onCategoryChange(cat: SearchCategoryObject): void {
		this.filter.category = cat.value;
		this.filter.page = "1";
		this.onSearch();
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-dataview) {
	.p-dataview-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		background: var(--surface-a);
		border: none !important;
		position: sticky;
		top: var(--navbarHeightInPx);
		z-index: 10;
		padding: 0.75rem 0;

		.header-left {
			display: flex;
			margin: 0.5rem 0;
			gap: 0.5rem;
		}

		.header-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}

	.search-bar {
		min-width: 400px;
	}

	.search-categories {
		min-width: 150px;
	}
}
</style>
