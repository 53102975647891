<template>
	<form v-if="v$['askAQuestionMessageForm']">
		<div class="form-row">
			<div class="form-group col-lg-6">
				<label :class="{ required: v$['askAQuestionMessageForm'].subject?.required }">
					{{ $t("common.forms.lblSubject") }}
				</label>
				<InputText
					v-model="v$['askAQuestionMessageForm'].subject.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionMessageForm'].subject) }"
					type="text"
				/>
				<span v-if="shouldShowError(v$['askAQuestionMessageForm'].subject)">
					<small v-if="!v$['askAQuestionMessageForm'].subject.$model" class="p-error">
						{{ v$["askAQuestionMessageForm"].subject?.required?.$message }}
					</small>
					<small v-else-if="v$['askAQuestionMessageForm'].subject.$model" class="p-error">
						{{ v$["askAQuestionMessageForm"].subject.maxLength?.$message }}
					</small>
				</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col col-lg-9">
				<label :class="{ required: v$['askAQuestionMessageForm'].message?.required }">
					{{ $t("common.forms.lblDescription") }}
				</label>
				<Textarea
					v-model="v$['askAQuestionMessageForm'].message.$model"
					:class="{ 'p-invalid': shouldShowError(v$['askAQuestionMessageForm'].message) }"
					rows="5"
					type="text"
				/>
				<span v-if="shouldShowError(v$['askAQuestionMessageForm'].message)">
					<small v-if="!v$['askAQuestionMessageForm'].message.$model" class="p-error">
						{{ v$["askAQuestionMessageForm"].message?.required?.$message }}
					</small>
					<small v-else-if="v$['askAQuestionMessageForm'].message.minLength.$invalid" class="p-error">
						{{ v$["askAQuestionMessageForm"].message.minLength?.$message }}
					</small>
					<small v-else-if="v$['askAQuestionMessageForm'].message.maxLength.$invalid" class="p-error">
						{{ v$["askAQuestionMessageForm"].message.maxLength?.$message }}
					</small>
				</span>
			</div>
		</div>
		<div v-if="isSupportQuestion" class="form-row">
			<div class="form-group col col-lg-9">
				<label>
					{{ $t("common.forms.lblAttachment") }}
				</label>
				<PopoverIcon class="d-inline-flex ml-0">
					<i class="popover-icon pi pi-question" />
					<template #content>
						<div v-html="$t('support.attachmentTooltip')" />
					</template>
				</PopoverIcon>

				<FileUpload
					ref="fileUploader"
					:auto="true"
					:choose-label="$t('common.chooseFile')"
					:custom-upload="true"
					:invalid-file-size-message="$t('common.fileMayNotBeLargerThan', { fileSize: '20MB' })"
					:max-file-size="20971520"
					mode="basic"
					name="attachment"
					@uploader="onFileSelected"
				>
				</FileUpload>
				<div v-if="v$['askAQuestionMessageForm'].attachments.$model.length > 0">
					<div class="d-flex flex-wrap p-2 p-lg-0">
						<div
							v-for="(file, index) of v$['askAQuestionMessageForm'].attachments.$model"
							:key="file.name + file.type + file.size"
							class="my-2 p-1 d-flex shadow-sm align-items-center w-100"
							style="border: 1px solid #dddd"
						>
							<div>
								<img
									v-if="file.objectURL"
									:alt="file.name"
									class=""
									height="50"
									role="presentation"
									:src="file.objectURL"
									width="100"
								/>
								<font-awesome-icon v-else :icon="['far', 'file']" size="2x" />
							</div>
							<span class="text-bold ml-2 flex-1">{{ file.name }}</span>
							<div class="ml-2">{{ formatSize(file.size) }}</div>
							<Button
								class="p-button-text p-button-danger p-button-rounded ml-2"
								icon="pi pi-trash"
								:label="isSmallScreen ? $t('common.remove') : ''"
								:title="$t('common.remove')"
								@click="onFileRemoved(file, index)"
							/>
						</div>
					</div>
					<small>{{ $t("common.xOfY", [formatSize(totalSize), formatSize(totalSizeLimit)]) }}</small>
				</div>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop, Ref, VModel } from "vue-facing-decorator";
import { Validation } from "@vuelidate/core";
import FileUpload from "primevue/fileupload";
import { sumBy } from "lodash";

@Component({
	components: { FileUpload },
})
export default class AskAQuestionMessageForm extends BaseComponent {
	@Prop({ type: Boolean, required: true, default: false }) submitted!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) isSupportQuestion!: boolean;
	@Ref readonly fileUploader;
	@VModel() v$!: Validation;

	//20MB
	totalSizeLimit = 20971520;
	totalSize = 0;

	get checkTotalFileSize(): boolean {
		return this.totalSize < this.totalSizeLimit;
	}

	formatSize(bytes) {
		if (bytes === 0) {
			return "0 B";
		}

		let k = 1024,
			dm = 0,
			sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
			i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat(Math.floor(bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onFileSelected(event: any): void {
		const file: File = event.files[0];
		if (file.size + this.totalSize < this.totalSizeLimit) {
			this.v$["askAQuestionMessageForm"].attachments.$model.push(file);
			this.totalSize = sumBy(this.v$["askAQuestionMessageForm"].attachments.$model, (x: File) => x.size);
		} else {
			//throw error
			this.fileUploader.remove(this.v$["askAQuestionMessageForm"].attachments.$model.length);
			this.$toast.add({
				severity: "error",
				detail: this.$t("support.toast.uploadFileTotalSizeLimitError"),
				life: 3000,
			});
		}
	}

	onFileRemoved(file: File, index: number): void {
		this.v$["askAQuestionMessageForm"].attachments.$model.splice(index, 1);
		this.fileUploader.remove(index);
		this.totalSize -= file.size;
	}
}
</script>

<style scoped lang="scss"></style>
