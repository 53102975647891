import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-079938fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container py-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-lg-8" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "lead"
}
const _hoisted_6 = { class: "col-12 col-lg-4 order-first order-lg-last mb-3 mb-lg-0" }
const _hoisted_7 = { class: "sticky-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvisorResultCta = _resolveComponent("AdvisorResultCta")!
  const _component_AdvisorAdviceOverview = _resolveComponent("AdvisorAdviceOverview")!
  const _component_AdvisorInsightsOverview = _resolveComponent("AdvisorInsightsOverview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.vm.pageTitle)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.vm.pageTitle), 1))
          : _createCommentVNode("", true),
        (_ctx.vm.introText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.vm.introText), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_AdvisorResultCta, {
          vm: { ..._ctx.vm.resultCtaBlockViewModel }
        }, null, 8, ["vm"]),
        _createVNode(_component_AdvisorAdviceOverview, {
          "advice-log-id": _ctx.vm.adviceLogId,
          "api-version": _ctx.vm.apiVersion
        }, null, 8, ["advice-log-id", "api-version"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_AdvisorInsightsOverview)
        ])
      ])
    ])
  ]))
}