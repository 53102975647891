<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<template #header>
			<h3 class="mb-0">
				{{ $t("cart.dialog.saveCartTitle") }}
			</h3>
		</template>
		<p class="lead mb-2">{{ $t("cart.dialog.saveCartHelpText") }}</p>
		<form onsubmit="return false;">
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['cartSaveForm'].title?.required }">
						{{ $t("cart.dialog.saveCartLblCartTitle") }}
					</label>
					<InputText
						v-model="v$['cartSaveForm'].title.$model"
						:class="{ 'p-invalid': shouldShowError(v$['cartSaveForm'].title) }"
						type="text"
					/>
					<small v-if="shouldShowError(v$['cartSaveForm'].title)" class="p-error">
						{{ v$["cartSaveForm"].title?.required?.$message }}
					</small>
				</div>
			</div>
			<div class="p-field-checkbox mr-2 my-1">
				<Checkbox
					v-model="v$['cartSaveForm'].deleteAfterSave.$model"
					:id="'deleteAfterSave'"
					:binary="true"
					name="roles"
				/>
				<label class="ml-1 mb-0" :for="'deleteAfterSave'">
					{{ $t("cart.dialog.lblDeleteAfterSave") }}
				</label>
			</div>
		</form>
		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button :disabled="!canSubmit" :label="$t('common.save')" @click="onSubmit()" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import useVuelidate, { Validation } from "@vuelidate/core";
import { CartSaveForm } from "@/types/models/form/cartSaveForm";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { minLength } from "@/types/utils/i18n-validators";
import { useCartStore } from "@/store/commerce/cartStore";

@Component({
	emits: ["cart-saved"],
	components: { Checkbox },
	options: {
		validations: {
			cartSaveForm: {
				title: { minLength: minLength(2) },
				deleteAfterSave: {},
			},
		},
	},
})
export default class SaveCartModal extends BaseComponent {
	@Prop({ required: false, default: "" }) currentTitle!: string;

	v$ = useVuelidate();
	cartSaveForm!: CartSaveForm;
	isVisible = true;
	submitted = false;

	get canSubmit(): boolean {
		return this.v$["cartSaveForm"].$anyDirty && !this.v$["cartSaveForm"].$invalid;
	}

	@Emit()
	cartSaved(): CartSaveForm {
		return this.cartSaveForm;
	}

	created(): void {
		this.cartSaveForm = {
			title: this.currentTitle,
			deleteAfterSave: !this.currentTitle,
			salesOpportunity: "",
		};
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.cartSaved();
	}

	onCancel(): void {
		useCartStore().hideModal("SaveCart");
	}
}
</script>

<style scoped lang="scss"></style>
