import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08a18e4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "mb-0 text-muted d-block" }
const _hoisted_6 = {
  key: 0,
  class: "mb-0 text-bold"
}
const _hoisted_7 = ["href", "title"]
const _hoisted_8 = {
  key: 1,
  class: "mb-0 text-bold"
}
const _hoisted_9 = {
  key: 2,
  class: "text-success text-bold"
}
const _hoisted_10 = {
  key: 3,
  class: "text-info text-bold"
}
const _hoisted_11 = {
  key: 0,
  class: "text-danger text-bold"
}
const _hoisted_12 = {
  key: 1,
  class: "text-right"
}
const _hoisted_13 = {
  key: 0,
  class: "d-block text-muted"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "d-flex flex-column" }
const _hoisted_16 = { class: "d-flex flex-column" }
const _hoisted_17 = { class: "row-expansion-content" }
const _hoisted_18 = { class: "text-bold" }
const _hoisted_19 = { class: "d-flex align-items-center ml-1 ml-lg-0" }
const _hoisted_20 = { class: "d-flex flex-column" }
const _hoisted_21 = { class: "mb-0 text-muted d-block" }
const _hoisted_22 = { class: "mb-0 text-bold" }
const _hoisted_23 = { class: "text-right" }
const _hoisted_24 = {
  key: 0,
  class: "d-block text-muted"
}
const _hoisted_25 = { class: "col-md-10 col-lg-8 col-xl-6 ml-auto pr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "my-3" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("myCadac.contractDetail.card.titleProlong")), 1),
        (_ctx.canChangeContractDuration && !_ctx.isSwitchingContract)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "p-button-primary",
              label: _ctx.$t('myCadac.contractDetail.button.changeDuration'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showExtendOptions()))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ])
    ]),
    content: _withCtx(() => [
      (_ctx.isChangingDuration)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            class: "mt-0 mb-1",
            closable: false,
            severity: "warn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_i18n_t, { keypath: "myCadac.contractDetail.message.newExpirationDateDurationChange" }, {
                date: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.newEndDate), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_ctx.prolongableContractLines.length && _ctx.selectedContractLines?.length)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              class: "mt-0 mb-1",
              closable: false,
              severity: "info"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_i18n_t, { keypath: "myCadac.contractDetail.message.newExpirationDate" }, {
                  date: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString(_ctx.newEndDate), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      (_ctx.visibleContractLines?.length)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 2,
            "expanded-rows": _ctx.expandedRows,
            "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandedRows) = $event)),
            selection: _ctx.selectedContractLines,
            "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedContractLines) = $event)),
            "auto-layout": "",
            class: "mt-2",
            "data-key": "serialNumber",
            "responsive-layout": "stack",
            "row-class": _ctx.rowClass,
            value: _ctx.visibleContractLines
          }, {
            expansion: _withCtx((item) => [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$tc("myCadac.contractDetail.titleLicenseTradeInOverview", _ctx.isSwitchingContract)), 1),
                _createVNode(_component_DataTable, {
                  "auto-layout": "",
                  "data-key": "serialNumber",
                  "responsive-layout": "stack",
                  value: _ctx.getSwitchingContractLine(item.data)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "productName",
                      header: _ctx.$t('myCadac.contractDetail.table.colLicenses'),
                      sortable: false
                    }, {
                      body: _withCtx((item) => [
                        _createElementVNode("div", _hoisted_19, [
                          (_ctx.getSwitchProduct(item.data).productIcon)
                            ? (_openBlock(), _createBlock(_component_Avatar, {
                                key: 0,
                                alt: _ctx.getSwitchProduct(item.data).productName,
                                class: "mr-1 bg-transparent flex-shrink-0",
                                image: _ctx.getSwitchProduct(item.data).productIcon,
                                shape: "square",
                                size: "large",
                                title: item.productName
                              }, null, 8, ["alt", "image", "title"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("small", _hoisted_21, _toDisplayString(_ctx.getSwitchProduct(item.data).productBrand), 1),
                            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.formatProductName(
														_ctx.getSwitchProduct(item.data).productName,
														_ctx.getSwitchProduct(item.data).productBrand
													)), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["header"]),
                    _createVNode(_component_Column, {
                      key: "duration",
                      field: "duration",
                      header: _ctx.$t('myCadac.contractDetail.table.colDuration'),
                      sortable: false
                    }, {
                      body: _withCtx((item) => [
                        _createTextVNode(_toDisplayString(_ctx.$tc(
											"myCadac.contractDetail.table.duration",
											item.data.selectedSwitchDuration
										)), 1)
                      ]),
                      _: 2
                    }, 1032, ["header"]),
                    _createVNode(_component_Column, {
                      field: "deployment",
                      header: _ctx.$t('myCadac.contractDetail.table.colProductLicenceType'),
                      sortable: false
                    }, {
                      body: _withCtx((item) => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`enum.deployment.${item.data.deployment}`)), 1)
                      ]),
                      _: 2
                    }, 1032, ["header"]),
                    _createVNode(_component_Column, {
                      field: "selectedSwitchSeats",
                      header: _ctx.$t('myCadac.contractDetail.table.colProductSeats'),
                      sortable: false
                    }, null, 8, ["header"]),
                    _createVNode(_component_Column, {
                      "body-class": "text-right pr-2",
                      header: _ctx.$t('myCadac.contractDetail.table.colProductPrice'),
                      "header-class": "text-right pr-2",
                      sortable: false
                    }, {
                      body: _withCtx((item) => [
                        _createElementVNode("div", _hoisted_23, [
                          (item.data.priceBase !== item.data.price)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_24, [
                                _createElementVNode("s", null, _toDisplayString(_ctx.formatCurrency(item.data.priceBase)), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(item.data.price)), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["header"])
                  ]),
                  _: 2
                }, 1032, ["value"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                "body-style": "width:2rem",
                class: "text-lg-center",
                header: _ctx.$t('myCadac.contractDetail.table.colProductName'),
                "selection-mode": "multiple"
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "productName",
                sortable: false
              }, {
                body: _withCtx((item) => [
                  _createElementVNode("div", _hoisted_3, [
                    (item.data.icon)
                      ? (_openBlock(), _createBlock(_component_Avatar, {
                          key: 0,
                          alt: item.data.iconAlt,
                          class: "mr-1 bg-transparent",
                          image: item.data.icon,
                          shape: "square",
                          size: "large",
                          title: item.productName
                        }, null, 8, ["alt", "image", "title"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("small", _hoisted_5, _toDisplayString(item.data.productBrand), 1),
                      (_ctx.userProfile?.isCadacSales || _ctx.userProfile?.isImpersonated)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                            _createElementVNode("a", {
                              href: item.data.crmUrl,
                              target: "_blank",
                              title: _ctx.$t('common.goToCrm')
                            }, _toDisplayString(_ctx.formatProductName(item.data.productName, item.data.productBrand)), 9, _hoisted_7)
                          ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.formatProductName(item.data.productName, item.data.productBrand)), 1)),
                      (_ctx.isSwitching(item.data))
                        ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t("myCadac.contractDetail.table.productTradeInMessage")), 1))
                        : _createCommentVNode("", true),
                      (!_ctx.isSwitching(item.data) && item.data.isSwitched)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t("myCadac.contractDetail.table.productSwitchedMessage")), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "deployment",
                header: _ctx.$t('myCadac.contractDetail.table.colProductLicenceType'),
                sortable: false
              }, {
                body: _withCtx((item) => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`enum.deployment.${item.data.deployment}`)), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "seats",
                header: _ctx.$t('myCadac.contractDetail.table.colProductSeats'),
                sortable: false
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                class: "text-lg-right pr-lg-2",
                header: _ctx.$t('myCadac.contractDetail.table.colProductPrice'),
                sortable: false
              }, {
                body: _withCtx((item) => [
                  (_ctx.isRowDisabled(item.data))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.$t("myCadac.contractDetail.table.productNotProlongableMessage")), 1))
                    : (!_ctx.isSwitching(item.data) && _ctx.isRowSelected(item.data))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          (item.data.priceBase !== item.data.price)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_13, [
                                _createElementVNode("s", null, _toDisplayString(_ctx.formatCurrency(item.data.priceBase)), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("b", null, _toDisplayString(_ctx.formatCurrency(item.data.price)), 1)
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                class: "text-center",
                sortable: false
              }, {
                body: _withCtx((item) => [
                  (_ctx.canSwitch(item.data))
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "p-button-raised p-button-primary p-button-text p-button-sm",
                        label: _ctx.$t('myCadac.contractDetail.button.tradeIn'),
                        onClick: ($event: any) => (_ctx.showTradeInOptions(item.data))
                      }, null, 8, ["label", "onClick"]))
                    : (_ctx.isSwitching(item.data))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_Button, {
                                class: "p-button-link text-primary p-button-sm ml-1",
                                label: _ctx.$t('common.cancel'),
                                onClick: ($event: any) => (_ctx.removeTradeIn(item.data))
                              }, null, 8, ["label", "onClick"]),
                              _createVNode(_component_Button, {
                                class: "p-button-raised p-button-primary p-button-text p-button-sm ml-1",
                                label: _ctx.$t('common.change'),
                                onClick: ($event: any) => (_ctx.showTradeInOptions(item.data))
                              }, null, 8, ["label", "onClick"])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["expanded-rows", "selection", "row-class", "value"]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (_ctx.prolongableContractLines.length && !_ctx.selectedContractLines?.length)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            closable: false,
            severity: "warn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("myCadac.contractDetail.message.cancelContract")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_PriceSummary, { vm: _ctx.priceSummary }, null, 8, ["vm"]),
        _createVNode(_component_Button, {
          class: "p-button-success p-button-raised p-button-lg d-block ml-auto",
          disabled: !_ctx.canCheckout,
          label: _ctx.$t('myCadac.contractDetail.button.goToCheckout'),
          loading: _ctx.loadingStore.isBusy,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCheckoutClick()))
        }, null, 8, ["disabled", "label", "loading"])
      ])
    ]),
    _: 1
  }))
}