<template>
	<HeroFaqBlock v-if="vm.heroFaqBlock" :vm="vm.heroFaqBlock" />
	<div class="container">
		<div class="row mt-3 mb-4">
			<div :id="`cat_${selectedCategory.faqId}`" class="col-12">
				<SelectButton
					v-model="selectedCategory"
					class="row m-0 faq-categories"
					data-key="faqId"
					:options="vm.faqHeadCategories"
					@change="onHeadCategoryChanged()"
				>
					<template #option="{ option }">
						<div class="d-flex flex-column align-items-center flex-1 p-2">
							<div class="d-flex flex-column align-items-center">
								<img
									v-if="option.icon"
									:alt="option.icon.alt"
									class="mb-2 category-icon"
									:src="option.icon.url"
								/>
								<p class="font-weight-bold lead text-primary text-upper">
									{{ option.title }}
								</p>
								<p class="mb-0 lead">{{ option.description }}</p>
							</div>
						</div>
					</template>
				</SelectButton>
				<hr />
			</div>
			<div class="col-md-4 col-xl-3">
				<div class="faq-menu">
					<ScrollPanel style="width: 100%; height: 60vh; overflow: hidden">
						<div class="faq-category-menu">
							<Panel
								v-for="subCategory in selectedCategory?.subCategories"
								v-model:collapsed="
									menuItemCollapsed[`${selectedCategory?.faqId}_${subCategory.id}`]
								"
								:key="`menu_${selectedCategory?.faqId}_${subCategory.id}`"
								class="faq-category-submenu"
							>
								<template #header>
									<div :id="`${selectedCategory?.faqId}_${subCategory.id}`" class="">
										{{ subCategory.title }}
									</div>
								</template>
								<template #icons>
									<button
										v-if="selectedCategory?.subCategories?.length > 1"
										class="p-panel-header-icon p-link mr-2"
										@click="
											toggleMenuItemCollapse(`${selectedCategory?.faqId}_${subCategory.id}`)
										"
									>
										<i
											:class="
												isMenuItemCollapsed(`${selectedCategory?.faqId}_${subCategory.id}`)
													? 'pi pi-chevron-down'
													: 'pi pi-chevron-up'
											"
										/>
									</button>
								</template>
								<ul class="faq-category-subsubmenu">
									<li
										v-for="subSubCategory in subCategory.subSubCategories"
										:key="`menu_${selectedCategory?.faqId}_${subCategory.id}_${subSubCategory.id}`"
										class="faq-category-header-item"
										:class="{
											active:
												firstVisibleHeader ===
												`${selectedCategory?.faqId}_${subCategory.id}_${subSubCategory.id}`,
										}"
									>
										<a
											class="faq-category-header-item-link"
											:href="`#${selectedCategory?.faqId}_${subCategory.id}_${subSubCategory.id}`"
										>
											<span>{{ subSubCategory.title }}</span>
										</a>
									</li>
								</ul>
							</Panel>
						</div>
					</ScrollPanel>
				</div>
			</div>
			<div class="col-md-8 col-xl-9">
				<div class="faq-container">
					<div :key="`content_${selectedCategory.faqId}`" class="faq-items p-2">
						<div
							v-for="subCategory in selectedCategory.subCategories"
							:key="`content_${selectedCategory.faqId}_${subCategory.id}`"
							class="faq-sub-items"
						>
							<h4 :id="`${selectedCategory.faqId}_${subCategory.id}`" class="mb-0">
								{{ subCategory.title }}
							</h4>
							<ul
								v-for="subSubCategory in subCategory.subSubCategories"
								:key="`content_${selectedCategory.faqId}_${subCategory.id}_${subSubCategory.id}`"
								class="faq-sub-sub-items"
							>
								<li>
									<a
										:id="`${selectedCategory.faqId}_${subCategory.id}_${subSubCategory.id}`"
										v-vue-intersect="
											onIntersectionObserved(
												`${selectedCategory.faqId}_${subCategory.id}_${subSubCategory.id}`
											)
										"
										class="hidden-category-anchor"
									>
										{{ subCategory.title }}
									</a>
									<p
										:id="`header_${selectedCategory.faqId}_${subCategory.id}_${subSubCategory.id}`"
										class="faq-sub-sub-header"
									>
										{{ subSubCategory.title }}
									</p>
									<ul>
										<li
											v-for="item in subSubCategory.faqItems"
											:key="`item_${item.id}`"
											class="faq-item"
										>
											<a
												class="p-button p-button-arrow p-button-outlined justify-content-between ml-0 mb-1"
												:href="item.url"
											>
												{{ item.question }}
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import HeroFaqBlock from "@/views/blocks/heroFaqBlock.vue";
import ScrollPanel from "primevue/scrollpanel";
import SelectButton from "primevue/selectbutton";
import { Component, Prop } from "vue-facing-decorator";
import { FaqHeadCategory } from "@/types/models/support/faqHeadCategory";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { SupportFaqViewModel } from "@/types/viewModels/commerce/supportFaqViewModel";
import { entries, groupBy } from "lodash";
import { throttledWatch } from "@vueuse/core";
import CallToActionSimpleBlock from "@/views/blocks/callToActionSimpleBlock.vue";

@Component({ components: { CallToActionSimpleBlock, HeroFaqBlock, ScrollPanel, SelectButton } })
export default class SupportFaq extends BaseComponent {
	@Prop({ type: Object as PropType<SupportFaqViewModel>, required: true, default: {} })
	vm!: SupportFaqViewModel;

	menuItemCollapsed: Dictionary<boolean> = {};
	activeCategory: Dictionary<boolean> = {};
	previouslyFirstActiveHeader = "";
	selectedCategory!: FaqHeadCategory;

	created(): void {
		Log.info("SupportFaq", this.vm);

		this.initHeadCategoryFromUrl();
		this.initMenuItems();
		this.sortFaqHeadCategories();
	}

	mounted(): void {
		throttledWatch(this.activeCategory, this.collapseCategories, {
			throttle: 350,
		});
	}

	get isMenuItemCollapsed(): (id) => boolean {
		return (id) => this.menuItemCollapsed[id];
	}

	get firstVisibleHeader(): string {
		let id = "";
		entries(this.activeCategory)
			.sort()
			.some(([key, val]) => {
				if (val && !id) {
					id = key;
					return true;
				}
				return false;
			});
		if (id) {
			this.previouslyFirstActiveHeader = id;
			return id;
		} else {
			return this.previouslyFirstActiveHeader;
		}
	}

	initHeadCategoryFromUrl(): void {
		const regex = /\/([^/]+)\/?(?:#|$)/;
		const match = window.location.href.match(regex);
		if (!match) return;
		const category = match[1];
		this.selectedCategory = this.vm.faqHeadCategories.find((x) => x.faqId === category) as FaqHeadCategory;
	}

	initMenuItems(): void {
		// this.vm.faqHeadCategories.forEach((headCat) => {
		// 	headCat.subCategories.forEach((subCat) => {
		// 		this.menuItemCollapsed[`${headCat.faqId}_${subCat.id}`] = true;
		// 	});
		// });
		this.selectedCategory?.subCategories.forEach((subCat) => {
			this.menuItemCollapsed[`${this.selectedCategory.faqId}_${subCat.id}`] = true;
		});
	}

	collapseCategories(activeCategories: Dictionary<boolean>): void {
		// Group categories by prefix
		const groupedCategories = groupBy(entries(activeCategories).sort(), ([key, _value]) =>
			key.substring(0, key.lastIndexOf("_"))
		);

		// Map values into an object with prefix as key and a boolean array as value
		const mappedGroupedCategories = entries(groupedCategories).reduce(
			(acc, [key, val]) => {
				acc[key] = val.map(([_key, value]) => value);
				return acc;
			},
			{} as Dictionary<boolean[]>
		);

		// Generate object with key as prefix and value as a boolean indicating whether to collapse
		this.menuItemCollapsed = entries(mappedGroupedCategories).reduce((acc, [id, values]) => {
			const anyTrue = values.some((val) => val);
			acc[id] = !anyTrue;
			return acc;
		}, {} as Dictionary<boolean>);
	}

	onIntersectionObserved(id: string): (...args) => void {
		return (...args) => {
			const [{ isIntersecting }] = args[0];
			this.activeCategory[id] = isIntersecting;
		};
	}

	toggleMenuItemCollapse(id: string): void {
		this.menuItemCollapsed[id] = !this.menuItemCollapsed[id];
	}

	onHeadCategoryChanged(): void {
		this.initMenuItems();
		this.updateUrlForSelectedCategory();
	}

	// TODO sort categories based on predefined order, needs refactoring to a more global
	sortFaqHeadCategories(): void {
		enum sortOrder {
			SALES = "sales" as any,
			SERVICE = "service" as any,
			SUPPORT = "support" as any,
		}

		this.vm.faqHeadCategories.sort(
			(a, b) => Object.values(sortOrder).indexOf(a.faqId) - Object.values(sortOrder).indexOf(b.faqId)
		);
	}

	private updateUrlForSelectedCategory(): void {
		// Get the current URL
		const currentUrl = window.location.href;
		// Split the URL by '/'
		const urlParts = currentUrl.split("/");
		// Replace the second part of the URL with 'sales'
		urlParts[4] = this.selectedCategory.faqId;
		// Join the URL parts back together
		const newUrl = urlParts.join("/");
		// Replace the current URL with the new URL
		window.history.replaceState(null, document.title, newUrl);
	}
}
</script>

<style scoped lang="scss">
.faq-category-header {
	text-transform: uppercase;
	border-top: 1px solid;
	border-bottom: 1px solid;
	border-color: var(--gray-400);
	padding: 0.5rem 0;
	text-align: center;
}

.faq-menu {
	position: sticky;
	top: calc(var(--navbarHeightInPx) + 1rem);
	padding: 1rem 0;
	overflow: hidden;

	.faq-category-menu {
		margin-bottom: 1rem;
	}

	a {
		text-decoration: none;
		color: var(--text-color-secondary);
	}

	.faq-category-header-item {
		padding: 0.2rem 0.4rem;

		&.active {
			border-left: 3px solid var(--primary-color);

			a {
				font-weight: bold;
				color: var(--primary-color);
			}
		}
	}

	::v-deep(.p-panel) {
		.p-panel-header {
			padding: 0.25rem 0;
			font-weight: 500;
			border-bottom: 0 none;
		}

		.p-panel-content {
			padding: 0.25rem 0;
		}
	}

	::v-deep(.p-scrollpanel) {
		.p-scrollpanel-bar {
			background: var(--surface-d);
		}
	}
}

.faq-container {
	.faq-sub-sub-header {
		background-color: var(--surface-a);
		margin: 0;
		padding: 1rem 0 1rem 0;
		font-size: 1.3rem;
		font-weight: 500;
	}

	.faq-item {
		&:hover {
			a {
				background-color: var(--surface-e);
			}
		}

		a {
			background-color: var(--surface-a);
			border: 1px solid var(--surface-d);
			font-size: 1.1rem;
			text-decoration: none;
			justify-content: space-between;
			text-align: left;
			width: 100%;
			margin-left: 0;
			margin-bottom: 0.5rem;
		}
	}

	.faq-sub-sub-items {
		padding: 0 1rem;
	}
}

.faq-categories {
	a {
		text-decoration: none;
		color: var(--text-color);
	}
}

.hidden-category-anchor {
	display: block;
	visibility: hidden;
	position: relative;
	top: -3rem;
}

.category-icon {
	width: 50px;
	height: 50px;
	object-fit: cover;
}
</style>
