<template>
	<Dialog
		v-model:visible="isVisible"
		:breakpoints="{ '1600px': '40vw', '1200px': '50vw', '992px': '60vw', '768px': '75vw', '576px': '90vw' }"
		:modal="true"
		:style="{ width: '30vw' }"
		@hide="onCancel()"
	>
		<template #header>
			<h3 class="mb-0">
				{{
					selectedLicence
						? $t("myCadac.licenceManagementDetail.dialog.titleEditLicence")
						: $t("myCadac.licenceManagementDetail.dialog.titleNewLicence")
				}}
			</h3>
		</template>
		<form>
			<div class="form-row">
				<div class="form-group col-12">
					<label :class="{ required: v$['licenceForm'].licenceType?.required }">
						{{ $t("myCadac.licenceManagementDetail.dialog.lblLicenceType") }}
					</label>
					<Dropdown
						v-model="v$['licenceForm'].licenceType.$model"
						:class="{ 'p-invalid': shouldShowError(v$['licenceForm'].licenceType) }"
						:disabled="possibleLicenceTypes.length === 1"
						option-label="label"
						option-value="value"
						:options="possibleLicenceTypes"
					/>
					<small v-if="shouldShowError(v$['licenceForm'].licenceType)" class="p-error">
						{{ v$["licenceForm"].licenceType?.required?.$message }}
					</small>
				</div>
			</div>
			<UserLicenceForm v-if="isUserLicence" v-model="v$" :submitted="submitted" :vm="vm" />
			<ServerLicenceForm v-if="isServerLicence" v-model="v$" :submitted="submitted" :vm="vm" />
			<div v-if="!isEditing" class="p-field-checkbox mr-2 mb-2">
				<Checkbox
					v-model="v$['licenceForm'].sendEmailToLicenseUser.$model"
					id="sendEmailToLicenseUser"
					binary
					name="sendEmailToLicenseUser"
				/>
				<label class="ml-1 mb-0" for="sendEmailToLicenseUser">
					{{ $t("myCadac.licenceManagementDetail.dialog.lblSendEmailToLicenceUser") }}
				</label>
			</div>
		</form>
		<template #footer>
			<Button class="p-button-text p-button-plain" :label="$t('common.cancel')" @click="onCancel()" />
			<Button
				:disabled="!canSubmit"
				:label="isEditing ? $t('common.save') : $t('common.add')"
				@click="onSubmit()"
			/>
		</template>
	</Dialog>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import Checkbox from "primevue/checkbox";
import ServerLicenceForm from "@/components/licenceManagement/serverLicenceForm.vue";
import UserLicenceForm from "@/components/licenceManagement/userLicenceForm.vue";
import useVuelidate, { Validation } from "@vuelidate/core";
import { Component, Emit, Prop } from "vue-facing-decorator";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { ILicenceManagementDetail } from "@/types/viewModels/myCadac/licenceManagementDetailViewModel";
import { Licence } from "@/types/generated/licence";
import { LicenceForm } from "@/types/models/form/licenceForm";
import { LicenceStatus } from "@/types/enum/licenceStatus";
import { LicenceType } from "@/types/enum/licenceType";
import { PropType } from "vue";
import { email, minLength, required } from "@/types/utils/i18n-validators";
import { merge } from "lodash";
import { useLicenceStore } from "@/store/software/licenceStore";

@Component({
	components: { UserLicenceForm, ServerLicenceForm, Checkbox },
	emits: ["licence-saved"],
	options: {
		validations: {
			licenceForm: { licenceType: { required }, sendEmailToLicenseUser: { required } },
			serverLicenceForm: {
				serverName: { required },
				email: { required, email },
				roles: { required, minLength: minLength(1) },
			},
			userLicenceForm: {
				firstName: { required },
				lastName: { required },
				email: { required, email },
				roles: { required, minLength: minLength(1) },
			},
		},
	},
})
export default class LicenceFormModal extends BaseComponent {
	@Prop({ type: Object as PropType<ILicenceManagementDetail>, required: true, default: {} })
	vm!: ILicenceManagementDetail;

	v$ = useVuelidate();

	isEditing = false;
	submitted = false;
	isLoading = false;
	isVisible = true;

	licenceForm: LicenceForm | null = null;
	serverLicenceForm: Partial<LicenceForm> | null = null;
	userLicenceForm: Partial<LicenceForm> | null = null;
	licenceStore = useLicenceStore();

	@Emit()
	licenceSaved(): LicenceForm {
		return {
			...this.licenceFormModel,
			id: this.selectedLicence?.id || "",
		};
	}

	created(): void {
		this.isEditing = !!this.selectedLicence?.id;
		const formData: LicenceForm = this.selectedLicence
			? { ...this.selectedLicence, sendEmailToLicenseUser: !this.isEditing }
			: {
					firstName: "",
					lastName: "",
					email: "",
					roles: [],
					serverName: "",
					licenceStatus: LicenceStatus.assigned,
					licenceType: LicenceType.user,
					sendEmailToLicenseUser: !this.isEditing,
				};
		this.licenceForm = formData;
		this.serverLicenceForm = formData;
		this.userLicenceForm = formData;
	}

	get isUserLicence(): boolean {
		return this.v$["licenceForm"].$model.licenceType === LicenceType.user;
	}

	get isServerLicence(): boolean {
		return this.v$["licenceForm"].$model.licenceType === LicenceType.server;
	}

	get licenceFormModel(): LicenceForm {
		if (this.isUserLicence) {
			return merge(this.v$["licenceForm"].$model, this.v$["userLicenceForm"].$model);
		} else if (this.isServerLicence) {
			return merge(this.v$["licenceForm"].$model, this.v$["serverLicenceForm"].$model);
		}
		return this.v$["licenceForm"].$model;
	}

	get canSubmit(): boolean {
		return !this.v$["licenceForm"].$invalid && this.isSubformValid && !this.isLoading;
	}

	get isSubformValid(): boolean {
		switch (this.v$["licenceForm"].licenceType.$model) {
			case LicenceType.user:
				return !this.v$["userLicenceForm"].$invalid && this.v$["userLicenceForm"].$anyDirty;
			case LicenceType.server:
				return !this.v$["serverLicenceForm"].$invalid && this.v$["serverLicenceForm"].$anyDirty;
			case LicenceType.service:
			case LicenceType.connector:
			default:
				return true;
		}
	}

	get selectedLicence(): Licence | null {
		return this.licenceStore.selectedLicence;
	}

	get possibleLicenceTypes(): IDropdownItem[] {
		const licenceTypes = [
			{ label: this.$t(`enum.licenceType.${LicenceType.user}`), value: LicenceType.user },
			{ label: this.$t(`enum.licenceType.${LicenceType.server}`), value: LicenceType.server },
		];
		return licenceTypes.filter((x) => {
			if (x.value === LicenceType.user) {
				return this.vm.licenceProduct.namedUserLicencesAllowed;
			} else if (x.value === LicenceType.server) {
				return this.vm.licenceProduct.serverUserLicencesAllowed;
			}
			return true;
		});
	}

	shouldShowError(fieldToValidate: Validation): boolean {
		return fieldToValidate.$invalid && (this.submitted || fieldToValidate.$dirty);
	}

	onSubmit(): void {
		this.submitted = true;
		if (!this.canSubmit) return;
		this.licenceSaved();
	}

	onCancel(): void {
		this.licenceStore.hideModal("LicenceForm");
	}
}
</script>

<style scoped lang="scss"></style>
