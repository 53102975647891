<template>
	<a
		v-if="vm.backgroundUrl"
		class="cta-offer-block has-image flex-md-row align-items-md-center"
		:href="vm.linkItem.url"
		:style="{
			'background-image': `url(${vm.backgroundUrl}`,
		}"
		:target="vm.linkItem.target"
	>
		<div class="d-flex flex-1 justify-content-center">
			<div class="align-self-center mr-1">
				<Avatar class="bg-transparent" shape="square" :size="vm.useLargeIcon ? 'xlarge' : 'large'">
					<img v-if="vm.iconUrl" v-lazy="vm.iconUrl" :alt="vm.iconAlt" />
				</Avatar>
			</div>
			<div class="d-flex flex-column">
				<h4 class="mb-0">
					{{ vm.title }}
				</h4>
				<p class="mb-0 font-weight-light">
					{{ vm.subTitle }}
				</p>
			</div>
		</div>
	</a>
	<div v-else class="cta-offer-block flex-md-row align-items-md-center">
		<div class="d-flex flex-1">
			<div class="align-self-center mr-1">
				<Avatar class="bg-transparent" shape="square" :size="vm.useLargeIcon ? 'xlarge' : 'large'">
					<img v-if="vm.iconUrl" v-lazy="vm.iconUrl" :alt="vm.iconAlt" />
				</Avatar>
			</div>
			<div class="d-flex flex-column">
				<h4 class="mb-0">
					{{ vm.title }}
				</h4>
				<p class="mb-0 lead">
					{{ vm.subTitle }}
				</p>
			</div>
		</div>
		<div class="mt-2 mt-lg-0 align-self-end">
			<LinkButton
				v-if="vm.linkItem"
				class="p-button-arrow p-button-raised p-button-text"
				:label="vm.linkItem.text"
				:target="vm.linkItem.target"
				:title="vm.linkItem.title"
				:url="vm.linkItem.url"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { CallToActionOfferBlockViewModel } from "@/types/viewModels/blocks/callToActionOfferBlockViewModel";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";

@Component
export default class CallToActionOfferBlock extends BaseComponent {
	@Prop({ type: Object as PropType<CallToActionOfferBlockViewModel>, required: true, default: {} })
	vm!: CallToActionOfferBlockViewModel;
}
</script>

<style scoped lang="scss">
.cta-offer-block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	padding: 2rem;
	color: var(--primary-color-text);
	background-color: var(--primary-color);
	border: 1px solid var(--surface-d);
	border-radius: var(--border-radius);

	&.has-image {
		cursor: pointer;
		text-decoration: none;
		background-color: var(--surface-d);
		color: var(--text-color);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		transition: all 0.3s;
		&:hover {
			box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 10%);
		}
	}
}
</style>
