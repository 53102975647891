import { defineStore } from 'pinia';
import { Licence } from '@/types/generated/licence';
import { LicenceProduct } from '@/types/generated/licenceProduct';
import { useSessionStorage } from '@vueuse/core';

interface LicenceStoreState {
	canSeeLicences: boolean;
	isLicenceFormModalVisible: boolean;
	selectedLicence: Licence | null;
	selectedLicenceProduct: LicenceProduct | null;
}

export type LicenceModalType = 'LicenceForm';

export const useLicenceStore = defineStore('licence', {
	state: () =>
		({
			canSeeLicences: false,
			isLicenceFormModalVisible: false,
			selectedLicence: null,
			selectedLicenceProduct: null,
		}) as LicenceStoreState,
	getters: {
		canSeeLicences(): boolean {
			const canSeeLicences = useSessionStorage('licenceViewUnlocked', false);
			return canSeeLicences?.value;
		},
	},
	actions: {
		setCanSeeLicences(value: boolean) {
			const canSeeLicences = useSessionStorage('licenceViewUnlocked', false);
			canSeeLicences.value = value;
		},
		showModal(type: LicenceModalType) {
			switch (type) {
				case 'LicenceForm':
					this.isLicenceFormModalVisible = true;
					break;
			}
		},
		hideModal(type: LicenceModalType) {
			switch (type) {
				case 'LicenceForm':
					this.isLicenceFormModalVisible = false;
					break;
			}
		},
	},
});
