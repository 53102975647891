import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c47dbda0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-image text-center" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = ["data-firstletter"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "pi pi-spinner spin profile-avatar-loading"
}
const _hoisted_6 = {
  key: 3,
  class: "profile-figcaption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.canChangeImage)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            accept: "image/png,image/jpeg",
            class: "d-none",
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onProfilePictureChange && _ctx.onProfilePictureChange(...args)))
          }, null, 32))
        : _createCommentVNode("", true),
      _createElementVNode("figure", {
        class: "profile-figure",
        style: _normalizeStyle({ cursor: _ctx.canChangeImage ? 'pointer' : 'auto' })
      }, [
        (!_ctx.validProfileImage)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.firstLetterAvatar)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    "aria-hidden": "true",
                    class: _normalizeClass(["avatar", { 'avatar--large': !_ctx.isSmallScreen }]),
                    "data-firstletter": _ctx.firstLetterAvatar
                  }, null, 10, _hoisted_3))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["avatar", { 'avatar--large': !_ctx.isSmallScreen }])
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ('far', 'user'),
                      size: "sm"
                    })
                  ], 2))
            ], 64))
          : (!_ctx.isSpinnerVisible('avatar'))
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                alt: "avatar",
                class: "profile-avatar",
                src: _ctx.profileImageUrl || _ctx.loadedFile,
                onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onProfilePictureError && _ctx.onProfilePictureError(...args)))
              }, null, 40, _hoisted_4))
            : (_openBlock(), _createElementBlock("i", _hoisted_5)),
        (_ctx.canChangeImage)
          ? (_openBlock(), _createElementBlock("figcaption", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.change")), 1)
            ]))
          : _createCommentVNode("", true)
      ], 4)
    ])
  ]))
}