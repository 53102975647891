<template>
	<div class="row hidden-print">
		<div class="col-12">
			<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>
			<Message v-if="!vm.shoppingCarts.length" :closable="false" severity="warn">
				{{ $t("myCadac.cartOverview.noCarts") }}
			</Message>

			<DataTable
				v-else
				v-model:expanded-rows="expandedRows"
				:auto-layout="!isSmallScreen"
				class="mb-3"
				responsive-layout="stack"
				:row-class="highlightRow"
				:row-hover="true"
				:value="vm.shoppingCarts"
				@row-click.self="onRowClick"
			>
				<Column v-if="!isSmallScreen" expander header-style="width:1.75rem" />
				<Column
					v-if="userProfile?.isImpersonated || userProfile?.isCadacSales"
					key="sales"
					:header="$t('myCadac.cartOverview.table.colSalesOpportunity')"
				>
					<template #body="item">
						<a
							v-if="userProfile?.isImpersonatedByCadacSales && item.data.opportunityUrl"
							class="text-bold"
							:href="item.data.opportunityUrl"
							target="_blank"
							:title="$t('common.goToCrm')"
						>
							{{ item.data.opportunityName }}
						</a>
						<p v-else class="mb-0">
							{{ item.data.opportunityName || item.data.title }}
						</p>
						<small>{{ item.data.responsibleSales }}</small>
					</template>
				</Column>
				<Column
					key="title"
					field="title"
					:header="$t('myCadac.cartOverview.table.colCartTitle')"
					:sortable="true"
				/>
				<Column
					key="created"
					field="created"
					:header="$t('myCadac.cartOverview.table.colCreatedDate')"
					:sortable="true"
				>
					<template #body="item">
						{{ formatDate(item.data.created) }}
					</template>
				</Column>
				<Column
					key="createdBy"
					field="createdBy"
					:header="$t('myCadac.cartOverview.table.colCreatedBy')"
					:sortable="true"
				/>
				<Column
					key="nrOfProducts"
					field="nrOfProducts"
					:header="$t('myCadac.cartOverview.table.colNrOfProducts')"
					:sortable="true"
				/>
				<Column
					key="total"
					field="totalPrice"
					:header="$t('myCadac.cartOverview.table.colTotal')"
					:sortable="true"
				>
					<template #body="item">
						<span class="text-primary text-bold">{{ formatCurrency(item.data.totalPrice || 0) }}</span>
					</template>
				</Column>
				<Column>
					<template #body="item">
						<Menu
							:ref="`menu${item.index}`"
							class="hidden-print"
							:model="optionsMenu[item.index]"
							:popup="true"
						>
							<a
								class="p-menuitem-link"
								:href="item.url"
								role="menuitem"
								:target="item.target"
								@click="item.command"
							>
								<span :class="['p-menuitem-icon', item.icon]"></span>
								<span class="p-menuitem-text">{{ item.label }}</span>
							</a>
						</Menu>
						<div class="d-flex flex-nowrap flex-grow-1 justify-content-between justify-content-lg-end">
							<Button
								class="p-button-success mr-2"
								:disabled="isSpinnerVisible(`btnCheckoutCart_${item.data.cartName}`)"
								icon="pi pi-shopping-cart"
								:label="isSmallScreen ? $t('common.order') : ''"
								:loading="isSpinnerVisible(`btnCheckoutCart_${item.data.cartName}`)"
								:title="$t('common.order')"
								@click="checkoutCart(item.data)"
							/>
							<Button
								class="p-button-primary p-button-raised p-button-text p-button-sm surface-a"
								icon="pi pi-ellipsis-v"
								@click="toggleMenu($event, item.index)"
							/>
						</div>
					</template>
				</Column>
				<template #expansion="item">
					<div class="row-expansion-content">
						<CartOverview :cart-client="item.data.cartClient" :is-editable="false" />
					</div>
				</template>
			</DataTable>
		</div>
	</div>

	<div v-if="cartToPrint" class="visible-print">
		<CartPrintPreview :cart-client="cartToPrint?.cartClient" :title="cartToPrint?.title" />
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartOverview from "@/components/commerce/cart/cartOverview.vue";
import CartPrintPreview from "@/components/commerce/cart/cartPrintPreview.vue";
import Menu from "primevue/menu";
import { Component, Prop } from "vue-facing-decorator";
import { ISavedCartsOverview } from "@/types/viewModels/myCadac/savedCartsOverviewViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { StoredShoppingCart } from "@/types/generated/storedShoppingCart";

@Component({ components: { CartPrintPreview, CartOverview, Menu } })
export default class SavedCartsOverview extends BaseComponent {
	@Prop({ type: Object as PropType<ISavedCartsOverview>, required: true, default: {} })
	vm!: ISavedCartsOverview;

	optionsMenu = {};
	expandedRows: StoredShoppingCart[] = [];
	cartToPrint: StoredShoppingCart | null = null;

	created(): void {
		this.vm.shoppingCarts.forEach((cart, index) => (this.optionsMenu[index] = this.getMenuItemsForCart(cart)));
	}

	getMenuItemsForCart(cart: StoredShoppingCart) {
		return [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				data: cart,
				command: () => this.editCart(cart),
			},
			{
				label: "Print",
				icon: "pi pi-print",
				data: cart,
				command: () => this.printCart(cart),
			},
			{
				label: "Remove",
				icon: "pi pi-trash",
				data: cart,
				command: () => this.deleteCart(cart),
			},
		];
	}

	onRowClick(event: { data: StoredShoppingCart; index: number }): void {
		const existingRowIndex = this.expandedRows.findIndex((x) => x.created === event.data.created);
		if (existingRowIndex > -1) {
			this.expandedRows.splice(existingRowIndex, 1);
		} else {
			this.expandedRows.push(event.data);
		}
		this.expandedRows = [...this.expandedRows];
	}

	highlightRow(cart: StoredShoppingCart): string {
		const isRowExpanded = this.expandedRows.findIndex((x) => x.created === cart.created) > -1;
		return isRowExpanded ? "highlight" : "";
	}

	toggleMenu(event: Event, index: number): void {
		(this.$refs[`menu${index}`] as Menu)?.toggle(event);
	}

	editCart(cart: StoredShoppingCart): void {
		this.$confirm.require({
			header: this.$t("myCadac.cartOverview.dialog.confirmEdit_title"),
			message: this.$t("myCadac.cartOverview.dialog.confirmEdit"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.loadingStore.increaseLoadingCount();
				this.axios
					.post(`/api/cart/editCart`, cart.cartName)
					.then(() => {
						this.openUrl(this.vm.cartPageUrl);
					})
					.catch((err) => {
						this.loadingStore.decreaseLoadingCount();
						Log.error(err);
					});
			},
		});
	}

	printCart(cart: StoredShoppingCart): void {
		this.cartToPrint = cart;
		setTimeout(() => {
			this.$forceUpdate();
			window.print();
		}, 1000);
	}

	deleteCart(cart: StoredShoppingCart): void {
		this.$confirm.require({
			header: this.$t("myCadac.cartOverview.dialog.confirmDelete_title"),
			message: this.$t("myCadac.cartOverview.dialog.confirmDelete"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.loadingStore.increaseLoadingCount();
				this.axios
					.post(`/api/cart/deleteCart`, cart.cartName)
					.then(() => {
						window.location.reload();
					})
					.catch((err) => {
						this.loadingStore.decreaseLoadingCount();
						Log.error(err);
					});
			},
		});
	}

	checkoutCart(cart: StoredShoppingCart): void {
		if (this.isSpinnerVisible(`btnCheckoutCart_${cart.cartName}`)) return;
		this.loadingStore.showSpinner(`btnCheckoutCart_${cart.cartName}`);
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/cart/editCart`, cart.cartName)
			.then(() => {
				this.openUrl(this.vm.checkoutPageUrl);
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
			})
			.finally(() => this.loadingStore.hideSpinner(`btnCheckoutCart_${cart.cartName}`));
	}
}
</script>

<style lang="scss" scoped>
.p-button.p-button-raised {
	box-shadow:
		0px 3px 0px -2px rgb(0 0 0 / 20%),
		0px 2px 2px 0px rgb(0 0 0 / 14%),
		0px 1px 0px 0px rgb(0 0 0 / 12%);
}

::v-deep(.p-datatable) {
	.p-datatable-tbody tr.p-datatable-row-expansion div.row-expansion-content .p-datatable-tbody tr,
	.p-datatable-tbody tr.p-datatable-row-expansion div.row-expansion-content .p-datatable-thead tr th {
		background: var(--surface-a);
		padding: 0.75rem 0.75rem;
	}

	.p-datatable-tbody tr.p-datatable-row-expansion div.row-expansion-content {
		background: var(--surface-a);
		padding: 0;

		.p-datatable-tbody td {
			padding: 0.25rem 0.75rem;
		}
	}

	.p-datatable-tbody tr.p-datatable-row-expansion td {
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	}
}
</style>
