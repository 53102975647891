import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-group col-lg-6" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-lg-6" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_ctx.v$['askAQuestionSupportTicketForm'])
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['askAQuestionSupportTicketForm']?.impact?.required })
            }, _toDisplayString(_ctx.$t("support.lblImpact")), 3),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.v$['askAQuestionSupportTicketForm'].impact.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$['askAQuestionSupportTicketForm'].impact.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['askAQuestionSupportTicketForm']?.impact) }),
              "option-label": "label",
              "option-value": "value",
              options: _ctx.availableImpactOptions
            }, null, 8, ["modelValue", "class", "options"]),
            (_ctx.shouldShowError(_ctx.v$['askAQuestionSupportTicketForm']?.impact))
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$["askAQuestionSupportTicketForm"].impact?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", {
              class: _normalizeClass({ required: _ctx.v$['askAQuestionSupportTicketForm']?.urgency?.required })
            }, _toDisplayString(_ctx.$t("support.lblUrgency")), 3),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.v$['askAQuestionSupportTicketForm'].urgency.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$['askAQuestionSupportTicketForm'].urgency.$model) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.shouldShowError(_ctx.v$['askAQuestionSupportTicketForm']?.urgency) }),
              "option-label": "label",
              "option-value": "value",
              options: _ctx.availableUrgencyOptions
            }, null, 8, ["modelValue", "class", "options"]),
            (_ctx.shouldShowError(_ctx.v$['askAQuestionSupportTicketForm']?.urgency))
              ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$["askAQuestionSupportTicketForm"]?.urgency?.required?.$message), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}