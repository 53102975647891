<template>
    <div class="circular-wrapper">
        <div class="circular-icon-wrapper">
            <div ref="progressCircle" class="circular-progress" />
            <div ref="innerCircle" class="inner-circle" />
            <img ref="icon"
                 v-if="item.iconUrl || item.icon.url"
                 v-lazy="item.iconUrl || item.icon.url"
                 :alt="item.iconAlt || item.icon.alt"
                 class="circular-icon"
                 :title="item.name || item.cashbackText" />
        </div>
    </div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component, Prop } from "vue-facing-decorator";
import { PropType } from "vue";

interface CircularProgressProps {
    name: string;
    iconUrl: string;
    iconAlt: string;
    percentage: number;
}

@Component
export default class CircularProgress extends BaseComponent {
    @Prop({
        type: Object as PropType<CircularProgressProps>,
        required: true,
        default: {},
    })
    item!: CircularProgressProps;

    mounted() {
        const progressCircle = this.$refs.progressCircle as HTMLElement;
        const innerCircle = this.$refs.innerCircle as HTMLElement;
        const icon = this.$refs.icon as HTMLElement;

        setTimeout(() => {
            if (!progressCircle) return;
            const percentage = Number(this.item.percentage);

            let startValue = -1,
                endValue = percentage,
                speed = 10;

            if (innerCircle && percentage < 100) {
                innerCircle.classList.add('grayed-out');
            }

            if (icon && percentage < 100) {
                icon.classList.add('grayed-out');
            }

            const progress = setInterval(() => {
                startValue++;

                progressCircle.style.background = "linear-gradient(#FFCD82, #CC7C03)";
                progressCircle.style.maskImage = `conic-gradient(#000 ${startValue}%, #0000 0)`;
                if (startValue === endValue) {
                    clearInterval(progress);
                }
            }, speed);

        }, 500);
    }


}
</script>
<style scoped lang="scss">
    .circular-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .circular-icon-wrapper {
            position: relative;
        }

        .circular-icon {
            position: absolute;
            inset: 0;
            margin: auto;
            width: 2.5rem;
            height: 2.5rem;
            z-index: 10;

            &.grayed-out {
                filter: contrast(60%) grayscale(0.5) saturate(0.2);
                opacity: 0.5;
            }
        }
    }


    .circular-progress {
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inner-circle {
        position: absolute;
        width: 6.2rem;
        height: 6.2rem;
        border-radius: 50%;
        background-color: #fff;
        inset: 0;
        margin: auto;
        border: 4px solid #fff;

        &.grayed-out {
            background: #E0E0DD;
        }
    }
</style>
