import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4da321a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-config-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Panel, {
      collapsed: _ctx.panelCollapsed,
      "onUpdate:collapsed": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.panelCollapsed) = $event)),
      header: _ctx.header
    }, {
      icons: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-text p-panel-header-icon",
          icon: _ctx.panelCollapsed ? 'pi pi-chevron-left' : 'pi pi-chevron-down',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.panelCollapsed = !_ctx.panelCollapsed))
        }, null, 8, ["icon"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("dl", null, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["collapsed", "header"])
  ]))
}