<template>
	<div>
		<Calendar
			v-bind="bookingDateOptions"
			class="w-100"
			:disabled-dates="disabledDates"
			:locale="currentRegion"
			:max-date="new Date(calendarMaxDate)"
			:model-value="activeDate"
			@date-select="onDateSelected($event)"
		>
			<template #date="slotProps">
				<strong
					class="custom-date"
					:class="{
						selected: isSelectedDate(convertDateObjectToDate(slotProps.date)),
						available:
							getAvailabilityType(convertDateObjectToDate(slotProps.date)) ===
							expertDateAvailability.available,
						'on-request':
							getAvailabilityType(convertDateObjectToDate(slotProps.date)) ===
							expertDateAvailability.onRequest,
					}"
				>
					{{ slotProps.date.day }}
				</strong>
			</template>
		</Calendar>
		<div class="d-flex align-items-center flex-wrap p-1">
			<div
				v-for="option in legendOptions"
				:key="option.label"
				class="d-flex align-items-center flex-shrink-0 mr-2"
			>
				<div class="legend-box" :class="option.color"></div>
				<small class="mb-0">{{ `${$t(option.label)}` }}</small>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { ExpertBookedHour } from "@/types/models/expert/expertBookedHour";

const { convertDateObjectToDate, getDateWithoutTime } = FormatHelper;
import BaseComponent from "@/components/base/baseComponent.vue";
import Calendar, { CalendarProps } from "primevue/calendar";
import { Component, Emit, Prop, VModel } from "vue-facing-decorator";
import { ExpertAvailabilityDate } from "@/types/models/expert/expertAvailabilityDate";
import { ExpertBookingDate } from "@/types/models/expert/expertBookingDate";
import { ExpertDateAvailability } from "@/types/enum/expertDateAvailability";
import { FormatHelper } from "@/types/helpers/formatHelper";
import { PropType } from "vue";
import { addHours, compareAsc, getHours } from "date-fns";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { ExpertBookingLocationType } from "@/types/enum/expertBookingLocation";

export interface ExpertBookingDateSelectedEvent {
	date: Date;
	availability: ExpertDateAvailability;
	bookedHours: Array<ExpertBookedHour>;
	startHour: Date;
}

export interface ILegendOption {
	color: string;
	label: string;
}

@Component({
	components: {
		Calendar,
	},
})
export default class ExpertBookingCalendar extends BaseComponent {
	@Prop({ type: Object as PropType<ExpertAvailabilityDate[]>, required: true, default: [] })
	availabilityDates!: ExpertAvailabilityDate[];
	@Prop({ type: Object as PropType<ExpertBookingDate[]>, required: true, default: [] })
	selectedDates!: ExpertBookingDate[];
	@VModel({ type: Date, default: null }) activeDate!: Date;
	@Prop({ type: Date, required: true }) selectedDateStartingHour!: Date;
	@Prop({ type: Number, default: 8 }) totalWorkHours;
	@Prop({ type: Object as PropType<IDropdownItem> | null, default: null })
	selectedLocationOption!: IDropdownItem;
	@Prop({ type: String, required: true }) calendarMinDate;
	@Prop({ type: String, required: true }) calendarMaxDate;

	legendOptions: ILegendOption[] = [
		{
			color: "bg-success",
			label: "expert.legendAvailable",
		},
		{
			color: "bg-warning",
			label: "expert.legendOnRequest",
		},
		{
			color: "bg-black",
			label: "expert.legendToday",
		},
	];

	@Emit("date-select")
	selectDate(date, bookedHours, startHour): ExpertBookingDateSelectedEvent {
		return {
			date,
			availability: this.getAvailabilityType(date),
			bookedHours: bookedHours,
			startHour: startHour,
		};
	}

	get expertDateAvailability(): typeof ExpertDateAvailability {
		return ExpertDateAvailability;
	}

	get convertDateObjectToDate(): typeof convertDateObjectToDate {
		return convertDateObjectToDate;
	}

	get disabledDates(): Date[] {
		const disabledDates: Date[] = [];
		this.availabilityDates?.forEach((element: ExpertAvailabilityDate) => {
			let totalHours = 0;

			totalHours += element.totalBookedHours;
			this.selectedDates?.map((x) => {
				const startingDateTime = addHours(
					this.toDate(element.date),
					getHours(this.selectedDateStartingHour)
				);
				const endDateTime = addHours(startingDateTime, this.totalWorkHours);
				if (
					(compareAsc(this.toDate(x.end), startingDateTime) === 1 ||
						compareAsc(this.toDate(x.end), startingDateTime) === 0) &&
					(compareAsc(this.toDate(x.start), endDateTime) === -1 ||
						compareAsc(this.toDate(x.start), endDateTime) === 0)
				)
					totalHours += x.totalHours;
			});
			// TODO totalhours max value could possible be set by the expert in the future
			if (
				element.availability === this.expertDateAvailability.unavailable ||
				totalHours >= 8 ||
				(this.selectedLocationOption?.value === ExpertBookingLocationType.OnLocation && totalHours > 0)
			) {
				disabledDates.push(new Date(element.date));
			}
		});
		return disabledDates;
	}

	get bookingDateOptions(): Partial<CalendarProps> {
		return {
			minDate: new Date(this.calendarMinDate),
			disabledDays: [0, 6],
			showIcon: false,
			inline: true,
			selectionMode: "single",
			numberOfMonths: 1,
		};
	}

	getAvailabilityType(date: Date): ExpertDateAvailability {
		return this.availabilityDates?.find((x) => {
			return compareAsc(getDateWithoutTime(date), getDateWithoutTime(this.toDate(x.date))) === 0;
		})?.availability as ExpertDateAvailability;
	}

	isSelectedDate(date: Date): boolean {
		const startingDateTime = addHours(date, getHours(this.selectedDateStartingHour));
		const endDateTime = addHours(startingDateTime, this.totalWorkHours);
		return !!this.selectedDates?.find((x) => {
			return (
				compareAsc(new Date(x.end), startingDateTime) === 1 &&
				compareAsc(new Date(x.start), endDateTime) === -1
			);
		});
	}

	onDateSelected(date: any): void {
		const bookedHours = this.availabilityDates?.find((x) => {
			return compareAsc(getDateWithoutTime(date), getDateWithoutTime(this.toDate(x.date))) === 0;
		})?.bookedHours;

		const startHour = this.availabilityDates?.find((x) => {
			return compareAsc(getDateWithoutTime(date), getDateWithoutTime(this.toDate(x.date))) === 0;
		})?.date;
		if (startHour) this.selectDate(date, bookedHours, new Date(startHour));
	}
}
</script>

<style scoped lang="scss">
.legend-box {
	height: 0.65rem;
	width: 0.65rem;
	background-color: black;
	margin-right: 5px;
}

:not(.p-disabled) > .custom-date {
	font-weight: 450;
	padding: 0.35rem;
	width: inherit;
	text-align: center;

	&.on-request {
		color: var(--warning-color);
		width: inherit;

		&.selected {
			background-color: var(--warning-color);
			color: var(--primary-color-text);
			width: inherit;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.p-datepicker-calendar table tbody td {
		border-bottom: 0px solid #e1e1e1;
	}

	&.available {
		color: var(--success-color);
		width: inherit;

		&.selected {
			background-color: var(--success-color);
			color: var(--primary-color-text);
			width: inherit;
		}
	}
}

::v-deep(.p-datepicker-calendar) {
	.p-datepicker-today span {
		color: var(--text-color) !important;
	}
}
</style>
