<template>
	<div class="cart-contract-table">
		<!--	Mock datatable header for consistent header styling-->
		<div class="p-datatable">
			<div class="p-datatable-header d-flex justify-content-between flex-wrap">
				<span>{{ $t(`cart.table.title${getUpperCaseCartLineType(cartClient.cartLineType)}`) }}</span>

				<Button
					v-if="isEditable"
					class="p-button-text p-button-danger hidden-print p-0"
					icon="pi pi-times"
					:label="$t('cart.button.removeItem')"
					:title="$t('cart.button.removeItem')"
					@click="onRemoveContractFromCartClick()"
				/>
			</div>
		</div>
		<ContractSummary :vm="contractSummary" />
		<Message
			v-if="cartClient.contractCoterming && contractSummary?.contractProlongationDate"
			class="mt-0"
			:closable="false"
			severity="warn"
		>
			<i18n-t keypath="myCadac.contractDetail.message.newExpirationDateDurationChange" scope="global">
				<template #date>
					<b>{{ formatDate(contractSummary.contractProlongationDate) }}</b>
				</template>
			</i18n-t>
		</Message>
		<Message
			v-else-if="contractSummary?.contractProlongationDate"
			class="mt-0"
			:closable="false"
			severity="info"
		>
			<i18n-t keypath="myCadac.contractDetail.message.newExpirationDate" scope="global">
				<template #date>
					<b>{{ formatDate(contractSummary.contractProlongationDate) }}</b>
				</template>
			</i18n-t>
		</Message>
		<CartClientTable
			v-if="editingCartLines?.length"
			:cart-contract-client="cartClient"
			:cart-line-type="cartClient.cartLineType"
			:is-editable="isEditable"
			:items="editingCartLines"
		/>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import CartClientTable from "@/components/commerce/cart/cartClientTable.vue";
import ContractSummary, { IContractSummary } from "@/components/common/contractSummary.vue";
import { CartClient } from "@/types/models/cart/cartClient";
import { CartContractClient } from "@/types/generated/Api/cartContractClient";
import { CartLineClient } from "@/types/generated/Api/cartLineClient";
import { CartLineType } from "@/types/enum/cartLineType";
import { Component, Prop } from "vue-facing-decorator";
import { ContractUpdateInCart } from "@/types/generated/Api/contractUpdateInCart";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { upperFirst } from "lodash";
import toFixed from "accounting-js/lib/toFixed.js";

@Component({ components: { CartClientTable, ContractSummary } })
export default class CartContractTable extends BaseComponent {
	@Prop({ type: Object as PropType<CartContractClient>, required: true, default: {} })
	cartClient!: CartContractClient;
	@Prop({ type: Boolean, required: true, default: false }) isEditable!: boolean;

	isLoading = false;
	editingCartLines: CartLineClient[] = [];

	get cartLineTypeEnum(): typeof CartLineType {
		return CartLineType;
	}

	get contractSummary(): IContractSummary {
		return {
			contractManager: this.cartClient?.contractManager,
			contractEndDate: this.cartClient?.endDate,
			contractNumber: this.cartClient?.contractNumber,
			contractType: this.cartClient?.contractType,
			contractProlongationDate: this.cartClient?.newProlongationDate,
		};
	}

	created(): void {
		this.editingCartLines = this.cartClient?.assets;
	}

	getUpperCaseCartLineType(cartLineType: CartLineType): string {
		return upperFirst(this.cartLineTypeEnum[cartLineType]);
	}

	onRemoveContractFromCartClick(): void {
		this.$confirm.require({
			message: this.$t("cart.dialog.confirmRemoveContractMessage", [this.cartClient.contractNumber]),
			header: this.$t("cart.dialog.confirmRemoveContractTitle"),
			acceptLabel: this.$t("common.yes"),
			rejectLabel: this.$t("common.no"),
			accept: () => {
				this.updateContractInCart({
					id: 0,
					amount: 0,
					contractnumber: this.cartClient.contractNumber,
					contractcoterming: this.cartClient.contractCoterming,
					cartLineType: this.cartClient.cartLineType,
				});
			},
		});
	}

	protected updateContractInCart(model: ContractUpdateInCart): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post<CartClient>("/api/cart/updateContract", model)
			.then(() => {
				let total = 0;
				this.cartClient.assets.forEach((asset: CartLineClient): void => {
					total += asset.priceSubTotal;
				});

				this.setDataLayer.removeFromCart(toFixed(total, 2), this.cartClient.assets, null);

				window.location.reload();
			})
			.catch((err) => {
				this.loadingStore.decreaseLoadingCount();
				Log.error(err);
			});
	}
}
</script>

<style scoped lang="scss"></style>
