<template>
	<div v-if="!isMobile" class="block" :class="blockBackgroundClass(vm.backgroundColor)">
		<div class="container">
			<h2
				v-if="vm.title"
				class="display-4"
				:class="textAlignmentClass(vm.titleAlignment)"
				v-html="vm.title"
			/>
			<div class="row">
				<div class="col">
					<Carousel
						:autoplay-interval="7500"
						:num-scroll="amountOfProductsToScroll"
						:num-visible="amountOfVisibleItems"
						:show-navigators="false"
						:value="vm.items"
					>
						<template #item="slotProps">
							<a
								class="highlight-item"
								:href="slotProps.data.linkItem.url"
								style="text-decoration: none"
								:target="slotProps.data.linkItem.target"
							>
								<img
									v-if="slotProps.data.imageUrl"
									v-lazy="slotProps.data.imageUrl"
									:alt="slotProps.data.imageAlt || slotProps.data.title"
									:title="slotProps.data.title"
								/>
								<div class="item-text">
									<h3 class="mb-0 text-primary">
										{{ slotProps.data.title }}
									</h3>
									<p class="lead text-black">
										{{ slotProps.data.text }}
									</p>
								</div>
							</a>
						</template>
					</Carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BaseBlock from "@/components/base/baseBlock.vue";
import Carousel from "primevue/carousel";
import { Component, Prop } from "vue-facing-decorator";
import { HighlightsBlockViewModel } from "@/types/viewModels/blocks/highlightsBlockViewModel";
import { PropType } from "vue";

@Component({ components: { Carousel } })
export default class HighlightsBlock extends BaseBlock {
	@Prop({
		type: Object as PropType<HighlightsBlockViewModel>,
		required: true,
		default: {},
	})
	vm!: HighlightsBlockViewModel;

	get amountOfVisibleItems(): number {
		return this.vm.numberOfItemsToShow || 1;
	}

	get amountOfProductsToScroll(): number {
		return this.vm.numberOfItemsToScroll || 1;
	}
}
</script>

<style lang="scss" scoped>
.highlight-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background: var(--surface-b);
	overflow: hidden;
	border-radius: var(--border-radius);

	&:hover {
		img {
			transform: scale(1.03);
		}
	}

	img {
		height: 500px;
		width: 100%;
		object-fit: cover;
		transition: all 1.25s;
	}

	.item-text {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		padding: 1.5rem;
		border-radius: var(--border-radius);
		background-color: rgba(230, 230, 230, 0.7);
		box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
		backdrop-filter: blur(0.6rem);
		width: clamp(475px, 50%, 750px);
		color: var(--text-color);
		p {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}
</style>
